import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import Photo from '../../components/panels/photo';
import AddressClass from '../../components/panels/address-class';
import AddressDocuments from '../../components/panels/address-documents';
import PlanTemplate from '../../components/panels/plan';
import TemplateContactPerson from '../../components/panels/contact-person/template-contact-person';
import { routes } from '../../../../config/constants';
import { CreateServiceContext } from '../../create';
import { ServiceData } from '../../../../types/service';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import history from '../../../../history';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import TemplateInfo from '../panels/template-info';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { generateLink } from '../../../../lib/utils';
import { infoValidationSchema } from '../panels/info';
import { convertParallelInResponse, setGenderStartEndToBack } from '../../utils';

const DayCareCreateDetails = () => {
  const { serviceData, currentEducationType, organizationEdit } = useContext(CreateServiceContext);
  const [load, setLoad] = useState<boolean>(false);

  const initialErrors = useInitialErrors(serviceData, getValidationSchema());

  const submitForm = async (values: ServiceData) => {
    setLoad(true);
    try {
      const payload = {
        ...values,
        info: {
          ...setGenderStartEndToBack(values.info),
          educationTypeId: currentEducationType,
          parallelList: convertParallelInResponse(values.info.parallelList as SelectOptionType[]),
        },
      };
      const id = await serviceTemplateApi.createService(payload);
      history.push(generateLink(routes.dayCareProgram, { id }));
    } finally {
      setLoad(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={serviceData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
      onSubmit={submitForm}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TemplateInfo editMode parent="info" isCreate organizationEdit={organizationEdit} />
            <TemplateContactPerson editMode parent="contactPerson" />
            <AddressClass editCount="many" />
            <AddressDocuments />
            <PlanTemplate isCreate />
            <Photo />

            <div className="room-save-container">
              <div className="room-panel-save">
                <div className="container">
                  <div className="room-panel-save__inner">
                    <Button primary onClick={() => history.push(routes.dayCarePrograms)} border label="Отмена" />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      load={load}
                      submit
                      disabled={!isValid}
                      onClick={handleSubmit}
                      primary
                      label="Создать программу"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default DayCareCreateDetails;

const getValidationSchema = () => {
  return objectYup().shape({
    contactPerson: contactPersonValidationSchema(),
    info: infoValidationSchema(),
  });
};
