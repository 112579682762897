import isEqual from 'lodash/isEqual';

type TokenManager = {
  isTokenValid: () => Promise<boolean> | boolean;
  getToken: () => string;
  setToken: (token: string) => void;
  clearToken: () => void;
};

const initialTokenManager = Object.freeze({
  isTokenValid: async () => false,
  getToken: () => '',
  setToken: () => {},
  clearToken: () => {},
});

let tokenManager: TokenManager = initialTokenManager;

export const http = <T>(req: RequestInfo, params: RequestInit): Promise<T> =>
  fetch(req, params)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error('request failed');
      }
    })
    .catch((err) => {
      throw new Error(err);
    });

const manager = (initialToken: string | null): TokenManager => {
  // Токен авторизации
  // let token = initialToken;
  // Получение токена из мемоизированной переменной или куки
  const getToken = () => {
    // if (token) return token;
    const token = localStorage.getItem('eszToken');
    if (token) {
      return token;
    }
    return '';
  };

  const setToken = (newToken: string | null) => {
    if (newToken) {
      localStorage.setItem('eszToken', newToken);
    }
  };

  const isTokenValid = async (): Promise<boolean> => {
    try {
      const token = getToken();
      return !!token;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return {
    /**
     * Проверка истечения времени действия токена
     */
    isTokenValid,
    // Получение токена
    getToken,
    // Обновление токена
    setToken,
    clearToken: () => {
      // token = null;
      localStorage.removeItem('eszToken');
    },
  };
};

if (isEqual(tokenManager, initialTokenManager)) {
  tokenManager = manager(null);
}

export default tokenManager;
