import React from 'react';
import { Link } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import FooterWrapper from '../../components/footer/footer-wrapper';
import Img from '../../assets/images/errors/technical.svg';

const TechnicalError = () => {
  return (
    <div className="error-layout">
      <div className="error-container">
        <Push size={40} />
        <div className="error-heading">Скоро все заработает!</div>
        <Push size={8} />
        <div className="error-lead">
          Сейчас мы работаем над улучшением сервиса! Извините за доставленные неудобства :(
        </div>
        <Push size={16} />
        <Link to=".">Обновить страницу</Link>
        <Push size={72} />
        <img src={Img} alt="" className="error-img" />
      </div>
      <FooterWrapper />
    </div>
  );
};

export default TechnicalError;
