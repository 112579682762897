import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FieldEducationType from '../fields/education-type';
import FieldDepartment from '../fields/department';
import FieldOrganization from '../fields/organization';
import FieldAddress from '../fields/address';
import FieldDirection from '../fields/direction';
import FieldProgrammLevel from '../fields/programm-level';
import FieldVisitType from '../fields/visit-type';
import FieldStatus from '../fields/status';
import FormikToggleGroup from '../../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../../components/formik/formik-toggle';
import FieldParallel from '../fields/parallel';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';
import { ServiceRegistryContext } from '../../initial-data';
import FieldStatusPlanReception from '../fields/status-plan-reception';
import FieldPeriodOfStudy from '../fields/period-of-study';
import { geTitleByType } from './utils';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { getEducationTypes, hasAccessObjectAny } from '../../../../../lib/utils';
import { AppState } from '../../../../../redux/types/state';
import { userProfileSelector } from '../../../../../redux/selectors';
import { accessAction, accessObject } from '../../../../../mock-data/access-enum';
import { SearchInitialFormData } from '../../../../../types/service';
import { selectServiceItemCallback } from '../../../../../lib/utils/service';
import { NotificationField } from '../../../../../components/fields/notification';

type Props = {
  setOpenTreePopup: (value: boolean) => void;
  basicDirection: SelectOptionType | null | undefined;
  type: RegistryTypeEnum;
  setBasicDirection: (value: SelectOptionType | null) => void;
  setCurrentSearchData: (values: SearchInitialFormData) => void;
};

type FieldStatusTitleType = {
  [index: string]: string;
};

const FieldsContainer: React.FC<Props> = ({
  type,
  setOpenTreePopup,
  basicDirection,
  setBasicDirection,
  setCurrentSearchData,
}) => {
  const { isAdmin, currentEducationType, setEdTypeByField } = React.useContext(ServiceRegistryContext);
  const { values } = useFormikContext<SearchInitialFormData>();

  const [fieldKeys, setFieldKeys] = useState<string[]>([]);

  const showDirectionAndProgrammLevel =
    type === RegistryTypeEnum.serviceChildType ||
    type === RegistryTypeEnum.serviceEducationType ||
    type === RegistryTypeEnum.serviceTemplateType ||
    (type === RegistryTypeEnum.serviceEducationProgramType && isAdmin);

  const showAvailable = !(type === RegistryTypeEnum.serviceTemplateType);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const fieldStatusTitle = (): string => {
    const conditionServiceChild = isAdmin && fieldKeys.includes('servicePlaceId');

    const keys: FieldStatusTitleType = {
      [RegistryTypeEnum.serviceChildType]: conditionServiceChild ? 'Статус услуги' : 'Статус программы',
      [RegistryTypeEnum.serviceEducationProgramType]: 'Статус образовательной программы',
      [RegistryTypeEnum.serviceGpdType]: 'Статус программы',
      [RegistryTypeEnum.serviceTemplateType]: isAdmin ? 'Статус шаблона' : 'Статус услуги',
      [RegistryTypeEnum.serviceEducationType]: 'Статус услуги',
    };

    const keysByEducationType: FieldStatusTitleType = {
      [EducationTypeEnum.ChildrenEducation]: 'Статус программы',
      [EducationTypeEnum.ChildrenNonDogmEducation]: 'Статус программы',
      [EducationTypeEnum.DayCareCentersEducation]: 'Статус программы',
      [EducationTypeEnum.VirtualAssistantEducation]: 'Статус услуги',
      [EducationTypeEnum.ArtHouseEducation]: 'Статус услуги',
      [EducationTypeEnum.SportEducation]: 'Статус услуги',
      [EducationTypeEnum.ProfessionalEducation]: 'Статус образовательной программы',
    };

    if (
      values.educationTypeName !== 'Все' &&
      values.educationTypeId &&
      isAdmin &&
      type === RegistryTypeEnum.serviceChildType
    ) {
      return keysByEducationType[values.educationTypeId];
    } else {
      return keys[type];
    }
  };

  const accessFieldAddress =
    hasAccessObjectAny(userProfile, [accessObject.ServiceNonDOGM], accessAction.ViewRegistry) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceDOGM], accessAction.ViewRegistry) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceArtHouse], accessAction.ViewRegistry) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceSport], accessAction.ViewRegistry) ||
    isAdmin;

  const accessStatusPlanReception =
    hasAccessObjectAny(userProfile, [accessObject.ServiceArtHouse], accessAction.ViewRegistry) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceSport], accessAction.ViewRegistry) ||
    isAdmin;

  const showFieldAddress = (type: string) => {
    const address = (
      <>
        <Push size={16} />
        <FieldAddress />
      </>
    );

    if (type === RegistryTypeEnum.serviceChildType && isAdmin && fieldKeys.includes('servicePlaceId')) {
      return null;
    } else if (type === RegistryTypeEnum.serviceChildType) {
      return accessFieldAddress && address;
    } else if (type === RegistryTypeEnum.serviceEducationType && accessFieldAddress) {
      return address;
    } else if (type === RegistryTypeEnum.serviceEducationType && !isAdmin) {
      return null;
    } else {
      return address;
    }
  };

  const showFieldStatusPlanReception = (type: string) => {
    const field = (
      <>
        <Push size={16} />
        <FieldStatusPlanReception />
      </>
    );
    if (type === RegistryTypeEnum.serviceTemplateType && isAdmin) {
      return null;
    } else if (type === RegistryTypeEnum.serviceEducationType && accessStatusPlanReception) {
      return field;
    } else if (type === RegistryTypeEnum.serviceEducationType && !isAdmin) {
      return null;
    } else if (type === RegistryTypeEnum.serviceEducationType) {
      return field;
    } else {
      return isAdmin && field;
    }
  };

  const showPeriodOfStudy = (type: RegistryTypeEnum) => {
    const isVa = getEducationTypes(userProfile, [accessObject.ServiceVA], accessAction.View)?.length > 0;
    const field = (
      <>
        <Push size={16} />
        <FieldPeriodOfStudy />
      </>
    );

    if (
      [
        RegistryTypeEnum.serviceChildType,
        RegistryTypeEnum.serviceGpdType,
        RegistryTypeEnum.serviceEducationProgramType,
      ].includes(type) ||
      isVa
    ) {
      return isAdmin && field;
    } else if (type === RegistryTypeEnum.serviceEducationType && !isAdmin) {
      return field;
    } else {
      return field;
    }
  };

  const showFieldParallel = () => {
    const field = (
      <>
        <FieldParallel />
        <Push size={16} />
      </>
    );
    if (type === RegistryTypeEnum.serviceChildType && isAdmin && fieldKeys.includes('parallelId')) {
      return null;
    } else if (
      [RegistryTypeEnum.serviceChildType, RegistryTypeEnum.serviceGpdType].includes(type) &&
      (currentEducationType.includes(EducationTypeEnum.DayCareCentersEducation) || isAdmin)
    ) {
      return field;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setCurrentSearchData(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const checkChildrenType =
      values.educationTypeId === EducationTypeEnum.ChildrenEducation ||
      values.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation;

    const checkVirtualAssistantType = values.educationTypeId === EducationTypeEnum.VirtualAssistantEducation;

    const checkTypeInSelect = checkChildrenType || checkVirtualAssistantType;

    if (values.educationTypeId) {
      if (checkTypeInSelect) {
        if (isAdmin && type === RegistryTypeEnum.serviceChildType) {
          if (checkChildrenType) {
            setFieldKeys(['parallelId']);
          } else if (checkVirtualAssistantType) {
            setFieldKeys(['parallelId', 'servicePlaceId']);
          }
        }
      } else {
        setFieldKeys([]);
      }
    } else {
      setFieldKeys([]);
    }
  }, [values, isAdmin, type]);

  useEffect(() => {
    setEdTypeByField(values.educationTypeId as EducationTypeEnum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.educationTypeId]);

  return (
    <>
      <Push size={16} />
      <div className="service-search-group-grid">
        <div>
          <FieldEducationType />
          <Push size={16} />
          <FieldDepartment />
          <Push size={16} />
          <FieldOrganization />

          {showFieldAddress(type)}

          {showFieldStatusPlanReception(type)}

          {/* По уведомлению */}
          <NotificationField />

          {showAvailable && (
            <>
              <Push size={16} />
              <FormikToggleGroup
                name="isWithFreeVolume"
                size="small"
                label="Отображать только доступные для записи"
                width="fit-content"
              >
                <FormikToggle name="isWithFreeVolume" size="small" />
              </FormikToggleGroup>
            </>
          )}
        </div>
        <div>
          {showFieldParallel()}

          {showDirectionAndProgrammLevel && (
            <>
              <FieldDirection
                basicDirection={basicDirection}
                setOpenTreePopup={setOpenTreePopup}
                title={geTitleByType(type, 'direction')}
                setBasicDirection={setBasicDirection}
                type={type}
                isAdmin={isAdmin}
              />
              <Push size={16} />
              <FieldProgrammLevel title={geTitleByType(type, 'programmLevel')} />
              <Push size={16} />
            </>
          )}

          <FieldVisitType />
          <Push size={16} />
          {selectServiceItemCallback ? null : <FieldStatus title={fieldStatusTitle()} />}
          {showPeriodOfStudy(type)}
        </div>
      </div>
    </>
  );
};

export default FieldsContainer;
