import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { routes } from '../../config/constants';
import { initApp } from '../../redux/thunks/init';
import history from '../../history';
import { moduleName as appModule } from '../../redux/ducks/app';
import { moduleName as appProfile } from '../../redux/ducks/user-profile';
import { AppState } from '../../redux/types/state';

import Layout from './layout';
import TechnicalError from '../errors/technical';
import UnknownError from '../errors/unknown';
import Auth from '../auth';
import RequestPrint from '../print/request-print';
import ServiceClassPrint from '../print/service-class-print';
import ServicePrint from '../print/service-print';
import ScrollToTop from './scroll-to-top';
import LoginLayout from '../login';

const mapStateToProps = (state: AppState) => ({
  appLoading: state[appModule].loading,
  userProfile: state[appProfile],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      initApp,
    },
    dispatch
  );

const enhance = connect(mapStateToProps, mapDispatchToProps);

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Routes extends Component<Props> {
  async componentDidMount() {
    const { initApp } = this.props;

    await initApp();
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <Switch>
          <Route path={routes.loginAupd} component={LoginLayout} />
          <Route path={routes.auth} component={Auth} />
          <Route exact path={routes.technicalError} component={TechnicalError} />
          <Route exact path={routes.unknownError} component={UnknownError} />
          <Route path={routes.printRequest} component={RequestPrint} />
          <Route path={routes.printServiceClass} component={ServiceClassPrint} />
          <Route path={routes.printService} component={ServicePrint} />
          <Route path={routes.main} component={Layout} />
          {/* <Route component={NotFound} /> */}
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default enhance(Routes);
