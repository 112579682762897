import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../../../hooks/formik-initial-errors';
import { ServiceStatusEnum } from '../../../../../../mock-data/service-status-enum';
import { EntranceTestsData } from '../../../../../../types/service';
import { serviceTemplateApi } from '../../../../../../lib/api/service-template';
import { ServiceContext } from '../../../../index';
import TestsEdit, { testValidationSchema } from './tests-edit';
import { templateApi } from '../../../../../../lib/api/template';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
  parent?: string;
};

const Tests: React.FC<Props> = ({ parent, isTemplate, setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData.test ?? ({} as EntranceTestsData);
  const initialErrors = useInitialErrors(initialValues, testValidationSchema());
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitTest = async (values: EntranceTestsData) => {
    try {
      if (isTemplate) {
        await templateApi.updateTemplateTest(serviceData.id, {
          isRequired: values.isRequired,
          toursNumber: values.toursNumber,
          periodGettingDocument: values.periodGettingDocument,
          canChangeTestAndToursNumber: values.canChangeTestAndToursNumber,
          canChangePeriodGettingDocument: values.canChangePeriodGettingDocument,
          educationTypeId: serviceData.educationTypeId,
          templateId: serviceData.id,
        });
      } else {
        await serviceTemplateApi.updateTest(serviceData.id, {
          ...values,
          educationTypeId: serviceData.educationTypeId,
        });
      }
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } catch {}
  };

  return (
    <Formik
      onSubmit={(value) => submitTest(value)}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={testValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<EntranceTestsData>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <TestsEdit
              parent={parent}
              editMode={editMode}
              isTemplate={isTemplate}
              setEditMode={setEditMode}
              setEditModeParent={setEditModeParent}
              checkEditable={checkEditable}
            />
            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button submit primary label="Сохранить" load={isSubmitting} disabled={!isValid} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Tests;
