import React from 'react';
import { Infobox, Push } from '@mosru/esz_uikit';
import { formatDateAndTime } from '../../../../lib/utils/date';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { SignInfoData } from '../../../../types/service';

type Props = {
  sign: SignInfoData;
  serviceStatusId: ServiceStatusEnum;
};

const SignInfo: React.FC<Props> = ({ sign, serviceStatusId }) => {
  const downloadSign = () => {
    const linkSource = `data:text/plain;charset=utf-8,${sign.description}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = sign.fileName;
    downloadLink.click();
  };
  return serviceStatusId === ServiceStatusEnum.Signed && sign ? (
    <>
      <Push size={12} />
      <Infobox
        fullWidth
        color="success"
        text={
          <>
            Пользователь: {sign.signer}. Дата: {formatDateAndTime(sign.date)}. Файл:{' '}
            <button type="button" className="icon-group__text color-primary" onClick={downloadSign}>
              Подписание услуги
            </button>
          </>
        }
      />
    </>
  ) : null;
};

export default SignInfo;
