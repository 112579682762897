import React, { useEffect, useState } from 'react';
import { Button, ModalPanel, Modal } from '@mosru/esz_uikit';
import { ProgrammModule } from '../../types/entities';
import { serviceTemplateApi } from '../../lib/api/service-template';
import Field from '../fields/field';
import { EducationTypeEnum } from '../../types/education-type';
import { getServiceEditLink } from '../../lib/utils';

type Props = {
  show: boolean;
  hideModal: () => void;
  moduleId?: number;
  serviceId?: number;
  serviceName: string;
};

const Module: React.FC<Props> = ({ show, hideModal, moduleId, serviceId, serviceName }) => {
  const [module, setModule] = useState<ProgrammModule>();
  useEffect(() => {
    const fetch = async () => {
      if (moduleId && serviceId) {
        const data = await serviceTemplateApi.getProgrammModule(moduleId, serviceId);
        setModule(data);
      }
    };
    fetch();
  }, [moduleId, serviceId]);

  return (
    <Modal show={show} onClose={hideModal}>
      <ModalPanel
        overflow
        onClose={hideModal}
        controls={() => <Button label="Закрыть" primary border size="small" onClick={hideModal} />}
        modalTitle="Модуль образовательной программы"
        renderComponent={() => (
          <>
            <Field
              label="Образовательная программа"
              value={
                <span
                  className="text-decoration-underline cursor-pointer brand-link"
                  onClick={() =>
                    window.open(
                      getServiceEditLink(EducationTypeEnum.ProfessionalEducation, module?.serviceId),
                      '_blank'
                    )
                  }
                >
                  {serviceName}
                </span>
              }
            />
            <Field label="Название модуля" value={module?.name} />
            <Field label="Описание модуля" value={module?.description} />
            <Field label="Количество часов в модуле" value={module?.learnHours} />
            <Field label="Адрес предоставления услуги" value={module?.fullAddress} />
            <Field label="Преподаватель" value={module?.teacherName} />
          </>
        )}
      />
    </Modal>
  );
};

export default Module;
