import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { TeacherContext } from '..';
import teacherApi from '../../../../lib/api/teacher';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const AddArchiveModal = ({ show, onCloseHandler }: Props) => {
  const { teacher } = useContext(TeacherContext);
  const [loading, setLoading] = useState<boolean>(false);

  const historyRouter = useHistory();

  const addArchiveTeacher = async () => {
    setLoading(true);

    try {
      await teacherApi.archiveTeacher(teacher?.id as number);
      historyRouter.goBack();
    } finally {
      setLoading(false);
      onCloseHandler();
    }
  };

  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        alert
        onClose={onCloseHandler}
        modalTitle="Отправка преподавателя в архив"
        description="Сведения о преподавателе будут отправлены в архив. Продолжить?"
        controls={() => (
          <>
            <Button border disabled={loading} label="Отмена" size="small" onClick={onCloseHandler} />
            <Push orientation="horizontal" size={12} />
            <Button primary load={loading} label="Да, продолжить" size="small" onClick={addArchiveTeacher} />
          </>
        )}
      />
    </Modal>
  );
};

export default AddArchiveModal;
