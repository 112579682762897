import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { object as objectYup, string as stringYup } from 'yup';
import { Button, notify, Push } from '@mosru/esz_uikit';
import { onSignIn } from '../../../../redux/thunks/auth';
import {
  initialChangePasswordData,
  ChangePasswordData,
  PasswordSettings,
  defaultPasswordSettings,
  LoginData,
} from '../../../../types/auth';
import FormikInput from '../../../../components/formik/formik-input';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { authApi } from '../../../../lib/api';
import history from '../../../../history';
import { routes } from '../../../../config/constants';
import PasswordStrength from '../../../password-rules/strength';
import PasswordRequirement from '../../../password-rules/requirement';
import { getRequirements, validatePassword } from '../../../password-rules/utils';
import { PasswordRequirementType } from '../../../password-rules/types/password';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import FormikCheckboxGroup from '../../../../components/formik/formik-checkbox-group';

type Props = {
  onSuccess?: () => void;
  requirements?: PasswordRequirementType[];
};

const ChangePasswordForm = ({ onSuccess, requirements }: Props) => {
  const [settings, setSettings] = useState<PasswordSettings>(defaultPasswordSettings);
  const location = useLocation();

  const dispatch = useDispatch();
  // todo
  const { search, state } = useLocation<LoginData>();
  if (!state) {
    history.push({
      pathname: routes.login,
      search: location.search,
    });
  }
  const initialData = useMemo(
    () => ({
      ...initialChangePasswordData,
      login: state?.login,
      oldPassword: state?.password,
      remember: state.remember,
    }),
    [state]
  );
  const initialErrors = useInitialErrors(initialData, getValidationSchema(settings));
  if (state === undefined) {
    history.push({
      pathname: routes.login,
      search,
    });
  }
  // todo
  // eslint-disable-next-line

  const passwordRules = useMemo(() => {
    return requirements ?? getRequirements(settings);
  }, [requirements, settings]);

  const signInHandler = useCallback(
    async (values: ChangePasswordData, formikProps: FormikHelpers<ChangePasswordData>) => {
      try {
        const response = await authApi.changePassword(values);
        if (response.errors?.length) {
          notify.danger({ data: { label: response.errors?.join(', ') || '' } });
        } else {
          const responseSing = await authApi.signIn({
            login: values.login,
            password: values.newPassword,
            appName: 'ui',
            needCheckPasswordExpired: false,
            remember: true,
            impersonateKey: '',
          });
          if (responseSing.isLoggedIn) {
            await dispatch(onSignIn(responseSing.token));
          } else {
            notify.danger({ data: { label: responseSing.errors?.join(', ') || '' } });
          }
        }
      } catch (err) {}
    },
    [dispatch]
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await authApi.getSettings();
      setSettings(data);
    };
    fetchData();
  }, []);

  return (
    <Formik
      onSubmit={signInHandler}
      enableReinitialize
      initialValues={initialData}
      validationSchema={getValidationSchema(settings)}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ChangePasswordData>) => {
        const { handleSubmit, values, isSubmitting, isValid } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormikFormGroup name="newPassword" label="Новый пароль">
              <FormikInput placeholder="Введите пароль..." value={values.newPassword} name="newPassword" password />
            </FormikFormGroup>

            <Push size={8} />
            <PasswordStrength password={values.newPassword} passwordRules={passwordRules} />

            <Push size={12} />
            <PasswordRequirement password={values.newPassword} passwordRules={passwordRules} />

            <Push size={16} />
            <FormikFormGroup name="confirmNewPassword" label="Повторите пароль">
              <FormikInput
                placeholder="Введите пароль еще раз..."
                value={values.confirmNewPassword}
                name="confirmNewPassword"
                password
              />
            </FormikFormGroup>
            <Push size={12} />
            <FormikCheckboxGroup label="Запомнить меня" name="remember" size="small">
              <FormikCheckbox name="remember" />
            </FormikCheckboxGroup>

            <Push size={16} />
            <Button
              submit
              label="Сохранить и войти в систему"
              primary
              load={isSubmitting}
              disabled={!isValid}
              fullWidth
            />
          </form>
        );
      }}
    </Formik>
  );
};

const getValidationSchema = (settings: PasswordSettings) =>
  objectYup().shape({
    newPassword: stringYup()
      .nullable()
      .test('password-requirement', '', (value) => validatePassword(value, settings)),
    confirmNewPassword: stringYup()
      .nullable()
      .test(
        'passwords-match',
        'Введенные пароли не совпадают. Проверьте ввод пароля и повторите попытку',
        function (value) {
          return this.parent.newPassword === value;
        }
      ),
  });

export default ChangePasswordForm;
