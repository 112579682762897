import React from 'react';
import FormikFormGroup from '../../../../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../../../../components/formik/formik-datepicker';
import { getDate } from '../../../../../../../lib/utils/date';

type Props = {
  docDate: Date | string;
  accessCheck: boolean;
  newDocument: boolean;
};

export const DocumentDate: React.FC<Props> = ({ docDate, newDocument, accessCheck }) => {
  const date = new Date(docDate);
  date.toISOString();

  // Todo разобраться с багом в комментарии
  return (
    <div style={{ maxWidth: '160px' }}>
      {/*  если указать  !newDocument ? date : undefined то дата не рендерится хотя value и starDate в DataPicker установлен */}
      {!newDocument && (
        <FormikFormGroup name="docDate" required label="Дата">
          <FormikDatePicker
            disabled={!accessCheck}
            startDate={getDate(date)}
            placeholder="ДД.ММ.ГГГГ"
            size="small"
            name="docDate"
          />
        </FormikFormGroup>
      )}

      {newDocument && (
        <FormikFormGroup name="docDate" required label="Дата">
          <FormikDatePicker
            disabled={!accessCheck}
            startDate={undefined}
            placeholder="ДД.ММ.ГГГГ"
            size="small"
            name="docDate"
          />
        </FormikFormGroup>
      )}
    </div>
  );
};
