import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import {
  date as dateYup,
  object as objectYup /* string as stringYup */,
  string as stringYup,
  array as arrayYup,
  number,
} from 'yup';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Infobox, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import { ReactComponent as IconClock } from '../../../../assets/images/icons/clock-danger.svg';
import { ScheduleData, ServiceData, TrainingGroupData } from '../../../../types/service';
import lookupApi from '../../../../lib/api/lookup';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { generateLink, hasAccessObjectAny, hasGeneralAccess, parseUrlSearchString } from '../../../../lib/utils';
import { formatDate, formatDateAndTime, formatTime, getDate } from '../../../../lib/utils/date';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../../types/authorization-data';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import SimpleModal from '../../components/modals/simple-modal';
import { routes } from '../../../../config/constants';
import history from '../../../../history';
import SignModal from '../../../../components/sign-modal';
import { validationCheckDate } from '../../../../lib/utils/validation';
import { accessVedomst } from '../../../../mock-data/access-enum';
import { TypeFinancingEnum } from '../../../../mock-data/type-financing-enum';
import DateField from '../../components/fields/add-plan/date';
import TimeField from '../../components/fields/add-plan/time';
import SavePanel from '../../../../components/save-panel';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  editable?: boolean;
  planData?: TrainingGroupData;
  getTraininig: () => Promise<void>;
  service: ServiceData;
};

const TrainingGroupBody: React.FC<Props> = ({ editable, setEditModeParent, planData, getTraininig, service }) => {
  const [editMode, setEditMode] = useState(editable);
  const [initialValues, setInitialValues] = useState<TrainingGroupData>();
  const initialErrors = useInitialErrors(initialValues, getValidationSchema(false));
  const [periodOptions, setPeriodOptions] = useState<SelectOptionType[]>([]);
  const [isRetro, setIsRetro] = useState<boolean>();
  const [yearId, setYearId] = useState<number>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [signData, setSignData] = useState<TrainingGroupData | undefined>();
  const location = useLocation();
  const [key, setKey] = useState(Math.random());
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const canEdit =
    hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceArtHouse, accessObject.ServiceSport], accessAction.Edit);
  const needPublicationDate = useMemo(() => !service.isTestService && !isRetro, [service.isTestService, isRetro]);
  const canEditeStage = useMemo(
    () =>
      userProfile.vedomstvoId !== accessVedomst.Dkgm ||
      planData?.id === 0 ||
      (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment &&
        hasGeneralAccess(userProfile, generalAccess.CreatePaydableService) &&
        [3, 14, 15].includes(service.info.programmLevelId ?? 0)),
    [userProfile, service, planData?.id]
  );

  const trainingStatus = useMemo(
    () =>
      !planData?.scheduleList || service.isTestService
        ? TrainingGroupStatusEnum.Draft
        : planData?.scheduleList[0]?.trainingGroupStatusId ?? TrainingGroupStatusEnum.Draft,
    [planData, service.isTestService]
  );
  const canPublicate = useMemo(
    () =>
      canEdit &&
      !service.isTestService &&
      (!hasGeneralAccess(userProfile, generalAccess.UseSign) || isRetro) &&
      trainingStatus === TrainingGroupStatusEnum.Draft,
    [canEdit, trainingStatus, service.isTestService, userProfile, isRetro]
  );

  const canSign = useMemo(
    () =>
      canEdit &&
      !service.isTestService &&
      trainingStatus === TrainingGroupStatusEnum.Draft &&
      !isRetro &&
      hasGeneralAccess(userProfile, generalAccess.UseSign),
    [canEdit, service.isTestService, isRetro, userProfile, trainingStatus]
  );

  const haveRequest = useMemo(
    () => planData?.scheduleList?.length && planData?.scheduleList[0].requestTotalCount > 0,
    [planData]
  );

  const showEditButton = useMemo(
    () =>
      (service.isTestService || trainingStatus !== TrainingGroupStatusEnum.Signed || !haveRequest) &&
      trainingStatus !== TrainingGroupStatusEnum.Archive,
    [trainingStatus, haveRequest, service.isTestService]
  );

  const enabledOnlyDraft = trainingStatus === TrainingGroupStatusEnum.Draft || service.isTestService;

  useEffect(() => {
    setIsRetro(
      planData?.scheduleList?.length
        ? planData.scheduleList[0].isRetro
        : !!parseUrlSearchString(location.search).isRetro
    );
    const yearId = planData?.yearOfTrainingId ?? parseUrlSearchString(location.search).yearId;
    setYearId(yearId);
  }, [planData, location.search]);

  useEffect(() => {
    setInitialValues(
      (planData?.id ?? 0) > 0
        ? planData
        : service.isTestService || isRetro
        ? ({ serviceStageId: periodOptions?.length > 0 ? periodOptions[0].value : undefined } as TrainingGroupData)
        : ({
            serviceStageId: periodOptions?.length > 0 ? periodOptions[0].value : undefined,
            scheduleList: [{ requestStart: new Date(), requestTimeStart: '08:00' } as unknown],
          } as TrainingGroupData)
    );
    setKey(Math.random());
  }, [planData, service.isTestService, periodOptions, isRetro]);

  useEffect(() => {
    if (service.stage?.list?.length > 0) {
      setPeriodOptions(
        service.stage.list.map((s) => {
          return { label: s.name, value: s.id || 0 };
        })
      );
    }
  }, [service.stage]);

  const createOrUpdate = async (values: TrainingGroupData): Promise<number> => {
    if (isRetro && !service.isTestService && !values.scheduleList) {
      values.scheduleList = [{ isRetro: true } as ScheduleData];
    }
    values = {
      ...values,
      educationTypeId: service.educationTypeId,
      yearOfTrainingId: yearId,
    };

    if (values.id > 0) {
      await serviceTemplateApi.updateTrainingGroupNonDogm(service.id, values);
      return values.id;
    } else {
      if (values.scheduleList) {
        values.scheduleList[0].trainingGroupId = 0;
      }
      return await serviceTemplateApi.createTrainingGroupNonDogm(service.id, values);
    }
  };

  const submit = async (values: TrainingGroupData) => {
    const tgId = await createOrUpdate(values);
    if (values.id === tgId) {
      setEditMode(false);
      getTraininig();
      setEditModeParent && setEditModeParent(null);
    } else {
      setEditMode(false);
      history.push(
        `${generateLink(routes.trainingGroup, { serviceId: service.id, id: tgId })}?isRetro=${isRetro}&yearId=${
          values.yearOfTrainingId
        }`
      );
    }
  };

  const signTg = async (signData: TrainingGroupData, sign: string): Promise<number> => {
    const tgId = await createOrUpdate(signData);
    await serviceTemplateApi.signTrainingGroup({
      serviceId: service.id,
      educationTypeId: service.educationTypeId,
      signedDocument: sign,
      trainingGroupId: tgId,
    });
    return tgId;
  };

  const updateStatus = async (tgId: number, status: TrainingGroupStatusEnum, needGetTraining = true) => {
    await serviceTemplateApi.updateTrainingGroupStatus({
      serviceId: service.id,
      educationTypeId: service.educationTypeId,
      trainingGroupId: tgId,
      trainingGroupStatusId: status,
    });
    setEditMode(false);
    needGetTraining && getTraininig();
  };

  const handleCloseEditModal = async () => {
    await updateStatus(planData?.id ?? 0, TrainingGroupStatusEnum.Draft);
    setEditModeParent && setEditModeParent('plan-case1');
    setEditMode(true);
  };

  const admissionPlanData = useMemo(
    () => service.test?.admissionPlanList?.find((a) => a.yearOfTrainingId === yearId),
    [yearId, service]
  );

  return initialValues ? (
    <>
      <Formik
        onSubmit={() => {}}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema(needPublicationDate)}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<TrainingGroupData>) => {
          const { values, isSubmitting, isValid, setFieldValue, resetForm } = formikProps;

          return (
            <form>
              {!editMode &&
                !service.isTestService &&
                !!planData?.id &&
                canEdit &&
                trainingStatus === TrainingGroupStatusEnum.Draft && (
                  <>
                    <Push size={12} />
                    <div className="infobox infobox--danger">
                      <div className="infobox__head">
                        <div className="infobox__body">
                          <div className="flex">
                            <IconClock className="flex-none" />
                            <Push size={8} orientation="horizontal" />
                            {isRetro ? (
                              <div className="color-danger-dark">
                                <span className="font-weight-bold">План приема не опубликован.</span> Нажмите
                                “Подтвердить” для публикации.
                              </div>
                            ) : (
                              <span className="color-danger-dark">
                                Для подачи заявлений необходимо, чтобы данная услуга была в статусе “Опубликовано на
                                MOS.ru”, план приема / расписание вступительных испытаний “Подписан”/ “Подписано”,
                                количество зачисленных заявлений не превышало количество мест и дата приема заявлений на
                                Mos.ru была не позже сегодняшней даты и текущего времени.
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="infobox__control">
                          {canSign && <Button primary label="Подписать" onClick={() => setSignData(values)} />}
                          <Push size={12} orientation="horizontal" />
                          {canPublicate && (
                            <Button
                              primary
                              label={isRetro ? 'Подтвердить' : 'Опубликовать'}
                              onClick={() => updateStatus(planData.id, TrainingGroupStatusEnum.Signed)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <Push size={12} />
              <Panel
                title={() => <>Сведения о плане приема</>}
                headingControl={() => {
                  return !editMode && setEditModeParent && showEditButton ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (trainingStatus === TrainingGroupStatusEnum.Signed && !service.isTestService) {
                          setShowEditModal(true);
                        } else {
                          setEditModeParent && setEditModeParent('plan-case1');
                          setEditMode(true);
                        }
                      }}
                      className="icon-group"
                    >
                      <span className="icon-group__icon">
                        <IconEdit />
                      </span>
                      <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                    </button>
                  ) : null;
                }}
              >
                <div className="container">
                  {trainingStatus === TrainingGroupStatusEnum.Signed &&
                    values.scheduleList?.length > 0 &&
                    values.scheduleList[0].sign && (
                      <>
                        <Infobox
                          fullWidth
                          color="success"
                          text={`Пользователь: ${values.scheduleList[0].sign.signer}. Дата: ${formatDateAndTime(
                            values.scheduleList[0].sign.date
                          )}`}
                        />
                        <Push size={18} />
                      </>
                    )}
                  {admissionPlanData?.yearOfTrainingName && (
                    <Infobox
                      fullWidth
                      color={editMode ? 'warning' : 'primary'}
                      text={`${editMode ? 'Обратите внимание! Вы создаете план приема на' : 'План приема на'} ${
                        admissionPlanData?.yearOfTrainingName
                      }.`}
                    />
                  )}
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Дата начала занятий {editMode && <span className="table-data__required" />}
                    </div>
                    <div className="table-data__body">
                      {editMode ? (
                        <div style={{ width: 180 }}>
                          <FormikFormGroup name="educationStartDate" label="">
                            <FormikDatePicker
                              placeholder="ДД.ММ.ГГГГ"
                              size="small"
                              name="educationStartDate"
                              startDate={values?.educationStartDate ? getDate(values.educationStartDate) : undefined}
                              disabled={!enabledOnlyDraft}
                            />
                          </FormikFormGroup>
                        </div>
                      ) : values.educationStartDate ? (
                        formatDate(values.educationStartDate)
                      ) : (
                        '—'
                      )}
                    </div>
                  </div>

                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Этап обучения {editMode && <span className="table-data__required" />}
                    </div>
                    <div className="table-data__body">
                      {editMode ? (
                        <FormikFormGroup name="serviceStageId" label="" required>
                          <FormikSelect
                            name="serviceStageId"
                            size="small"
                            isSearchable
                            options={periodOptions}
                            placeholder="Выберите..."
                            disabled={!enabledOnlyDraft || !canEditeStage}
                            selectedValue={(v: SelectOptionType) => {
                              v && setFieldValue('serviceStageName', v.label);
                            }}
                          />
                        </FormikFormGroup>
                      ) : (
                        values.serviceStageName || '—'
                      )}
                    </div>
                  </div>

                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Состав группы {editMode && <span className="table-data__required" />}
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-2">
                        <div className="table-data__group">
                          <div className="table-data__label">План приема</div>
                          <div className="table-data__body">
                            {editMode ? (
                              <div style={{ width: 100 }}>
                                <FormikFormGroup required label="" name="volume">
                                  <FormikInput name="volume" size="small" number placeholder="0" />
                                </FormikFormGroup>
                              </div>
                            ) : (
                              values.volume || '—'
                            )}
                          </div>
                        </div>
                        <div className="table-data__group">
                          <div className="table-data__label">Зачислено</div>
                          <div className={classNames('table-data__body', editMode && 'table-data-row-control')}>
                            {0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Преподаватель</div>
                    <div className="table-data__body">
                      {editMode ? (
                        <FormikFormGroup name="teacher" label="" required>
                          <FormikSelect
                            name="teacher"
                            size="small"
                            isSearchable
                            options={[]}
                            placeholder="Начните вводить..."
                            loadOptions={async (query) =>
                              await lookupApi.getTeachers(query, service.info.organizationId)
                            }
                            defaultValue={values?.teacher ? { value: 0, label: values?.teacher } : null}
                            selectedValue={(option: any) => {
                              setFieldValue('teacherId', option?.value || '');
                              setFieldValue('teacher', option?.label || '');
                            }}
                          />
                        </FormikFormGroup>
                      ) : (
                        values.teacher || '—'
                      )}
                    </div>
                  </div>

                  {!isRetro && service?.isTestService === false && (
                    <>
                      <div className="table-data__item table-data__group">
                        <div className="table-data__label table-data__label--main">
                          <div>
                            Начало приема заявлений на Mos.ru {editMode && <span className="table-data__required" />}
                          </div>
                        </div>
                        <div className="table-data__body">
                          <div className="table-data-grid-2">
                            <div className="table-data__group">
                              <div className="table-data__label">Дата</div>
                              <div className="table-data__body">
                                {editMode ? (
                                  <DateField
                                    key={key}
                                    name="scheduleList[0].requestStart"
                                    dependentTimeName="scheduleList[0].requestTimeStart"
                                    showErrorImmediately={values.id > 0}
                                  />
                                ) : values.scheduleList ? (
                                  formatDate(values.scheduleList[0].requestStart)
                                ) : (
                                  '—'
                                )}
                              </div>
                            </div>
                            <div className="table-data__group">
                              <div className="table-data__label">Время</div>
                              <div className="table-data__body">
                                <div style={{ width: 114 }} className="flex-none">
                                  {editMode ? (
                                    <TimeField
                                      name="scheduleList[0].requestTimeStart"
                                      dependentDateName="scheduleList[0].requestStart"
                                    />
                                  ) : values.scheduleList ? (
                                    formatTime(values.scheduleList[0]?.requestTimeStart)
                                  ) : (
                                    '—'
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-data__item table-data__group">
                        <div className="table-data__label table-data__label--main">
                          <div>
                            Окончание приема заявлений на Mos.ru {editMode && <span className="table-data__required" />}
                          </div>
                        </div>
                        <div className="table-data__body">
                          <div className="table-data-grid-2">
                            <div className="table-data__group">
                              <div className="table-data__label">Дата</div>
                              <div className="table-data__body">
                                {editMode ? (
                                  <DateField
                                    name="scheduleList[0].requestEnd"
                                    dependentTimeName="scheduleList[0].requestTimeEnd"
                                    showErrorImmediately={values.id > 0}
                                  />
                                ) : values.scheduleList ? (
                                  formatDate(values.scheduleList[0].requestEnd)
                                ) : (
                                  '—'
                                )}
                              </div>
                            </div>
                            <div className="table-data__group">
                              <div className="table-data__label">Время</div>
                              <div className="table-data__body">
                                <div style={{ width: 114 }} className="flex-none">
                                  {editMode ? (
                                    <TimeField
                                      name="scheduleList[0].requestTimeEnd"
                                      dependentDateName="scheduleList[0].requestEnd"
                                    />
                                  ) : values.scheduleList ? (
                                    formatTime(values.scheduleList[0]?.requestTimeEnd)
                                  ) : (
                                    '—'
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Panel>

              {editMode && setEditModeParent && (
                <SavePanel
                  controls={
                    <>
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                          if (!values.id) {
                            history.push(generateLink(routes.service, { id: service.id }));
                          }
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      {!service.isTestService && canSign && (
                        <>
                          <Button
                            onClick={() => {
                              setSignData(values);
                            }}
                            border
                            primary
                            disabled={!isValid}
                            label="Подписать"
                          />
                          <Push size={12} orientation="horizontal" />
                        </>
                      )}
                      {!service.isTestService && canPublicate && (
                        <>
                          <Button
                            onClick={async () => {
                              const tgId = await createOrUpdate(values);
                              await updateStatus(tgId, TrainingGroupStatusEnum.Signed, !!planData?.id);
                              if (!planData?.id) {
                                history.push(
                                  `${generateLink(routes.trainingGroup, {
                                    serviceId: service.id,
                                    id: tgId,
                                  })}?isRetro=${isRetro}&yearId=${values.yearOfTrainingId}`
                                );
                              }
                            }}
                            border
                            disabled={!isValid}
                            primary
                            label={isRetro ? 'Подтвердить' : 'Опубликовать'}
                          />
                          <Push size={12} orientation="horizontal" />
                        </>
                      )}
                      <Button
                        onClick={() => {
                          submit(values);
                        }}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </>
                  }
                />
              )}
            </form>
          );
        }}
      </Formik>
      <SimpleModal
        show={showEditModal}
        handleSubmit={handleCloseEditModal}
        onClose={() => setShowEditModal(false)}
        title="Снятие с публикации на Mos.ru"
        description="Внимание! После подтверждения план приема будет снят с публикации на Mos.ru."
      />
      <SignModal
        show={!!signData}
        onCloseHandle={() => {
          setSignData(undefined);
        }}
        ids={[{ id: 0 }]}
        getDataForSignHandle={async () => JSON.stringify(planData)}
        setSignedDataHandle={async (id, sign) => {
          if (signData) {
            try {
              const tgId = await signTg(signData, sign);
              setSignData(undefined);
              setEditMode(false);
              if (signData.id > 0) {
                getTraininig();
              } else {
                history.push(
                  `${generateLink(routes.trainingGroup, {
                    serviceId: service.id,
                    id: tgId,
                  })}?isRetro=${isRetro}&yearId=${signData.yearOfTrainingId}`
                );
              }
            } catch (ex) {
              console.error(ex);
            }
          }
        }}
      />
    </>
  ) : null;
};

export default TrainingGroupBody;

const getValidationSchema = (needPublication: boolean) => {
  const depsMosDate: [string, string] = ['requestStart', 'requestEnd'];
  const needPublicationData = needPublication;
  const dateValidation = objectYup().shape(
    {
      requestStart: validationCheckDate(
        'Выберите дату начала приема заявлений',
        { start: 'requestStart', end: 'requestEnd' },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'start'
      ),
      requestEnd: validationCheckDate(
        'Выберите дату окончания приема заявлений',
        { start: 'requestStart', end: 'requestEnd' },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'end'
      ),
      requestTimeStart: stringYup().nullable().required('Введите время начала'),
      requestTimeEnd: stringYup().nullable().required('Введите время окончания'),
    },
    [depsMosDate]
  );

  const validation: any = {
    educationStartDate: dateYup().nullable().required('Выберите дату'),
    volume: number()
      .nullable()
      .required('Введите количество мест')
      .test('volume', 'Введите количество мест', (value) => value !== 0),
    serviceStageId: number().required('Выберите этап обучения'),
  };
  if (needPublicationData) {
    validation.scheduleList = arrayYup().of(dateValidation).required();
  }

  return objectYup().shape(validation);
};
