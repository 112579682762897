import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  DatePickerComponent,
  FormGroup,
  Select,
  SelectOptionType,
  Panel,
  Loader,
  Push,
  AsyncTable as Table,
  OrderByType,
} from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../mock-data';
import { HistoryData } from '../../types/history';
import { EntityTypeEnum } from '../../mock-data/entity-type-enum';
import securityApi from '../../lib/api/security';
import { maxRegistryRows } from '../../config/constants';
import { sliceText } from '../../lib/utils';

type Props = {
  name: string;
  extendEntityGuid?: string;
  entityTypes: EntityTypeEnum[];
  title?: string;
  defaultLoading?: boolean;
};

const ChangeHistory = ({ extendEntityGuid, entityTypes, name, title, defaultLoading = false }: Props) => {
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0].value);
  const [pageNum, setPageNum] = useState(0);
  const [searchData, setSearchData] = useState<{
    date: Date | [Date, Date | null] | null;
    action: number | undefined;
    field: string | undefined;
  }>({ date: null, action: undefined, field: undefined });
  const [actionOptions, setActionOptions] = useState<SelectOptionType[]>([{ label: 'Все', value: 0 }]);
  const [fieldOptions, setFieldOptions] = useState<SelectOptionType[]>([{ label: 'Все', value: 0 }]);
  const [tableData, setTableData] = useState<HistoryData[]>([]);
  const [tableTotal, setTableTotal] = useState(0);
  const [orderBy, setOrderBy] = useState<OrderByType>({ field: null, direction: 'asc' });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      for (const entityType of entityTypes) {
        const actions = await securityApi.getActions(entityType);
        setActionOptions((prev) => prev.concat(actions));
      }
      const fields = await securityApi.getFields(entityTypes);
      setFieldOptions((prev) => prev.concat(fields));
    };
    fetch();
  }, [entityTypes]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      if (extendEntityGuid) {
        const data = await securityApi.getHistory({
          actionType: searchData.action || undefined,
          dateFrom: new Date(
            moment(Array.isArray(searchData.date) ? searchData.date[0] : searchData.date).format('yyyy-MM-DD')
          ),
          dateTo: new Date(
            moment(Array.isArray(searchData.date) ? searchData.date[1] : searchData.date).format('yyyy-MM-DD')
          ),
          extendEntityGuids: [extendEntityGuid],
          fieldName: searchData.field || undefined,
          pageSize,
          pageNumber: pageNum + 1,
          orderBy: orderBy.field,
        });
        setTableTotal(data.total);
        setTableData(data.items);
        setLoading(false);
      }
    };
    fetch();
  }, [searchData, pageNum, pageSize, extendEntityGuid, orderBy]);

  const getCurrentValue = (type: string) => {
    return type === 'action'
      ? actionOptions.find((item) => item.value === searchData.action) || {
          value: 0,
          label: 'Все',
        }
      : fieldOptions.find((item) => item.value === searchData.field) || {
          value: 0,
          label: 'Все',
        };
  };

  const editDescription = (item: string) => {
    const str = item
      .split('\r\n')
      .filter((s) => s)
      .map((s) => `<div>${s.trim()}</div>`)
      .join(' ');
    return { __html: `${str}` };
  };

  return (
    <>
      <Push size={12} />
      <Panel>
        <Push size={20} />
        <div className="container">
          <div className="teacher-history-input-grid">
            <FormGroup label="Дата или интервал дат">
              <DatePickerComponent
                name="searchData"
                size="small"
                rangePicker
                onChange={(date) => setSearchData({ ...searchData, date })}
                placeholder="ДД.ММ.ГГГГ—ДД.ММ.ГГГГ"
              />
            </FormGroup>
            <FormGroup label="Действие">
              <Select
                name="action"
                size="small"
                isSearchable
                value={getCurrentValue('action')}
                options={actionOptions}
                onChange={(selectedOption) => {
                  const { value } = selectedOption as SelectOptionType;
                  setSearchData({ ...searchData, action: value as number });
                }}
                placeholder="Выберите действие..."
              />
            </FormGroup>
            <FormGroup label="Поле">
              <Select
                name="field"
                size="small"
                isSearchable
                value={getCurrentValue('field')}
                options={fieldOptions}
                onChange={(selectedOption) => {
                  const { value } = selectedOption as SelectOptionType;
                  setSearchData({ ...searchData, field: value as string });
                }}
                placeholder="Выберите поле..."
              />
            </FormGroup>
          </div>
        </div>
        <Push size={20} />
      </Panel>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            {title || 'Записей'}
            <span className="color-gray-dark">
              {' \u00A0'}
              {tableTotal}
            </span>
          </>
        )}
        headingControl={() => (
          <div style={{ width: 184 }}>
            <Select
              name="pageSize"
              isSearchable={false}
              hideClearIndicator
              value={pageSizeOptions.find((item) => item.value === pageSize)}
              onChange={(selectedOption) => {
                const { value } = selectedOption as SelectOptionType;
                setPageSize(value as number);
                setPageNum(0);
              }}
              options={pageSizeOptions}
              size="small"
            />
          </div>
        )}
      >
        {/* 
        defaultLoading - нужен чтобы в группах обучения, в истории изменений
        скролл не багался, когда мы изменяем pageNumber 
        */}
        {loading && !defaultLoading ? (
          <div className="loader-container">
            <Loader title={`Загружаем ${name}`} description="Нужно немного подождать" />
          </div>
        ) : (
          <Table
            scrollUp
            pageNum={pageNum}
            setPageNumber={setPageNum}
            overflow
            hideSort
            showPagination
            rowLimit={maxRegistryRows}
            pageSize={pageSize}
            data={tableData}
            itemsAll={tableTotal}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            columns={[
              {
                width: '220px',
                dataIndex: 'dateTime',
                title: 'Дата и время',
                render: (item: any) => moment(item.dateTime).format('DD.MM.yyyy HH:mm'),
              },
              {
                width: '220px',
                dataIndex: 'user',
                title: 'Пользователь',
                render: (item: any) => item.user,
              },
              {
                width: '160px',
                dataIndex: 'action',
                title: 'Действие',
                render: (item: any) => item.action,
              },
              {
                dataIndex: 'description',
                title: 'Комментарий',
                render: (item: any) => <div dangerouslySetInnerHTML={editDescription(sliceText(item.description))} />,
              },
            ]}
          />
        )}
      </Panel>
    </>
  );
};

export default ChangeHistory;
