import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, ButtonGroup, Push } from '@mosru/esz_uikit';
import { RequestContext } from '../../../lib/utils/requests';
import Details from './details';
import ChangeHistory from '../../../components/change-history';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { RequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { generateLink, getServiceEditLink, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { generalAccess } from '../../../types/authorization-data';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { serviceTypeEnum } from '../../../mock-data/print';
import { EducationTypeEnum } from '../../../types/education-type';

type DigitalTutorRequestProps = {
  requestData: RequestData;
  updateRequest: () => void;
};

const DigitalTutorRequest = ({ requestData, updateRequest }: DigitalTutorRequestProps) => {
  const [activeTab, setActiveTab] = useState('Основные сведения');

  const { routeBack } = useContext(RequestContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const adminView: boolean = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const editServiceVA = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Edit),
    [userProfile]
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <Details requestData={requestData} updateRequest={updateRequest} />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name="сведения о заявлении"
            extendEntityGuid={requestData?.extendEntityGuid}
            entityTypes={[EntityTypeEnum.Request]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [requestData, updateRequest]
  );

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(requestData.dateRequest)}
              <Push size={20} orientation="horizontal" />
              Источник: {requestData.requestSourceName}
            </div>
            <Push size={12} />
          </>
        }
        buttonBack
        routeBack={routeBack}
        expansion={
          <div className="flex items-center">
            <Favorite
              entityId={requestData.id}
              typeEntity="заявление"
              currentEntityType={`№${requestData.requestNumber || ''}`}
              entityTypeId={EntityTypeEnum.Request}
            />
            <Push size={20} orientation="horizontal" />
            <Badge text={requestData.requestStatusName} {...getRequestStatusColor(requestData.requestStatusId)} />
          </div>
        }
        title={`Заявление №${requestData.requestNumber || ''}`}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Сведения о заявлении' },
        ]}
        activeTab={activeTab}
        tabs={tabs}
        controlsTopRight={
          <ButtonGroup>
            {(adminView || editServiceVA) && (
              <Button
                label="Вернуться к ОУ"
                size="small"
                handleClick={() => {
                  window.open(
                    getServiceEditLink(
                      EducationTypeEnum.VirtualAssistantEducation,
                      requestData.trainingGroup.serviceId
                    ),
                    '_blank'
                  );
                }}
              />
            )}
            <Button
              handleClick={() =>
                window.open(
                  generateLink(routes.printRequest, { type: serviceTypeEnum.digitalTutor, id: requestData.id }),
                  '_blank'
                )
              }
              label="Печать"
              iconLeft={() => <IconPrinter />}
              size="small"
            />
          </ButtonGroup>
        }
      />

      {tabs.find((item) => item.label === activeTab)?.component}
    </>
  );
};

export default DigitalTutorRequest;
