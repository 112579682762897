import React, { useContext, useState } from 'react';
import { Button, Push } from '@mosru/esz_uikit';
import Expulsion from '../../components/modals/expulsion';
import Recovery from '../../components/modals/recovery';
import Change from '../../components/modals/change';
import Union from '../../components/modals/union';
import TransferGroup from '../../components/modals/transfer-group';
import { ServiceClassContext } from '../../service-class';
import { ServiceClassTableContext } from '../../utils';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, openDetails, active } = useContext(ServiceClassTableContext);
  const { adminView, adminEdit, accessRecover } = useContext(ServiceClassContext);

  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);

  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  return (
    <>
      {' '}
      {openDetails && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  default
                  handleClick={() => {
                    setSelected([]);
                    setOpenDetails(false);
                  }}
                  label="Отменить выбор"
                />
                {active ? (
                  <>
                    <Push size={12} orientation="horizontal" />
                    <Button
                      danger
                      border
                      label="Отчислить"
                      handleClick={() => {
                        setOpenExpulsionPopup(true);
                      }}
                    />
                    <Push size={12} orientation="horizontal" />
                    {(adminView || adminEdit) && (
                      <>
                        <Button
                          border
                          primary
                          label="Изменить даты обучения"
                          handleClick={() => setOpenChangeModal(true)}
                        />
                        <Push size={12} orientation="horizontal" />
                      </>
                    )}
                    <Button
                      border
                      primary
                      label="Перевести в другую группу"
                      handleClick={() => setOpenTransferGroupPopup(true)}
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button handleClick={() => setOpenUnionPopup(true)} primary border label="Объединить в подгруппу" />
                  </>
                ) : (
                  accessRecover && (
                    <>
                      <Push size={12} orientation="horizontal" />
                      <Button handleClick={() => setOpenRecoveryPopup(true)} primary border label="Восстановить" />
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Expulsion open={openExpulsionPopup} close={() => setOpenExpulsionPopup(false)} />
      <Recovery open={openRecoveryPopup} close={() => setOpenRecoveryPopup(false)} />
      <Change open={openChangeModal} close={() => setOpenChangeModal(false)} />
      <Union open={openUnionPopup} close={() => setOpenUnionPopup(false)} />
      <TransferGroup open={openTransferGroupPopup} close={() => setOpenTransferGroupPopup(false)} />
    </>
  );
};

export default PanelButtons;
