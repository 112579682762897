import React, { useState } from 'react';
import { Button, FormGroup, Input, notify } from '@mosru/esz_uikit';
import { notificationMessages } from '../../../../lib/utils/learners';
import { SplitModalListElement } from '../../../../types/learners';
import { allowOnlyNumbersNoSpace, preventInvalidPaste } from '../../../../lib/utils/validation';

type Props = {
  disabled?: boolean;
  data?: SplitModalListElement[];
  comparisonData?: SplitModalListElement[];
  onSelect: (value: string) => Promise<void>;
  labelId: string;
};

const MAX_VALUE = 2147483647;

const InputGroup = ({ onSelect, disabled, data, comparisonData, labelId }: Props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState<string>();
  const [randomKey, setRandomFieldKey] = useState(0);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const checkingMatch = comparisonData?.every((item) => item.id !== Number(value));
  const isUniqueValue = !data || data.every((item) => item.id !== Number(value));
  const disableBtn = !value || disabled || !!error || !isUniqueValue;

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    setError(value && Number(value) >= MAX_VALUE ? 'Введено недопустимое значение' : undefined);
  };

  const reset = () => {
    setValue('');
    setRandomFieldKey(Math.random());
  };

  const handleSelect = async () => {
    if (checkingMatch) {
      try {
        setIsLoadingBtn(true);
        await onSelect(value);
      } finally {
        reset();
        setIsLoadingBtn(false);
      }
    } else {
      notify.danger({
        data: {
          label: notificationMessages.mergeError,
          icon: true,
          close: true,
        },
      });
      reset();
    }
  };

  return (
    <div className="learners-form-group">
      <div className="learners-form-group__input" onPaste={(e) => preventInvalidPaste(e, /[^\d]/g)}>
        <FormGroup label="" labelId={labelId} error={error}>
          <Input
            key={randomKey}
            labelId={labelId}
            size="small"
            value={value}
            error={error}
            onKeyPress={(e) => allowOnlyNumbersNoSpace(e, true)}
            onChange={handleChangeValue}
            placeholder="Введите ID личного дела..."
          />
        </FormGroup>
      </div>
      <div className="learners-form-group__btn">
        <Button load={isLoadingBtn} disabled={disableBtn} onClick={handleSelect} primary size="small" label="Выбрать" />
      </div>
    </div>
  );
};

export default InputGroup;
