import React, { useEffect, useState } from 'react';
import { Button, SelectOptionType, TreeNodeType, Loader, Push } from '@mosru/esz_uikit';
import { Key } from 'rc-tree/lib/interface';
import { ReactComponent as IconClose } from '../../../assets/images/icons/close.svg';
import Search from './search';
import SelectCategory from './select-category';
import TreeElement from './tree-element';
import { classificatorApi } from '../../../lib/api/classificator';
import { transformClassificatorSearch } from '../../../lib/utils/learners';
import { mockCategoryTreeModal } from '../helpers';
import { TreeModalContext } from '../index';

const Content = () => {
  const {
    title,
    selected,
    closePopup,
    educationId,
    dataTreePopup,
    selectOptions,
    numberElements,
    setDataTreePopup,
    setValueBasicDirection,
    currentMenuClosePopup,
    organizationId,
    showAmount,
  } = React.useContext(TreeModalContext);

  const [initialData, setInitialData] = useState<TreeNodeType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [currentCategory, setCurrentCategory] = useState<SelectOptionType | undefined>(findMenu(currentMenuClosePopup));

  const [edId, setEdId] = useState<number | null>(null);
  const [basicDirection, setBasicDirection] = useState<SelectOptionType | null>(null);

  const [keyRandom, setKeyRandom] = useState<number>(0);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  function findMenu(currentMenu?: SelectOptionType) {
    if (selectOptions && currentMenuClosePopup) {
      if (currentMenuClosePopup.value) {
        return selectOptions.find((item: SelectOptionType) => item.value === currentMenu?.value);
      } else {
        return selectOptions[0];
      }
    } else if (selectOptions) {
      return selectOptions[0];
    } else {
      return mockCategoryTreeModal[0];
    }
  }

  useEffect(() => {
    if (educationId) {
      setEdId(educationId);
    } else {
      setEdId(currentCategory?.value as number);
    }
  }, [currentCategory, educationId]);

  useEffect(() => {
    // делаем запрос от выбранной категории
    (async () => {
      setLoading(false);
      const edType = educationId || currentCategory?.value;

      if (numberElements) {
        setDataTreePopup(dataTreePopup);
        setInitialData(dataTreePopup);
        setLoading(true);
      } else if (edType) {
        const response = await classificatorApi.search({
          edType: educationId || currentCategory?.value,
          orgId: organizationId,
        });
        setInitialData(transformClassificatorSearch(response, showAmount));
        setDataTreePopup(transformClassificatorSearch(response, showAmount));
        setKeyRandom(Math.random());
        setLoading(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory?.value]);

  const save = () => {
    setValueBasicDirection(basicDirection as SelectOptionType);
    closePopup(false);
  };

  return (
    <div className="modal-right">
      <div className="learners-modal-right">
        <div className="learners-modal-right__head">
          <div className="flex">
            <div className="font-weight-bold font-size-base">{title}</div>
            <button type="button" onClick={() => closePopup(false)} className="learners-modal-right__head-close">
              <IconClose />
            </button>
          </div>
          <Push size={4} />
          <div className="color-gray-dark font-size-small">
            Направленность {'>'} профиль {'>'} вид деятельности
          </div>

          {selected ? (
            <>
              <Push size={16} />
              <SelectCategory
                selectOptions={selectOptions}
                currentCategory={currentCategory}
                setDataTreePopup={setDataTreePopup}
                setCurrentCategory={setCurrentCategory}
              />
            </>
          ) : null}
        </div>

        <div className="learners-modal-right__body">
          <div className="learners-modal-right__body-inner">
            <Search
              setLoading={setLoading}
              setKeyRandom={setKeyRandom}
              setInitialData={setInitialData}
              setSelectedKeys={setSelectedKeys}
              setExpandedKeys={setExpandedKeys}
            />

            <Push size={16} />

            {!loading ? (
              <span className="loader">
                <Loader small roller />
              </span>
            ) : (
              <TreeElement
                educationId={edId}
                keyRandom={keyRandom}
                initialData={initialData}
                selectedKeys={selectedKeys}
                setBasicDirectionValue={setBasicDirection}
                organizationId={organizationId}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
              />
            )}
          </div>
        </div>

        <div className="learners-modal-right__controls">
          <Button label="Отмена" onClick={() => closePopup(false)} border primary size="small" />
          <Push size={12} orientation="horizontal" />
          <Button onClick={save} label="Сохранить" primary size="small" />
        </div>
      </div>
    </div>
  );
};

export default Content;
