import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { apiService } from '../../config/constants';
import api from '../../lib/api/api';
import axiosBaseQuery from '../../lib/api/axios-base-query';
import { AdvertisementViewData, ChangeLogData, DocListType, FaqType } from '../../types/static-content';

export const staticContentApi = createApi({
  reducerPath: 'staticContentApi',
  baseQuery: axiosBaseQuery({ baseURL: `${apiService.data}/StaticContent/` }),
  tagTypes: ['Advertisements'],
  endpoints: (builder) => ({
    getFaq: builder.query<FaqType[], void>({
      query: () => {
        return api.GET({
          url: 'Faq',
        });
      },
    }),
    getUserManual: builder.query<DocListType[], void>({
      query: () => {
        return api.GET({
          url: 'UserManual',
        });
      },
      transformResponse: (response) =>
        response.map((item: any) => ({
          title: item.fileUserName,
          link: encodeURI(item.link),
          isIconFile: !item.link.match(/http/),
        })),
    }),
    getNormativeDocument: builder.query<DocListType[], void>({
      query: () => {
        return api.GET({
          url: 'NormativeDocument',
        });
      },
      transformResponse: (response) =>
        response.map((item: any) => ({
          link: item.link,
          title: item.nameDocument,
        })),
    }),
    getAdvertisements: builder.query<AdvertisementViewData[], void>({
      query: () => {
        return api.GET({
          url: 'AdvertisementList',
        });
      },
      providesTags: ['Advertisements'],
    }),
    getChangeLog: builder.query<ChangeLogData, void>({
      query: () => {
        return api.GET({
          url: 'ChangeLog',
        });
      },
    }),
    changeAvdState: builder.mutation<boolean, number>({
      query: (adId: number) => {
        return api.PATCH({
          url: `Advertisement/${adId}`,
        });
      },
      invalidatesTags: ['Advertisements'],
    }),
  }),
});

export const {
  useGetFaqQuery,
  useGetUserManualQuery,
  useGetNormativeDocumentQuery,
  useGetAdvertisementsQuery,
  useGetChangeLogQuery,
  useChangeAvdStateMutation,
} = staticContentApi;
