import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, DatePickerComponent, FormGroup, Input, Panel, Toggle, Push } from '@mosru/esz_uikit';
import { timeMask, timeMaskFunction } from '../../lib/utils/mask';
import { ReactComponent as IconClock } from '../../assets/images/icons/clock-color.svg';
import { ReactComponent as IconPlus } from '../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconRemove } from '../../assets/images/icons/remove.svg';
import { scheduleMockData } from '../../lib/utils/time-table';
import { weekday } from '../shedule-modal/helpers';
import { formatTimeWithSeconds, getDate } from '../../lib/utils/date';
import { EducationTypeEnum } from '../../types/education-type';

type EditScheduleProps = {
  title: string | ReactNode;
  extendedPeriods: PeriodType[];
  submit: boolean;
  submitError: () => void;
  submitSuccess: (schedule: PeriodType[]) => void;
  setCurrentErrorSchedule?: (value: boolean) => void;
  dateStartProps?: Date | null;
  dateEndProps?: Date | null;
  educationType?: EducationTypeEnum;
  timeRoundingUp?: boolean;
};

export type PeriodType = {
  id: number;
  dateEnd: Date | null;
  dateStart: Date | null;
  shedulePeriod?: number;
  schedule: ScheduleType[];
  dateOverlapError: string | null;
  dateInvalidError: string | null;
  dateOrderError: string | null;
  touched?: boolean;
};

export type ScheduleType = {
  id: number;
  name: string;
  from: string;
  to: string;
  dayOff: boolean;
  intervals?: ScheduleIntervalType[];
  touched?: boolean;
};

type ScheduleIntervalType = {
  id: number;
  from: string;
  to: string;
  intervalOverlapError: string | null;
  touched?: boolean;
};

const dateOverlapError =
  'Даты периодов не могут пересекаться и должны быть ограничены датами начала и окончания занятий данной группы обучения';
const intervalOverlapError = 'Временные интервалы не могут пересекаться';
export const dateInvalidError = 'Период не заполнен или заполнен не верно';
export const dateOrderError = 'Даты периодов должны быть указаны последовательно';

const EditPeriodSchedule: React.FC<EditScheduleProps> = ({
  title,
  submit,
  submitError,
  submitSuccess,
  extendedPeriods,
  setCurrentErrorSchedule,
  dateStartProps = null,
  dateEndProps = null,
  educationType,
  timeRoundingUp,
}) => {
  const [periods, setPeriods] = useState<PeriodType[]>(extendedPeriods);

  useEffect(() => {
    if (
      submitValidateTime(periods) ||
      submitValidateDate(periods) ||
      submitValidateIntervals(periods) ||
      !validateNullCheck(periods) ||
      validatePeriod(periods)
    ) {
      setCurrentErrorSchedule && setCurrentErrorSchedule(true);
    } else {
      setCurrentErrorSchedule && setCurrentErrorSchedule(false);
    }
    if (submit) {
      if (submitValidateTime(periods) || submitValidateDate(periods) || submitValidateIntervals(periods)) {
        submitError();
      } else {
        submitSuccess(periods);
      }
    }
  }, [periods, submit, submitError, submitSuccess, setCurrentErrorSchedule]);

  return (
    <>
      {periods.map((periodItem, index) => (
        <Fragment key={periodItem.id}>
          {index !== 0 && <Push size={12} />}
          <Panel
            {...(index === 0 && {
              title: () => (typeof title === 'function' ? title() : typeof title === 'string' ? title : undefined),
            })}
            {...(index === 0 && {
              headingControl: () =>
                periods.length < 5 && (
                  <button
                    type="button"
                    onClick={() => {
                      setPeriods([
                        ...periods,
                        {
                          schedule: scheduleMockData(
                            educationType === EducationTypeEnum.VirtualAssistantEducation ? 'full' : 'days'
                          ).map((mock, index) => ({
                            id: index,
                            name: mock.name,
                            dayOff: true,
                            intervals: [],
                            from: '',
                            to: '',
                          })),
                          dateInvalidError,
                          id: periods.length,
                          shedulePeriod: periods.length,
                        } as unknown as PeriodType,
                      ]);
                    }}
                    className="icon-group"
                    disabled={periods.length >= 5}
                  >
                    <span className="icon-group__icon">
                      <IconPlus />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Добавить период</span>
                  </button>
                ),
            })}
          >
            {index !== 0 && <Push size={20} />}
            <div className="container">
              <div className="flex justify-between items-start">
                <FormGroup
                  label={`Начало и окончание периода ${index ? index + 1 : ''}`}
                  error={
                    (periodItem.touched &&
                      (periodItem.dateOverlapError || periodItem.dateInvalidError || periodItem.dateOrderError)) ||
                    undefined
                  }
                  required
                >
                  <div style={{ width: 240 }}>
                    <DatePickerComponent
                      {...(index === 0 && { key: periods.length })}
                      name="periods"
                      size="small"
                      rangePicker
                      error={
                        (periodItem.touched &&
                          (periodItem.dateOverlapError || periodItem.dateInvalidError || periodItem.dateOrderError)) ||
                        undefined
                      }
                      onChange={(date) => {
                        const newPeriods = changeDate(
                          date as [Date, Date | null] | null,
                          dateStartProps,
                          dateEndProps,
                          periods,
                          periodItem.id
                        );
                        setPeriods(newPeriods);
                      }}
                      placeholder="ДД.ММ.ГГГГ—ДД.ММ.ГГГГ"
                      minDate={dateStartProps || undefined}
                      maxDate={dateEndProps || undefined}
                      startDate={periodItem.dateStart ? getDate(periodItem.dateStart) : undefined}
                      endDate={periodItem.dateEnd ? getDate(periodItem.dateEnd) : undefined}
                      // startDate={periods.length === 1 ? dateStartProps || undefined : periodItem.dateStart || undefined}
                      // endDate={periods.length === 1 ? dateEndProps || undefined : periodItem.dateEnd || undefined}
                      // disabled={periods.length === 1}
                    />
                  </div>
                </FormGroup>
                {index !== 0 && (
                  <Button
                    iconLeft={() => <IconRemove />}
                    border
                    size="small"
                    handleClick={() => {
                      const filteredPeriods = periods.filter((item) => item.id !== periodItem.id);
                      setPeriods(checkOrderDates(checkOverlapDates(filteredPeriods, dateStartProps, dateEndProps)));
                    }}
                  />
                )}
              </div>
            </div>
            <Push size={20} />
            {periodItem.schedule.map((currentDay) => (
              <Fragment key={currentDay.name}>
                <div className="table-data-striped__item items-center">
                  <div className="table-data-striped__label table-data-striped__label--edit">
                    <div className="flex items-center">
                      <Toggle
                        labelId={currentDay.name}
                        size="xsmall"
                        checked={!currentDay.dayOff}
                        onChange={(checked) => {
                          setPeriods(toggleDay(periods, periodItem.id, currentDay.id, checked));
                        }}
                      />
                      <Push size={12} orientation="horizontal" />
                      <label htmlFor={currentDay.name}>{currentDay.name}</label>
                    </div>
                  </div>
                  <div className="table-data-striped__body">
                    {currentDay.dayOff ? (
                      <span className="flex items-center color-gray-dark" style={{ minHeight: '36px' }}>
                        нерабочий
                      </span>
                    ) : (
                      <div className="flex items-center">
                        <div className="org-input-time">
                          <Input
                            mask={(value) => timeMaskFunction(value)}
                            size="small"
                            error={
                              currentDay.touched &&
                              ((!currentDay.dayOff && !timeMask.test(currentDay.from)) ||
                                validateTime(currentDay.from, currentDay.to))
                                ? 'error'
                                : ''
                            }
                            value={currentDay.from}
                            placeholder="00:00"
                            onChange={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'from',
                                  false,
                                  periodItem.id,
                                  currentDay.id,
                                  undefined,
                                  timeRoundingUp
                                )
                              );
                            }}
                            onBlur={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'from',
                                  true,
                                  periodItem.id,
                                  currentDay.id,
                                  undefined,
                                  timeRoundingUp
                                )
                              );
                            }}
                            iconRight={() => <IconClock />}
                          />
                        </div>
                        <Push size={8} orientation="horizontal" />
                        —
                        <Push size={8} orientation="horizontal" />
                        <div className="org-input-time">
                          <Input
                            mask={(value) => timeMaskFunction(value)}
                            size="small"
                            error={
                              currentDay.touched &&
                              ((!currentDay.dayOff && !timeMask.test(currentDay.to)) ||
                                validateTime(currentDay.from, currentDay.to))
                                ? 'error'
                                : ''
                            }
                            value={currentDay.to}
                            placeholder="00:00"
                            onChange={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'to',
                                  false,
                                  periodItem.id,
                                  currentDay.id,
                                  undefined,
                                  timeRoundingUp
                                )
                              );
                            }}
                            onBlur={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'to',
                                  true,
                                  periodItem.id,
                                  currentDay.id,
                                  undefined,
                                  timeRoundingUp
                                )
                              );
                            }}
                            iconRight={() => <IconClock />}
                          />
                        </div>
                        <Push size={12} orientation="horizontal" />
                        {(currentDay.intervals ? currentDay.intervals.length < 4 : true) && (
                          <button
                            type="button"
                            onClick={() => {
                              setPeriods(addInterval(periods, periodItem.id, currentDay.id));
                            }}
                            className="icon-group"
                          >
                            <span className="icon-group__icon">
                              <IconPlus />
                            </span>
                            <span className="icon-group__text font-weight-bold color-primary">Добавить интервал</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {currentDay.intervals?.map((interval) => (
                  <div key={interval.id} className="table-data-striped__item items-center">
                    <div className="table-data-striped__label table-data-striped__label--edit" />
                    <div className="table-data-striped__body">
                      <div className="flex items-center">
                        <div className="org-input-time">
                          <Input
                            mask={(value) => timeMaskFunction(value)}
                            size="small"
                            value={interval.from}
                            error={
                              interval.touched &&
                              (interval.intervalOverlapError ||
                                (!currentDay.dayOff && !timeMask.test(interval.from)) ||
                                validateTime(interval.from, interval.to))
                                ? 'error'
                                : ''
                            }
                            placeholder="00:00"
                            iconRight={() => <IconClock />}
                            onChange={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'from',
                                  false,
                                  periodItem.id,
                                  currentDay.id,
                                  interval.id,
                                  timeRoundingUp
                                )
                              );
                            }}
                            onBlur={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'from',
                                  true,
                                  periodItem.id,
                                  currentDay.id,
                                  interval.id,
                                  timeRoundingUp
                                )
                              );
                            }}
                          />
                        </div>
                        <Push size={8} orientation="horizontal" />
                        —
                        <Push size={8} orientation="horizontal" />
                        <div className="org-input-time">
                          <Input
                            mask={(value) => timeMaskFunction(value)}
                            size="small"
                            value={interval.to}
                            error={
                              interval.touched &&
                              (interval.intervalOverlapError ||
                                (!currentDay.dayOff && !timeMask.test(interval.to)) ||
                                validateTime(interval.from, interval.to))
                                ? 'error'
                                : ''
                            }
                            placeholder="00:00"
                            iconRight={() => <IconClock />}
                            onChange={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'to',
                                  false,
                                  periodItem.id,
                                  currentDay.id,
                                  interval.id,
                                  timeRoundingUp
                                )
                              );
                            }}
                            onBlur={({ target }) => {
                              setPeriods(
                                changeInputValue(
                                  periods,
                                  target.value,
                                  'to',
                                  true,
                                  periodItem.id,
                                  currentDay.id,
                                  interval.id,
                                  timeRoundingUp
                                )
                              );
                            }}
                          />
                        </div>
                        <Push size={12} orientation="horizontal" />
                        <Button
                          iconLeft={() => <IconRemove />}
                          border
                          size="small"
                          handleClick={() => {
                            setPeriods(removeInterval(periods, periodItem.id, currentDay.id, interval.id));
                          }}
                        />
                        <Push size={12} orientation="horizontal" />
                        <div className="color-danger">{interval.intervalOverlapError}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            ))}
          </Panel>
        </Fragment>
      ))}
    </>
  );
};

export default EditPeriodSchedule;

const roundingUpTime = (str: string, timeRoundingUp?: boolean) => {
  const splitAr = str.split(':');
  if (splitAr.length === 2) {
    if (splitAr[0] && splitAr[1] && timeRoundingUp) {
      const minutes = parseInt(splitAr[1], 10);
      let round = '00';
      if (minutes > 0 && minutes <= 15) {
        round = '15';
      } else if (minutes > 15 && minutes <= 30) {
        round = '30';
      } else if (minutes > 30 && minutes <= 45) {
        round = '45';
      }
      return `${splitAr[0]}:${round}`;
    }
    if (splitAr[0] && !splitAr[1].trim()) {
      return `${splitAr[0]}:00`;
    }
    if (splitAr[0] && splitAr[1].trim().length === 1) {
      return `${splitAr[0]}:${splitAr[1].trim()}0`;
    }
  }
  return str;
};

function changeDate(
  date: [Date, Date | null] | null,
  dateStartProps: Date | null,
  dateEndProps: Date | null,
  periods: PeriodType[],
  currentId: number
) {
  const periodsWithDate = periods.map((item): PeriodType => {
    if (item.id === currentId) {
      return {
        ...item,
        dateStart: (date && date[0]) || null,
        dateEnd: (date && date[1]) || null,
        touched: true,
      };
    } else {
      return item;
    }
  });
  const periodsWithInvalidError = validateDate(periodsWithDate);
  const periodsWithOverlapError = checkOverlapDates(periodsWithInvalidError, dateStartProps, dateEndProps);
  return checkOrderDates(periodsWithOverlapError);
}

function submitValidateDate(periods: PeriodType[]) {
  return periods.some(
    (periodItem) => periodItem.dateOverlapError || periodItem.dateInvalidError || periodItem.dateOrderError
  );
}

function submitValidateIntervals(periods: PeriodType[]) {
  return periods.some((periodItem) =>
    periodItem.schedule.some((currentDay) => currentDay.intervals?.some((interval) => interval.intervalOverlapError))
  );
}

function validatePeriod(periods: PeriodType[]) {
  return periods.some((item) => !item.dateStart || !item.dateEnd);
}

function validateNullCheck(periods: PeriodType[]) {
  return periods.every((periodItem: PeriodType) => periodItem.schedule.some((currentDay) => !currentDay.dayOff));
}

function validateDate(periods: PeriodType[]) {
  return periods.map((periodItem) => {
    if (!periodItem.dateStart || !periodItem.dateEnd) {
      return { ...periodItem, dateInvalidError };
    } else {
      return { ...periodItem, dateInvalidError: null };
    }
  });
}

function submitValidateTime(periods: PeriodType[]) {
  return periods.some((periodItem) =>
    periodItem.schedule.some((item) => {
      const validateDay =
        !item.dayOff && (!timeMask.test(item.from) || !timeMask.test(item.to) || validateTime(item.from, item.to));
      const validateIntervals = item.intervals?.some(
        (intervalItem) =>
          !item.dayOff &&
          (!timeMask.test(intervalItem.from) ||
            !timeMask.test(intervalItem.to) ||
            validateTime(intervalItem.from, intervalItem.to))
      );
      return validateDay || validateIntervals;
    })
  );
}

function checkOrderDates(schedule: PeriodType[]) {
  return schedule.map((currentItem, i) => {
    if (!currentItem.dateStart) {
      return { ...currentItem, dateOrderError: null };
    }
    const { dateStart } = currentItem;
    const checkedPeriods = schedule.slice(0, i);
    const error = checkedPeriods.some((checkedItem) => checkedItem.dateEnd && checkedItem.dateEnd >= dateStart);
    return error ? { ...currentItem, dateOrderError } : { ...currentItem, dateOrderError: null };
  });
}

function checkOverlapDates(schedule: PeriodType[], dateStartProps: Date | null, dateEndProps: Date | null) {
  return schedule.map((currentItem) => {
    const overlap = schedule.some((periodItem) => {
      if (
        dateStartProps &&
        ((currentItem.dateStart && currentItem.dateStart < dateStartProps) ||
          (currentItem.dateEnd && currentItem.dateEnd < dateStartProps))
      ) {
        return true;
      }
      if (
        dateEndProps &&
        ((currentItem.dateStart && currentItem.dateStart > dateEndProps) ||
          (currentItem.dateEnd && currentItem.dateEnd > dateEndProps))
      ) {
        return true;
      }
      if (periodItem.id === currentItem.id) {
        return false;
      }
      if (currentItem.dateStart && currentItem.dateEnd && periodItem.dateStart && periodItem.dateEnd) {
        return (
          (currentItem.dateStart >= periodItem.dateStart && currentItem.dateStart <= periodItem.dateEnd) ||
          (currentItem.dateEnd >= periodItem.dateStart && currentItem.dateEnd <= periodItem.dateEnd)
        );
      } else {
        return false;
      }
    });
    if (overlap) {
      return { ...currentItem, dateOverlapError };
    } else {
      return { ...currentItem, dateOverlapError: null };
    }
  });
}

function fixHoursFormat(time: string) {
  let [hours, minutes] = time.split(':');
  hours = hours.replace(/\s/g, '');
  hours = hours.padStart(2, '0');
  return `${hours}:${minutes}`;
}

function validateTime(from: string, to: string): boolean {
  return moment(from, 'hh:mm').isAfter(moment(to, 'hh:mm')) || moment(from, 'hh:mm').isSame(moment(to, 'hh:mm'));
}

function changeInputValue(
  schedule: PeriodType[],
  value: string,
  match: string,
  blur: boolean,
  scheduleItemId: number,
  currentDayId: number,
  currentIntervalId?: number,
  timeRoundingUp?: boolean
): PeriodType[] {
  const changedValue = blur && value ? roundingUpTime(fixHoursFormat(value), timeRoundingUp) : value;
  const newSchedule = schedule.map((scheduleItem) => {
    if (scheduleItem.id === scheduleItemId) {
      const schedule = scheduleItem.schedule.map((currentDay) => {
        if (currentDay.id === currentDayId) {
          if (typeof currentIntervalId === 'number') {
            const intervals = currentDay.intervals?.map((currentInterval) => {
              if (currentInterval.id === currentIntervalId) {
                return {
                  ...currentInterval,
                  [match]: changedValue,
                  touched: true,
                };
              } else {
                return currentInterval;
              }
            });
            return {
              ...currentDay,
              intervals,
            };
          } else {
            return {
              ...currentDay,
              [match]: changedValue,
              touched: true,
            };
          }
        } else {
          return currentDay;
        }
      });
      return {
        ...scheduleItem,
        schedule,
      };
    } else {
      return scheduleItem;
    }
  });
  return blur ? checkOverlapIntervals(newSchedule) : newSchedule;
}

function checkOverlapTime(currentFrom: Date, currentTo: Date, checkedFrom: Date, checkedTo: Date) {
  if (currentFrom <= checkedFrom && currentTo >= checkedFrom) {
    return true;
  }
  if (currentFrom >= checkedFrom && currentFrom <= checkedTo) {
    return true;
  }
  return false;
}

function checkOverlapIntervals(schedule: PeriodType[]) {
  return schedule.map((scheduleItem) => {
    const schedule = scheduleItem.schedule.map((currentDay) => {
      if (!currentDay.intervals || !currentDay.intervals.length) {
        return currentDay;
      }
      const dayFrom = currentDay.from ? moment(currentDay.from, 'HH:mm').toDate() : '';
      const dayTo = currentDay.to ? moment(currentDay.to, 'HH:mm').toDate() : '';
      const intervals = currentDay.intervals?.map((interval, i) => {
        const intervalFrom = interval.from ? moment(interval.from, 'HH:mm').toDate() : '';
        const intervalTo = interval.to ? moment(interval.to, 'HH:mm').toDate() : '';
        let error = false;
        if (dayFrom && dayTo && intervalFrom && intervalTo) {
          error = checkOverlapTime(intervalFrom, intervalTo, dayFrom, dayTo);
        }
        if (!error && currentDay.intervals?.length) {
          for (let checkedIndex = 0; checkedIndex < currentDay.intervals.length; checkedIndex++) {
            if (i === checkedIndex) {
              continue;
            }
            const checkedInterval = currentDay.intervals[checkedIndex];
            const checkedIntervalFrom = checkedInterval.from ? moment(checkedInterval.from, 'HH:mm').toDate() : '';
            const checkedIntervalTo = checkedInterval.to ? moment(checkedInterval.to, 'HH:mm').toDate() : '';
            if (checkedIntervalFrom && checkedIntervalTo && intervalFrom && intervalTo) {
              error = checkOverlapTime(intervalFrom, intervalTo, checkedIntervalFrom, checkedIntervalTo);
            }
            if (error) {
              break;
            }
          }
        }
        return { ...interval, intervalOverlapError: error ? intervalOverlapError : null };
      });
      return { ...currentDay, intervals };
    });
    return { ...scheduleItem, schedule };
  });
}

function createScheduleInterval(): ScheduleIntervalType {
  return {
    id: Date.now(),
    from: '',
    to: '',
    intervalOverlapError: null,
    touched: false,
  };
}

function addInterval(schedule: PeriodType[], scheduleItemId: number, currentDayId: number): PeriodType[] {
  return schedule.map((currentItem) => {
    if (currentItem.id === scheduleItemId) {
      const schedule = currentItem.schedule.map((currentItemDay) =>
        currentItemDay.id === currentDayId
          ? {
              ...currentItemDay,
              intervals: currentItemDay.intervals
                ? [...currentItemDay.intervals, createScheduleInterval()]
                : [createScheduleInterval()],
            }
          : currentItemDay
      );
      return {
        ...currentItem,
        schedule,
      };
    } else {
      return currentItem;
    }
  });
}

function removeInterval(
  schedule: PeriodType[],
  scheduleItemId: number,
  currentDayId: number,
  intervalId: number
): PeriodType[] {
  const newSchedule = schedule.map((item) => {
    if (item.id === scheduleItemId) {
      const schedule = item.schedule.map((currentItemDay) => {
        if (currentItemDay.id === currentDayId) {
          const intervals = currentItemDay.intervals?.filter((currentInterval) => currentInterval.id !== intervalId);
          return { ...currentItemDay, intervals };
        } else {
          return currentItemDay;
        }
      });
      return { ...item, schedule };
    } else {
      return item;
    }
  });
  return checkOverlapIntervals(newSchedule);
}

function toggleDay(periods: PeriodType[], periodItemId: number, currentDayId: number, checked: boolean) {
  return periods.map((currentItem) => {
    if (currentItem.id === periodItemId) {
      const schedule = currentItem.schedule.map((item) => {
        if (item.id === currentDayId) {
          return { ...item, dayOff: !checked, ...(!checked && { from: '', to: '', intervals: [] }) };
        } else {
          return item;
        }
      });
      return { ...currentItem, schedule };
    } else {
      return currentItem;
    }
  });
}

export function getIntervals(name: string, schedules: ScheduleType[]) {
  const item = schedules.find((schedule) => schedule.id === weekday.find((day) => day.field === name)?.id);
  let result = !item || item.dayOff ? [] : [{ f: formatTimeWithSeconds(item.from), t: formatTimeWithSeconds(item.to) }];
  if (item?.intervals?.length) {
    result = result.concat(
      item.intervals?.map((interval) => ({
        f: formatTimeWithSeconds(interval.from),
        t: formatTimeWithSeconds(interval.to),
      }))
    );
  }
  return result;
}
