import React from 'react';
import { useFormikContext } from 'formik';
import { Button, Tooltip, Toggle, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove-no-fill.svg';
import FormikInput from '../../../../components/formik/formik-input';
import { BudgetPlaceFormData, RestrictionsBudgetPlacesFormData } from '../../../../types/classificator';
import { MAX_LIMIT_VOLUME, VOLUME_FIELD_WIDTH } from './utils';

type ProfessionTableProps = {
  editMode: boolean;
};

const HEIGHT_FIELD = 'inherit';

const ProfessionTable = ({ editMode }: ProfessionTableProps) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<RestrictionsBudgetPlacesFormData>();

  const getCurrentIndex = (id: number) => values.data.findIndex(({ classificatorEKUId }) => classificatorEKUId === id);

  const errorMessageLimit = (limitVolume: number | string | null, volume: number | null) => {
    const limitNumber = limitVolume && Number(limitVolume);

    return limitNumber
      ? limitNumber > MAX_LIMIT_VOLUME
        ? `Количество бюджетных мест не может быть больше ${MAX_LIMIT_VOLUME}`
        : !!volume && limitNumber < volume && 'Невозможно уменьшить лимит, указанное количество мест уже занято.'
      : limitNumber !== null && 'Укажите количество или снимите отметку о наличии бюджетных мест';
  };

  const handleChangeToggle = (id: number) => (checked: boolean) => {
    const index = getCurrentIndex(id);
    setFieldValue(`data[${index}].hasPlaces`, checked);
    setFieldTouched(`data[${index}].limitVolume`, true);
    setFieldValue(`data[${index}].limitVolume`, checked ? 0 : null);
  };

  const handleDelete = (currentId: number) => () => {
    setFieldValue(
      'data',
      values.data.filter(({ classificatorEKUId }) => classificatorEKUId !== currentId)
    );
  };

  const checkValidationValue = (value: string) => (value.length > 0 && value[0] === '0' ? value.slice(1) : value);

  const filterTable = (optionsData: BudgetPlaceFormData[]) => {
    return optionsData.sort((a, b) => {
      if (a.classificatorEKUName && b.classificatorEKUName) {
        const labelA = a.classificatorEKUName;
        const labelB = b.classificatorEKUName;
        if (labelA < labelB) {
          return -1;
        }
        if (labelB > labelA) {
          return 1;
        }
      }
      return 0;
    });
  };

  return (
    <Table
      data={!editMode ? filterTable(values.data) : values.data}
      pageSize={Number.MAX_SAFE_INTEGER}
      overflow
      columns={[
        {
          dataIndex: 'classificatorEKUName',
          title: 'Доступные услуги',
          render: (item: any) => (
            <div className="flex items-center" style={{ height: HEIGHT_FIELD }}>
              {item.classificatorEKUName}
            </div>
          ),
          width: '35%',
        },
        {
          dataIndex: 'hasPlaces',
          title: 'Бюджетные места',
          render: (item: any) => (
            <div className="flex justify-center items-center" style={{ width: 127, height: HEIGHT_FIELD }}>
              <Toggle
                disabled={!editMode || !!item.volume}
                labelId={item.id}
                value={item.hasPlaces}
                size="small"
                onChange={handleChangeToggle(item.classificatorEKUId)}
              />
            </div>
          ),
          width: '172px',
        },
        {
          dataIndex: 'usedVolume',
          title: 'Зачислено',
          render: (item: any) => (
            <div className="flex items-center" style={{ height: HEIGHT_FIELD }}>
              {item.hasPlaces ? item.usedVolume || 0 : '—'}
            </div>
          ),
          width: '118px',
        },
        {
          dataIndex: 'limitVolume',
          title: (
            <span>
              Занято
              <Tooltip
                text={
                  <div className="text-center font-size-xsmall font-weight-base" style={{ maxWidth: '268px' }}>
                    Количество мест, зарезервированных в планах приема образовательных программ для соответствующих
                    профессий
                  </div>
                }
                component={() => (
                  <div style={{ marginLeft: 4 }}>
                    <IconInfo />
                  </div>
                )}
                position="bottom"
              />{' '}
              / Всего бюджетных мест
            </span>
          ),
          render: (item: any) => (
            <div className="flex items-center" style={{ height: HEIGHT_FIELD }}>
              {item.limitVolume !== null ? (
                <>
                  <div className="flex-none text-right" style={{ minWidth: 25 }}>
                    {item.volume ?? 0} /
                  </div>
                  {editMode ? (
                    <>
                      <Push orientation="horizontal" size={8} />
                      <div style={{ width: VOLUME_FIELD_WIDTH }} className="flex-none">
                        <FormikInput
                          name={`data[${getCurrentIndex(item.classificatorEKUId)}].limitVolume`}
                          value={checkValidationValue(item.limitVolume)}
                          showErrorImmediately
                          number
                          textRight
                          size="small"
                          maxLength={`${MAX_LIMIT_VOLUME}`.length}
                          maxValue={MAX_LIMIT_VOLUME}
                        />
                      </div>
                      <Push orientation="horizontal" size={12} />
                      <div className="flex-auto color-danger line-height-base">
                        {errorMessageLimit(item.limitVolume, item.volume)}
                      </div>
                    </>
                  ) : (
                    <>&nbsp;{item.limitVolume ?? 0}</>
                  )}
                </>
              ) : (
                <span className="flex justify-center" style={{ width: 27 }}>
                  —
                </span>
              )}
              {editMode && (
                <div className="ml-auto">
                  <Button
                    border
                    iconLeft={() => <IconRemove />}
                    size="small"
                    disabled={!!item.volume || item.hasServices}
                    handleClick={handleDelete(item.classificatorEKUId)}
                  />
                </div>
              )}
            </div>
          ),
        },
      ]}
    />
  );
};

export default ProfessionTable;
