import React, { useCallback, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Crop } from 'react-image-crop/src/ReactCrop';
import 'react-image-crop/dist/ReactCrop.css';
// @ts-ignore
import Files from 'react-files';
import { Button, FormGroup, Input, notify, Push } from '@mosru/esz_uikit';
import { ReactFilesError, ReactFilesFile } from '../../../types/files';
import { formatBytes } from '../../../lib/utils/format-bytes';
import organizationApi from '../../../lib/api/organization';
import { ReactComponent as IconClose } from '../../../assets/images/auth/close.svg';
import { orgPhotoUrl } from '../../../config/constants';

type ArrImageType = {
  id: number;
  name: string;
  crop: Crop;
  cropImage: Blob;
  originalFile: ReactFilesFile;
  path?: string | undefined;
};

type OrganizationPhotoProps = {
  isArchive: boolean;
  checkSigned: boolean;
  organizationId: number;
};

const defaultCrop: Crop = { x: 0, y: 0, width: 100, height: 100, unit: '%' };
const defaultName = 'Без имени';
const maxCountImg = 12;

export const ACCEPTS = ['.jpg', '.jpeg', '.png'];
export const REG_EXP_ACCEPTS = new RegExp(`\\S\\.(${ACCEPTS.join('|').replaceAll('.', '')})$`, 'gi');
export const ACCEPTS_ERROR = `Допустимые расширения файла: ${ACCEPTS.join(', ')}`;

const OrganizationPhoto = ({ organizationId, isArchive, checkSigned }: OrganizationPhotoProps) => {
  const [currentFile, setCurrentFile] = useState<ReactFilesFile | null>(null); // обрабатываемый url blob
  const [currentImage, setCurrentImage] = useState<HTMLImageElement | null>(null); // кропнутая картинка в стейте
  const [currentName, setCurrentName] = useState('');
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [currentCrop, setCurrentCrop] = useState<Crop>(defaultCrop);
  const [arrImages, setArrImages] = useState<ArrImageType[]>([]);
  const [nameError, setNameError] = useState<string>();

  const getNotifyFileError = () =>
    notify.danger({
      data: {
        label: 'Некорректный файл. Выберите другое изображение и повторите загрузку.',
        icon: true,
        close: true,
      },
    });

  const clear = useCallback(() => {
    setCurrentFile(null);
    setCurrentImage(null);
    setCurrentCrop(defaultCrop);
    setCurrentName('');
    setCurrentId(null);
    setNameError(undefined);
  }, []);

  useEffect(() => {
    const initPhotos = async () => {
      const photos = await organizationApi.getPhotos(organizationId);
      if (photos) {
        const images: ArrImageType[] = [];
        for (const item of photos) {
          const image = await dataURItoBlob(`${orgPhotoUrl}${item.path}`);
          images.push({
            id: item.id ?? 0,
            name: item.name,
            crop: { width: Math.round(item.width), height: Math.round(item.height), x: 0, y: 0, unit: 'px' },
            cropImage: image,
            originalFile: {
              ...image,
              lastModified: Math.random(),
              name: item.name,
              id: item.id?.toString() ?? '',
              extension: 'jpg',
              sizeReadable: image.size.toString(),
              preview: { type: 'image', url: URL.createObjectURL(image) },
            } as ReactFilesFile,
          });
        }
        setArrImages(images);
      }
    };
    initPhotos();
  }, [organizationId]);

  useEffect(() => {
    if (arrImages.length >= maxCountImg) {
      notify.warning({
        data: {
          label: 'Максимально можно сохранить 12 фотографий',
          icon: true,
        },
      });
    }
  }, [arrImages]);

  useEffect(() => {
    setNameError(currentName && !currentName.match(REG_EXP_ACCEPTS) ? ACCEPTS_ERROR : undefined);
  }, [currentName]);

  return (
    <>
      <Push size={12} />
      <div className="container">
        <div className="org-photo">
          <div className="org-photo__object">
            {currentFile ? (
              <div className="org-crop-photo">
                <div className="org-crop-photo__object">
                  <ReactCrop
                    src={currentFile.preview.url}
                    onImageError={() => {
                      clear();
                      getNotifyFileError();
                    }}
                    onImageLoaded={(image) => {
                      setCurrentImage(image);
                      setCurrentName(currentFile.name);
                      setCurrentCrop({
                        x: 0,
                        y: 0,
                        width: image.width,
                        height: image.height,
                        unit: 'px',
                      });
                      return false;
                    }}
                    crop={currentCrop}
                    onChange={(crop) => setCurrentCrop(crop)}
                  />
                </div>
                {!checkSigned && !isArchive && (
                  <div className="org-crop-photo__name">
                    <FormGroup label="" labelId="photoName" error={nameError}>
                      <Input
                        labelId="photoName"
                        size="small"
                        placeholder="Введите имя изображения"
                        value={currentName}
                        error={nameError}
                        onChange={(ev) => {
                          setCurrentName(ev.target.value);
                        }}
                      />
                    </FormGroup>
                    <Push size={12} />
                    <div className="flex justify-end">
                      <Button label="Отмена" primary border size="small" onClick={clear} />
                      <Push size={8} orientation="horizontal" />
                      <Button
                        label="Сохранить"
                        primary
                        size="small"
                        disabled={!currentName || !currentImage || !!nameError}
                        onClick={async () => {
                          if (!currentImage) {
                            return;
                          }
                          const image = (await getCroppedImg(currentImage, currentCrop)) as Blob;
                          const photo = await organizationApi.putPhoto({
                            id: currentId,
                            name: currentName || defaultName,
                            orgId: organizationId,
                            width: Math.round(currentCrop.width),
                            height: Math.round(currentCrop.height),
                            body: image,
                          });
                          const crop = {
                            ...currentCrop,
                            x: 0,
                            y: 0,
                          };
                          setArrImages(
                            currentId
                              ? arrImages.map((item) => {
                                  if (photo.id === item.id) {
                                    return {
                                      id: photo.id ?? 0,
                                      name: currentName,
                                      crop,
                                      cropImage: image,
                                      originalFile: {
                                        ...currentFile,
                                        name: currentName,
                                        preview: {
                                          ...currentFile.preview,
                                          url: URL.createObjectURL(image),
                                        },
                                      },
                                    };
                                  }
                                  return item;
                                })
                              : [
                                  ...arrImages,
                                  {
                                    id: photo.id ?? 0,
                                    name: currentName,
                                    crop,
                                    cropImage: image,
                                    originalFile: {
                                      ...currentFile,
                                      name: currentName,
                                      preview: {
                                        ...currentFile.preview,
                                        url: URL.createObjectURL(image),
                                      },
                                    },
                                  },
                                ]
                          );
                          clear();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Files
                multiple={false}
                className="org-photo-dropzone"
                onChange={(files: ReactFilesFile[]) => {
                  if (!checkSigned && !isArchive && !(arrImages.length >= maxCountImg)) {
                    files[0] && setCurrentFile(files[0]);
                  }
                }}
                onError={(error: ReactFilesError) => {
                  getNotifyFileError();
                  console.error(error);
                }}
                accepts={ACCEPTS}
                clickable={!checkSigned && !isArchive && !(arrImages.length >= maxCountImg)}
              >
                <div className="org-photo-dropzone__title">Перетащите файл сюда, чтобы загрузить</div>
                <Push size={4} />
                Формат файлов: JPG, PNG
                <Push size={12} />
                <Button
                  disabled={checkSigned || isArchive || arrImages.length >= maxCountImg}
                  label="Выбрать файл"
                  primary
                  size="small"
                />
              </Files>
            )}
          </div>
          <div className="org-photo__list">
            <ul className="org-photo-ul">
              {arrImages.map((item) => (
                <li key={item.id} className="org-photo-ul__item">
                  <div className={`org-photo-media ${currentId === item.id && 'org-photo-media--active'}`}>
                    {currentId !== item.id && (
                      <button
                        type="button"
                        aria-label="photo_select"
                        onClick={() => {
                          setCurrentName(item.name);
                          setCurrentFile(item.originalFile ?? null);
                          setCurrentId(item.id);
                          setCurrentCrop(item.crop);
                        }}
                        className="org-photo-media__btn-select"
                      />
                    )}
                    {!checkSigned && !isArchive && (
                      <button
                        type="button"
                        onClick={() => {
                          const newArrImages = arrImages.filter((arrItem) => arrItem.id !== item.id);
                          if (item.id === currentId) {
                            clear();
                          }
                          setArrImages(newArrImages);
                          organizationApi.deletePhoto(item.id, organizationId);
                        }}
                        className="org-photo-media__btn-delete"
                      >
                        <IconClose />
                      </button>
                    )}
                    <img
                      alt={item.name}
                      src={URL.createObjectURL(item.cropImage)}
                      className="org-photo-media__object"
                    />
                    <div className="org-photo-media__body">
                      {item.name}
                      <Push size={4} />
                      <div className="color-gray-dark">{formatBytes(item.cropImage.size)}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function getCroppedImg(image: HTMLImageElement, crop: Crop) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      0.85
    );
  });
}

const dataURItoBlob = async (dataURI: string) => (await fetch(dataURI)).blob();

export default OrganizationPhoto;
