import React, { useCallback, useState } from 'react';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Loader, Push } from '@mosru/esz_uikit';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import ServiceClassesEnrollment from '../components/fields/service-classes-enrollment';
import { RequestEnrollmentData } from '../../../types/requests';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import { submitEnrollment } from '../utils';
import { EnrollmentModal } from '../components/modals';
import { docDate, documentTypeId, enrollDocNumber, serviceClassId } from '../../../lib/utils/validation';
import { getDate } from '../../../lib/utils/date';

type Props = {
  enrollment: RequestEnrollmentData;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
  contingentGuid: string;
};

const ActionsFormEnrollment: React.FC<Props> = ({
  contingentGuid,
  enrollment,
  serviceId,
  requestId,
  updateRequest,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState<number>(0);

  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      await submitEnrollment(values, requestId, setLoading, setShowModal, undefined, updateRequest);
    },
    [requestId, updateRequest]
  );
  const initialErrors = useInitialErrors(enrollment, getValidationSchema());

  const resetFormValues = (resetFn: () => void) => {
    resetFn();
    setFormKey(Math.random());
  };

  const updatedInitialValues = { ...enrollment, enrollDocumentTypeId: FormTypeEnum.EditRequestEnrollment };

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <Formik
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={updatedInitialValues}
      initialErrors={initialErrors}
      key={formKey}
    >
      {(formikProps: FormikProps<RequestEnrollmentData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <ServiceClassesEnrollment
              serviceId={serviceId}
              contingentGuid={contingentGuid}
              warningMessage="Группы обучения для указанной услуги не найдены. Для зачисления доступны только те группы обучения, в которых выбрана указанная услуга."
              type="EditVA"
            />
            <Push size={16} />
            <div className="container">
              <div className="table-data">
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Дата зачисления <div className="table-data__required" />
                  </div>
                  <div className="table-data__body">
                    <div className="table-data-grid-3">
                      <FormikFormGroup label="" name="enrollDate">
                        <FormikDatePicker
                          placeholder="ДД.ММ.ГГГГ"
                          size="small"
                          name="enrollDate"
                          startDate={enrollment?.enrollDate ? getDate(enrollment.enrollDate) : undefined}
                        />
                      </FormikFormGroup>
                    </div>
                  </div>
                </div>
                <Document
                  name="enrollDocumentType"
                  label="Вид документа-основания"
                  editMode
                  formType={FormTypeEnum.EditRequestEnrollment}
                  required
                  size={3}
                />
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Номер и дата документа <div className="table-data__required" />
                  </div>
                  <div className="table-data__body">
                    <div className="table-data-grid-2">
                      <div>
                        <FormikFormGroup label="" name="enrollDocNumber">
                          <FormikInput size="small" name="enrollDocNumber" placeholder="Введите номер..." />
                        </FormikFormGroup>
                      </div>
                      <div>
                        <FormikFormGroup label="" name="enrollDocDate">
                          <FormikDatePicker
                            size="small"
                            name="enrollDocDate"
                            placeholder="ДД.ММ.ГГГГ"
                            startDate={enrollment?.enrollDocDate ? getDate(enrollment.enrollDocDate) : undefined}
                          />
                        </FormikFormGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <SimpleTextArea name="enrollComment" editMode label="Комментарий" placeholder="Введите..." />
              </div>
            </div>
            <div className="requests-hr" />
            <div className="container">
              <div className="flex justify-end">
                <Button label="Сбросить" primary border onClick={() => resetFormValues(resetForm)} />
                <Push size={12} orientation="horizontal" />
                <Button
                  disabled={!isValid || isSubmitting}
                  onClick={() => setShowModal(true)}
                  label="Зачислить"
                  primary
                />
              </div>
              <Push size={16} />
            </div>
            <EnrollmentModal showModal={showModal} setShowModal={setShowModal} handleSubmit={handleSubmit} />
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormEnrollment;

const getValidationSchema = () =>
  objectYup().shape({
    enrollDate: docDate.nullable().required('Выберите дату зачисления'),
    enrollDocumentTypeId: documentTypeId,
    enrollDocNumber,
    enrollDocDate: docDate.nullable().required('Выберите дату документа'),
    serviceClass: objectYup().shape({
      id: serviceClassId,
    }),
  });
