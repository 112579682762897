import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { array, number, object as objectYup, string as stringYup } from 'yup';
import { Button, SelectOptionType, TreeNodeType, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import { ReactComponent as IconOptions } from '../../../../../assets/images/icons/options-color.svg';
import lookupApi from '../../../../../lib/api/lookup';
import learnerApi from '../../../../../lib/api/learner';
import { checkEmptyData, showSuccessNotification } from '../../../../../lib/utils/learners';
import TreeModal from '../../../../../components/tree-modal';
import { DocumentDataFinishedAndTypes } from '../../../../../types/learners';
import { classificatorApi } from '../../../../../lib/api/classificator';
import { transformProfession } from '../../../../../components/tree-modal/helpers';
import { docDate } from '../../../../../lib/utils/validation';
import {
  FileData,
  ProgramLevelsType,
  PropertyOrganization,
  ServiceProperty,
} from '../../../../../types/document-modal-other';
import { waitTypeHead } from './utils';
import { FinDocument } from './components/fields/fin-type';
import { UploadFiles } from './components/upload-files';
import { DocumentType } from './components/fields/document-type';
import { DocumentModalContext } from '../index';
import { DocumentDate } from './components/fields/document-date';

type Props = {
  data: any;
  pupilId: string;
  newDocument: boolean;
  onCloseHandler: () => void;
  setValidateFormOtherCity: (value: boolean) => void;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
};

// Todo нужно полностью отрефакторить
const FormOther = forwardRef<any, Props>(
  ({ data, newDocument, pupilId, onCloseHandler, setValidateFormOtherCity, documentDataFinishedAndTypes }, ref) => {
    const { adminEdit, adminView, setLoadingRequest } = useContext(DocumentModalContext);
    const accessCheck = !adminView || adminEdit;

    const [rerenderQualificationKey, setRerenderQualificationKey] = useState(1);
    const [rerenderOrganization, setRerenderOrganization] = useState(0);
    const [rerenderStage, setRerenderStage] = useState(2);

    const [files, setFiles] = useState<FileData[]>(data.files ? [...data.files] : []);

    const [fileIds, setFileIds] = useState<number[]>([...data.fileIds]);

    const [openTreePopup, setOpenTreePopup] = useState<boolean>(false);

    const programLevels = useRef<ProgramLevelsType>();

    const [dataTreePopup, setDataTreePopup] = useState<TreeNodeType[]>([]);

    const [organization, setOrganization] = useState<(SelectOptionType & { properties?: PropertyOrganization }) | null>(
      {
        value: data.orgId,
        label: data.orgName,
      }
    );

    const [shortOrganization, setShortOrganization] = useState<
      (SelectOptionType & { properties?: PropertyOrganization }) | null
    >(
      data.shortOrgName
        ? {
            label: data.shortOrgName,
            value: data.orgId,
          }
        : null
    );

    const [address, setAddress] = useState<string | null>(data.address);

    const [docNumber, setDocNumber] = useState<string>(data.docNumber);
    const [finTypeId, setFinId] = useState<string | null>(data.finTypeId);
    const [docDate, setDocDate] = useState<Date | string | null | undefined>(data.docDate);

    const [stage, setStage] = useState<(SelectOptionType & { additionalPropertiesJson?: string }) | null>({
      label: data.stageName,
      value: data.stageId,
      additionalPropertiesJson: '',
    });

    const [profession, setProfession] = useState<(SelectOptionType & { properties?: SelectOptionType[] }) | null>({
      label: data.profName,
      value: data.profId,
    });

    const [qualification, setQualification] = useState<SelectOptionType | null>({
      label: data.qualificationName,
      value: data.qualificationId,
    });

    const [service, setService] = useState<(SelectOptionType & { properties?: ServiceProperty }) | null>({
      label: data.serviceName,
      value: data.serviceId,
    });

    const [errorForm, setErrorForm] = useState<boolean>(false);

    const orgFullNameDefault = useMemo(() => {
      if (!organization?.value) {
        return null;
      } else {
        return {
          label: data.orgName ? data.orgName : organization?.label,
          value: data.orgId,
        };
      }
    }, [organization?.label, organization?.value, data.orgId, data.orgName]);

    const openPopupTreeHandler = (value: boolean) => {
      setOpenTreePopup(value);
    };

    const initialData: any = useMemo(() => {
      const certificate = 7;

      const date = new Date(docDate ?? data.docDate);
      date.toISOString();
      return {
        files,
        fileIds,
        docDate: date,
        finTypeId,
        address,
        stageName: stage?.label,
        profName: profession?.label,
        orgName: organization?.label,
        docNumber,
        serviceName: service?.label,
        shortOrgName: shortOrganization?.label,
        docTypeId: newDocument ? certificate : data.docTypeId,
        qualificationName: qualification?.label,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      address,
      data,
      docNumber,
      finTypeId,
      newDocument,
      organization?.label,
      profession?.label,
      qualification?.label,
      service?.label,
      shortOrganization?.label,
      stage,
    ]);
    const initialErrors = useInitialErrors(data, getValidationSchema());

    const deleteFiles = async (whileSaving?: boolean) => {
      const fromArray = whileSaving ? data.fileIds : fileIds;
      const comparedArray = whileSaving ? fileIds : data.fileIds;

      for (const id of fromArray) {
        if (!comparedArray.includes(id)) {
          await learnerApi.deleteFileOtherCity(pupilId, id, { fileId: id });
        }
      }
    };

    const handleSubmitOther = () => {
      setLoadingRequest(true);
      (async () => {
        const certificate = 7;
        const sendData = checkEmptyData({
          fileIds: fileIds.filter((fileId) => files.some(({ id }) => fileId === id)),
          pupilId: +pupilId,
          stageId: stage?.value,
          serviceId: service?.value,
          orgId: organization?.value,
          qualificationId: qualification?.value,
          address,
          docTypeId: newDocument ? certificate : data.docTypeId,
          stageName: stage?.label,
          profName: profession?.label,
          profId: profession?.value,
          finTypeId,
          orgName: organization?.label,
          docNumber,
          serviceName: service?.label,
          shortOrgName: organization?.label,
          docDate,
          qualificationName: qualification?.label,
        });

        try {
          await deleteFiles(true);
          newDocument
            ? await learnerApi.postDocumentOtherCity({ id: 0, ...sendData })
            : await learnerApi.putDocumentOtherCity({ id: data.id, ...sendData });
          showSuccessNotification();
          onCloseHandler();
        } finally {
          setLoadingRequest(false);
        }
      })();
    };

    useEffect(() => {
      setValidateFormOtherCity(errorForm);
    }, [errorForm, setValidateFormOtherCity]);

    // От выбора наименование программы обучения меняем поля
    useEffect(() => {
      const data = service?.properties;

      if (data && data.ProfId) {
        (async () => {
          const response = await classificatorApi.getParents({ id: data.ProfId });

          setFinId(data.TypeFinancingId);
          setStage(null);
          setOrganization({ label: data.OrganizationName, value: data.OrganizationId });
          setProfession(transformProfession(response).profession);
          setShortOrganization({
            label: data.OrganizationShortName,
            value: data.OrganizationId,
          });
          setRerenderStage(Math.random());
          programLevels.current = { data: transformProfession(response).stage };

          if (programLevels.current?.data?.length) {
            const currentQualification = programLevels.current?.data.find((item) => item.value === data.LevelId);
            setQualification(currentQualification || null);
          }
          setAddress(data.Address);
        })();
      }
    }, [service]);

    // обнуляем значения квалификации если не выбрана профессия
    useEffect(() => {
      if (!profession?.value) {
        programLevels.current = { data: [] };
        setQualification(null);
        setRerenderQualificationKey(Math.random());
      }
    }, [profession]);

    useEffect(() => {
      if (profession?.properties) {
        setQualification(null);
        programLevels.current = { data: profession?.properties };
      }
    }, [profession?.properties]);

    useEffect(() => {
      if (!organization) {
        setRerenderOrganization(Math.random());
      }
    }, [organization]);

    // устанавливаем значения когда выбираем организацию
    useEffect(() => {
      const label = organization?.properties?.ShortName ?? '';
      if (organization?.properties) {
        setShortOrganization({ label, value: organization.value });
        setAddress(organization?.properties?.Address ?? '');
      }
    }, [newDocument, data.shortOrgName, organization]);

    // устанавливаем значение когда выбираем краткое наименование организации
    useEffect(() => {
      const label = shortOrganization?.properties?.OrgName || '';
      if (shortOrganization?.properties) {
        setOrganization({ label, value: shortOrganization.value });
        setAddress(shortOrganization?.properties.Address);
      }
    }, [shortOrganization?.properties, shortOrganization?.value]);

    useImperativeHandle(ref, () => ({
      handleSubmitOther,
      deleteFiles,
    }));

    const validate = (values: any) => {
      setAddress(values.address || '');
      setDocNumber(values.docNumber);
      setDocDate(values.docDate);
    };

    return (
      <>
        <Formik
          enableReinitialize
          validateOnMount
          innerRef={(formikActions) => {
            if (formikActions) {
              setErrorForm(formikActions.isValid);
            }
          }}
          validate={validate}
          initialValues={initialData}
          initialErrors={initialErrors}
          validationSchema={getValidationSchema()}
          onSubmit={(values, actions) => {
            handleSubmitOther();
            actions.setSubmitting(false);
          }}
        >
          {(formikProps: FormikProps<FormData>) => {
            const { handleSubmit } = formikProps;

            return (
              <>
                <Push size={16} />
                <form onSubmit={handleSubmit}>
                  <DocumentType
                    docTypeId={data.docTypeId}
                    newDocument={newDocument}
                    documentDataFinishedAndTypes={documentDataFinishedAndTypes}
                  />

                  <Push size={16} />
                  <FormikFormGroup name="docNumber" required label="Номер">
                    <FormikInput disabled={!accessCheck} size="small" name="docNumber" placeholder="Введите номер" />
                  </FormikFormGroup>
                  <Push size={16} />
                  <DocumentDate docDate={data.docDate} accessCheck={accessCheck} newDocument={newDocument} />
                  <Push size={16} />
                  <div key={rerenderOrganization}>
                    <FormikFormGroup name="orgName" required label="Полное наименование организации">
                      <FormikSelect
                        name="orgName"
                        size="small"
                        selectedValue={setOrganization}
                        isSearchable
                        loadOptions={async (query) => {
                          const data = await lookupApi.getOrganizationOC(query);
                          return data.length ? data : waitTypeHead(query);
                        }}
                        defaultValue={orgFullNameDefault}
                        options={[]}
                        disabled={!accessCheck}
                        placeholder="Введите полное наименование организации"
                      />
                    </FormikFormGroup>
                  </div>
                  <Push size={16} />
                  <FormikFormGroup name="shortOrgName" required label="Краткое наименование организации">
                    <FormikSelect
                      name="shortOrgName"
                      size="small"
                      disabled={!accessCheck}
                      selectedValue={setShortOrganization}
                      defaultValue={shortOrganization}
                      loadOptions={async (query) => {
                        const data = await lookupApi.getOrganizationOC(query, true);
                        return data.length ? data : waitTypeHead(query);
                      }}
                      isSearchable
                      options={[]}
                      placeholder="Введите краткое наименование организации"
                    />
                  </FormikFormGroup>
                  <Push size={16} />
                  <FormikFormGroup name="address" required label="Юридический адрес">
                    <FormikInput
                      disabled={!accessCheck}
                      size="small"
                      name="address"
                      placeholder="Введите юридический адрес"
                    />
                  </FormikFormGroup>
                  <Push size={16} />
                  <FormikFormGroup name="serviceName" required label="Наименование программы обучения">
                    <FormikSelect
                      isSearchable
                      size="small"
                      options={[]}
                      name="serviceName"
                      disabled={!accessCheck}
                      selectedValue={setService}
                      defaultValue={service?.value ? service : null}
                      placeholder="Введите наименование программы обучения"
                      loadOptions={async (query) => {
                        const data = await lookupApi.getServiceOc(query);
                        return data.length ? data : waitTypeHead(query);
                      }}
                    />
                  </FormikFormGroup>
                  <Push size={16} />
                  <FinDocument accessCheck={accessCheck} finTypeId={finTypeId} setFinId={setFinId} />
                  <Push size={16} />
                  <div className="flex items-end">
                    <div className="flex-auto">
                      <FormikFormGroup name="profName" required label="Профессия">
                        <FormikSelect
                          size="small"
                          isSearchable
                          options={[]}
                          name="profName"
                          disabled={!accessCheck}
                          selectedValue={setProfession}
                          loadOptions={async (query) => await lookupApi.getClassificator(query, 2)}
                          defaultValue={!profession?.value ? null : profession}
                          placeholder="Начните вводить или выберите"
                        />
                      </FormikFormGroup>
                    </div>

                    <Push size={10} orientation="horizontal" />

                    <Button
                      size="small"
                      border
                      primary
                      disabled={!accessCheck}
                      iconLeft={() => <IconOptions />}
                      onClick={() => setOpenTreePopup(true)}
                    />
                  </div>
                  <Push size={16} />
                  <div key={rerenderQualificationKey}>
                    <FormikFormGroup required name="qualificationName" label="Квалификация">
                      <FormikSelect
                        size="small"
                        isSearchable
                        options={programLevels.current?.data ?? []}
                        name="qualificationName"
                        selectedValue={setQualification}
                        disabled={!profession?.value || !accessCheck}
                        defaultValue={qualification}
                        placeholder={!profession?.value ? 'Сначала выберите профессию' : 'Выберите квалификацию'}
                      />
                    </FormikFormGroup>
                  </div>
                  <Push size={16} />
                  <div key={rerenderStage}>
                    <FormikFormGroup name="stageName" required label="Этап обучения">
                      <FormikSelect
                        name="stageName"
                        size="small"
                        isSearchable
                        options={[]}
                        disabled={!accessCheck}
                        selectedValue={setStage}
                        defaultValue={stage?.value ? stage : null}
                        loadOptions={async (query) => {
                          const data = await lookupApi.getModuleOc(query, service?.value);
                          return data.length ? data : waitTypeHead(query);
                        }}
                        placeholder="Введите этап обучения"
                      />
                    </FormikFormGroup>
                  </div>
                  <Push size={16} />

                  <UploadFiles
                    id={data.id}
                    files={files}
                    fileIds={fileIds}
                    pupilId={pupilId}
                    setFiles={setFiles}
                    setFileIds={setFileIds}
                    accessCheck={accessCheck}
                  />
                </form>
              </>
            );
          }}
        </Formik>

        <TreeModal
          expandAll
          open={openTreePopup}
          educationId={2} // для Не Москвы может быть только Проф образование. EducationType == 2
          title="Реестр профессий"
          dataTreePopup={dataTreePopup}
          setDataTreePopup={setDataTreePopup}
          closePopup={openPopupTreeHandler}
          setQualifications={programLevels}
          setValueBasicDirection={setProfession}
          numberElements={0}
        />
      </>
    );
  }
);

export default FormOther;

const getValidationSchema = () =>
  objectYup().shape({
    docTypeId: number().required('Выберите тип документа').nullable(),
    docNumber: stringYup().required('Введите номер'),
    docDate: docDate.nullable().required('Выберите дату').required('Выберите дату'),
    orgName: stringYup().required('Введите полное наименование организации'),
    shortOrgName: stringYup().required('Введите краткое наименование организации').nullable(),
    address: stringYup().required('Введите юридический адрес').nullable(),
    serviceName: stringYup().required('Введите наименование программы обучения').nullable(),
    finTypeId: number().required('Выберите тип финансирования').nullable(),
    qualificationName: stringYup().required('Выберите квалификацию').nullable(),
    stageName: stringYup().required('Введите этап обучения').nullable(),
    fileIds: array().min(1, 'Необходимо прикрепить хотя бы один документ'),
    files: array().min(1, 'Необходимо прикрепить хотя бы один документ'),
  });
