import React from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ProgrammModule } from '../../../../types/entities';
import lookupApi from '../../../../lib/api/lookup';
import { ServiceData } from '../../../../types/service';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';

type Props = {
  show: boolean;
  hideModal: () => void;
  module?: ProgrammModule;
  onSubmit: (values: ProgrammModule) => void;
  serviceData: ServiceData;
};

const AddModule: React.FC<Props> = ({ show, hideModal, module, onSubmit, serviceData }) => {
  const initialErrors = useInitialErrors(module, getValidationSchema());

  return (
    <Modal show={show} onClose={hideModal}>
      <Formik
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={
          module ||
          ({ serviceId: serviceData.id, educationTypeId: serviceData.educationTypeId } as unknown as ProgrammModule)
        }
      >
        {(formikProps: FormikProps<any>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={hideModal}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={hideModal} />
                    <Push orientation="horizontal" size={12} />
                    <Button label="Сохранить" disabled={!isValid || isSubmitting} primary size="small" submit />
                  </>
                )}
                modalTitle="Модуль образовательной программы"
                renderComponent={() => (
                  <>
                    <FormikFormGroup required name="name" label="Название модуля">
                      <FormikInput name="name" size="small" placeholder="Введите..." />
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup name="description" label="Описание модуля">
                      <FormikTextarea rows={3} name="description" placeholder="Введите..." />
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup required name="" label="Количество часов в модуле">
                      <div style={{ width: 88 }}>
                        <FormikFormGroup name="learnHours" label="">
                          <FormikInput
                            name="learnHours"
                            number
                            placeholder="0"
                            size="small"
                            onKeyPress={allowOnlyNumbers}
                          />
                        </FormikFormGroup>
                      </div>
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup required name="addressId" label="Адрес предоставления услуги">
                      <FormikSelect
                        name="addressId"
                        size="small"
                        isSearchable
                        options={serviceData.placeService.list.map((address) => ({
                          label: address.fullAddress,
                          value: address.addressId,
                        }))}
                        placeholder="Выберите..."
                      />
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup required name="teacherId" label="Преподаватель">
                      <FormikSelect
                        name="teacherId"
                        size="small"
                        isSearchable
                        loadOptions={async (query) =>
                          await lookupApi.getTeachers(query, serviceData.info.organizationId)
                        }
                        options={
                          module?.teacherId && module?.teacherName
                            ? [{ value: module.teacherId, label: module.teacherName }]
                            : []
                        }
                        placeholder="Начните вводить..."
                      />
                    </FormikFormGroup>
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddModule;

const getValidationSchema = () =>
  objectYup().shape({
    name: stringYup().required('Введите наименование').nullable(),
    learnHours: numberYup().nullable().required('Введите количество часов').min(1, 'Введите количество часов'),
    addressId: stringYup().nullable().required('Выберите адрес предоставления услуги'),
    teacherId: stringYup().nullable().required('Выберите преподавателя'),
  });
