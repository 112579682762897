import React from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  onCloseHandle: () => void;
  onRestoreHandler: () => void;
  title: string;
  description: string;
};

const RestoreDocumentModal = ({ show, onCloseHandle, onRestoreHandler, title, description }: Props) => (
  <Modal show={show} onClose={onCloseHandle}>
    <ModalPanel
      alert
      onClose={onCloseHandle}
      modalTitle={title}
      description={description}
      controls={() => (
        <>
          <Button label="Отмена" border primary size="small" onClick={onCloseHandle} />
          <Push orientation="horizontal" size={12} />
          <Button label="Да, восстановить" primary size="small" onClick={onRestoreHandler} />
        </>
      )}
    />
  </Modal>
);

export default RestoreDocumentModal;
