import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  title: string;
  description: string;
  labelOk?: string;
  labelCancel?: string;
};

const SimpleModal = ({ show, onClose, handleSubmit, title, description, labelOk, labelCancel }: Props) => {
  return (
    <Modal show={show} onClose={onClose}>
      <ModalPanel
        alert
        modalTitle={title}
        onClose={onClose}
        description={description}
        controls={() => (
          <>
            <Button primary border label={labelCancel ?? 'Отмена'} onClick={onClose} />
            <Push orientation="horizontal" size={12} />
            <Button
              primary
              label={labelOk ?? 'Подтвердить'}
              onClick={async () => {
                await handleSubmit();
                onClose();
              }}
            />
          </>
        )}
      />
    </Modal>
  );
};

export default SimpleModal;
