import { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import LatoRegular from '../assets/fonts/Lato/Lato-Regular.ttf';
import LatoRegularItalic from '../assets/fonts/Lato/Lato-Italic.ttf';
import LatoBold from '../assets/fonts/Lato/Lato-Bold.ttf';
import LatoBoldItalic from '../assets/fonts/Lato/Lato-BoldItalic.ttf';
import LatoBlack from '../assets/fonts/Lato/Lato-Black.ttf';
import LatoBlackItalic from '../assets/fonts/Lato/Lato-BlackItalic.ttf';
import { GlobalStyleVars, styleVars } from './vars';

const GlobalStyle = createGlobalStyle`  
  ${GlobalStyleVars}

  @font-face {
    font-family: 'Lato';
    src: url(${LatoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${LatoRegularItalic}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${LatoBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${LatoBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${LatoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${LatoBlackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  html {
    font-family: ${styleVars.fontFamilyBase};
    font-size: ${styleVars.fontSizeBase};
    font-weight: ${styleVars.fontWeightBase};
    line-height: ${styleVars.lineHeightBase};
    color: ${styleVars.textColor};

    // Reset the box-sizing
    box-sizing: border-box;
    * {
      &, &:before, &:after {
        box-sizing: inherit;
      }
    }

    // Reset outline 
    * {
      outline: none;
    }
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    input:autofill {
      background: #fff; /* or any other */
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 0 white !important;
      transition: background-color 5000s ease-in-out 0s;
    }




    // Reset buttons
    button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      cursor: pointer;
    }

    // Reset svg    
    svg {
      display: block;
    }
  }

  body {
    -webkit-overflow-scrolling: touch;

    // Font smoothing
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyle;
