import { createApi } from '@reduxjs/toolkit/query/react';
import { OrderByTypeRequest, PaginationRequest, PagingList, Response } from '../../types/entities';
import { Favorite, SearchFavoriteFormData } from '../../types/favorite';
import { apiService } from '../../config/constants';
import axiosBaseQuery from '../../lib/api/axios-base-query';
import api from '../../lib/api/api';

type GetFavoriteProps = SearchFavoriteFormData & OrderByTypeRequest & PaginationRequest;

// Define a service using a base URL and expected endpoints
export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: axiosBaseQuery({ baseURL: `${apiService.data}/StaticContent/Favorites/` }),
  tagTypes: ['Favorite', 'Favorites'],
  endpoints: (builder) => ({
    getFavorite: builder.query<Response<Favorite>, { entityId: number; entityTypeId: number }>({
      query: (params: { entityId: number; entityTypeId: number }) => {
        return api.GET({
          url: 'ByEntity',
          params,
        });
      },
      providesTags: ['Favorite'],
    }),
    getFavorites: builder.query<PagingList<Favorite>, GetFavoriteProps>({
      query: (data: GetFavoriteProps) => {
        return api.POST({
          url: `search`,
          data,
        });
      },
      providesTags: ['Favorites'],
    }),
    deleteFavorite: builder.mutation<{ success: boolean }, number>({
      query(objectId) {
        return api.DELETE({
          params: { objectId },
        });
      },
      invalidatesTags: ['Favorite', 'Favorites'],
    }),
    updateFavorite: builder.mutation<Response<any>, Favorite>({
      query(data: Favorite) {
        return api.PUT({
          data,
        });
      },
      invalidatesTags: ['Favorite', 'Favorites'],
    }),
    addFavorite: builder.mutation<Response<any>, Favorite>({
      query(data: Favorite) {
        return api.POST({
          data,
        });
      },
      invalidatesTags: ['Favorite', 'Favorites'],
    }),
  }),
});

export const {
  useGetFavoriteQuery,
  useGetFavoritesQuery,
  useDeleteFavoriteMutation,
  useUpdateFavoriteMutation,
  useAddFavoriteMutation,
} = favoritesApi;
