import { RequestStatusEnum } from '../../mock-data/request-status-enum';
import { StatusColor } from '../../mock-data/status-color-enum';

type ColorObjectType = {
  textColor: string;
  color: string;
};

export const getStatusColor = (value: string): ColorObjectType => {
  switch (value) {
    case StatusColor.success:
      return {
        color: '#EBF8EA',
        textColor: '#318C2E',
      };
    case StatusColor.pending:
      return {
        color: '#F4E5F9',
        textColor: '#621B7C',
      };
    case StatusColor.reject:
      return {
        color: '#FBE9EA',
        textColor: '#A3212A',
      };
    case StatusColor.submitted:
      return {
        color: '#E6FBFD',
        textColor: '#00949E',
      };
    case StatusColor.suspend:
      return {
        color: '#FEF6EE',
        textColor: '#ED861A',
      };
    case StatusColor.pendingOrNew:
      return {
        color: '#E0EDFF',
        textColor: '#0044A2',
      };
    default:
      return {
        color: '#F4F3F8',
        textColor: '#7B819B',
      };
  }
};

export const getRequestStatusColor = (value?: number) => {
  let requestType = '';

  switch (value) {
    case RequestStatusEnum.Included:
      requestType = StatusColor.success;
      break;
    case RequestStatusEnum.DogmForInclude:
    case RequestStatusEnum.WaitForElectronicAccept:
      requestType = StatusColor.pending;
      break;
    case RequestStatusEnum.Excluded:
    case RequestStatusEnum.IncludeDeclined:
    case RequestStatusEnum.ApplicantRefused:
      requestType = StatusColor.reject;
      break;
    case RequestStatusEnum.DocumentAccepted:
    case RequestStatusEnum.TestCompleted:
    case RequestStatusEnum.AcceptedElectronically:
    case RequestStatusEnum.NeedTest:
    case RequestStatusEnum.ForInclude:
      requestType = StatusColor.submitted;
      break;
    case RequestStatusEnum.Suspended:
      requestType = StatusColor.suspend;
      break;
    case RequestStatusEnum.New:
    case RequestStatusEnum.UnderConsideration:
      requestType = StatusColor.pendingOrNew;
      break;
    default:
      requestType = '';
  }

  return getStatusColor(requestType);
};

/*
Зачислен
5
Ожидание
4, 8, 10, 16
Поданы документы
2, 3, 17
Отказ 
6, 7, 15
Приостанов
11,
На рассмотрении или новое
13, 14
По-умолчанию
1, 9, 12
*/

export const messageResponse = {
  success: 'Действие выполнено успешно!',
};
