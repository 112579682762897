import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';
import PageHeading from '../../components/header/page-heading';
import useClearHistoryState from '../../hooks/use-clear-history-state';
import { historyState } from '../../mock-data/history-state';
import OrganizationTable from './table';
import OrganizationSearch from './search';
import { SearchOrganizationInitialFormData } from '../../types/organization';
import { hasGeneralAccess, replaceHistoryState } from '../../lib/utils';
import { generalAccess } from '../../mock-data/access-enum';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';

export const searchOrganizationInitialFormData = {
  query: '',
  excludeTest: true,
  showArchive: false,
  showNotAccepted: false,
  excludeWoVedomstvo: false,
  organizationNotDefined: false,
};

const OrganizationList = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdminView = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const [search, setSearch] = useState<SearchOrganizationInitialFormData>(
    window.history.state[historyState.search] || searchOrganizationInitialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchOrganizationInitialFormData) => {
    setSearch(values);
    replaceHistoryState({ [historyState.search]: values });
  };

  return (
    <>
      <PageHeading title="Организации" sections={[{ title: 'Главная', link: routes.main }, { title: 'Организации' }]} />

      <Push size={12} />
      <OrganizationSearch
        submitForm={handleSubmitSearch}
        isAdminEdit={isAdminEdit}
        isAdminView={isAdminView}
        initialForm={search}
      />
      <Push size={12} />
      <OrganizationTable isAdminEdit={isAdminEdit} isAdminView={isAdminView} search={search} />
    </>
  );
};

export default OrganizationList;
