import { useCallback, useEffect, useState } from 'react';
import serviceClassApi from '../lib/api/service-class';
import { ServiceClassPupil, ServiceClassPupilsListParams } from '../types/service-class';

export const useFetchTable = (
  pageSize: number,
  setLoading: (value: boolean) => void,
  pageNum: number,
  setPageNum: (num: number) => void,
  active: boolean,
  setLearners: (value: ServiceClassPupil[]) => void,
  setTotalCount: (value: number) => void,
  serviceClassId: number | undefined,
  educationId: number
): [fetchTable: () => void, setSearch: (value: string) => void, searchString: string, filterLearners: () => void] => {
  const [searchString, setSearchString] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const fetchTable = useCallback(async () => {
    setLoading(true);

    try {
      const data = await serviceClassApi.getServiceClassLearnerList({
        serviceClassId,
        educationTypeId: educationId,
        pageSize,
        pageNumber: pageNum + 1,
        showExcluded: active,
        searchString: searchValue,
      } as ServiceClassPupilsListParams);

      setLearners(
        data.items?.map((e: ServiceClassPupil, i: number) => {
          return { ...e, count: pageSize * pageNum + i + 1 };
        })
      );
      setTotalCount(data.total);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [active, educationId, pageNum, pageSize, serviceClassId, setLearners, setLoading, setTotalCount, searchValue]);

  useEffect(() => {
    fetchTable();
  }, [educationId, pageSize, pageNum, active, fetchTable]);

  const setSearch = (value: string) => setSearchString(value);

  const filterLearners = () => {
    setPageNum(0);
    setSearchValue(searchString);
  };

  return [fetchTable, setSearch, searchString, filterLearners];
};
