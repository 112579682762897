import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, notify, Panel, Loader, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { PersonRequestAddressData } from '../../../types/requests';
import Street from '../components/fields/street';
import BuildingInfo from '../components/fields/building-info';
import Apartment from '../components/fields/apartment';
import District from '../components/fields/district';
import Territory from '../components/fields/territory';
import requestsApi from '../../../lib/api/requests';
import { addressValidationSchema } from './create';

type Props = {
  address: PersonRequestAddressData | null;
  setEditModeParent: (value: string | null) => void;
  editable: boolean;
  requestId: number;
  educationTypeId: number;
  updateRequest: () => void;
};

const initialValues = {
  street: '',
  home: '',
  building: '',
  ownership: '',
  structure: '',
  apartment: '',
  county: '',
  district: '',
};

const AddressForm: React.FC<Props> = ({
  updateRequest,
  address,
  educationTypeId,
  requestId,
  setEditModeParent,
  editable,
}) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(address, addressValidationSchema);
  const [formKey, setFormKey] = useState(Math.random());
  const [loading, setLoading] = useState(false);

  const submitForm = useCallback(
    async (values: PersonRequestAddressData) => {
      setLoading(true);
      try {
        let param = { requestId, educationTypeId };

        if (!address) {
          param = { ...param, ...{ isRegAddress: true } };
          await requestsApi.postPersonAddress({ ...values, ...param });
          updateRequest();
        } else {
          await requestsApi.saveRequestPersonAddress({ ...values, ...param });
          updateRequest();
        }
      } catch (e) {
        setFormKey(Math.random());
        notify.danger({
          data: { label: 'Не удалось сохранить адрес регистрации ученика', icon: true },
        });
      } finally {
        setLoading(false);
      }
    },
    [address, educationTypeId, requestId, updateRequest]
  );

  return loading ? (
    <Panel>
      <div className="loader-container">
        <Loader roller small />
      </div>
    </Panel>
  ) : (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={addressValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={address ?? initialValues}
    >
      {(formikProps: FormikProps<PersonRequestAddressData>) => {
        const { handleSubmit, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация об адресе регистрации по месту жительства ребенка'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('address');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <Street name="street" editMode={editMode} />
                  <BuildingInfo name="" editMode={editMode} />
                  <Apartment name="roomNumber" editMode={editMode} />
                  <District name="district" editMode={editMode} />
                  <Territory name="area" editMode={editMode} />
                </div>
              </div>
            </Panel>

            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                          setFormKey(Math.random());
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={() => {
                          handleSubmit();
                          setEditModeParent(null);
                          setEditMode(false);
                        }}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
