import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import {
  SearchGroupTrainingInitialFormData,
  SearchGroupTrainingInitialFormDataAddition,
} from '../../../../types/service-class';
import lookupApi from '../../../../lib/api/lookup';
import { AuthorizationData, generalAccess } from '../../../../types/authorization-data';
import { hasGeneralAccess } from '../../../../lib/utils';
import { usePrevious } from '../../../../hooks/use-previous';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
};

export const FieldOrganization: React.FC<Props> = ({ isAdmin, userProfile }) => {
  const { setFieldValue, values } = useFormikContext<
    SearchGroupTrainingInitialFormData & SearchGroupTrainingInitialFormDataAddition
  >();

  const [rerender, setRerender] = useState<number>(0);

  const accessMain = hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) || isAdmin;
  const prevVedomstvoId = usePrevious(values.vedomstvoId);
  const prevOrganizationId = usePrevious(values.organizationId);

  const canEdit = () => {
    if (isAdmin && !values.vedomstvoId) {
      return false;
    }
    if (accessMain) {
      return true;
    }
  };

  const hasOrganizationValue =
    values.organizationName && values.organizationId
      ? {
          label: values.organizationName ?? '',
          value: values.organizationId ?? '',
        }
      : null;

  const defaultValue = !accessMain
    ? {
        label: userProfile.organizationName ?? '',
        value: userProfile.organizationId ?? '',
      }
    : hasOrganizationValue;

  useEffect(() => {
    // если мы меняем ведомство(департамент), то обнуляем значение организации
    if (values.vedomstvoId !== prevVedomstvoId && prevOrganizationId) {
      setFieldValue('organizationId', null);
      setFieldValue('organizationName', null);
      setRerender(Math.random());
    }
  }, [prevOrganizationId, prevVedomstvoId, setFieldValue, values.organizationId, values.vedomstvoId]);

  return (
    <FormikFormGroup name="organizationId" label="Наименование образовательной организации">
      <FormikSelect
        name="organizationId"
        size="small"
        isSearchable
        options={[]}
        key={rerender}
        disabled={!canEdit()}
        placeholder={!canEdit() ? 'Сначала выберите департамент' : 'Начните вводить...'}
        loadOptions={async (query) => await lookupApi.getOrganization(query, values.vedomstvoId)}
        defaultValue={defaultValue}
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('organizationName', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};
