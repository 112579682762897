import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FilterOptionType, SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { usePrevious } from '../../../../../hooks/use-previous';

const FieldParallel: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const [parallelIdKey, setParallelIdKey] = useState<number>(0);

  const prevParallelId = usePrevious(values.parallelId);
  const prevOrganizationId = usePrevious(values.organizationId);

  useEffect(() => {
    if (values.organizationId !== prevOrganizationId) {
      const fetchParallels = async () => {
        if (!values.organizationId) {
          return;
        }

        if (!values.parallelData?.length) {
          const parallels = await dictionariesApi.getParallels(values.organizationId as number);
          setFieldValue('parallelData', parallels);
        }
      };

      fetchParallels();
    }
  }, [
    prevOrganizationId,
    prevParallelId,
    setFieldValue,
    values.organizationId,
    values.parallelData?.length,
    values.parallelId,
  ]);

  useEffect(() => {
    // При обнулении "Организации" обнуляем значение "Параллель"
    if (values.organizationId !== prevOrganizationId && prevParallelId) {
      setFieldValue('parallelId', null);
      setFieldValue('parallelName', null);
      setFieldValue('parallelData', null);
      setParallelIdKey(Math.random());
    }
  }, [setFieldValue, values.vedomstvoId, prevOrganizationId, values.parallelId, prevParallelId, values.organizationId]);

  const disabled = Boolean(!values.organizationId);

  const filterOption = (option: SelectOptionType, currentValue: string) => Boolean(option.label.includes(currentValue));

  return (
    <FormikFormGroup name="parallelId" label="Параллель">
      <FormikSelect
        size="small"
        isSearchable={false}
        filterOption={filterOption as FilterOptionType}
        name="parallelId"
        key={parallelIdKey}
        options={values.parallelData ?? []}
        placeholder={disabled ? 'Сначала выберите организацию' : 'Выберите...'}
        disabled={disabled}
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('parallelName', v.label);
          }
        }}
        showClearIndicator
      />
    </FormikFormGroup>
  );
};

export default FieldParallel;
