import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export function timeMaskFunction(value: string, hours24?: boolean): (string | RegExp)[] {
  const chars = value.split('');

  let hours;
  if (chars[0] === '0' || chars[0] === '1' || chars[0] === '2') {
    if (chars[1] === ':') {
      // only one digit before the colon: "0", "1", "2"
      hours = [/[0-2]/];
    } else {
      // two digits: 00, 01, 02, 03, 04, ..., 18, 19, 20, 21, 22, 23

      hours = [/[0-2]/, chars[0] === '2' ? (hours24 ? /[0-4]/ : /[0-3]/) : /[0-9]/];
    }
  } else {
    // one digit greater than two: 3, 4, 5, 6, 7, 8, 9
    hours = [/[3-9]/];
  }
  // minutes is always two-digits
  let minutes = [/[0-5]/, /[0-9]/];
  if (chars[0] === '2' && chars[1] === '4') {
    minutes = [/[0]/, /[0]/];
  }

  return [...hours, ':', ...minutes];
}

export const timeMask = /^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/;

export const priceMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  decimalSymbol: ',',
  includeThousandsSeparator: false,
});

export const fullDateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
export const mountYearDateMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
export const yearDateMask = [/\d/, /\d/, /\d/, /\d/];
export const autoCorrectedFullDate = createAutoCorrectedDatePipe('dd/mm/yyyy');
export const autoCorrectedMountYearDate = createAutoCorrectedDatePipe('mm/yyyy');
export const phoneMask = [
  '+',
  '7',
  ' ',
  '(',
  /[0-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
