import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'connected-react-router';
import { AnyAction, Reducer, combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { persistStorageKey } from '../config/constants';
import userProfile, { moduleName as profileModule } from '../redux/ducks/user-profile';
import aupdOrganizations, { moduleName as aupdOrganizationsModule } from '../redux/ducks/aupd-organizations';
import appReducer, { moduleName as appModule } from '../redux/ducks/app';
import userSystem, { moduleName as systemSettings } from '../redux/ducks/system-settings';
import history from '../history';
import { favoritesApi } from './favorites';
import { homeApi } from './home';
import { staticContentApi } from './static-content';
import { eventApi } from './event';

const persistConfig = {
  key: persistStorageKey,
  storage,
  blacklist: [
    'router',
    profileModule,
    aupdOrganizationsModule,
    favoritesApi.reducerPath,
    homeApi.reducerPath,
    staticContentApi.reducerPath,
    eventApi.reducerPath,
  ],
};

const reducer = combineReducers({
  router: connectRouter(history),
  [appModule]: appReducer,
  [profileModule]: userProfile,
  [systemSettings]: userSystem,
  [aupdOrganizationsModule]: aupdOrganizations,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [staticContentApi.reducerPath]: staticContentApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  // Сброс кэша запросов при разлогинивании
  if (state && !state[appModule].authorized) {
    state = {
      router: state.router,
      [appModule]: state[appModule],
      [profileModule]: state[profileModule],
      [systemSettings]: state[systemSettings],
      [aupdOrganizationsModule]: state[aupdOrganizationsModule],
    };
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(favoritesApi.middleware, homeApi.middleware, staticContentApi.middleware, eventApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
