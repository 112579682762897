import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader, Push } from '@mosru/esz_uikit';
import { useGetFromRoute } from '../../hooks/get-from-route';
import { classificatorEKULimitApi } from '../../lib/api/classificator-EKU-limit';
import { dictionariesApi } from '../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../lib/api/service-template';
import { EducationTypeEnum } from '../../types/education-type';
import { BudgetPlacesData, NumberSeatsAdmissionPlan, ServiceData, TemplateService } from '../../types/service';
import ServiceDKGM from './dkgm-dsit';
import ServiceDayCare from './day-care';
import ServiceChild from './child';
import DigitalTutor from './digital-tutor';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { adminAccess, hasGeneralAccess } from '../../lib/utils';
import {
  accessByEducationTypeService,
  accessPanelEdit,
  getHeaderLink,
  organizationAccess,
  setGenderStartEndFromBack,
  toArchive,
} from './utils';
import { useGetDataEducationTypes } from '../../hooks/get-education-types';
import { accessAction, accessObject, accessVedomst, generalAccess } from '../../mock-data/access-enum';
import { AuthorizationData } from '../../types/authorization-data';
import { RegistryTypeEnum } from '../../mock-data/registry-type-enum';
import ServiceProgram from './preparation';
import TemplateServices from './template-services';
import { templateApi } from '../../lib/api/template';

export type ServiceContextType = {
  serviceData: ServiceData & TemplateService.Data;
  budgetPlaces?: BudgetPlacesData;
  updateService: () => void;
  updateBudgetPlaces: () => void;
  adminView?: boolean;
  adminEdit?: boolean;
  admin?: boolean;
  toArchive?: () => Promise<void>;
  educationTypes: number[];
  type: RegistryTypeEnum;
  organizationEdit: boolean;
  userProfile: AuthorizationData;
  accessPanelEdit: boolean;
  accessAddArchive?: boolean;
  canRecover: boolean;
  isDsit: boolean;
  isKdc: boolean;
  setNumberSeatsAdmissionPlan: (value: NumberSeatsAdmissionPlan[]) => void;
  numberSeatsAdmissionPlan: NumberSeatsAdmissionPlan[];
  routeBack: string;
};

export const ServiceContext = createContext<ServiceContextType>({} as ServiceContextType);

const Service = (type: RegistryTypeEnum, isTemplate = false) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const fromRoute = useGetFromRoute();

  const { id } = useParams<Record<string, string | undefined>>();
  const [service, setService] = useState<ServiceData | undefined>(undefined);
  const [budgetPlaces, setBudgetPlaces] = useState<BudgetPlacesData>();
  const [currentYearOfTrainings, setCurrentYearOfTrainings] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const educationTypeData = useGetDataEducationTypes(userProfile, accessObject.Services, accessAction.ViewRegistry);
  const currentEducationTypes = educationTypeData?.map((item) => item.value) as EducationTypeEnum[];

  const [numberSeatsAdmissionPlan, setNumberSeatsAdmissionPlan] = useState<NumberSeatsAdmissionPlan[]>([]);

  const getService = useCallback(async (): Promise<void> => {
    if (id) {
      try {
        setIsLoading(true);
        const data = isTemplate
          ? await templateApi.getTemplate(id)
          : setGenderStartEndFromBack(await serviceTemplateApi.getServiceById(id));
        if (data.schedule.scheduleTypeOfServiceId === 2) {
          data.schedule.scheduleTypeOfServiceName = 'Уточняется в процессе зачисления';
        }
        setService(data);
      } finally {
        setIsLoading(false);
      }
    }
  }, [id, isTemplate]);

  const getBudgetPlaces = useCallback(async () => {
    if (!service || service.educationTypeId !== EducationTypeEnum.ProfessionalEducation || !currentYearOfTrainings) {
      return;
    }

    try {
      const data = await classificatorEKULimitApi.getBudgetPlaces({
        educationTypeId: service.educationTypeId,
        organizationId: service.info.organizationId,
        classificatorEKUId: service.info.classificatorEKUId,
        yearOfTrainingId: currentYearOfTrainings,
      });

      setBudgetPlaces({
        limitVolume: data[0].limitVolume,
        volume: data[0].volume,
      });
    } catch (error) {
      setBudgetPlaces(undefined);
    }
  }, [service, currentYearOfTrainings]);

  const getCurrentYear = async () => {
    try {
      const currentYear = await dictionariesApi.getCurrentYearOfTrainings();
      setCurrentYearOfTrainings(currentYear.id);
    } catch (error) {
      setBudgetPlaces(undefined);
    }
  };

  useEffect(() => {
    getService();
  }, [id, getService]);

  useEffect(() => {
    service?.educationTypeId === EducationTypeEnum.ProfessionalEducation && getCurrentYear();
  }, [service?.educationTypeId]);

  useEffect(() => {
    getBudgetPlaces();
  }, [service, currentYearOfTrainings, getBudgetPlaces]);

  const currentService = () => {
    switch (service?.educationTypeId) {
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        return <ServiceChild />;
      case EducationTypeEnum.SportEducation:
      case EducationTypeEnum.ArtHouseEducation:
        return isTemplate ? <TemplateServices /> : <ServiceDKGM />;
      case EducationTypeEnum.DayCareCentersEducation:
        return <ServiceDayCare />;
      case EducationTypeEnum.VirtualAssistantEducation:
        return <DigitalTutor />;
      case EducationTypeEnum.ProfessionalEducation:
        return <ServiceProgram />;
      default:
        return null;
    }
  };

  const access = useMemo(() => {
    return {
      adminView: adminAccess('view', userProfile),
      adminEdit: adminAccess('edit', userProfile),
      admin: adminAccess('all', userProfile),
      organizationEdit: organizationAccess(userProfile),
      accessPanelEdit: accessPanelEdit(
        userProfile,
        adminAccess('edit', userProfile),
        service?.educationTypeId as EducationTypeEnum,
        isTemplate
      ),
      accessAddArchive: accessByEducationTypeService(
        userProfile,
        Number(service?.educationTypeId),
        accessAction.Archive
      ),
      isDsit: userProfile.vedomstvoId === accessVedomst.Dsit,
      isKdc: userProfile.vedomstvoId === accessVedomst.Kdc,
      canRecover:
        (hasGeneralAccess(userProfile, generalAccess.AdminView) &&
          (service?.educationTypeId !== EducationTypeEnum.ArtHouseEducation ||
            service?.educationTypeId === EducationTypeEnum.SportEducation ||
            service?.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation)) ||
        accessByEducationTypeService(userProfile, Number(service?.educationTypeId), accessAction.RecoverFromArchive),
    };
  }, [userProfile, service?.educationTypeId, isTemplate]);

  const initialValues: ServiceContextType = useMemo(() => {
    return {
      serviceData: service as ServiceData,
      budgetPlaces,
      updateService: getService,
      updateBudgetPlaces: getBudgetPlaces,
      toArchive: async () => toArchive(id as string),
      educationTypes: currentEducationTypes,
      type,
      userProfile,
      numberSeatsAdmissionPlan,
      setNumberSeatsAdmissionPlan,
      ...access,
      routeBack: fromRoute || getHeaderLink(currentEducationTypes, type, (service as ServiceData)?.educationTypeId),
    };
  }, [
    access,
    budgetPlaces,
    currentEducationTypes,
    getBudgetPlaces,
    getService,
    id,
    numberSeatsAdmissionPlan,
    service,
    type,
    userProfile,
    fromRoute,
  ]);

  return (
    <>
      {service ? (
        <ServiceContext.Provider value={initialValues}>{currentService()}</ServiceContext.Provider>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto loader-auto--bg">
            <Loader roller />
          </div>
        </>
      ) : null}
    </>
  );
};

export default Service;
