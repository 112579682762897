import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageHeading from '../../components/header/page-heading';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { authApi } from '../../lib/api';
import { siteMapList } from '../../mock-data/routes';
import { MenuSettings } from '../../types/entities';
import TreeLinkWrapper from '../../components/tree-link-wrapper';
import { routes } from '../../config/constants';
import { sendReachGoal } from '../../lib/metrica';

const SiteMap = () => {
  const [menuSetting, setMenuSetting] = useState<MenuSettings[]>([]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const fetchData = async () => {
      const settings = await authApi.getMenuSettings();
      setMenuSetting(settings.data);
    };
    fetchData();
    sendReachGoal('sitemap');
  }, []);

  return (
    <>
      <PageHeading title="Карта сайта" sections={[{ title: 'Главная', link: routes.main }, { title: 'Карта сайта' }]} />
      <TreeLinkWrapper userProfile={userProfile} itemList={siteMapList} menuSetting={menuSetting} isOpened />
    </>
  );
};

export default SiteMap;
