import React, { useState } from 'react';
import { Button, ModalPanel, Modal, Tooltip, Push } from '@mosru/esz_uikit';
import { ReactComponent as Stars } from '../../assets/images/icons/stars.svg';
import { ReactComponent as StarsFill } from '../../assets/images/icons/stars-fill.svg';
import ModalFavorite from './modal';
import { useDeleteFavoriteMutation } from '../../store/favorites';

type Props = {
  entityId: number;
  entityTypeId: number;
  typeEntity?: string;
  currentEntityType: string;
};

const Favorite: React.FC<Props> = ({ ...props }) => {
  const [id, setId] = useState<number>();

  const [entityId, setEntityId] = useState<number>();
  const [commentTooltip, setCommentTooltip] = useState<string>('');

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openDeletePopup, setDeletePopup] = useState<boolean>(false);

  const openPopupHandler = () => setOpenPopup(true);
  const closePopupHandler = () => setOpenPopup(false);

  const openDeletePopupHandler = () => setDeletePopup(true);
  const closeDeletePopupHandler = () => setDeletePopup(false);

  const [deleteFavoriteQuery] = useDeleteFavoriteMutation();

  const deleteFavorite = async () => {
    if (id) {
      deleteFavoriteQuery(id);
      setEntityId(0);
      closeDeletePopupHandler();
    }
  };

  return (
    <>
      <div
        data-test="favorite"
        className="cursor-pointer"
        role="presentation"
        onClick={entityId ? openDeletePopupHandler : openPopupHandler}
      >
        {!entityId ? <Stars /> : <Tooltip component={() => <StarsFill />} position="right" text={commentTooltip} />}
      </div>
      <ModalFavorite
        setId={setId}
        show={openPopup}
        resetComment={entityId}
        entityId={props.entityId}
        setEntityId={setEntityId}
        typeEntity={props.typeEntity}
        entityTypeId={props.entityTypeId}
        setCommentTooltip={setCommentTooltip}
        title="Добавление в избранное"
        onCloseHandler={() => closePopupHandler()}
        currentEntityType={props.currentEntityType}
      />

      <Modal show={openDeletePopup} onClose={closeDeletePopupHandler}>
        <ModalPanel
          alert
          onClose={() => setDeletePopup(false)}
          modalTitle={`Вы уверены, что хотите убрать ${props.typeEntity} из избранного?`}
          renderComponent={() => (
            <div>
              Вы уверены, что хотите убрать{' '}
              <b>
                {props.typeEntity} {props.currentEntityType}
              </b>{' '}
              из избранного? Обратите внимание, что комментарий будет удален.
            </div>
          )}
          controls={() => (
            <>
              <Button label="Нет, вернуть" primary border size="small" onClick={closeDeletePopupHandler} />
              <Push orientation="horizontal" size={12} />
              <Button label="Да, убрать" primary size="small" onClick={deleteFavorite} />
            </>
          )}
        />
      </Modal>
    </>
  );
};

export default Favorite;
