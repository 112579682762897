import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Switcher, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';

type Props = { editMode: boolean; isCreate?: boolean; parent?: string; disabled?: boolean };

enum GenderEnum {
  all = 'all',
  male = 'male',
  female = 'female',
}

export const GroupMembersField: React.FC<Props> = ({ editMode, parent, disabled }) => {
  const start = parent ? `${parent}.altStart` : `altStart`;
  const end = parent ? `${parent}.altEnd` : `altEnd`;

  const [startField] = useField(start);
  const [endField] = useField(end);

  const isFemalePresentFieldName = parent ? `${parent}.isFemalePresent` : `isFemalePresent`;
  const isMalePresentFieldName = parent ? `${parent}.isMalePresent` : `isMalePresent`;

  const [isFemalePresentField, , isFemalePresentFieldHelper] = useField(isFemalePresentFieldName);
  const [isMalePresentField, , isMalePresentFieldHelper] = useField(isMalePresentFieldName);

  const [gender, setGender] = useState<GenderEnum>(GenderEnum.all);

  useEffect(() => {
    if (isFemalePresentField.value && isMalePresentField.value) {
      setGender(GenderEnum.all);
    } else if (!isFemalePresentField.value && isMalePresentField.value) {
      setGender(GenderEnum.male);
    } else {
      setGender(GenderEnum.female);
    }
  }, [isFemalePresentField.value, isMalePresentField.value]);

  const onChangeGender = (type: GenderEnum) => {
    switch (type) {
      case GenderEnum.male:
        isFemalePresentFieldHelper.setValue(false);
        isMalePresentFieldHelper.setValue(true);
        break;

      case GenderEnum.female:
        isFemalePresentFieldHelper.setValue(true);
        isMalePresentFieldHelper.setValue(false);
        break;

      default:
        isFemalePresentFieldHelper.setValue(true);
        isMalePresentFieldHelper.setValue(true);
    }
  };

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Состав группы {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="flex items-start">
            <Switcher size="small">
              <button
                type="button"
                onClick={() => onChangeGender(GenderEnum.all)}
                className={gender === GenderEnum.all ? 'active' : ''}
              >
                Все
              </button>
              <button
                type="button"
                onClick={() => onChangeGender(GenderEnum.male)}
                className={gender === GenderEnum.male ? 'active' : ''}
              >
                Муж
              </button>
              <button
                type="button"
                onClick={() => onChangeGender(GenderEnum.female)}
                className={gender === GenderEnum.female ? 'active' : ''}
              >
                Жен
              </button>
            </Switcher>
            <Push size={16} orientation="horizontal" />
            <div style={{ width: 80 }}>
              <FormikFormGroup required label="" name={start}>
                <FormikInput
                  size="small"
                  number
                  placeholder="0"
                  name={start}
                  onKeyPress={(e: any) => allowOnlyNumbers(e)}
                  disabled={disabled}
                />
              </FormikFormGroup>
            </div>

            <Push size={8} orientation="horizontal" />
            <div>
              <div className="table-row-item-height flex items-center">—</div>
            </div>
            <Push size={8} orientation="horizontal" />

            <div style={{ width: 80 }}>
              <FormikFormGroup required label="" name={end} helpNowrap>
                <FormikInput
                  number
                  size="small"
                  placeholder="0"
                  name={end}
                  onKeyPress={(e: any) => allowOnlyNumbers(e)}
                  disabled={disabled}
                />
              </FormikFormGroup>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="table-data__group">
              <div className="table-data__label">Пол</div>
              <div className="table-data__body" style={{ width: 218 }}>
                {gender === 'all' ? 'Все' : gender === 'male' ? 'Муж' : 'Жен'}
              </div>
            </div>
            <Push size={16} orientation="horizontal" />
            <div className="table-data__group">
              <div className="table-data__label">Возраст</div>
              <div className="table-data__body">{`${startField.value} - ${endField.value}`} лет</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
