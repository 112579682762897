import React, { useContext, useEffect, useMemo, useState } from 'react';
import { number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { accessVedomst } from '../../../../mock-data/access-enum';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ServiceDataInfo, ServiceEditTextFileData, ServiceFileTextType } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { convertParallelInSelect } from '../../utils';
import { ServiceContext } from '../..';
import { ParallelOptionsByResponse } from '../../components/fields/parallel';
import Info from '../components/info';
import { durationOfTrainingDsitDkgm, isFloorPresent } from '../../../../lib/utils/validation';
import { ReactFilesFile } from '../../../../types/files';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const InfoPanel = ({ setEditModeParent }: Props) => {
  const { serviceData, accessPanelEdit, updateService } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [programmFile, setProgrammFile] = useState<ServiceFileTextType>();
  const [ruleFile, setRuleFile] = useState<ServiceFileTextType>();
  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  useEffect(() => {
    if ((serviceData.info?.fileProgrammServiceId ?? 0) > 0) {
      // await serviceTemplateApi.setTextFiles(values.serviceId, values.educationTypeId, file, undefined );
      setProgrammFile({
        id: (serviceData.info.fileProgrammServiceId ?? 0).toString(),
        name: serviceData.info.fileProgrammServiceName,
      } as ReactFilesFile);
    }
  }, [serviceData.info?.fileProgrammServiceId, serviceData.info?.fileProgrammServiceName]);

  useEffect(() => {
    if ((serviceData.info?.fileRuleServiceId ?? 0) > 0) {
      // await serviceTemplateApi.setTextFiles(values.serviceId, values.educationTypeId, file, undefined );
      setRuleFile({
        id: (serviceData.info.fileRuleServiceId ?? 0).toString(),
        name: serviceData.info.fileRuleServiceName,
      } as ReactFilesFile);
    }
  }, [serviceData.info?.fileRuleServiceId, serviceData.info?.fileRuleServiceName]);

  const initialData = useMemo(() => {
    if (serviceData.info) {
      return {
        ...serviceData.info,
        parallelList: convertParallelInSelect(serviceData?.info?.parallelList as ParallelOptionsByResponse[]),
      };
    } else {
      return {} as ServiceDataInfo;
    }
  }, [serviceData.info]);

  const hasHoursPerWeek = serviceData.info?.vedomstvoId === accessVedomst.Dkgm;

  const initialErrors = useInitialErrors(serviceData.info, infoValidationSchema(hasHoursPerWeek));

  const submitProgrammAndRules = async (serviceId: number, educationTypeId: number) => {
    const data: ServiceEditTextFileData = {
      serviceId,
      educationTypeId,
      isRuleServiceDeleted: typeof ruleFile === 'boolean' ? ruleFile : false,
      isProgrammServiceDeleted: typeof programmFile === 'boolean' ? programmFile : false,
      programmService:
        typeof programmFile !== 'boolean' && programmFile?.id && programmFile.id.startsWith('file')
          ? programmFile
          : undefined,
      ruleService:
        typeof ruleFile !== 'boolean' && ruleFile?.id && ruleFile.id.startsWith('file') ? ruleFile : undefined,
    };

    if (data.isRuleServiceDeleted || data.isProgrammServiceDeleted || data.ruleService || data.programmService) {
      await serviceTemplateApi.setTextFiles(data);
    }
  };

  const submitForm = async (values: ServiceDataInfo) => {
    setLoadBtn(true);
    if (values?.serviceId) {
      try {
        await serviceTemplateApi.updateService(values.serviceId, values);
        await submitProgrammAndRules(values.serviceId, values.educationTypeId);
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        setLoadBtn(false);
        updateService();
      } finally {
        setLoadBtn(false);
      }
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialData}
      validationSchema={infoValidationSchema(hasHoursPerWeek)}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceDataInfo>) => {
        const { isValid, handleSubmit, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Info
              setEditModeParent={setEditModeParent}
              editMode={editMode}
              setEditMode={setEditMode}
              checkEditable={checkEditable}
              programmFile={programmFile}
              setProgrammFile={setProgrammFile}
              ruleFile={ruleFile}
              setRuleFile={setRuleFile}
              isVisibleHoursPerWeek={hasHoursPerWeek}
            />
            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        border
                        primary
                        label="Отмена"
                        handleClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button submit primary load={loadBtn} label="Сохранить" disabled={!isValid} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default InfoPanel;

export const infoValidationSchema = (isHoursPerWeek?: boolean) =>
  objectYup().shape({
    name: stringYup().nullable().required('Введите наименование').nullable(),
    classificatorEKUId: stringYup().nullable().required('Выберите реестр базовых направлений'),
    durationOfTraining: durationOfTrainingDsitDkgm,
    programmService: stringYup().nullable().required('Введите описание услуги'),
    programmLevelId: stringYup().nullable().required('Выберите уровень программы'),
    hoursPerWeek: isHoursPerWeek
      ? numberYup()
          .nullable()
          .required('Введите количество часов в неделю')
          .min(1, 'минимальное количество часов в неделю 1')
      : numberYup().optional().nullable(),
    altStart: stringYup()
      .nullable()
      .test('altStart', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isMalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст от' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
        }
        return true;
      }),
    altEnd: stringYup()
      .nullable()
      .test('altEnd', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isMalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст до' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
          if (parent1?.value?.altStart && parent1?.value?.altStart > parseInt(value)) {
            return ctx.createError({ message: 'Максимальный возраст должен быть больше, чем минимальный' });
          }
        }
        return true;
      }),
    allStart: stringYup()
      .nullable()
      .test('allStart', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст от' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
        }
        return true;
      }),
    allEnd: stringYup()
      .nullable()
      .test('allEnd', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст до' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
          if (parent1?.value?.allStart && parent1?.value?.allStart > parseInt(value)) {
            return ctx.createError({ message: 'Максимальный возраст должен быть больше, чем минимальный' });
          }
        }
        return true;
      }),
    altFemaleStart: stringYup()
      .nullable()
      .test('altFemaleStart', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isFemalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст от' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
        }
        return true;
      }),
    altFemaleEnd: stringYup()
      .nullable()
      .test('altFemaleEnd', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isFemalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст до' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
          if (parent1?.value?.altFemaleStart && parent1?.value?.altFemaleStart > parseInt(value)) {
            return ctx.createError({ message: 'Максимальный возраст должен быть больше, чем минимальный' });
          }
        }
        return true;
      }),

    isFemalePresent: isFloorPresent('isFemalePresent'),
    isMalePresent: isFloorPresent('isMalePresent'),
  });
