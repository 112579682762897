import React from 'react';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import TemplateServicesCreateDetails from './create/details';
import history from '../../../history';

const TemplateServicesCreate = () => {
  return (
    <>
      <PageHeading
        buttonBack
        title="Новый шаблон услуги"
        buttonBackFn={() => history.push(routes.myTemplates)}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Шаблоны услуг', link: routes.myTemplates },
          { title: 'Описание шаблона услуги' },
        ]}
      />
      <TemplateServicesCreateDetails />
    </>
  );
};

export default TemplateServicesCreate;
