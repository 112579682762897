import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, ButtonGroup, IconArrowDown, IconArrowLongRight, Push } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { generateLink } from '../../../lib/utils';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import { ReactComponent as IconRefresh } from '../../../assets/images/icons/refresh.svg';
import Favorite from '../../../components/favorite';
import ServiceChildDetails from './details';
import { ServiceContext } from '..';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { getHeaderLink } from '../utils';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import ToArchiveModal from '../components/modals/to-archive';
import RecoverModal from '../components/modals/recover';
import DropDown from '../../../components/drop-down';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ArchiveObstacle } from '../../../types/service';
import ErrorArchiveModal from '../components/modals/error-archive';

const ServiceChild = () => {
  const { serviceData, educationTypes, type, accessPanelEdit, accessAddArchive, canRecover, routeBack } =
    useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalErrorArchive, setShowModalErrorArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);
  const [archiveObstacle, setArchiveObstacle] = useState<ArchiveObstacle>();

  const history = useHistory();

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);

  const [activeTab, setActiveTab] = useState('Основные сведения');

  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <ServiceChildDetails />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name=""
            entityTypes={[EntityTypeEnum.Service]}
            extendEntityGuid={serviceData.extendEntityGuid}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [serviceData.extendEntityGuid]
  );

  return (
    <>
      <PageHeading
        buttonBack
        routeBack={routeBack}
        title="Детское объединение"
        sections={[
          { title: 'Главная', link: routes.main },
          {
            title: 'Детские объединения',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          { title: 'Описание детского объединения' },
        ]}
        tabsButton={
          <div className="flex items-center">
            <Push size={12} orientation="horizontal" />
            {isArchive ? (
              <Badge text="Архивная" color="default" />
            ) : (
              <Badge
                text={
                  serviceData?.serviceStatusId === ServiceStatusEnum.Signed
                    ? 'Опубликовано на Mos.ru'
                    : 'Не опубликовано на Mos.ru'
                }
                color={serviceData?.serviceStatusId === ServiceStatusEnum.Signed ? 'success' : 'warning'}
              />
            )}
          </div>
        }
        controlsTopRight={
          isArchive ? (
            <ButtonGroup>
              {canRecover && (
                <Button
                  label="Восстановить"
                  iconLeft={() => <IconRefresh />}
                  size="small"
                  handleClick={() => setShowModalRecover(true)}
                />
              )}
              <Button
                handleClick={() =>
                  history.push(
                    generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id, showArchive: true })
                  )
                }
                label="Перейти к группам обучения"
                iconLeft={() => <IconArrowLongRight />}
                size="small"
              />
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              {serviceData.serviceStatusId === ServiceStatusEnum.Draft ? (
                <Button
                  handleClick={() =>
                    history.push(generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id }))
                  }
                  label="Перейти к группам обучения"
                  iconLeft={() => <IconArrowLongRight />}
                  size="small"
                />
              ) : serviceData.serviceStatusId === ServiceStatusEnum.Signed ? (
                <DropDown
                  component={() => (
                    <Button label="Действия с группами обучения" iconRight={() => <IconArrowDown />} size="small" />
                  )}
                >
                  <div className="drop-down-panel">
                    <div className="drop-down-panel__list">
                      <button
                        type="button"
                        onClick={() =>
                          history.push(generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id }))
                        }
                        className="drop-down-panel__list-item"
                      >
                        Перейти к группам обучения
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          history.push(
                            generateLink(
                              routes.createServiceClass,
                              { edType: serviceData.educationTypeId },
                              { serviceId: serviceData.id }
                            )
                          )
                        }
                        className="drop-down-panel__list-item"
                      >
                        Добавить группу обучения
                      </button>
                    </div>
                  </div>
                </DropDown>
              ) : null}
              {checkAccessAddArchive ? (
                <Button
                  label="В архив"
                  iconLeft={() => <IconArchive />}
                  size="small"
                  handleClick={async () => {
                    const data = await serviceTemplateApi.getArchiveObstacle(serviceData.id);
                    if (data.pupilList.length || data.requestList.length) {
                      setShowModalErrorArchive(true);
                      setArchiveObstacle(data);
                    } else {
                      setShowModalArchive(true);
                    }
                  }}
                />
              ) : null}
              <Button
                label="Печать"
                iconLeft={() => <IconPrinter />}
                size="small"
                handleClick={() =>
                  window.open(
                    generateLink(routes.printService, {
                      id: serviceData.id,
                    }),
                    '_blank'
                  )
                }
              />
            </ButtonGroup>
          )
        }
        tabs={tabs}
        activeTab={activeTab}
        expansion={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      {tabs.find((item) => item.label === activeTab)?.component}
      <ToArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
      <RecoverModal onCloseHandler={() => setShowModalRecover(false)} show={showModalRecover} />
      <ErrorArchiveModal
        onCloseHandler={() => setShowModalErrorArchive(false)}
        show={showModalErrorArchive}
        data={archiveObstacle}
      />
    </>
  );
};

export default ServiceChild;
