import React, { useEffect, useState } from 'react';
import { Button, Textarea, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { useAddFavoriteMutation, useGetFavoriteQuery } from '../../store/favorites';
import { Favorite } from '../../types/favorite';

type Props = {
  show: boolean;
  title: string;
  entityId: number;
  entityTypeId: number;
  typeEntity?: string;
  resetComment: number | undefined;
  setId: (value: number | undefined) => void;
  setEntityId: (value: number) => void;
  setCommentTooltip: (value: string) => void;
  onCloseHandler: () => void;
  currentEntityType: string;
};

const ModalFavorite = ({
  setId,
  show,
  typeEntity,
  resetComment,
  onCloseHandler,
  title,
  setEntityId,
  entityId,
  entityTypeId,
  setCommentTooltip,
  currentEntityType,
}: Props) => {
  const [comment, setComment] = useState<string>('');
  const { data } = useGetFavoriteQuery({ entityId, entityTypeId });
  const [addFavorite] = useAddFavoriteMutation();

  useEffect(() => {
    if (data) {
      setId(data.data.id);
      setComment(data.data.comment);
      setCommentTooltip(data.data.comment);
      setEntityId(data.data.entityId);
    }
  }, [data, setCommentTooltip, setEntityId, setId]);

  useEffect(() => {
    if (!resetComment) {
      setComment('');
    }
  }, [resetComment]);

  const save = () => {
    if (comment.trim() === '') {
      return;
    }

    const fetch = async () => {
      const data = {
        comment,
        entityId,
        entityTypeId,
      };
      addFavorite(data as Favorite);
    };

    onCloseHandler();
    fetch();
  };

  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle={title}
        renderComponent={() => (
          <>
            <div>
              {' '}
              Вы собираетесь добавить {typeEntity} {currentEntityType} в Избранные записи.
            </div>
            <Push orientation="vertical" size={25} />
            <div>Управлять избранными записями вы сможете из раздела "Избранное" в главном меню.</div>

            <Push orientation="vertical" size={25} />

            <Textarea
              placeholder="Введите..."
              value={comment}
              rows={3}
              onChange={(value) => setComment(value.target.value)}
            />
          </>
        )}
        controls={() => (
          <>
            <Button primary border label="Отменить" onClick={onCloseHandler} />
            <Push orientation="horizontal" size={12} />
            <Button disabled={!comment} primary label="Добавить" onClick={save} />
          </>
        )}
      />
    </Modal>
  );
};

export default ModalFavorite;
