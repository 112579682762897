import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

type DependentDocumentPropsType = {
  children: any;
};

type DependentDocumentValuesType = {
  docSeriesKey: number;
  docNumberKey: number;
  docDateKey: number;
  birthDateKey: number;
  issuedPlaceKey: number;
  issuedKey: number;
  setDocSeriesKey: Dispatch<SetStateAction<number>>;
  setDocNumberKey: Dispatch<SetStateAction<number>>;
  setDocDateKey: Dispatch<SetStateAction<number>>;
  setBirthDateKey: Dispatch<SetStateAction<number>>;
  setIssuedPlaceKey: Dispatch<SetStateAction<number>>;
  setIssuedKey: Dispatch<SetStateAction<number>>;
};

export const DependentDocumentContext = createContext<DependentDocumentValuesType>({} as DependentDocumentValuesType);

const DependentDocument: React.FC<DependentDocumentPropsType> = ({ children }) => {
  const [docSeriesKey, setDocSeriesKey] = useState<number>(0);
  const [docNumberKey, setDocNumberKey] = useState<number>(0);
  const [docDateKey, setDocDateKey] = useState<number>(0);
  const [birthDateKey, setBirthDateKey] = useState<number>(0);
  const [issuedPlaceKey, setIssuedPlaceKey] = useState<number>(0);
  const [issuedKey, setIssuedKey] = useState<number>(0);

  const values: DependentDocumentValuesType = useMemo(
    () => ({
      docSeriesKey,
      docNumberKey,
      docDateKey,
      birthDateKey,
      issuedPlaceKey,
      issuedKey,
      setDocSeriesKey,
      setDocNumberKey,
      setDocDateKey,
      setBirthDateKey,
      setIssuedPlaceKey,
      setIssuedKey,
    }),
    [
      docSeriesKey,
      docNumberKey,
      docDateKey,
      birthDateKey,
      issuedPlaceKey,
      setDocSeriesKey,
      setDocNumberKey,
      setDocDateKey,
      setBirthDateKey,
      setIssuedPlaceKey,
      issuedKey,
    ]
  );

  return <DependentDocumentContext.Provider value={values}>{children}</DependentDocumentContext.Provider>;
};

export default DependentDocument;
