import React from 'react';
import { useField } from 'formik';
import { Select, SelectOptionType, SelectProps } from '@mosru/esz_uikit';

export type FormicSelectProps = SelectProps & {
  name: string;
  selectedValue?: (value: SelectOptionType) => void;
  defaultValue?: SelectOptionType | null;
};

const FormikSelect: React.FC<Omit<FormicSelectProps, 'onChange'>> = ({
  name,
  selectedValue,
  defaultValue,
  ...props
}) => {
  const [field, , helpers] = useField(name);
  const value = props.options?.find((option) => option.value === field.value);

  return (
    <Select
      {...field}
      {...props}
      value={defaultValue ? value || defaultValue : value}
      onChange={(selectedOption) => {
        let value;
        if (Array.isArray(selectedOption)) {
          value = selectedOption;
        } else {
          value = (selectedOption ? (selectedOption as SelectOptionType) : { value: null }).value;
        }
        helpers.setValue(value, true);
        // костыль, для корректной обработки показа ошибки. Без этого плохо
        // Куплю пиво тому, кто сделает нормально
        setTimeout(() => helpers.setTouched(true), 0);
        if (selectedValue) {
          selectedValue(selectedOption as SelectOptionType);
        }
      }}
    />
  );
};

export default FormikSelect;
