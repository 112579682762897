import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, TabButton, TabGroup, Heading, Switcher, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconBack } from '../../assets/images/icons/back.svg';
import history from '../../history';

type LinkProps = {
  link?: string;
  title: string;
};

type TypeSwitch = {
  id: number;
  name: string;
  selected: boolean;
};

export type TabProps = {
  label: string;
  component: ReactNode;
  onClick: (label: string) => void;
};

export type PageHeadingProps = {
  title?: string;
  description?: string;
  sections?: LinkProps[];
  buttonBack?: boolean;
  tabs?: TabProps[];
  tabsButton?: ReactNode;
  activeTab?: string;
  details?: ReactNode;
  expansion?: ReactNode;
  switcher?: TypeSwitch[];
  controls?: () => ReactNode;
  controlsTopRight?: ReactNode;
  currentSwitcher?: (value: TypeSwitch) => void;
  buttonBackFn?: () => void;
  routeBack?: string;
};

const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  description,
  sections,
  buttonBack,
  tabs,
  tabsButton,
  activeTab,
  details,
  expansion,
  switcher,
  controls,
  controlsTopRight,
  currentSwitcher,
  buttonBackFn,
  routeBack,
}) => {
  const [listSwitcher, setListSwitcher] = useState<TypeSwitch[]>(switcher || []);

  useEffect(() => {
    if (currentSwitcher) {
      const activeElement = listSwitcher.find((item: TypeSwitch) => item.selected);
      if (activeElement) {
        currentSwitcher(activeElement);
      }
    }
  }, [listSwitcher, currentSwitcher]);

  const onSelectedSwitch = (id: number, setData: any) => {
    setData((prevState: TypeSwitch[]) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            selected: true,
          };
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      });
    });
  };

  const getHistory = () => {
    if (routeBack) {
      if (history.length <= 1) {
        history.push(routeBack);
      } else {
        history.goBack();
      }
    }
  };

  const goBack = () => {
    if (routeBack) {
      getHistory();
    } else if (!buttonBackFn) {
      history.goBack();
    } else {
      buttonBackFn();
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-heading">
        <div className="container">
          <div className="flex">
            {sections && (
              <Breadcrumbs>
                {sections.map((item, index) =>
                  item.link ? (
                    <Link data-test={`breadcrumb-link-${index}`} key={item.title} to={item.link}>
                      {item.title}
                    </Link>
                  ) : (
                    <span
                      key={item.title}
                      data-test={`breadcrumb-text-${index}`}
                      className={`${sections && index === sections.length - 1 ? 'page-heading__selected' : ''}`}
                    >
                      {item.title}
                    </span>
                  )
                )}
              </Breadcrumbs>
            )}

            {controlsTopRight && <div className="page-heading__controls-top-right">{controlsTopRight}</div>}
          </div>
          <Push size={8} />

          <div className="page-heading__subheader">
            <div className="page-heading__title">
              {buttonBack && (
                <>
                  <IconBack data-test="page-heading-btn-back" className="cursor-pointer" onClick={goBack} />
                  <Push size={12} orientation="horizontal" />
                </>
              )}
              {title && (
                <>
                  <Heading label={title} as="h4" /> <Push orientation="horizontal" size={12} />
                  {expansion}
                </>
              )}
            </div>
            {controls && controls()}
          </div>
          {details}
          {description && (
            <>
              <Push orientation="vertical" size={12} />
              <div className="page-heading__description">{description}</div>
            </>
          )}
          {tabs ? (
            <div className={tabsButton ? 'page-heading__tabs--btn' : ''}>
              <TabGroup data-test="tab-group">
                {tabs.map((item) => (
                  <TabButton
                    key={item.label}
                    active={activeTab === item.label}
                    label={item.label}
                    onClick={() => item.onClick(item.label)}
                  />
                ))}
              </TabGroup>
              {tabsButton && <span>{tabsButton}</span>}
            </div>
          ) : (
            <Push size={20} />
          )}

          {switcher ? (
            <div className="page-heading__swither">
              <Switcher>
                {listSwitcher.map((element: TypeSwitch) => {
                  return (
                    <button
                      onClick={() => {
                        onSelectedSwitch(element.id, setListSwitcher);
                      }}
                      key={`${element.id}${element.name}`}
                      className={element.selected ? 'active' : ''}
                      type="button"
                    >
                      {element.name}
                    </button>
                  );
                })}
              </Switcher>
              <Push size={20} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PageHeading;
