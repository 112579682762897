import React, { useCallback } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps, useField } from 'formik';
import { Infobox, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error.svg';
import { ReactComponent as AttentionIcon } from '../../../../assets/images/icons/info-yellow.svg';
import { FieldProps } from '../../../../components/fields/field';
import { ContingentLinkTypeEnum } from '../../../../mock-data/contingent-link-type-enum';
import { buildFormFieldName } from '../../../../lib/utils/requests';

type FioProps = Omit<
  FieldProps<any> & {
    contingent?: ContingentLinkTypeEnum;
    disabled?: boolean;
    parent?: string;
    newRequest?: boolean;
    requestErrorMessage?: string;
    showErrorImmediately?: boolean;
    dayCareRegisterRequestMessage?: string;
  },
  'name'
>;

export const fioFirstNameField = 'firstName';
export const fioLastNameField = 'lastName';
export const fioMiddleNameField = 'middleName';

const FIO = ({
  newRequest,
  parent,
  label,
  editMode,
  required,
  contingent,
  disabled = false,
  requestErrorMessage,
  showErrorImmediately = false,
  dayCareRegisterRequestMessage,
}: FioProps) => {
  const getContingentLinkDisplayName = (linkType: ContingentLinkTypeEnum) => {
    switch (linkType) {
      case ContingentLinkTypeEnum.Found:
        return 'Сведения найдены в Реестре контингента';
      case ContingentLinkTypeEnum.NotFound:
        return 'Сведения в Реестре контингента отсутствуют';
      case ContingentLinkTypeEnum.InQueue:
        return 'Проверка в реестре контингента еще выполняется... Обновите страницу позднее';
      default:
        return 'Проверка в Реестре контингента не проводилась. Сведения будут проверены, результаты проверки отображены после первого сохранения.';
    }
  };

  const getContingentLinkIcon = useCallback(() => {
    switch (contingent) {
      case ContingentLinkTypeEnum.Found:
        return <SuccessIcon />;
      case ContingentLinkTypeEnum.NotFound:
        return <ErrorIcon />;
      case ContingentLinkTypeEnum.InQueue:
        return <AttentionIcon />;
      default:
        return <AttentionIcon />;
    }
  }, [contingent]);

  const getContingentLinkColor = useCallback(() => {
    switch (contingent) {
      case ContingentLinkTypeEnum.Found:
        return 'success';
      case ContingentLinkTypeEnum.NotFound:
        return 'danger';
      case ContingentLinkTypeEnum.InQueue:
        return 'warning';
      default:
        return 'warning';
    }
  }, [contingent]);

  const lastNameField = buildFormFieldName(parent, fioLastNameField);
  const firstNameField = buildFormFieldName(parent, fioFirstNameField);
  const middleNameField = buildFormFieldName(parent, fioMiddleNameField);

  const [middleName] = useField(middleNameField);

  // Отображение ошибки с бэка
  const errorMessage = dayCareRegisterRequestMessage || requestErrorMessage;

  return (
    <FormikField>
      {({ form }: FormikFieldProps) => {
        return (
          <>
            {!newRequest && editMode && contingent !== undefined ? (
              <Infobox text={getContingentLinkDisplayName(contingent)} fullWidth color={getContingentLinkColor()} />
            ) : null}

            {!newRequest && !editMode && Boolean(errorMessage) && (
              <Infobox text={errorMessage ?? ''} fullWidth color="danger" />
            )}

            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">
                {label} {editMode && required ? <span className="table-data__required" /> : null}
              </div>
              <div className="table-data__body">
                {editMode ? (
                  <div className="table-data-grid-3">
                    <div>
                      <FormikFormGroup
                        required
                        label=""
                        name={lastNameField}
                        showErrorImmediately={showErrorImmediately}
                      >
                        <FormikInput
                          size="small"
                          placeholder="Введите фамилию..."
                          name={lastNameField}
                          disabled={disabled}
                        />
                      </FormikFormGroup>
                    </div>
                    <div>
                      <FormikFormGroup
                        required
                        label=""
                        name={firstNameField}
                        showErrorImmediately={showErrorImmediately}
                      >
                        <FormikInput
                          size="small"
                          placeholder="Введите имя..."
                          name={firstNameField}
                          disabled={disabled}
                        />
                      </FormikFormGroup>
                    </div>
                    <div>
                      <FormikFormGroup
                        required
                        label=""
                        name={middleNameField}
                        showErrorImmediately={showErrorImmediately}
                      >
                        <FormikInput
                          size="small"
                          placeholder="Введите отчество (если есть)..."
                          name={middleNameField}
                          disabled={disabled}
                          showClearIndicator={Boolean(middleName.value)}
                        />
                      </FormikFormGroup>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between">
                    <span className="flex items-center">
                      {form.values[lastNameField]} {form.values[firstNameField]} {form.values[middleNameField]}
                    </span>{' '}
                    {contingent !== undefined ? (
                      <span className={`color-${getContingentLinkColor()}-dark flex`}>
                        {' '}
                        {getContingentLinkIcon()}
                        <Push orientation="horizontal" size={6} />
                        <div style={{ maxWidth: '350px' }}>{getContingentLinkDisplayName(contingent)}</div>
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      }}
    </FormikField>
  );
};

export default FIO;
