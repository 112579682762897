import React from 'react';
import { Button, IconCheckmark, IconClear, SelectOptionType, Push } from '@mosru/esz_uikit';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { Learner } from '../../../../types/learners';
import { fieldValidation } from './helpers';
import learnerApi from '../../../../lib/api/learner';

const Edit: React.FC<{
  id: number;
  editMode: boolean;
  editObj: Learner.TransformRepresentative;
  submitHandler: () => void;
  checkAllValidate: boolean;
  pupilId: number;
  removeRepresentative: (val: number) => void;
  setEditMode: (v: boolean) => void;
  setRepresentative: (v: boolean) => void;
  setBlockAddBtn: (value: boolean) => void;
  typeRepresentative: SelectOptionType | undefined;
  setValidate: (obj: Learner.ValidateRepresentatives) => void;
  newRepresentative?: boolean;
  updateLearn: (value: boolean) => void;
}> = ({
  setEditMode,
  editObj,
  editMode,
  submitHandler,
  setRepresentative,
  checkAllValidate,
  removeRepresentative,
  id,
  typeRepresentative,
  setValidate,
  pupilId,
  setBlockAddBtn,
  updateLearn,
}) => {
  const send = async () => {
    if (editMode) {
      if (editObj && editObj.id) {
        const { id, email, phone, name, typeRepresentationId } = editObj;

        await learnerApi.putRepresentative(editObj.id, {
          id,
          email,
          phone,
          lastName: name.lastName,
          firstName: name.firstName,
          middleName: name.middleName,
          typeRepresentationId: typeRepresentationId || (typeRepresentative?.value as number) || 0,
          pupilId,
        });
      } else {
        submitHandler();
      }
    }

    setEditMode(false);
    setBlockAddBtn(false);
    setRepresentative(false);
    updateLearn(true);
  };

  return (
    <div className="disciplines-table-comment right">
      <div className="disciplines-table-comment__controls">
        {editMode ? (
          <>
            <Button
              iconLeft={() => <IconClear />}
              border
              size="small"
              handleClick={() => {
                setEditMode(false);
                setBlockAddBtn(false);
                setRepresentative(false);
                setValidate({
                  phone: 0,
                  email: 0,
                  lastName: 0,
                  firstName: 0,
                  middleName: 0,
                });
              }}
            />
            <Push size={12} orientation="horizontal" />
            <Button
              iconLeft={() => <IconCheckmark />}
              border
              success
              disabled={
                !(
                  fieldValidation(editObj.name.firstName) &&
                  fieldValidation(editObj.name.lastName) &&
                  fieldValidation(editObj.phone, true) &&
                  typeRepresentative?.value
                ) || checkAllValidate
              }
              size="small"
              handleClick={() => {
                send();
              }}
            />
          </>
        ) : (
          <DropDown
            itemId={id}
            component={() => (
              <span className="drop-down-btn-round">
                <IconDots />
              </span>
            )}
          >
            <div className="drop-down-panel">
              <div className="drop-down-panel__list">
                <button
                  type="button"
                  onClick={() => {
                    setEditMode(true);
                  }}
                  className="drop-down-panel__list-item"
                >
                  <IconEdit />
                  Редактировать
                </button>
                <button
                  type="button"
                  onClick={() => {
                    removeRepresentative(id);
                  }}
                  className="drop-down-panel__list-item"
                >
                  <IconRemove />
                  Удалить
                </button>
              </div>
            </div>
          </DropDown>
        )}
      </div>
    </div>
  );
};

export default Edit;
