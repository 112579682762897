import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Radio, SimpleTable, TableDataType } from '@mosru/esz_uikit';
import { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { formatDate } from '../../../../lib/utils/date';
import {
  RequestEnrollmentData,
  TrainingGroupByServiceDonm,
  TrainingGroupScheduleData,
} from '../../../../types/requests';

type AdmissionPlanProps = FieldProps<TrainingGroupByServiceDonm[]> & {
  serviceId: number;
  scheduleOfTimetableId: number;
};

const EnrollmentAdmissionPlan = ({ serviceId, scheduleOfTimetableId, disabled }: AdmissionPlanProps) => {
  const { values, setFieldValue } = useFormikContext<RequestEnrollmentData & any>();
  const [trainingGroupList, setTrainingGroupList] = useState<TrainingGroupByServiceDonm[] | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceTemplateApi.getTrainingGroupsByService(serviceId);
      setTrainingGroupList(tgList);
    };

    if (serviceId != null) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceId]);

  useEffect(() => {
    setFieldValue('scheduleOfTimetableId', scheduleOfTimetableId);
  }, [scheduleOfTimetableId, setFieldValue]);

  // костыль, сразу после сохранения блока enrollment пропадает выбранный radio button, пока не вызовится перерендер
  useEffect(() => {
    if (values.scheduleOfTimetableId === null) {
      setFieldValue('scheduleOfTimetableId', scheduleOfTimetableId);
    }
  }, [values.scheduleOfTimetableId, scheduleOfTimetableId, setFieldValue]);

  return trainingGroupList ? (
    <SimpleTable
      data={trainingGroupList as TableDataType[]}
      hideSort
      headerBorderTop={false}
      columns={[
        {
          dataIndex: 'id',
          title: '',
          render: (item: any) => (
            <Radio
              name=""
              labelId=""
              checked={(item.scheduleOfTimetable as TrainingGroupScheduleData[]).some(
                (st) => st.id === values.scheduleOfTimetableId
              )}
              onChange={() => {
                setFieldValue('scheduleOfTimetableId', item.scheduleOfTimetableId);
              }}
              disabled={disabled}
            />
          ),
          width: '40px',
        },
        {
          dataIndex: 'date',
          title: 'Дата начала занятий',
          render: (item: any) => formatDate(item.educationStartDate),
        },
        {
          dataIndex: 'teacher',
          title: 'Преподаватель',
          render: (item: any) => item.teacher,
        },
        {
          dataIndex: 'numbers',
          title: 'Количество мест',
          render: (item: any) => item.volume ?? 'Без ограничений',
        },
      ]}
    />
  ) : null;
};

export default EnrollmentAdmissionPlan;
