import React, { FC, Fragment, useMemo } from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { AuthorizationData } from '../../types/authorization-data';
import { sidebarList, routeType, SidebarLinkType } from '../../mock-data/routes';
import { GetSidebarIcons } from '../../mock-data/siderbar-icon';
import { MenuSettings } from '../../types/entities';
import { routes } from '../../config/constants';

type Props = {
  userProfile: AuthorizationData;
  menuSetting: MenuSettings[];
  meshLink: string;
  showSider: boolean;
};

const SiderNav: React.FC<Props> = ({ userProfile, menuSetting, meshLink, showSider }: Props) => {
  const location = useLocation();

  const filteredList = useMemo(
    () =>
      menuSetting.reduce((result: SidebarLinkType[], s) => {
        const item = sidebarList.find((x) => x.id === s.id);
        if (item) {
          if (item.checkAccess(userProfile)) {
            return [...result, { ...item, parent: s.parentId, name: s.menuName }];
          }
        } else {
          console.error(`Error: menuSetting id:${s.id} not found in SidebarLink`);
        }
        return result;
      }, []),
    [menuSetting, userProfile /* , props.meshLink */]
  );

  const filteredListTitle = useMemo(
    () => filteredList.filter((item) => item && item.type & routeType.sidebarTitle),
    [filteredList]
  );

  return (
    <div className="sider-scroll">
      <div className="sider-scroll__container">
        {filteredListTitle.map((itemTitle) => {
          const children = filteredList.filter((item) => item.parent === itemTitle.id);
          return children.length ? (
            <Fragment key={itemTitle.id}>
              <div className="sider-title">{itemTitle.name}</div>
              <div className="sider-divider" />
              <div className="sider-ul">
                {children.map(
                  (itemChild) =>
                    itemChild && (
                      <SmartLink
                        key={itemChild.id}
                        data-test={itemChild.name}
                        link={itemChild.link}
                        title={!showSider ? itemChild.name : ''}
                        className={`sider-ul__li ${
                          matchPath(location.pathname, { path: itemChild.link, exact: itemChild.link === routes.main })
                            ? 'active'
                            : ''
                        }`}
                        redirect={itemChild.redirect}
                      >
                        <div className="sider-ul__icon">{GetSidebarIcons(itemChild.icon)}</div>
                        <div className="sider-ul__text">{itemChild.name}</div>
                      </SmartLink>
                    )
                )}
              </div>
            </Fragment>
          ) : null;
        })}
      </div>
    </div>
  );
};

type SmartLinkProps = {
  link?: string;
  title: string;
  className: string;
  redirect?: () => void;
};

const SmartLink: FC<SmartLinkProps> = ({ link = '', className, redirect, ...props }) => {
  return redirect ? (
    <div className={`${className} cursor-pointer`} onClick={redirect} {...props} />
  ) : (
    <Link to={link} className={className} {...props} />
  );
};

export default SiderNav;
