import React from 'react';
import { useFormikContext } from 'formik';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../../../components/formik/formik-datepicker';
import { Learner } from '../../../../../../types/learners';
import { getDate } from '../../../../../../lib/utils/date';

type Props = {
  editMode: boolean;
};

export const Birthday = ({ editMode }: Props) => {
  const { values } = useFormikContext<Learner.Info>();

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Дата рождения {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div style={{ width: 220 }}>
            <FormikFormGroup required label="" name="birthDate" showErrorImmediately>
              <FormikDatePicker
                placeholder="ДД.ММ.ГГГГ"
                size="small"
                name="birthDate"
                startDate={values.birthDate ? getDate(values.birthDate) : undefined}
              />
            </FormikFormGroup>
          </div>
        ) : values.birthDate ? (
          values.birthDate.toLocaleDateString('ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })
        ) : (
          'Не выбран'
        )}
      </div>
    </div>
  );
};
