import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Select,
  SelectOptionType,
  Tooltip,
  Panel,
  Loader,
  Push,
  AsyncTable as Table,
} from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { ReactComponent as IconArrow } from '../../../assets/images/icons/arrow-right.svg';
import { ReactComponent as IconCopy } from '../../../assets/images/icons/copy.svg';
import { generateLink, hasGeneralAccess, replaceHistoryState, showExcelErrorData } from '../../../lib/utils';
import { maxRegistryRows, routes } from '../../../config/constants';
import learnerApi from '../../../lib/api/learner';
import { checkStatuses, numberWithCommas, transformDataLearners } from '../../../lib/utils/learners';
import { pageSizeOptions } from '../../../mock-data';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { generalAccess } from '../../../mock-data/access-enum';
import { ReactComponent as Success } from '../../../assets/images/icons/success.svg';
import { ReactComponent as Error } from '../../../assets/images/icons/error.svg';
import SplitModal from './split-modal';
import { SearchLearnersInitialFormData } from '../../../types/learners';

type Props = {
  search: SearchLearnersInitialFormData | undefined;
};

const LearnersTable: React.FC<Props> = ({ search }) => {
  const [pageSize, setPageSize] = useState(window.history.state[historyState.pageSize] || pageSizeOptions[0].value);
  const [modal, showModal] = useState(false);

  const [tableData, setTableData] = useState<any>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<SearchLearnersInitialFormData>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const fetchData = useCallback(async () => {
    if (currentSearch) {
      setLoading(true);
      const defaultData = {
        ...currentSearch,
        organizationName: undefined,
        pageSize,
        pageNumber: pageNum + 1,
      };

      const learners = await learnerApi.postLearners(defaultData);

      setTableData(transformDataLearners(learners.items, defaultData.pageSize, defaultData.pageNumber));
      setTableTotal(learners.total);
      setLoading(false);
    }
  }, [currentSearch, pageSize, pageNum]);

  useLayoutEffect(() => {
    if (search) {
      setCurrentSearch(search);
      if (initPageNum) {
        setInitPageNum(0);
      } else {
        setPageNum(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const handleExportFile = async () => {
    setLoadingFile(true);
    try {
      await learnerApi.getLearnersExcel({
        ...currentSearch,
        pageSize,
        pageNumber: pageNum + 1,
      });
    } catch (e) {
      showExcelErrorData();
    } finally {
      setLoadingFile(false);
    }
  };

  return (
    <>
      <Panel
        title={() => (
          <>
            Обучающиеся
            <span className="color-gray-dark">
              {' \u00A0'}
              {numberWithCommas(tableTotal)}
            </span>
          </>
        )}
        headingControl={() => (
          <>
            <Button
              handleClick={handleExportFile}
              label="Экспорт"
              size="small"
              border
              iconRight={() => <IconExcel />}
              colorIcon
              load={loadingFile}
            />
            <Push orientation="horizontal" size={12} />
            <div style={{ width: 184 }}>
              <Select
                name="pageSize"
                hideClearIndicator
                isSearchable={false}
                value={pageSizeOptions.find((item) => item.value === pageSize)}
                onChange={(selectedOption) => {
                  const { value } = selectedOption as SelectOptionType;
                  setPageSize(value as number);
                  setPageNum(0);
                }}
                options={pageSizeOptions}
                size="small"
              />
            </div>
            <Push orientation="horizontal" size={12} />

            {hasGeneralAccess(userProfile, generalAccess.AdminEdit) && (
              <Button
                label="Объединить личные дела"
                size="small"
                primary
                handleClick={() => showModal(true)}
                iconLeft={() => <IconCopy />}
              />
            )}
          </>
        )}
      >
        {loading ? (
          <div className="loader-container">
            <Loader title="Загружаем реестр обучающихся" description="Нужно немного подождать" />
          </div>
        ) : tableTotal ? (
          <Table
            overflow
            data={tableData}
            pageNum={pageNum}
            pageSize={pageSize}
            itemsAll={tableTotal}
            rowLimit={maxRegistryRows}
            setPageNumber={setPageNum}
            columns={[
              {
                dataIndex: 'row',
                title: '№',
                render: (item: any) => item.row,
                width: '88px',
                hiddenSort: true,
              },
              {
                dataIndex: 'name',
                title: 'ФИО',
                render: (item: any) => (
                  <Link className="learners-table-name" to={`${routes.learners}/${item.id}`} type="button">
                    {item.name}
                  </Link>
                ),
                hiddenSort: true,
                width: '25%',
              },

              {
                dataIndex: 'hasInContingent',
                title: <span style={{ margin: '0 auto' }}>Реестр</span>,
                render: (item: any) => (
                  <div className="text-center">
                    <Tooltip
                      component={() => (
                        <div className="flex justify-center">{item.hasInContingent ? <Success /> : <Error />}</div>
                      )}
                      position="top"
                      text={
                        item.hasInContingent
                          ? 'Сведения найдены в Реестре контингента'
                          : 'Сведения в Реестре контингента отсутствуют'
                      }
                    />
                  </div>
                ),
                hiddenSort: true,
              },

              {
                dataIndex: 'sexId',
                title: 'Пол',
                render: (item: any) => item.sexId,
                hiddenSort: true,
              },
              {
                dataIndex: 'birthDate',
                title: 'Дата рождения',
                render: (item: any) => item.birthDate,
                hiddenSort: true,
              },

              {
                dataIndex: 'statuses',
                title: 'Статусы',
                render: (item: any) => (
                  <div className="flex">
                    {checkStatuses(item.statuses).included ? (
                      <Badge color="success" text={`Зачислен (${checkStatuses(item.statuses).included})`} />
                    ) : (
                      ''
                    )}

                    {checkStatuses(item.statuses).included ? <Push orientation="horizontal" size={12} /> : ''}

                    {checkStatuses(item.statuses).excluded ? (
                      <Badge color="warning" text={`Отчислен (${checkStatuses(item.statuses).excluded})`} />
                    ) : (
                      ''
                    )}
                  </div>
                ),
                hiddenSort: true,
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) => (
                  <Link to={generateLink(routes.learner, { id: item.id })}>
                    <IconArrow />
                  </Link>
                ),
                hiddenSort: true,
                width: '72px',
              },
            ]}
          />
        ) : (
          <div className="learner-no-data">Нет данных</div>
        )}
      </Panel>
      <SplitModal modal={modal} showModal={showModal} />
    </>
  );
};

export default LearnersTable;
