import React, { useEffect, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import PageHeading from '../../components/header/page-heading';
import useClearHistoryState from '../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../lib/utils';
import { historyState } from '../../mock-data/history-state';
import FavoriteSearch from './search';
import { SearchFavoriteFormData } from '../../types/favorite';
import FavoriteTable from './table';
import { routes } from '../../config/constants';
import { sendReachGoal } from '../../lib/metrica';

export const initialFormData = {
  query: '',
  entityTypeId: '0',
  date: null,
  startDate: null,
  endDate: null,
};

const Favorite = () => {
  const [search, setSearch] = useState<SearchFavoriteFormData>(
    window.history.state[historyState.search] || initialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchFavoriteFormData) => {
    setSearch(values);
    replaceHistoryState({ [historyState.search]: values });
  };

  useEffect(() => {
    sendReachGoal('favorites');
  }, []);
  return (
    <>
      <PageHeading title="Избранное" sections={[{ title: 'Главная', link: routes.main }, { title: 'Избранное' }]} />
      <Push size={12} />
      <FavoriteSearch submitForm={handleSubmitSearch} initialForm={search} />
      <Push size={12} />
      <FavoriteTable search={search} />
    </>
  );
};

export default Favorite;
