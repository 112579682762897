import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../../components/formik/formik-input';
import { usePrevious } from '../../../../../../hooks/use-previous';
import { SubmitValues } from '../index';

const FiledServiceClass = () => {
  const { values, setFieldValue, setTouched } = useFormikContext<SubmitValues>();

  const [rerender, setRerender] = useState<number>(0);

  const serviceClassIdPrev = usePrevious(values.serviceClassId);

  useEffect(() => {
    if (!values.serviceClassId) {
      setFieldValue('serviceClassName', null);
      setTouched({}, false);
      setRerender(Math.random());
    }
  }, [serviceClassIdPrev, setFieldValue, setTouched, values.serviceClassId]);

  return (
    <FormikFormGroup required name="serviceClassName" label="Наименование группы">
      <FormikInput
        key={rerender}
        size="small"
        placeholder="Введите..."
        name="serviceClassName"
        disabled={values.serviceClassId > 0}
      />
    </FormikFormGroup>
  );
};

export default FiledServiceClass;
