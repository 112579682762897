import React from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type ModalProps = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  handleSubmit: () => void;
};

const BaseModal = ({ showModal, setShowModal, handleSubmit, text }: ModalProps & { text: string }) => (
  <Modal show={showModal} onClose={setShowModal}>
    <ModalPanel
      alert
      onClose={() => setShowModal(false)}
      modalTitle="Подтверждение"
      renderComponent={() => <div>{text}</div>}
      controls={() => (
        <>
          <Button label="Отмена" border primary size="small" onClick={() => setShowModal(false)} />
          <Push orientation="horizontal" size={12} />
          <Button label="Продолжить" primary size="small" onClick={handleSubmit} />
        </>
      )}
    />
  </Modal>
);

export const RefuseModal = ({ showModal, setShowModal, handleSubmit }: ModalProps) => (
  <BaseModal
    showModal={showModal}
    setShowModal={setShowModal}
    handleSubmit={handleSubmit}
    text="Статус заявления будет изменен на “Отказ в зачислении”. Продолжить?"
  />
);

export const EnrollmentModal = ({ showModal, setShowModal, handleSubmit }: ModalProps) => (
  <BaseModal
    showModal={showModal}
    setShowModal={setShowModal}
    handleSubmit={handleSubmit}
    text="Статус заявления будет изменен на “Зачислен”. Продолжить?"
  />
);

export const ApplicationRegistrationModal = ({
  showModal,
  setShowModal,
  handleSubmit,
  text,
  modalTitle,
}: ModalProps & { text: string; modalTitle: string }) => (
  <Modal show={showModal} onClose={setShowModal}>
    <ModalPanel
      alert
      onClose={() => setShowModal(false)}
      modalTitle={modalTitle}
      renderComponent={() => <div>{text}</div>}
      controls={() => (
        <>
          <Button label="Отмена" border primary size="small" onClick={() => setShowModal(false)} />
          <Push orientation="horizontal" size={12} />
          <Button label="Зарегистрировать" primary size="small" onClick={handleSubmit} />
        </>
      )}
    />
  </Modal>
);

export const LoadingModal = ({ showModal }: Omit<ModalProps, 'setShowModal' | 'handleSubmit'>) => (
  <Modal show={showModal} onClose={() => {}}>
    <ModalPanel
      alert
      onClose={() => {}}
      modalTitle="Идет сохранение!"
      renderComponent={() => <div style={{ width: '100%', textAlign: 'center' }}>Пожалуйста, подождите...</div>}
      hideButton
      textAlign="center"
    />
  </Modal>
);
