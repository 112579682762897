import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../../components/formik/formik-input';
import FormikCheckboxGroup from '../../../../../../components/formik/formik-checkbox-group';
import FormikCheckbox from '../../../../../../components/formik/formik-checkbox';
import { ReactComponent as Success } from '../../../../../../assets/images/icons/success.svg';
import { ReactComponent as Error } from '../../../../../../assets/images/icons/error.svg';
import { Learner } from '../../../../../../types/learners';

type Props = {
  editMode: boolean;
  hasInContingent: boolean;
};

export const FIO = ({ editMode, hasInContingent }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const [randomKey, setRandomFieldKey] = useState(0);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        ФИО учащегося {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="table-data-grid-4">
            <div>
              <FormikFormGroup required label="" name="lastName" showErrorImmediately>
                <FormikInput size="small" placeholder="Фамилия" name="lastName" />
              </FormikFormGroup>
            </div>
            <div>
              <FormikFormGroup required label="" name="firstName" showErrorImmediately>
                <FormikInput size="small" placeholder="Имя" name="firstName" />
              </FormikFormGroup>
            </div>
            <div>
              <FormikFormGroup showErrorImmediately label="" name="middleName">
                <FormikInput
                  key={randomKey}
                  size="small"
                  disabled={values.isMiddleName}
                  placeholder="Отчество"
                  name="middleName"
                />
              </FormikFormGroup>
            </div>
            <div>
              <Push size={8} />
              <FormikCheckboxGroup showErrorImmediately label="Нет отчества" name="isMiddleName" size="small">
                <div
                  onClick={() => {
                    setTimeout(() => {
                      setFieldValue('middleName', '');
                      setRandomFieldKey(Math.random());
                    });
                  }}
                >
                  <FormikCheckbox size="small" name="isMiddleName" />
                </div>
              </FormikCheckboxGroup>
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <span>
              {values.lastName} {values.firstName} {values.middleName}
            </span>
            {hasInContingent ? (
              <span className="color-success-dark flex">
                <Success /> <Push orientation="horizontal" size={6} /> Сведения найдены в Реестре контингента
              </span>
            ) : (
              <span className="color-danger-dark flex">
                <Error /> <Push orientation="horizontal" size={6} /> Сведения в Реестре контингента отсутствуют
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
