import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectOptionType,
  Panel,
  Loader,
  AsyncTable,
  SimpleTable as Table,
  TableDataType,
} from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../../mock-data';
import { DataTable } from '../../../types/reports';

type Props = {
  reportId: number;
  dataTable: DataTable;
  headerTable: { dataIndex?: string; render: any; title: string; width: string }[];
  setPageNumber: (counterPage: number) => void;
  setPageSize: (pageSize: number) => void;
  pageNumber: number | undefined;
  pageSize: number;
  loading?: boolean;
  dateCreate: Date;
  fixedData?: TableDataType[];
};

const TableReport: React.FC<Props> = ({
  reportId,
  dataTable,
  fixedData,
  headerTable,
  setPageNumber,
  pageNumber,
  setPageSize,
  pageSize,
  loading,
  dateCreate,
}) => {
  const currentTime = {
    date: dateCreate.toLocaleDateString(),
    time: dateCreate.toLocaleTimeString().slice(0, -3),
  };
  const [rerenderSimpleTable, setRerenderSimpleTable] = useState(0);
  const asyncTable = reportId === 6 || reportId === 10 || reportId === 26 || reportId === 32;

  useEffect(() => {
    setRerenderSimpleTable(Math.random());
  }, [pageSize, dataTable]);

  return dataTable.items ? (
    <Panel
      title={() => (
        <div>
          <div className="report__little--title">
            Сформировано:&nbsp; {currentTime.date} в {currentTime.time}
          </div>
          <div className="report__big--title">{dataTable.title}</div>
        </div>
      )}
      headingControl={() => (
        <div style={{ width: 184 }}>
          <Select
            name="pageSize"
            isSearchable={false}
            hideClearIndicator
            value={pageSizeOptions.find((item) => item.value === pageSize)}
            onChange={(selectedOption) => {
              const { value } = selectedOption as SelectOptionType;
              setPageSize(value as number);
            }}
            options={pageSizeOptions}
            size="small"
          />
        </div>
      )}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        {loading ? (
          <div className="loader-container">
            <Loader title="Загружаем данные" description="Нужно немного подождать" />
          </div>
        ) : asyncTable ? (
          <AsyncTable
            fixedHeader
            fixedData={fixedData}
            pageSize={pageSize}
            columns={headerTable}
            data={dataTable.items}
            border={reportId !== 10}
            pageNum={pageNumber ?? 0}
            setPageNumber={setPageNumber}
            reverseHead={reportId === 32}
            fixedCols={dataTable.fixedColumns}
            itemsAll={dataTable.itemsCount ?? 0}
          />
        ) : (
          <Table
            fixedData={fixedData}
            border
            fixedHeader
            loading={loading}
            pageSize={pageSize}
            columns={headerTable}
            data={dataTable.items}
            key={rerenderSimpleTable}
            fixedCols={dataTable.fixedColumns}
          />
        )}
      </div>
    </Panel>
  ) : null;
};

export default TableReport;
