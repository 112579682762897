import React, { useContext, useEffect, useState } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { setAsyncValues } from '../../../../lib/utils/requests';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  isAdmin: boolean;
  label: string;
  placeholder: string;
};

const FieldVedomstvo: React.FC<Props> = ({ isAdmin, label, placeholder }) => {
  const [vedomstvoData, setVedomstvoData] = useState<SelectOptionType[]>([]);
  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();
  const { values } = useFormikContext<SearchRequestsInitialFormData>();

  const { setServiceKey, setTrainingGroupKey, setOrganizationKey, setPlaceServiceKey } = useContext(SearchKeyContext);

  useEffect(() => {
    const fetchData = async () => {
      const departments = await dictionariesApi.getVedomstvo();

      if (isAdmin) {
        setVedomstvoData([{ label: 'Все', value: null }, ...departments]);
      } else {
        setVedomstvoData(departments);
      }
    };
    fetchData();
  }, [isAdmin]);

  const [, , helpers] = useField('vedomstvoId');
  const [, , helpersName] = useField('vedomstvoName');

  const onChange = (option: SelectOptionType) => {
    if (option.value !== null) {
      const dataToClearValues = [
        { key: 'organizationId', action: setOrganizationKey },
        { key: 'serviceId', action: setServiceKey },
        { key: 'placeServiceId', action: setPlaceServiceKey },
        { key: 'trainingGroupId', action: setTrainingGroupKey },
      ];

      setAsyncValues(dataToClearValues, setFieldValue);
    }
    helpers.setValue(option.value);
    helpersName.setValue(option.label);
  };
  return (
    <>
      <Push size={16} />
      <FormikFormGroup name="vedomstvoId" label={label}>
        <FormikSelect
          name="vedomstvoId"
          size="small"
          isSearchable
          disabled={vedomstvoData.length < 2 || !isAdmin}
          options={vedomstvoData}
          defaultValue={
            values.vedomstvoId
              ? {
                  label: values.vedomstvoName ?? '',
                  value: values.vedomstvoId ?? '',
                }
              : isAdmin
              ? {
                  label: 'Все',
                  value: null,
                }
              : {
                  label: values.vedomstvoName ?? '',
                  value: values.vedomstvoId ?? '',
                }
          }
          placeholder={placeholder}
          selectedValue={onChange}
        />
      </FormikFormGroup>
    </>
  );
};

export default FieldVedomstvo;
