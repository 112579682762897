import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { EntityTypeEnum } from '../../mock-data/entity-type-enum';
import { Actions, GetHistoryParams, HistoryData } from '../../types/history';
import { OrderByTypeRequest, PaginationRequest, PagingList } from '../../types/entities';

const getActions = async (entityType: EntityTypeEnum) => {
  const { data } = await fetchRequest.get(`${apiService.data}/Security/History/Types/?entityType=${entityType}`);

  return data?.map((item: Actions) => ({ value: item.id, label: item.name }));
};

const getFields = async (entityTypes: EntityTypeEnum[]) => {
  const { data } = await fetchRequest.get(
    `${apiService.data}/Security/History/Fields${entityTypes.reduce(
      (prev, curr) => `${prev}entityTypes=${curr}&`,
      '?'
    )}`
  );

  return data?.map((item: string) => ({ value: item, label: item }));
};

const getHistory = async (
  params: GetHistoryParams & PaginationRequest & OrderByTypeRequest
): Promise<PagingList<HistoryData>> => {
  const response = await fetchRequest.post(`${apiService.data}/Security/History`, params);

  return {
    items: response.data?.items?.map((item: any) => ({
      id: item.id,
      dateTime: item.dateTime,
      description: item.description,
      user: item.user?.name,
      action: item.loggingType?.name,
    })),
    total: response.data?.total,
  };
};

const securityApi = {
  getActions,
  getFields,
  getHistory,
};

export default securityApi;
