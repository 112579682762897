import { SignTypeEnum } from '../../mock-data/sign-type-enum';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import {
  Organization,
  OrganizationPhotoItem,
  OrganizationShort,
  SearchOrganizationInitialFormData,
} from '../../types/organization';
import { OrderByTypeRequest, PaginationRequest, PagingList } from '../../types/entities';
import { TypeSwitch } from '../../types/reports';

type GetOrganizationProps = SearchOrganizationInitialFormData & OrderByTypeRequest & PaginationRequest;

export const getShortOrganization = async (id: number): Promise<{ organizationName: string }> => {
  return await fetchRequest.get(`${apiService.data}/Organization/${id}/Name`);
};

export const getOrganizations = async (params: GetOrganizationProps): Promise<PagingList<OrganizationShort>> => {
  return await fetchRequest.post(`${apiService.data}/Organization/Search`, params);
};

export const deleteOrganization = async (id: number): Promise<any> =>
  await fetchRequest.delete(`${apiService.data}/Organization/${id}`);

export const getOrganization = async (id?: number): Promise<Organization> =>
  await fetchRequest.get(`${apiService.data}/Organization/${id}`);

export const updateOrganization = async (params: Organization): Promise<number | null> =>
  await fetchRequest.put(`${apiService.data}/Organization/${params.id}`, params);

export const getOrganizationFromContingentGuid = async (guid?: string): Promise<Organization> =>
  await fetchRequest.get(`${apiService.data}/Organization/External/1/${guid}`);

export const getDataForSign = async (id: number) =>
  await fetchRequest.get(`${apiService.data}/Organization/DataForSign/${id}`);

export const setSignedData = async (id: number, signType: SignTypeEnum, sign: string) =>
  await fetchRequest.post(`${apiService.data}/Organization/sign/${id}?signingType=${signType}`, sign);

export const putPhoto = async (image: OrganizationPhotoItem): Promise<OrganizationPhotoItem> => {
  const formData = new FormData();
  formData.append('id', (image.id ?? 0).toString());
  formData.append('Image', image.body);
  formData.append('OrgId', image.orgId.toString());
  formData.append('Width', image.width.toString());
  formData.append('Height', image.height.toString());
  formData.append('Name', image.name);

  return await fetchRequest.putFormData(`${apiService.data}/Organization/${image.orgId}/Image`, formData);
};

export const getPhotos = async (orgId: number): Promise<OrganizationPhotoItem[]> =>
  await fetchRequest.get(`${apiService.data}/Organization/${orgId}/Image`);

export const deletePhoto = async (id: number, orgId: number) =>
  await fetchRequest.delete(`${apiService.data}/Organization/${orgId}/Image/${id}`);

const getOrganizationTypeService = async (data: { vedomstvoId?: number }): Promise<TypeSwitch[]> => {
  const service = await fetchRequest.get(`${apiService.data}/Dictionary/TypeProvidingService/${data.vedomstvoId}`);
  const result = service.map((item: { id: number; name: string }) => {
    return { id: item.id, name: item.name, selected: false };
  });
  return await result;
};

const organizationApi = {
  getShortOrganization,
  getOrganizations,
  deleteOrganization,
  getOrganization,
  updateOrganization,
  getDataForSign,
  setSignedData,
  getOrganizationTypeService,
  putPhoto,
  getPhotos,
  deletePhoto,
  getOrganizationFromContingentGuid,
};

export default organizationApi;
