import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, date as dateYup } from 'yup';
import { useSelector } from 'react-redux';
import { Button, notify, Panel, Loader, Push } from '@mosru/esz_uikit';
import { addYears, addDays } from 'date-fns';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikCheckboxGroup from '../../../../components/formik/formik-checkbox-group';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikToggle from '../../../../components/formik/formik-toggle';
import teacherApi from '../../../../lib/api/teacher';
import { formatPhoneNumber } from '../../../../lib/utils/format-number';
import { phoneMask } from '../../../../lib/utils/mask';
import TeacherDisciplines from './disciplines';
import PlaceOfWorks from './place-of-works';
import { emailRegexp, nullablePhone, rusRegexp } from '../../../../lib/utils/validation';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { PlaceOfWork, Teacher } from '../../../../types/teacher';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info-success.svg';
import { ReactComponent as IconDanger } from '../../../../assets/images/icons/close-circle.svg';
import { TeacherContext } from '../index';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { Discipline } from '../../../../types/discipline';

type Props = {
  teacher: Teacher;
  setFullName: (value: string) => void;
};

const TeacherForm = ({ teacher, setFullName }: Props) => {
  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const [editPersonalData, setEditPersonalData] = useState(false);
  const [randomKey, setRandomFieldKey] = useState(0);

  const context = useContext(TeacherContext);

  const [discipline, setDiscipline] = useState<Discipline[] | undefined>(teacher.discipline);
  const [placeOfWork, setPlaceOfWork] = useState<PlaceOfWork[] | undefined>(teacher.organization);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const initialValues = useMemo(
    () => ({
      ...teacher,
      phone: editPersonalData ? formatPhoneNumber(teacher.phone) || '' : teacher.phone,
      notPatronymic: !teacher.middleName,
    }),
    [teacher, editPersonalData]
  );

  const initialErrors = useInitialErrors(initialValues, getTeacherValidationSchema());

  const isAcceptedEdit = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.Edit) && !teacher?.isFromRegister,
    [userProfile, teacher]
  );

  const access = hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.Edit);

  const submitForm = useCallback(
    async ({ notPatronymic, ...values }: Teacher & { notPatronymic: boolean }, { setSubmitting }) => {
      delete values.discipline;
      delete values.organization;

      const response = await teacherApi.updateTeacher({
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        middleName: values.middleName?.trim(),
        discipline,
        organization: placeOfWork,
        ...values,
      });

      notify.success({
        data: {
          label: 'Данные успешно сохранены',
          icon: true,
        },
      });

      setEditPersonalData(false);
      setFullName(response.fullName);
      setSubmitting(false);
    },
    [discipline, placeOfWork, setFullName]
  );

  return context?.loading ? (
    <Panel>
      <div className="flex justify-center flex-column">
        <Push orientation="vertical" size={180} />
        <Loader roller small />
        <Push orientation="vertical" size={180} />
      </div>
    </Panel>
  ) : (
    <>
      <Formik
        enableReinitialize
        onSubmit={submitForm}
        key={rerenderFormKey}
        initialErrors={initialErrors}
        validationSchema={getTeacherValidationSchema()}
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<Teacher & { notPatronymic: boolean }>) => {
          const { handleSubmit, isSubmitting, isValid, values, setFieldValue } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />
              <Panel
                title={() => 'Персональные данные'}
                headingControl={() =>
                  access &&
                  !editPersonalData && (
                    <button type="button" onClick={() => setEditPersonalData(true)} className="icon-group">
                      <span className="icon-group__icon">
                        <IconEdit />
                      </span>
                      <span className="icon-group__text color-primary">
                        <b>Редактировать</b>
                      </span>
                    </button>
                  )
                }
              >
                <div className="container">
                  <div className="table-data">
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">
                        ФИО преподавателя {editPersonalData ? <div className="table-data__required" /> : null}
                      </div>
                      <div className="table-data__body">
                        {editPersonalData ? (
                          <div className="table-data-grid-4 items-start">
                            <div>
                              <FormikFormGroup required label="" name="lastName">
                                <FormikInput
                                  size="small"
                                  placeholder="Фамилия"
                                  name="lastName"
                                  disabled={!isAcceptedEdit}
                                />
                              </FormikFormGroup>
                            </div>
                            <div>
                              <FormikFormGroup required label="" name="firstName">
                                <FormikInput
                                  size="small"
                                  placeholder="Имя"
                                  name="firstName"
                                  disabled={!isAcceptedEdit}
                                />
                              </FormikFormGroup>
                            </div>
                            <div>
                              <FormikFormGroup label="" name="middleName">
                                <FormikInput
                                  key={randomKey}
                                  size="small"
                                  placeholder="Отчество"
                                  name="middleName"
                                  disabled={!isAcceptedEdit || values.notPatronymic}
                                />
                              </FormikFormGroup>
                            </div>
                            <div>
                              <Push size={8} />
                              <FormikCheckboxGroup label="Нет отчества" name="notPatronymic" size="small">
                                <div
                                  role="presentation"
                                  onClick={() => {
                                    //  TODO Исправить
                                    setTimeout(() => {
                                      setFieldValue('middleName', '');
                                      setRandomFieldKey(Math.random());
                                    }, 0);
                                  }}
                                >
                                  <FormikCheckbox size="small" name="notPatronymic" disabled={!isAcceptedEdit} />
                                </div>
                              </FormikCheckboxGroup>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-between">
                            <span>
                              {values.lastName} {values.firstName} {values.middleName}
                            </span>{' '}
                            {values.isFromRegister ? (
                              <div className="icon-group">
                                <span className="icon-group__icon">
                                  <IconInfo />
                                </span>
                                <span className="icon-group__text color-success-dark">
                                  Сведения найдены в Реестре преподавателей
                                </span>
                              </div>
                            ) : (
                              <div className="icon-group">
                                <span className="icon-group__icon">
                                  <IconDanger />
                                </span>
                                <span className="icon-group__text color-danger-dark">
                                  Сведения не найдены в Реестре преподавателей
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="table-data__item">
                      <FormikFormGroup name="birthDate" horizontal label="Дата рождения">
                        <div className="table-data-grid-4">
                          {editPersonalData ? (
                            <FormikDatePicker
                              size="small"
                              name="birthDate"
                              placeholder="ДД.ММ.ГГГГ"
                              disabled={!isAcceptedEdit}
                              startDate={values.birthDate ? new Date(values.birthDate) : undefined}
                            />
                          ) : values.birthDate ? (
                            new Date(values.birthDate).toLocaleDateString('ru-RU', {
                              day: 'numeric',
                              month: 'numeric',
                              year: 'numeric',
                            })
                          ) : (
                            '-'
                          )}
                        </div>
                      </FormikFormGroup>
                    </div>

                    <div className="table-data__item">
                      <FormikFormGroup name="phone" horizontal label="Номер телефона">
                        <div className="table-data-grid-4">
                          {editPersonalData ? (
                            <FormikInput
                              size="small"
                              disabled={!isAcceptedEdit}
                              mask={phoneMask}
                              placeholder="+7 (ХХХ) ХХХ-ХХ-ХХ"
                              name="phone"
                            />
                          ) : (
                            values.phone || '-'
                          )}
                        </div>
                      </FormikFormGroup>
                    </div>

                    <div className="table-data__item">
                      <FormikFormGroup name="email" horizontal label="Адрес электронной почты">
                        {editPersonalData ? (
                          <FormikInput
                            size="small"
                            disabled={!isAcceptedEdit}
                            placeholder="Введите адрес электронной почты"
                            name="email"
                          />
                        ) : (
                          <>{values.email || '-'}</>
                        )}
                      </FormikFormGroup>
                    </div>

                    <div className="table-data__item">
                      <FormikFormGroup label="Контактное лицо" horizontal name="isContactPerson">
                        <FormikToggle disabled={!access || !editPersonalData} name="isContactPerson" size="xsmall" />
                      </FormikFormGroup>
                    </div>
                  </div>
                </div>
              </Panel>

              {editPersonalData && (
                <div className="room-save-container">
                  <div className="room-panel-save">
                    <div className="container">
                      <div className="room-panel-save__inner">
                        <Button
                          onClick={() => {
                            setRerenderFormKey(Math.random());
                            setEditPersonalData(false);
                          }}
                          border
                          primary
                          label="Отмена"
                        />
                        <Push orientation="horizontal" size={12} />
                        <Button
                          primary
                          label="Сохранить"
                          load={isSubmitting}
                          onClick={handleSubmit}
                          disabled={!isValid || !access}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          );
        }}
      </Formik>

      {!editPersonalData && (
        <>
          <Push size={12} />
          <TeacherDisciplines values={initialValues} discipline={discipline} setDiscipline={setDiscipline} />

          <Push size={12} />
          <PlaceOfWorks
            values={initialValues}
            placeOfWork={placeOfWork}
            setPlaceOfWork={setPlaceOfWork}
            isAcceptedEdit={isAcceptedEdit}
          />
        </>
      )}
    </>
  );
};

export default TeacherForm;

const getTeacherValidationSchema = () =>
  objectYup().shape({
    lastName: stringYup()
      .required('Введите фамилию')
      .matches(rusRegexp, {
        message: 'Фамилия может содержать только русские буквы',
      })
      .nullable(),
    firstName: stringYup()
      .required('Введите имя')
      .matches(rusRegexp, {
        message: 'Имя может содержать только русские буквы',
      })
      .nullable(),
    middleName: stringYup()
      .nullable()
      .when('notPatronymic', {
        is: false,
        then: stringYup()
          .matches(rusRegexp, { message: 'Отчество может содержать только русские буквы' })
          .nullable()
          .required('Введите отчество'),
      }),
    birthDate: dateYup()
      .max(new Date(addYears(Date.now(), -18)), 'Учителю не может быть меньше 18 лет')
      .min(new Date(addDays(addYears(Date.now(), -100), -1)), 'Учителю не может быть больше 100 лет')
      .nullable()
      .optional(),

    email: stringYup()
      .matches(emailRegexp, {
        message: 'Указан неверный адрес электронной почты',
      })
      .nullable(),
    phone: nullablePhone,
  });
