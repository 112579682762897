import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import lookupApi from '../../../../lib/api/lookup';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { formatPhoneNumber } from '../../../../lib/utils/format-number';
import { onlyLetters, preventInvalidPaste } from '../../../../lib/utils/validation';
import { ServiceDataInfo } from '../../../../types/service';

type ContactNameProps = FieldProps<string> & {
  disabled?: boolean;
  phoneField: string;
  organizationId?: number;
};

const ContactName = ({
  label = 'ФИО',
  name,
  editMode,
  required,
  disabled = false,
  phoneField,
  placeholder = 'Начните вводить...',
  organizationId,
}: ContactNameProps) => {
  const [field] = useField(name);
  const [key, setKey] = useState(0);

  const { setFieldValue } = useFormikContext<ServiceDataInfo>();

  const onSelectedValue = (v: SelectOptionType) => {
    const canEditRusLetter = /^[А-Яа-яЁё\s]+/.test(v?.label);
    const canEditSymbol = /[-,'.\s]+/.test(v?.label);
    if (v) {
      if (canEditRusLetter || canEditSymbol) {
        if (canEditRusLetter && canEditSymbol) {
          setFieldValue(name, v.label);
        } else if (canEditSymbol) {
          setKey(Math.random());
          setFieldValue(name, null);
        } else {
          setFieldValue(name, v.label);
        }
      }
    }

    if (v && v.additionalPropertiesJson) {
      try {
        const parse = JSON.parse(v.additionalPropertiesJson);
        const phone = formatPhoneNumber(parse?.Phone);
        setFieldValue(phoneField, phone);
      } catch (e) {
        console.error(e);
      }
    } else {
      setFieldValue(phoneField, '');
    }
  };

  return (
    <Field label={label} editMode={editMode} required={required} value={field.value ?? '—'}>
      <div onPaste={preventInvalidPaste}>
        <FormikFormGroup required label="" name={name}>
          <FormikSelect
            key={key}
            name={name}
            size="small"
            isSearchable
            options={[]}
            isCreatable
            disabled={disabled}
            onKeyDown={onlyLetters}
            placeholder={placeholder}
            selectedValue={onSelectedValue}
            defaultValue={field.value ? { value: field.value, label: field.value } : null}
            loadOptions={async (query) => await lookupApi.getTeachers(query, organizationId, true)}
          />
        </FormikFormGroup>
      </div>
    </Field>
  );
};

export default ContactName;
