import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, Loader, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import DeclineReason from '../components/fields/decline-reason';
import { RequestDeclineData } from '../../../types/requests';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { submitRefusal } from '../utils';
import { RefuseModal } from '../components/modals';
import { requestDeclineReasonId } from '../../../lib/utils/validation';
import SavePanel from '../../../components/save-panel';

type Props = {
  editMode: boolean;
  setEditMode: () => void;
  decline: RequestDeclineData;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
  changeStatusRequest?: () => void;
  requestStatusId?: number;
};

const ActionsFormRefusal: React.FC<Props> = ({
  editMode,
  setEditMode,
  decline,
  serviceId,
  requestId,
  updateRequest,
  changeStatusRequest,
  requestStatusId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState<number>(0);

  const initialErrors = useInitialErrors(decline, getValidationSchema());
  const submitForm = useCallback(
    async (values: RequestDeclineData) => {
      await submitRefusal(values, requestId, setLoading, setShowModal, setEditMode, updateRequest);
      // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
      changeStatusRequest && changeStatusRequest();
      updateRequest();
      setFormKey(Math.random());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestId, updateRequest, setEditMode]
  );

  useEffect(() => {
    setFormKey(Math.random());
  }, [decline]);

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={decline}
    >
      {(formikProps: FormikProps<RequestDeclineData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="table-data">
                <DeclineReason
                  name="requestDeclineReason"
                  disabled={!editMode}
                  editMode={editMode}
                  serviceId={serviceId}
                  requestId={requestId}
                  required
                  showRequiredIcon
                />
                <SimpleTextArea
                  name="declineComment"
                  disabled={!editMode}
                  editMode={editMode}
                  label="Комментарий"
                  placeholder={editMode ? 'Введите...' : ''}
                />
              </div>
            </div>
            {editMode && (
              <SavePanel
                controls={
                  <>
                    <Button
                      label="Отмена"
                      onClick={() => {
                        resetForm();
                        setEditMode();
                        setFormKey(Math.random());
                      }}
                      primary
                      border
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      onClick={() => setShowModal(true)}
                      load={isSubmitting}
                      disabled={!isValid}
                      label="Отправить отказ"
                      primary
                    />
                  </>
                }
              />
            )}
            <RefuseModal showModal={showModal} setShowModal={setShowModal} handleSubmit={handleSubmit} />
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormRefusal;

const getValidationSchema = () =>
  objectYup().shape({
    requestDeclineReasonId,
  });
