import React, { useContext, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { FinancingData, TemplateService } from '../../../../../types/service';
import { ServiceContext } from '../../..';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import FormikToggleGroup from '../../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../../components/formik/formik-toggle';
import { templateApi } from '../../../../../lib/api/template';
import FormFinancing from './form';
import { MAX_VALUE } from '../../../../../lib/utils/validation';
import { replaceDotsWithCommas } from '../../../../../lib/utils';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
};

export type Finance = TemplateService.Financing | FinancingData;

const Financing: React.FC<Props> = ({ setEditModeParent, isTemplate }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData?.financing ?? {};
  const initialErrors = useInitialErrors(initialValues, financingValidationSchema(isTemplate));

  const onlyView =
    serviceData?.educationTypeId === EducationTypeEnum.ProfessionalEducation &&
    initialValues?.typeFinancingId === TypeFinancingEnum.Free;

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const checkEditable =
    !onlyView &&
    !editMode &&
    setEditModeParent &&
    serviceData.serviceStatusId === ServiceStatusEnum.Draft &&
    accessPanelEdit;

  const submitForm = async (values: Finance) => {
    setLoadBtn(true);

    try {
      isTemplate
        ? await templateApi.updateFinancing(serviceData.id, values)
        : await serviceTemplateApi.updateFinancing(serviceData.id, values);
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
      setLoadBtn(false);
    } finally {
      setLoadBtn(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      validationSchema={financingValidationSchema(isTemplate)}
      onSubmit={(values) => submitForm(values)}
    >
      {(formikProps: FormikProps<Finance>) => {
        const { isValid, resetForm, handleSubmit } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Финансирование'}
              headingControl={() => (
                <>
                  {checkEditable && (
                    <button
                      type="button"
                      onClick={() => {
                        setEditModeParent && setEditModeParent('financing');
                        setEditMode(true);
                      }}
                      className="icon-group"
                    >
                      <span className="icon-group__icon">
                        <IconEdit />
                      </span>
                      <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                    </button>
                  )}

                  {isTemplate && (
                    <div className="table-data__control">
                      <FormikToggleGroup name="canChangeFinancing" label="">
                        <FormikToggle disabled={!editMode} name="canChangeFinancing" size="xsmall" />
                      </FormikToggleGroup>
                    </div>
                  )}
                </>
              )}
            >
              <FormFinancing {...formikProps} editMode={editMode} isTemplate={isTemplate} />
            </Panel>

            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        handleClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button submit load={loadBtn} disabled={!isValid} primary label="Сохранить" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Financing;

export const financingValidationSchema = (isTemplate?: boolean) =>
  objectYup().shape({
    typeFinancingId: stringYup().required('Выберите тип финансирования'),
    typeValueServiceId: stringYup()
      .nullable()
      .when('typeFinancingId', { is: '2', then: (s) => s.required('Выберите периодичность оплаты') }),
    fullPrice: isTemplate
      ? stringYup().nullable()
      : stringYup()
          .nullable()
          .test((value: string | null | undefined, ctx: any) => {
            const parent = ctx.from[0];

            if (parent?.value?.typeFinancingId === 2) {
              if (!value) {
                return ctx.createError({ message: 'Необходимо указать общую стоимость' });
              } else if (parseFloat(value) > MAX_VALUE) {
                return ctx.createError({
                  message: `Значение не может быть больше ${replaceDotsWithCommas(MAX_VALUE)}`,
                });
              } else if (parseFloat(value) < 1) {
                return ctx.createError({ message: 'Необходимо указать общую стоимость' });
              }
            }
            return true;
          }),
    periodPrice: stringYup()
      .nullable()
      .test((value: string | null | undefined, ctx: any) => {
        const parent = ctx.from[0];

        if (parent?.value?.typeFinancingId === 2) {
          if (!value) {
            return ctx.createError({ message: 'Необходимо указать стоимость за период' });
          } else if (parseFloat(value) > MAX_VALUE) {
            return ctx.createError({ message: `Значение не может быть больше ${replaceDotsWithCommas(MAX_VALUE)}` });
          } else if (parseFloat(value) < 1) {
            return ctx.createError({ message: 'Необходимо указать стоимость за период' });
          }
        }
        return true;
      }),
    lessonPrice: stringYup()
      .nullable()
      .test((value: string | null | undefined, ctx: any) => {
        const parent = ctx.from[0];

        if (parent?.value?.typeFinancingId === 2 && value) {
          if (parseFloat(value) > MAX_VALUE) {
            return ctx.createError({ message: `Значение не может быть больше ${replaceDotsWithCommas(MAX_VALUE)}` });
          }
        }
        return true;
      }),
  });
