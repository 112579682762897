import { useField } from 'formik';
import React from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';

type VedomstvoFieldProps = FieldProps<string> & { list: SelectOptionType[] };

const VedomstvoField = ({
  label = 'Департамент',
  disabled,
  name,
  editMode,
  required,
  list,
  defaultValue = '—',
}: VedomstvoFieldProps) => {
  const fieldName = `${name}Name`;
  const filedId = `${name}Id`;

  const [vedomstvoName, , helpersVedomstvo] = useField(fieldName);
  const [vedomstvoId, , helpersVedomstvoId] = useField(filedId);

  return (
    <Field label={label} editMode={editMode} required={required} defaultValue={defaultValue}>
      <FormikSelect
        name={fieldName}
        size="small"
        isSearchable
        disabled={disabled}
        options={list}
        defaultValue={{ value: vedomstvoId.value, label: vedomstvoName.value }}
        placeholder="Выберите департамент"
        selectedValue={(selected: SelectOptionType) => {
          helpersVedomstvo.setValue(selected.label);
          helpersVedomstvoId.setValue(selected.value);
        }}
      />
    </Field>
  );
};

export default VedomstvoField;
