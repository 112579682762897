import React from 'react';
import { Panel, Push, SimpleTable } from '@mosru/esz_uikit';
import { accessVedomst } from '../../../mock-data/access-enum';
import { MetroStation, OrganizationAddressItem } from '../../../types/organization';
import { ItemsWork } from '../../../types/items-work';
import { PlaceServices } from '../../../types/place-service';

type AddressProps = {
  addresses: OrganizationAddressItem[];
  vedomstvoId?: number;
  itemWorks: ItemsWork[] | undefined;
  placeServices: PlaceServices[] | undefined;
};

const OrganizationAddress = ({ addresses, vedomstvoId, itemWorks, placeServices }: AddressProps) => {
  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            {vedomstvoId === accessVedomst.Dogm ? 'Адреса предоставления услуг' : 'Все адреса организации'}
            <span className="color-gray-dark">
              {' \u00A0'}
              {addresses?.length}
            </span>
          </>
        )}
      >
        {vedomstvoId === accessVedomst.Dogm ? (
          <SimpleTable
            data={addresses.map((item, index) => ({ ...item, index: index + 1 }))}
            pageSize={Number.MAX_SAFE_INTEGER}
            hideSort
            columns={[
              {
                dataIndex: 'index',
                title: '№',
                render: (item: any) => item.index,
              },
              {
                dataIndex: 'fullAddress',
                title: 'Адрес',
                render: (item: any) => item.fullAddress,
              },
              {
                dataIndex: 'metroStation',
                title: 'Станции метро',
                render: (item: any) =>
                  item.metroStations?.length ? (
                    item.metroStations.map((metroStation: MetroStation, index: number) => (
                      <span>
                        {metroStation.name}
                        {item.metroStations.length > 1 && !(item.metroStations.length - 1 === index) ? ', ' : ''}
                      </span>
                    ))
                  ) : (
                    <span className="color-gray-dark">Не указаны</span>
                  ),
              },
            ]}
          />
        ) : (
          <SimpleTable
            data={addresses.map((item, index) => ({ ...item, index: index + 1 }))}
            hideSort
            pageSize={Number.MAX_SAFE_INTEGER}
            columns={[
              {
                dataIndex: 'index',
                title: '№',
                render: (item: any) => item.index,
              },
              {
                dataIndex: 'fullAddress',
                title: 'Адрес',
                render: (item: any) => item.fullAddress,
              },
              {
                dataIndex: 'isItemWork',
                title: 'Пункт работы с заявителями',
                render: (item: any) => {
                  const elem = itemWorks?.find((itemPlaceService) => item.id === itemPlaceService.addressId);
                  return elem ? <span>Да</span> : <span className="color-gray-dark">Нет</span>;
                },
              },
              {
                dataIndex: 'isPlaceService',
                title: 'Место предоставления услуг',
                render: (item: any) => {
                  const elem = placeServices?.find((itemPlaceService) => item.id === itemPlaceService.addressId);

                  return elem ? <span>Да</span> : <span className="color-gray-dark">Нет</span>;
                },
              },
            ]}
          />
        )}
      </Panel>
    </>
  );
};

export default OrganizationAddress;
