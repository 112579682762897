import React, { useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Button, IconSearch, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { LoaderCustom } from '../../components/loader-custom';
import { historyState } from '../../mock-data/history-state';
import { SearchOrganizationInitialFormData } from '../../types/organization';
import { ReactComponent as IconOptions } from '../../assets/images/icons/options.svg';
import { ReactComponent as IconUp } from '../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../assets/images/icons/down.svg';
import FormikInput from '../../components/formik/formik-input';
import FormikFormGroup from '../../components/formik/formik-form-group';
import FormikSelect from '../../components/formik/formik-select';
import FormikToggleGroup from '../../components/formik/formik-toggle-group';
import FormikToggle from '../../components/formik/formik-toggle';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { accessAction, accessObject, accessVedomst, generalAccess } from '../../mock-data/access-enum';
import { hasAccessObjectAny, hasGeneralAccess, replaceHistoryState } from '../../lib/utils';
import { searchAction } from '../../lib/utils/organization';
import { dictionariesApi } from '../../lib/api/dictionaries';
import { searchOrganizationInitialFormData } from './index';
import { getAccessSign } from './utils';

type Props = {
  isAdminView: boolean;
  isAdminEdit: boolean;
  initialForm: SearchOrganizationInitialFormData;
  submitForm: (values: SearchOrganizationInitialFormData) => void;
};

const OrganizationSearch: React.FC<Props> = ({ initialForm, submitForm, isAdminView, isAdminEdit }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [isLoadingVedomstvoList, setIsLoadingVedomstvoList] = useState(false);

  const accessToggleArchiveAndTest =
    (hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.ViewRegistry) &&
      userProfile.vedomstvoId !== accessVedomst.Dogm) ||
    isAdminView;

  // Тип организации не определен
  const accessOrganizationNotDefined =
    isAdminEdit &&
    userProfile.vedomstvoId !== accessVedomst.Dkgm &&
    userProfile.vedomstvoId !== accessVedomst.Dogm &&
    userProfile.vedomstvoId !== accessVedomst.Dsit;

  const accessOrgDkgm = isAdminEdit && userProfile.vedomstvoId === accessVedomst.Dkgm;

  const showNotAcceptedToggle = getAccessSign(userProfile, isAdminEdit || isAdminView, true);

  const accessDogm = userProfile.vedomstvoId !== accessVedomst.Dogm || showNotAcceptedToggle;

  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [departmentData, setDepartmentData] = useState<SelectOptionType[]>([]);
  const [rerenderTableKey, setRerenderTableKey] = useState<number>(0);

  useEffect(() => {
    if (isAdminView) {
      (async () => {
        try {
          setIsLoadingVedomstvoList(true);
          const departments = await dictionariesApi.getVedomstvo();
          setDepartmentData(departments);
        } finally {
          setIsLoadingVedomstvoList(false);
        }
      })();
    }
  }, [isAdminView]);

  const resetForm = () => {
    submitForm(searchOrganizationInitialFormData);
    setRerenderTableKey(Math.random());
  };

  const organizationNotDefined = () => {
    const element = (value: string) => (
      <div className="organization-toggle-group__item">
        <FormikToggleGroup name={value} label="Тип организации не определен">
          <FormikToggle name={value} size="xsmall" />
        </FormikToggleGroup>
      </div>
    );

    if (accessOrganizationNotDefined) {
      return element('excludeOrganizationType');
    }
    if (accessOrgDkgm) {
      return element('excludeOrganizationTypeDkgm');
    }
  };

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [historyState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  return (
    <Formik
      key={rerenderTableKey}
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialForm}
    >
      {(formikProps: FormikProps<SearchOrganizationInitialFormData>) => {
        const { handleSubmit, values, isSubmitting } = formikProps;

        const copyObject: Pick<
          SearchOrganizationInitialFormData,
          'excludeWoVedomstvo' | 'organizationNotDefined' | 'query' | 'showArchive' | 'showNotAccepted'
        > = {
          query: values.query,
          showArchive: values.showArchive,
          showNotAccepted: values.showNotAccepted,
          excludeWoVedomstvo: values.excludeWoVedomstvo,
          organizationNotDefined: values.organizationNotDefined,
        };

        // если пользователь(не админ) имеет право на просмотр реестра организаций и организация пользователя ДОНМ + право ЭП тогда убираем excludeTest,
        const value =
          !accessToggleArchiveAndTest &&
          hasGeneralAccess(userProfile, generalAccess.UseSign) &&
          userProfile.vedomstvoId === accessVedomst.Dogm &&
          hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.ViewRegistry)
            ? copyObject
            : values;

        const count = searchAction(value, !accessToggleArchiveAndTest);

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              subControl={() =>
                accessDogm && (
                  <button type="button" onClick={handleClickAdvancedSearch} className="icon-group">
                    <span className="icon-group__icon">
                      <IconOptions />
                    </span>
                    <span className="icon-group__text">Расширенный поиск</span>
                    {count > 0 && !open && (
                      <span className="icon-group__icon">
                        <span className="badge">{count}</span>
                      </span>
                    )}
                    <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
                  </button>
                )
              }
              controls={() => (
                <>
                  <Button label="Сбросить" onClick={resetForm} border size="small" />
                  <Push orientation="horizontal" size={12} />
                  <Button submit label="Начать поиск" load={isSubmitting} primary size="small" />
                </>
              )}
            >
              <div className="container">
                <Push size={20} />
                <FormikInput name="query" placeholder="Поиск по организациям..." iconLeft={() => <IconSearch />} />
                {open &&
                  (isLoadingVedomstvoList ? (
                    <LoaderCustom size={50} />
                  ) : (
                    <>
                      <Push size={16} />

                      {isAdminView && (
                        <div className="organization-select-group">
                          <FormikFormGroup name="vedomstvoId" label="Департамент">
                            <FormikSelect
                              size="small"
                              isSearchable
                              name="vedomstvoId"
                              showClearIndicator
                              options={departmentData}
                              placeholder="Начните вводить или выберите..."
                            />
                          </FormikFormGroup>
                        </div>
                      )}

                      <Push size={16} />

                      <div className="organization-toggle-group">
                        {accessToggleArchiveAndTest && (
                          <>
                            <div className="organization-toggle-group__item">
                              <FormikToggleGroup name="showArchive" label="Отображать архивные">
                                <FormikToggle name="showArchive" size="xsmall" />
                              </FormikToggleGroup>
                            </div>

                            <div className="organization-toggle-group__item">
                              <FormikToggleGroup name="excludeTest" label="Исключить тестовые">
                                <FormikToggle name="excludeTest" size="xsmall" />
                              </FormikToggleGroup>
                            </div>
                          </>
                        )}

                        {showNotAcceptedToggle && (
                          <div className="organization-toggle-group__item">
                            <FormikToggleGroup name="showNotAccepted" label="Ожидающие подтверждения">
                              <FormikToggle name="showNotAccepted" size="xsmall" />
                            </FormikToggleGroup>
                          </div>
                        )}

                        {isAdminEdit && (
                          <div className="organization-toggle-group__item">
                            <FormikToggleGroup name="excludeWoVedomstvo" label="Департамент не определен">
                              <FormikToggle name="excludeWoVedomstvo" size="xsmall" />
                            </FormikToggleGroup>
                          </div>
                        )}

                        {organizationNotDefined()}
                      </div>
                    </>
                  ))}
              </div>
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

export default OrganizationSearch;
