import React from 'react';
import { useField } from 'formik';
import { CheckboxGroup, CheckboxGroupType } from '@mosru/esz_uikit';

export type FormicCheckboxGroupType = CheckboxGroupType & {
  name: string;
  showErrorImmediately?: boolean;
};

const FormikCheckboxGroup: React.FC<Omit<Omit<Omit<FormicCheckboxGroupType, 'checked'>, 'labelId'>, 'onChange'>> = ({
  name,
  showErrorImmediately,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const error = showErrorImmediately ? meta.error : meta.touched && meta.error && meta.initialValue !== field.value;
  return (
    <CheckboxGroup
      {...props}
      checked={field.value}
      labelId={name}
      onChange={(checked) => {
        helpers.setValue(checked);
      }}
      error={error ? (meta.error as string) : ''}
    >
      {props.children}
    </CheckboxGroup>
  );
};

export default FormikCheckboxGroup;
