import React, { useContext } from 'react';
import { Button, Input, IconSearch, Select, SelectOptionType, Push } from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../../../mock-data';
import { ServiceClassTableContext } from '../../utils';

const HeadingControl: React.FC = () => {
  const { setSearch, searchString, filterLearners, setPageSize, pageSize } = useContext(ServiceClassTableContext);
  return (
    <div className="flex">
      <div className="flex" style={{ width: 244 }}>
        <div style={{ width: '192px' }}>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={searchString}
            placeholder="Введите..."
            size="small"
          />
        </div>

        <div style={{ marginLeft: '8px' }}>
          <Button handleClick={filterLearners} primary iconRight={() => <IconSearch />} size="small" />
        </div>
      </div>
      <Push orientation="horizontal" size={32} />
      <div style={{ width: 184 }}>
        <Select
          name="pageSize"
          isSearchable={false}
          hideClearIndicator
          value={pageSizeOptions.find((item) => item.value === pageSize)}
          onChange={(selectedOption) => {
            const { value } = selectedOption as SelectOptionType;
            setPageSize(value as number);
          }}
          options={pageSizeOptions}
          size="small"
        />
      </div>
    </div>
  );
};

export default HeadingControl;
