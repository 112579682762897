import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, ToggleGroup, Infobox, Select, SelectOptionType, Panel, Toggle, Push } from '@mosru/esz_uikit';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import DropDown from '../../../../components/drop-down';
import { ServiceContext } from '../../index';
import AddPlan from '../modals/add-plan';
import { ReactComponent as IconDown } from '../../../../assets/images/icons/down.svg';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { EntranceTestsData, TrainingGroupData } from '../../../../types/service';
import TgListWithTests from './tg-list-with-tests';
import TgList from './tg-list';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import history from '../../../../history';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';

type Props = {
  editable?: boolean;
  serviceId?: number;
  isTestService: boolean;
  test?: EntranceTestsData;
};

const TrainingGroups: React.FC<Props> = ({ editable, serviceId, isTestService, test }) => {
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);
  const [showModal, setShowModal] = useState(false);
  const [showArchive, setShowArchive] = useState(true);
  const [trainingGroups, setTrainingGroups] = useState<TrainingGroupData[]>([]);
  const [currentAdmissionPlans, setCurrentAdmissionPlans] = useState<SelectOptionType>({ value: null, label: '' });
  const [admissionPlans, settAdmissionPlans] = useState<SelectOptionType[]>([]);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  useEffect(() => {
    const trainingGroupOptions = test?.admissionPlanList?.map(
      (item) =>
        ({
          value: item.yearOfTrainingId,
          label: item.yearOfTrainingName,
        } as SelectOptionType)
    );

    if (trainingGroupOptions?.length) {
      settAdmissionPlans(trainingGroupOptions);
      setCurrentAdmissionPlans({ value: trainingGroupOptions[0]?.value, label: trainingGroupOptions[0].label });
    }
  }, [test?.admissionPlanList]);

  const getTGList = useCallback(async () => {
    if (serviceId) {
      const response = await serviceTemplateApi.getServiceTrainingGroupList(serviceId);
      setTrainingGroups(response ?? []);
    }
  }, [serviceId]);

  useEffect(() => {
    getTGList();
  }, [serviceId, getTGList, serviceData.serviceStatusId]);

  const tgList = useMemo(
    () =>
      trainingGroups?.filter(
        (tg) =>
          (showArchive || tg.scheduleList[0]?.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive) &&
          tg.yearOfTrainingId === currentAdmissionPlans?.value
      ),
    [trainingGroups, currentAdmissionPlans, showArchive]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <div className="flex items-center">
            Планы приема{' '}
            {editable && (
              <>
                <span className="color-gray-dark">
                  {'\u00A0'} {tgList.length}
                </span>
                <div className="flex font-weight-base">
                  <Push orientation="horizontal" size={16} />
                  <div style={{ width: 224 }}>
                    <Select
                      name="admissionPlan"
                      onChange={(option) => {
                        setCurrentAdmissionPlans(option as SelectOptionType);
                      }}
                      options={admissionPlans}
                      placeholder="Учебный год"
                      size="small"
                      value={currentAdmissionPlans}
                    />
                  </div>
                  <Push orientation="horizontal" size={16} />
                  <ToggleGroup labelId="showArchive" label="Отображать архивные" size="small">
                    <Toggle
                      size="xsmall"
                      checked={showArchive}
                      labelId="showArchive"
                      onChange={(value) => setShowArchive(value)}
                    />
                  </ToggleGroup>
                </div>
              </>
            )}
          </div>
        )}
        headingControl={() => {
          return editable && checkEditable ? (
            <DropDown
              component={() => (
                <span className="drop-down-btn-round">
                  {admissionPlans?.length > 0 && (
                    <Button primary border iconRight={() => <IconDown />} label="Добавить" size="small" />
                  )}
                </span>
              )}
            >
              <div className="drop-down-panel">
                <div className="drop-down-panel__list">
                  <button
                    type="button"
                    onClick={() => {
                      history.push(
                        `${generateLink(routes.trainingGroup, { serviceId: serviceId ?? 0, id: 0 })}?isRetro=0&yearId=${
                          currentAdmissionPlans.value
                        }`
                      );
                    }}
                    className="drop-down-panel__list-item"
                  >
                    План приема
                  </button>
                  {!isTestService && (
                    <button
                      type="button"
                      onClick={() => {
                        history.push(
                          `${generateLink(routes.trainingGroup, {
                            serviceId: serviceId ?? 0,
                            id: 0,
                          })}?isRetro=1&yearId=${currentAdmissionPlans.value}`
                        );
                      }}
                      className="drop-down-panel__list-item"
                    >
                      План приема без публикации на Mos.ru
                    </button>
                  )}
                </div>
              </div>
            </DropDown>
          ) : null;
        }}
      >
        {editable ? (
          isTestService ? (
            <TgListWithTests list={tgList} getTGList={getTGList} />
          ) : (
            <TgList list={tgList} getTGList={getTGList} />
          )
        ) : (
          <div className="container">
            <Infobox
              fullWidth
              color="warning"
              text="Планы приема станут доступными для ввода после первого сохранения."
            />
            <Push size={24} />
          </div>
        )}
      </Panel>

      <AddPlan show={showModal} hideModal={() => setShowModal(false)} />
    </>
  );
};

export default TrainingGroups;
