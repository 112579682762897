import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconUp } from '../../../../assets/images/icons/up-color.svg';
import { ReactComponent as IconCheck } from '../../../../assets/images/icons/check-dark.svg';
import { ReactComponent as IconWarning } from '../../../../assets/images/icons/clock-warning.svg';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/alert-primary.svg';
import { ReactComponent as IconError } from '../../../../assets/images/icons/error.svg';
import { externalSystemApi } from '../../../../lib/api/external-system';
import { ExternalSystemResponseData } from '../../../../types/external-system';
import { ExternalSystemResponseTypeEnum } from '../../../../mock-data/external-system-response-type-enum';
import { RequestData } from '../../../../types/requests';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { RequestSourceEnum } from '../../../../mock-data/request-source-enum';
import { formatDateAndTime } from '../../../../lib/utils/date';
import requestsApi from '../../../../lib/api/requests';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';

type Props = {
  requestData: RequestData;
};

enum CheckStatusTypeEnum {
  NotRequired = 1,
  Successful = 2,
  NotConfirmed = 3,
  Error = 4,
  Pending = 6,
}

const InterdepartmentalChecks: React.FC<Props> = ({ requestData }) => {
  const [checksDetails, setChecksDetails] = useState(false);
  const [statuses, setStatuses] = useState<ExternalSystemResponseData[] | undefined>();
  const [statusType, setStatusType] = useState<CheckStatusTypeEnum>();

  const buttonVisible = useMemo(
    () =>
      requestData.requestSourceId === RequestSourceEnum.Operator &&
      (requestData.applicant?.documentTypeId === DocumentTypeEnum.Passport ||
        requestData.child?.documentTypeId === DocumentTypeEnum.Passport ||
        requestData.child?.documentTypeId === DocumentTypeEnum.BirthCertificate),
    [requestData]
  );

  const buttonDisabledTimeOut = useMemo(
    () =>
      statuses && statuses.length > 0
        ? statuses.every((s) => s.dateCreate && Date.now() - new Date(s.dateCreate).getTime() < 600000)
        : false,
    [statuses]
  );

  const buttonDisabled = useMemo(
    () =>
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      (statuses && statuses.some((s) => s.externalSystemResponseStatus.statusCode === 1)),
    [requestData.requestStatusId, statuses]
  );

  const fetchStatuses = useCallback(() => {
    const fetch = async () => {
      const statuses = await externalSystemApi.getCheckStatuses(requestData.extendEntityGuid, [
        ExternalSystemResponseTypeEnum.ApplicantPassportValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.BirthCertificateValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.PupilPassportValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.RegistrationAddressValidityCheckingRequest,
        ExternalSystemResponseTypeEnum.RelatednessCheckingRequest,
        ExternalSystemResponseTypeEnum.BirthRecordCheck,
      ]);
      setStatuses(statuses);
    };
    return fetch();
  }, [requestData.extendEntityGuid]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses, requestData.extendEntityGuid]);

  useEffect(() => {
    if (statuses?.length === 0) {
      setStatusType(CheckStatusTypeEnum.NotRequired);
    } else if (
      statuses?.every(
        (e) => e.externalSystemResponseStatus.statusCode === 2 || e.externalSystemResponseStatus.statusCode === 5
      )
    ) {
      setStatusType(CheckStatusTypeEnum.Successful);
    } else if (statuses?.every((e) => e.externalSystemResponseStatus.statusCode === 3)) {
      setStatusType(CheckStatusTypeEnum.NotConfirmed);
    } else if (statuses?.some((e) => e.externalSystemResponseStatus.isErrorStatus)) {
      setStatusType(CheckStatusTypeEnum.Error);
    } else if (statuses?.some((e) => e.externalSystemResponseStatus.statusCode === 1)) {
      setStatusType(CheckStatusTypeEnum.Pending);
    }
  }, [statuses]);

  const getStatusColor = useCallback(() => {
    switch (statusType) {
      case CheckStatusTypeEnum.NotRequired:
        return 'primary';
      case CheckStatusTypeEnum.Successful:
        return 'success';
      case CheckStatusTypeEnum.NotConfirmed:
      case CheckStatusTypeEnum.Error:
        return 'danger';
      case CheckStatusTypeEnum.Pending:
        return 'warning';
      default:
        break;
    }
  }, [statusType]);

  const buildHead = useCallback(() => {
    switch (statusType) {
      case CheckStatusTypeEnum.NotRequired:
        return (
          <span className={`color-${getStatusColor()}-dark font-weight-bold`}>
            Межведомственные проверки не требуются
          </span>
        );
      case CheckStatusTypeEnum.Successful:
        return (
          <span className={`color-${getStatusColor()}-dark font-weight-bold`}>
            Межведомственные проверки пройдены успешно
          </span>
        );
      case CheckStatusTypeEnum.NotConfirmed:
        return (
          <span className={`color-${getStatusColor()}-dark font-weight-bold`}>
            Межведомственные проверки пройдены. Данные не подтверждены
          </span>
        );
      case CheckStatusTypeEnum.Error:
        return (
          <span className={`color-${getStatusColor()}-dark font-weight-bold`}>
            Межведомственные проверки не пройдены
          </span>
        );
      case CheckStatusTypeEnum.Pending:
        return (
          <span className={`color-${getStatusColor()}-dark font-weight-bold`}>Межведомственные проверки запрошены</span>
        );
      default:
        break;
    }
  }, [statusType, getStatusColor]);

  const getStatusIcon = useCallback(() => {
    switch (statusType) {
      case CheckStatusTypeEnum.NotRequired:
        return <IconInfo className="flex-none" />;
      case CheckStatusTypeEnum.Successful:
        return <IconCheck className="flex-none" />;
      case CheckStatusTypeEnum.NotConfirmed:
      case CheckStatusTypeEnum.Error:
        return <IconError className="flex-none" />;
      case CheckStatusTypeEnum.Pending:
        return <IconWarning className="flex-none" />;
      default:
        break;
    }
  }, [statusType]);

  return statuses ? (
    <>
      <Push size={12} />
      <div className={`infobox infobox--${getStatusColor()}`}>
        <div className="infobox__head">
          <div className="infobox__body">
            <div className="flex">
              {getStatusIcon()}
              <Push size={8} orientation="horizontal" />
              {buildHead()}
            </div>
          </div>
          <div className="infobox__control">
            {statuses?.length !== 0 && (
              <button type="button" onClick={() => setChecksDetails(!checksDetails)} className="icon-group">
                <span className={`icon-group__text color-${getStatusColor()}-dark`}>
                  {checksDetails ? 'Свернуть' : 'Подробнее'}
                </span>
                <span className={classNames(`icon-group__icon`, !checksDetails && 'icon-group__icon--rotate')}>
                  <IconUp className={`requests-infobox-icon--${getStatusColor()}`} />
                </span>
              </button>
            )}

            <Push size={20} orientation="horizontal" />
            {buttonVisible && (
              <Button
                size="small"
                border
                primary
                label="Запросить сведения"
                disabled={buttonDisabledTimeOut || buttonDisabled}
                onClick={async () => {
                  await requestsApi.sendIrsRequests(requestData.extendEntityGuid);
                  fetchStatuses();
                }}
              />
            )}
          </div>
        </div>

        {checksDetails && statuses && (
          <div className="infobox__details">
            <Push size={24} />
            <div className="requests-infobox-table">
              <div className="requests-infobox-table__row">
                <div className="requests-infobox-table__type">
                  <span className="font-weight-bold">Тип проверки</span>
                </div>
                <div className="requests-infobox-table__dates">
                  <span className="font-weight-bold">Начало и окончание проверки</span>
                </div>
                <div className="requests-infobox-table__details">
                  <span className="font-weight-bold">Результат</span>
                </div>
              </div>
              {statuses.map((s) => (
                <div className="requests-infobox-table__row">
                  <div className="requests-infobox-table__type">
                    {s.externalSystemResponseStatus.externalSystemResponseType.responseTypeName}
                  </div>
                  <div className="requests-infobox-table__dates">
                    {s.externalSystemResponseStatus.statusCode === 5 ||
                    s.externalSystemResponseStatus.statusCode === 0 ? (
                      <>—</>
                    ) : s.externalSystemResponseStatus.statusCode === 1 ? (
                      formatDateAndTime(s.dateCreate)
                    ) : (
                      formatDateAndTime(s.dateCreate) + (s.dateChange ? `—${formatDateAndTime(s.dateChange)}` : '')
                    )}
                  </div>
                  <div className="requests-infobox-table__details">{s.externalSystemResponseStatus.statusName}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default InterdepartmentalChecks;
