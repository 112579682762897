import React, { useContext, useMemo, useState } from 'react';
import { Badge, Button, ButtonGroup, IconPlus, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import Details from './details';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import ChangeHistory from '../../../components/change-history';
import { RequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { generateLink, getServiceEditLink, redirect } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import { serviceTypeEnum } from '../../../mock-data/print';
import { RequestContext } from '../../../lib/utils/requests';
import { EducationTypeEnum } from '../../../types/education-type';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const RegistryStatementsDonm: React.FC<Props> = ({ requestData, updateRequest }) => {
  const [activeTab, setActiveTab] = useState('Основные сведения');

  const { serviceDOGMEdit, serviceNonDOGMEdit, oiv, adminView, routeBack } = useContext(RequestContext);

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <Details requestData={requestData} updateRequest={updateRequest} />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name="сведения о заявлении"
            extendEntityGuid={requestData?.extendEntityGuid}
            entityTypes={[EntityTypeEnum.Request]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [requestData, updateRequest]
  );

  const backDO = () => redirect(getServiceEditLink(requestData.educationTypeId, requestData.trainingGroup?.serviceId));

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(requestData.dateRequest)}
              <Push size={20} orientation="horizontal" />
              Источник: {requestData.requestSourceName}
              <Push size={20} orientation="horizontal" />
            </div>
            <Push size={12} />
          </>
        }
        buttonBack
        routeBack={routeBack}
        expansion={
          <div className="flex items-center">
            <Favorite
              entityId={requestData.id}
              typeEntity="заявление"
              currentEntityType={`№${requestData.requestNumber || ''}`}
              entityTypeId={EntityTypeEnum.Request}
            />
            <Push size={20} orientation="horizontal" />
            <Badge text={requestData.requestStatusName} {...getRequestStatusColor(requestData.requestStatusId)} />
          </div>
        }
        title={`Заявление №${requestData.requestNumber}`}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Сведения о заявлении' },
        ]}
        activeTab={activeTab}
        tabs={tabs}
        controlsTopRight={
          <ButtonGroup>
            {(serviceDOGMEdit || serviceNonDOGMEdit) && (
              <Button handleClick={backDO} label="Вернуться к ДО" size="small" />
            )}
            {!oiv && !adminView && requestData.isCopyRequestAllowed && (
              <Button
                label="Создать на основе текущего"
                handleClick={() => {
                  window.open(generateLink(routes.createRequestBasedOn, { id: requestData.id }), '_blank');
                }}
                iconLeft={() => <IconPlus />}
                size="small"
              />
            )}
            <Button
              label="Печать"
              handleClick={() =>
                window.open(
                  generateLink(routes.printRequest, {
                    type:
                      requestData.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation
                        ? serviceTypeEnum.notDonm
                        : serviceTypeEnum.donm,
                    id: requestData.id,
                  }),
                  '_blank'
                )
              }
              iconLeft={() => <IconPrinter />}
              size="small"
            />
          </ButtonGroup>
        }
      />

      {tabs.find((item) => item.label === activeTab)?.component}
    </>
  );
};

export default RegistryStatementsDonm;
