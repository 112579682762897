import React from 'react';
import { cloneDeep } from 'lodash';
import { Input, IconSearch, TreeNodeType } from '@mosru/esz_uikit';
import { Key } from 'rc-tree/lib/interface';
import { searchTreeElements } from '../helpers';
import { TreeModalContext } from '../index';

type Props = {
  setLoading: (value: boolean) => void;
  setSelectedKeys: (value: number[]) => void;
  setInitialData: (value: TreeNodeType[]) => void;
  setKeyRandom: (value: number) => void;
  setExpandedKeys: (value: Key[]) => void;
};

const Search: React.FC<Props> = ({ setInitialData, setLoading, setSelectedKeys, setKeyRandom, setExpandedKeys }) => {
  const { dataTreePopup, placeholderName } = React.useContext(TreeModalContext);

  const searchElement = (value: string) => {
    const copyArray = cloneDeep(dataTreePopup);
    setSelectedKeys([]);
    const filterExpandedKeys: Key[] = [];

    const result = searchTreeElements(value.toLowerCase().trim(), copyArray, filterExpandedKeys);

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);

      const search = result.map((item: TreeNodeType) => String(item.key)).join(' ');

      const searchCurrentElement = copyArray.find((item: TreeNodeType) => item.key === search);

      if (searchCurrentElement && searchCurrentElement.children.length === 1) {
        setSelectedKeys([Number(searchCurrentElement.key), Number(searchCurrentElement.children[0].key)]);
      } else if (searchCurrentElement && searchCurrentElement.children) {
        setSelectedKeys([
          Number(searchCurrentElement.key),
          ...searchCurrentElement.children.map((item: TreeNodeType) => Number(item.key)),
        ]);
      }

      setInitialData(value ? result : dataTreePopup);
      setKeyRandom(value ? Math.random() : 0);
      setExpandedKeys(value ? filterExpandedKeys : []);
    }, 1000);

    if (!value.length) {
      setLoading(false);
    }
    setLoading(false);
    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <Input
      size="small"
      placeholder={placeholderName || 'Поиск...'}
      onChange={(value) => searchElement(value.target.value)}
      iconLeft={() => <IconSearch />}
    />
  );
};

export default Search;
