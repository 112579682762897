import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { ChildrenSearchResult } from '../../types/contingent';
import { Person } from '../../types/like-modal';

export const searchChildrenByPersonalData = async (
  firstName: string | undefined,
  lastName: string | undefined,
  middleName: string | undefined,
  birthDay: string | undefined
): Promise<ChildrenSearchResult[]> => {
  const childrens = await fetchRequest.get(`${apiService.contingent}/contingent/byPersonalData`, {
    firstName,
    lastName,
    middleName,
    birthDay,
  });
  return childrens;
};

export const checkPrivilege = async (
  requestId: number,
  contingentGuid: string,
  extRequestId: string,
  privilegeCategories: number
) =>
  await fetchRequest.get(`${apiService.contingent}/query/checkPrivilege`, {
    requestId,
    contingentGuid,
    extRequestId,
    privilegeCategories,
  });

export const getPersonLike = async (requestExtendEntityGuid: string) =>
  await fetchRequest.get(`${apiService.contingent}/contingent/personsLike`, {
    requestExtendEntityGuid,
  });

export const updatePerson = async (requestExtendEntityGuid: string, data: Person | undefined) =>
  await fetchRequest.put(
    `${apiService.contingent}/contingent/updatePersonByOperator?requestExtendEntityGuid=${requestExtendEntityGuid}`,
    data
  );

const contingentApi = {
  searchChildrenByPersonalData,
  checkPrivilege,
  getPersonLike,
  updatePerson,
};

export default contingentApi;
