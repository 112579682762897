import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../../components/formik/formik-select';
import lookupApi from '../../../../../../lib/api/lookup';
import { DocumentDataFinishedAndTypes } from '../../../../../../types/learners';
import FormikInput from '../../../../../../components/formik/formik-input';

type Props = {
  listModules: SelectOptionType[];
  accessCheck: boolean;
  programName: string;
  organization: SelectOptionType | null;
  setAcceptEducationProgram: (value: SelectOptionType) => void;
  unionCatalogServicesId: number;
  setOrganization: (value: SelectOptionType | null) => void;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
  acceptEducationProgram: SelectOptionType;
  hasCreateDocument: boolean;
};
export const AcceptFields: React.FC<Props> = ({
  setAcceptEducationProgram,
  programName,
  unionCatalogServicesId,
  accessCheck,
  documentDataFinishedAndTypes,
  setOrganization,
  acceptEducationProgram,
  listModules,
  organization,
  hasCreateDocument,
}) => {
  const defaultTutorialProgram: SelectOptionType = { label: 'Выберите образовательную программу', value: 0 };

  const { setFieldValue, values } = useFormikContext<any>();

  const [rerenderModule, setRerenderModule] = useState(0);

  const defaultValueEducation = {
    label: programName || defaultTutorialProgram.label,
    value: unionCatalogServicesId || defaultTutorialProgram.value,
  };

  return (
    <>
      <FormikFormGroup name="catalogName" required label="Образовательная программа">
        <FormikSelect
          size="small"
          name="catalogName"
          selectedValue={(option: SelectOptionType) => {
            if (option && option.additionalPropertiesJson) {
              const parse = JSON.parse(option.additionalPropertiesJson);
              setOrganization({ label: parse.OrganizationName, value: parse.OrganizationId });
              setAcceptEducationProgram(option);

              setFieldValue('moduleName', null);
              setFieldValue('moduleId', null);

              setFieldValue('catalogId', option.value);
              setFieldValue('catalogName', option.label);

              if (values.acceptEducationProgram !== option.value && option) {
                setFieldValue('moduleId', null);
                setFieldValue('moduleName', null);

                setRerenderModule(Math.random());
              }
            } else {
              setFieldValue('catalogName', null);
              setFieldValue('catalogId', null);

              setFieldValue('moduleId', null);
              setFieldValue('moduleName', null);

              setRerenderModule(Math.random());
            }
          }}
          loadOptions={async (query) => await lookupApi.getDocumentReacceptServices(query)}
          isSearchable
          options={[]}
          disabled={!accessCheck || !hasCreateDocument}
          placeholder="Выберите образовательную программу"
          defaultValue={documentDataFinishedAndTypes.docFinished.length ? null : defaultValueEducation}
        />
      </FormikFormGroup>

      <Push size={16} />

      <FormikFormGroup key={rerenderModule} name="moduleName" label="Модуль">
        <FormikSelect
          size="small"
          isSearchable
          name="moduleName"
          selectedValue={(option: SelectOptionType) => {
            setFieldValue('moduleId', option.value);
            setFieldValue('moduleName', option.label);
          }}
          options={listModules || []}
          disabled={!(acceptEducationProgram && !!acceptEducationProgram.value)}
          placeholder="Сначала выберите образовательную программу"
        />
      </FormikFormGroup>

      <Push size={16} />

      <FormikFormGroup name="organizationName" label="Организация">
        <FormikInput
          size="small"
          name="organizationName"
          disabled
          value={organization?.label}
          placeholder="Сначала выберите образовательную программу"
        />
      </FormikFormGroup>
    </>
  );
};
