import { EducationTypeEnum } from '../../types/education-type';
import { ListMenuAddGroup, ScheduleData, SearchInitialFormData, SearchItem } from '../../types/service';
import { RegistryTypeEnum } from '../../mock-data/registry-type-enum';
import { AuthorizationData } from '../../types/authorization-data';
import { generateLink, hasAccessObjectAny } from '.';
import { accessAction, accessObject } from '../../mock-data/access-enum';
import { TrainingGroupScheduleData } from '../../types/requests';
import { formatDate, formatTime } from './date';
import { TypesOfRepetitionEnum } from '../../mock-data/type-of-repetition';
import { DurationOfTrainingUnitEnum } from '../../mock-data/duration-of-training-unit-enum';
import { bookingApi } from '../api/booking';
import { routes, searchServiceRegistryName } from '../../config/constants';
import history from '../../history';

export const selectServiceItemCallback =
  window.name === searchServiceRegistryName ? window.opener?.selectServiceItem : undefined;

export const transformServiceDataSearch = (data: SearchItem[], pageNum: number, countPage: number) => {
  return data?.map((item: SearchItem, index: number) => {
    return {
      ...item,
      count: index + pageNum * countPage + 1,
      selected: false,
    };
  });
};

export const countSignedElements = (count: number) => {
  const dictionary = () => {
    if (count === 1) {
      return 'выбранную услугу';
    } else if (count > 1 && count <= 4) {
      return 'выбранные услуги';
    } else if (count > 4) {
      return 'выбранных услуг';
    } else {
      return null;
    }
  };

  return `${count} ${dictionary()}`;
};

export const dictionaryService = (type: string, edTypes: EducationTypeEnum[]): { [key: string]: string } => {
  const checkAccessOneRole = (edType: EducationTypeEnum) => edTypes.length === 1 && edTypes[0] === edType;
  const accessVa = checkAccessOneRole(EducationTypeEnum.VirtualAssistantEducation);
  const classDOGM = checkAccessOneRole(EducationTypeEnum.ChildrenEducation);
  const classNonDOGM = checkAccessOneRole(EducationTypeEnum.ChildrenNonDogmEducation);
  const dkgm = checkAccessOneRole(EducationTypeEnum.ArtHouseEducation);
  const dsit = checkAccessOneRole(EducationTypeEnum.SportEducation);

  const addBtnNameForServiceChildType = (): string => {
    if (selectServiceItemCallback) {
      return '';
    }
    if (classDOGM) {
      return 'Добавить детское объединение ДОНМ';
    } else if (classNonDOGM) {
      return 'Добавить детское объединение';
    } else {
      return 'Добавить';
    }
  };

  switch (type) {
    case RegistryTypeEnum.serviceChildType:
      return {
        title: 'Детские объединения',
        subTitle: 'Поиск детских объединений',
        addBtn: addBtnNameForServiceChildType(),
        placeholder: 'Поиск по детским объединениям...',
        loading: 'Загружаем реестр детских объединений',
      };

    case RegistryTypeEnum.serviceEducationProgramType:
      return {
        title: 'Образовательные программы',
        subTitle: '',
        addBtn: 'Добавить образовательную программу',
        placeholder: 'Поиск по образовательным программам...',
        loading: 'Загружаем реестр образовательных программ',
      };

    case RegistryTypeEnum.serviceGpdType:
      return {
        title: 'Программы по уходу и присмотру за детьми школьного возраста',
        subTitle: '',
        addBtn: 'Добавить программу',
        placeholder: 'Поиск по программам по уходу и присмотру за детьми школьного возраста...',
        loading: 'Загружаем реестр программ продленного дня',
      };
    case RegistryTypeEnum.serviceEducationType:
      return {
        title: accessVa ? 'Услуги цифрового репетитора' : 'Образовательные услуги',
        subTitle: '',
        addBtn: accessVa
          ? 'Добавить услугу цифрового репетитора'
          : dkgm
          ? 'Добавить образовательную услугу дома творчества'
          : dsit
          ? 'Добавить образовательную услугу спортивной секции'
          : 'Добавить',
        placeholder: accessVa ? 'Поиск по услугам цифрового репетитора...' : 'Поиск по образовательным услугам...',
        loading: accessVa ? 'Загружаем услуги цифрового репетитора' : 'Загружаем образовательные услуги',
      };

    case RegistryTypeEnum.serviceTemplateType:
      return {
        title: 'Шаблоны услуг',
        subTitle: '',
        addBtn: dkgm
          ? 'Добавить шаблон дома творчества'
          : dsit
          ? 'Добавить шаблон спортивной секции'
          : 'Добавить шаблон',
        placeholder: 'Поиск по шаблонам услуг...',
        loading: 'Загружаем шаблоны услуг',
      };
    default:
      return {};
  }
};

export const findNumberPages = (data: any, tableTotal: number, pageSize: number, pageNum: number) => {
  const numbersPages = Math.ceil(tableTotal / pageSize);

  const fakeArray = Array(numbersPages).fill([]);
  fakeArray[pageNum] = data;
  return fakeArray;
};

export const getCountDiff = (values: any, filters: SearchInitialFormData): number => {
  return Object.keys(values).reduce((acc, valueIndex) => {
    if (
      !valueIndex.endsWith('Name') &&
      !(values[valueIndex] === filters[valueIndex as keyof SearchInitialFormData]) &&
      values[valueIndex] !== null &&
      values[valueIndex] !== '' &&
      values[valueIndex] !== 0 &&
      valueIndex !== 'servicePlaceQuery' &&
      valueIndex !== 'parallelData'
    ) {
      acc++;
    }

    return acc;
  }, 0);
};

export const removeEmptyDataForRequest = (requestData: { [key: string]: any }, excel?: boolean) => {
  for (const key in requestData) {
    const condition =
      requestData[key] === null ||
      requestData[key] === undefined ||
      requestData[key] === '' ||
      requestData[key] === 'parallelData';

    if (excel ? condition : condition || requestData[key] === 'Все') {
      delete requestData[key];
    }
  }

  return requestData;
};

export const listMenuAddGroup = (
  userProfile: AuthorizationData,
  isAdmin: boolean,
  type: RegistryTypeEnum
): ListMenuAddGroup[] => {
  // детское объединение ДОНМ
  const classDOGM: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceDOGM], accessAction.Create) || isAdmin;

  // детское объединение
  const classNonDOGM: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceNonDOGM], accessAction.Create) || isAdmin;

  // образовательную услугу спортивной секции
  const classSport = hasAccessObjectAny(userProfile, [accessObject.ServiceSport], accessAction.Create) || isAdmin;

  // образовательной услугу дома творчества
  const serviceArtHouse: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceArtHouse], accessAction.Create) || isAdmin;

  // образовательную программу
  const classOP: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.Create) || isAdmin;

  // группу
  const serviceDayCare: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.Create) || isAdmin;

  // услугу цифрового репетитора (без Администратора (ред.))
  const serviceVa: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Create);

  // шаблон спортивной секции
  const serviceTemplateSport: boolean =
    hasAccessObjectAny(userProfile, [accessObject.TemplateSport], accessAction.Create) || isAdmin;

  // шаблон дома творчества
  const serviceTemplateArtHouse: boolean =
    hasAccessObjectAny(userProfile, [accessObject.TemplateArtHouse], accessAction.Create) || isAdmin;

  const menu: ListMenuAddGroup[] | null = [];

  const listMenu = {
    classDOGM: {
      label: 'детское объединение ДОНМ',
      link: `service/create/${EducationTypeEnum.ChildrenEducation}`,
      educationType: EducationTypeEnum.ChildrenEducation,
    },
    classNonDOGM: {
      label: 'детское объединение',
      link: `service/create/${EducationTypeEnum.ChildrenNonDogmEducation}`,
      educationType: EducationTypeEnum.ChildrenNonDogmEducation,
    },
    classSport: {
      label: 'образовательную услугу спортивной секции',
      link: `service/create/${EducationTypeEnum.SportEducation}`,
      educationType: EducationTypeEnum.SportEducation,
    },
    serviceArtHouse: {
      label: 'образовательной услугу дома творчества',
      link: `service/create/${EducationTypeEnum.ArtHouseEducation}`,
      educationType: EducationTypeEnum.ArtHouseEducation,
    },
    classOP: {
      label: 'образовательную программу',
      link: `service/create/${EducationTypeEnum.ProfessionalEducation}`,
      educationType: EducationTypeEnum.ProfessionalEducation,
    },
    serviceDayCare: {
      label: 'программу по уходу и присмотру',
      link: `service/create/${EducationTypeEnum.DayCareCentersEducation}`,
      educationType: EducationTypeEnum.DayCareCentersEducation,
    },
    serviceVa: {
      label: 'услугу цифрового репетитора',
      link: `service/create/${EducationTypeEnum.VirtualAssistantEducation}`,
      educationType: EducationTypeEnum.VirtualAssistantEducation,
    },
    serviceTemplateSport: {
      label: 'шаблон спортивной секции',
      link: `myTemplates/create/${EducationTypeEnum.SportEducation}`,
      educationType: EducationTypeEnum.SportEducation,
    },
    serviceTemplateArtHouse: {
      label: 'шаблон дома творчества',
      link: `myTemplates/create/${EducationTypeEnum.ArtHouseEducation}`,
      educationType: EducationTypeEnum.ArtHouseEducation,
    },
  };

  if (type === RegistryTypeEnum.serviceChildType) {
    if (classDOGM) {
      menu.push(listMenu.classDOGM);
    }
    if (classNonDOGM) {
      menu.push(listMenu.classNonDOGM);
    }
    if (serviceVa || isAdmin) {
      menu.push(listMenu.serviceVa);
    }
    if (serviceDayCare) {
      menu.push(listMenu.serviceDayCare);
    }
  }

  if (type === RegistryTypeEnum.serviceEducationType) {
    if (classSport) {
      menu.push(listMenu.classSport);
    }
    if (serviceArtHouse) {
      menu.push(listMenu.serviceArtHouse);
    }
    if (serviceVa && !isAdmin) {
      menu.push(listMenu.serviceVa);
    }
  }

  if (type === RegistryTypeEnum.serviceGpdType) {
    if (serviceDayCare) {
      menu.push(listMenu.serviceDayCare);
    }
  }

  if (type === RegistryTypeEnum.serviceEducationProgramType) {
    if (classOP) {
      menu.push(listMenu.classOP);
    }
  }

  if (type === RegistryTypeEnum.serviceTemplateType) {
    if (serviceTemplateSport) {
      menu.push(listMenu.serviceTemplateSport);
    }

    if (serviceTemplateArtHouse) {
      menu.push(listMenu.serviceTemplateArtHouse);
    }
  }

  return menu;
};

export const buildScheduleDisplayName = (schedule: TrainingGroupScheduleData | ScheduleData): string => {
  if (!schedule.periodFrom) {
    return '';
  }
  let name = `${
    schedule.typesOfRepetitionId === 3
      ? formatDate(schedule.periodFrom)
      : schedule.periodTo
      ? `${formatDate(schedule.periodFrom)} - ${formatDate(schedule.periodTo)}`
      : `${formatDate(schedule.periodFrom)} - не ограничено`
  }`;

  if (schedule.typesOfRepetitionId === 4) {
    name += ', повторять каждый ';
    if (!schedule.repeatNumber1) {
      name += 'рабочий день';
    } else if (schedule.repeatNumber1 !== 1) {
      name += `${schedule.repeatNumber1}-й день`;
    }
  } else if (schedule.typesOfRepetitionId === 1) {
    const days = [];
    if (schedule.monday) {
      days.push('пн.');
    }
    if (schedule.tuesday) {
      days.push('вт.');
    }
    if (schedule.wednesday) {
      days.push('ср.');
    }
    if (schedule.thursday) {
      days.push('чт.');
    }
    if (schedule.friday) {
      days.push('пт.');
    }
    if (schedule.saturday) {
      days.push('сб.');
    }
    if (schedule.sunday) {
      days.push('вс.');
    }
    name += ', повторять каждую ';

    if (schedule.repeatNumber1 && schedule.repeatNumber1 > 1) {
      name += `${schedule.repeatNumber1}-ю  `;
    }

    name += `неделю, по ${days.join(', ')}`;
  } else if (schedule.typesOfRepetitionId === 2) {
    let addonString = '';
    if (schedule.dayOfWeek) {
      const addonFunc = (repeatNumber: number | undefined, postfix: string, day: string) =>
        `${repeatNumber === 5 ? 'последний' : repeatNumber === 1 ? ' каждый' : `${repeatNumber}-${postfix}`} ${day}`;

      switch (schedule.dayOfWeek) {
        case 1:
          addonString = addonFunc(schedule.repeatNumber1, 'й', 'понедельник');
          break;
        case 2:
          addonString = addonFunc(schedule.repeatNumber1, 'й', 'вторник');
          break;
        case 3:
          addonString = addonFunc(schedule.repeatNumber1, 'ю', 'среду');
          break;
        case 4:
          addonString = addonFunc(schedule.repeatNumber1, 'й', 'четверг');
          break;
        case 5:
          addonString = addonFunc(schedule.repeatNumber1, 'ю', 'пятницу');
          break;
        case 6:
          addonString = addonFunc(schedule.repeatNumber1, 'ю', 'субботу');
          break;
        case 7:
          addonString = addonFunc(schedule.repeatNumber1, 'е', 'воскресенье');
          break;
        default:
          break;
      }
    } else {
      addonString = `${schedule.repeatNumber1}-го числа`;
    }

    let month = '';
    if ((schedule.repeatNumber2 ?? 1) > 1) {
      month = `${schedule.repeatNumber2}-го `;
    }
    name += `, повторять ${addonString} каждого ${month}месяца`;
  }

  name += ` ${formatTime(schedule.timeStart)}-${formatTime(schedule.timeEnd)}`;
  return name;
};

export const repetitionTypes = [
  {
    label: 'ежедневно',
    value: TypesOfRepetitionEnum.RepeatDaily,
  },
  {
    label: 'еженедельно',
    value: TypesOfRepetitionEnum.RepeatWeekly,
  },
  {
    label: 'ежемесячно',
    value: TypesOfRepetitionEnum.RepeatMonthly,
  },
];

export const daysForTrainingSchedule = [
  { label: 'Пн', value: 'monday', fullName: 'понедельник', id: 1 },
  { label: 'Вт', value: 'tuesday', fullName: 'вторник', id: 2 },
  { label: 'Ср', value: 'wednesday', fullName: 'среду', id: 3 },
  { label: 'Чт', value: 'thursday', fullName: 'четверг', id: 4 },
  { label: 'Пт', value: 'friday', fullName: 'пятницу', id: 5 },
  { label: 'Сб', value: 'saturday', fullName: 'субботу', id: 6 },
  { label: 'Вс', value: 'sunday', fullName: 'воскресенье', id: 7 },
];

export const weekNumberInMonth = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: 'последн', value: 5 },
];

export const postfixMatrix = [
  ['й', 'й', 'ю', 'й', 'ю', 'ю', 'е'],
  ['ий', 'ий', 'юю', 'ий', 'юю', 'юю', 'ее'],
];

export const calcLearnEnd = (
  startDate: Date,
  durationOfTrainingUnitId?: number,
  durationOfTraining?: number,
  durationOfTrainingMonths?: number,
  durationOfTrainingWeeks?: number,
  durationOfTrainingDays?: number
): Date => {
  if (durationOfTrainingUnitId && durationOfTraining) {
    switch (durationOfTrainingUnitId) {
      case DurationOfTrainingUnitEnum.Years:
        startDate.setFullYear(startDate.getFullYear() + durationOfTraining);
        break;
      case DurationOfTrainingUnitEnum.Month:
        startDate.setMonth(startDate.getMonth() + durationOfTraining);
        break;
      case DurationOfTrainingUnitEnum.Weeks:
        startDate.setDate(startDate.getDate() + 7 * durationOfTraining);
        break;
      case DurationOfTrainingUnitEnum.Days:
        startDate.setDate(startDate.getDate() + durationOfTraining);
        break;
      default:
        break;
    }
  } else {
    if (durationOfTrainingDays) {
      startDate.setDate(startDate.getDate() + durationOfTrainingDays);
    }
    if (durationOfTrainingWeeks) {
      startDate.setDate(startDate.getDate() + durationOfTrainingWeeks * 7);
    }
    if (durationOfTrainingMonths) {
      startDate.setMonth(startDate.getMonth() + durationOfTrainingMonths);
    }
    if (durationOfTraining) {
      startDate.setFullYear(startDate.getFullYear() + durationOfTraining);
    }
  }
  return startDate;
};

export const apply = async (scheduleId: number, educationTypeId?: number) => {
  if (educationTypeId && educationTypeId === EducationTypeEnum.DayCareCentersEducation) {
    history.push(generateLink(routes.createRequest, { edType: educationTypeId }));
  } else {
    const slots = await bookingApi.getTrainingGroupSlots(scheduleId);
    if (slots?.length > 0) {
      history.push(generateLink(routes.createRequestBySchedule, { slotId: slots[0].id }));
    }
  }
};
