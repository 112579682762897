import React from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikInput from '../../../../components/formik/formik-input';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

type ApartmentProps = FieldProps<string> & {
  disabled?: boolean;
};

const Apartment = ({
  label = 'Квартира',
  defaultValue = '—',
  name,
  editMode,
  required,
  disabled = false,
}: ApartmentProps) => {
  return (
    <FormikField name={name}>
      {({ field }: FormikFieldProps) => {
        return (
          <Field label={label} editMode={editMode} required={required} value={field.value} defaultValue={defaultValue}>
            <div className="table-data__item table-data__group">
              <div className="table-data__body">
                <div className="table-data-grid-4">
                  <div>
                    <FormikFormGroup required label="" name={name}>
                      <FormikInput size="small" placeholder="Введите..." name={name} disabled={disabled} />
                    </FormikFormGroup>
                  </div>
                </div>
              </div>
            </div>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default Apartment;
