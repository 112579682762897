import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import {
  AuthOrganizationsType,
  ChangePasswordData,
  LoginData,
  LoginResponse,
  PasswordSettings,
} from '../../types/auth';
import { MenuSettings, Response } from '../../types/entities';
import { RoomUserType } from '../../containers/password-rules/types/room';

export const signIn = async (data: LoginData) => {
  const response = await fetchRequest.post(`${apiService.auth}/auth/Login`, data, {
    isProtected: false,
  });
  return response;
};

export const changePassword = async (data: ChangePasswordData): Promise<LoginResponse> => {
  const response = await fetchRequest.patch(`${apiService.auth}/User/Password`, data, {
    isProtected: false,
  });
  return response;
};

export const logout = async () => {
  await fetchRequest.post(
    `${apiService.auth}/auth/Logout`,
    {},
    {
      isProtected: true,
    }
  );
};

export const getProfile = async () => await fetchRequest.get(`${apiService.auth}/User/CurrentUser`);

export const updateProfile = async (data: RoomUserType) =>
  await fetchRequest.patch(`${apiService.auth}/User/Information`, data);

export const generatePassword = async () => await fetchRequest.get(`${apiService.auth}/User/Password`);

export const getMenuSettings = async (): Promise<Response<MenuSettings[]>> =>
  await fetchRequest.get(`${apiService.data}/MenuSettings`);

export const getSettings = async (): Promise<PasswordSettings> => {
  const settings = await fetchRequest.get(`${apiService.auth}/Auth/settings`, {}, { isProtected: false });
  if (settings) {
    return settings;
  }
  throw new Error('Не удалось получить данные');
};

const loginBySign = async (sign: string) => {
  const response = await fetchRequest.post(
    `${apiService.auth}/auth/LoginBySign`,
    { sign, appName: 'ui' },
    {
      isProtected: false,
    }
  );
  return response;
};

const checkSign = async (sign: string): Promise<boolean> =>
  await fetchRequest.post(`${apiService.auth}/User/CheckUserSign`, sign);

const getOrganizationList = async (token: string): Promise<AuthOrganizationsType[]> =>
  await fetchRequest.get(`${apiService.auth}/Auth/Organization/List?aupdToken=${token}`, null, { isProtected: false });

export const loginAupd = async (data: { token: string; login: string }): Promise<LoginResponse> =>
  await fetchRequest.post(`${apiService.auth}/Auth/Login/Aupd`, data, { isProtected: false });

const checkLocalAuthApiKey = async (): Promise<boolean> =>
  await fetchRequest.get(`${apiService.auth}/Auth/AuthKey`, undefined, { isProtected: false });

const auth = {
  signIn,
  changePassword,
  getProfile,
  getMenuSettings,
  logout,
  getSettings,
  updateProfile,
  generatePassword,
  loginBySign,
  checkSign,
  getOrganizationList,
  loginAupd,
  checkLocalAuthApiKey,
};

export default auth;
