import React from 'react';
import { langApi } from '../../../../types/lang';
import RequestStatusName from './column/request-status-name';
import ChildName from './column/child-name';
import RequestNumber from './column/request-number';
import { sliceText } from '../../../../lib/utils';

export const artHouseSport = (allowedEducationTypeId?: number) => {
  return [
    {
      dataIndex: 'requestNumber',
      title: 'Номер',
      render: (item: any) => <RequestNumber id={item.id} requestNumber={item.requestNumber} />,
      width: '108px',
    },
    {
      dataIndex: 'requestSourceName',
      title: 'Источник',
      render: (item: any) => item.requestSourceName,
      width: '100px',
    },
    {
      dataIndex: 'requestDate',
      title: 'Подано',
      render: (item: any) => item.requestDate,
      width: '100px',
    },
    {
      dataIndex: 'childName',
      title: 'ФИО\u00A0ребёнка',
      render: (item: any) => (
        <ChildName
          childName={item.childName}
          hasInContingent={item.hasInContingent}
          childDateOfBirth={item.childDateOfBirth}
        />
      ),
      width: '8%',
    },
    {
      dataIndex: 'serviceName',
      title: langApi.get(allowedEducationTypeId, 'ServiceShortIm'),
      render: (item: any) => <div style={{ minWidth: 276 }}>{sliceText(item.serviceName, 50)}</div>,
    },
    {
      dataIndex: 'organizationName',
      title: langApi.get(allowedEducationTypeId, 'OrganizationIm').replace(' ', '\u00A0'),
      render: (item: any) => item.organizationName,
      width: '300px',
    },
    {
      dataIndex: 'testDate',
      title: <span style={{ whiteSpace: 'nowrap' }}>Дата испыт. / явки</span>,
      render: (item: any) => item.testDate,
      width: '100px',
    },
    {
      dataIndex: 'personName',
      title: 'Преподаватель',
      render: (item: any) => item.personName,
      width: '140px',
    },
    {
      dataIndex: 'requestStatusName',
      title: 'Статус заявления',
      render: (item: any) => (
        <RequestStatusName
          id={item.id}
          documentDate={item.documentDate}
          actionDeadline={item.actionDeadline}
          documentNumber={item.documentNumber}
          enrollmentDate={item.enrollmentDate}
          requestStatusId={item.requestStatusId}
          requestStatusName={item.requestStatusName}
        />
      ),
      width: '259px',
    },
  ];
};
