import React, { useContext } from 'react';
import PageHeading from '../../../../components/header/page-heading';
import { routes } from '../../../../config/constants';
import { generateLink } from '../../../../lib/utils';
import CreateDetails from './create-details';
import history from '../../../../history';
import { CreateServiceContext } from '../../create';

const ServiceCreate = () => {
  const { serviceData } = useContext(CreateServiceContext);
  const cancelHandler = () => {
    const url = serviceData.info.templateId
      ? generateLink(routes.myTemplate, { id: serviceData.info.templateId })
      : generateLink(routes.services, {});
    history.push(url);
  };
  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={cancelHandler}
        title="Новая образовательная услуга"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Образовательные услуги', link: routes.services },
          { title: 'Описание образовательной услуги' },
        ]}
      />
      <CreateDetails cancelHandler={cancelHandler} />
    </>
  );
};

export default ServiceCreate;
