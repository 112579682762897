import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconSearch } from '../../assets/images/header/search.svg';
import { ReactComponent as IconLogout } from '../../assets/images/header/logout.svg';
import { ReactComponent as IconSettings } from '../../assets/images/header/settings.svg';
import { ReactComponent as IconRole } from '../../assets/images/header/role.svg';
import { ReactComponent as IconFlip } from '../../assets/images/header/flip.svg';
import { emojiRegex } from '../../lib/utils/validation';
import { AuthorizationData } from '../../types/authorization-data';
import { redirectToAupdUrl } from '../../lib/utils';
import { logout } from '../../redux/thunks/auth';
import { routes } from '../../config/constants';
import { reducerRecord, setUserProfile } from '../../redux/ducks/user-profile';
import { setAuthorized } from '../../redux/ducks/app';
import tokenManager from '../../lib/token-manager';
import { AppState } from '../../redux/types/state';
import { aupdOrganizationsSelector } from '../../redux/selectors';
import { UserIdEnum } from '../../mock-data/access-enum';

type Props = {
  userProfile: AuthorizationData;
};

const Header = ({ userProfile }: Props) => {
  const { aupdOrganizations } = useSelector((state: AppState) => ({
    aupdOrganizations: aupdOrganizationsSelector(state),
  }));

  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);

  const hideModal = useCallback(
    (ev) => {
      if (showModal && !ev.target.closest(`[data-name="user-modal"]`)) {
        ev.stopPropagation();
        setShowModal(false);
      }
    },
    [showModal]
  );

  useEffect(() => {
    document.addEventListener('click', hideModal, true);
    return () => {
      document.removeEventListener('click', hideModal, true);
    };
  }, [hideModal]);

  const initials = userProfile.userName
    .split(/\s+/)
    .slice(0, 2)
    .map((i) => i[0])
    .join('');

  const dispatch = useDispatch();

  const logoutHandler = async () => {
    await dispatch(logout());
    tokenManager.clearToken();
    dispatch(setUserProfile(reducerRecord));
    dispatch(setAuthorized(false));
    await redirectToAupdUrl();
  };

  const handleChangeSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    if (!emojiRegex.test(value)) {
      setSearch(value);
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <div className="header-search">
            <div className="header-search__icon">
              <IconSearch />
            </div>
            <input
              value={search}
              type="text"
              placeholder="Поиск по системе..."
              className="header-search__input"
              onChange={handleChangeSearch}
            />
          </div>
          <div className="header-controls">
            <div className="header-user">
              <button
                type="button"
                onClick={() => !showModal && setShowModal(!showModal)}
                className="header-user-object"
              >
                {initials}
              </button>

              {showModal && (
                <div data-name="user-modal" className="header-user__modal">
                  <div className="header-user__modal-container">
                    <div className="header-user-media">
                      <div className="header-user-media__object">
                        <div className="header-user-object header-user-object--sm">{initials}</div>
                      </div>
                      <div className="header-user-media__body">
                        <span className="header-user-media__body__name">{userProfile.userName}</span>
                        <span className="header-user-media__body__organization">{userProfile.organizationName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="header-user__modal-hr" />
                  <div className="header-user__modal-container">
                    <div className="header-user__modal-title">Роли пользователя</div>
                    {userProfile.roles.map((role) => (
                      <div key={role.id} className="header-user__modal-link">
                        <span className="header-user__modal-link-icon">
                          <IconRole />
                        </span>
                        {role.name}
                      </div>
                    ))}
                  </div>
                  <div className="header-user__modal-hr" />
                  <div className="header-user__modal-container">
                    {userProfile.userTypeId !== UserIdEnum.Aupd && (
                      <Link to={routes.room} onClick={() => setShowModal(false)} className="header-user__modal-link">
                        <span className="header-user__modal-link-icon">
                          <IconSettings />
                        </span>
                        Управление аккаунтом
                      </Link>
                    )}
                    {userProfile.userTypeId === UserIdEnum.Aupd && aupdOrganizations.length > 1 && (
                      <Link to={routes.loginAupd} className="header-user__modal-link">
                        <span className="header-user__modal-link-icon">
                          <IconFlip />
                        </span>
                        Смена организации
                      </Link>
                    )}
                    <button type="button" onClick={logoutHandler} className="header-user__modal-link">
                      <span className="header-user__modal-link-icon">
                        <IconLogout />
                      </span>
                      {userProfile.userTypeId === UserIdEnum.Aupd ? 'Выход из МЭШ.Кружки' : 'Выход'}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
