import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';

type Props = {
  title: string;
};

const FieldStatus: React.FC<Props> = ({ title }) => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const { statusesData, type, isAdmin, educationSumm } = React.useContext(ServiceRegistryContext);

  const [selectEducationType, setSelectEducationType] = useState<SelectOptionType[]>([]);

  // При изменении типа образования в селекте, получаем новый список статусов
  useEffect(() => {
    if (type === RegistryTypeEnum.serviceChildType && isAdmin) {
      const fetchStatuses = async () => {
        const edType = !values.educationTypeId ? educationSumm : values.educationTypeId;
        const statusesData = await dictionariesApi.getStatuses(edType);
        setSelectEducationType([{ label: 'Актуальные', value: 0 }, ...statusesData]);
      };

      fetchStatuses();
    }
  }, [values.educationTypeId, isAdmin, type, educationSumm]);

  return (
    <FormikFormGroup name="statusId" label={title}>
      <FormikSelect
        name="statusId"
        size="small"
        options={selectEducationType?.length ? selectEducationType : statusesData}
        isSearchable={false}
        defaultValue={
          values.statusId
            ? { label: values.statusName || '', value: values.statusId }
            : { label: 'Актуальные', value: 0 }
        }
        placeholder="Выберите..."
        hideClearIndicator
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('statusName', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};

export default FieldStatus;
