import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Infobox, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import FormikToggle from '../../../../components/formik/formik-toggle';
import FormikToggleGroup from '../../../../components/formik/formik-toggle-group';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import lookupApi from '../../../../lib/api/lookup';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { formatDate, formatDateAndTime, formatTime, getDate } from '../../../../lib/utils/date';
import { TypesOfRepetitionEnum } from '../../../../mock-data/type-of-repetition';
import { ScheduleDataEx } from '../tests';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import DateField from '../../components/fields/add-plan/date';
import TimeField from '../../components/fields/add-plan/time';
import ScheduleRepetition from '../components/schedule-repetition';

type Props = {
  editMode: boolean;
  editCallBack: () => void;
  showEditButton: boolean;
  isRetro: boolean;
};

const ScheduleTests: React.FC<Props> = ({ editMode, editCallBack, isRetro, showEditButton }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { values, setFieldValue } = useFormikContext<ScheduleDataEx>();
  const enabledOnlyDraft = values.trainingGroupStatusId === TrainingGroupStatusEnum.Draft;

  useEffect(() => {
    setFieldValue(
      'typesOfRepetitionId',
      !values.repeat
        ? TypesOfRepetitionEnum.NoRepeat
        : values.typesOfRepetitionId === TypesOfRepetitionEnum.NoRepeat
        ? TypesOfRepetitionEnum.RepeatDaily
        : values.typesOfRepetitionId
    );
  }, [values.repeat, setFieldValue, values.typesOfRepetitionId]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => <>Расписание проведения испытаний</>}
        headingControl={() => {
          return !editMode && showEditButton ? (
            <button type="button" onClick={editCallBack} className="icon-group">
              <span className="icon-group__icon">
                <IconEdit />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <div className="container">
          {values.trainingGroupStatusId === TrainingGroupStatusEnum.Signed && values.sign && (
            <Infobox
              fullWidth
              color="success"
              text={`Пользователь: ${values.sign.signer}. Дата: ${formatDateAndTime(values.sign.date)}`}
            />
          )}

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Дата проведения испытаний {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              <div className="flex">
                <div>
                  {editMode ? (
                    <div style={{ width: 180 }}>
                      <DateField name="periodFrom" dependentTimeName="" showErrorImmediately={values.id > 0} />
                    </div>
                  ) : (
                    <div className="flex items-center flex-none table-row-item-height">
                      <Push size={8} orientation="horizontal" />
                      {values.periodFrom ? formatDate(values.periodFrom) : '—'}
                      <Push size={8} orientation="horizontal" />
                    </div>
                  )}
                </div>
                <Push size={16} orientation="horizontal" />
                {!isRetro && (
                  <FormikToggleGroup name="repeat" label="Повторять" size="xsmall">
                    <FormikToggle name="repeat" size="xsmall" disabled={!editMode || !enabledOnlyDraft} />
                  </FormikToggleGroup>
                )}
                {values.repeat && (
                  <>
                    {(editMode || values.endDate) && (
                      <>
                        <Push size={16} orientation="horizontal" />
                        <FormikToggleGroup
                          name="endDate"
                          label={editMode ? 'Дата окончания' : 'Окончить повтор'}
                          size="xsmall"
                        >
                          <FormikToggle name="endDate" size="xsmall" disabled={!editMode || !enabledOnlyDraft} />
                        </FormikToggleGroup>
                      </>
                    )}

                    {values.endDate && (
                      <>
                        <Push size={16} orientation="horizontal" />
                        {editMode ? (
                          <div style={{ width: 180 }}>
                            <FormikFormGroup name="periodTo" label="">
                              <FormikDatePicker
                                key={values.periodTo}
                                placeholder="ДД.ММ.ГГГГ"
                                size="small"
                                name="periodTo"
                                startDate={values.periodTo ? getDate(values.periodTo) : undefined}
                                disabled={!enabledOnlyDraft}
                              />
                            </FormikFormGroup>
                          </div>
                        ) : (
                          <div className="flex items-center flex-none table-row-item-height">
                            <Push size={8} orientation="horizontal" />
                            {values.periodTo ? formatDate(values.periodTo) : '—'}
                            <Push size={8} orientation="horizontal" />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <ScheduleRepetition editMode={editMode} enabled={enabledOnlyDraft} />

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Время проведения испытаний {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <div className="flex">
                  <div style={{ width: 114 }} className="flex-none">
                    <TimeField name="timeStart" dependentDateName="" />
                  </div>
                  <div className="flex items-center flex-none table-row-item-height">
                    <Push size={8} orientation="horizontal" />
                    —
                    <Push size={8} orientation="horizontal" />
                  </div>
                  <div style={{ width: 114 }} className="flex-none">
                    <TimeField name="timeEnd" dependentDateName="" />
                  </div>
                </div>
              ) : (
                `${formatTime(values.timeStart)} — ${formatTime(values.timeEnd)}`
              )}
            </div>
          </div>

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Размер группы на испытания {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <div style={{ width: 90 }}>
                  <FormikFormGroup required label="" name="volume">
                    <FormikInput name="volume" size="small" number placeholder="0" />
                  </FormikFormGroup>
                </div>
              ) : (
                values.volume || '—'
              )}
            </div>
          </div>

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Преподаватель</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikFormGroup name="teacherId" label="" required>
                  <FormikSelect
                    name="teacher"
                    size="small"
                    isSearchable
                    options={[]}
                    placeholder="Начните вводить..."
                    loadOptions={async (query) => await lookupApi.getTeachers(query, userProfile.organizationId)}
                    defaultValue={values?.teacher ? { value: values.teacherId, label: values?.teacher } : null}
                    selectedValue={(option: any) => {
                      setFieldValue('teacherId', option?.value || undefined);
                      setFieldValue('teacher', option?.label || undefined);
                    }}
                    disabled={!enabledOnlyDraft}
                  />
                </FormikFormGroup>
              ) : (
                values.teacher || '—'
              )}
            </div>
          </div>

          {!isRetro && (
            <>
              <div className="table-data__item table-data__group">
                <div className="table-data__label table-data__label--main">
                  <div>Начало приема заявлений на Mos.ru {editMode && <span className="table-data__required" />}</div>
                </div>
                <div className="table-data__body">
                  <div className="table-data-grid-2">
                    <div className="table-data__group">
                      <div className="table-data__label">Дата</div>
                      <div className="table-data__body">
                        {editMode ? (
                          <DateField
                            name="requestStart"
                            dependentTimeName="requestTimeStart"
                            showErrorImmediately={values.id > 0}
                          />
                        ) : values.requestStart ? (
                          formatDate(values.requestStart)
                        ) : (
                          '—'
                        )}
                      </div>
                    </div>
                    <div className="table-data__group">
                      <div className="table-data__label">Время</div>
                      <div className="table-data__body">
                        <div style={{ width: 114 }} className="flex-none">
                          {editMode ? (
                            <TimeField name="requestTimeStart" dependentDateName="requestStart" />
                          ) : (
                            values.requestTimeStart || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-data__item table-data__group">
                <div className="table-data__label table-data__label--main">
                  <div>
                    Окончание приема заявлений на Mos.ru {editMode && <span className="table-data__required" />}
                  </div>
                </div>
                <div className="table-data__body">
                  <div className="table-data-grid-2">
                    <div className="table-data__group">
                      <div className="table-data__label">Дата</div>
                      <div className="table-data__body">
                        {editMode ? (
                          <DateField
                            name="requestEnd"
                            dependentTimeName="requestTimeEnd"
                            showErrorImmediately={values.id > 0}
                          />
                        ) : values.requestEnd ? (
                          formatDate(values.requestEnd)
                        ) : (
                          '—'
                        )}
                      </div>
                    </div>
                    <div className="table-data__group">
                      <div className="table-data__label">Время</div>
                      <div className="table-data__body">
                        <div style={{ width: 114 }} className="flex-none">
                          {editMode ? (
                            <TimeField name="requestTimeEnd" dependentDateName="requestEnd" />
                          ) : (
                            values.requestTimeEnd || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">Комментарий</div>
            <div className="table-data__body">
              {editMode ? (
                <FormikFormGroup name="comment" label="" horizontalLabelStart required>
                  <FormikTextarea name="comment" rows={2} placeholder="Введите..." disabled={!enabledOnlyDraft} />
                </FormikFormGroup>
              ) : (
                values.comment || '—'
              )}
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default ScheduleTests;
