import { Link } from 'react-router-dom';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { routes } from '../../../../config/constants';
import { generateLink } from '../../../../lib/utils';
import { DuplicateData } from '../../../../types/learners';

type Props = {
  show: boolean;
  duplicates: DuplicateData[];
  onCloseHandler: () => void;
};

const DuplicateModal = ({ show, duplicates, onCloseHandler }: Props) => {
  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        alert
        onClose={onCloseHandler}
        modalTitle="Такой обучающийся уже существует"
        renderComponent={() => (
          <>
            <div>С введенными данными найден обучающийся:</div>
            {duplicates.map((item) => {
              return (
                <div>
                  <Push orientation="vertical" size={8} />
                  <Link
                    to={generateLink(routes.learner, { id: item.Id })}
                    target="_blank"
                    className="brand-link font-size-small"
                  >
                    {item.FullName}
                  </Link>
                </div>
              );
            })}
            <Push orientation="vertical" size={32} />
            <div>Если необходимо связать сведения обучающихся, воспользуйтесь механизмом объединения личных дел.</div>
          </>
        )}
        controls={() => <Button primary label="Закрыть" size="small" onClick={onCloseHandler} />}
      />
    </Modal>
  );
};

export default DuplicateModal;
