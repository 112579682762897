import { notify } from '@mosru/esz_uikit';
import { Discipline } from '../../../types/discipline';

export const transformData = (values: Discipline[] | undefined) => {
  let count = 1;

  if (!values) {
    return [];
  }

  return values
    .map((item: Discipline) => {
      return {
        id: item.id,
        count: count++,
        name: item.name,
      };
    })
    .sort((a, b) => a.count - b.count);
};

export const teacherError = {
  name: 'В блоке "Персональные данные" не заполнены обязательные поля.',
};

export const isError = (filter: () => void) => {
  notify.danger({
    data: {
      label: teacherError.name,
      icon: true,
    },
  });
  return filter();
};
