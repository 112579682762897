import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { useGetDataEducationTypes } from '../../../hooks/get-education-types';
import { accessAction, accessObject } from '../../../types/authorization-data';
import { EducationTypeEnum } from '../../../types/education-type';
import { RegistryTypeEnum } from '../../../mock-data/registry-type-enum';
import { InitialData } from './initial-data';
import { hasGeneralAccess } from '../../../lib/utils';
import { generalAccess } from '../../../mock-data/access-enum';

type CurrentEducationTypeDataType = {
  [index: string]: SelectOptionType[];
};

export const ServiceRegistry = (type: RegistryTypeEnum) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const educationTypeData = useGetDataEducationTypes(userProfile, accessObject.Services, accessAction.ViewRegistry);

  // Фильтр только для "Реестр детских объединений"
  const serviceChildData: SelectOptionType[] = educationTypeData?.filter(
    (item) =>
      ![
        EducationTypeEnum.ProfessionalEducation,
        EducationTypeEnum.SportEducation,
        EducationTypeEnum.ArtHouseEducation,
      ].includes(item.value as number)
  );

  // Фильтр только для "Реестр образовательных услуг"
  const serviceEducationData: SelectOptionType[] = educationTypeData?.filter((item) =>
    Boolean([EducationTypeEnum.SportEducation, EducationTypeEnum.ArtHouseEducation].includes(item.value as number))
  );

  // Фильтр только для "Реестр образовательных программ"
  const serviceEducationProgramType: SelectOptionType[] = educationTypeData?.filter((item) =>
    Boolean([EducationTypeEnum.ProfessionalEducation].includes(item.value as number))
  );

  // Установка "Типа образования" в зависимости от нужных проверок.
  const currentEducationTypeData: CurrentEducationTypeDataType = useMemo(
    () => ({
      [RegistryTypeEnum.serviceChildType]: serviceChildData,
      [RegistryTypeEnum.serviceEducationProgramType]: serviceEducationProgramType,
      [RegistryTypeEnum.serviceGpdType]: educationTypeData,
      [RegistryTypeEnum.serviceTemplateType]: isAdmin
        ? [{ label: 'Все', value: null }, ...serviceEducationData]
        : educationTypeData,
      [RegistryTypeEnum.serviceEducationType]: isAdmin
        ? [{ label: 'Все', value: null }, ...serviceEducationData]
        : educationTypeData,
    }),
    [serviceChildData, serviceEducationProgramType, educationTypeData, serviceEducationData, isAdmin]
  );

  const summ = currentEducationTypeData[type]?.reduce((acc, item) => {
    if (item) {
      acc += item.value as number;
    }
    return acc;
  }, 0);

  return (
    <>
      {!!educationTypeData.length && (
        <InitialData
          type={type}
          isAdmin={isAdmin}
          educationSumm={summ}
          userProfile={userProfile}
          educationTypeData={currentEducationTypeData[type]}
        />
      )}
    </>
  );
};
