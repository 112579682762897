import React, { useEffect, useState } from 'react';
import { Checkbox, Radio, Tooltip, Loader, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { PlaceServiceData } from '../../../../../types/service';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { EducationTypeEnum } from '../../../../../types/education-type';

type Props = {
  setPlaceServiceData: React.Dispatch<React.SetStateAction<PlaceServiceData[]>>;
  placeServiceData: PlaceServiceData[];
  editCount: 'one' | 'many';
  serviceId?: number;
  organizationId?: number;
  educationType: EducationTypeEnum;
};

const EditAddressClass: React.FC<Props> = ({
  placeServiceData,
  setPlaceServiceData,
  editCount,
  serviceId,
  organizationId,
  educationType,
}) => {
  const canChooseSeveral = editCount === 'many';
  const [loading, setLoading] = useState(false);

  const hasAddressArchive = placeServiceData.some(({ isAddressArchive }) => isAddressArchive);

  useEffect(() => {
    const fetchPlaceService = async () => {
      setLoading(true);
      try {
        if (serviceId) {
          const result = await serviceTemplateApi.getPlaceServices({ serviceId, withOrgServicePlaces: true });
          setPlaceServiceData(result);
        } else if (organizationId) {
          const result = await dictionariesApi.getPlaceServiceList(organizationId);
          setPlaceServiceData(
            result.map(
              (item) =>
                ({
                  id: item.id,
                  addressId: item.addressId,
                  fullAddress: item.fullAddress,
                  name: item.name,
                  isSelected: false,
                } as PlaceServiceData)
            )
          );
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPlaceService();
  }, [serviceId, setPlaceServiceData, organizationId]);

  const handleChangeAllPlaces = () => {
    const anySelected = placeServiceData.some(({ isSelected }) => isSelected);

    setPlaceServiceData(
      placeServiceData.map((place) => ({
        ...place,
        isSelected: !anySelected,
      }))
    );
  };

  const handleChangePlaceList = (id: number) => () => {
    setPlaceServiceData(
      placeServiceData.map((place) =>
        canChooseSeveral
          ? place.id === id
            ? { ...place, isSelected: !place.isSelected }
            : place
          : { ...place, isSelected: place.id === id }
      )
    );
  };

  const colums = [
    {
      dataIndex: '',
      title: canChooseSeveral && (
        <Checkbox
          labelId=""
          checked={!!placeServiceData.length && placeServiceData.every(({ isSelected }) => isSelected)}
          indeterminate={
            placeServiceData.some(({ isSelected }) => isSelected) &&
            !placeServiceData.every(({ isSelected }) => isSelected)
          }
          onChange={handleChangeAllPlaces}
        />
      ),
      render: (item: any) =>
        canChooseSeveral ? (
          <Checkbox labelId={item.id} onChange={handleChangePlaceList(item.id)} checked={item.isSelected} />
        ) : (
          <Radio
            name="address"
            labelId={item.number}
            onChange={handleChangePlaceList(item.id)}
            checked={item.isSelected}
          />
        ),
      width: '60px',
    },
    {
      dataIndex: 'number',
      title: '№',
      render: (item: any) => item.number,
      width: '40px',
    },
    {
      dataIndex: 'address',
      title: 'Адрес',
      render: (item: any) => item.fullAddress,
    },
  ];

  if (hasAddressArchive) {
    colums.splice(2, 0, {
      dataIndex: 'isAddressArchive',
      title: '',
      width: '1%',
      render: (item: any) =>
        item.isAddressArchive && (
          <Tooltip
            component={() => <IconInfo />}
            position="bottom"
            text={
              <div className="text-center" style={{ maxWidth: '203px' }}>
                Адрес более не используется организацией в качестве места предоставления услуги
              </div>
            }
          />
        ),
    });
  }

  if ([EducationTypeEnum.SportEducation, EducationTypeEnum.ArtHouseEducation].includes(educationType)) {
    colums.splice(hasAddressArchive ? 3 : 2, 0, {
      dataIndex: 'number',
      title: 'Наименование организации',
      render: (item: any) => item.name,
      width: '45%',
    });
  }

  return loading ? (
    <div className="flex justify-center flex-column">
      <Push orientation="vertical" size={20} />
      <Loader roller small />
      <Push orientation="vertical" size={20} />
    </div>
  ) : (
    <Table
      data={placeServiceData.map((item, index) => ({ ...item, number: index + 1 }))}
      hideSort
      pageSize={Number.MAX_SAFE_INTEGER}
      overflow
      loading
      columns={colums}
    />
  );
};

export default EditAddressClass;
