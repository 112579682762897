import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, TableColumnType } from '@mosru/esz_uikit';
import { formatDate, formatTime } from '../../../../../lib/utils/date';
import { EducationTypeEnum } from '../../../../../types/education-type';
import ControlPanel from './control-panel';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';
import { routes } from '../../../../../config/constants';
import { generateUrlSearchString } from '../../../../../lib/utils';
import { RequestStatusEnum } from '../../../../../mock-data/request-status-enum';
import { ServiceContext } from '../../..';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';
import { TrainingGroupData } from '../../../../../types/service';

export const useGetColumns = (
  educationType: EducationTypeEnum,
  editPlan: (id: number) => void,
  setIdRemoveModal: (id: number) => void
) => {
  const [columns, setColumns] = useState<any>([]);
  const { serviceData } = useContext(ServiceContext);
  const udodAccessDenied = useMemo(
    () =>
      serviceData.isUdod &&
      serviceData?.financing.typeFinancingId === TypeFinancingEnum.Free &&
      serviceData.educationTypeId === EducationTypeEnum.ChildrenEducation,
    [serviceData]
  );

  const isVirtualAssistantEducation = educationType === EducationTypeEnum.VirtualAssistantEducation;

  const noReceptionTooltipText = useMemo(
    () => (
      <div className="text-center">
        {educationType === EducationTypeEnum.VirtualAssistantEducation
          ? 'Подача заявления в системе АИС "Зачисление в УДО" не предусмотрена. Для создания заявления обратитесь в электронный журнал'
          : udodAccessDenied
          ? 'Для организаций дополнительного образования подача заявления в бюджетные ДО доступна только через MOS.ru'
          : `Для подачи заявлений необходимо, ${
              educationType === EducationTypeEnum.ChildrenEducation ||
              educationType === EducationTypeEnum.ChildrenNonDogmEducation
                ? 'чтобы данное ДО было'
                : 'чтобы данная услуга была'
            } в статусе “Опубликовано на Mos.ru”, количество зачисленных заявлений не превышало количество мест и дата приема заявлений на Mos.ru была не позже сегодняшней даты и текущего времени.`}
      </div>
    ),
    [educationType, udodAccessDenied]
  );

  const ReceptionTooltipText = useMemo(
    () =>
      isVirtualAssistantEducation || udodAccessDenied ? (
        <div className="icon-group">
          <div className="icon-group__text">Идет прием</div>
          <div className="icon-group__icon">
            <Tooltip component={() => <IconInfo />} position="bottom" text={noReceptionTooltipText} />
          </div>
        </div>
      ) : (
        'Идет прием'
      ),
    [isVirtualAssistantEducation, noReceptionTooltipText, udodAccessDenied]
  );

  const stagesToolTip = useCallback((item: TrainingGroupData) => {
    return (
      <>
        {item.stageList.map((e) => {
          return (
            <>
              {formatDate(e.dateStart)}–{formatDate(e.dateEnd)}
              <br />
            </>
          );
        })}
      </>
    );
  }, []);

  const volumeText = useCallback(
    (volume: number | string) => {
      if (educationType === EducationTypeEnum.DayCareCentersEducation) {
        return (
          <div className="icon-group">
            <div className="icon-group__text">{volume}</div>
            <div className="icon-group__icon">
              <Tooltip
                component={() => <IconInfo />}
                position="bottom"
                text={
                  <div className="text-center">
                    Данный параметр рассчитывается автоматически и является суммой значений предельного кол-ва человек в
                    связанных группах обучения.
                  </div>
                }
              />
            </div>
          </div>
        );
      } else {
        return volume;
      }
    },
    [educationType]
  );

  useEffect(() => {
    const updatedColumns: TableColumnType[] = [
      {
        dataIndex: 'date',
        title: 'Дата начала занятий/Учебный период',
        render: (item: any) => {
          return (
            <Tooltip
              component={() => <span className="color-primary">{formatDate(item.educationStartDate)}</span>}
              text={stagesToolTip(item)}
              position="right"
            />
          );
        },
        width: '15%',
      },
    ];

    if (educationType === EducationTypeEnum.ProfessionalEducation) {
      updatedColumns.unshift({
        dataIndex: 'yearOfTrainingName',
        title: 'Учебный год',
        render: (item: any) => item.yearOfTrainingName?.replace(/[^\d-]/g, ''),
        width: '15%',
      });
    }

    if (
      [
        EducationTypeEnum.VirtualAssistantEducation,
        EducationTypeEnum.ChildrenEducation,
        EducationTypeEnum.ChildrenNonDogmEducation,
      ].includes(educationType)
    ) {
      updatedColumns.push({
        dataIndex: 'period',
        title: (
          <div className="icon-group">
            <div className="icon-group__text">Учебный период, на который ведется прием заявлений</div>
            <div className="icon-group__icon">
              <Tooltip
                component={() => <IconInfo />}
                position="bottom"
                text={
                  <div className="text-center font-weight-base">
                    Выберите учебный период, на который Вы хотите открыть план приема. Дети, зачисленные по заявлениям
                    данного плана приема, начнут обучение с указанного здесь периода.
                  </div>
                }
              />
            </div>
          </div>
        ),
        render: (item: any) => <div className="overflow-wrap-anywhere">{item.serviceStageName}</div>,
        width: '12%',
      });
    }

    updatedColumns.push(
      {
        dataIndex: 'volume',
        title: 'Количество мест',
        render: (item: any) => <>{volumeText(item.volume ?? 'Без ограничений')}</>,
        width: '13%',
      },
      {
        dataIndex: 'amount',
        title: `Всего / ${
          isVirtualAssistantEducation ? 'на рассмотрении' : 'активные'
        } / зачислено / отчислено / отказано / отозвано`,
        render: (item: any) => {
          return (
            !!item?.scheduleList?.length && (
              <>
                {item.scheduleList[0].requestTotalCount && (
                  <Link
                    to={{
                      pathname: routes.requests,
                      search: generateUrlSearchString({
                        serviceId: item.serviceId,
                        trainingGroupId: item.id,
                        educationTypeId: educationType,
                      }),
                    }}
                    target="_blank"
                    className="brand-link"
                  >
                    {item.scheduleList[0].requestTotalCount}
                  </Link>
                )}
                /
                {item.scheduleList[0].requestsActiveCount && (
                  <Link
                    to={{
                      pathname: routes.requests,
                      search: generateUrlSearchString({
                        serviceId: item.serviceId,
                        trainingGroupId: item.id,
                        requestStatusId: isVirtualAssistantEducation
                          ? RequestStatusEnum.UnderConsideration
                          : RequestStatusEnum.Active,
                        educationTypeId: educationType,
                      }),
                    }}
                    target="_blank"
                    className="brand-link"
                  >
                    {item.scheduleList[0].requestsActiveCount}
                  </Link>
                )}
                /
                {item.scheduleList[0].requestIncludedCount && (
                  <Link
                    to={{
                      pathname: routes.requests,
                      search: generateUrlSearchString({
                        serviceId: item.serviceId,
                        trainingGroupId: item.id,
                        requestStatusId: RequestStatusEnum.Included,
                        educationTypeId: educationType,
                      }),
                    }}
                    target="_blank"
                    className="brand-link"
                  >
                    {item.scheduleList[0].requestIncludedCount}
                  </Link>
                )}
                /
                {item.scheduleList[0].requestExcludedCount && (
                  <Link
                    to={{
                      pathname: routes.requests,
                      search: generateUrlSearchString({
                        serviceId: item.serviceId,
                        trainingGroupId: item.id,
                        requestStatusId: RequestStatusEnum.IncludedExcluded,
                        educationTypeId: educationType,
                      }),
                    }}
                    target="_blank"
                    className="brand-link"
                  >
                    {item.scheduleList[0].requestExcludedCount}
                  </Link>
                )}
                /
                {item.scheduleList[0].requestDeclineCount && (
                  <Link
                    to={{
                      pathname: routes.requests,
                      search: generateUrlSearchString({
                        serviceId: item.serviceId,
                        trainingGroupId: item.id,
                        requestStatusId: RequestStatusEnum.IncludeDeclined,
                        educationTypeId: educationType,
                      }),
                    }}
                    target="_blank"
                    className="brand-link"
                  >
                    {item.scheduleList[0].requestDeclineCount}
                  </Link>
                )}
                /
                {item.scheduleList[0].requestRefusedCount && (
                  <Link
                    to={{
                      pathname: routes.requests,
                      search: generateUrlSearchString({
                        serviceId: item.serviceId,
                        trainingGroupId: item.id,
                        requestStatusId: RequestStatusEnum.ApplicantRefused,
                        educationTypeId: educationType,
                      }),
                    }}
                    target="_blank"
                    className="brand-link"
                  >
                    {item.scheduleList[0].requestRefusedCount}
                  </Link>
                )}
              </>
            )
          );
        },
        width: '15%',
      }
    );

    if (educationType !== EducationTypeEnum.DayCareCentersEducation) {
      updatedColumns.push({
        dataIndex: 'teacher',
        title: 'Преподаватель',
        render: (item: any) => item.teacher,
        width: '13%',
      });
    }

    updatedColumns.push(
      {
        dataIndex: 'periodMosru',
        title: !isVirtualAssistantEducation ? 'Период приема заявлений на mos.ru' : 'Период приема заявлений',
        render: (item: any) => {
          return (
            <span>
              {`c ${formatDate(item.scheduleList[0]?.requestStart || '')} ${formatTime(
                item.scheduleList[0]?.requestTimeStart || ''
              )} по ${formatDate(item.scheduleList[0]?.requestEnd || '')} ${formatTime(
                item.scheduleList[0]?.requestTimeEnd || ''
              )}`}
            </span>
          );
        },
        width: educationType === EducationTypeEnum.DayCareCentersEducation ? '30%' : '10%',
      },
      {
        dataIndex: 'status',
        title: 'Прием заявлений',
        render: (item: any) => {
          return item.scheduleList[0]?.isAccessible ? (
            ReceptionTooltipText
          ) : (
            <div className="icon-group">
              <div className="icon-group__text">Приема нет</div>
              <div className="icon-group__icon">
                <Tooltip component={() => <IconInfo />} position="bottom" text={noReceptionTooltipText} />
              </div>
            </div>
          );
        },

        width: '13%',
      },
      {
        dataIndex: '',
        title: '',
        render: (item: any) => (
          <ControlPanel
            itemData={item}
            editPlan={editPlan}
            setIdRemoveModal={setIdRemoveModal}
            udodAccessDenied={udodAccessDenied}
          />
        ),
        width: '40px',
      }
    );

    setColumns(updatedColumns);
  }, [
    educationType,
    editPlan,
    setIdRemoveModal,
    noReceptionTooltipText,
    ReceptionTooltipText,
    volumeText,
    udodAccessDenied,
    stagesToolTip,
    isVirtualAssistantEducation,
  ]);

  return columns;
};
