import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import useQuery from '../../../../hooks/use-query';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { SearchRequestsInitialFormData } from '../../../../types/requests';

export type Props = { submitForm: (values: SearchRequestsInitialFormData) => void };

const InitFilters: React.FC<Props> = ({ submitForm }) => {
  const { setFieldValue, values } = useFormikContext<SearchRequestsInitialFormData>();
  const [needSubmit, setNeedSubmit] = useState(false);
  const serviceId = useQuery().get('serviceId');
  const trainingGroupId = useQuery().get('trainingGroupId');
  const requestStatusId = useQuery().get('requestStatusId');
  const educationTypeId = useQuery().get('educationTypeId');
  const scheduleId = useQuery().get('scheduleId');

  useEffect(() => {
    const fetch = async (id: string) => {
      const service = await serviceTemplateApi.getServiceName(id);
      setFieldValue('serviceName', service);
    };
    if (serviceId && !values.serviceId && !values.serviceName) {
      fetch(serviceId);

      setFieldValue('serviceId', parseInt(serviceId));

      if (trainingGroupId) {
        setFieldValue('trainingGroupId', parseInt(trainingGroupId));
      }
      if (requestStatusId) {
        setFieldValue('requestStatusId', parseInt(requestStatusId));
      }
      if (educationTypeId) {
        setFieldValue('educationTypeId', parseInt(educationTypeId));
      }
      if (scheduleId) {
        setFieldValue('scheduleId', parseInt(scheduleId));
      }
      setFieldValue('isActualRequestIndex', false);
      setNeedSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, trainingGroupId, requestStatusId]);

  useEffect(() => {
    if (needSubmit) {
      submitForm(values);
      setNeedSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needSubmit]);

  return null;
};
export default InitFilters;
