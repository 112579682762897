import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';

const FieldDepartment: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const { vedomstvoData, isAdmin } = React.useContext(ServiceRegistryContext);

  const defaultValue = isAdmin
    ? values.vedomstvoId
      ? { label: values.vedomstvoName || '', value: values.vedomstvoId }
      : { label: 'Все', value: null }
    : { label: vedomstvoData[0]?.label, value: vedomstvoData[0]?.value };

  return (
    <FormikFormGroup name="vedomstvoId" label="Департамент">
      <FormikSelect
        name="vedomstvoId"
        size="small"
        options={vedomstvoData}
        disabled={!isAdmin}
        defaultValue={defaultValue}
        placeholder="Выберите..."
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('vedomstvoName', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};

export default FieldDepartment;
