import React, { useMemo, useState } from 'react';
import { Panel, Switcher, Push } from '@mosru/esz_uikit';
import ActionsFormEnrollment from './actions-form-enrollment';
import ActionsFormRefusal from './actions-form-refusal';
import ActionsFormEnrolled from './actions-form-enrolled';
import { RequestDeclineData, RequestEnrollmentData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';

type Props = {
  setEditModeParent: (value: string | null) => void;
  enrollment: RequestEnrollmentData | undefined;
  decline: RequestDeclineData;
  requestStatusId: RequestStatusEnum;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
  showEditBtn?: (type: string) => boolean;
  changeStatusRequest?: () => void;
  contingentGuid: string;
  serviceClassId: number;
};

const ActionsForm: React.FC<Props> = ({
  setEditModeParent,
  enrollment,
  requestStatusId,
  serviceId,
  decline,
  requestId,
  updateRequest,
  showEditBtn,
  changeStatusRequest,
  contingentGuid,
  serviceClassId,
}) => {
  const [editMode, setEditMode] = useState(false);

  const showDecline = useMemo(
    () =>
      requestStatusId !== RequestStatusEnum.Included &&
      requestStatusId !== RequestStatusEnum.Archive &&
      requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestStatusId !== RequestStatusEnum.New &&
      requestStatusId !== RequestStatusEnum.IncludedExcluded,
    [requestStatusId]
  );

  const showEnrollment = useMemo(
    () =>
      requestStatusId !== RequestStatusEnum.IncludeDeclined &&
      requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestStatusId !== RequestStatusEnum.WaitForElectronicAccept &&
      requestStatusId !== RequestStatusEnum.New &&
      requestStatusId !== RequestStatusEnum.Included &&
      requestStatusId !== RequestStatusEnum.Archive &&
      requestStatusId !== RequestStatusEnum.UnderConsideration &&
      requestStatusId !== RequestStatusEnum.IncludedExcluded,
    [requestStatusId]
  );
  const [refusal, setRefusal] = useState(!showEnrollment);

  return (
    <>
      <Push size={12} />
      {showDecline || showEnrollment ? (
        <Panel
          title={() => (showDecline && !showEnrollment ? 'Отказ в зачислении' : 'Действия по заявлению')}
          headingControl={() => (
            <>
              {showDecline && showEnrollment && (
                <Switcher size="small">
                  <button
                    type="button"
                    onClick={() => {
                      setRefusal(false);
                    }}
                    className={refusal ? '' : 'active'}
                  >
                    Зачисление
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setRefusal(true);
                    }}
                    className={refusal ? 'active' : ''}
                  >
                    Отказ в зачислении
                  </button>
                </Switcher>
              )}
              {!editMode && showEditBtn && showEditBtn('actions') && (
                <>
                  <Push size={12} orientation="horizontal" />
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('actions');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                </>
              )}
            </>
          )}
        >
          {refusal ? (
            <ActionsFormRefusal
              decline={decline}
              requestId={requestId}
              serviceId={serviceId}
              editMode={editMode}
              setEditMode={() => {
                setEditModeParent('');
                setEditMode(false);
              }}
              updateRequest={updateRequest}
              changeStatusRequest={changeStatusRequest}
              requestStatusId={requestStatusId}
            />
          ) : (
            <ActionsFormEnrollment
              editMode={editMode}
              setEditMode={() => {
                setEditModeParent('');
                setEditMode(false);
              }}
              enrollment={enrollment ?? ({} as RequestEnrollmentData)}
              requestId={requestId}
              updateRequest={updateRequest}
              changeStatusRequest={changeStatusRequest}
              requestStatusId={requestStatusId}
              contingentGuid={contingentGuid}
              serviceClassId={serviceClassId}
            />
          )}
        </Panel>
      ) : (
        requestStatusId !== RequestStatusEnum.ApplicantRefused && (
          <Panel
            title={() =>
              requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded
                ? 'Зачисление'
                : 'Действия по заявлению'
            }
          >
            <ActionsFormEnrolled enrollment={enrollment} />
          </Panel>
        )
      )}
    </>
  );
};

export default ActionsForm;
