import React from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { NumberSeatsAdmissionPlan } from '../../../../types/service';

type NumberSeats = (NumberSeatsAdmissionPlan & { numberSeatsLeft: number }) | null;

type Props = {
  id: number;
  show: boolean;
  onClose: () => void;
  handleCopyProgram: () => void;
  numberSeats: NumberSeats[];
};

export const CopyProgramModal = ({ show, onClose, numberSeats, handleCopyProgram }: Props) => {
  const handleClick = () => {
    handleCopyProgram();
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <ModalPanel
        alert
        modalTitle="Создание копии программы"
        onClose={onClose}
        renderComponent={() => (
          <>
            Вы собираетесь создать копию текущей программы со следующими планами приема:
            <Push orientation="vertical" size={16} />
            {numberSeats.map((item, index) => {
              if (item?.yearOfTrainingName) {
                return (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <div key={index}>
                    {`${item.yearOfTrainingName} учебный год с количеством мест ${item.volume || 0} из ${
                      item.numberSeatsLeft
                    } оставшихся${numberSeats.length - 1 === index || numberSeats.length === 1 ? '.' : ';'}`}
                  </div>
                );
              }
              return null;
            })}
          </>
        )}
        controls={() => (
          <>
            <Button border primary label="Отмена" onClick={onClose} />
            <Push orientation="horizontal" size={12} />
            <Button primary label="Да, продолжить" handleClick={handleClick} />
          </>
        )}
      />
    </Modal>
  );
};
