import { NavLink, useLocation } from 'react-router-dom';
import React from 'react';
import { Switcher } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';

const SwitcherForm = () => {
  const location = useLocation();
  return (
    <Switcher>
      <NavLink activeClassName="active" to={`${routes.login}${location.search}`}>
        Логин и пароль
      </NavLink>
      <NavLink activeClassName="active" to={`${routes.sign}${location.search}`}>
        Электронная подпись
      </NavLink>
    </Switcher>
  );
};

export default SwitcherForm;
