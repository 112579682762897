import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Loader, Push } from '@mosru/esz_uikit';
import { useChangeAvdStateMutation, useGetAdvertisementsQuery } from '../../store/static-content';
import Notification from './notification';
import { ReactComponent as IconDown } from '../../assets/images/icons/down-black.svg';

const defaultVisibleCount = 3;

const PanelNotifications = () => {
  const [open, setOpen] = useState(false);
  const [countNew, setCountNew] = useState<number>(0);

  const { data: advertisements = [], isLoading } = useGetAdvertisementsQuery();
  const [changeAvdState] = useChangeAvdStateMutation();

  useEffect(() => {
    setCountNew(advertisements.filter((a) => !a.isRead).length);
  }, [advertisements]);

  const changeState = useCallback(
    async (id) => {
      setCountNew((prevState) => prevState - 1);
      await changeAvdState(id);
    },
    [changeAvdState]
  );

  return (
    <div className="container">
      <div className="desktop-notifications__title">
        Объявления
        {countNew > 0 && (
          <span className="color-gray-dark font-size-base">
            {' \u00A0'} +{countNew} {countNew > 1 ? 'новых' : 'новое'}
          </span>
        )}{' '}
      </div>
      {isLoading ? (
        <div className="flex justify-center flex-column">
          <Loader small />
        </div>
      ) : advertisements.length ? (
        advertisements.map(
          (item, i) =>
            (open || i < defaultVisibleCount) && (
              <Fragment key={`notify_${item.id}`}>
                <Notification adv={item} changeState={changeState} />
                <Push size={8} />
              </Fragment>
            )
        )
      ) : (
        <div className="desktop-no-data">Нет данных</div>
      )}
      <Push size={12} />

      {advertisements.length > defaultVisibleCount && (
        <button
          type="button"
          onClick={() => {
            setOpen(!open);
          }}
          className="icon-group"
        >
          <span className="icon-group__text  ">{!open ? 'Показать еще' : 'Скрыть'}</span>
          <span className={open ? 'icon-group__icon--rotate' : ''}>
            <IconDown />
          </span>
        </button>
      )}
    </div>
  );
};

export default PanelNotifications;
