import React, { useCallback, useLayoutEffect } from 'react';
import GlobalHeaderAupd from '@mes-ui/global-header';
import { useSelector } from 'react-redux';
import { routes } from '../../config/constants';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import tokenManager from '../../lib/token-manager';
import { getCookie } from '../../lib/utils';

const GlobalHeader = () => {
  const handleFooBar = useCallback(() => {
    tokenManager.clearToken();
  }, []);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useLayoutEffect(() => {
    const aupdToken = getCookie('aupd_token');
    if (!localStorage.getItem('aupd_token') && aupdToken) {
      localStorage.setItem('aupd_token', `"${aupdToken}"`);
    }
  }, []);

  return userProfile.tokenAupd && window.location.pathname !== routes.loginAupd ? (
    <div className="global-header">
      <GlobalHeaderAupd callBeforeRedirect={handleFooBar} />
    </div>
  ) : null;
};

export default GlobalHeader;
