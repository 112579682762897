import moment from 'moment';
import { AuthorizationData } from '../../types/authorization-data';
import { generateLink, hasAccessObjectAny } from './index';
import { accessAction, accessObject } from '../../mock-data/access-enum';
import { ListMenuAddGroup, SearchGroupTrainingInitialFormData } from '../../types/service-class';
import { EducationTypeEnum } from '../../types/education-type';
import { routes } from '../../config/constants';
import { styleVars } from '../../theme/vars';
import { PeriodType, ScheduleType } from '../../components/schedule/period';
import { scheduleMockData } from './time-table';

// Используется для placeHolder-a  serviceId"
// label="Наименование + 'текст от роли'
export const roleLabel = (userProfile: AuthorizationData): string => {
  // услуги
  const service: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassSport], accessAction.View) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassArtHouse], accessAction.View) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassVA], accessAction.View);

  // детского объединения
  const children: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassNonDOGM], accessAction.View) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassDOGM], accessAction.View);

  // программы по уходу и присмотру за детьми школьного возраста
  const program: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceClassDayCare], accessAction.View);

  // образоват. программы
  const educationProgram: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceClassOP], accessAction.View);

  const name = 'Наименование ';

  const dictionary: { [key: string]: string } = {
    service: 'услуги',
    children: 'детского объединения',
    program: 'программы по уходу и присмотру за детьми школьного возраста',
    educationProgram: 'образовательной программы',
  };

  const serviceName: string = service ? `${dictionary.service} / ` : '';
  const childrenName: string = children ? `${dictionary.children} / ` : '';
  const programName: string = program ? `${dictionary.program} / ` : '';
  const educationProgramName: string = educationProgram ? `${dictionary.educationProgram} / ` : '';

  const result: string = name + serviceName + childrenName + programName + educationProgramName;
  const lastSymbol: boolean = result.endsWith(' / ');

  return lastSymbol ? result.slice(0, -2) : result;
};

export const listMenuAddGroup = (userProfile: AuthorizationData, isAdmin: boolean): ListMenuAddGroup[] => {
  // для детского объединения ДОНМ
  const classDOGM: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassDOGM], accessAction.Create) || isAdmin;

  // для детского объединения
  const classNonDOGM: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassNonDOGM], accessAction.Create) || isAdmin;

  // для спортивной секции
  const classSport = hasAccessObjectAny(userProfile, [accessObject.ServiceClassSport], accessAction.Create) || isAdmin;

  // для дома творчества
  const serviceClassArtHouse: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassArtHouse], accessAction.Create) || isAdmin;

  // для образовательной программы
  const classOP: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassOP], accessAction.Create) || isAdmin;

  // для группы продленного дня
  const serviceClassDayCare: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassDayCare], accessAction.Create) || isAdmin;

  // для цифрового репетитора
  const serviceVa: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Create) || isAdmin;

  const menu: ListMenuAddGroup[] | null = [];

  const listMenu = {
    classDOGM: {
      label: 'для детского объединения ДОНМ',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.ChildrenEducation }),
    },
    classNonDOGM: {
      label: 'для детского объединения',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.ChildrenNonDogmEducation }),
    },
    classSport: {
      label: 'для спортивной секции',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.SportEducation }),
    },
    serviceClassArtHouse: {
      label: 'для дома творчества',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.ArtHouseEducation }),
    },
    classOP: {
      label: 'для образовательной программы',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.ProfessionalEducation }),
    },
    serviceClassDayCare: {
      label: 'для группы продленного дня',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.DayCareCentersEducation }),
    },
    serviceVa: {
      label: 'для цифрового репетитора',
      link: generateLink(routes.createServiceClass, { edType: EducationTypeEnum.VirtualAssistantEducation }),
    },
  };

  if (classDOGM) {
    menu.push(listMenu.classDOGM);
  }
  if (classNonDOGM) {
    menu.push(listMenu.classNonDOGM);
  }
  if (classSport) {
    menu.push(listMenu.classSport);
  }
  if (serviceClassArtHouse) {
    menu.push(listMenu.serviceClassArtHouse);
  }
  if (classOP) {
    menu.push(listMenu.classOP);
  }
  if (serviceClassDayCare) {
    menu.push(listMenu.serviceClassDayCare);
  }

  if (serviceVa) {
    menu.push(listMenu.serviceVa);
  }

  return menu;
};

export const removeEmptyDataForRequest = (requestData: { [key: string]: any }, search: boolean) => {
  for (const key in requestData) {
    if (requestData[key] === null || requestData[key] === undefined || requestData[key] === '') {
      delete requestData[key];
    }
  }

  if (search) {
    delete requestData.scheduleOfServiceText;
  }

  return requestData;
};

export const getCountDiff = (values: any, initialForm: SearchGroupTrainingInitialFormData): number => {
  return Object.keys(values).reduce((acc, valueIndex) => {
    if (
      values[valueIndex] !== initialForm[valueIndex as keyof SearchGroupTrainingInitialFormData] &&
      !valueIndex.endsWith('Name') &&
      values[valueIndex] !== null &&
      values[valueIndex] !== '' &&
      !valueIndex.endsWith('scheduleOfServiceText')
    ) {
      acc++;
    }
    return acc;
  }, 0);
};

export const accessInitialForm = (
  userProfile: AuthorizationData,
  educationTypeId: number,
  educationTypeName?: string
) => {
  return {
    vedomstvoId: userProfile.vedomstvoId,
    organizationName: userProfile.organizationName,
    organizationId: userProfile.organizationId,
    educationTypeId,
    educationTypeName,
  };
};

export const setTextColorByCheckCurrentDate = (start: string, end: string): string => {
  return new Date() > moment(end).toDate() && moment(start).toDate() < moment(end).toDate()
    ? styleVars.brandDangerDark
    : '';
};

export const textColorByStatusId: { [key: number]: string } = {
  1: 'color-success-dark',
  2: 'color-danger-dark',
  3: 'color-gray-dark',
};

export const editPeriodDataToTransformRequestData = (data: PeriodType[]) => {
  const checkDay = (day: ScheduleType | undefined) => {
    if (day) {
      if (day.intervals && day.intervals.length > 0) {
        const result = day.intervals.map(({ from, to }) => {
          return { f: from, t: to };
        });

        return [{ f: day.from, t: day.to }, ...result];
      } else {
        return !(day.from && day.to) ? null : [{ f: day.from, t: day.to }];
      }
    }
  };

  const transformDay = (nameDay: string, schedule: ScheduleType[]) =>
    checkDay(schedule.find((item: ScheduleType) => item.name === nameDay));

  return data.map((item: PeriodType, index) => {
    const days = {
      monday: transformDay('Понедельник', item.schedule),
      tuesday: transformDay('Вторник', item.schedule),
      wednesday: transformDay('Среда', item.schedule),
      thursday: transformDay('Четверг', item.schedule),
      friday: transformDay('Пятница', item.schedule),
      saturday: transformDay('Суббота', item.schedule),
      sunday: transformDay('Воскресенье', item.schedule),
    };

    return {
      id: index,
      shedulePeriod: index,
      dateStart: item.dateStart,
      dateEnd: item.dateEnd,
      ...days,
    };
  });
};

export const scheduleDataCreateService = (type?: 'full' | 'days' | 'periods') => [
  {
    schedule: scheduleMockData(type).map((mock, index) => ({
      id: index,
      name: mock.name,
      dayOff: true,
      intervals: [],
      from: '',
      to: '',
    })),
    id: 0,
  } as unknown as PeriodType,
];

export const maxCommentLength = 1000;

export const extendedPeriods = (schedule: PeriodType[], startDate: Date | null, endDate: Date | null) => {
  return schedule.map((period, index) => {
    if (index === 0 && startDate && endDate) {
      return {
        ...period,
        dateStart: startDate,
        dateEnd: endDate,
      };
    }
    return period;
  });
};
