import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { ServiceRegistryContext } from '../../initial-data';

const FieldPeriodOfStudy: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  const { periodOfStudyData } = React.useContext(ServiceRegistryContext);

  return (
    <FormikFormGroup name="yearOfTrainingId" label="Период обучения">
      <FormikSelect
        name="yearOfTrainingId"
        size="small"
        options={periodOfStudyData}
        isSearchable
        placeholder="Выберите..."
        defaultValue={
          values.yearOfTrainingId
            ? { label: values.yearOfTrainingName || '', value: values.yearOfTrainingId }
            : { label: 'Все', value: 0 }
        }
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('yearOfTrainingName', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};

export default FieldPeriodOfStudy;
