import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { AuthorizationData } from '../../../../types/authorization-data';
import { SearchGroupTrainingInitialData } from '../../../../types/service-class';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  vedomstvoData: SelectOptionType[];
};

export const FieldVedomstvo: React.FC<Props> = ({ isAdmin, userProfile, vedomstvoData }) => {
  const { setFieldValue, values } = useFormikContext<SearchGroupTrainingInitialData>();

  const defaultValue = isAdmin
    ? values.vedomstvoId
      ? { label: values.vedomstvoName || '', value: values.vedomstvoId }
      : { label: 'Все', value: null }
    : { label: userProfile.vedomstvoName ?? '', value: userProfile.vedomstvoId ?? '' };

  return (
    <>
      <FormikFormGroup name="vedomstvoId" label="Департамент">
        <FormikSelect
          size="small"
          isSearchable
          name="vedomstvoId"
          disabled={!isAdmin}
          options={vedomstvoData}
          defaultValue={defaultValue}
          selectedValue={(option: SelectOptionType) => {
            setFieldValue('vedomstvoId', option.value || '');
            setFieldValue('vedomstvoName', option.label || '');
          }}
        />
      </FormikFormGroup>
      <Push size={16} />
    </>
  );
};
