import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { serviceIdField } from './service';
import { DependentServiceContext } from './dependent-service';

export type TrainingGroupItemType = {
  requestStartDate: string;
  requestEndDate: string;
  educationDateStart: string;
  educationDateEnd: string;
};

type TrainingGroupProps = FieldProps<string> & { setCurrentTrainingGroup?: (data: TrainingGroupItemType) => void };

export const trainingGroupFieldId = 'trainingGroupId';
export const trainingGroupFieldName = 'trainingGroupName';

const TrainingGroupField = ({
  label = 'План приема',
  name,
  editMode,
  required,
  defaultValue,
  disabled,
  setCurrentTrainingGroup,
}: TrainingGroupProps) => {
  const { trainingGroupKey } = useContext(DependentServiceContext);

  const fieldName = `${name}Name`;
  const fieldIdName = `${name}Id`;

  const [serviceField] = useField<number>(serviceIdField);
  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldIdValue] = useField(fieldIdName);

  const [trainingGroupData, setTrainingGroupData] = useState<SelectOptionType[]>([]);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      const res = await serviceTemplateApi.getTrainingGroupsServiceClass({ serviceId });
      setTrainingGroupData(res);

      const findCurrentTrainingGroup = res.find((item) => item.value === fieldIdValue.value) as TrainingGroupItemType &
        SelectOptionType;

      if (findCurrentTrainingGroup) {
        setCurrentTrainingGroup &&
          setCurrentTrainingGroup({
            requestStartDate: findCurrentTrainingGroup?.requestStartDate,
            requestEndDate: findCurrentTrainingGroup?.requestEndDate,
            educationDateStart: findCurrentTrainingGroup?.educationDateStart,
            educationDateEnd: findCurrentTrainingGroup?.educationDateEnd,
          });
      }
    };

    if (serviceField.value && editMode) {
      fetchTrainGroup(serviceField.value);
    } else {
      setTrainingGroupData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField.value, editMode]);

  return (
    <Field label={label} editMode={editMode} required={required} value={fieldLabel.value} defaultValue={defaultValue}>
      <FormikSelect
        key={trainingGroupKey}
        name={fieldIdName}
        size="small"
        isSearchable
        options={trainingGroupData}
        selectedValue={(selected: any) => {
          setCurrentTrainingGroup &&
            setCurrentTrainingGroup({
              requestStartDate: selected?.requestStartDate,
              requestEndDate: selected?.requestEndDate,
              educationDateStart: selected?.educationDateStart,
              educationDateEnd: selected?.educationDateEnd,
            });
          helpersLabel.setValue(selected ? selected.label : fieldLabel);
        }}
        placeholder="Выберите..."
        disabled={disabled}
      />
    </Field>
  );
};

export default TrainingGroupField;
