import React from 'react';
import { Badge, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconClose } from '../../../../assets/images/icons/close.svg';
import { SplitModalListElement } from '../../../../types/learners';

type Props = {
  data: SplitModalListElement[];
  deleteElement: (value: number) => void;
};

const List: React.FC<Props> = ({ data, deleteElement }) => {
  return (
    <ul className="learners-modal-ul">
      {data.map((item) => (
        <div key={item.id} className="learners-modal-ul__item">
          <div className="color-gray-dark flex items-center">
            #{item.id}
            <button onClick={() => deleteElement(item.id)} type="button" className="learners-modal-ul__btn">
              <IconClose />
            </button>
          </div>
          <Push size={4} />
          <div className="font-weight-bold">{item.name}</div>
          <Push size={4} />
          {item.date}
          <Push size={8} />
          <div className="flex">
            <Badge small color="success" text="Активное" />
            <Push orientation="horizontal" size={8} />
          </div>
        </div>
      ))}
    </ul>
  );
};

export default List;
