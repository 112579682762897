import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Select, SelectOptionType, Tooltip, Panel, Loader, Push, AsyncTable as Table } from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { pageSizeOptions } from '../../../mock-data';
import { generateLink, replaceHistoryState, showExcelErrorData } from '../../../lib/utils';
import { maxRegistryRows, routes } from '../../../config/constants';
import { ReactComponent as IconArrow } from '../../../assets/images/teachers/arrow-right.svg';
import { SearchTeachersFormData, Teacher } from '../../../types/teacher';
import { Discipline } from '../../../types/discipline';
import teacherApi from '../../../lib/api/teacher';
import { ReactComponent as IconRegisterSuccess } from '../../../assets/images/teachers/register-success.svg';
import { ReactComponent as IconRegisterFailed } from '../../../assets/images/teachers/register-failed.svg';

type Props = {
  search: SearchTeachersFormData;
};

const TeachersTable = ({ search }: Props) => {
  const [pageSize, setPageSize] = useState<number>(
    window.history.state[historyState.pageSize] || pageSizeOptions[0].value
  );
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [currentSearch, setCurrentSearch] = useState(search);
  const [tableTotal, setTableTotal] = useState(0);
  const [tableData, setTableData] = useState<Teacher[]>([]);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const data = await teacherApi.getTeachers({
        ...currentSearch,
        organizationId: currentSearch.organizationId || null,
        pageSize,
        pageNumber: pageNum + 1,
      });
      setTableData(data.items.map((item, index) => ({ ...item, index: index + pageNum * pageSize + 1 })));
      setTableTotal(data.total);
    } finally {
      setLoading(false);
    }
  }, [pageNum, pageSize, currentSearch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setCurrentSearch(search);
    if (initPageNum) {
      setInitPageNum(0);
    } else {
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const handleExportFile = async () => {
    setLoadingFile(true);
    try {
      await teacherApi.getTeachersExcel({
        ...currentSearch,
        pageSize,
        pageNumber: pageNum + 1,
      });
    } catch (e) {
      showExcelErrorData();
    } finally {
      setLoadingFile(false);
    }
  };

  return (
    <Panel
      title={() => (
        <>
          Преподаватели{' '}
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableTotal}
          </span>
        </>
      )}
      headingControl={() => (
        <>
          <Button
            label="Экспорт"
            handleClick={handleExportFile}
            size="small"
            border
            load={loadingFile}
            iconRight={() => <IconExcel />}
            colorIcon
          />
          <Push orientation="horizontal" size={8} />
          <div style={{ width: 184 }}>
            <Select
              name="pageSize"
              isSearchable={false}
              hideClearIndicator
              value={pageSizeOptions.find((item) => item.value === pageSize)}
              onChange={(selectedOption) => {
                const { value } = selectedOption as SelectOptionType;
                setPageSize(value as number);
                setPageNum(0);
              }}
              options={pageSizeOptions}
              size="small"
            />
          </div>
        </>
      )}
    >
      {loading ? (
        <div className="loader-container">
          <Loader title="Загружаем реестр преподавателей" description="Нужно немного подождать" />
        </div>
      ) : tableTotal && setTableTotal ? (
        <Table
          overflow
          rowLimit={maxRegistryRows}
          data={tableData}
          pageSize={pageSize}
          itemsAll={tableTotal}
          setPageNumber={setPageNum}
          pageNum={pageNum}
          hideSort
          columns={[
            {
              dataIndex: 'id',
              title: '№',
              render: (item: any) => item.index,
              width: '70px',
            },
            {
              dataIndex: 'organizationName',
              title: 'Организация',
              render: (item: any) => (
                <div className="flex">
                  <div>
                    {item.organizationName.map((name: string, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span className="teachers-link__item" key={`${index}`}>
                        {item.organizationName.length === 1 ? name : <>{name};&nbsp;&nbsp;</>}
                      </span>
                    ))}
                  </div>
                </div>
              ),
              width: '20%',
            },
            {
              dataIndex: 'fullName',
              title: 'ФИО',
              width: '20%',
              render: (item: any) => (
                <Link className="brand-link" to={generateLink(routes.teacher, { id: item.id })}>
                  {item.fullName}
                </Link>
              ),
            },
            {
              dataIndex: 'isFromRegister',
              title: <span style={{ margin: '0 auto' }}>Реестр</span>,
              width: '56px',
              render: (item: any) => (
                <div className="text-center">
                  <Tooltip
                    position="top"
                    component={() =>
                      item.isFromRegister ? (
                        <IconRegisterSuccess className="table__column_center" />
                      ) : (
                        <IconRegisterFailed />
                      )
                    }
                    text={
                      item.isFromRegister
                        ? 'Сведения найдены в Реестре преподавателей'
                        : 'Сведения не найдены в Реестре преподавателей'
                    }
                  />
                </div>
              ),
            },
            {
              dataIndex: 'phone',
              title: 'Телефон',
              render: (item: any) => item.phone || <span className="color-gray-dark">Не указан</span>,
              width: '150px',
            },
            {
              dataIndex: 'isContactPerson',
              title: <span style={{ margin: '0 auto' }}>Конт. лицо</span>,
              render: (item: any) => <div className="text-center">{item.isContactPerson ? 'да' : 'нет'}</div>,
              width: '100px',
            },
            {
              dataIndex: 'discipline',
              title: 'Дисциплины',
              width: '35%',
              render: (item: any) => (
                <div className="flex">
                  <div>
                    {item.discipline.length ? (
                      item.discipline.map((discipline: Discipline, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span className="teachers-link__item" key={index}>
                          {item.discipline.length === 1 ? discipline : <>{discipline};&nbsp;&nbsp;</>}
                        </span>
                      ))
                    ) : (
                      <span className="color-gray-dark">Не указан</span>
                    )}
                  </div>
                  <Link className="teachers-link" to={generateLink(routes.teacher, { id: item.id })}>
                    <IconArrow />
                  </Link>
                </div>
              ),
            },
          ]}
        />
      ) : (
        <div className="teacher-no-data">Нет данных</div>
      )}
    </Panel>
  );
};

export default TeachersTable;
