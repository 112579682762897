import React from 'react';
import iconFile from '../../assets/images/docs/file.svg';
import iconLink from '../../assets/images/docs/link.svg';
import iconDownload from '../../assets/images/docs/download.svg';
import iconArrow from '../../assets/images/docs/north-east.svg';
import { DocListType } from '../../types/static-content';

const DocList: React.FC<{ data: DocListType[] }> = ({ data }) => {
  return (
    <ul className="doc-list">
      {data.map((item) => {
        const externalLink = item.link.match(/http/);
        return (
          <li key={item.title} className="doc-list__item">
            <div className="container">
              <div className="doc-media">
                <img src={externalLink && !item.isIconFile ? iconLink : iconFile} alt="" className="doc-media__img" />
                <div className="doc-media__body">
                  <a target="_blank" rel="noreferrer" href={item.link} className="doc-media__link">
                    {item.title}
                  </a>
                </div>
                <img
                  src={externalLink && !item.isIconFile ? iconArrow : iconDownload}
                  alt=""
                  className="doc-media__img"
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default DocList;
