import React, { useEffect, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

import { AuthorizationData } from '../../../../types/authorization-data';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { SearchGroupTrainingInitialData } from '../../../../types/service-class';
import { usePrevious } from '../../../../hooks/use-previous';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  programModules: SelectOptionType[];
};

export const FieldModule: React.FC<Props> = ({ isAdmin, userProfile, programModules }) => {
  const { values, setFieldValue } = useFormikContext<SearchGroupTrainingInitialData>();

  const [rerender, setRerender] = useState<number>(0);
  const prevProgrammModuleId = usePrevious(values.serviceId);

  // Модуль
  const accessModule: boolean = useMemo(() => {
    return hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.View) || isAdmin;
  }, [isAdmin, userProfile]);

  useEffect(() => {
    // если мы меняем Наименование услуги, то обнуляем значение модуля
    if (values.serviceId !== prevProgrammModuleId && prevProgrammModuleId) {
      setFieldValue('programmModuleId', null);
      setRerender(Math.random());
    }
    // если в Наименование услуги нет значения, то обнуляем значение
    if (!values.serviceId) {
      setFieldValue('programmModuleId', null);
      setRerender(Math.random());
    }
  }, [prevProgrammModuleId, setFieldValue, values.programmModuleId, values.serviceId]);

  return accessModule ? (
    <>
      <FormikFormGroup name="programmModuleId" label="Модуль">
        <FormikSelect
          size="small"
          isSearchable
          key={rerender}
          name="programmModuleId"
          options={programModules}
          placeholder={values.serviceId ? 'Выберите...' : 'Сначала выберите образовательную программу'}
          disabled={!values.serviceId}
          selectedValue={(option: SelectOptionType) => {
            setFieldValue('programmModuleId', option.value || '');
            setFieldValue('programmModuleName', option.label || '');
          }}
          defaultValue={
            values.programmModuleId && values.programmModuleName
              ? { label: values.programmModuleName, value: values.programmModuleId }
              : undefined
          }
        />
      </FormikFormGroup>
      <Push size={16} />
    </>
  ) : null;
};
