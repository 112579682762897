import { useField } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Radio, SimpleTable, TableDataType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import { TrainingGroupByServiceDonm } from '../../../../types/requests';
import { formatDate } from '../../../../lib/utils/date';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { serviceIdField } from './service-donm';
import { bookingApi } from '../../../../lib/api/booking';
import { BookingResultData } from '../../../../types/booking';

type AdmissionPlanProps = FieldProps<TrainingGroupByServiceDonm[]> & {
  setBooking?: Dispatch<SetStateAction<BookingResultData | undefined>>;
  booking?: BookingResultData;
};

const AdmissionPlanService = ({
  label = 'План приема',
  name,
  editMode,
  required,
  disabled,
  booking,
  setBooking,
}: AdmissionPlanProps) => {
  const [trainingGroupList, setTrainingGroupList] = useState<TrainingGroupByServiceDonm[] | null>(null);
  const [serviceField] = useField<number>(serviceIdField);
  const [field, , helpers] = useField(name);

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceTemplateApi.getTrainingGroupsByService(serviceField.value ?? 0);
      setTrainingGroupList(tgList);
    };

    if (serviceField.value != null) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceField.value]);

  return trainingGroupList ? (
    <Field
      label={label}
      editMode={editMode}
      disabled={!editMode || disabled}
      required={required}
      value={trainingGroupList}
    >
      <SimpleTable
        data={trainingGroupList as TableDataType[]}
        hideSort
        headerBorderTop={false}
        columns={[
          {
            dataIndex: 'id',
            title: '',
            render: (item: any) => (
              <Radio
                name=""
                labelId=""
                checked={field.value === item.scheduleOfTimetableId}
                onChange={async () => {
                  if (setBooking) {
                    try {
                      const booking = await bookingApi.preBookingByscheduleOfTimetable(item.scheduleOfTimetableId);
                      setBooking(booking);
                    } catch {
                      setBooking(undefined);
                    }
                  }
                  helpers.setValue(item.scheduleOfTimetableId);
                }}
                disabled={!editMode || disabled}
              />
            ),
            width: '40px',
          },
          {
            dataIndex: 'date',
            title: 'Дата начала занятий',
            render: (item: any) => formatDate(item.educationStartDate),
          },
          {
            dataIndex: 'teacher',
            title: 'Педагог',
            render: (item: any) => item.teacher,
          },
          {
            dataIndex: 'numbers',
            title: 'Количество мест',
            render: (item: any) => item.volume ?? 'Без ограничений',
          },
        ]}
      />
    </Field>
  ) : null;
};

export default AdmissionPlanService;
