import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { Button, ModalPanel, Modal, Infobox, SelectOptionType, Loader, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceTransferModal } from '../../../../types/service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import lookupApi from '../../../../lib/api/lookup';
import { ServiceClassTableContext } from '../../utils';

type Props = {
  open: boolean;
  close: () => void;
};

const Specialization: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const [loading, setLoading] = useState(false);

  const [documents, setDocuments] = useState<SelectOptionType[]>([]);
  const [specializations, setSpecializations] = useState<SelectOptionType[]>([]);

  const initialValues = useMemo(() => {
    return {
      comment: '',
      documentTypeId: documents[0]?.value,
      serviceId: specializations[0]?.value,
    };
  }, [documents, specializations]);

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const documentTypes = await dictionariesApi.getDocumentTypes(3);
        const specializationsList = await lookupApi.getService(
          undefined,
          serviceClassData.educationTypeId,
          undefined,
          serviceClassData.organizationId,
          undefined,
          true,
          true,
          100
        );

        setLoading(false);
        setSpecializations(specializationsList);
        setDocuments(documentTypes);
      } finally {
        setLoading(false);
      }
    };
    open && fetch();
  }, [open, serviceClassData.educationTypeId, serviceClassData.organizationId, serviceClassData.serviceId]);

  const submitForm = useCallback(
    async (data: ServiceTransferModal) => {
      setLoading(true);
      try {
        if (serviceClassData.id) {
          await serviceClassApi.serviceTransfer(serviceClassData.id, {
            ...data,

            megaRelationIds: selected,
            serviceClassId: serviceClassData.id,
            educationTypeId: serviceClassData.educationTypeId,
          });

          fetchTable();
          setLoading(false);
          setOpenDetails(false);
          setSelected([]);
          close();
        }
      } catch (e) {
        setLoading(false);
      }
    },
    [setSelected, close, setOpenDetails, selected, serviceClassData.educationTypeId, serviceClassData.id, fetchTable]
  );

  return (
    <Modal show={open} onClose={close}>
      <Formik
        enableReinitialize
        onSubmit={submitForm}
        initialErrors={initialErrors}
        initialValues={initialValues as ServiceTransferModal}
        validationSchema={getValidationSchema()}
      >
        {(formikProps: FormikProps<ServiceTransferModal>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() =>
                  specializations.length !== 0 && (
                    <>
                      <Button label="Отмена" primary border size="small" onClick={close} />
                      <Push orientation="horizontal" size={12} />
                      <Button
                        primary
                        submit
                        size="small"
                        load={loading}
                        label="Перевести"
                        disabled={!isValid || isSubmitting}
                      />
                    </>
                  )
                }
                modalTitle="Изменение специализации"
                renderComponent={() =>
                  loading ? (
                    <div className="flex align-center justify-center">
                      <Loader small roller />
                    </div>
                  ) : specializations.length === 0 ? (
                    <Infobox fullWidth color="primary" text="Нет доступных для перевода специализаций" />
                  ) : (
                    <>
                      <FormikFormGroup name="serviceId" label="Специализация" required>
                        <FormikSelect
                          name="serviceId"
                          size="small"
                          isSearchable
                          options={specializations}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>

                      <Push size={16} />

                      <FormikFormGroup name="documentTypeId" label="Тип документа" required>
                        <FormikSelect
                          name="documentTypeId"
                          size="small"
                          isSearchable
                          options={documents}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>

                      <Push size={16} />

                      <FormikFormGroup name="docNumber" label="Номер документа">
                        <FormikInput size="small" name="docNumber" placeholder="Введите..." />
                      </FormikFormGroup>

                      <Push size={16} />

                      <FormikFormGroup name="docDate" label="Дата документа">
                        <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="docDate" />
                      </FormikFormGroup>

                      <Push size={16} />

                      <FormikFormGroup name="comment" label="Причина перевода">
                        <FormikTextarea name="comment" placeholder="Введите..." rows={2} />
                      </FormikFormGroup>
                    </>
                  )
                }
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Specialization;

const getValidationSchema = () =>
  objectYup().shape({
    serviceId: stringYup().required('Выберите специализацию').nullable(),
    documentTypeId: stringYup().required('Выберите тип документа'),
  });
