import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import ServiceField from '../components/fields/service';
import { EducationTypeEnum } from '../../../types/education-type';
import SimpleInput from '../../../components/fields/simple-input';
import TrainingGroupField, { TrainingGroupItemType } from '../components/fields/training-group';
import DateField from '../components/fields/date';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import PupilInGroupField from '../components/fields/pupil-in-group';
import ModuleField from '../components/fields/module';
import AddressField from '../components/fields/address';
import { ServiceClassData } from '../../../types/service-class';
import { ServiceClassContext } from '../service-class';
import serviceClassApi from '../../../lib/api/service-class';
import { validationCheckDate } from '../../../lib/utils/validation';
import { maxCommentLength } from '../../../lib/utils/service-class';
import { serviceClassStatusEnum } from '../../../mock-data/service-class-status';
import DependentService from '../components/fields/dependent-service';
import WarningScheduleModal from '../components/modals/warning-schedule-modal';
import SavePanel from '../../../components/save-panel';

type Props = {
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};
const ServiceClassGroup: React.FC<Props> = ({ setEditModeParent, editMode, setEditMode }) => {
  const {
    setStartDateSchedulePeriod,
    setEndDateSchedulePeriod,
    serviceClassData,
    updateServiceClass,
    accessEditServiceClass,
    setCurrentValuesGroupDetails,
  } = useContext(ServiceClassContext);

  const initialErrors = useInitialErrors(serviceClassData, getValidationSchema());

  const [loading, setLoading] = useState(false);

  const [currentTrainingGroup, setCurrentTrainingGroup] = useState<TrainingGroupItemType | null>(null);

  const [showWarningScheduleModal, setShowWarningScheduleModal] = useState<boolean>(false);

  const submitForm = async (data: ServiceClassData) => {
    const teacherByModuleId = await serviceClassApi.getTeacherByProgrammModule(data.programmModuleId as number);

    const findTeacher = data?.teacher?.list?.find((teacher) => teacher.id === teacherByModuleId.id);

    const teachers = findTeacher ? data?.teacher?.list : [...(data?.teacher?.list || []), teacherByModuleId];

    const payload = {
      serviceClassId: serviceClassData.id,
      educationTypeId: serviceClassData.educationTypeId,
      list: teachers,
    };

    if (data?.trainStartDate && data?.trainEndDate) {
      if (
        serviceClassData.trainStartDate !== data.trainStartDate ||
        serviceClassData.trainEndDate !== data.trainEndDate
      ) {
        setShowWarningScheduleModal(true);
      } else {
        setLoading(true);

        try {
          await serviceClassApi.updateServiceClass(data);

          // Добавление преподавателя при изменении модуля
          if (!findTeacher) {
            await serviceClassApi.updateServiceClassTeacherList(payload);
          }

          updateServiceClass();
          setEditModeParent && setEditModeParent(null);
          if (setEditMode) {
            setEditMode(false);
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (serviceClassData.trainStartDate && serviceClassData.trainEndDate) {
      setStartDateSchedulePeriod(new Date(serviceClassData.trainStartDate));
      setEndDateSchedulePeriod(new Date(serviceClassData.trainEndDate));
    }
  }, [serviceClassData, setEndDateSchedulePeriod, setStartDateSchedulePeriod]);

  return serviceClassData ? (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        if (setCurrentValuesGroupDetails) {
          setCurrentValuesGroupDetails(values);
        }
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceClassData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceClassData>) => {
        const { isValid, handleSubmit, resetForm, values } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              title={() => <>Сведения о группе</>}
              headingControl={() => {
                return !editMode &&
                  setEditModeParent &&
                  serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                  accessEditServiceClass ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('group');
                      if (setEditMode) {
                        setEditMode(true);
                      }
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <DependentService>
                    <ServiceField
                      required
                      name="service"
                      editMode={editMode}
                      educationType={EducationTypeEnum.ProfessionalEducation}
                      disabled={!!serviceClassData.included || serviceClassData.hasActiveLearners}
                      label="Образовательная программа"
                    />
                    <ModuleField
                      name="programmModule"
                      label="Модуль"
                      editMode={editMode}
                      required
                      disabled={serviceClassData.hasActiveLearners}
                    />

                    <SimpleInput required name="name" editMode={editMode} label="Наименование группы" />

                    <SimpleInput name="code" required label="Код группы" />
                    <AddressField
                      name="address"
                      editMode={editMode}
                      required
                      label="Адрес"
                      disabled={serviceClassData.hasActiveLearners}
                    />

                    <TrainingGroupField
                      name="trainingGroup"
                      defaultValue="—"
                      editMode={editMode}
                      required
                      disabled={serviceClassData.hasActiveLearners}
                      setCurrentTrainingGroup={setCurrentTrainingGroup}
                    />
                  </DependentService>

                  <DateField
                    label="Даты занятий"
                    name="train"
                    editMode={editMode}
                    required
                    disabled={!!values.included}
                    currentTrainingGroup={currentTrainingGroup}
                    calendarPosition="top-end"
                  />

                  <PupilInGroupField name="included" label="Человек в группе" editMode={editMode} />

                  <SimpleTextArea
                    name="description"
                    label="Комментарий"
                    editMode={editMode}
                    placeholder="Введите..."
                    maxLength={maxCommentLength}
                  />
                </div>
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <SavePanel
                controls={
                  <>
                    <Button
                      handleClick={() => {
                        setEditModeParent && setEditModeParent(null);
                        if (setEditMode) {
                          setEditMode(false);
                        }
                        resetForm();
                      }}
                      border
                      primary
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button handleClick={handleSubmit} load={loading} disabled={!isValid} primary label="Сохранить" />
                  </>
                }
              />
            )}
            {setEditMode && (
              <WarningScheduleModal
                setEditMode={setEditMode}
                show={showWarningScheduleModal}
                setEditModeParent={setEditModeParent}
                onCloseHandler={() => setShowWarningScheduleModal(false)}
              />
            )}
          </form>
        );
      }}
    </Formik>
  ) : null;
};

export default ServiceClassGroup;

const getValidationSchema = () => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      fullServiceName: stringYup().required('Выберите программу').nullable(),
      programmModuleId: stringYup().required('Выберите модуль'),
      name: stringYup().required('Введите наименование группы').nullable(),
      address: stringYup().required('Выберите адрес'),
      trainingGroupId: stringYup().required('Выберите план приема'),
      trainStartDate: validationCheckDate(
        'Выберите дату начала',
        { start: 'trainStartDate', end: 'trainEndDate' },
        'Дата начала обучения больше даты окончания обучения',
        'start'
      ),

      trainEndDate: validationCheckDate(
        'Выберите дату окончания',
        { start: 'trainStartDate', end: 'trainEndDate' },
        'Дата окончания обучения меньше даты начала обучения',
        'end'
      ),
      capacity: numberYup()
        .min(1, 'Предельное число обучающихся в группе не может быть отрицательным числом или нулем')
        .nullable(),
    },
    [depsDate]
  );
};
