import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeading from '../../../../components/header/page-heading';
import { routes } from '../../../../config/constants';
import ServiceChildCreateDetails from './details';
import { getHeaderLink } from '../../utils';
import { CreateServiceContext } from '../../create';

const ServiceChildCreate = () => {
  const history = useHistory();
  const { serviceData, educationTypes, type } = useContext(CreateServiceContext);

  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={() => history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
        title="Новое детское объединение"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Детские объединения', link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId) },
          { title: 'Описание детского объединения' },
        ]}
      />
      <ServiceChildCreateDetails />
    </>
  );
};

export default ServiceChildCreate;
