import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Push } from '@mosru/esz_uikit';
import Expulsion from '../../components/modals/expulsion';
import TransferGroup from '../../components/modals/transfer-group';
import Certificate from '../../components/modals/certificate';
import Recovery from '../../components/modals/recovery';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, selected, openDetails, active } = useContext(ServiceClassTableContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { accessRecover } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);
  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);
  const [openCertificatePopup, setOpenCertificatePopup] = useState<boolean>(false);

  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);
  const hasCreateDocument = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.CompletionDocument], accessAction.Create),
    [userProfile]
  );

  return (
    <>
      {' '}
      {openDetails && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  default
                  handleClick={() => {
                    setSelected([]);
                    setOpenDetails(false);
                  }}
                  label="Отменить выбор"
                />
                <Push size={12} orientation="horizontal" />

                {active ? (
                  <>
                    <Button
                      danger
                      border
                      label="Отчислить"
                      onClick={() => {
                        setOpenExpulsionPopup(true);
                      }}
                    />

                    <Push size={12} orientation="horizontal" />

                    <Button
                      primary
                      border
                      label="Перевести в другую группу обучения"
                      handleClick={() => setOpenTransferGroupPopup(true)}
                    />

                    <Push size={12} orientation="horizontal" />

                    {(hasCreateDocument || isAdminEdit) && (
                      <Button
                        primary
                        border
                        disabled={selected.length > 1}
                        label="Выдать сертификат"
                        handleClick={() => {
                          setOpenCertificatePopup(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  accessRecover && (
                    <Button onClick={() => setOpenRecoveryPopup(true)} primary border label="Восстановить" />
                  )
                )}
                <Push size={12} orientation="horizontal" />
              </div>
            </div>
          </div>
        </div>
      )}
      <Recovery open={openRecoveryPopup} close={() => setOpenRecoveryPopup(false)} />
      <Expulsion open={openExpulsionPopup} close={() => setOpenExpulsionPopup(false)} />
      <TransferGroup open={openTransferGroupPopup} close={() => setOpenTransferGroupPopup(false)} />
      <Certificate open={openCertificatePopup} close={() => setOpenCertificatePopup(false)} />
    </>
  );
};

export default PanelButtons;
