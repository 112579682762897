import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { Button, IconSearch, Panel, Push } from '@mosru/esz_uikit';
import FormikToggleGroup from '../../components/formik/formik-toggle-group';
import FormikToggle from '../../components/formik/formik-toggle';
import FormikInput from '../../components/formik/formik-input';
import { SearchDisciplinesFormData } from '../../types/discipline';
import { initialFormData } from './index';

type Props = {
  submitForm: (values: SearchDisciplinesFormData) => void;
  initialForm: SearchDisciplinesFormData;
};

const DisciplineSearch = ({ submitForm, initialForm }: Props) => {
  const [rerenderFormKey, setRerenderFormKey] = useState(0);

  return (
    <Formik
      key={rerenderFormKey}
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialForm}
    >
      {(formikProps: FormikProps<SearchDisciplinesFormData>) => {
        const { handleSubmit, values, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              subControl={() => (
                <FormikToggleGroup name="Отображать архивные" label="Отображать архивные">
                  <FormikToggle name="showArchive" size="xsmall" />
                </FormikToggleGroup>
              )}
              controls={() => (
                <>
                  <Button
                    border
                    label="Сбросить"
                    size="small"
                    onClick={() => {
                      submitForm(initialFormData);
                      setRerenderFormKey(Math.random());
                    }}
                    disabled={!isValid}
                  />
                  <Push size={12} orientation="horizontal" />
                  <Button label="Начать поиск" primary submit size="small" load={isSubmitting} disabled={!isValid} />
                </>
              )}
            >
              <div className="container">
                <Push size={20} />
                <FormikInput
                  placeholder="Поиск по дисциплинам…"
                  value={values.query}
                  iconLeft={() => <IconSearch />}
                  name="query"
                  size="medium"
                />
              </div>
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

export default DisciplineSearch;
