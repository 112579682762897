import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import { SearchInitialFormData } from '../../../../../types/service';
import { useGetProgrammLevels } from '../../../../../hooks/get-programm-level';
import { usePrevious } from '../../../../../hooks/use-previous';

type Props = {
  title: string;
};
const FieldProgrammLevel: React.FC<Props> = ({ title }) => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();
  const [programmLevelIdKey, setProgrammLevelIdKey] = useState<number>(0);

  const programmLevelData: SelectOptionType[] = useGetProgrammLevels(values?.classificatorEKUId as number);
  const prevProgrammLevelId = usePrevious(values?.programmLevelId);

  const prevDirectionId = usePrevious(values.classificatorEKUId);

  // При изменении "Базового направления" обнуляем значение "Уровня программы"
  useEffect(() => {
    if (values.classificatorEKUId !== prevDirectionId && prevProgrammLevelId !== undefined) {
      setFieldValue('programmLevelId', undefined);
      setFieldValue('programmLevelName', '');
      setProgrammLevelIdKey(Math.random());
    }
  }, [prevDirectionId, setFieldValue, values.classificatorEKUId, prevProgrammLevelId]);

  const placeholder = !values.classificatorEKUId
    ? title === 'Квалификация'
      ? 'Сначала выберите профессию'
      : 'Сначала выберите вид деятельности'
    : 'Начните вводить или выберите...';

  return (
    <FormikFormGroup name="programmLevelId" label={title}>
      <FormikSelect
        key={programmLevelIdKey}
        name="programmLevelId"
        size="small"
        disabled={!values.classificatorEKUId}
        options={programmLevelData}
        isSearchable
        placeholder={placeholder}
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('programmLevelName', v.label);
          }
        }}
        defaultValue={
          values.programmLevelId || (values.programmLevelId === null && values.programmLevelName === 'Все')
            ? { value: values.programmLevelId, label: values.programmLevelName || '' }
            : undefined
        }
      />
    </FormikFormGroup>
  );
};

export default FieldProgrammLevel;
