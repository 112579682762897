import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, ModalPanel, Modal, Loader, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import { ServiceClassSubClass } from '../../../../../types/service-class';
import serviceClassApi from '../../../../../lib/api/service-class';
import { SubClassItem } from './sub-class-item';
import { ServiceClassTableContext } from '../../../utils';

type FormData = {
  newSubclassName: string;
};

const initialValues = {
  newSubclassName: '',
};

type Props = {
  open: boolean;
  close: () => void;
};

const Union: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const [listSubClass, setListSubClass] = useState<ServiceClassSubClass[]>([]);

  const callOptions = useCallback(() => {
    setOpenDetails(false);
    fetchTable();
    setSelected([]);
    close();
    setLoading(false);
  }, [setSelected, close, setOpenDetails, fetchTable]);

  const submitForm = useCallback(
    async (values: FormData) => {
      if (values.newSubclassName || listSubClass.length) {
        setLoading(true);
        try {
          const list = listSubClass
            .filter((item) => !(!item.isAllSelectedLearnersInSubclass && item.hasSelectedLearnersInSubclass))
            .map((item) => {
              return { isEnabled: item.hasSelectedLearnersInSubclass, subclassId: item.id };
            });

          if (list.length === 0 && !values.newSubclassName) {
            callOptions();
            return;
          }

          if (serviceClassData.id) {
            await serviceClassApi.updateSubClassList(serviceClassData.id, {
              ...values,
              megaRelationIds: selected,
              serviceClassId: serviceClassData.teacher.serviceClassId as number,
              educationTypeId: serviceClassData.educationTypeId,
              subclassList: values.newSubclassName ? [{ isEnabled: true, subclassId: 0 }, ...list] : list,
            });

            callOptions();
          }
        } catch (e) {
          setLoading(false);
        }
      } else {
        close();
        setOpenDetails(false);
        setSelected([]);
      }
    },
    [
      callOptions,
      setSelected,
      close,
      setOpenDetails,
      listSubClass,
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      serviceClassData.teacher.serviceClassId,
    ]
  );

  useEffect(() => {
    const fetch = async () => {
      setLoadingModal(true);
      if (serviceClassData.id) {
        try {
          const response = await serviceClassApi.postSubClassList(serviceClassData.id, {
            educationTypeId: serviceClassData.educationTypeId,
            serviceClassId: serviceClassData.id,
            megaRelationIds: selected,
          });
          setLoadingModal(false);
          setListSubClass(response);
        } catch (e) {
          setLoadingModal(false);
        }
      }
    };
    open && fetch();
  }, [open, selected, serviceClassData.educationTypeId, serviceClassData.id]);

  const loadElement = (
    <div className="flex justify-center items-center">
      <Loader small roller />
    </div>
  );

  return (
    <Modal show={open} onClose={close}>
      <Formik enableReinitialize onSubmit={submitForm} initialValues={initialValues}>
        {(formikProps: FormikProps<FormData>) => {
          const { handleSubmit } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={close} />
                    <Push orientation="horizontal" size={12} />
                    <Button primary submit size="small" load={loading} label="Объединить" />
                  </>
                )}
                modalTitle="Объединение обучающихся в подгруппы"
                renderComponent={() =>
                  !loadingModal ? (
                    <>
                      <FormikFormGroup name="newSubclassName" label="Наименование новой подгруппы">
                        <FormikInput size="small" name="newSubclassName" placeholder="Введите..." autoComplete="off" />
                      </FormikFormGroup>

                      <Push orientation="vertical" size={12} />

                      {!loading
                        ? listSubClass.map((item) => {
                            return (
                              <>
                                <SubClassItem
                                  id={item.id}
                                  name={item.name}
                                  setLoading={setLoading}
                                  updateTable={fetchTable}
                                  listSubClass={listSubClass}
                                  selectedPeople={selected}
                                  setListSubClass={setListSubClass}
                                  serviceClassData={serviceClassData}
                                  isAllSelectedLearnersInSubclass={item.isAllSelectedLearnersInSubclass}
                                  hasSelectedLearnersInSubclass={item.hasSelectedLearnersInSubclass}
                                />
                                <Push orientation="vertical" size={12} />
                              </>
                            );
                          })
                        : loadElement}
                    </>
                  ) : (
                    loadElement
                  )
                }
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Union;
