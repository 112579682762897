import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import { ReactComponent as IconArrow } from '../../../../assets/images/icons/arrow-right.svg';
import { generateLink, getServiceEditLink, hasAccessObjectAny, sliceText } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { AuthorizationData } from '../../../../types/authorization-data';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { ServiceClassStatusEnum } from '../../../../mock-data/service-class-status-enum';
import { EducationTypeEnum } from '../../../../types/education-type';
import Module from '../../../../components/module-modal';

export const tableColumn = (userProfile: AuthorizationData, isAdmin: boolean) => {
  const insert = (arr: string | any[], index: number, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  // Этапы
  const accessServiceStages: boolean =
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassSport], accessAction.View) ||
    hasAccessObjectAny(userProfile, [accessObject.ServiceClassArtHouse], accessAction.View) ||
    isAdmin;

  // Детское объединение /образоват. программа (модуль) // План приема
  const accessChildren: boolean =
    (hasAccessObjectAny(userProfile, [accessObject.ServiceClassDOGM], accessAction.View) &&
      hasAccessObjectAny(userProfile, [accessObject.ServiceClassOP], accessAction.View)) ||
    (hasAccessObjectAny(userProfile, [accessObject.ServiceClassNonDOGM], accessAction.View) &&
      hasAccessObjectAny(userProfile, [accessObject.ServiceClassOP], accessAction.View)) ||
    isAdmin;

  const nameAccess: boolean =
    !hasAccessObjectAny(userProfile, [accessObject.ServiceClassSport], accessAction.View) ||
    !hasAccessObjectAny(userProfile, [accessObject.ServiceClassArtHouse], accessAction.View) ||
    isAdmin;

  const code = {
    dataIndex: 'code',
    title: 'Код',
    width: '110px',
    render: (item: any) => (
      <span className={item.serviceClassStatus === ServiceClassStatusEnum.Archive ? 'table-archive' : ''}>
        {item.code}
      </span>
    ),
  };

  const nameText = (item: any): string => {
    if (nameAccess) {
      if (![EducationTypeEnum.SportEducation, EducationTypeEnum.ArtHouseEducation].includes(item?.educationTypeId)) {
        return `${item.serviceName ? `${item.serviceName}  -` : ''} ${item.name ? `${item.name}` : ''} `;
      } else if (item.name) {
        return item.name;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };

  const name = {
    dataIndex: 'name',
    title: 'Наименование',
    width: isAdmin ? '230px' : undefined,
    render: (item: any) => (
      <Link
        className={`${item.serviceClassStatus === ServiceClassStatusEnum.Archive ? 'table-archive' : 'brand-link'}`}
        to={generateLink(routes.serviceClass, { id: item.id })}
      >
        {sliceText(nameText(item), 40)}
      </Link>
    ),
  };

  const supervisorPerson = {
    dataIndex: 'supervisorPerson',
    title: 'Руководитель',
    render: (item: any) => (
      <span className={item.serviceClassStatus === ServiceClassStatusEnum.Archive ? 'table-archive' : ''}>
        {item.supervisorPerson}
      </span>
    ),
  };

  const serviceNames = {
    dataIndex: 'serviceNames',
    title: (
      <span>
        Детское объединение / <br /> образоват. программа (модуль)
      </span>
    ),
    width: '200px',
    render: (item: any) => <ServiceNameComponent item={item} />,
  };

  const ServiceNameComponent = ({ item }: { item: any }) => {
    const [show, setShow] = useState<boolean>(false);
    const serviceLinkRedirect = () =>
      window.open(getServiceEditLink(EducationTypeEnum.ProfessionalEducation, item.serviceId), '_blank');

    return (
      <>
        <div
          className={` ${
            item.serviceClassStatus === ServiceClassStatusEnum.Archive
              ? 'table-archive cursor-pointer'
              : 'brand-link cursor-pointer'
          }`}
        >
          {item.moduleId ? (
            <div>
              {item.serviceName ? <div onClick={serviceLinkRedirect}>{sliceText(item.serviceName, 40)} </div> : ''}
              {item.moduleName ? (
                <div className="overflow-wrap-anywhere" role="presentation" onClick={() => setShow(true)}>
                  ({item.moduleName}){' '}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div>
              {item.serviceName ? <div onClick={serviceLinkRedirect}>{sliceText(item.serviceName, 40)} </div> : ''}
            </div>
          )}
        </div>
        <Module
          show={show}
          hideModal={() => setShow(false)}
          moduleId={item.moduleId}
          serviceId={item.serviceId}
          serviceName={item.serviceName}
        />
      </>
    );
  };

  const trainDates = {
    dataIndex: 'trainDates',
    title: 'План приема',
    render: (item: any) => (
      <span className={item.serviceClassStatus === ServiceClassStatusEnum.Archive ? 'table-archive' : ''}>
        {item.trainDates}
      </span>
    ),
  };

  const serviceStages = {
    dataIndex: 'serviceStages',
    title: 'Этапы',
    render: (item: any) => (
      <div className={`${item.serviceClassStatus === ServiceClassStatusEnum.Archive ? 'table-archive' : ''}`}>
        {item.serviceStages}
      </div>
    ),
  };

  const capacity = {
    dataIndex: 'capacity',
    title: 'Предельное число обучающихся в группе/зачисленных',
    width: '200px',
    render: (item: any) => (
      <div className="flex justify-between">
        <span
          style={{ textAlign: 'center', width: '100%' }}
          className={item.serviceClassStatus === ServiceClassStatusEnum.Archive ? 'table-archive' : ''}
        >
          {item.capacity}/{item.included}
        </span>
        <Push orientation="horizontal" size={12} />

        <Link to={generateLink(routes.serviceClass, { id: item.id })}>
          <IconArrow />
        </Link>
      </div>
    ),
  };

  let viewColumn = [code, name, supervisorPerson];

  if (accessChildren) {
    viewColumn = insert(viewColumn, 3, serviceNames);
    viewColumn = insert(viewColumn, 4, trainDates);
  }
  if (accessServiceStages) {
    viewColumn = insert(viewColumn, 5, serviceStages);
  }
  viewColumn = insert(viewColumn, viewColumn.length, capacity);

  return viewColumn;
};
