import React, { useContext, useState } from 'react';
import { object as objectYup /* string as stringYup */ } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Push } from '@mosru/esz_uikit';
import { ServiceStatusEnum } from '../../../../../../mock-data/service-status-enum';
import { CandidateRestrictionTypeListData } from '../../../../../../types/service';
import { serviceTemplateApi } from '../../../../../../lib/api/service-template';
import { ServiceContext } from '../../../../index';
import LimitEdit from './limit-edit';
import { templateApi } from '../../../../../../lib/api/template';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
  parent?: string;
};

const Limit: React.FC<Props> = ({ parent, isTemplate, setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const initialValues = serviceData.candidateRestrictionType ?? ({} as CandidateRestrictionTypeListData);
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;
  const submitLimit = async (values: CandidateRestrictionTypeListData) => {
    try {
      if (isTemplate) {
        await templateApi.setTemplateCandidateRestriction({
          ...values,
          serviceId: serviceData.id,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.setCandidateRestriction({
          ...values,
          serviceId: serviceData.id,
          educationTypeId: serviceData.educationTypeId,
        });
      }

      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } catch {}
  };

  return (
    <Formik
      onSubmit={(value) => submitLimit(value)}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<CandidateRestrictionTypeListData>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <LimitEdit
              parent={parent}
              editMode={editMode}
              isTemplate={isTemplate}
              setEditMode={setEditMode}
              setEditModeParent={setEditModeParent}
              checkEditable={checkEditable && (isTemplate || values.canChangeCandidateRestriction)}
            />
            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button submit primary label="Сохранить" load={isSubmitting} disabled={!isValid} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Limit;

const getValidationSchema = () =>
  objectYup().shape({
    /* amount: stringYup().required('Введите количество'),
     duration: stringYup().required('Введите срок'),
     count: stringYup().required('Введите'), */
  });
