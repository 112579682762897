import React, { useContext } from 'react';
import { Push } from '@mosru/esz_uikit';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import { EducationTypeEnum } from '../../../../types/education-type';
import FieldAsyncSelect from './async-select';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  values: SearchRequestsInitialFormData;
  allowedEducationTypeId: number;
  label: string;
  placeholder: string;
};

const FieldServiceClass: React.FC<Props> = ({ values, allowedEducationTypeId, label, placeholder }) => {
  const { serviceClassKey } = useContext(SearchKeyContext);

  return (allowedEducationTypeId & EducationTypeEnum.DayCareCentersEducation) !== 0 ? (
    <>
      <Push size={16} />
      <FieldAsyncSelect
        key={serviceClassKey}
        nameFieldId="serviceClassId"
        nameFieldName="serviceClassName"
        label={label}
        placeholder={placeholder}
        loadOptions={async (query) => await lookupApi.getServiceClass(query, values.serviceId, values.educationTypeId)}
      />
    </>
  ) : null;
};

export default FieldServiceClass;
