import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, ref } from 'yup';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import serviceClassApi from '../../../../lib/api/service-class';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';

type Props = {
  open: boolean;
  close: () => void;
};

type FormData = {
  dateFrom: Date;
  dateTo: Date;
};

const Change: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, learners, selected, fetchTable, serviceClassData } =
    useContext(ServiceClassTableContext);

  const [loading, setLoading] = useState(false);

  const initialData: FormData = useMemo(() => {
    return {
      dateFrom: new Date(learners[0]?.dateLearnStart),
      dateTo: new Date(learners[0]?.dateLearnEnd),
    };
  }, [learners]);

  const initialErrors = useInitialErrors(initialData, getValidationSchema());

  const submitForm = useCallback(
    async (data: FormData) => {
      setLoading(true);

      try {
        if (serviceClassData.id) {
          await serviceClassApi.periodServiceClass(serviceClassData.id, {
            ...data,
            megaRelationIds: selected,
            serviceClassId: serviceClassData.teacher.serviceClassId,
            educationTypeId: serviceClassData.educationTypeId,
          });
          setLoading(false);
          fetchTable();
          setOpenDetails(false);
          setSelected([]);
          close();
        }
      } catch (e) {
        setLoading(false);
      }
    },
    [
      setSelected,
      close,
      setOpenDetails,
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      serviceClassData.teacher.serviceClassId,
      fetchTable,
    ]
  );

  return (
    <Modal show={open} onClose={close}>
      <Formik
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialData}
      >
        {(formikProps: FormikProps<FormData>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() => (
                  <>
                    <Button label="Отмена" border primary size="small" onClick={close} />
                    <Push orientation="horizontal" size={12} />
                    <Button
                      label="Сохранить изменения"
                      disabled={!isValid || isSubmitting}
                      primary
                      load={loading}
                      size="small"
                      submit
                    />
                  </>
                )}
                modalTitle="Изменение плановых дат обучения"
                renderComponent={() => (
                  <>
                    <FormikFormGroup name="dateFrom" required label="Начало обучения">
                      <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="dateFrom" />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="dateTo" required label="Окончание обучения">
                      <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="dateTo" />
                    </FormikFormGroup>
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Change;

const getValidationSchema = () =>
  objectYup().shape({
    dateFrom: docDate.nullable().required('Выберите дату начала'),
    dateTo: docDate
      .nullable()
      .required('Выберите дату окончания')
      .min(ref('dateFrom'), 'Дата окончания обучения меньше даты начала обучения'),
  });
