import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Button, IconSearch, SelectOptionType, Panel, Switcher, Push } from '@mosru/esz_uikit';
import { LoaderCustom } from '../../../components/loader-custom';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { historyState } from '../../../mock-data/history-state';
import { EducationTypeEnum } from '../../../types/education-type';
import FormikInput from '../../../components/formik/formik-input';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import FormikSelect from '../../../components/formik/formik-select';

import {
  genderOptions,
  informationOptions,
  statusOptions,
  transformPrivelegiesData,
} from '../../../lib/utils/learners';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { adminAccess, hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import FormikToggle from '../../../components/formik/formik-toggle';
import FormikToggleGroup from '../../../components/formik/formik-toggle-group';
import { SearchLearnersInitialFormData } from '../../../types/learners';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import lookupApi from '../../../lib/api/lookup';
import { filterEducations } from '../../../lib/utils/education';
import { ExpandedSearchButton } from './components/expanded-search-button';
import { ServiceField } from './components/fileds/service';
import { VedomstvoField } from './components/fileds/vedomstvo';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { sendReachGoal } from '../../../lib/metrica';

type Props = {
  search: SearchLearnersInitialFormData | undefined;
  submitForm: (values: SearchLearnersInitialFormData) => void;
};

export const searchLearnersInitialFormData: SearchLearnersInitialFormData = {
  query: '',
  statusId: '',
  sexId: '',
  serviceId: null,
  vedomstvoId: null,
  childFirstName: '',
  childLastName: '',
  childMiddleName: '',
  organizationId: null,
  contingentLinkTypeId: '',
  privilegeCategoryId: null,
  hasActivePrivileges: false,
  educationTypeId: EducationTypeEnum.All,
};

const LearnersSearch: React.FC<Props> = ({ search, submitForm }) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [initialForm, setInitialForm] = useState(searchLearnersInitialFormData);

  const [educationType, setEducationType] = useState<SelectOptionType | null>(null);
  const [educationTypeData, setEducationTypeData] = useState<SelectOptionType[]>([]);

  const [vedomstvo, setVedomstvo] = useState<SelectOptionType | null>(null);

  const [organization, setOrganization] = useState<SelectOptionType | null>(null);
  const [organizationKey, setOrganizationKey] = useState(0);

  const [service, setService] = useState<SelectOptionType | null>(null);
  const [serviceKey, setServiceKey] = useState(0);

  const [rerenderTableKey, setRerenderTableKey] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<SearchLearnersInitialFormData>(
    search || window.history.state[historyState.search] || initialForm
  );

  const [isLoadingService, setIsLoadingService] = useState(true);
  const [isLoadingEdType, setIsLoadingEdType] = useState(true);

  const isLoading = isLoadingService || isLoadingEdType;

  const vedomstvoOptions: SelectOptionType[] = useGetDataDepartment();

  const [privelegies, setPrivelegies] = useState<any>([]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin = adminAccess('edit-or-view', userProfile);

  const canChangeOrganization: boolean = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const initEdTypes = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Pupils,
        accessAction.ViewRegistry
      ),
    [userProfile.objectAccessActionList]
  );

  const getEducationType = (edType: SelectOptionType[]) => {
    const historyEdType = window.history.state[historyState.search]?.educationTypeId;

    return edType.length > 0
      ? historyEdType
        ? edType.find(({ value }) => value === historyEdType) ?? null
        : edType[0]
      : null;
  };

  useEffect(() => {
    search && setInitialValues(search);
  }, [search]);

  // инициализация search
  useEffect(() => {
    if (!canChangeOrganization) {
      setOrganization({ label: userProfile.organizationName, value: userProfile.organizationId ?? '' });
    }

    setEducationTypeData(initEdTypes);
    const currentEducationType = getEducationType(initEdTypes);
    setEducationType(currentEducationType);

    submitForm(
      window.history.state[historyState.search] || {
        ...searchLearnersInitialFormData,
        vedomstvoId: isAdmin ? null : userProfile.vedomstvoId,
        organizationId: canChangeOrganization ? null : userProfile.organizationId,
        educationTypeId: initEdTypes.length > 0 ? currentEducationType?.value : undefined,
      }
    );
  }, [
    canChangeOrganization,
    isAdmin,
    submitForm,
    userProfile.organizationId,
    userProfile.organizationName,
    userProfile.vedomstvoId,
    initEdTypes,
  ]);

  useEffect(() => {
    setVedomstvo(
      isAdmin
        ? vedomstvoOptions.find(({ value }) => value === initialValues.vedomstvoId) ?? null
        : { label: userProfile.vedomstvoName, value: userProfile.vedomstvoId as number }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, vedomstvoOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const educationTypes = await dictionariesApi.getEducationTypes();

      if (hasGeneralAccess(userProfile, generalAccess.CheckPrivileges)) {
        const privelegiesAll = await dictionariesApi.getPrivilegeCategories();
        const transformPrivelegies = transformPrivelegiesData(privelegiesAll);
        setPrivelegies(transformPrivelegies);
      }

      const edTypes = filterEducations(
        userProfile.objectAccessActionList,
        educationTypes,
        undefined,
        accessObject.Pupils,
        accessAction.ViewRegistry
      );
      setEducationTypeData(edTypes);
      setEducationType(getEducationType(edTypes));
    };

    fetchData().finally(() => {
      setIsLoadingEdType(false);
    });
  }, [userProfile]);

  useEffect(() => {
    setInitialForm((prevState) => ({
      ...prevState,
      educationTypeId: initEdTypes.length > 0 ? initEdTypes[0].value : undefined,
      organizationId: canChangeOrganization ? null : userProfile.organizationId,
      vedomstvoId: isAdmin ? null : userProfile.vedomstvoId,
      serviceId: null,
    }));
  }, [initEdTypes, canChangeOrganization, isAdmin, userProfile.organizationId, userProfile.vedomstvoId]);

  useEffect(() => {
    const fetch = async () => {
      const id = search?.serviceId as number;
      const service = await serviceTemplateApi.getServiceName(id);
      setService({
        label: service,
        value: id,
      });
    };

    if (search?.serviceId && !service?.label) {
      fetch().finally(() => {
        setIsLoadingService(false);
      });
    } else {
      search && setIsLoadingService(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search?.serviceId]);

  useEffect(() => {
    if (search?.organizationName && search?.organizationId && !organization?.label) {
      setOrganization({
        label: search.organizationName,
        value: search.organizationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search?.organizationName, search?.organizationId]);

  // установить департамент по-умолчанию при сбросе формы
  const setDefaultVedomstvo = () => {
    setVedomstvo(isAdmin ? null : { label: userProfile.vedomstvoName, value: userProfile.vedomstvoId as number });
  };

  // установить организацию по-умолчанию при сбросе формы или изменении департамента
  const setDefaultOrganization = () => {
    setOrganization(
      canChangeOrganization ? null : { label: userProfile.organizationName, value: userProfile.organizationId ?? '' }
    );
  };

  // установить вид образования по-умолчанию при сбросе формы
  const setDefaultEducationType = () => {
    setEducationType(initEdTypes.length > 0 ? initEdTypes[0] : null);
  };

  // обработчик сброса формы
  const onResetForm = () => {
    setDefaultEducationType();
    setDefaultVedomstvo();
    setDefaultOrganization();
    setService(null);
    submitForm(initialForm);
    setRerenderTableKey(Math.random());
  };

  const onEducationTypeSelect = (value: SelectOptionType) => {
    setOrganizationKey(Math.random());
    setDefaultOrganization();
    setServiceKey(Math.random());
    setService(null);
    setEducationType(value);
  };

  const onVedomstvoSelect = (value: SelectOptionType) => {
    setOrganizationKey(Math.random());
    setDefaultOrganization();
    setServiceKey(Math.random());
    setService(null);
    setVedomstvo(value);
  };

  const onOrganizationSelect = (value: SelectOptionType) => {
    setServiceKey(Math.random());
    setService(null);
    setOrganization(value);
  };

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [historyState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  return (
    <Formik
      key={rerenderTableKey}
      onSubmit={(values, formikHelpers) => {
        sendReachGoal('onclick-learner-search');

        submitForm({
          ...values,
          vedomstvoId: (vedomstvo?.value as number) ?? null,
          organizationId: (organization?.value as number) ?? null,
          educationTypeId: educationType?.value as number,
          serviceId: (service?.value as number) ?? null,
        });
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<SearchLearnersInitialFormData>) => {
        const { handleSubmit, values, isSubmitting, setFieldValue } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              subControl={() => (
                <ExpandedSearchButton open={open} setOpen={handleClickAdvancedSearch} initialForm={initialForm} />
              )}
              controls={() => (
                <>
                  <Button label="Сбросить" onClick={onResetForm} border size="small" />
                  <Push orientation="horizontal" size={12} />
                  <Button submit label="Начать поиск" load={isSubmitting} primary size="small" />
                </>
              )}
            >
              <div className="container">
                <Push size={20} />
                <FormikInput
                  name="query"
                  value={values.query}
                  placeholder="Поиск по обучающимся..."
                  iconLeft={() => <IconSearch />}
                />
                {open && (
                  <>
                    <Push size={16} />
                    {isLoading ? (
                      <LoaderCustom size={150} />
                    ) : (
                      <div className="learners-search-grid">
                        <div className="learners-search-grid__main">
                          <div>ФИО обучающегося</div>
                          <Push size={8} />
                          <div className="learners-input-grid">
                            <FormikFormGroup name="childLastName" label="">
                              <FormikInput
                                name="childLastName"
                                value={values.childLastName}
                                placeholder="Фамилия"
                                size="small"
                              />
                            </FormikFormGroup>

                            <FormikFormGroup name="childFirstName" label="">
                              <FormikInput
                                name="childFirstName"
                                value={values.childFirstName}
                                placeholder="Имя"
                                size="small"
                              />
                            </FormikFormGroup>

                            <FormikFormGroup name="childMiddleName" label="">
                              <FormikInput
                                name="childMiddleName"
                                value={values.childMiddleName}
                                placeholder="Отчество"
                                size="small"
                              />
                            </FormikFormGroup>
                          </div>

                          <Push size={16} />
                          <FormikFormGroup name="educationTypeId" label="Тип образования">
                            <FormikSelect
                              name="educationTypeId"
                              size="small"
                              isSearchable
                              options={educationTypeData}
                              disabled={educationTypeData.length === 1}
                              defaultValue={educationType}
                              selectedValue={(option: SelectOptionType) => {
                                setFieldValue('organizationId', null);
                                setFieldValue('serviceId', null);
                                onEducationTypeSelect(option);
                              }}
                              placeholder="Выберите тип образования..."
                            />
                          </FormikFormGroup>
                          <Push size={16} />

                          <VedomstvoField
                            vedomstvo={vedomstvo}
                            setVedomstvo={setVedomstvo}
                            vedomstvoOptions={vedomstvoOptions}
                            onVedomstvoSelect={onVedomstvoSelect}
                          />

                          <Push size={16} />
                          <FormikFormGroup name="organizationId" label="Образовательная организация">
                            <FormikSelect
                              key={organizationKey}
                              name="organizationId"
                              size="small"
                              isSearchable
                              loadOptions={async (query) => await lookupApi.getOrganization(query, vedomstvo?.value)}
                              disabled={!canChangeOrganization}
                              defaultValue={organization}
                              selectedValue={(option: SelectOptionType) => {
                                setFieldValue('serviceId', null);
                                setFieldValue('organizationName', option?.label);
                                onOrganizationSelect(option);
                              }}
                              options={[]}
                              placeholder="Начните вводить название образовательной организации..."
                            />
                          </FormikFormGroup>
                          <Push size={16} />

                          <ServiceField
                            service={service}
                            serviceKey={serviceKey}
                            setService={setService}
                            vedomstvo={vedomstvo?.value as number}
                            educationType={educationType?.value as number}
                            organization={organization?.value as number}
                          />
                        </div>
                        <div className="learners-search-grid__options">
                          <FormikFormGroup name="sexId" label="Пол">
                            <Switcher size="small" btnWidthAuto>
                              {genderOptions.map((item) => (
                                <button
                                  key={item.value}
                                  type="button"
                                  onClick={() => setFieldValue('sexId', item.value)}
                                  className={(values.sexId ?? '') === item.value ? 'active' : ''}
                                >
                                  {item.label}
                                </button>
                              ))}
                            </Switcher>
                          </FormikFormGroup>
                          <Push size={16} />
                          <FormikFormGroup name="contingentLinkTypeId" label="Сведения об обучающемся в Контингенте">
                            <Switcher size="small" btnWidthAuto>
                              {informationOptions.map((item) => (
                                <button
                                  key={item.value}
                                  type="button"
                                  onClick={() => setFieldValue('contingentLinkTypeId', item.value)}
                                  className={(values.contingentLinkTypeId ?? '') === item.value ? 'active' : ''}
                                >
                                  {item.label}
                                </button>
                              ))}
                            </Switcher>
                          </FormikFormGroup>

                          <Push size={16} />
                          <FormikFormGroup name="statusId" label="Статус обучающегося">
                            <Switcher size="small" btnWidthAuto>
                              {statusOptions.map((item) => (
                                <button
                                  key={item.value}
                                  type="button"
                                  onClick={() => setFieldValue('statusId', item.value)}
                                  className={(values.statusId ?? '') === item.value ? 'active' : ''}
                                >
                                  {item.label}
                                </button>
                              ))}
                            </Switcher>
                          </FormikFormGroup>

                          {hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) && (
                            <>
                              <Push size={16} />

                              <FormikFormGroup name="privilegeCategoryId" label="Льготная категория">
                                <FormikSelect
                                  name="privilegeCategoryId"
                                  size="small"
                                  isSearchable
                                  options={privelegies}
                                  defaultValue={{ label: 'Все', value: null }}
                                  placeholder="Начните вводить наименование льготной категории..."
                                />
                              </FormikFormGroup>

                              <Push size={16} />
                              <FormikToggleGroup
                                name="hasActivePrivileges"
                                label="Отображать обучающихся, имеющих активные льготные категории"
                              >
                                <FormikToggle
                                  checked={values.hasActivePrivileges}
                                  name="hasActivePrivileges"
                                  size="small"
                                />
                              </FormikToggleGroup>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

export default LearnersSearch;
