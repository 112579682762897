import React, { useContext } from 'react';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../../components/formik/formik-input';
import { DocumentModalContext } from '../../index';

type Props = {
  newDocument: boolean;
  accessDisabled: boolean;
};

export const DocNumber: React.FC<Props> = ({ newDocument, accessDisabled }) => {
  const { adminEdit } = useContext(DocumentModalContext);
  return (
    <FormikFormGroup name="docNumber" required label="Номер">
      <FormikInput
        key="docNumber"
        size="small"
        name="docNumber"
        placeholder="Введите номер"
        disabled={!newDocument && !adminEdit}
      />
    </FormikFormGroup>
  );
};
