import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DropDown from '../../../../../components/drop-down';
import { ReactComponent as IconDots } from '../../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../../assets/images/icons/remove.svg';
import { ReactComponent as IconFileAdd } from '../../../../../assets/images/icons/file-add.svg';
import { userProfileSelector } from '../../../../../redux/selectors';
import { AppState } from '../../../../../redux/types/state';
import { hasAccessObjectParent, hasGeneralAccess } from '../../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../../types/authorization-data';
import { ScheduleData, TrainingGroupData } from '../../../../../types/service';
import { TrainingGroupStatusEnum } from '../../../../../mock-data/training-group-status-enum';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { apply } from '../../../../../lib/utils/service';
import { ServiceContext } from '../../..';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';

type Props = {
  itemData: TrainingGroupData;
  editPlan?: (id: number) => void;
  setIdRemoveModal?: (id: number) => void;
  isSubmitRequest?: boolean;
  udodAccessDenied: boolean;
};

const ControlPanel: React.FC<Props> = ({ itemData, editPlan, setIdRemoveModal, udodAccessDenied }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { serviceData } = useContext(ServiceContext);

  const canEdit = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
      hasAccessObjectParent(userProfile, accessObject.Services, accessAction.Edit),
    [userProfile]
  );
  const canEditButton = useMemo(
    () => canEdit && serviceData.serviceStatusId === ServiceStatusEnum.Draft,
    [canEdit, serviceData.serviceStatusId]
  );
  const canRemoveButton = useMemo(
    () =>
      canEditButton &&
      (!itemData.scheduleList?.length || itemData.scheduleList.every((s: ScheduleData) => s.canDeleteSchedule)),
    [itemData.scheduleList, canEditButton]
  );

  const canCreateRequestButton = useMemo(
    () =>
      canEdit &&
      itemData.scheduleList?.length > 0 &&
      itemData.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
      itemData.scheduleList[0].isAccessible &&
      itemData.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation &&
      !udodAccessDenied,
    [canEdit, itemData, udodAccessDenied]
  );

  return canEditButton || canRemoveButton || canCreateRequestButton ? (
    <div className="flex justify-end">
      <DropDown
        component={() => (
          <span className="drop-down-btn-round">
            <IconDots />
          </span>
        )}
      >
        <div className="drop-down-panel">
          <div className="drop-down-panel__list">
            {canEditButton && (
              <button
                type="button"
                onClick={() => editPlan && editPlan(itemData.id)}
                className="drop-down-panel__list-item"
              >
                <IconEdit />
                Редактировать
              </button>
            )}
            {canCreateRequestButton && (
              <button
                type="button"
                onClick={async () => await apply(itemData.scheduleList[0].id, serviceData.educationTypeId)}
                className="drop-down-panel__list-item"
              >
                <IconFileAdd />
                Подать заявление
              </button>
            )}

            {canRemoveButton && (
              <button
                type="button"
                onClick={() => setIdRemoveModal && setIdRemoveModal(itemData.id)}
                className="drop-down-panel__list-item"
              >
                <IconRemove />
                Удалить
              </button>
            )}
          </div>
        </div>
      </DropDown>
    </div>
  ) : null;
};

export default ControlPanel;
