import { stringify, StringifyOptions } from 'query-string';
import { deepReplaceDateToString } from '../utils/date';
import { RequestConfig } from './axios-base-query';

const seriailizeOptions: StringifyOptions = { arrayFormat: 'bracket' };

const getParamsSerializerDefault = (config: RequestConfig = {}) =>
  config.params && !config.paramsSerializer
    ? (data: RequestConfig['data']) => stringify(data, seriailizeOptions)
    : config.paramsSerializer;

const getTransformData = (data: RequestConfig['data']) => data && deepReplaceDateToString(data);

const GET = (config: RequestConfig = {}): RequestConfig => {
  return {
    paramsSerializer: getParamsSerializerDefault(config),
    ...config,
    method: 'GET',
  };
};

const POST = ({ data, headers, ...config }: RequestConfig = {}): RequestConfig => {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    data: getTransformData(data),
    ...config,
    method: 'POST',
  };
};

const FILE = (config: RequestConfig = {}): RequestConfig => {
  return {
    ...config,
    method: 'POST',
  };
};

const PUT = ({ data, headers, ...config }: RequestConfig = {}): RequestConfig => {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    data: getTransformData(data),
    ...config,
    method: 'PUT',
  };
};

const PUT_FORM_DATA = ({ headers, ...config }: RequestConfig = {}): RequestConfig => {
  return {
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    ...config,
    method: 'PUT',
  };
};

const PATCH = ({ data, headers, ...config }: RequestConfig = {}): RequestConfig => {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    data: getTransformData(data),
    ...config,
    method: 'PATCH',
  };
};

const DELETE = ({ headers, ...config }: RequestConfig = {}): RequestConfig => {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    paramsSerializer: getParamsSerializerDefault(config),
    ...config,
    method: 'DELETE',
  };
};

const api = {
  GET,
  POST,
  FILE,
  PUT,
  PUT_FORM_DATA,
  PATCH,
  DELETE,
};

export default api;
