import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { Loader, Push } from '@mosru/esz_uikit';
import { EducationTypeEnum } from '../../types/education-type';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { adminAccess } from '../../lib/utils';
import { ServiceData } from '../../types/service';
import { useGetDataEducationTypes } from '../../hooks/get-education-types';
import { accessObject } from '../../mock-data/access-enum';
import { accessAction } from '../../types/authorization-data';
import { RegistryTypeEnum } from '../../mock-data/registry-type-enum';
import { serviceTemplateApi } from '../../lib/api/service-template';
import { organizationAccess } from './utils';
import ServiceCreate from './dkgm-dsit/create/create';
import { CreateServiceContext } from './create';

const initialValue = {} as ServiceData;

export type CreateServiceContextType = {
  serviceData: ServiceData;
  createService?: () => void | undefined;
  adminView?: boolean;
  adminEdit?: boolean;
  admin?: boolean;
  currentEducationType: number;
  educationTypes: number[];
  type: RegistryTypeEnum;
  organizationEdit: boolean;
};

const CreateServiceByTemplate = (type: RegistryTypeEnum) => {
  const [service, setService] = useState<ServiceData>();
  const { templateId } = useParams<Record<string, string | undefined>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async (tId: string) => {
      try {
        setIsLoading(true);
        const emptyService = await serviceTemplateApi.getServiceByTemplate(tId);
        setService(emptyService);
      } finally {
        setIsLoading(false);
      }
    };
    if (templateId) {
      fetch(templateId);
    }
  }, [templateId]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const educationTypeData = useGetDataEducationTypes(userProfile, accessObject.Services, accessAction.ViewRegistry);
  const currentEducationTypes = educationTypeData?.map((item) => item.value) as EducationTypeEnum[];

  const currentService = () => {
    switch (service?.educationTypeId) {
      case EducationTypeEnum.ArtHouseEducation:
      case EducationTypeEnum.SportEducation:
        return <ServiceCreate />;
      default:
        return null;
    }
  };

  const initialValues: CreateServiceContextType = useMemo(
    () => ({
      serviceData: service || initialValue,
      createService: () => {},
      adminView: adminAccess('view', userProfile),
      adminEdit: adminAccess('edit', userProfile),
      admin: adminAccess('all', userProfile),
      educationTypes: currentEducationTypes,
      currentEducationType: service?.educationTypeId || 0,
      type,
      organizationEdit: organizationAccess(userProfile),
    }),
    [service, userProfile, currentEducationTypes, type]
  );

  return (
    <>
      {service ? (
        <CreateServiceContext.Provider value={initialValues}>{currentService()}</CreateServiceContext.Provider>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto loader-auto--bg">
            <Loader roller />
          </div>
        </>
      ) : null}
    </>
  );
};

export default CreateServiceByTemplate;
