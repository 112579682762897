import { useContext, useState } from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { ServiceClassContext } from '../../service-class';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const AddArchiveModal = ({ show, onCloseHandler }: Props) => {
  const { updateServiceClass, addArchive } = useContext(ServiceClassContext);
  const [loading, setLoading] = useState<boolean>(false);

  const addArchiveServiceSclass = async () => {
    setLoading(true);
    try {
      addArchive && (await addArchive());
      onCloseHandler();
      updateServiceClass();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle="Отправка группы обучения в архив"
        renderComponent={() => (
          <>
            <Push orientation="vertical" size={15} />
            <div>Сведениям о группе обучения будет присвоен статус Архив. Продолжить?</div>
            <Push orientation="vertical" size={15} />
          </>
        )}
        controls={() => (
          <>
            <Button border disabled={loading} label="Нет" onClick={onCloseHandler} />
            <Push orientation="horizontal" size={12} />
            <Button primary load={loading} label="Да" onClick={addArchiveServiceSclass} />
          </>
        )}
      />
    </Modal>
  );
};

export default AddArchiveModal;
