import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { PlaceOfWork, SearchTeachersFormData, Teacher } from '../../types/teacher';
import { OrderByTypeRequest, PaginationRequest } from '../../types/entities';
import { downloadBase64File } from '../utils';
import { Discipline } from '../../types/discipline';

type GetTeachersProps = SearchTeachersFormData & OrderByTypeRequest & PaginationRequest;

const getTeachers = async (data: GetTeachersProps): Promise<{ items: Teacher[]; total: number }> =>
  await fetchRequest.post(`${apiService.data}/Teacher/Search`, data);

const getTeachersExcel = async (data: GetTeachersProps) => {
  const resp = (await fetchRequest.post(`${apiService.data}/Teacher/ToExcel`, data)).data;
  downloadBase64File(resp.content, resp.data, resp.name);
};

const getTeacher = async (id: string): Promise<Teacher> => await fetchRequest.get(`${apiService.data}/Teacher/${id}`);

const updateTeacher = async (teacher: Teacher): Promise<Teacher> =>
  await fetchRequest.put(`${apiService.data}/Teacher/${teacher.id}`, teacher, { isProtected: true });

const archiveTeacher = async (id: number) =>
  await fetchRequest.delete(`${apiService.data}/Teacher/${id}`, {}, { isProtected: true });

const restoreTeacher = async (id: number) =>
  await fetchRequest.put(`${apiService.data}/Teacher/Restore/${id}`, {}, { isProtected: true });

const addTeacherDiscipline = async (teacherId: number, discipline: Discipline) =>
  await fetchRequest.post(`${apiService.data}/Teacher/${teacherId}/Discipline/`, discipline, { isProtected: true });

const deleteTeacherDiscipline = async (teacherId: number, disciplineId: number) =>
  await fetchRequest.delete(
    `${apiService.data}/Teacher/${teacherId}/Discipline/`,
    { disciplineId },
    { isProtected: true }
  );

const addTeacherPosition = async (teacherId: number, position: PlaceOfWork) =>
  await fetchRequest.post(`${apiService.data}/Teacher/${teacherId}/Position/`, position, { isProtected: true });

const deleteTeacherPosition = async (teacherId: number, position: PlaceOfWork) =>
  await fetchRequest.delete(
    `${apiService.data}/Teacher/${teacherId}/Position/`,
    { positionId: position.personPositionId, organizationId: position.organizationId },
    { isProtected: true }
  );

const teacherApi = {
  getTeachers,
  getTeachersExcel,
  getTeacher,
  updateTeacher,
  archiveTeacher,
  restoreTeacher,
  addTeacherDiscipline,
  deleteTeacherDiscipline,
  addTeacherPosition,
  deleteTeacherPosition,
};

export default teacherApi;
