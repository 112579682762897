import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Checkbox, Tooltip, Loader, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';
import {
  convertScheduleToBack,
  convertScheduleToFront,
  getDayOfTheWeekFromString,
  getStringFromTimeTable,
} from '../../../../../lib/utils/time-table';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { ItemWorkData } from '../../../../../types/service';
import ScheduleModal from '../../../../../components/schedule/schedule-modal';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { EducationTypeEnum } from '../../../../../types/education-type';

type Props = {
  itemWorkList: ItemWorkData[];
  setItemWorkList: Dispatch<SetStateAction<ItemWorkData[]>>;
  serviceId?: number;
  organizationId?: number;
  educationTypeId?: EducationTypeEnum;
};

const EditAddressDocuments: React.FC<Props> = ({
  itemWorkList,
  setItemWorkList,
  serviceId,
  organizationId,
  educationTypeId,
}) => {
  const [showScheduleFor, setShowScheduleFor] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        if (serviceId) {
          const result = await serviceTemplateApi.getServiceItemWorkList({
            serviceId,
            withOrgServicePlaces: true,
          });
          setItemWorkList(
            result.map((i, number) => {
              return { ...i, number: number + 1 };
            })
          );
        } else if (organizationId) {
          const result = await dictionariesApi.getItemsWorkList(organizationId);
          setItemWorkList(
            result.map((i, number) => {
              return {
                id: i.id,
                shortName: i.shortName,
                fullName: i.fullName,
                addressId: i.addressId,
                fullAddress: i.fullAddress,
                isAddressArchive: i.isArchive,
                timetable: i.timetable,
                isSelected: false,
                schedule: { list: i.scheduleList, educationTypeId, serviceId },
                number: number + 1,
              } as ItemWorkData;
            })
          );
        }
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [serviceId, setItemWorkList, organizationId, educationTypeId]);

  return loading ? (
    <div className="flex justify-center flex-column">
      <Push orientation="vertical" size={20} />
      <Loader roller small />
      <Push orientation="vertical" size={20} />
    </div>
  ) : (
    <>
      <Table
        data={itemWorkList}
        hideSort
        overflow
        pageSize={Number.MAX_SAFE_INTEGER}
        columns={[
          {
            dataIndex: '1',
            title: (
              <Checkbox
                labelId=""
                checked={!!itemWorkList.length && itemWorkList.every((i) => i.isSelected)}
                indeterminate={itemWorkList.some((i) => i.isSelected) && !itemWorkList.every((i) => i.isSelected)}
                onChange={() => {
                  const anySelected = itemWorkList.some((i) => i.isSelected);
                  setItemWorkList(
                    itemWorkList.map((i) => {
                      return { ...i, isSelected: !anySelected };
                    })
                  );
                }}
              />
            ),
            render: (item: any) => (
              <Checkbox
                labelId=""
                checked={item.isSelected}
                onChange={() => {
                  setItemWorkList(
                    itemWorkList.map((i) => {
                      return i.id !== item.id ? i : { ...i, isSelected: !item.isSelected };
                    })
                  );
                }}
              />
            ),
            width: '60px',
          },
          {
            dataIndex: 'number',
            title: '№',
            render: (item: any) => item.number,
            width: '40px',
          },
          {
            dataIndex: 'isAddressArchive',
            title: '',
            render: (item: any) =>
              item.isAddressArchive && (
                <Tooltip
                  component={() => <IconInfo />}
                  position="bottom"
                  text={
                    <div className="text-center" style={{ maxWidth: '203px' }}>
                      Адрес является архивным и более не используется организацией в качестве места приема документов
                    </div>
                  }
                />
              ),
          },
          {
            dataIndex: 'name',
            title: 'Наименование организации',
            render: (item: any) => item.fullName,
            width: '45%',
          },
          {
            dataIndex: 'address',
            title: 'Адрес',
            render: (item: any) => item.fullAddress,
            width: '30%',
          },
          {
            dataIndex: 'schedule',
            title: 'Время работы',
            render: (item: any) => (
              <div className="flex items-start">
                <div>
                  {getDayOfTheWeekFromString(getStringFromTimeTable(convertScheduleToFront(item.schedule?.list))).map(
                    (item: string, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`schedule_${index}`}>{item}</div>
                    )
                  )}
                </div>
                <Push size={4} orientation="horizontal" />
                {/* Использовать модалку с рассписанием */}
                <button type="button" className="icon-group flex-none" onClick={() => setShowScheduleFor(item.id)}>
                  <span className="icon-group__icon">
                    <IconEdit />
                  </span>
                </button>
              </div>
            ),
            width: '20%',
          },
        ]}
      />

      <ScheduleModal
        title="Время работы"
        show={showScheduleFor !== 0}
        onClose={() => {
          setShowScheduleFor(0);
        }}
        onSubmit={(schedule) => {
          setItemWorkList(
            itemWorkList.map((i) => {
              return i.id !== showScheduleFor
                ? i
                : { ...i, schedule: { ...i.schedule, list: convertScheduleToBack(schedule) } };
            })
          );
          setShowScheduleFor(0);
        }}
        scheduleData={convertScheduleToFront(itemWorkList.find((i) => i.id === showScheduleFor)?.schedule?.list ?? [])}
      />
    </>
  );
};

export default EditAddressDocuments;
