import React from 'react';
import { useField } from 'formik';
import { SelectOptionType, GroupTypeBase } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';

type Props = {
  disabled?: boolean;
  onChange?: (value?: SelectOptionType) => void;
  nameFieldId: string;
  nameFieldName: string;
  label: string;
  placeholder: string;
  loadOptions: (value: string) => Promise<ReadonlyArray<SelectOptionType | GroupTypeBase<SelectOptionType>>>;
  option?: SelectOptionType[];
};

const FieldAsyncSelect: React.FC<Props> = ({
  disabled,
  onChange,
  nameFieldId,
  nameFieldName,
  label,
  placeholder,
  loadOptions,
  option,
}) => {
  const [fieldId, , helpers] = useField(nameFieldId);
  const [fieldName, , helpersName] = useField(nameFieldName);
  return (
    <FormikFormGroup name={nameFieldId} label={label}>
      <FormikSelect
        name={nameFieldId}
        size="small"
        isSearchable
        noCacheOptions
        loadOptions={loadOptions}
        options={option || []}
        disabled={disabled}
        placeholder={placeholder}
        selectedValue={(option: SelectOptionType) => {
          helpers.setValue(option?.value);
          helpersName.setValue(option?.label);
          onChange && onChange(option);
        }}
        defaultValue={fieldId.value ? { label: fieldName.value, value: fieldId.value } : null}
      />
    </FormikFormGroup>
  );
};

export default FieldAsyncSelect;
