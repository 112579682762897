import React, { useContext, useState } from 'react';
import Info from './panels/info';
import AddressClass from '../components/panels/address-class';
import AddressDocuments from '../components/panels/address-documents';
import Photo from '../components/panels/photo';
import ContactPerson from '../components/panels/contact-person';
import PlanTemplate from '../components/panels/plan';
import { ServiceContext } from '..';
import PublicationStatus from '../components/publication-status';
import { TrainingGroupData } from '../../../types/service';

const ServiceGroupDetails = () => {
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState<string | null>(null);
  const [plans, setPlans] = useState<TrainingGroupData[]>([]);

  return (
    <>
      {accessPanelEdit && (
        <PublicationStatus
          plans={plans}
          serviceStatusId={serviceData.serviceStatusId}
          editMode={editMode}
          educationType={serviceData.educationTypeId}
        />
      )}

      {(!editMode || editMode === 'info') && <Info setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'contact') && <ContactPerson setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'address-class') && (
        <AddressClass
          setEditModeParent={setEditMode}
          editCount="many"
          textForEmpty="Для публикации услуги необходимо указать адрес проведения занятий"
        />
      )}
      {(!editMode || editMode === 'address-documents') && (
        <AddressDocuments
          setEditModeParent={setEditMode}
          textForEmpty="Для публикации программы по уходу и присмотру за детьми школьного возраста необходимо указать хотя бы один адрес приема документов"
        />
      )}
      {(!editMode || editMode === 'plan') && <PlanTemplate setPlans={setPlans} editable />}
      {(!editMode || editMode === 'photo') && <Photo setEditModeParent={setEditMode} />}
    </>
  );
};

export default ServiceGroupDetails;
