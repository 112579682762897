import React, { ReactNode, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { SelectOptionType, SelectValueType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import { ServiceDataInfo } from '../../../../types/service';
import FormikInput from '../../../../components/formik/formik-input';

type Props = FieldProps<string> & {
  dictionaryFunc: () => Promise<SelectOptionType[]>;
  otherElement?: ReactNode;
  onlyOneDictionaryResult?: boolean;
  onChange?: (value: SelectValueType) => void;
};

export const Dictionary: React.FC<Props> = ({
  name,
  editMode,
  value,
  label,
  isNotTableMain,
  disabled,
  dictionaryFunc,
  parent,
  otherElement,
  onlyOneDictionaryResult,
  required,
  onChange,
}) => {
  const nameLabel = parent ? `${parent}.${name}Name` : `${name}Name`;
  const nameValue = parent ? `${parent}.${name}Id` : `${name}Id`;

  const [fieldLabel, , setFieldLabel] = useField(nameLabel);
  const [fieldValue, , setFieldVal] = useField(nameValue);
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const { setFieldValue } = useFormikContext<ServiceDataInfo>();

  useEffect(() => {
    const fetch = async () => {
      const result = await dictionaryFunc();
      setOptions(result);
    };

    if (editMode) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (options.length) {
      const elem = options.find((item) => item.value === fieldValue.value);

      if (elem) {
        setFieldValue(nameLabel, elem.label);
        setFieldValue(nameValue, elem.value);
      } else {
        setFieldValue(nameLabel, options[0].label);
        setFieldValue(nameValue, options[0].value);
      }
    }
  }, [fieldValue.value, nameLabel, nameValue, options, setFieldValue]);

  return (
    <Field
      label={label}
      editMode={editMode}
      otherElement={otherElement}
      isNotTableMain={isNotTableMain}
      value={value ?? fieldLabel.value}
      required={required}
    >
      <FormikFormGroup required label="" name={nameLabel}>
        {!onlyOneDictionaryResult ? (
          <FormikSelect
            name={nameValue}
            size="small"
            isSearchable={false}
            disabled={disabled}
            options={options}
            hideClearIndicator
            placeholder="Выберите"
            selectedValue={(option: SelectOptionType) => {
              setFieldLabel.setValue(option.label);
              setFieldVal.setValue(option.value);
              onChange && onChange(option.value);
            }}
            defaultValue={{ label: fieldLabel.value, value: fieldValue.value }}
          />
        ) : (
          <FormikInput name={nameValue} value={fieldLabel.value} size="small" disabled />
        )}
      </FormikFormGroup>
    </Field>
  );
};
