import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

type SearchKeyPropsType = {
  children: any;
};

type SearchKeyValuesType = {
  organizationKey: number;
  serviceKey: number;
  placeServiceKey: number;
  trainingGroupKey: number;
  classificatorEKUKey: number;
  programmLevelKey: number;
  serviceClassKey: number;
  privilegeCategoryKey: number;
  privilegeStatusKey: number;
  statementChildKey: number;
  testDateKey: number;
  yearOfTrainingKey: number;
  setOrganizationKey: Dispatch<SetStateAction<number>>;
  setServiceKey: Dispatch<SetStateAction<number>>;
  setPlaceServiceKey: Dispatch<SetStateAction<number>>;
  setTrainingGroupKey: Dispatch<SetStateAction<number>>;
  setClassificatorEKUKey: Dispatch<SetStateAction<number>>;
  setProgrammLevelKey: Dispatch<SetStateAction<number>>;
  setServiceClassKey: Dispatch<SetStateAction<number>>;
  setPrivilegeCategoryKey: Dispatch<SetStateAction<number>>;
  setPrivilegeStatusKey: Dispatch<SetStateAction<number>>;
  setStatementChildKey: Dispatch<SetStateAction<number>>;
  setTestDateKey: Dispatch<SetStateAction<number>>;
  setYearOfTrainingKey: Dispatch<SetStateAction<number>>;
};

export const SearchKeyContext = createContext<SearchKeyValuesType>({} as SearchKeyValuesType);

const SearchKey: React.FC<SearchKeyPropsType> = ({ children }) => {
  const [organizationKey, setOrganizationKey] = useState<number>(0);
  const [serviceKey, setServiceKey] = useState<number>(0);
  const [placeServiceKey, setPlaceServiceKey] = useState<number>(0);
  const [trainingGroupKey, setTrainingGroupKey] = useState<number>(0);
  const [classificatorEKUKey, setClassificatorEKUKey] = useState<number>(0);
  const [programmLevelKey, setProgrammLevelKey] = useState<number>(0);
  const [serviceClassKey, setServiceClassKey] = useState<number>(0);
  const [privilegeCategoryKey, setPrivilegeCategoryKey] = useState<number>(0);
  const [privilegeStatusKey, setPrivilegeStatusKey] = useState<number>(0);
  const [statementChildKey, setStatementChildKey] = useState<number>(0);
  const [testDateKey, setTestDateKey] = useState<number>(0);
  const [yearOfTrainingKey, setYearOfTrainingKey] = useState<number>(0);

  const values: SearchKeyValuesType = useMemo(
    () => ({
      organizationKey,
      serviceKey,
      placeServiceKey,
      trainingGroupKey,
      classificatorEKUKey,
      programmLevelKey,
      serviceClassKey,
      privilegeStatusKey,
      privilegeCategoryKey,
      statementChildKey,
      testDateKey,
      yearOfTrainingKey,
      setOrganizationKey,
      setServiceKey,
      setPlaceServiceKey,
      setTrainingGroupKey,
      setClassificatorEKUKey,
      setProgrammLevelKey,
      setServiceClassKey,
      setPrivilegeCategoryKey,
      setPrivilegeStatusKey,
      setStatementChildKey,
      setTestDateKey,
      setYearOfTrainingKey,
    }),
    [
      organizationKey,
      serviceKey,
      placeServiceKey,
      trainingGroupKey,
      classificatorEKUKey,
      programmLevelKey,
      serviceClassKey,
      privilegeStatusKey,
      privilegeCategoryKey,
      statementChildKey,
      testDateKey,
      yearOfTrainingKey,
      setOrganizationKey,
      setServiceKey,
      setPlaceServiceKey,
      setTrainingGroupKey,
      setClassificatorEKUKey,
      setProgrammLevelKey,
      setServiceClassKey,
      setPrivilegeCategoryKey,
      setPrivilegeStatusKey,
      setStatementChildKey,
      setTestDateKey,
      setYearOfTrainingKey,
    ]
  );

  return <SearchKeyContext.Provider value={values}>{children}</SearchKeyContext.Provider>;
};

export default SearchKey;
