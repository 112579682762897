import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { OptionFilter } from '../../../../types/reports';
import FormikCheckboxGroup from '../../../../components/formik/formik-checkbox-group';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';

// Используется в нижней части фильра, т.е в основном это чекбокс

type Props = {
  filterData: any;
};

const FilterBottom: React.FC<Props> = ({ filterData }) => {
  return filterData.filterParams ? (
    <>
      {filterData?.filterList.map((item: OptionFilter) => {
        return (
          <div key={item.key}>
            {item.key === 14 && item.visible ? (
              <div key={item.key}>
                <Push size={16} />
                <FormikCheckboxGroup
                  key={item.key}
                  label="Учитывать сведения только о детях, сведения о которых найдены в Реестре Контингента"
                  name={item.parameterField}
                  size="small"
                >
                  <FormikCheckbox name={item.parameterField} />
                </FormikCheckboxGroup>
              </div>
            ) : null}

            {/* Исключить тестовые организации */}
            {item.key === 30 && item.visible ? (
              <div key={item.key}>
                <Push size={16} />
                <FormikCheckboxGroup
                  key={item.key}
                  label={item.parameterCaption}
                  name={item.parameterField}
                  size="small"
                >
                  <FormikCheckbox name={item.parameterField} />
                </FormikCheckboxGroup>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  ) : null;
};

export default FilterBottom;
