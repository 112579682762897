import { AppState } from './types/state';
import { moduleName as appModule } from './ducks/app';
import { moduleName as userProfile } from './ducks/user-profile';
import { moduleName as systemSettings } from './ducks/system-settings';
import { moduleName as aupdOrganizations } from './ducks/aupd-organizations';

export const isAuthorizedSelector = (state: AppState) => state[appModule].authorized;

/** Дает доступ к профилю пользователя в хранилище состояния */
export const userProfileSelector = (state: AppState) => state[userProfile];

export const userSettingsSelector = (state: AppState) => state[systemSettings];

export const aupdOrganizationsSelector = (state: AppState) => state[aupdOrganizations];
