import React, { useContext } from 'react';
import { TabPill, TabPillGroup, Push } from '@mosru/esz_uikit';
import { ServiceClassTableContext } from '../../utils';

const TabsGroup: React.FC = () => {
  const { setActive, setSelected, setOpenDetails, active, setPageNum } = useContext(ServiceClassTableContext);

  const onChangeActiveTab = (active: boolean) => {
    setActive(active);
    setPageNum(0);
    setSelected([]);
    setOpenDetails(false);
  };

  return (
    <div className="flex items-center">
      Состав группы
      <Push orientation="horizontal" size={16} />
      <div className="font-weight-base">
        <TabPillGroup>
          <TabPill
            label="Зачислены"
            size="small"
            active={active}
            onClick={() => {
              onChangeActiveTab(true);
            }}
          />
          <TabPill
            label="Отчислены / Переведены"
            active={!active}
            size="small"
            onClick={() => {
              onChangeActiveTab(false);
            }}
          />
        </TabPillGroup>
      </div>
    </div>
  );
};

export default TabsGroup;
