import React from 'react';
import { Heading, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconLogin } from '../../assets/images/login/logo.svg';
import ImageMonitor from '../../assets/images/loader/monitor.gif';

const LoginLayoutLoader = () => {
  return (
    <div className="login-layout">
      <div className="login-layout__header">
        <IconLogin />
      </div>
      <div className="login-layout__body">
        <div className="text-center">
          <img src={ImageMonitor} alt="" className="login-layout-monitor" />
          <Heading as="h3" label="Производим авторизацию пользователя" />
          <Push size={12} />
          <div className="font-size-base">Нужно немного подождать</div>
        </div>
      </div>
      <div className="login-layout__footer">
        <div className="login-layout-footer-nav">
          <div className="login-layout-footer-nav__item">+7 (495) 539-38-38</div>
          <div className="login-layout-footer-nav__item">dop_hd@mos.ru</div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayoutLoader;
