import { useField, useFormikContext } from 'formik';
import { useContext } from 'react';
import * as Popper from 'popper.js';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import { FieldProps } from '../../../../components/fields/field';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import { formatDate, getDate } from '../../../../lib/utils/date';
import { checkMaskDocNumber, checkMaskDocSeries } from '../../../../lib/utils/validation';
import { DependentDocumentContext } from './dependent-document';
import { documentTypeField } from './document';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';

type DocumentNumberProps = Omit<
  FieldProps<any> & {
    disabled?: boolean;
    parent?: string;
    showErrorImmediately?: boolean;
    calendarPosition?: Popper.Placement;
  },
  'name'
>;

export const docNumberSeriesField = 'series';
export const docNumberField = 'number';
export const docNumberDateOfIssueField = 'dateOfIssue';

const DocumentNumber = ({
  parent = '',
  label = 'Серия, номер, дата выдачи',
  editMode,
  required,
  disabled = false,
  defaultValue,
  showErrorImmediately = false,
  calendarPosition,
}: DocumentNumberProps) => {
  const numberField = buildFormFieldName(parent, docNumberField);
  const seriesField = buildFormFieldName(parent, docNumberSeriesField);
  const issueField = buildFormFieldName(parent, docNumberDateOfIssueField);
  const documentTypeIdField = buildFormFieldName(parent, documentTypeField);

  const [number] = useField(numberField);
  const [series] = useField(seriesField);
  const [issue] = useField(issueField);
  const [documentTypeId] = useField(documentTypeIdField);

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { docSeriesKey, docNumberKey, docDateKey } = useContext(DependentDocumentContext);

  const isBirthRecord = documentTypeId.value === DocumentTypeEnum.BirthRecord;

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        {isBirthRecord ? 'Номер, дата выдачи' : label}{' '}
        {editMode && required ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        <div className="table-data-grid-3">
          {editMode ? (
            <>
              {isBirthRecord ? null : (
                <div>
                  <FormikFormGroup required label="" name={seriesField} showErrorImmediately={showErrorImmediately}>
                    <FormikInput
                      key={docSeriesKey}
                      size="small"
                      mask={checkMaskDocSeries(documentTypeId.value)}
                      placeholder="Введите серию..."
                      name={seriesField}
                      disabled={disabled}
                      showClearIndicator={Boolean(series.value)}
                    />
                  </FormikFormGroup>
                </div>
              )}

              <div>
                <FormikFormGroup required label="" name={numberField} showErrorImmediately={showErrorImmediately}>
                  <FormikInput
                    key={docNumberKey}
                    mask={!isBirthRecord && checkMaskDocNumber(documentTypeId.value as number)}
                    size="small"
                    placeholder="Введите номер..."
                    name={numberField}
                    disabled={disabled}
                    showClearIndicator={Boolean(number.value)}
                  />
                </FormikFormGroup>
              </div>
              <div>
                <FormikFormGroup required label="" name={issueField} showErrorImmediately={showErrorImmediately}>
                  <FormikDatePicker
                    key={docDateKey}
                    placeholder="ДД.ММ.ГГГГ"
                    size="small"
                    name={issueField}
                    startDate={issue.value ? getDate(issue.value) : undefined}
                    popperPlacementRight
                    disabled={disabled}
                    popperPlacement={calendarPosition}
                    onChangeValue={(val) => {
                      !val && isBirthRecord && setFieldValue(numberField, '');
                      setTimeout(() => {
                        setFieldTouched('docDate', true);
                        !val && isBirthRecord && setFieldTouched(numberField, true);
                      }, 0);
                    }}
                  />
                </FormikFormGroup>
              </div>
            </>
          ) : (
            <>
              {!isBirthRecord && (
                <div className="table-data__group">
                  <div className="table-data__label">Серия</div>
                  <div className="table-data__body word-break-all">{(series.value || defaultValue) ?? '—'}</div>
                </div>
              )}
              <div className="table-data__group">
                <div className="table-data__label">Номер</div>
                <div className="table-data__body word-break-all">{(number.value || defaultValue) ?? '—'}</div>
              </div>
              <div className="table-data__group">
                <div className="table-data__label">Дата выдачи</div>
                <div className="table-data__body word-break-all">
                  {(issue.value ? formatDate(issue.value) : defaultValue) ?? '—'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentNumber;
