import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceData, ServiceDataInfo } from '../../../../types/service';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessVedomst } from '../../../../mock-data/access-enum';

type Props = FieldProps<number> & { width: 80 | 'auto' };

export const HoursPerWeek = ({
  width,
  isNotTableMain,
  disabled,
  label = 'Часов в неделю',
  editMode,
  value,
  otherElement,
  parent,
  name,
  required,
}: Props) => {
  const { setFieldValue, values } = useFormikContext<ServiceDataInfo & ServiceData>();
  const [key, setKey] = useState(0);

  const inputName = parent ? `${parent}.${name}` : 'hoursPerWeek';

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isDkgm = useMemo(() => userProfile.vedomstvoId === accessVedomst.Dkgm, [userProfile.vedomstvoId]);

  useEffect(() => {
    // костыль, так как при первом render внутри input state: 0
    if (values && values?.info?.hoursPerWeek === 1 && isDkgm) {
      setKey(Math.random());
    }
  }, [inputName, isDkgm, setFieldValue, values]);

  useEffect(() => {
    (() => {
      const editValue = () => {
        setFieldValue(inputName, 14);
        setKey(Math.random());
      };

      return parent ? values.info?.hoursPerWeek > 14 && editValue() : values.hoursPerWeek > 14 && editValue();
    })();
  }, [name, inputName, parent, setFieldValue, values]);

  return (
    <Field
      required={required}
      isNotTableMain={isNotTableMain}
      label={label}
      editMode={editMode}
      value={value}
      otherElement={otherElement}
    >
      <div style={{ width }}>
        <FormikFormGroup required label="" name={inputName}>
          <FormikInput
            number
            key={key}
            maxLength={2}
            maxValue={14}
            minValue={0}
            size="small"
            placeholder="0"
            name={inputName}
            disabled={disabled}
          />
        </FormikFormGroup>
      </div>
    </Field>
  );
};
