import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType, Switcher, Push } from '@mosru/esz_uikit';
import { regCityOptions } from '../../../../../../lib/utils/learners';
import FormikInput from '../../../../../../components/formik/formik-input';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { checkAddress, downloadMoscowAddresses } from '../utils';
import { Learner } from '../../../../../../types/learners';

type Props = {
  editMode: boolean;
  currentAddressRegistration: SelectOptionType | null;
  setCurrentAddressRegistration: (option: SelectOptionType) => void;
};

export const AddressRegistration = ({ editMode, setCurrentAddressRegistration, currentAddressRegistration }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  return (
    <div className="table-data__item table-data__group table-data__group--center">
      <div className="table-data__label table-data__label--main">Адрес регистрации</div>
      <div className="table-data__body">
        {editMode ? (
          <>
            <Switcher size="small" btnWidthAuto>
              {regCityOptions.map((item) => (
                <button
                  key={item.value}
                  type="button"
                  onClick={() => {
                    setFieldValue('regCity', item.value);
                    setCurrentAddressRegistration({ label: '', value: 0 });

                    if (item.value === 'other') {
                      setFieldValue('regAddress', null);
                    } else {
                      setFieldValue('regAddressId', null);
                    }
                  }}
                  className={values.regCity === item.value ? 'active' : ''}
                >
                  {item.label}
                </button>
              ))}
            </Switcher>
            <Push size={10} />

            {values.regCity === 'other' ? (
              <FormikInput size="small" placeholder="Укажите адрес регистрации" name="regAddress" />
            ) : (
              <FormikFormGroup name="regAddressId" label="">
                <FormikSelect
                  name="regAddressId"
                  size="small"
                  selectedValue={setCurrentAddressRegistration}
                  loadOptions={async (query) => downloadMoscowAddresses(query)}
                  isSearchable
                  defaultValue={currentAddressRegistration?.value ? currentAddressRegistration : null}
                  options={[]}
                  placeholder="Укажите адрес регистрации"
                />
              </FormikFormGroup>
            )}
          </>
        ) : (
          checkAddress(values.regAddress ?? '', '')
        )}
      </div>
    </div>
  );
};
