import React from 'react';
import { Tooltip, Push } from '@mosru/esz_uikit';
import { ReactComponent as Success } from '../../../../../assets/images/icons/success.svg';
import { ReactComponent as Error } from '../../../../../assets/images/icons/error.svg';

type Props = {
  childName: string;
  childDateOfBirth: string;
  hasInContingent: boolean;
};

const RequestStatusName: React.FC<Props> = ({ childName, hasInContingent, childDateOfBirth }) => {
  return (
    <>
      {childName}
      <Push size={4} />
      <div className="color-gray-dark">{childDateOfBirth}</div>
      <Tooltip
        component={() => <div className="flex justify-center">{hasInContingent ? <Success /> : <Error />}</div>}
        position="top"
        text={
          hasInContingent ? (
            <div style={{ textAlign: 'center' }}>
              Сведения найдены <br /> в Реестре контингента
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              Сведения в Реестре <br /> контингента отсутствуют
            </div>
          )
        }
      />
    </>
  );
};

export default RequestStatusName;
