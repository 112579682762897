import React, { useEffect } from 'react';
import { useField } from 'formik';
import { formatPhoneNumber } from '../../lib/utils/format-number';
import { phoneMask } from '../../lib/utils/mask';
import Field, { FieldProps } from './field';
import FormikInput from '../formik/formik-input';
import FormikFormGroup from '../formik/formik-form-group';

type PhoneProps = FieldProps<string> & { showErrorImmediately?: boolean };

const Phone = ({
  label = 'Телефон',
  defaultValue = 'Не представлен',
  name,
  editMode,
  required,
  showErrorImmediately = false,
}: PhoneProps) => {
  const [field, , helpers] = useField(name);

  useEffect(() => {
    if (editMode) {
      helpers.setValue(formatPhoneNumber(field.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={field.value}
      defaultValue={defaultValue}
      size={3}
    >
      <FormikFormGroup required label="" name={name} showErrorImmediately={showErrorImmediately}>
        <FormikInput size="small" mask={phoneMask} placeholder="+7 (XXX) XXX-XX-XX" name={name} />
      </FormikFormGroup>
    </Field>
  );
};

export default Phone;
