import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { initialPupilDocument } from '../../../mock-data/learners';
import LearnerFormInfo from './form-info';
import LearnerGeneralEducation from './general-education';
import LearnerRepresentatives from './representatives/index';
import learnerApi from '../../../lib/api/learner';
import ListStatements from './list-statements';
import { Learner } from '../../../types/learners';
import LearnerDocumentation from './documents';
import LearnerPrivilegies from './privelegies';
import { editName, showSuccessNotification } from '../../../lib/utils/learners';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';

type Props = {
  isAdminEdit: boolean;
  reloadRequest: boolean;
  deleteLearner: boolean;
  setPeopleId: (value: number) => void;
  setLearnerName: (value: string) => void;
  setHeadingLoading: (value: boolean) => void;
  setExtendEntityGuid: (value: string) => void;
  setDeleteLearners: (value: boolean) => void;
  setReloadRequest: (value: boolean) => void;
  setPeopleIsArchive: (value: boolean) => void;
};

const LearnerDetails = ({
  setPeopleId,
  reloadRequest,
  deleteLearner,
  setDeleteLearners,
  setLearnerName,
  setExtendEntityGuid,
  setHeadingLoading,
  setReloadRequest,
  setPeopleIsArchive,
  isAdminEdit,
}: Props) => {
  const [editMode, setEditMode] = useState<string | null>(null);

  const { id } = useParams<Record<string, string | undefined>>();

  const [loading, setLoading] = useState<boolean>(true);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [userData, setUserData] = useState<Learner.Data>({
    id: 0,
    sexId: 0,
    snils: '',
    birthDate: '',
    lastName: '',
    firstName: '',
    middleName: '',
    isArchive: false,
    contingentGuid: '',
    factAddress: '',
    regAddress: '',
    representatives: [],
    pupilDocument: [initialPupilDocument],
  });

  const fetch = useCallback(() => {
    (async () => {
      if (id) {
        const learner = await learnerApi.getLearner({ pupilId: id, showExcluded: true });

        setPeopleId(learner.id);
        setLoading(false);
        setUserData(learner);
        setReloadRequest(false);
        setHeadingLoading(false);
        setPeopleIsArchive(learner.isArchive);
        setLearnerName(editName(learner.lastName, learner.firstName, learner.middleName));

        learner.extendEntityGuid && setExtendEntityGuid(learner.extendEntityGuid);
      }
    })();
  }, [id, setPeopleId, setReloadRequest, setHeadingLoading, setPeopleIsArchive, setLearnerName, setExtendEntityGuid]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const updateLearn = (value: boolean) => {
    (async () => {
      if (id && value) {
        const learner = await learnerApi.getLearner({ pupilId: id });
        setUserData(learner);
      }
    })();
  };

  useEffect(() => {
    if (reloadRequest) {
      fetch();
    }
  }, [fetch, reloadRequest]);

  const deleteFetch = useCallback(async () => {
    if (id) {
      try {
        await learnerApi.deleteLearner({ id });

        showSuccessNotification();

        setDeleteLearners(false);
        setPeopleIsArchive(true);

        fetch();
      } catch (e) {}
    }
  }, [fetch, id, setPeopleIsArchive, setDeleteLearners]);

  useEffect(() => {
    if (deleteLearner) {
      deleteFetch();
    }
  }, [deleteFetch, deleteLearner]);

  return (
    <>
      {(!editMode || (editMode && editMode === 'info')) && (
        <LearnerFormInfo
          loading={loading}
          userData={userData}
          isAdminEdit={isAdminEdit}
          updateLearn={updateLearn}
          setLearnerName={setLearnerName}
          setEditModeParent={(value) => setEditMode(value ? 'info' : null)}
        />
      )}

      {id && !editMode && hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) && (
        <LearnerPrivilegies pupilId={id} />
      )}

      {!editMode && <LearnerGeneralEducation contingentGuid={userData.contingentGuid} />}

      {!editMode && <LearnerRepresentatives loading={loading} updateLearn={updateLearn} userData={userData} />}

      {id && !editMode && <ListStatements pupilId={id} />}

      {id && !editMode && hasAccessObjectAny(userProfile, [accessObject.PupilOP], accessAction.View) && (
        <LearnerDocumentation access={userData.access} pupilId={id} />
      )}
    </>
  );
};

export default LearnerDetails;
