import React, { useContext, useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  values: SearchRequestsInitialFormData;
  label: string;
  placeholder: string;
};

const FieldTrainingGroup: React.FC<Props> = ({ values, label, placeholder }) => {
  const [trainingGroupData, setTrainingGroupData] = useState<SelectOptionType[]>([]);
  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      setTrainingGroupData(await serviceTemplateApi.getTrainingGroups({ serviceId }));
    };

    if (values.serviceId) {
      fetchTrainGroup(values.serviceId);
    } else {
      setTrainingGroupData([]);
    }
  }, [values.serviceId]);

  const { trainingGroupKey } = useContext(SearchKeyContext);

  const [, , helpers] = useField('trainingGroupId');
  const [, , helpersName] = useField('trainingGroupName');

  const onChange = (option: SelectOptionType) => {
    helpers.setValue(option?.value);
    helpersName.setValue(option?.label);
  };

  return (
    <>
      <Push size={16} />
      <FormikFormGroup name="trainingGroupId" label={label}>
        <FormikSelect
          showClearIndicator
          key={trainingGroupKey}
          disabled={!values.serviceId}
          name="trainingGroupId"
          size="small"
          options={trainingGroupData}
          defaultValue={
            values.trainingGroupId ? { label: values.trainingGroupName ?? '', value: values.trainingGroupId } : null
          }
          placeholder={placeholder}
          selectedValue={onChange}
        />
      </FormikFormGroup>
    </>
  );
};

export default FieldTrainingGroup;
