import React, { useState } from 'react';
import { routes } from '../../config/constants';
import PageHeading from '../../components/header/page-heading';
import { replaceHistoryState } from '../../lib/utils';
import { historyState } from '../../mock-data/history-state';
import { EventParamsFormData } from '../../types/events';
import Params from './params';
import List from './list';
import { initialEventParams } from './utils';

const Events = () => {
  const [search, setSearch] = useState<EventParamsFormData | undefined>(
    window.history.state[historyState.search] ? undefined : initialEventParams
  );

  const handleChangeSearch = (values: EventParamsFormData) => {
    setSearch(values);
    replaceHistoryState({ [historyState.search]: values });
  };

  return (
    <>
      <PageHeading
        title="Реестр уведомлений"
        sections={[{ title: 'Главная', link: routes.main }, { title: 'Реестр уведомлений' }]}
        buttonBack
        routeBack={routes.main}
      />
      <Params params={search} onChangeParams={handleChangeSearch} />
      {!!search && <List search={search} />}
    </>
  );
};

export default Events;
