import React, { useContext } from 'react';
import { Tooltip, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';
import { ServiceContext } from '../../../index';
import { getDayOfTheWeekFromString } from '../../../../../lib/utils/time-table';

const ViewAddressDocuments: React.FC = () => {
  const { serviceData } = useContext(ServiceContext);

  return (
    <Table
      data={serviceData.itemWork.list
        .filter((i) => i.isSelected)
        .map((i, number) => {
          return { ...i, number: number + 1 };
        })}
      hideSort
      overflow
      columns={[
        {
          dataIndex: 'number',
          title: '№',
          render: (item: any) => item.number,
          width: '40px',
        },
        {
          dataIndex: 'isAddressArchive',
          title: '',
          render: (item: any) =>
            item.isAddressArchive && (
              <Tooltip
                component={() => <IconInfo />}
                position="right"
                text={
                  <div className="text-center" style={{ maxWidth: '203px' }}>
                    Адрес является архивным и более не используется организацией в качестве места приема документов
                  </div>
                }
              />
            ),
        },
        {
          dataIndex: 'name',
          title: 'Наименование организации',
          render: (item: any) => item.fullName,
          width: '45%',
        },
        {
          dataIndex: 'address',
          title: 'Адрес',
          render: (item: any) => item.fullAddress,
          width: '30%',
        },
        {
          dataIndex: 'schedule',
          title: 'Время работы',
          render: (item: any) =>
            getDayOfTheWeekFromString(item.timetable).map((item: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`schedule_${index}`}>{item}</div>
            )),
          width: '20%',
        },
      ]}
    />
  );
};

export default ViewAddressDocuments;
