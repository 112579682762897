import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Input, Panel, Loader, Push, SimpleTable as Table, FormGroup } from '@mosru/esz_uikit';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconUp } from '../../../../assets/images/icons/arrow-up.svg';
import { ReactComponent as IconDown } from '../../../../assets/images/icons/arrow-down.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../../index';
import { ServiceData, StageList } from '../../../../types/service';
import { MAX_STAGE_NAME_LENGTH } from '../../utils';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  serviceData: ServiceData;
};

const Stage: React.FC<Props> = ({ setEditModeParent, setFieldValue, serviceData }) => {
  const { updateService, accessPanelEdit } = useContext(ServiceContext);
  const [optionsState, setOptionsState] = useState(serviceData?.stage?.list ?? []);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [loading, setLoading] = useState(false);

  const checkEditable =
    !editMode && setEditModeParent && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitHandler = async () => {
    setLoading(true);
    try {
      await serviceTemplateApi.updateStages(serviceData.id, { ...serviceData.stage, list: optionsState });
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(`stage`, {
        educationTypeId: serviceData?.info?.educationTypeId,
        serviceId: serviceData?.info?.serviceId,
        list: optionsState,
      } as StageList);
    }
  }, [setFieldValue, optionsState, serviceData?.info?.educationTypeId, serviceData?.info?.serviceId]);

  const columns = useMemo(
    () =>
      editMode
        ? [
            {
              dataIndex: 'orderNumber',
              title: '№',
              render: (item: any) => {
                return <div className="table-row-item-height flex items-center">{item.orderNumber}</div>;
              },
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование ',
              render: (item: any) => {
                const error =
                  item.name.length > MAX_STAGE_NAME_LENGTH
                    ? `Должно содержать не более ${MAX_STAGE_NAME_LENGTH} символов`
                    : undefined;

                return (
                  <div className="flex items-start">
                    <div className="flex-auto">
                      <FormGroup label="" error={error}>
                        <Input
                          error={error}
                          size="small"
                          value={item.name}
                          onChange={({ target }) => {
                            const newOptionsState = optionsState.map((entry) =>
                              entry.orderNumber === item.orderNumber ? { ...entry, name: target.value } : entry
                            );
                            setOptionsState(newOptionsState);
                          }}
                        />
                      </FormGroup>
                    </div>
                    {optionsState.length > 1 && (
                      <>
                        <Push size={8} orientation="horizontal" />
                        <Button
                          iconLeft={() => <IconRemove />}
                          onClick={() => {
                            const filteredOptionsState = optionsState.filter(
                              (entry) => entry.orderNumber !== item.orderNumber
                            );
                            const newOptionsState = filteredOptionsState.map((entry) => {
                              if (entry.orderNumber > item.orderNumber) {
                                return { ...entry, orderNumber: entry.orderNumber - 1 };
                              } else {
                                return entry;
                              }
                            });
                            setOptionsState(newOptionsState);
                          }}
                          border
                          size="small"
                        />
                        <Push size={8} orientation="horizontal" />
                        <Button
                          iconLeft={() => <IconUp />}
                          border
                          size="small"
                          handleClick={() => {
                            let temporaryName = '';
                            let temporaryId: number | undefined = 0;
                            const newOptionsState = optionsState.map((entry) => {
                              if (entry.orderNumber === item.orderNumber - 1) {
                                temporaryName = entry.name;
                                temporaryId = entry.id;
                                return { ...entry, name: item.name, id: item.id };
                              } else if (entry.orderNumber === item.orderNumber) {
                                return { ...entry, name: temporaryName, id: temporaryId };
                              } else {
                                return entry;
                              }
                            });
                            setOptionsState(newOptionsState);
                          }}
                          disabled={!optionsState.find((findEntry) => findEntry.orderNumber === item.orderNumber - 1)}
                        />
                        <Push size={8} orientation="horizontal" />
                        <Button
                          iconLeft={() => <IconDown />}
                          border
                          size="small"
                          handleClick={() => {
                            const newOptionsState = optionsState.map((entry) => {
                              if (entry.orderNumber === item.orderNumber) {
                                const newEntry = optionsState.find(
                                  (findEntry) => findEntry.orderNumber === item.orderNumber + 1
                                );
                                return {
                                  ...entry,
                                  name: newEntry?.name,
                                  id: newEntry?.id,
                                };
                              } else if (entry.orderNumber === item.orderNumber + 1) {
                                return { ...entry, name: item.name, id: item.id };
                              } else {
                                return entry;
                              }
                            });
                            setOptionsState(newOptionsState);
                          }}
                          disabled={!optionsState.find((findEntry) => findEntry.orderNumber === item.orderNumber + 1)}
                        />
                      </>
                    )}
                  </div>
                );
              },
            },
          ]
        : [
            {
              dataIndex: 'orderNumber',
              title: '№',
              render: (item: any) => <div className="table-row-item-height flex items-center">{item.orderNumber}</div>,
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование',
              render: (item: any) => (
                <div className="table-row-item-height flex items-center overflow-wrap-anywhere">{item.name}</div>
              ),
            },
          ],
    [editMode, optionsState]
  );

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Этапы обучения
            {editMode && <span className="table-data__required" />}
            <span className="color-gray-dark">
              {' \u00A0'} {optionsState.length || '0'}
            </span>
          </>
        )}
        headingControl={() => {
          return editMode || !setEditModeParent ? (
            <button
              type="button"
              onClick={() => {
                const newOptionsState = [...optionsState];
                newOptionsState.push({
                  orderNumber: (optionsState[optionsState.length - 1]?.orderNumber || 0) + 1,
                  name: '',
                  id: 0,
                });
                setOptionsState(newOptionsState);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconPlus />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          ) : (
            checkEditable && (
              <button
                type="button"
                onClick={() => {
                  setEditMode(true);
                  setEditModeParent && setEditModeParent('stage');
                }}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <IconEdit />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
              </button>
            )
          );
        }}
      >
        <Table key={String(editMode)} data={optionsState} hideSort columns={columns} />
      </Panel>

      {editMode && setEditModeParent && !setFieldValue && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  onClick={() => {
                    setEditModeParent && setEditModeParent(null);
                    setEditMode(false);
                    setOptionsState(serviceData?.stage?.list ?? []);
                  }}
                  border
                  primary
                  label="Отмена"
                />
                <Push size={12} orientation="horizontal" />
                <Button
                  disabled={optionsState.some(({ name }) => name.length > MAX_STAGE_NAME_LENGTH)}
                  submit
                  onClick={submitHandler}
                  primary
                  label="Сохранить"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Stage;
