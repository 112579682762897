import { SelectOptionType } from '@mosru/esz_uikit';
import { TypeOfAttendanceId, TypeOfAttendanceName } from '../../../mock-data/type-of-attendance';
import { EducationTypeEnum } from '../../../types/education-type';
import { SearchInitialFormData } from '../../../types/service';
import { AuthorizationData } from '../../../types/authorization-data';
import { RegistryTypeEnum } from '../../../mock-data/registry-type-enum';
import { hasGeneralAccess } from '../../../lib/utils';
import { generalAccess } from '../../../mock-data/access-enum';
import { selectServiceItemCallback } from '../../../lib/utils/service';

type AdditionalDataType = {
  [index: string]: {
    [index: string]: string | null;
  };
};

export const formData = (type: string, educationSumm: number): SearchInitialFormData => {
  return {
    query: '',
    vedomstvoId: null,
    organizationId: null,
    servicePlaceId: null,
    classificatorEKUId: null,
    programmLevelId: null,
    typeOfAttendanceId: null,
    statusId: null,
    parallelId: null,
    educationTypeId: educationSumm,
    isTemplate: type === RegistryTypeEnum.serviceTemplateType,
  };
};

export const installInitialData = (
  isAdmin: boolean,
  userProfile: AuthorizationData,
  educationTypeData: SelectOptionType[],
  visitTypeData: SelectOptionType[],
  statusesData: SelectOptionType[],
  type: string,
  educationSumm: number,
  eventId: number | null
) => {
  const additionalData: AdditionalDataType = {
    [RegistryTypeEnum.serviceEducationType]: {
      statusPlanReceptionId: null,
      statusPlanReceptionName: 'Все',
      periodOfStudyId: null,
      periodOfStudyName: 'Все',
    },
    [RegistryTypeEnum.serviceTemplateType]: {
      periodOfStudyId: null,
      periodOfStudyName: 'Все',
    },
  };

  const accessOrganization = isAdmin || hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);
  const educationTypeId = isAdmin ? educationSumm : (educationTypeData[0]?.value as number);
  const isVirtualAssistantEducation = educationTypeId === EducationTypeEnum.VirtualAssistantEducation;

  const typeOfAttendance = {
    typeOfAttendanceId: visitTypeData?.length
      ? (visitTypeData[0]?.value as number)
      : isVirtualAssistantEducation
      ? TypeOfAttendanceId.OnlineCircle
      : null,
    typeOfAttendanceName: visitTypeData?.length
      ? visitTypeData[0]?.label
      : isVirtualAssistantEducation
      ? TypeOfAttendanceName.OnlineCircle
      : 'Все',
  };

  return {
    ...formData,
    educationTypeId,
    educationTypeName:
      isAdmin && !(RegistryTypeEnum.serviceEducationProgramType === type)
        ? 'Все'
        : (educationTypeData[0]?.label as string),
    organizationId: accessOrganization ? null : (userProfile?.organizationId as number),
    organizationName: accessOrganization ? '' : (userProfile?.organizationName as string),
    vedomstvoId: isAdmin ? null : (userProfile?.vedomstvoId as number),
    vedomstvoName: isAdmin ? '' : (userProfile?.vedomstvoName as string),
    statusId: selectServiceItemCallback ? 2 : statusesData?.length ? (statusesData[0]?.value as number) : 0,
    statusName: statusesData?.length ? (statusesData[0]?.label as string) : 'Актуальные',
    isTemplate: type === RegistryTypeEnum.serviceTemplateType,
    isWithFreeVolume: false,
    trainingGroupStatusId: 0,
    trainingGroupStatusName: 'Все',
    eventId,
    ...additionalData[type],
    ...typeOfAttendance,
  };
};
