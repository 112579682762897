import React, { useContext, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup, array as arrayYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ParallelOptionsByResponse } from '../../components/fields/parallel';
import { ServiceContext } from '../../index';
import { ServiceDataInfo } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { convertParallelInResponse, convertParallelInSelect, setGenderStartEndToBack } from '../../utils';
import TemplateInfo from './template-info';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const Info: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, organizationEdit, accessPanelEdit } = useContext(ServiceContext);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const initData = useMemo(() => {
    if (serviceData?.info) {
      return {
        ...serviceData.info,
        parallelList: convertParallelInSelect(serviceData?.info?.parallelList as ParallelOptionsByResponse[]),
      };
    } else {
      return {} as ServiceDataInfo;
    }
  }, [serviceData?.info]);

  const initialErrors = useInitialErrors(initData, infoValidationSchema());

  const submitForm = async (values: ServiceDataInfo) => {
    try {
      if (values?.serviceId) {
        await serviceTemplateApi.updateService(values.serviceId, {
          ...setGenderStartEndToBack(values),
          parallelList: convertParallelInResponse(values.parallelList as SelectOptionType[]),
        });
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        updateService();
      }
    } finally {
      setEditMode(false);
      setEditModeParent && setEditModeParent(null);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initData}
      validationSchema={infoValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceDataInfo>) => {
        const { isSubmitting, isValid, resetForm, handleSubmit } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TemplateInfo
              setEditModeParent={setEditModeParent}
              editMode={editMode}
              setEditMode={setEditMode}
              organizationEdit={organizationEdit}
              isDraft={serviceData.serviceStatusId === ServiceStatusEnum.Draft}
              accessEditable={accessPanelEdit}
            />
            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button load={isSubmitting} disabled={!isValid} primary submit label="Сохранить" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Info;

export const infoValidationSchema = () =>
  objectYup().shape({
    organizationId: stringYup().nullable().required('Выберите организацию'),
    name: stringYup().nullable().required('Введите наименование'),
    parallelList: arrayYup()
      .nullable()
      .min(1, 'Выберите параллель обучения')
      .required('Должен состоять из списка параллелей'),
    programmService: stringYup().nullable().required('Введите описание программы'),
  });
