import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Loader, Push } from '@mosru/esz_uikit';
import PrintList from './print-list';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ServiceData } from '../../../types/service';
import { EducationTypeEnum } from '../../../types/education-type';
import { withErrorBoundary } from '../../../hoc/error-boundary';
import { sendReachGoal } from '../../../lib/metrica';

const ServicePrint = () => {
  const { id, type } = useParams<Record<string, string | undefined>>();
  const [service, setService] = useState<ServiceData | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getServiceClass = async () => {
      if (id) {
        const response = await serviceTemplateApi.getServiceById(id);
        setService(response);
        setLoading(false);
      }
    };
    getServiceClass();
    sendReachGoal('print');
  }, [id]);

  useEffect(() => {
    switch (service?.educationTypeId) {
      case EducationTypeEnum.ProfessionalEducation:
        document.title = 'Образовательная программа';
        break;
      case EducationTypeEnum.ArtHouseEducation:
      case EducationTypeEnum.ChildrenEducation:
      case EducationTypeEnum.ChildrenNonDogmEducation:
        document.title = 'Описание услуги';
        break;
      default:
        break;
    }
  }, [service?.educationTypeId]);

  return loading ? (
    <div className="loader-container-wrap">
      <div className="loader-container">
        <Loader title="Загружаем форму печати" description="Нужно немного подождать" />
      </div>
    </div>
  ) : (
    <div className="container">
      <Push size={16} />
      <div className="flex justify-end btn-panel-print">
        <Button onClick={() => window.print()} primary size="small" label="Печать" />
      </div>

      <PrintList data={service} type={type} />
    </div>
  );
};

export default withErrorBoundary(ServicePrint);
