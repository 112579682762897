import React from 'react';
import { useField } from 'formik';
import { Toggle, ToggleType } from '@mosru/esz_uikit';

export type FormicToggleProps = ToggleType & {
  name: string;
};

const FormikToggle: React.FC<Omit<Omit<FormicToggleProps, 'labelId'>, 'onChange'>> = ({ name, ...props }) => {
  const [field, , helpers] = useField(name);

  return (
    <Toggle
      {...props}
      {...field}
      checked={field.value}
      labelId={name}
      onChange={(checked) => {
        helpers.setValue(checked);
      }}
    />
  );
};

export default FormikToggle;
