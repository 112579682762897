import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../../lib/utils';
import { historyState } from '../../../mock-data/history-state';
import TeachersSearch from './search';
import { SearchTeachersFormData } from '../../../types/teacher';
import TeachersTable from './table';

export const searchTeachersInitialFormData = {
  query: '',
  showArchive: false,
  isContactPerson: '',
  presentInNSI: '',
  disciplineId: null,
  organizationId: null,
};

const TeacherList = () => {
  const [search, setSearch] = useState<SearchTeachersFormData>(
    window.history.state[historyState.search] || searchTeachersInitialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchTeachersFormData) => {
    setSearch(values);
    replaceHistoryState({ [historyState.search]: values });
  };

  return (
    <>
      <PageHeading
        title="Преподаватели"
        sections={[{ title: 'Главная', link: routes.main }, { title: 'Преподаватели' }]}
      />
      <Push size={12} />
      <TeachersSearch submitForm={handleSubmitSearch} initialForm={search} />
      <Push size={12} />
      <TeachersTable search={search} />
    </>
  );
};

export default TeacherList;
