import React, { useContext, useMemo } from 'react';
import { Infobox, Select, Panel, Toggle, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { period } from '../../../../../components/schedule';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { convertScheduleToFront } from '../../../../../lib/utils/time-table';
import { ServiceContext } from '../../../index';
import { useGetOptions } from './use-get-options';
import { showText } from './index';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode: (value: boolean) => void;
  isTemplate?: boolean;
  title?: string;
  isTemplateOrService?: boolean | undefined;
};

const ViewServiceSchedule = ({ title, isTemplate, setEditModeParent, setEditMode, isTemplateOrService }: Props) => {
  const { serviceData, accessPanelEdit, userProfile } = useContext(ServiceContext);

  const scheduleData = useMemo(() => convertScheduleToFront(serviceData.schedule.list), [serviceData.schedule.list]);

  const checkEditable = setEditModeParent && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const { options, scheduleType } = useGetOptions(isTemplate, serviceData, userProfile);

  return (
    <Panel
      title={() => (
        <div className="flex items-center">
          {title}
          <Push orientation="horizontal" size={16} />
          {!!scheduleData.length && (
            <div className="font-weight-base">
              <Select
                name="scheduleType"
                onChange={() => {}}
                isSearchable={false}
                hideClearIndicator
                disabled
                options={options}
                value={scheduleType}
                size="small"
              />
            </div>
          )}
        </div>
      )}
      headingControl={() => (
        <>
          {checkEditable && (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
                setEditModeParent && setEditModeParent('schedule');
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconEdit />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          )}
          {isTemplate && (
            <>
              <Push orientation="horizontal" size={16} />
              <Toggle
                labelId=""
                size="xsmall"
                value={serviceData.schedule.canChangeSchedule}
                disabled
                onChange={() => {}}
              />
            </>
          )}
        </>
      )}
    >
      {serviceData.schedule.scheduleTypeOfServiceId === 2 ? (
        <div className="container">
          <Infobox fullWidth color="primary" text={showText(isTemplateOrService)} />
          <Push size={20} />
        </div>
      ) : (
        <>
          <div className="table-data-striped">
            {scheduleData
              .filter((s) => !s.dayOff)
              .map((item) => (
                <div key={item.name} className="table-data-striped__item">
                  <div className="table-data-striped__label">{item.name}</div>
                  <div className="table-data-striped__body">
                    {item.period === 3
                      ? `${item.from} — ${item.to}`
                      : `${period.find((p) => p.value === item.period)?.label ?? ''}`}
                  </div>
                </div>
              ))}
          </div>
          <Push size={8} />
        </>
      )}
    </Panel>
  );
};

export default ViewServiceSchedule;
