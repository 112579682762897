import { Button, ModalPanel, Modal } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const WarningModal = ({ show, onCloseHandler }: Props) => {
  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        alert
        onClose={onCloseHandler}
        modalTitle="Создание программы по уходу и присмотру"
        renderComponent={() => (
          <div>
            Внимание! Для Вашей организации не предусмотрено создание программы по уходу и присмотру за детьми школьного
            возраста согласно сведениям, полученным из АИС “Зачисление в ОУ”.
          </div>
        )}
        controls={() => <Button border label="Закрыть" onClick={onCloseHandler} />}
      />
    </Modal>
  );
};

export default WarningModal;
