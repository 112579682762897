import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, notify, Panel, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../../components/header/page-heading';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikToggleGroup from '../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../components/formik/formik-toggle';
import FIO from '../../components/fields/fio';
import Gender from '../../../../components/fields/gender';
import Document from '../../components/fields/document';
import DocumentNumber from '../../components/fields/document-number';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import Issued from '../../components/fields/issued';
import Snils from '../../components/fields/snils';
import Phone from '../../../../components/fields/phone';
import Email from '../../../../components/fields/email';
import SimpleInput from '../../../../components/fields/simple-input';
import ServiceDonm from '../../components/fields/service-donm';
import { EducationTypeEnum } from '../../../../types/education-type';
import { RequestData } from '../../../../types/requests';
import { bookingApi } from '../../../../lib/api/booking';
import requestsApi from '../../../../lib/api/requests';
import history from '../../../../history';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import Organization from '../organization';
import Mark from '../../components/fields/mark';
import SignModal from '../../../../components/sign-modal';
import TestAdmissionPlan from '../components/test-admission-plan';
import AdmissionPlan from '../components/admission-plan';
import {
  birthRecordIssued,
  childrenAssociation,
  dateOfBirth,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  nullableEmail,
  nullableRus,
  phone,
  scheduleOfTimetable,
  sexId,
  slot,
  snils,
  stringRequired,
} from '../../../../lib/utils/validation';
import { formatDate, formatTime } from '../../../../lib/utils/date';
import Info from '../info';
import DependentDocument from '../../components/fields/dependent-document';
import { RequestContext } from '../../../../lib/utils/requests';
import WrapperFields from '../../components/fields/wrapper-fields';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import WrapperFieldsDocument from '../../components/fields/wrapper-fields-document';
import { StatusChild } from '../../components/status-child';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';

type Props = {
  request?: RequestData;
  educationType: EducationTypeEnum;
  serviceInfoReadOnly?: boolean;
};

const DkgmRequestCreate: React.FC<Props> = ({ request, educationType, serviceInfoReadOnly }) => {
  const [isTestService, setIsTestService] = useState(false);
  const [signModal, showSignModal] = useState(false);
  const [signatureData, setSignatureData] = useState<RequestData>();
  const { useSign, adminEdit } = useContext(RequestContext);
  const back = () => history.push(routes.requests);

  useEffect(() => {
    setIsTestService(request?.trainingGroup?.isTestService ?? false);
  }, [request]);

  const initialValues = useMemo(() => {
    let values: RequestData | undefined = request;

    if (values && values?.child && values?.child.sexId === undefined) {
      values = {
        ...values,
        ...{
          child: { ...values.child, sexId: 1 },
        },
      } as RequestData;
    }
    return (
      values ??
      ({
        child: {
          sexId: 1,
          firstName: '',
          lastName: '',
          middleName: '',
          series: '',
          sexName: 'Мужской',
          documentTypeId: DocumentTypeEnum.BirthCertificate,
          documentTypeName: 'Свидетельство о рождении',
        },
        applicant: {
          documentTypeId: DocumentTypeEnum.Passport,
          documentTypeName: 'Паспорт гражданина РФ',
        },
      } as unknown as RequestData)
    );
  }, [request]);

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const submitForm = useCallback(
    async (formData: RequestData) => {
      const fetch = async () => {
        try {
          if (!formData.trainingGroup.bookingGuid) {
            const booking = await bookingApi.makePreBooking(formData.trainingGroup.slotId);
            formData.trainingGroup.bookingGuid = booking.bookingId;
            formData.trainingGroup.slotId = booking.slotId;
          }
          formData.educationTypeId = educationType;
          formData.child.isChild = true;
          formData.child.educationTypeId = educationType;
          formData.applicant.isChild = false;
          formData.applicant.educationTypeId = educationType;

          if (useSign) {
            setSignatureData(formData);
            showSignModal(true);
          } else {
            const requestId = await requestsApi.createRequest(formData);
            if (requestId) {
              await requestsApi.signRequest(requestId, educationType, null);
              history.push(generateLink(routes.request, { id: requestId }));
            } else {
              throw new Error('Не удалось сохранить заявление');
            }
          }
        } catch (ex: any) {
          notify.danger({
            data: {
              label: ex.data?.message,
              icon: true,
              close: true,
            },
          });
        }
      };
      fetch();
    },
    [educationType, useSign]
  );

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(new Date())}
              <Push size={20} orientation="horizontal" />
              Источник: Оператор
            </div>
          </>
        }
        buttonBack
        buttonBackFn={back}
        title="Новое заявление"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Новое заявление' },
        ]}
      />

      <Formik
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<RequestData>) => {
          const { handleSubmit, isSubmitting, isValid, values } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              {!serviceInfoReadOnly ? (
                <>
                  <Push size={12} />
                  <Panel title={() => 'Информация об услуге'}>
                    <div className="container">
                      <div className="table-data">
                        <ServiceDonm
                          parent="trainingGroup"
                          name="service"
                          editMode
                          educationType={educationType}
                          required
                          setIsTestService={setIsTestService}
                          label="Наименование услуги"
                        />
                      </div>
                    </div>
                  </Panel>

                  <Push size={12} />
                  {isTestService ? (
                    <TestAdmissionPlan />
                  ) : (
                    <AdmissionPlan name="trainingGroup.scheduleOfTimetableId" editMode />
                  )}
                </>
              ) : (
                <Info trainingGroup={values.trainingGroup} educationType={values.educationTypeId} />
              )}

              <Push size={12} />
              <Panel title={() => 'Информация о ребенке'}>
                <div className="container">
                  <StatusChild contingentLinkTypeId={values.contingentLinkTypeId} />
                  <Push size={24} />
                  <div className="table-data">
                    <DependentDocument>
                      <WrapperFields />
                      <Gender editMode required name="child.sex" />
                      <WrapperFieldsDocument />
                      <Snils name="child.snils" editMode disabled={!adminEdit} />
                    </DependentDocument>
                  </div>
                </div>
              </Panel>

              <Organization contingentGuid={values.contingentGuid} contingentLinkTypeId={values.contingentLinkTypeId} />

              <Push size={12} />
              <Panel title={() => 'Информация о заявителе'}>
                <DependentDocument>
                  <div className="container">
                    <div className="table-data">
                      <FIO label="ФИО заявителя" parent="applicant" required editMode />
                      <Phone
                        name="applicant.phone"
                        editMode
                        required
                        showErrorImmediately={!!initialValues.applicant?.phone}
                      />
                      <Email name="applicant.email" editMode />
                      <Document
                        editMode
                        dependentFields
                        parent="applicant"
                        name="documentType"
                        formType={FormTypeEnum.EditRequestApplicantDsitDkgm}
                      />
                      <Issued name="applicant.issued" editMode />
                      <DocumentNumber parent="applicant" editMode calendarPosition="top-end" />
                    </div>
                  </div>
                </DependentDocument>
              </Panel>

              {isTestService && (
                <>
                  <Push size={12} />
                  <Panel
                    title={() => (
                      <div className="flex items-center">
                        Результаты вступительных испытаний
                        <Push size={16} orientation="horizontal" />
                        <span className="font-weight-base">
                          <FormikToggleGroup
                            name="evaluation.isEvaluationCompleted"
                            size="small"
                            label="Испытания пройдены"
                          >
                            <FormikToggle name="evaluation.isEvaluationCompleted" size="small" />
                          </FormikToggleGroup>
                        </span>
                      </div>
                    )}
                  >
                    <div className="container">
                      <div className="table-data">
                        <SimpleInput
                          name="evaluation.testDate"
                          label="Дата и время испытаний"
                          value={
                            !values.evaluation?.testDate &&
                            !values.evaluation?.testTimeFrom &&
                            !values.evaluation?.testTimeTo
                              ? '—'
                              : `${
                                  values.evaluation.testDate ? formatDate(values.evaluation.testDate) : ''
                                } ${formatTime(values.evaluation.testTimeFrom)} - ${formatTime(
                                  values.evaluation.testTimeTo
                                )}`
                          }
                        />
                        <SimpleInput name="evaluation.addressEvaluation" label="Место проведения испытаний" />
                        <Mark
                          name="evaluation.MarkEvaluation"
                          label="Оценка"
                          editMode
                          disabled={!values.evaluation?.isEvaluationCompleted}
                        />
                        <SimpleTextArea
                          name="evaluation.CommentEvaluation"
                          label="Комментарий"
                          editMode
                          disabled={!values.evaluation?.isEvaluationCompleted}
                        />
                      </div>
                    </div>
                  </Panel>
                </>
              )}

              <Push size={12} />
              <Panel title={() => 'Уведомление о необходимости явки'}>
                <div className="container">
                  <div className="table-data">
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Уведомить заявителя</div>
                      <div className="table-data__body">
                        <FormikToggle name="approve.isEnrollmentApproved" size="small" />
                      </div>
                    </div>
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Комментарий</div>
                      <div className="table-data__body">
                        <FormikTextarea
                          name="approve.enrollmentApproveComment"
                          rows={2}
                          placeholder={!values.approve?.isEnrollmentApproved ? '' : 'Введите...'}
                          disabled={!values.approve?.isEnrollmentApproved}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>

              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button onClick={back} border primary label="Отмена" />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label={useSign ? 'Подписать' : 'Подтвердить'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <SignModal
        show={signModal}
        onCloseHandle={() => {
          showSignModal(false);
        }}
        ids={[{ id: 0 }]}
        getDataForSignHandle={async () => JSON.stringify(signatureData)}
        setSignedDataHandle={async (id, sign) => {
          if (signatureData) {
            try {
              const requestId = await requestsApi.createRequest(signatureData);
              if (requestId) {
                await requestsApi.signRequest(requestId, educationType, sign);
                history.push(generateLink(routes.request, { id: requestId }));
              } else {
                throw new Error('Не удалось сохранить заявление');
              }
            } catch {}
          }
        }}
      />
    </>
  );
};

export default DkgmRequestCreate;

export const childrenValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName: nullableRus,
  dateOfBirth,
  sexId,
  documentTypeId: stringRequired,
  series: documentSeries(true),
  number: documentNumber(true),
  dateOfIssue: dateOfIssue(true),
  snils,
  issued: birthRecordIssued,
});

export const applicantValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName: nullableRus,
  email: nullableEmail,
  phone,
  documentTypeId: stringRequired,
  dateOfIssue: dateOfIssue(false),
});

export const trainingGroupValidationSchema = objectYup().shape({
  serviceName: childrenAssociation,
  scheduleOfTimetableId: scheduleOfTimetable,
  slotId: slot,
});

export const getValidationSchema = () =>
  objectYup().shape({
    child: childrenValidationSchema,
    applicant: applicantValidationSchema,
    trainingGroup: trainingGroupValidationSchema,
  });
