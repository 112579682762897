import { Panel, Push, SelectOptionType } from '@mosru/esz_uikit';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { object } from 'yup';
import FormikSelect from '../../components/formik/formik-select';
import { LoaderCustom } from '../../components/loader-custom';
import useInitialErrors from '../../hooks/formik-initial-errors';
import FormikDatePicker from '../../components/formik/formik-datepicker';
import { transformSelectOptions } from '../../lib/utils';
import { historyState } from '../../mock-data/history-state';
import { useGetUserEventTypesQuery } from '../../store/event';
import { EventParamsFormData } from '../../types/events';
import { allTypes, initialEventParams } from './utils';

type ParamsProps = {
  params: EventParamsFormData | undefined;
  onChangeParams: (values: EventParamsFormData) => void;
};

const Params = ({ params, onChangeParams }: ParamsProps) => {
  const [eventTypes, setEventTypes] = useState<SelectOptionType[]>([]);
  const initialErrors = useInitialErrors(params, validationSchema);

  const { data, isFetching } = useGetUserEventTypesQuery();

  useEffect(() => {
    if (data) {
      const onlySubscription = data.filter(({ isSubscribeTo }) => isSubscribeTo);
      setEventTypes([allTypes, ...transformSelectOptions(onlySubscription, 'id', (item) => item.shortName)]);
    }
  }, [data]);

  useEffect(() => {
    if (eventTypes.length && !params) {
      const state = window.history.state[historyState.search];
      const hasCurrentEventType = !!state && eventTypes.some(({ value }) => value === state.selectedTypeId);
      onChangeParams(hasCurrentEventType ? state : initialEventParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTypes]);

  return (
    <Formik
      initialValues={params || initialEventParams}
      initialErrors={initialErrors}
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onChangeParams}
    >
      {({ handleSubmit }: FormikProps<EventParamsFormData>) => {
        const handleChangeForm = () => {
          handleSubmit();
        };

        return (
          <form onSubmit={handleSubmit}>
            <Push size={20} />
            <div className="container">
              <Panel borderRadius>
                {isFetching ? (
                  <LoaderCustom size={10} />
                ) : (
                  <>
                    <Push size={16} />
                    <div className="container container--sm">
                      <div className="table-data-grid-2-1">
                        <FormikSelect
                          name="selectedTypeId"
                          size="small"
                          options={eventTypes}
                          placeholder="Выберите..."
                          selectedValue={handleChangeForm}
                        />
                        <FormikDatePicker
                          popperPlacementRight
                          rangePicker
                          placeholder="Дата или интервал получения"
                          size="small"
                          name="dates"
                          onChangeValue={handleChangeForm}
                        />
                      </div>
                    </div>
                    <Push size={16} />
                  </>
                )}
              </Panel>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Params;

const validationSchema = object().shape({});
