import { FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { useContext, useRef } from 'react';
import { Button, RadioGroup, Radio, SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../../components/formik/formik-select';
import { TeacherType } from '../../../../../types/service-class';
import { ReactComponent as IconClose } from '../../../../../assets/images/icons/close.svg';
import lookupApi from '../../../../../lib/api/lookup';
import { ServiceClassContext } from '../../../service-class';
import { styleVars } from '../../../../../theme/vars';

type SelectTeacherProps = {
  label: string;
  data: TeacherType;
  editMode: boolean;
  required?: boolean;
  defaultValue?: string | SelectOptionType | null;
  child?: string;
  arrayHelpers: FieldArrayRenderProps;
  itemIndex: number;
  hasCreateServiceClass?: boolean;
};

export const personIdField = 'id';
export const personNameField = 'name';
export const isSupervisorField = 'isSupervisor';

const SelectTeacher: React.FC<SelectTeacherProps> = ({
  label = 'ФИО',
  editMode,
  required,
  defaultValue = '—',
  data,
  child,
  arrayHelpers,
  itemIndex,
  hasCreateServiceClass,
}) => {
  const { setValues, values } = useFormikContext<any>();
  const { serviceClassData } = useContext(ServiceClassContext);
  const includedData = useRef<number[]>(values?.teachers?.map((item: TeacherType) => item.id));

  const fieldPersonId = child ? `${child}.[${itemIndex}].${personIdField}` : personIdField;
  const fieldPersonName = child ? `${child}.[${itemIndex}].${personNameField}` : personNameField;
  const fieldIsSupervisor = child ? `${child}.[${itemIndex}].${isSupervisorField}` : isSupervisorField;

  const [personIdOptions, , helpersValue] = useField(fieldPersonId);
  const [personNameOptions, metaPersonName, helpersLabel] = useField(fieldPersonName);
  const [isSupervisorOptions] = useField(fieldIsSupervisor);

  const getOptions = async (query: string): Promise<SelectOptionType[]> => {
    const currentOptions = await lookupApi.getTeachers(query, serviceClassData?.organizationId);

    const filterOptions = currentOptions.filter((item) => !includedData.current?.includes(item.value as number));

    return includedData.current?.length > 0 ? filterOptions : currentOptions;
  };

  const setCurrentValue = async (option: SelectOptionType): Promise<void> => {
    helpersValue?.setValue(option?.value || '');
    helpersLabel?.setValue(option?.label || '');

    if (option?.value) {
      includedData.current = includedData.current
        ? [...includedData.current, Number(option?.value)]
        : [Number(option?.value)];
    } else {
      includedData.current = includedData.current?.filter((item: number) => item !== option?.value);
    }
  };

  return (
    <div className="table-data__item flex flex-column">
      <div className="table-data__group">
        <div className="table-data__label table-data__label--main">{label}</div>
        <div className="table-data__body table-data-grid-2-1 items-start">
          <div>
            <FormikSelect
              error={Boolean(metaPersonName.error) && metaPersonName.touched ? 'error' : ''}
              menuPlacement={hasCreateServiceClass || itemIndex > 2 ? 'top' : 'bottom'}
              name={fieldPersonName}
              size="small"
              isSearchable
              loadOptions={(query: string) => getOptions(query)}
              options={[]}
              placeholder="Начните вводить..."
              maxMenuHeight={150}
              defaultValue={
                personNameOptions?.value ? { value: personIdOptions?.value, label: personNameOptions?.value } : null
              }
              selectedValue={async (option: SelectOptionType) => {
                await setCurrentValue(option);

                // Чтобы избежать автоскролла вверх списка при выборе элемента из селекта
                window.scrollTo(0, document.body.clientHeight);
              }}
            />
            <div style={{ paddingTop: '8px', color: styleVars.brandDanger }}>
              {Boolean(metaPersonName.error) && metaPersonName.touched && metaPersonName.error}
            </div>
          </div>

          <div className="flex justify-between">
            <RadioGroup
              name={fieldIsSupervisor}
              labelId={fieldIsSupervisor}
              onChange={() => {
                setValues({
                  ...values,
                  teachers: values?.teachers?.map((item: TeacherType) =>
                    item.id === data?.id ? { ...item, isSupervisor: true } : { ...item, isSupervisor: false }
                  ),
                });
              }}
              label="Руководитель"
              checked={Boolean(isSupervisorOptions.value)}
            >
              {(props: any) => (
                <Radio {...props} labelId={fieldIsSupervisor} checked={Boolean(isSupervisorOptions.value)} />
              )}
            </RadioGroup>
            <Button
              iconLeft={() => <IconClose />}
              border
              size="small"
              handleClick={() => arrayHelpers.remove(itemIndex)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTeacher;
