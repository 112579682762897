import { useEffect, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import { dictionariesApi } from '../lib/api/dictionaries';

export const useGetDataDepartment = (fullName?: boolean): SelectOptionType[] => {
  const [vedomstvoData, setVedomstvoData] = useState<SelectOptionType[]>([]);

  // Загрузка справочника департаментов
  useEffect(() => {
    const fetchVedomstvo = async () => {
      const departments = await dictionariesApi.getVedomstvo(fullName || false);

      if (departments.length > 1) {
        setVedomstvoData([{ label: 'Все', value: null }, ...departments]);
      } else {
        setVedomstvoData(departments);
      }
    };

    fetchVedomstvo();
  }, [fullName, setVedomstvoData]);

  return vedomstvoData;
};
