import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import {
  SearchGroupTrainingInitialFormData,
  SearchGroupTrainingInitialFormDataAddition,
} from '../../../../types/service-class';
import { roleLabel } from '../../../../lib/utils/service-class';
import lookupApi from '../../../../lib/api/lookup';
import learnerApi from '../../../../lib/api/learner';
import { AuthorizationData } from '../../../../types/authorization-data';
import { usePrevious } from '../../../../hooks/use-previous';
import { serviceTemplateApi } from '../../../../lib/api/service-template';

type Props = {
  userProfile: AuthorizationData;
  setProgramModules: (value: SelectOptionType[]) => void;
};

export const FieldPlace: React.FC<Props> = ({ userProfile, setProgramModules }) => {
  const { setFieldValue, values } = useFormikContext<
    SearchGroupTrainingInitialFormData & SearchGroupTrainingInitialFormDataAddition
  >();

  const defaultValue =
    values.serviceId && values.serviceName ? { label: values.serviceName ?? '', value: values.serviceId } : null;

  const selectValue = (v: SelectOptionType) => {
    if (v) {
      setFieldValue('serviceName', v.label);
      (async () => {
        const response = await learnerApi.getDocumentProgramModules({ serviceId: v.value });
        setProgramModules(response);
      })();
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const service = values.serviceId && (await serviceTemplateApi.getServiceName(values.serviceId));
      setFieldValue('serviceName', service);
    };
    if (values.serviceId && !values.serviceName) {
      fetch();
    }
  }, [values.serviceId, values.serviceName, setFieldValue]);

  const [rerender, setRerender] = useState<number>(0);
  const prevEducationTypeId = usePrevious(values.educationTypeId);
  const prevServiceId = usePrevious(values.serviceId);

  useEffect(() => {
    // если мы меняем Тип образования, то обнуляем значение Наименование услуги
    if (values.educationTypeId !== prevEducationTypeId && prevServiceId) {
      setFieldValue('serviceName', null);
      setFieldValue('serviceId', null);
      setRerender(Math.random());
    }
  }, [prevEducationTypeId, prevServiceId, setFieldValue, values.educationTypeId]);

  return (
    <FormikFormGroup name="serviceId" label={roleLabel(userProfile)}>
      <FormikSelect
        key={rerender}
        name="serviceId"
        size="small"
        isSearchable
        options={[]}
        placeholder="Начните вводить..."
        loadOptions={async (query) => {
          return await lookupApi.getService(
            query,
            values.educationTypeId,
            values.vedomstvoId,
            values.organizationId,
            null,
            false
          );
        }}
        selectedValue={(v: SelectOptionType) => selectValue(v)}
        defaultValue={defaultValue}
      />
    </FormikFormGroup>
  );
};
