import { Field as FormikField, FieldProps as FormikFieldProps, useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import lookupApi from '../../../../lib/api/lookup';
import { districtField } from './district';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

export const teritoryField = 'areaId';
export const teritoryNameField = 'areaName';

type TerritoryProps = FieldProps<string> & {
  disabled?: boolean;
  parent?: string;
};

const Territory = ({ label = 'Район', name, editMode, required, disabled = false, parent }: TerritoryProps) => {
  const fieldId = buildFormFieldName(parent, `${name}Id`);
  const fieldName = buildFormFieldName(parent, `${name}Name`);
  const districtFieldFullName = buildFormFieldName(parent, districtField);
  const [districtId] = useField(districtFieldFullName);
  const [v] = useField(fieldId);
  const [l] = useField(fieldName);

  return (
    <FormikField name={fieldId}>
      {({ form }: FormikFieldProps) => {
        return (
          <Field label={label} editMode={editMode} required={required} value={l.value ?? '—'}>
            <FormikFormGroup required label="" name={fieldId}>
              <FormikSelect
                key={districtId.value}
                name={fieldId}
                size="small"
                isSearchable
                options={[]}
                loadOptions={async (query) => {
                  return await lookupApi.getArea(query, districtId.value ?? null);
                }}
                selectedValue={(val: SelectOptionType) => {
                  form.setFieldValue(fieldId, val?.value);
                  form.setFieldValue(fieldName, val?.label);
                  if (val) {
                    form.setFieldValue(
                      districtFieldFullName,
                      val.additionalPropertiesJson && JSON.parse(val.additionalPropertiesJson).ParentId
                    );
                  }
                }}
                defaultValue={v.value && l.value ? { value: v.value, label: l.value } : null}
                placeholder="Начните вводить..."
                disabled={disabled}
              />
            </FormikFormGroup>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default Territory;
