import React, { useEffect, useState } from 'react';
import { Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { RequestData } from '../../../types/requests';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import learnerApi from '../../../lib/api/learner';
import { EducationFact } from '../../../types/learners';

type Props = {
  data: RequestData;
};

const Organization: React.FC<Props> = ({ data }) => {
  const [contingentInfo, setContingentInfo] = useState<EducationFact[]>([]);
  useEffect(() => {
    const fetch = async () => {
      const items = await learnerApi.getEdFacts(data.contingentGuid);
      setContingentInfo(items);
    };
    if (data.contingentLinkTypeId !== ContingentLinkTypeEnum.NotFound) {
      fetch();
    }
  }, [data]);
  return (
    <>
      <Push size={12} />
      <Panel title={() => <>Общеобразовательная организация</>}>
        {contingentInfo.length ? (
          <Table
            data={contingentInfo}
            pageSize={20}
            columns={[
              {
                dataIndex: 'educationLevelName',
                title: 'Уровень образования',
                render: (item: any) => item.educationLevelName,
                hiddenSort: true,
              },
              {
                dataIndex: 'organizationName',
                title: 'Общеобразовательная организация',
                render: (item: any) => item.organizationName,
                hiddenSort: true,
              },
              {
                dataIndex: 'className',
                title: 'Группа/класс',
                render: (item: any) => item.className,
                hiddenSort: true,
              },
            ]}
          />
        ) : (
          <div className="table-no-data">Сведения о получаемом ребенком образовании отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default Organization;
