export type EnumLiteralsOf<T extends Record<string, unknown>> = T[keyof T];

export const sidebarIcon = Object.freeze({
  main: 'main',
  reports: 'reports',
  request: 'request',
  myServices: 'myServices',
  registerLearners: 'registerLearners',
  myTemplates: 'myTemplates',
  registerChildEducation: 'registerChildEducation',
  registerEducationPrograms: 'registerEducationPrograms',
  registerServices: 'registerServices',
  serviceClasses: 'serviceClasses',
  teachers: 'teachers',
  disciplines: 'disciplines',
  organizations: 'organizations',
  organizationInfo: 'organizationInfo',
  favorite: 'favorite',
  userManual: 'userManual',
  faq: 'faq',
  normativeDoc: 'normativeDoc',
  link: 'link',
  mesh: 'mesh',
  file: 'file',
  siteMap: 'siteMap',
});

export type SidebarIcon = EnumLiteralsOf<typeof sidebarIcon>;
