import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup } from 'yup';
import { Button, Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { TeacherType } from '../../../../types/service-class';
import { ServiceClassContext } from '../../service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import TeachersField from '../fields/teachers';
import { serviceClassStatusEnum } from '../../../../mock-data/service-class-status';
import SavePanel from '../../../../components/save-panel';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type PersonType = {
  teachers: TeacherType[];
};

const ServiceClassTeacher: React.FC<Props> = ({ setEditModeParent, setEditMode, editMode }) => {
  const { serviceClassData, updateServiceClass, accessEditServiceClass } = useContext(ServiceClassContext);

  const handleSubmit = async (values: PersonType) => {
    const payload = {
      serviceClassId: serviceClassData.id,
      educationTypeId: serviceClassData.educationTypeId,
      list: [...values.teachers],
    };

    await serviceClassApi.updateServiceClassTeacherList(payload);
    updateServiceClass();

    setEditModeParent && setEditModeParent(null);
    if (setEditMode) {
      setEditMode(false);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{ teachers: serviceClassData.teacher.list }}
      validationSchema={getValidationSchema}
      render={({ values, isValid, resetForm, handleSubmit }) => {
        return (
          <Form>
            <Panel
              title={() => (
                <>
                  Ответственные преподаватели{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {values.teachers?.length}{' '}
                  </span>
                </>
              )}
              headingControl={() => {
                return !editMode &&
                  serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                  accessEditServiceClass ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent && setEditModeParent('teacher');
                      if (setEditMode) {
                        setEditMode(true);
                      }
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              {editMode ? (
                <TeachersField editMode={editMode} name="teachers" />
              ) : (
                <Table
                  data={serviceClassData.teacher.list}
                  pageSize={10}
                  hideSort
                  columns={[
                    {
                      dataIndex: 'name',
                      title: 'ФИО',
                      width: '50%',
                      render: (item: any) => item.name,
                    },
                    {
                      dataIndex: 'chief',
                      title: 'Руководитель',
                      width: '50%',
                      render: (item: any) => (item.isSupervisor ? 'Да' : ''),
                    },
                  ]}
                />
              )}
            </Panel>
            {editMode && setEditModeParent && (
              <SavePanel
                controls={
                  <>
                    <Button
                      onClick={() => {
                        setEditModeParent && setEditModeParent(null);
                        if (setEditMode) {
                          setEditMode(false);
                        }
                        resetForm();
                      }}
                      border
                      primary
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button handleClick={handleSubmit} disabled={!isValid} primary label="Сохранить" />
                  </>
                }
              />
            )}
          </Form>
        );
      }}
    />
  );
};

export default ServiceClassTeacher;

const getValidationSchema = objectYup().shape({
  teachers: arrayYup()
    .of(
      objectYup().shape({
        name: stringYup().required('Введите ФИО преподавателя'),
      })
    )
    .required('Должен состоять из списка преподавателей') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Минимум 1 преподаватель'),
});
