import { Field as FormikField, FieldProps as FormikFieldProps, useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import lookupApi from '../../../../lib/api/lookup';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

type StreetProps = FieldProps<string> & {
  disabled?: boolean;
  parent?: string;
};

const Street = ({ label = 'Улица', name, editMode, required, disabled = false, parent }: StreetProps) => {
  const fieldId = `${name}Id`;
  const fieldName = parent ? `${parent}.${name}Name` : `${name}Name`;

  const [field] = useField(fieldName);

  async function wait(value: string) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    return [{ value: null, label: value }];
  }

  return (
    <FormikField name={fieldName}>
      {({ form }: FormikFieldProps) => {
        const v = form.values[fieldId];
        const l = form.values[fieldName];

        return (
          <Field label={label} editMode={editMode} required={required} value={l ?? '—'}>
            <FormikFormGroup required label="" name={fieldName}>
              <FormikSelect
                name={fieldName}
                size="small"
                isSearchable
                options={[]}
                loadOptions={async (query) => {
                  const data = await lookupApi.getPersonalAddressStreet(query);
                  const result = data.map((item: SelectOptionType) => {
                    return {
                      ...item,
                      value: Math.random() * Math.expm1(item.label.length),
                    };
                  });
                  return data.length ? result : wait(query);
                }}
                selectedValue={(v: SelectOptionType) => {
                  form.setFieldValue(fieldId, v?.value);
                  form.setFieldValue(fieldName, v?.label);
                }}
                defaultValue={l || field.value ? { value: v, label: field.value } : null}
                placeholder="Начните вводить..."
                disabled={disabled}
              />
            </FormikFormGroup>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default Street;
