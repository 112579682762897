const date = new Date();

export const initialStateFormOtherDocument = {
  id: null,
  pupilId: null,
  docTypeId: null,
  docNumber: '',
  docDate: date.toUTCString(),
  orgId: null,
  orgName: '',
  shortOrgName: null,
  addressId: null,
  address: '',
  serviceId: null,
  serviceName: '',
  fileId: [0],
  finTypeId: 0,
  classificatorEKUId: null,
  programmLevelId: null,
  moduleId: null,
  moduleName: '',
  stageId: null,
  stageName: '',
  fileIds: [],
};

export async function waitTypeHead(value: string) {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return [{ value: null, label: value }];
}
