import { ScheduleType } from '../../components/schedule';
import { ScheduleOfServiceData } from '../../types/service';

export const getStringFromTimeTable = (schedules: ScheduleType[]): string =>
  schedules
    .reduce(
      (acc, schedule) =>
        schedule.dayOff ? acc : `${acc}${acc ? '-' : ''}${schedule.id}${schedule.from}${schedule.to}`,
      ''
    )
    .replaceAll(':', '');

const getCurrentDayWeek = (day: number) => {
  let weekDayName = '';
  switch (day) {
    case 1:
      weekDayName = 'Пн';
      break;
    case 2:
      weekDayName = 'Вт';
      break;
    case 3:
      weekDayName = 'Ср';
      break;
    case 4:
      weekDayName = 'Чт';
      break;
    case 5:
      weekDayName = 'Пт';
      break;
    case 6:
      weekDayName = 'Сб';
      break;
    case 7:
      weekDayName = 'Вс';
      break;
    case 8:
      weekDayName = 'Пн-Пт';
      break;
    case 9:
      weekDayName = 'Сб-Вс';
      break;
    default:
      break;
  }
  return weekDayName;
};

export const getDayOfTheWeekFromString = (str: string) => {
  if (!str) {
    return [];
  }
  const lineSeparator = str.split('-');

  return lineSeparator.reduce((acc: string[], item: string) => {
    const currentDayWeek = Number(item[0]);
    const dayWeek = getCurrentDayWeek(currentDayWeek);

    const deleteDay = item.slice(1);
    const splitDayFragments = deleteDay.match(/.{1,2}/g);

    if (splitDayFragments) {
      const startDay = `${splitDayFragments[0]}:${splitDayFragments[1]}`;
      const endDay = `${splitDayFragments[2]}:${splitDayFragments[3]}`;

      acc.push(`${dayWeek} ${startDay}-${endDay}`);
    }

    return acc;
  }, []);
};

export const scheduleMockData = (type?: 'full' | 'days' | 'periods') => {
  const days = [
    {
      id: 1,
      name: 'Понедельник',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 2,
      name: 'Вторник',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 3,
      name: 'Среда',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 4,
      name: 'Четверг',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 5,
      name: 'Пятница',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 6,
      name: 'Суббота',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 7,
      name: 'Воскресенье',
      from: '',
      to: '',
      dayOff: true,
    },
  ];
  const periods = [
    {
      id: 8,
      name: 'Будни',
      from: '',
      to: '',
      dayOff: true,
    },
    {
      id: 9,
      name: 'Выходные',
      from: '',
      to: '',
      dayOff: true,
    },
  ];

  return type === 'days' ? days : type === 'periods' ? periods : [...days, ...periods];
};

export const getTimeTableFromString = (str?: string) =>
  str?.split('-').reduce((acc, item: string) => {
    if (item.length > 0) {
      const day = acc[parseInt(item[0]) - 1];
      day.dayOff = false;
      day.from = `${item[1]}${item[2]}:${item[3]}${item[4]}`;
      day.to = `${item[5]}${item[6]}:${item[7]}${item[8]}`;
    }
    return acc;
  }, scheduleMockData()) || scheduleMockData();

export const convertScheduleToFront = (schedules: ScheduleOfServiceData[]): ScheduleType[] => {
  const result = getTimeTableFromString('') as ScheduleType[];
  schedules?.forEach((wd) => {
    result[wd.dayOfWeek - 1] = {
      ...result[wd.dayOfWeek - 1],
      dayOff: false,
      period: wd.shedulePeriodId,
      from: wd.timeStart?.substring(0, 5) ?? '',
      to: wd.timeEnd?.substring(0, 5) ?? '',
    };
  });
  return result;
};

export const convertScheduleToBack = (schedules: ScheduleType[]): ScheduleOfServiceData[] => {
  return schedules
    .filter((s) => !s.dayOff)
    .map((item) => {
      return {
        id: 0,
        dayOfWeek: item.id,
        shedulePeriodId: item.period,
        timeStart: item.from,
        timeEnd: item.to,
      };
    });
};
