import React, { useEffect, useState } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { teritoryField, teritoryNameField } from './territory';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

export const districtField = 'districtId';

type DistrictProps = FieldProps<string> & {
  disabled?: boolean;
  parent?: string;
};

const District = ({
  label = 'Округ',
  name,
  editMode,
  required,
  defaultValue = '—',
  disabled = false,
  parent,
}: DistrictProps) => {
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);
  const [optionsSet, setOptionsSet] = useState<boolean>(false);
  const fieldId = buildFormFieldName(parent, `${name}Id`);
  const fieldName = buildFormFieldName(parent, `${name}Name`);

  useEffect(() => {
    const fetchOptions = async () => {
      setOptionsData(await dictionariesApi.getDistricts());
      setOptionsSet(true);
    };
    if (editMode && !optionsSet) {
      fetchOptions();
    }
  }, [editMode, optionsSet]);

  optionsData.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelB > labelA) {
      return 1;
    }
    return 0;
  });

  return (
    <FormikField name={fieldId}>
      {({ form }: FormikFieldProps) => {
        const v = form.values[fieldId];
        const l = form.values[fieldName] ?? optionsData?.find((m) => m.value === v)?.label;
        return (
          <Field label={label} editMode={editMode} required={required} value={l} defaultValue={defaultValue}>
            <FormikFormGroup required label="" name={fieldId}>
              <FormikSelect
                name={fieldId}
                size="small"
                isSearchable
                options={optionsData}
                defaultValue={v && l ? { value: v, label: l } : null}
                placeholder="Выберите..."
                disabled={disabled}
                selectedValue={(v: SelectOptionType) => {
                  form.setFieldValue(buildFormFieldName(parent, teritoryField), 0);
                  form.setFieldValue(buildFormFieldName(parent, teritoryNameField), '');
                }}
              />
            </FormikFormGroup>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default District;
