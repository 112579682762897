import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Checkbox, Input, Select, SelectOptionType, Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { ReactComponent as IconEditGray } from '../../../../assets/images/icons/edit.svg';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconCheck } from '../../../../assets/images/icons/check.svg';
import { ReactComponent as IconClose } from '../../../../assets/images/icons/close.svg';
import { DisciplineData, ServiceData } from '../../../../types/service';
import lookupApi from '../../../../lib/api/lookup';

type Discipline = (DisciplineData & { number?: number }) | null;

const initialData: Discipline = {
  number: undefined,
  id: undefined,
  disciplineId: undefined,
  disciplineName: undefined,
  isRequired: undefined,
  hoursCount: undefined,
};

const CreateDisciplines = () => {
  const { values, setFieldValue } = useFormikContext<ServiceData>();
  const [editObj, setEditObj] = useState<Discipline>(null);

  useEffect(() => {
    if (!values.serviceDisciplines) {
      setFieldValue('serviceDisciplines', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.serviceDisciplines]);
  const columns = useMemo(
    () => [
      {
        dataIndex: 'number',
        title: '№',
        render: (item: any) => (
          <div className={editObj?.number === item.number ? 'table-row-item-height flex items-center' : ''}>
            {item.number}
          </div>
        ),
        width: '40px',
      },
      {
        dataIndex: 'disciplineName',
        title: 'Наименование дисциплины',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <Select
              name="disciplineId"
              size="small"
              options={
                editObj?.disciplineId && editObj?.disciplineName
                  ? [{ label: editObj.disciplineName, value: editObj.disciplineId }]
                  : []
              }
              value={editObj?.disciplineId ? { value: editObj?.disciplineId, label: editObj?.disciplineName } : null}
              isSearchable
              loadOptions={(query) => lookupApi.getDisciplines(query)}
              onChange={(selectedOption) => {
                const option = selectedOption
                  ? (selectedOption as SelectOptionType)
                  : { value: undefined, label: undefined };
                setEditObj((prev) => {
                  if (option.value && typeof option.value === 'string') {
                    return { ...prev, disciplineId: parseInt(option.value), disciplineName: option.label };
                  }
                  if (option.value && typeof option.value === 'number') {
                    return { ...prev, disciplineId: option.value, disciplineName: option.label };
                  }
                  return { ...prev, disciplineId: undefined, disciplineName: undefined };
                });
              }}
              placeholder="Начните вводить..."
            />
          ) : (
            item.disciplineName
          ),
        width: '33%',
      },
      {
        dataIndex: 'hours',
        title: 'Кол-во часов',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <div style={{ width: 88 }}>
              <Input
                size="small"
                value={editObj?.hoursCount}
                onChange={(value) => {
                  setEditObj((prev) => ({ ...prev, hoursCount: parseInt(value.target.value) || 0 }));
                }}
              />
            </div>
          ) : (
            item.hoursCount
          ),
        width: '33%',
      },
      {
        dataIndex: 'required',
        title: 'Обязательная',
        render: (item: any) => (
          <div className="flex">
            <div className="flex-auto">
              {editObj?.number === item.number ? (
                <div className="table-row-item-height flex items-center">
                  <Checkbox
                    checked={editObj?.isRequired || false}
                    onChange={(value) => setEditObj((prev) => ({ ...prev, isRequired: value }))}
                    labelId={item.number}
                  />
                </div>
              ) : item.isRequired ? (
                'Да'
              ) : (
                'Нет'
              )}
            </div>
            <div className="flex-none">
              {editObj?.number === item.number ? (
                <div className="flex">
                  <Button
                    iconLeft={() => <IconClose />}
                    border
                    size="small"
                    handleClick={() => {
                      setEditObj(null);
                      const clearDiscipline = values.serviceDisciplines.filter((item) => item.disciplineId);
                      setFieldValue('serviceDisciplines', clearDiscipline);
                    }}
                  />
                  <Push size={8} orientation="horizontal" />
                  <Button
                    iconLeft={() => <IconCheck />}
                    success
                    size="small"
                    border
                    disabled={!(editObj && editObj.disciplineName && editObj.hoursCount)}
                    handleClick={async () => {
                      const updatedDisciplines = values.serviceDisciplines.map((el: Discipline) =>
                        el?.number === editObj?.number
                          ? {
                              ...editObj,
                              educationTypeId: values.info?.educationTypeId,
                              serviceId: 0,
                            }
                          : el
                      );

                      setEditObj(null);
                      setFieldValue('serviceDisciplines', updatedDisciplines);
                    }}
                  />
                </div>
              ) : (
                <DropDown
                  component={() => (
                    <span className="drop-down-btn-round">
                      <IconDots />
                    </span>
                  )}
                >
                  <div className="drop-down-panel">
                    <div className="drop-down-panel__list">
                      <button type="button" onClick={() => setEditObj(item)} className="drop-down-panel__list-item">
                        <IconEditGray />
                        Редактировать
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            'serviceDisciplines',
                            values.serviceDisciplines.filter((i: any) => item.number !== i.number)
                          );
                        }}
                        className="drop-down-panel__list-item"
                      >
                        <IconRemove />
                        Удалить
                      </button>
                    </div>
                  </div>
                </DropDown>
              )}
            </div>
          </div>
        ),
        width: '33%',
      },
    ],
    [editObj, setFieldValue, values.serviceDisciplines, values.info?.educationTypeId]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Дисциплины
            <span className="color-gray-dark">
              {' \u00A0'} {values.serviceDisciplines?.length ?? 0}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  const number = (values.serviceDisciplines?.length || 0) + 1;
                  const data = { ...initialData, number };

                  setFieldValue('serviceDisciplines', [...values.serviceDisciplines, data]);
                  setEditObj(data);
                }}
                disabled={!!editObj}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <IconPlus />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
              </button>
            </div>
          );
        }}
      >
        <Table overflow data={values.serviceDisciplines} hideSort columns={columns} />
      </Panel>
    </>
  );
};

export default CreateDisciplines;
