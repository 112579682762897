import { sidebarIcon, SidebarIcon } from '../types/enum';
import { ReactComponent as IconDesktop } from '../assets/images/sider/desktop.svg';
import { ReactComponent as IconDisciplines } from '../assets/images/sider/disciplines.svg';
import { ReactComponent as IconFaq } from '../assets/images/sider/faq.svg';
import { ReactComponent as IconFavorite } from '../assets/images/sider/favorite.svg';
import { ReactComponent as IconReports } from '../assets/images/sider/report.svg';
import { ReactComponent as IconRequest } from '../assets/images/sider/request.svg';
import { ReactComponent as IconMyServices } from '../assets/images/sider/my-service.svg';
import { ReactComponent as IconRegisterLearners } from '../assets/images/sider/register-learners.svg';
import { ReactComponent as IconMyTemplates } from '../assets/images/sider/my-templates.svg';
import { ReactComponent as IconRegisterChildEducation } from '../assets/images/sider/register-child-education.svg';
import { ReactComponent as IconRegisterEducationPrograms } from '../assets/images/sider/register-education-programms.svg';
import { ReactComponent as IconRegisterServices } from '../assets/images/sider/register-services.svg';
import { ReactComponent as IconServiceClasses } from '../assets/images/sider/service-classes.svg';
import { ReactComponent as IconTeachers } from '../assets/images/sider/teachers.svg';
import { ReactComponent as IconOrganizations } from '../assets/images/sider/organizations.svg';
import { ReactComponent as IconOrganizationInfo } from '../assets/images/sider/organization-info.svg';
import { ReactComponent as IconUserManual } from '../assets/images/sider/user-manual.svg';
import { ReactComponent as IconNormativeDoc } from '../assets/images/sider/normative-doc.svg';
import { ReactComponent as IconMesh } from '../assets/images/sider/mesh.svg';
import { ReactComponent as IconLink } from '../assets/images/sider/icon-link.svg';
import { ReactComponent as IconFile } from '../assets/images/site-map/file.svg';
import { ReactComponent as IconSiteMap } from '../assets/images/sider/site-map.svg';

export const GetSidebarIcons = (icon: SidebarIcon = '') => {
  switch (icon) {
    case sidebarIcon.main:
      return <IconDesktop />;
    case sidebarIcon.reports:
      return <IconReports />;
    case sidebarIcon.request:
      return <IconRequest />;
    case sidebarIcon.myServices:
      return <IconMyServices />;
    case sidebarIcon.registerLearners:
      return <IconRegisterLearners />;
    case sidebarIcon.myTemplates:
      return <IconMyTemplates />;
    case sidebarIcon.registerChildEducation:
      return <IconRegisterChildEducation />;
    case sidebarIcon.registerEducationPrograms:
      return <IconRegisterEducationPrograms />;
    case sidebarIcon.registerServices:
      return <IconRegisterServices />;
    case sidebarIcon.serviceClasses:
      return <IconServiceClasses />;
    case sidebarIcon.teachers:
      return <IconTeachers />;
    case sidebarIcon.disciplines:
      return <IconDisciplines />;
    case sidebarIcon.organizations:
      return <IconOrganizations />;
    case sidebarIcon.organizationInfo:
      return <IconOrganizationInfo />;
    case sidebarIcon.favorite:
      return <IconFavorite />;
    case sidebarIcon.userManual:
      return <IconUserManual />;
    case sidebarIcon.faq:
      return <IconFaq />;
    case sidebarIcon.normativeDoc:
      return <IconNormativeDoc />;
    case sidebarIcon.link:
      return <IconLink />;
    case sidebarIcon.mesh:
      return <IconMesh />;
    case sidebarIcon.file:
      return <IconFile />;
    case sidebarIcon.siteMap:
      return <IconSiteMap />;
    default:
      break;
  }
};
