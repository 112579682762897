import React, { useState, useEffect } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps, FormikHelpers, useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import requestsApi from '../../../../lib/api/requests';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { RequestData } from '../../../../types/requests';

type DeclineReasonProps = FieldProps<SelectOptionType> & {
  serviceId: number;
  requestId: number;
  showRequiredIcon?: boolean;
};

const DeclineReason = ({
  label = 'Причина отказа',
  name,
  editMode,
  required,
  disabled,
  serviceId,
  requestId,
  defaultValue,
  showRequiredIcon,
}: DeclineReasonProps) => {
  const { setFieldValue } = useFormikContext<FormikHelpers<RequestData>>();
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);
  const [optionsSet, setOptionsSet] = useState<boolean>(false);
  const fieldId = `${name}Id`;
  const fieldName = `${name}Name`;

  useEffect(() => {
    const fetchOptions = async () => {
      setOptionsData(await requestsApi.getRequestDeclineReason(requestId, serviceId));
      setOptionsSet(true);
    };
    if (editMode && !optionsSet) {
      fetchOptions();
    }
  }, [editMode, optionsSet, serviceId, requestId]);

  return (
    <FormikField name={fieldId}>
      {({ form }: FormikFieldProps) => {
        const v = form.values[fieldId];
        const l = form.values[fieldName];

        return (
          <Field
            editMode={editMode}
            disabled={disabled}
            label={label}
            required={required}
            value={l}
            showRequiredIcon={showRequiredIcon}
          >
            <FormikFormGroup required={required} label="" name={fieldId}>
              <FormikSelect
                name={fieldId}
                size="small"
                placeholder={!disabled ? 'Выберите...' : ''}
                isSearchable
                options={defaultValue ? [...[defaultValue], ...optionsData] : optionsData}
                defaultValue={v && l ? { value: v, label: l } : null}
                disabled={disabled}
                selectedValue={(option: SelectOptionType) => {
                  if (option.value) {
                    setFieldValue(fieldId, option.value);
                    setFieldValue(fieldName, option.label);
                  } else {
                    setFieldValue(fieldId, null);
                    setFieldValue(fieldName, null);
                  }
                }}
              />
            </FormikFormGroup>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default DeclineReason;
