import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FooterWrapper from '../../components/footer/footer-wrapper';
import SiderNav from '../../components/sider/nav';
import { routes } from '../../config/constants';
import { routeList } from '../../mock-data/routes';
import ProtectedRoute from './protected';
import Header from '../../components/header/header';
import { AppState } from '../../redux/types/state';
import { isAuthorizedSelector, userProfileSelector } from '../../redux/selectors';
import { authApi } from '../../lib/api';
import AccessError from '../errors/access';
import Room from '../room';
import { MenuSettings } from '../../types/entities';
import ImgLogo from '../../assets/images/logo.svg';
import ImgShowSider from '../../assets/images/sider/show-sider.svg';
import ImgHelp from '../../assets/images/sider/help.svg';
import { ReactComponent as IconDesktop } from '../../assets/images/sider/email.svg';
import systemPropertyApi from '../../lib/api/system-propetry';
import { setUserSystem } from '../../redux/ducks/system-settings';
import { selectServiceItemCallback } from '../../lib/utils/service';
import { withErrorBoundary } from '../../hoc/error-boundary';
import { useGetAppealLinkQuery, useGetTssArchiveLinkQuery } from '../../store/home';
// import { useCookies } from 'react-cookie';
// import moment from 'moment';

const Layout = () => {
  const [showSider, setShowSider] = useState(true);
  const [simplifiedVersion, setSimplifiedVersion] = useState(false);

  const [menuSetting, setMenuSetting] = useState<MenuSettings[]>([]);
  const [meshLink, setMeshLink] = useState<string>('https://mes-contract.clink.tech/');
  // const [cookie, setCookie] = useCookies();
  const { userProfile, isAuthorized } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
    isAuthorized: isAuthorizedSelector(state),
  }));
  const dispatch = useDispatch();
  const { data: tssArchiveLink = '' } = useGetTssArchiveLinkQuery(userProfile.id, {
    skip: !userProfile.id || !isAuthorized,
  });
  const { data: appealLink = '' } = useGetAppealLinkQuery(undefined, { skip: !userProfile.id || !isAuthorized });

  useEffect(() => {
    const fetchData = async () => {
      const settings = await authApi.getMenuSettings();
      setMenuSetting(settings.data);
      const mesh = await systemPropertyApi.getSystemProperty('ContractAuthUrl');
      setMeshLink(mesh);
      dispatch(setUserSystem(mesh));
    };
    userProfile.id && isAuthorized && fetchData();
  }, [userProfile.id, dispatch, isAuthorized]);

  // надо для открытия маленькго окошка - подобрать услугу при создании группы обучения ДОНМ
  useEffect(() => {
    setSimplifiedVersion(!!selectServiceItemCallback);
  }, []);

  const contentHolder = useMemo(
    () => (
      <div className="content-holder">
        <Header userProfile={userProfile} />
        {userProfile.id ? (
          <Switch>
            {routeList.map((item) => (
              <ProtectedRoute
                exact
                key={`${item.id}`}
                path={item.route || item.link}
                component={item.component}
                checkAccess={item.checkAccess}
              />
            ))}
            <ProtectedRoute exact path={routes.room} component={Room} />
            <Route exact path={routes.accessDeny} component={AccessError} />
            <ProtectedRoute component={AccessError} />
          </Switch>
        ) : null}
      </div>
    ),
    [userProfile]
  );

  return (
    <div className={classNames('layout', showSider && 'sider-show')}>
      {!simplifiedVersion ? (
        <>
          <div className="layout__sider">
            <div className="sider-header">
              <img src={ImgLogo} alt="Logo" className="sider-header__logo" />
              <button type="button" onClick={() => setShowSider(!showSider)} className="sider-header__btn">
                <img src={ImgShowSider} alt="Show Sider" />
              </button>
            </div>
            <SiderNav userProfile={userProfile} menuSetting={menuSetting} meshLink={meshLink} showSider={showSider} />
            <div className="sider-footer">
              <div className="sider-help">
                <div className="sider-help__title">Нужна помощь?</div>
                <a target="_blank" rel="noreferrer" href={appealLink} className="sider-help__btn">
                  Написать нам
                  <span className="sider-help__icon">
                    <IconDesktop />
                  </span>
                </a>
                <a target="_blank" rel="noreferrer" href={tssArchiveLink} className="sider-help__my-link">
                  Мои обращения
                </a>
                <img src={ImgHelp} alt="Help" className="sider-help__img" />
              </div>
            </div>
          </div>
          <div className="layout__content">
            {contentHolder}
            <FooterWrapper />
          </div>
        </>
      ) : (
        contentHolder
      )}
    </div>
  );
};

export default withErrorBoundary(Layout);
