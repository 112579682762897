import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Select, SelectOptionType, Panel, Loader, Push, AsyncTable as Table } from '@mosru/esz_uikit';
import { historyState } from '../../mock-data/history-state';
import { ReactComponent as IconDownload } from '../../assets/images/icons/download.svg';
import { SignTypeEnum } from '../../mock-data/sign-type-enum';
import Edit from './edit';
import { OrganizationTransform, SearchOrganizationInitialFormData } from '../../types/organization';
import RemoveModal from '../../components/remove-modal';
import { pageSizeOptions } from '../../mock-data';
import { ReactComponent as IconCheckCircle } from '../../assets/images/icons/check-circle.svg';
import { ReactComponent as IconArrow } from '../../assets/images/teachers/arrow-right.svg';
import SignModal from '../../components/sign-modal';
import { generateLink, hasAccessObjectAny, replaceHistoryState } from '../../lib/utils';
import { accessAction, accessObject, accessVedomst } from '../../mock-data/access-enum';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { maxRegistryRows, routes } from '../../config/constants';
import { downloadOrganizationSign, transformOrganizationData } from '../../lib/utils/organization';
import organizationApi from '../../lib/api/organization';
import { checkAccessSign, getAccessSign } from './utils';

type Props = {
  isAdminView: boolean;
  isAdminEdit: boolean;
  search: SearchOrganizationInitialFormData;
};

const OrganizationTable: React.FC<Props> = ({ search, isAdminEdit, isAdminView }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const canSignOIV = getAccessSign(userProfile, isAdminEdit, true);

  const conditionAccessTable = getAccessSign(userProfile, isAdminView || isAdminEdit);

  const [removedId, setRemovedId] = useState(0);
  const [pageSize, setPageSize] = useState(window.history.state[historyState.pageSize] || pageSizeOptions[0].value);

  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [rerenderTableKey, setRerenderTableKey] = useState(0);

  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState<boolean>(false);
  const [disabledSelectAll, setDisabledSelectAll] = useState<boolean>(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<OrganizationTransform[]>([]);

  const [tableData, setTableData] = useState<OrganizationTransform[]>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);

  const [signModal, showSignModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentSearch, setCurrentSearch] = useState<SearchOrganizationInitialFormData>(search);

  const onSelectedCheckboxHandler = (id: number) => {
    setTableData((prevState: OrganizationTransform[]) => {
      return prevState.map((item) => {
        if (item.id === id) {
          setSelectAllCheckboxes(false);
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return {
          ...item,
        };
      });
    });
  };

  const checkDisabledCheckbox = useCallback(
    (item: OrganizationTransform) => item.isArchive || !!item.vedomstvoSign || !checkAccessSign(item.vedomstvoId, true),
    []
  );

  const onSelectedAllHandler = () => {
    setTableData((prevState: OrganizationTransform[]) => {
      if (selectAllCheckboxes) {
        setSelectAllCheckboxes(false);
        return prevState.map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
      } else {
        return prevState.map((item) => {
          return {
            ...item,
            selected: !checkDisabledCheckbox(item),
          };
        });
      }
    });
    setSelectAllCheckboxes(true);
  };

  useLayoutEffect(() => {
    setCurrentSearch(search);
    if (initPageNum) {
      setInitPageNum(0);
    } else {
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (tableData) {
      const hasAllSelected = tableData.filter((item) => !checkDisabledCheckbox(item)).every((item) => item.selected);

      // найти элементы не архивные и не подписанные
      const defaultElements = tableData.filter((item) => !checkDisabledCheckbox(item));

      // найти ОИВ
      const oivElements = tableData.filter((item) => item.vedomstvoSign);

      // найти все архивные документы
      const checkAllIsArchive = tableData.every((item) => item.isArchive);

      // Количество архивных элементов
      const filterArchiveElements = tableData.filter((item) => item.isArchive).length;

      // если все архивные то disabled
      if (checkAllIsArchive) {
        setDisabledSelectAll(!checkAllIsArchive);
      }

      // если Подписанный один ОИВ на странице, и на странице есть IsArchive то disabled
      else if ((oivElements.length === 1 && filterArchiveElements > oivElements.length) || !defaultElements.length) {
        setDisabledSelectAll(false);
      } else if (tableData.length !== oivElements.length) {
        setDisabledSelectAll(true);
      }

      setSelectAllCheckboxes(hasAllSelected);
    }
  }, [checkDisabledCheckbox, pageSize, tableData]);

  useEffect(() => {
    if (tableData) {
      const currentCheckboxSelected = tableData.filter((item: { selected: boolean }) => item.selected);
      setSelectedCheckboxes(currentCheckboxSelected);
    }
  }, [tableData]);

  const fetchData = useCallback(async () => {
    setTableData([]);

    const organizations = await organizationApi.getOrganizations({
      ...currentSearch,
      pageSize,
      pageNumber: pageNum + 1,
    });

    const transformOrganization = transformOrganizationData(organizations.items, pageNum, pageSize);
    setTableData(transformOrganization);
    setTableTotal(organizations.total);
    setSelectAllCheckboxes(false);
    setLoading(false);
  }, [currentSearch, pageNum, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const deleteOrganization = useCallback(
    async (id: number) => {
      await organizationApi.deleteOrganization(id);
      await fetchData();
      setRerenderTableKey(Math.random());
    },
    [fetchData]
  );

  const tableElements = () => {
    const showCheckbox = {
      dataIndex: 'selectAll',
      hiddenSort: true,
      width: '60px',
      title: (
        <Checkbox
          labelId="all"
          disabled={!disabledSelectAll}
          checked={selectAllCheckboxes}
          onChange={onSelectedAllHandler}
        />
      ),
      render: (item: any) => (
        <Checkbox
          labelId={item.id}
          checked={item.selected}
          onChange={() => onSelectedCheckboxHandler(item.id)}
          disabled={checkDisabledCheckbox(item)}
        />
      ),
    };

    const organizationSign = {
      dataIndex: 'organizationSign',
      title: <span style={{ margin: '0 auto' }}>ЭП орг-ии</span>,
      hiddenSort: true,
      width: '100px',
      render: (item: any) => {
        return (
          item.organizationSign && (
            <div className="text-center">
              <div className="organization-file" onClick={() => downloadOrganizationSign(item.organizationSign)}>
                <IconDownload />
              </div>
            </div>
          )
        );
      },
    };

    const vedomstvoSign = {
      dataIndex: 'vedomstvoSign',
      title: <div style={{ margin: '0 auto' }}>ЭП ОИВ</div>,
      hiddenSort: true,
      width: '100px',
      render: (item: any) => {
        return (
          item.vedomstvoSign && (
            <div className="text-center">
              <div className="organization-file" onClick={() => downloadOrganizationSign(item.vedomstvoSign)}>
                <IconDownload />
              </div>
            </div>
          )
        );
      },
    };

    const showEdit = {
      dataIndex: 'code',
      title: '',
      width: '15%',
      hiddenSort: true,
      render: (item: any) => {
        // Условия для отображения кнопок в контекстном меню
        const canEdit =
          (hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) || isAdminEdit) &&
          item.vedomstvoId !== accessVedomst.Dogm;

        const canSign = !checkDisabledCheckbox(item) && canSignOIV;

        const canDelete =
          (hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Archive) ||
            isAdminEdit) &&
          item.vedomstvoId !== accessVedomst.Dogm;

        const access = canEdit || canSign || canDelete;

        return !item.isArchive && access ? (
          <Edit
            id={item.id}
            canEdit={canEdit}
            canSign={canSign}
            canDelete={canDelete}
            setRemovedId={() => setRemovedId(item.id)}
            onSign={() => {
              setSelectedCheckboxes([item]);
              showSignModal(true);
            }}
          />
        ) : (
          <Link className="teachers-link" to={generateLink(routes.organization, { id: item.id })}>
            <IconArrow />
          </Link>
        );
      },
    };

    const showVedomstvoName = {
      dataIndex: 'vedomstvoName',
      title: 'Департамент',
      width: '200px',
      hiddenSort: true,
      render: (item: any) => <div>{item.vedomstvoName}</div>,
    };

    const elementsTable = [
      {
        dataIndex: 'id',
        width: '60px',
        title: '№',
        render: (item: any) => item.count,
        hiddenSort: true,
      },
      {
        dataIndex: 'organizationName',
        width: '30%',
        title: 'Краткое наименование',
        render: (item: any) => (
          <Link className="organization-table-name" to={`${routes.organizations}/${item.id}`} type="button">
            {item.organizationName ? item.organizationName : 'наименование отсутствует'}
          </Link>
        ),
        hiddenSort: true,
      },
      {
        dataIndex: 'address',
        title: 'Адрес',
        width: '50%',
        hiddenSort: true,
        render: (item: any) =>
          item.address ? item.address : <span className="organization-table-no-data">не указан</span>,
      },
    ];

    const showOrg =
      userProfile.vedomstvoId !== accessVedomst.Dsit
        ? [organizationSign, vedomstvoSign, showEdit]
        : [organizationSign, showEdit];

    const showOrganizationOrVedomstvo = conditionAccessTable ? showOrg : [showVedomstvoName, showEdit];

    const elements = [...elementsTable, ...showOrganizationOrVedomstvo];

    return canSignOIV ? [showCheckbox, ...elements] : elements;
  };

  return (
    <>
      <Panel
        title={() => (
          <>
            Организации
            <span className="color-gray-dark">
              {' \u00A0'}
              {tableTotal}
            </span>
          </>
        )}
        headingControl={() => (
          <>
            <div style={{ width: 184 }}>
              <Select
                name="pageSize"
                isSearchable={false}
                hideClearIndicator
                value={pageSizeOptions.find((item) => item.value === pageSize)}
                onChange={(selectedOption) => {
                  const { value } = selectedOption as SelectOptionType;
                  setPageSize(value as number);
                  setPageNum(0);
                }}
                options={pageSizeOptions}
                size="small"
              />
            </div>

            {canSignOIV &&
              (selectedCheckboxes.length ? (
                <>
                  <Push orientation="horizontal" size={8} />
                  <Button
                    primary
                    colorIcon
                    size="small"
                    iconLeft={() => <IconCheckCircle />}
                    handleClick={() => showSignModal(true)}
                    label={`Подписать ОИВ выбранные ${selectedCheckboxes.length}`}
                  />
                </>
              ) : null)}
          </>
        )}
      >
        {loading ? (
          <div className="loader-container">
            <Loader title="Загружаем организации" description="Нужно немного подождать" />
          </div>
        ) : tableTotal ? (
          tableData?.length > 0 ? (
            <Table
              hideSort
              overflow
              data={tableData}
              pageNum={pageNum}
              pageSize={pageSize}
              itemsAll={tableTotal}
              key={rerenderTableKey}
              columns={tableElements()}
              rowLimit={maxRegistryRows}
              setPageNumber={setPageNum}
            />
          ) : (
            <div className="loader-container">
              <Loader title="Загружаем организации" description="Нужно немного подождать" />
            </div>
          )
        ) : (
          <div className="table-no-data">Нет данных</div>
        )}
      </Panel>

      <SignModal
        show={signModal}
        onCloseHandle={() => {
          showSignModal(false);
          fetchData();
          setRerenderTableKey(Math.random());
        }}
        ids={selectedCheckboxes.map((organization) => ({ id: organization.id }))}
        getDataForSignHandle={async (id) => await organizationApi.getDataForSign(id)}
        setSignedDataHandle={async (id, sign) => await organizationApi.setSignedData(id, SignTypeEnum.OIV, sign)}
      />

      <RemoveModal
        title="Организации"
        description="Внимание! Данный объект будет удален из реестра «организаций». Хотите продолжить?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={() => {
          deleteOrganization(removedId);
          setRemovedId(0);
          setRerenderTableKey(Math.random());
        }}
      />
    </>
  );
};

export default OrganizationTable;
