import React from 'react';
import { Button, ModalPanel, Modal } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
};

const ModalAboutSignature = ({ show, onCloseHandler }: Props) => {
  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle="Сведения об электронной подписи"
        controls={() => <Button label="Ок" primary size="small" onClick={onCloseHandler} />}
        renderComponent={() => (
          <>
            Пользователь: NF, ЭП: 23.11.2022 15:45:12
            <div className="about-sign">
              <div className="flex flex-column">
                electronic signature_00209313
                <span>действует до 12.01.2025</span>
              </div>
            </div>
          </>
        )}
      />
    </Modal>
  );
};

export default ModalAboutSignature;
