import React, { useCallback, useContext, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Panel, Loader, Push } from '@mosru/esz_uikit';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import ServiceClassesEnrollment from '../components/fields/service-classes-enrollment';
import { RequestData, RequestEnrollmentData } from '../../../types/requests';
import { submitEnrollment } from '../utils';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { docDate } from '../../../lib/utils/validation';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import LikeModal from '../components/like/modal';
import { RequestContext } from '../../../lib/utils/requests';
import { EnrollmentModal } from '../components/modals';
import { EducationTypeEnum } from '../../../types/education-type';
import { InfoLike } from '../components/like/info';
import { getDate } from '../../../lib/utils/date';

type Props = {
  serviceId: number;
  requestId: number;
  setEditMode: () => void;
  updateRequest: () => void;
  extendEntityGuid: string;
  contingentGuid: string;
  requestData?: RequestData;
};

const ActionsFormEnrollment: React.FC<Props> = ({
  requestData,
  extendEntityGuid,
  setEditMode,
  updateRequest,
  serviceId,
  requestId,
  contingentGuid,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const enrollment = requestData?.enrollment;

  const [formKey, setFormKey] = useState<number>(0);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { canEditRequestDOGM } = useContext(RequestContext);

  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      await submitEnrollment(values, requestId, setLoading, setShowModal, setEditMode, updateRequest);
    },
    [requestId, updateRequest, setEditMode]
  );

  const enrollmentDisabled = !requestData?.contingentGuid;

  const initialData = useMemo(() => {
    return {
      ...enrollment,
      enrollDocumentTypeId: 3,
      contingentGuid: requestData?.contingentGuid,
    } as RequestEnrollmentData;
  }, [enrollment, requestData?.contingentGuid]);

  const initialErrors = useInitialErrors(initialData, getValidationSchema(requestData?.educationTypeId));

  return loading ? (
    <Panel>
      <div className="loader-container">
        <Loader roller small />
      </div>
    </Panel>
  ) : (
    <Formik
      key={formKey}
      validateOnMount
      enableReinitialize
      onSubmit={submitForm}
      initialValues={initialData}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema(requestData?.educationTypeId)}
    >
      {(formikProps: FormikProps<RequestEnrollmentData>) => {
        const { handleSubmit, isSubmitting, isValid } = formikProps;
        return (
          <>
            <form onSubmit={handleSubmit}>
              <InfoLike requestData={requestData} setOpen={setOpenPopup} disabled={!canEditRequestDOGM} />

              <ServiceClassesEnrollment
                contingentGuid={contingentGuid}
                serviceId={serviceId}
                warningMessage=" Группы обучения для указанного ДО не найдены. Для зачисления доступны только те группы обучения, в
                  которых выбранно указанное ДО."
                type={requestData?.educationTypeId === EducationTypeEnum.ChildrenEducation ? 'EditDogm' : 'EditNonDogm'}
                disabledRadio={enrollmentDisabled}
              />

              <div className="container">
                <Push size={20} />
                <div className="table-data">
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Дата зачисления <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-3">
                        <FormikFormGroup required label="" name="enrollDate">
                          <FormikDatePicker
                            startDate={enrollment?.enrollDate ? getDate(enrollment.enrollDate) : undefined}
                            placeholder={!enrollmentDisabled ? 'ДД.ММ.ГГГГ' : ''}
                            size="small"
                            name="enrollDate"
                            disabled={enrollmentDisabled}
                          />
                        </FormikFormGroup>
                      </div>
                    </div>
                  </div>
                  <Document
                    size={3}
                    name="enrollDocumentType"
                    label="Вид документа-основания"
                    editMode
                    formType={FormTypeEnum.EditRequestEnrollment}
                    required
                    disabled={enrollmentDisabled}
                  />
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Номер и дата документа <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-2">
                        <div>
                          <FormikFormGroup required label="" name="enrollDocNumber">
                            <FormikInput
                              size="small"
                              name="enrollDocNumber"
                              placeholder={!enrollmentDisabled ? 'Введите номер...' : ''}
                              disabled={enrollmentDisabled}
                            />
                          </FormikFormGroup>
                        </div>
                        <div>
                          <FormikFormGroup required label="" name="enrollDocDate">
                            <FormikDatePicker
                              size="small"
                              name="enrollDocDate"
                              placeholder={!enrollmentDisabled ? 'ДД.ММ.ГГГГ' : ''}
                              disabled={enrollmentDisabled}
                              startDate={enrollment?.enrollDocDate ? getDate(enrollment.enrollDocDate) : undefined}
                            />
                          </FormikFormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SimpleTextArea
                    disabled={enrollmentDisabled}
                    name="enrollComment"
                    placeholder={!enrollmentDisabled ? 'Введите...' : ''}
                    editMode
                    label="Комментарий"
                  />
                </div>
              </div>
              <div className="requests-hr" />
              <div className="container">
                <div className="flex justify-end">
                  <Button border primary label="Сбросить" handleClick={() => setFormKey(Math.random())} />
                  <Push size={12} orientation="horizontal" />
                  <Button
                    primary
                    label="Зачислить"
                    disabled={!isValid || isSubmitting || !requestData?.contingentGuid}
                    handleClick={() => setShowModal(true)}
                  />
                </div>
                <Push size={16} />
              </div>
              <EnrollmentModal showModal={showModal} setShowModal={setShowModal} handleSubmit={handleSubmit} />
            </form>

            <LikeModal
              open={openPopup}
              close={setOpenPopup}
              setFormKey={setFormKey}
              requestData={requestData}
              updateRequest={updateRequest}
              extendEntityGuid={extendEntityGuid}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ActionsFormEnrollment;

const getValidationSchema = (educationId: number | undefined) =>
  objectYup().shape({
    contingentGuid: stringYup().required(),
    enrollDate: docDate.required('Выберите дату зачисления').nullable(),
    enrollDocumentTypeId: stringYup().required('Выберите тип документа').nullable(),
    enrollDocNumber: stringYup().required('Введите номер документа').nullable(),
    enrollDocDate: docDate.required('Выберите дату документа').nullable(),
    serviceClass: objectYup()
      .required()
      .nullable()
      .shape({
        id: stringYup().required('Введите группу для зачисления').nullable(),
      }),
  });
