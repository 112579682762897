import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Push } from '@mosru/esz_uikit';
import { ReactFilesFile } from '../../types/files';
import { ReactComponent as IconArrowPrev } from '../../assets/images/icons/arrow-white-left.svg';
import { ReactComponent as IconArrowNext } from '../../assets/images/icons/arrow-white-right.svg';
import { ReactComponent as IconClose } from '../../assets/images/icons/close-big-white.svg';
import { styleVars } from '../../theme/vars';

export type GalleryModalType = {
  show: boolean;
  images: ReactFilesFile[];
  currentImage: ReactFilesFile;
  onCloseHandler: () => void;
};

export const GalleryModal = ({ show, onCloseHandler, currentImage: propsCurrentImage, images }: GalleryModalType) => {
  const [currentImage, setCurrentImage] = useState<ReactFilesFile>(propsCurrentImage);
  const [load, setLoad] = useState<boolean>(false);

  const movePrev = useCallback(() => {
    for (let i = 0; i < images.length; i++) {
      if (images[i].id === currentImage.id) {
        if (i === 0) {
          return;
        }
        setCurrentImage(images[i - 1]);
        setLoad(false);
      }
    }
  }, [currentImage.id, images]);

  const moveNext = useCallback(() => {
    for (let i = 0; i < images.length; i++) {
      if (images[i].id === currentImage.id) {
        if (i === images.length - 1) {
          return;
        }
        setCurrentImage(images[i + 1]);
        setLoad(false);
      }
    }
  }, [currentImage.id, images]);

  useEffect(() => {
    const img = document.createElement('img');
    img.src = currentImage.preview.fullSize ?? '';
    img.onload = () => {
      setLoad(true);
    };
  }, [currentImage.preview.fullSize]);

  useEffect(() => {
    setCurrentImage(propsCurrentImage);
  }, [propsCurrentImage]);

  const moveType = useCallback(
    (ev: KeyboardEvent) => {
      const { keyCode } = ev;

      const prevBtn = 37;
      const nextBtn = 39;

      if (keyCode === prevBtn) {
        movePrev();
      }
      if (keyCode === nextBtn) {
        moveNext();
      }
    },
    [moveNext, movePrev]
  );

  useEffect(() => {
    document.addEventListener('keydown', moveType);
    return () => {
      document.removeEventListener('keydown', moveType);
    };
  }, [moveType]);

  const onClose = () => {
    setCurrentImage(propsCurrentImage);
    onCloseHandler();
  };

  return (
    <Modal gallery show={show} onClose={onClose}>
      <StyleContainer data-test="modal-gallery">
        <StyledButtonClose onClick={onClose} data-test="modal-gallery-close">
          <IconClose />
        </StyledButtonClose>

        {currentImage.preview?.fullSize ? (
          <StyledImageContainer data-test="modal-gallery-img">
            {load && <StyledImg src={`${currentImage.preview?.fullSize}`} />}
          </StyledImageContainer>
        ) : (
          <StyledErrorText data-test="modal-gallery-error-text">
            Фото еще не загружено. <br />
            Фото будет доступно для полноэкранного просмотра после сохранения.
          </StyledErrorText>
        )}

        <StyledControlPanel data-test="modal-gallery-control-panel">
          <button type="button" data-test="modal-gallery-button-prev" onClick={movePrev}>
            <IconArrowPrev />
          </button>
          <Push size={16} orientation="horizontal" />
          <button type="button" data-test="modal-gallery-button-next" onClick={moveNext}>
            <IconArrowNext />
          </button>
        </StyledControlPanel>
      </StyleContainer>
    </Modal>
  );
};

const StyleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledImageContainer = styled.div`
  max-width: 70%;
  margin: 0 auto;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledControlPanel = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
`;

const StyledButtonClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const StyledErrorText = styled.div`
  color: white;
  text-align: center;
  line-height: ${styleVars.lineHeightText};
`;
