import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import { setAsyncValues } from '../../../../../lib/utils/requests';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { ServiceData } from '../../../../../types/service';
import { Dictionary } from '../../fields/dictionary';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';
import { priceMask } from '../../../../../lib/utils/mask';

type CreateFinancingProps = {
  onlyPayment?: boolean;
};

const CreateFinancing = ({ onlyPayment }: CreateFinancingProps) => {
  const { values, setFieldValue, dirty } = useFormikContext<ServiceData>();

  useEffect(() => {
    if (onlyPayment) {
      setFieldValue('financing.typeFinancingId', TypeFinancingEnum.Payment);
    }
  }, [onlyPayment, setFieldValue]);

  useEffect(() => {
    if (dirty) {
      const fieldsToClean = [
        { key: 'financing.typeValueServiceId' },
        { key: 'financing.typeValueServiceName' },
        { key: 'financing.fullPrice' },
        { key: 'financing.lessonPrice' },
        { key: 'financing.periodPrice' },
        { key: 'financing.subsidiesPercent' },
      ];

      setAsyncValues(fieldsToClean, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.financing?.typeFinancingId]);

  return (
    <Panel title={() => <>Финансирование</>}>
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Тип финансирования <span className="table-data__required" />
          </div>
          <div className="table-data__body">
            <Dictionary
              name="typeFinancing"
              parent="financing"
              editMode
              isNotTableMain
              dictionaryFunc={() => dictionariesApi.getTypeFinancingList(true)}
              disabled={onlyPayment}
            />
          </div>
        </div>
        {values.financing?.typeFinancingId !== 3 ? (
          <>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">
                Периодичность оплаты <span className="table-data__required" />
              </div>
              <div className="table-data__body">
                <Dictionary
                  name="typeValueService"
                  parent="financing"
                  editMode
                  isNotTableMain
                  disabled={values.financing?.typeFinancingId === TypeFinancingEnum.Free}
                  dictionaryFunc={dictionariesApi.getTypeValueServiceList}
                />
              </div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Стоимость, руб</div>
              <div className="table-data__body">
                <div className="flex items-start">
                  <div className="table-data__group items-start">
                    <div className="table-data__label table-data__label-start-small">
                      <div>
                        Общая <span className="table-data__required" />
                      </div>
                    </div>
                    <div className="table-data__body" style={{ width: 140 }}>
                      <FormikFormGroup required label="" name="financing.fullPrice">
                        <FormikInput
                          name="financing.fullPrice"
                          size="small"
                          mask={priceMask}
                          placeholder="0,00"
                          disabled={values.financing?.typeFinancingId === TypeFinancingEnum.Free}
                          textRight
                          postfix="₽"
                        />
                      </FormikFormGroup>
                    </div>
                  </div>
                  <Push size={16} orientation="horizontal" />
                  <div className="table-data__group items-start">
                    <div className="table-data__label table-data__label-start-small">
                      <div>
                        За период <span className="table-data__required" />
                      </div>
                    </div>
                    <div className="table-data__body" style={{ width: 140 }}>
                      <FormikFormGroup required label="" name="financing.periodPrice">
                        <FormikInput
                          name="financing.periodPrice"
                          size="small"
                          mask={priceMask}
                          placeholder="0,00"
                          disabled={values.financing?.typeFinancingId === TypeFinancingEnum.Free}
                          textRight
                          postfix="₽"
                        />
                      </FormikFormGroup>
                    </div>
                  </div>
                  <Push size={16} orientation="horizontal" />
                  <div className="table-data__group items-start">
                    <div className="table-data__label table-data__label-start-small">За занятие</div>
                    <div className="table-data__body" style={{ width: 140 }}>
                      <FormikFormGroup required label="" name="financing.lessonPrice">
                        <FormikInput
                          name="financing.lessonPrice"
                          size="small"
                          mask={priceMask}
                          placeholder="0,00"
                          disabled={values.financing?.typeFinancingId === TypeFinancingEnum.Free}
                          textRight
                          postfix="₽"
                        />
                      </FormikFormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Panel>
  );
};

export default CreateFinancing;
