import React, { useCallback, useEffect, useState } from 'react';
import { Button, ModalPanel, Modal, Loader, Push } from '@mosru/esz_uikit';
import contingentApi from '../../../../../lib/api/contingents';
import { RequestData } from '../../../../../types/requests';
import { OtherPerson } from './other-person';
import { Error } from './error';
import { MatchTypeEnum } from '../../../../../mock-data/like-modal-enum';
import { useDegree } from '../../../../../lib/utils/like-modal';
import { ErrorPerson, ListPerson } from '../../../../../types/like-modal';
import Person from './person';
import requestsApi from '../../../../../lib/api/requests';
import { redirect } from '../../../../../lib/utils';
import { useGetAppealLinkQuery } from '../../../../../store/home';

type Props = {
  open: boolean;
  extendEntityGuid: string;
  updateRequest: () => void;
  close: (value: boolean) => void;
  setFormKey: (value: number) => void;
  requestData?: RequestData;
};

const LikeModal = ({ updateRequest, open, close, extendEntityGuid, requestData, setFormKey }: Props) => {
  const [listPerson, setListPerson] = useState<ListPerson[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectLoadingBtn, setSelectLoadingButton] = useState<boolean>(false);
  const [checked, setChecked] = useState<number | null>(null);
  const [loadingBtnNewPerson, setLoadingBtnNewPerson] = useState<boolean>(false);
  const [showSTP, setShowSTP] = useState(false);

  const matchType = useDegree(listPerson);
  const showButtonSTP = matchType === MatchTypeEnum.oneMedium || matchType === MatchTypeEnum.severalWithHigh || showSTP;

  const { data: appealLink = '' } = useGetAppealLinkQuery(undefined, { skip: !showButtonSTP });

  const [error, setError] = useState<ErrorPerson>({
    text: '',
    color: '',
    show: false,
  });

  const getListPerson = useCallback(async (): Promise<ListPerson[] | undefined> => {
    if (extendEntityGuid) {
      const response = await contingentApi.getPersonLike(extendEntityGuid);
      setSelectLoadingButton(true);

      setListPerson(response);
      setLoading(true);

      return response;
    }
  }, [extendEntityGuid]);

  useEffect(() => {
    extendEntityGuid && open && getListPerson();
  }, [extendEntityGuid, getListPerson, open]);

  const newPerson = async (requestId: number | undefined) => {
    setLoadingBtnNewPerson(true);
    if (requestId) {
      const response = await requestsApi.newPerson(requestId);

      if (response.message) {
        setError({
          text: response.message,
          color: 'danger',
          show: true,
        });
        setLoadingBtnNewPerson(false);
        setShowSTP(true);
      } else {
        const listPerson = await getListPerson();
        if (listPerson?.length) {
          setLoadingBtnNewPerson(false);
          setError({
            text: response,
            color: 'success',
            show: true,
          });
          setShowSTP(false);
        }
      }
    }
  };

  const selectPerson = async () => {
    const findPerson = listPerson.find((item) => item.person.id === checked);
    setSelectLoadingButton(true);

    try {
      await contingentApi.updatePerson(extendEntityGuid, findPerson?.person);
      setSelectLoadingButton(false);
      updateRequest();
      close(false);
      setFormKey(Math.random());
    } catch (e) {}
  };

  listPerson.sort((a, b) => {
    const labelA = a.degree;
    const labelB = b.degree;
    if (labelA > labelB) {
      return -1;
    }
    if (labelB < labelA) {
      return 1;
    }
    return 0;
  });

  const sendSTP = () => {
    redirect(appealLink);
    close(false);
  };

  return (
    <Modal show={open} onClose={() => close(false)}>
      <ModalPanel
        overflow
        onClose={() => close(false)}
        size="medium"
        controls={() => (
          <>
            <Button label="Отмена" primary border size="small" onClick={() => close(false)} />

            {loading ? (
              <>
                {showButtonSTP && (
                  <>
                    <Push orientation="horizontal" size={12} />
                    <Button primary border size="small" label="Обратиться в СТП" onClick={sendSTP} />
                  </>
                )}
                {(matchType === MatchTypeEnum.oneLow ||
                  matchType === MatchTypeEnum.several ||
                  matchType === MatchTypeEnum.noMatch) && (
                  <>
                    <Push orientation="horizontal" size={12} />
                    <div style={{ width: 204 }}>
                      <Button
                        primary
                        size="small"
                        fullWidth
                        load={loadingBtnNewPerson}
                        label="Создать новую персону"
                        onClick={() => newPerson(requestData?.id)}
                        border={matchType !== MatchTypeEnum.noMatch}
                      />
                    </div>
                  </>
                )}
                {matchType !== MatchTypeEnum.noMatch && (
                  <>
                    <Push orientation="horizontal" size={12} />
                    <Button
                      label="Выбрать"
                      disabled={!checked}
                      load={!selectLoadingBtn}
                      primary
                      size="small"
                      onClick={selectPerson}
                    />
                  </>
                )}
              </>
            ) : null}
          </>
        )}
        modalTitle="Проверка сведений об обучающемся"
        renderComponent={() => (
          <div className="requests-check-details">
            <div className="requests-check-details__item">
              <Person requestData={requestData} />

              <div className="requests-check-details__inner line-height-text">
                <Error {...error} />

                {loading && !error.text
                  ? matchType === MatchTypeEnum.oneLow
                    ? 'По указанным Вами данным найдена похожая персона:'
                    : matchType === MatchTypeEnum.oneMedium
                    ? 'Указанные Вами данные частично совпадают с данными обучающегося в Реестре контингента. Вы можете использовать данные найденной персоны, нажав на кнопку “Выбрать”.'
                    : matchType === MatchTypeEnum.oneHigh
                    ? 'Данные обучающегося успешно найдены в Реестре контингента:'
                    : matchType === MatchTypeEnum.several
                    ? 'По указанным Вами данным найдено несколько похожих персон:'
                    : matchType === MatchTypeEnum.severalWithHigh
                    ? 'По указанным Вами данным найдено соответствие с несколькими персонами:'
                    : 'По указанным Вами данным похожие персоны не найдены. Для создания новой персоны нажмите кнопку "Создать новую персону”.'
                  : null}
              </div>
            </div>
            {loading ? (
              listPerson.map((item: ListPerson) => {
                if (listPerson.length === 1) {
                  setChecked(item.person.id);
                }
                return (
                  <OtherPerson
                    checked={checked}
                    id={item.person.id}
                    key={item.person.id}
                    degree={item.degree}
                    setChecked={setChecked}
                    snils={item.person.snils}
                    lastname={item.person.lastname}
                    gender_id={item.person.gender_id}
                    firstname={item.person.firstname}
                    birthdate={item.person.birthdate}
                    patronymic={item.person.patronymic}
                    documents_react={item.person.documents_react}
                    documentTypeId={requestData?.child.documentTypeId}
                    documentTypeName={requestData?.child.documentTypeName}
                  />
                );
              })
            ) : (
              <div className="flex justify-center">
                <div>
                  <Push size={40} />
                  <Loader roller small />
                  <Push size={50} />
                </div>
              </div>
            )}

            {loading
              ? (matchType === MatchTypeEnum.oneLow ||
                  matchType === MatchTypeEnum.oneMedium ||
                  matchType === MatchTypeEnum.several ||
                  matchType === MatchTypeEnum.severalWithHigh) && (
                  <div className="requests-check-details__item">
                    <div className="requests-check-details__inner line-height-text">
                      {matchType === MatchTypeEnum.oneLow
                        ? 'Для использования найденной персоны нажмите кнопку “Выбрать”. Для создания новой персоны нажмите кнопку “Создать новую персону”.'
                        : matchType === MatchTypeEnum.oneMedium
                        ? 'Если Вы уверены, что найденные данные содержат ошибку, то обратитесь в СТП. Создать нового обучающегося с указанными данными невозможно.'
                        : matchType === MatchTypeEnum.several
                        ? 'Выберите одну из найденных персон и нажмите кнопку “Выбрать”.  Для создания новой персоны нажмите кнопку “Создать новую персону”.'
                        : matchType === MatchTypeEnum.severalWithHigh
                        ? 'Если Вы уверены, что найденные данные содержат ошибку, то обратитесь в СТП. Создать нового обучающегося с указанными данными невозможно.'
                        : ''}
                    </div>
                  </div>
                )
              : null}
          </div>
        )}
      />
    </Modal>
  );
};

export default LikeModal;
