import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import classNames from 'classnames';
import { Button, Input, Select, SelectOptionType, Panel, Toggle, Push, AsyncTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { ReactComponent as IconEditGray } from '../../../../assets/images/icons/edit.svg';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconCheck } from '../../../../assets/images/icons/check.svg';
import { ReactComponent as IconClose } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as IconDown } from '../../../../assets/images/icons/down.svg';
import { LoaderCustom } from '../../../../components/loader-custom';
import { templateApi } from '../../../../lib/api/template';
import { ServiceContext } from '../../index';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import lookupApi from '../../../../lib/api/lookup';
import RemoveModal from '../../../../components/remove-modal';
import AddOrganizationPrevYear from '../modals/add-organization-prev-year';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';
import { TemplateService } from '../../../../types/service';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { Notification } from '../modals/notification';

const initialData: Omit<TemplateService.AdmissionPlanList, 'templateId' | 'educationTypeId' | 'count'> = {
  organizationId: null,
  organizationName: null,
  volume: 0,
  id: 0,
  yearOfTrainingId: 0,
  yearOfTrainingName: '',
};

type State = {
  pageNumber: number;
  total: number;
  currentOrganization: TemplateService.AdmissionPlanList;
  yearOfTraining: SelectOptionType[];
  data: TemplateService.AdmissionPlanList[] | [];
  currentYearOfTraining: SelectOptionType | null;
  openRemoveModal: boolean;
  openOrganizationPrevYear: boolean;
  canChangeAdmissionPlan: boolean;
  openOrganizationAlreadyAddedModal: boolean;
};

const PAGE_SIZE = 10;

const Organizations = () => {
  const { serviceData, userProfile, accessPanelEdit } = useContext(ServiceContext);

  const [state, dispatch] = useReducer<Reducer<State, Partial<State>>>(
    (state, dispatch) => ({ ...state, ...dispatch }),
    {
      pageNumber: 0,
      total: 0,
      currentOrganization: initialData,
      yearOfTraining: [],
      data: [],
      currentYearOfTraining: null,
      openRemoveModal: false,
      openOrganizationPrevYear: false,
      canChangeAdmissionPlan: serviceData.admissionPlan?.canChangeAdmissionPlan ?? false,
      openOrganizationAlreadyAddedModal: false,
    }
  );

  const [currentPageNum, setCurrentPageNum] = useState(state.pageNumber);
  const [volumeOrganization, setVolumeOrganization] = useState<TemplateService.AdmissionPlanList['volume']>(
    initialData.volume
  );

  const [currentYear, setCurrentYear] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const transformTableData = useCallback(
    (data: TemplateService.AdmissionPlanList[]) =>
      data.map((item, index) => ({ ...item, count: PAGE_SIZE * state.pageNumber + index + 1 })),
    [state.pageNumber]
  );

  const getTableData = useCallback(async () => {
    if (state.yearOfTraining.length && state.currentYearOfTraining) {
      setIsLoading(true);
      try {
        const response = await templateApi.getAdmissionPlanList({
          templateId: serviceData.id,
          pageNumber: state.pageNumber + 1,
          pageSize: PAGE_SIZE,
          yearOfTrainingId: state.currentYearOfTraining.value as number,
        });

        dispatch({ data: transformTableData(response.items), total: response.totalCount });
      } finally {
        setIsLoading(false);
      }
    }

    setCurrentPageNum(state.pageNumber);
  }, [transformTableData, serviceData.id, state.currentYearOfTraining, state.pageNumber, state.yearOfTraining.length]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  useEffect(() => {
    (async () => {
      const response = await dictionariesApi.getYearOfTrainings();
      const current = await dictionariesApi.getCurrentYearOfTrainings();

      setCurrentYear(current.id);
      const selectedCurrentYear = response.find(({ value }) => value === current?.id) || response[response.length - 1];
      dispatch({ yearOfTraining: response, currentYearOfTraining: selectedCurrentYear });
    })();
  }, []);

  const updateOrganization = useCallback(
    async (templateId: number, id: number, values: TemplateService.AdmissionPlanList) => {
      const response = await templateApi.updateAdmissionPlan(templateId, id, { ...values, volume: volumeOrganization });

      if (typeof response !== 'number' && response?.message) {
        dispatch({ openOrganizationAlreadyAddedModal: true });
      }

      await getTableData();
    },
    [getTableData, volumeOrganization]
  );

  const updateInfoDataAdmissionPlan = useCallback(
    async (canChangeAdmissionPlan: boolean) => {
      await templateApi.updateInfoDataAdmissionPlan(serviceData.id, {
        templateId: serviceData.id,
        educationTypeId: serviceData.educationTypeId,
        canChangeAdmissionPlan,
      });
      dispatch({ canChangeAdmissionPlan });
    },
    [serviceData.educationTypeId, serviceData.id]
  );

  const deleteOrganization = useCallback(
    async (templateId: number, id: number) => {
      await templateApi.deleteAdmissionPlan(templateId, id);
      await getTableData();
    },
    [getTableData]
  );

  const createOrganization = useCallback(
    async (templateId: number, values: TemplateService.AdmissionPlanList) => {
      const newOrganization = {
        ...values,
        volume: volumeOrganization,
        templateId,
        yearOfTrainingId: state.currentYearOfTraining?.value as number,
        educationTypeId: serviceData.educationTypeId,
      };

      const response = await templateApi.createAdmissionPlan(templateId, newOrganization);

      if (typeof response !== 'number' && response?.message) {
        dispatch({ openOrganizationAlreadyAddedModal: true });
      }
      await getTableData();
    },
    [getTableData, serviceData.educationTypeId, state.currentYearOfTraining?.value, volumeOrganization]
  );

  const deleteAllAdmissionPlanPeriod = useCallback(
    async (templateId: number, yearOfTrainingId: number) => {
      await templateApi.deleteAllAdmissionPlanPeriod(templateId, yearOfTrainingId);
      if (state.pageNumber) {
        dispatch({ pageNumber: 0 });
      } else {
        await getTableData();
      }
    },
    [getTableData, state.pageNumber]
  );

  const addAllOrganizationRegister = useCallback(
    async (templateId: number) => {
      await templateApi.getAllOrganizationAdmissionPlan(templateId);
      await getTableData();
    },
    [getTableData]
  );

  const getOptionsOrganization = useCallback(
    async (query: string) => await lookupApi.getOrganization(query, userProfile.vedomstvoId),
    [userProfile.vedomstvoId]
  );

  const disabledNewOrganization = state.data && state.data.every((item) => item.id > 0);

  const canEdit =
    serviceData.id &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    accessPanelEdit;

  const isCurrentYear =
    !!currentYear && !!state.currentYearOfTraining?.value && state.currentYearOfTraining.value === currentYear;

  const columns = useMemo(
    () => [
      {
        dataIndex: 'count',
        title: '№',
        render: (item: any) => (
          <div
            className={classNames(
              state.currentOrganization.id === item.id && 'table-row-item-height flex items-center'
            )}
          >
            {item.count}
          </div>
        ),
        width: '40px',
      },
      {
        dataIndex: 'organizationName',
        title: 'Наименование организации',
        render: (item: any) =>
          state.currentOrganization.id === item.id ? (
            <Select
              name="currentOrganization"
              options={[]}
              size="small"
              onChange={(option) => {
                const organization = option as SelectOptionType;
                dispatch({
                  currentOrganization: {
                    ...state.currentOrganization,
                    organizationName: organization?.label,
                    organizationId: organization?.value as number,
                  },
                });
              }}
              loadOptions={getOptionsOrganization}
              placeholder="Начните вводить..."
              value={
                Boolean(state.currentOrganization.organizationId) && {
                  label: state.currentOrganization.organizationName,
                  value: state.currentOrganization.organizationId,
                }
              }
            />
          ) : (
            item.organizationName
          ),
        width: '60%',
      },
      {
        dataIndex: 'volume',
        title: 'План приема на период обучения',
        render: (item: any) => (
          <div className="flex">
            <div className="flex-auto">
              {state.currentOrganization.id === item.id ? (
                <div style={{ width: 80 }}>
                  <Input
                    onChange={(event) => setVolumeOrganization(Number(event.target.value))}
                    number
                    size="small"
                    placeholder="0"
                    setValue={setVolumeOrganization}
                    onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    value={volumeOrganization ?? undefined}
                  />
                </div>
              ) : (
                item.volume
              )}
            </div>
            <div className="flex-none">
              {state.currentOrganization.id === item.id ? (
                <div className="flex">
                  <Button
                    iconLeft={() => <IconClose />}
                    border
                    size="small"
                    handleClick={() => {
                      dispatch({ currentOrganization: initialData });
                      setVolumeOrganization(initialData.volume);
                      getTableData();
                    }}
                  />
                  <Push size={8} orientation="horizontal" />
                  <Button
                    success
                    border
                    size="small"
                    iconLeft={() => <IconCheck />}
                    disabled={!state.currentOrganization.organizationId}
                    handleClick={() => {
                      item.id > 0
                        ? updateOrganization(serviceData.id, item.id, state.currentOrganization)
                        : createOrganization(serviceData.id, state.currentOrganization);
                      dispatch({ currentOrganization: initialData });
                      setVolumeOrganization(0);
                    }}
                  />
                </div>
              ) : (
                <DropDown
                  component={() =>
                    canEdit && (
                      <span className="drop-down-btn-round">
                        <IconDots />
                      </span>
                    )
                  }
                >
                  <div className="drop-down-panel">
                    <div className="drop-down-panel__list">
                      <button
                        type="button"
                        onClick={() => {
                          dispatch({ currentOrganization: item });
                          setVolumeOrganization(item.volume);
                        }}
                        className="drop-down-panel__list-item"
                      >
                        <IconEditGray />
                        Редактировать
                      </button>
                      <button
                        type="button"
                        onClick={() => deleteOrganization(serviceData.id, item.id)}
                        className="drop-down-panel__list-item"
                      >
                        <IconRemove />
                        Удалить
                      </button>
                    </div>
                  </div>
                </DropDown>
              )}
            </div>
          </div>
        ),
      },
    ],
    [
      canEdit,
      createOrganization,
      deleteOrganization,
      getOptionsOrganization,
      getTableData,
      serviceData.id,
      state.currentOrganization,
      updateOrganization,
      volumeOrganization,
    ]
  );

  return (
    <>
      <>
        <Push size={12} />
        <Panel
          title={() => (
            <div className="flex items-center">
              Организации
              <span className="color-gray-dark">
                {' \u00A0'} {state.total > 0 && state.total}
              </span>
              <div className="flex font-weight-base font-size-small">
                <Push orientation="horizontal" size={16} />
                <div style={{ width: 224 }}>
                  <Select
                    name="yearOfTraining"
                    size="small"
                    placeholder="Учебный год"
                    options={state.yearOfTraining}
                    value={state.currentYearOfTraining}
                    onChange={(option) =>
                      dispatch({ currentYearOfTraining: option as SelectOptionType, pageNumber: 0 })
                    }
                  />
                </div>
              </div>
            </div>
          )}
          headingControl={() => {
            return (
              <div className="flex items-center">
                {canEdit && (
                  <>
                    <Button
                      border
                      label="Удалить все"
                      size="small"
                      disabled={state.data.length === 0}
                      handleClick={() => dispatch({ openRemoveModal: true })}
                    />
                    <Push orientation="horizontal" size={16} />
                    <DropDown
                      component={() => (
                        <span className="drop-down-btn-round">
                          <Button primary iconRight={() => <IconDown />} label="Добавить" size="small" />
                        </span>
                      )}
                    >
                      <div className="drop-down-panel">
                        <div className="drop-down-panel__list">
                          <button
                            type="button"
                            disabled={!disabledNewOrganization}
                            onClick={() => {
                              dispatch({ data: [initialData, ...state.data] });
                            }}
                            className="drop-down-panel__list-item"
                          >
                            Организацию
                          </button>
                          <button
                            type="button"
                            onClick={() => addAllOrganizationRegister(serviceData.id)}
                            className="drop-down-panel__list-item"
                            disabled={!isCurrentYear}
                          >
                            Все организации из реестра
                          </button>
                          <button
                            type="button"
                            onClick={() => dispatch({ openOrganizationPrevYear: true })}
                            className="drop-down-panel__list-item"
                            disabled={!isCurrentYear}
                          >
                            Все организации с предыдущего года
                          </button>
                        </div>
                      </div>
                    </DropDown>
                    <Push orientation="horizontal" size={16} />
                  </>
                )}
                <Toggle
                  labelId=""
                  size="xsmall"
                  disabled={!canEdit}
                  value={state.canChangeAdmissionPlan}
                  onChange={(value) => updateInfoDataAdmissionPlan(value)}
                />
              </div>
            );
          }}
        >
          {isLoading ? (
            <LoaderCustom size={320} />
          ) : (
            <Table
              overflow
              hideSort
              columns={columns}
              data={state.data}
              pageSize={PAGE_SIZE}
              itemsAll={state.total || state.data.length}
              pageNum={currentPageNum}
              setPageNumber={(value) => dispatch({ pageNumber: value })}
            />
          )}
        </Panel>
      </>

      <RemoveModal
        buttonReverse
        show={state.openRemoveModal}
        title="Удаление всех организаций"
        description={
          <>
            Внимание! Все организации из текущего плана приема будут удалены.
            <br /> Продолжить?
          </>
        }
        onCloseHandle={() => {
          dispatch({ openRemoveModal: false });
        }}
        onRemoveHandler={() => {
          deleteAllAdmissionPlanPeriod(serviceData.id, state?.currentYearOfTraining?.value as number);
          dispatch({ openRemoveModal: false });
        }}
        deleteBtnTitle="Да, продолжить"
      />

      <AddOrganizationPrevYear
        title="Добавление организаций с предыдущего года"
        open={state.openOrganizationPrevYear}
        onCloseHandler={() => {
          dispatch({ openOrganizationPrevYear: false });
        }}
        onSaveHandler={async () => {
          await templateApi.copyAdmissionPlanLastYear(serviceData.id);
          await getTableData();
          dispatch({ openOrganizationPrevYear: false });
        }}
      />

      <Notification
        description={
          'План приема на эту организацию уже добавлен. Вы можете отредактировать его, нажав на кнопку "Редактировать"'
        }
        open={state.openOrganizationAlreadyAddedModal}
        title="План приема на период обучения"
        onCloseHandler={() => {
          dispatch({
            openOrganizationAlreadyAddedModal: false,
            data: state.data.filter((el) => el.id),
            currentOrganization: initialData,
          });
        }}
      />
    </>
  );
};

export default Organizations;
