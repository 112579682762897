import { Reducer, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { reportsApi } from '../lib/api/reports';
import { FilterValue, GetDataRequest, OptionFilter } from '../types/reports';
import { fillTypeSwitch, parameterFieldFilter } from '../lib/utils/reports';
import { AppState } from '../redux/types/state';
import { userProfileSelector } from '../redux/selectors';

export const useGetDataReport = (reportId: number) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [state, setState] = useReducer<Reducer<GetDataRequest, Partial<GetDataRequest>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      sourceFinance: [],
      typeOrganizationId: 0,
      longReport: false,
      filterData: null,
    }
  );

  useEffect(() => {
    const fetch = async () => {
      const reportInfo = await reportsApi.getReports({ reportId });
      setState({ filterData: reportInfo });
      // если больше 2 делаем другой запрос на формирования отчета, так как долгое ожидание и отдает 500 ошибку
      setState({ longReport: reportInfo.reportTypeId > 2 });
      setState({ educationType: reportInfo.educationTypeId });

      return reportInfo.filterList.forEach((item: OptionFilter) => {
        if (item.parameterField === parameterFieldFilter.typeFinansingId && item.visible) {
          (async () => {
            const sourceStatus = item.listItems?.map((i: FilterValue) => {
              return {
                id: i.id,
                name: i.name,
                selected: false,
              };
            });
            const transformTypesFinance = fillTypeSwitch(sourceStatus);
            setState({ sourceFinance: transformTypesFinance });
          })();
        }
      });
    };

    if (reportId !== 0) {
      fetch();
    }
  }, [reportId, userProfile]);

  return state;
};
