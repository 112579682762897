import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { Button, ModalPanel, Modal, Infobox, SelectOptionType, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import { NextStage, ServiceClassTransferNextStage } from '../../../../types/service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';

type Props = {
  open: boolean;
  close: () => void;
};

const TransferNextStage: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [people, setPeople] = useState<NextStage[]>([]);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);

  const initialValues: ServiceClassTransferNextStage = {
    documentTypeId: 3,
    docNumber: '',
    docDate: '',
    comment: '',
    educationTypeId: serviceClassData.educationTypeId,
    megaRelationIds: [],
  };

  const submitForm = useCallback(
    async (values: ServiceClassTransferNextStage) => {
      setLoadBtn(true);
      try {
        values.megaRelationIds = people;
        values.educationTypeId = serviceClassData.educationTypeId;
        await serviceClassApi.transferNextStage(serviceClassData.id || 0, values);
        fetchTable();
        setSelected([]);
        setOpenDetails(false);
        close();
      } finally {
        setLoadBtn(false);
      }
    },
    [serviceClassData.educationTypeId, serviceClassData.id, people, close, fetchTable, setSelected, setOpenDetails]
  );

  useEffect(() => {
    const fetch = async () => {
      if (serviceClassData.id) {
        const data = await serviceClassApi.nextStageSearch(
          serviceClassData.id,
          selected,
          serviceClassData.educationTypeId
        );
        setPeople(data);
      }
    };
    open && fetch();
  }, [serviceClassData.id, selected, serviceClassData.educationTypeId, open]);

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(3);
      setDocuments(documentTypes);
    };
    open && fetch();
  }, [open]);

  const peopleHasNotNextStage = useMemo(
    () => people.filter((value) => !value.nextStageId).map((value) => value.pupilName),
    [people]
  );

  return (
    <Modal show={open} onClose={close}>
      <Formik
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<ServiceClassTransferNextStage>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={close} />
                    <Push orientation="horizontal" size={12} />
                    <Button
                      label="Перевести"
                      disabled={!isValid || isSubmitting || !!peopleHasNotNextStage.length}
                      load={loadBtn}
                      primary
                      size="small"
                      submit
                    />
                  </>
                )}
                size="medium"
                modalTitle="Перевод на следующий этап обучения"
                renderComponent={() => (
                  <>
                    {peopleHasNotNextStage.length ? (
                      <Infobox
                        text={`По ученикам: ${peopleHasNotNextStage.join(
                          ', '
                        )} невозможно произвести перевод. Пожалуйста, исключите этих учеников из списка и повторите процедуру`}
                        color="danger"
                      />
                    ) : null}
                    <Push size={16} />
                    <Table
                      data={people}
                      hideSort
                      columns={[
                        {
                          dataIndex: 'pupilName',
                          title: 'ФИО',
                          render: (item: any) => item.pupilName,
                          width: '30%',
                        },
                        {
                          dataIndex: 'serviceName',
                          title: 'Специализация',
                          render: (item: any) => item.serviceName,
                          width: '30%',
                        },
                        {
                          dataIndex: 'currentStage',
                          title: 'Этап до',
                          render: (item: any) => <span className="color-gray-dark">{item.currentStage}</span>,
                          width: '20%',
                        },
                        {
                          dataIndex: 'nextStage',
                          title: 'Этап после',
                          render: (item: any) => <span className="color-gray-dark">{item.nextStage}</span>,
                          width: '20%',
                        },
                      ]}
                    />
                    <Push size={32} />
                    <FormikFormGroup name="documentTypeId" label="Тип документа" required>
                      <FormikSelect
                        name="documentTypeId"
                        size="small"
                        isSearchable
                        options={documents}
                        placeholder="Выберите..."
                      />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="docNumber" label="Номер документа">
                      <FormikInput size="small" name="docNumber" placeholder="Введите..." />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="docDate" label="Дата">
                      <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="docDate" />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="comment" label="Причина перевода">
                      <FormikTextarea name="comment" placeholder="Введите..." rows={2} />
                    </FormikFormGroup>
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TransferNextStage;

const getValidationSchema = () =>
  objectYup().shape({
    documentTypeId: stringYup().required('Выберите тип документа'),
    docDate: docDate.nullable(),
  });
