import React, { useCallback, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { CheckboxGroup, Checkbox, Switcher, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../../components/formik/formik-input';
import { ServiceData } from '../../../../../../types/service';
import { allowOnlyNumbers } from '../../../../../../lib/utils/validation';
import FormikToggleGroup from '../../../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../../../components/formik/formik-toggle';

type Props = { editMode: boolean; isCreate?: boolean; parent?: string; disabled?: boolean; isTemplate?: boolean };

const fieldFemaleStart = 'altFemaleStart';
const fieldFemaleEnd = 'altFemaleEnd';
const fieldMaleStart = 'altStart';
const fieldMaleEnd = 'altEnd';
const fieldAllStart = 'allStart';
const fieldAllEnd = 'allEnd';
const fieldIsFemalePresent = 'isFemalePresent';
const fieldIsMalePresent = `isMalePresent`;
const fieldGenderAll = `genderAll`;
const fieldCanChangeSexOrAge = `canChangeSexOrAge`;

export const GroupMembersField: React.FC<Props> = ({ isTemplate, editMode, isCreate, parent, disabled }) => {
  const { initialValues, values, setFieldValue, errors } = useFormikContext<
    ServiceData & { allStart: number; allEnd: number }
  >();
  const [initialised, setInitialised] = useState(false);

  const femaleStart = parent ? `${parent}.${fieldFemaleStart}` : fieldFemaleStart;
  const femaleEnd = parent ? `${parent}.${fieldFemaleEnd}` : fieldFemaleEnd;

  const maleStart = parent ? `${parent}.${fieldMaleStart}` : fieldMaleStart;
  const maleEnd = parent ? `${parent}.${fieldMaleEnd}` : fieldMaleEnd;

  const allStart = parent ? `${parent}.${fieldAllStart}` : fieldAllStart;
  const allEnd = parent ? `${parent}.${fieldAllEnd}` : fieldAllEnd;

  const isFemalePresent = parent ? `${parent}.${fieldIsFemalePresent}` : fieldIsFemalePresent;
  const isMalePresent = parent ? `${parent}.${fieldIsMalePresent}` : fieldIsMalePresent;

  const genderAll = parent ? `${parent}.${fieldGenderAll}` : fieldGenderAll;

  const canChangeSexOrAge = parent ? `${parent}.${fieldCanChangeSexOrAge}` : fieldCanChangeSexOrAge;

  const [, , setAllStartLabel] = useField(allStart);
  const [, , setAllEndLabel] = useField(allEnd);

  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;
      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  useEffect(() => {
    setInitialised(false);
  }, [editMode, initialValues]);

  useEffect(() => {
    if (initialised) {
      return;
    }
    if (
      (getFieldValue(fieldIsFemalePresent) === true &&
        getFieldValue(fieldIsMalePresent) === true &&
        getFieldValue(fieldFemaleStart) === getFieldValue(fieldMaleStart) &&
        getFieldValue(fieldFemaleEnd) === getFieldValue(fieldMaleEnd)) ||
      (getFieldValue(fieldIsFemalePresent) === null &&
        getFieldValue(fieldIsMalePresent) === null &&
        getFieldValue(fieldFemaleStart) === null &&
        getFieldValue(fieldFemaleEnd) === null)
    ) {
      setFieldValue(genderAll, true);
      setFieldValue(allStart, getFieldValue(fieldMaleStart));
      setFieldValue(allEnd, getFieldValue(fieldMaleEnd));
      setFieldValue(isFemalePresent, true);
      setFieldValue(isMalePresent, true);
    }
    setInitialised(true);
  }, [values, initialised, allEnd, allStart, getFieldValue, setFieldValue, genderAll, isMalePresent, isFemalePresent]);

  const onChangeGender = (all: boolean) => {
    setFieldValue(genderAll, all);

    setTimeout(() => {
      setAllStartLabel.setTouched(true);
      setAllEndLabel.setTouched(true);
    }, 0);

    if (!all) {
      setFieldValue(isFemalePresent, false);
      setFieldValue(isMalePresent, false);
    } else {
      setFieldValue(isFemalePresent, true);
      setFieldValue(isMalePresent, true);
    }
  };

  const start = getFieldValue(fieldAllStart);
  useEffect(() => {
    if (getFieldValue(fieldAllStart) !== undefined) {
      setFieldValue(maleStart, getFieldValue(fieldAllStart));
      setFieldValue(femaleStart, getFieldValue(fieldAllStart));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  const end = getFieldValue(fieldAllEnd);
  useEffect(() => {
    if (getFieldValue(fieldAllEnd) !== undefined) {
      setFieldValue(maleEnd, getFieldValue(fieldAllEnd));
      setFieldValue(femaleEnd, getFieldValue(fieldAllEnd));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end]);

  const buildViewValue = (label: string, start: string, end: string) => (
    <>
      <div className="table-data__group">
        <div className="table-data__label">Пол</div>
        <div className="table-data__body" style={{ width: 218 }}>
          {label}
        </div>
      </div>
      <div className="table-data__group">
        <div className="table-data__label">Возраст</div>
        {getFieldValue(start)} - {getFieldValue(end)} лет
      </div>
    </>
  );

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Состав группы {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="flex">
            <Switcher size="small">
              <button
                type="button"
                disabled={disabled}
                onClick={() => onChangeGender(true)}
                className={getFieldValue(fieldGenderAll) ? 'active' : ''}
              >
                Все
              </button>
              <button
                type="button"
                disabled={disabled}
                onClick={() => onChangeGender(false)}
                className={!getFieldValue(fieldGenderAll) ? 'active' : ''}
              >
                Муж/Жен
              </button>
            </Switcher>

            {getFieldValue(fieldGenderAll) && (
              <>
                <Push size={16} orientation="horizontal" />
                <div style={{ width: 80 }}>
                  <FormikFormGroup required label="" name={allStart}>
                    <FormikInput
                      name={allStart}
                      size="small"
                      number
                      placeholder="0"
                      disabled={disabled}
                      onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    />
                  </FormikFormGroup>
                </div>

                <Push size={8} orientation="horizontal" />
                <div>
                  <div className="table-row-item-height flex items-center">—</div>
                </div>
                <Push size={8} orientation="horizontal" />

                <div style={{ width: 80 }}>
                  <FormikFormGroup required label="" name={allEnd}>
                    <FormikInput
                      onKeyPress={(e: any) => {
                        allowOnlyNumbers(e);
                      }}
                      name={allEnd}
                      size="small"
                      number
                      placeholder="0"
                      disabled={disabled}
                    />
                  </FormikFormGroup>
                </div>
              </>
            )}

            {!getFieldValue(fieldGenderAll) && (
              <>
                <Push size={16} orientation="horizontal" />
                <div className="table-row-item-height flex items-center">
                  <CheckboxGroup
                    error={errors.info?.isMalePresent}
                    label="Муж"
                    checked
                    labelId={isMalePresent}
                    onChange={() => {}}
                  >
                    <Checkbox
                      disabled={disabled}
                      labelId={isMalePresent}
                      onChange={() => {
                        setFieldValue(isMalePresent, !getFieldValue(fieldIsMalePresent));
                      }}
                      checked={getFieldValue('isMalePresent')}
                    />
                  </CheckboxGroup>
                </div>

                <Push size={16} orientation="horizontal" />
                <div style={{ width: 80 }}>
                  <FormikFormGroup required label="" name={maleStart}>
                    <FormikInput
                      onKeyPress={(e: any) => {
                        allowOnlyNumbers(e);
                      }}
                      name={maleStart}
                      size="small"
                      number
                      placeholder="0"
                      disabled={!getFieldValue(fieldIsMalePresent) || disabled}
                    />
                  </FormikFormGroup>
                </div>

                <Push size={8} orientation="horizontal" />
                <div>
                  <div className="table-row-item-height flex items-center">—</div>
                </div>
                <Push size={8} orientation="horizontal" />

                <div style={{ width: 80 }}>
                  <FormikFormGroup required label="" name={maleEnd}>
                    <FormikInput
                      onKeyPress={(e: any) => {
                        allowOnlyNumbers(e);
                      }}
                      name={maleEnd}
                      size="small"
                      number
                      placeholder="0"
                      disabled={!getFieldValue(fieldIsMalePresent) || disabled}
                    />
                  </FormikFormGroup>
                </div>

                <Push size={16} orientation="horizontal" />

                <div className="table-row-item-height flex items-center">
                  <CheckboxGroup
                    error={errors.info?.isFemalePresent}
                    label="Жен"
                    checked
                    labelId={isFemalePresent}
                    onChange={() => {}}
                  >
                    <Checkbox
                      error={errors.info?.isFemalePresent}
                      labelId={isFemalePresent}
                      onChange={() => {
                        setFieldValue(isFemalePresent, !getFieldValue(fieldIsFemalePresent));
                      }}
                      checked={getFieldValue(fieldIsFemalePresent)}
                      disabled={disabled}
                    />
                  </CheckboxGroup>
                </div>

                <Push size={16} orientation="horizontal" />
                <div style={{ width: 80 }}>
                  <FormikFormGroup required label="" name={femaleStart}>
                    <FormikInput
                      onKeyPress={(e: any) => {
                        allowOnlyNumbers(e);
                      }}
                      name={femaleStart}
                      size="small"
                      number
                      placeholder="0"
                      disabled={!getFieldValue(fieldIsFemalePresent) || disabled}
                    />
                  </FormikFormGroup>
                </div>

                <Push size={8} orientation="horizontal" />
                <div>
                  <div className="table-row-item-height flex items-center">—</div>
                </div>
                <Push size={8} orientation="horizontal" />

                <div style={{ width: 80 }}>
                  <FormikFormGroup required label="" name={femaleEnd}>
                    <FormikInput
                      onKeyPress={(e: any) => {
                        allowOnlyNumbers(e);
                      }}
                      name={femaleEnd}
                      size="small"
                      number
                      placeholder="0"
                      disabled={!getFieldValue(fieldIsFemalePresent) || disabled}
                    />
                  </FormikFormGroup>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            {getFieldValue(fieldGenderAll) ? (
              buildViewValue('Все', 'allStart', 'allEnd')
            ) : (
              <>
                {getFieldValue(fieldIsMalePresent) && buildViewValue('Муж', 'altStart', 'altEnd')}
                <Push size={128} orientation="horizontal" />
                {getFieldValue(fieldIsFemalePresent) && buildViewValue('Жен', 'altFemaleStart', 'altFemaleEnd')}
              </>
            )}
          </div>
        )}
      </div>
      {isTemplate && (
        <div className="table-data__control">
          <FormikToggleGroup name={canChangeSexOrAge} label="">
            <FormikToggle disabled={!editMode} name={canChangeSexOrAge} size="xsmall" />
          </FormikToggleGroup>
        </div>
      )}
    </div>
  );
};
