import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { TrainingGroupRequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { getServiceEditLink, hasGeneralAccess } from '../../../lib/utils';
import { generalAccess } from '../../../mock-data/access-enum';
import { EducationTypeEnum } from '../../../types/education-type';

type Props = {
  data: TrainingGroupRequestData;
  educationType: EducationTypeEnum;
};

const Education: React.FC<Props> = ({ data, educationType }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminView) && hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const accessEducationalOrganization = useMemo(() => {
    return hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) || isAdmin;
  }, [isAdmin, userProfile]);
  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация об образовательной программе'}>
        <div className="container">
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Наименование программы</div>
              <Link
                className="brand-link cursor-pointer"
                to={getServiceEditLink(educationType, data.serviceId)}
                target="_blank"
                rel="noreferrer"
              >
                {data.serviceName}
              </Link>
            </div>
            {accessEducationalOrganization && (
              <div className="table-data__item table-data__group">
                <div className="table-data__label table-data__label--main">Образовательная организация</div>
                <div className="table-data__body">
                  <span>{data?.organizationName}</span>
                </div>
              </div>
            )}
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Адрес приема документов</div>
              <div className="table-data__body">{data.itemsWorkAddress}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Преподаватель</div>
              <div className="table-data__body">{data.teacherName}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Дата начала занятий</div>
              <div className="table-data__body">{formatDate(data.educationStartDate)}</div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Education;
