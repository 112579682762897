import React, { useContext, useState } from 'react';
import TemplateInfo from './panels/info';
import Organizations from './panels/organizations';
import Photo from '../components/panels/photo';
import Financing from '../components/panels/financing';
import ServiceSchedule from '../components/panels/service-schedule';
import { ServiceContext } from '../index';
import Tests from '../components/dkgm-dsit-template/panels/tests/tests';
import { TemplateService } from '../../../types/service';
import PublicationStatus from '../components/publication-status';
import { accessVedomst } from '../../../mock-data/access-enum';

import Limit from '../components/dkgm-dsit-template/panels/limits/limit';

const TemplateServicesDetails = () => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const { serviceData, accessPanelEdit, userProfile } = useContext(ServiceContext);
  return (
    <>
      {accessPanelEdit && (
        <PublicationStatus
          editMode={editMode}
          educationType={serviceData.educationTypeId}
          serviceStatusId={serviceData.serviceStatusId}
          isTemplate
          showPublicateButton={accessPanelEdit}
        />
      )}

      {(!editMode || editMode === 'info') && (
        <TemplateInfo data={serviceData?.info as unknown as TemplateService.Info} setEditModeParent={setEditMode} />
      )}
      {(!editMode || editMode === 'limits') && userProfile.vedomstvoId === accessVedomst.Kdc && (
        <Limit isTemplate setEditModeParent={setEditMode} />
      )}
      {(!editMode || editMode === 'financing') && <Financing isTemplate setEditModeParent={setEditMode} />}
      {(!editMode || editMode === 'tests') && <Tests isTemplate setEditModeParent={setEditMode} />}
      {!editMode && <Organizations />}
      {(!editMode || editMode === 'schedule') && <ServiceSchedule setEditModeParent={setEditMode} isTemplate />}
      {(!editMode || editMode === 'photo') && <Photo setEditModeParent={setEditMode} isTemplate />}
    </>
  );
};

export default TemplateServicesDetails;
