import React from 'react';
import PageHeading from '../../../../components/header/page-heading';
import { routes } from '../../../../config/constants';
import DigitalTutorCreateCreateDetails from './details';
import history from '../../../../history';

const DigitalTutorCreate = () => {
  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={() => history.push(routes.programs)}
        title="Новая услуга"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Услуги цифрового репетитора', link: routes.programs },
          { title: 'Описание услуги цифровой репетитор' },
        ]}
      />
      <DigitalTutorCreateCreateDetails />
    </>
  );
};

export default DigitalTutorCreate;
