import React from 'react';
import { Loader, Push } from '@mosru/esz_uikit';
import PageHeading from '../../components/header/page-heading';
import DocList from '../../components/doc-list';
import { useGetNormativeDocumentQuery } from '../../store/static-content';
import { routes } from '../../config/constants';

const NormativeDocs = () => {
  const { data = [], isFetching } = useGetNormativeDocumentQuery();

  return (
    <>
      <PageHeading
        title="Нормативные документы"
        sections={[{ title: 'Главная', link: routes.main }, { title: 'Нормативные документы' }]}
      />

      <Push size={12} />
      {isFetching ? (
        <div className="loader-auto">
          <Loader />
        </div>
      ) : (
        <DocList data={data} />
      )}
    </>
  );
};

export default NormativeDocs;
