import React, { useEffect, useState } from 'react';
import { Button, ToggleGroup, ModalPanel, Modal, Select, Toggle, Push } from '@mosru/esz_uikit';
import { day, weekday } from './helpers';

type Props = {
  show: boolean;
  title: string;
  reset: boolean;
  customAll?: boolean;
  onCloseHandler: () => void;
  resetModal: (value: boolean) => void;
  currentSelectDay?: Weekday[]; // текущий массив которые сейчас в инпуте
  currentShortDay?: string; // текущее значение из инпута
  setCurrentSelectDay?: (value: Weekday[]) => void;
  saveSchedule: (arg: { selectDaysForRequest: string; selectShortDay: string }) => void;
};

export type Weekday = {
  id: number;
  name: string;
  time: string;
  selected: number;
  shortName: string;
  checked: boolean;
};

const SheduleModal = ({
  show,
  onCloseHandler,
  title,
  saveSchedule,
  resetModal,
  reset,
  customAll,
  currentShortDay,
  setCurrentSelectDay,
  currentSelectDay,
}: Props) => {
  const [selectDay, setSelectDay] = useState<Weekday[]>(weekday);
  const [selectShortDay, setSelectShortDay] = useState<string>('');

  useEffect(() => {
    if (reset) {
      setSelectDay(weekday);
    }
  }, [reset]);

  useEffect(() => {
    if (show) {
      setSelectDay(currentSelectDay?.length ? currentSelectDay : weekday);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSelectedDayHandler = (id: number, element: any) => {
    setSelectDay((prevState: Weekday[]) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            time: element.label,
            selected: element.value,
          };
        }
        return {
          ...item,
        };
      });
    });
  };

  const save = () => {
    const selectDays = selectDay.map((item: Weekday, index) => {
      if (item.checked) {
        if (item.selected === 3 && customAll) {
          // кусок кода взят из монолита
          return `${(index + 1) * 10 + 1},${(index + 1) * 10 + 2},${(index + 1) * 10 + 4}`;
        } else {
          return `${item.id}${item.selected}`;
        }
      } else {
        return undefined;
      }
    });
    const selectShortDay = selectDay.map((item: Weekday) =>
      item.checked ? `${item.shortName} - ${item.time}` : undefined
    );

    const saveAll = {
      selectDaysForRequest: selectDays.filter((item) => item !== undefined)?.join(';'),
      selectShortDay: selectShortDay.filter((item) => item !== undefined).join(', '),
    };

    onCloseHandler();
    saveSchedule(saveAll);
    resetModal(false);
    if (setCurrentSelectDay) {
      setCurrentSelectDay(selectDay);
    }
    setSelectShortDay(saveAll.selectShortDay);
  };

  const closePopup = () => {
    if (!selectShortDay && !currentShortDay) {
      setSelectDay(weekday);
    } else if (currentSelectDay) {
      setSelectDay(currentSelectDay);
    }

    onCloseHandler();
  };

  return (
    <Modal show={show} onClose={closePopup}>
      <ModalPanel
        onClose={closePopup}
        modalTitle={title}
        bodyNoPadding
        renderComponent={() => (
          <div className="report__schedule--popup">
            {selectDay.map((item, index) => {
              const color = index % 2 === 0 ? '' : 'odd';
              return (
                <div key={item.id} className={`schedule__popup--item ${color}`}>
                  <ToggleGroup labelId={item.id.toString()} label={item.name} onChange={() => {}}>
                    <Toggle
                      labelId={item.id.toString()}
                      checked={item.checked}
                      size="xsmall"
                      onChange={(checked) => {
                        setSelectDay(
                          selectDay.map((i) => {
                            return {
                              ...i,
                              checked: i.id === item.id ? checked : i.checked,
                            };
                          })
                        );
                      }}
                    />
                  </ToggleGroup>
                  {item.checked ? (
                    <div className="select">
                      <Select
                        name="selectDay"
                        error=""
                        options={day}
                        size="small"
                        value={{
                          label: item.time,
                          value: item.selected,
                        }}
                        hideClearIndicator
                        isSearchable={false}
                        placeholder="Выберите значение..."
                        onChange={(element) => onSelectedDayHandler(item.id, element)}
                      />
                    </div>
                  ) : (
                    <span className="no-work">нерабочий</span>
                  )}
                </div>
              );
            })}
          </div>
        )}
        controls={() => (
          <>
            <Button primary border label="Отмена" onClick={closePopup} />
            <Push orientation="horizontal" size={12} />
            <Button primary label="Сохранить" onClick={save} />
          </>
        )}
      />
    </Modal>
  );
};

export default SheduleModal;
