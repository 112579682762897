import { Push } from '@mosru/esz_uikit';
import React from 'react';
import { EventUrl } from '../../mock-data/event';
import FormikFormGroup from '../formik/formik-form-group';
import useQuery from '../../hooks/use-query';
import FormikTextarea from '../formik/formik-textarea';

export const NotificationField = () => {
  const queryEventName = useQuery().get(EventUrl.EventName);
  return queryEventName ? (
    <>
      <Push size={16} />
      <FormikFormGroup label="По уведомлению" name="">
        <FormikTextarea maxLength={2} value={queryEventName} disabled name="" placeholder="Введите..." />
      </FormikFormGroup>
    </>
  ) : null;
};
