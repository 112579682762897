import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormGroup,
  Input,
  Select,
  SelectOptionType,
  Panel,
  Loader,
  Push,
  SimpleTable as Table,
} from '@mosru/esz_uikit';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { cutToNumbers, formatPhoneNumber } from '../../../../lib/utils/format-number';
import { phoneMask } from '../../../../lib/utils/mask';
import { phoneErrorMessage } from '../../../../lib/utils/validation';
import Edit from './edit';
import {
  defaultRepresentation,
  editName,
  NewRepresentatives,
  transformRepresentatives,
  typeRepresentation,
} from '../../../../lib/utils/learners';
import { Learner } from '../../../../types/learners';
import learnerApi from '../../../../lib/api/learner';
import { hasAccessObjectParent } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { checkValidation, validateMessage } from './helpers';
import { TypeRepresentationEnum } from '../../../../mock-data/type-representation-enum';

type Props = {
  loading: boolean;
  userData: Learner.Data;
  updateLearn: (val: boolean) => void;
};

const LearnerRepresentatives: React.FC<Props> = ({ userData, loading, updateLearn }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [validate, setValidate] = useReducer<
    Reducer<Learner.ValidateRepresentatives, Partial<Learner.ValidateRepresentatives>>
  >((state, newState) => ({ ...state, ...newState }), { lastName: 0, firstName: 0, middleName: 0, email: 0, phone: 0 });

  const [checkAllValidate, setCheckAllValidate] = useState<boolean>(false);

  const [tableData, setTableData] = useState<Learner.TransformRepresentative[]>([]);

  const [representatives, setRepresentatives] = useState<Learner.TransformRepresentative[]>([]);

  const [editObj, setEditObj] = useState<Learner.TransformRepresentative>(NewRepresentatives);

  const [blockAddBtn, setBlockAddBtn] = useState<boolean>(false);

  // если true нельзя создать нового представителя
  const [newRepresentative, setNewRepresentative] = useState<boolean>(false);
  const [typeRepresentative, setTypeRepresentative] = useState<SelectOptionType>();

  useEffect(() => {
    setRepresentatives(transformRepresentatives(userData.representatives));
  }, [userData.representatives]);

  useEffect(() => {
    setTableData(representatives);
  }, [representatives]);

  const deleteRepresentative = async (id: number) => {
    if (id) {
      const representative = representatives.find((item: Learner.TransformRepresentative) => item.id === id);
      if (representative && representative.id) {
        await learnerApi.deleteRepresentative(userData.id, representative.id);
      }
      updateLearn(true);
    }
  };

  const addRepresentative = () => {
    delete NewRepresentatives.id;
    delete NewRepresentatives.type;

    const newTableData = new Array(NewRepresentatives).concat(tableData);
    setTableData(newTableData);
    setTypeRepresentative({ label: 'Мать', value: TypeRepresentationEnum.Mother });
    setEditObj(NewRepresentatives);
    setNewRepresentative(true);
  };

  const submitForm = async () => {
    await learnerApi.postRepresentative(userData.id, {
      email: editObj.email,
      phone: editObj.phone,
      pupilId: userData.id,
      lastName: editObj.name.lastName,
      firstName: editObj.name.firstName,
      middleName: editObj.name.middleName,
      typeRepresentationId: typeRepresentative?.value as number,
    });
    updateLearn(true);
    setNewRepresentative(false);
  };

  const searchActiveType = (value: string) => typeRepresentation.find((item: SelectOptionType) => item.label === value);

  useEffect(() => {
    setCheckAllValidate(!!Object.values(validate).filter((item) => item).length);
  }, [validate]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Законные представители
            <span className="color-gray-dark">
              {' \u00A0'} {tableData.length ? tableData.length : ''}
            </span>
          </>
        )}
        headingControl={() =>
          hasAccessObjectParent(userProfile, accessObject.Pupils, accessAction.Edit) &&
          !userData.isArchive && (
            <button
              disabled={newRepresentative || blockAddBtn}
              type="button"
              onClick={addRepresentative}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconPlus />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          )
        }
      >
        {loading ? (
          <div className="flex justify-center flex-column">
            <Push orientation="vertical" size={20} />
            <Loader roller small />
            <Push orientation="vertical" size={20} />
          </div>
        ) : tableData.length ? (
          <Table
            overflow
            data={tableData}
            pageSize={Number.MAX_SAFE_INTEGER}
            columns={[
              {
                dataIndex: 'type',
                title: 'Тип представительства',
                render: (item: any) => {
                  editObj.id === item.id && setBlockAddBtn(true);
                  return editObj.id === item.id ? (
                    <Select
                      name="typeRepresentative"
                      size="small"
                      isSearchable
                      placeholder="Тип представительства"
                      onChange={(selectedOption) => {
                        const option = selectedOption as SelectOptionType;
                        setTypeRepresentative(option);

                        setEditObj((prev: Learner.TransformRepresentative) => ({
                          ...prev,
                          typeRepresentationId: option.value as number,
                        }));
                      }}
                      options={typeRepresentation}
                      value={typeRepresentative?.value ? typeRepresentative : null}
                    />
                  ) : (
                    item.type
                  );
                },
                hiddenSort: true,
                width: '21%',
              },
              {
                dataIndex: 'name',
                title: 'Фамилия, имя, отчество',
                render: (item: any) => {
                  return editObj.id === item.id ? (
                    <>
                      <FormGroup
                        label=""
                        error={
                          !editObj.name.lastName && !newRepresentative
                            ? 'Введите фамилию'
                            : validateMessage(validate.lastName ?? 0)
                        }
                      >
                        <Input
                          size="small"
                          placeholder="Фамилия"
                          value={editObj.name.lastName}
                          onChange={(value) => {
                            setEditObj((prev: Learner.TransformRepresentative) => ({
                              ...prev,
                              name: { ...prev.name, lastName: value.target.value },
                            }));

                            checkValidation(value.target.value, setValidate, 'lastName');
                          }}
                        />
                      </FormGroup>

                      <Push orientation="vertical" size={12} />

                      <FormGroup
                        label=""
                        error={
                          !editObj.name.firstName && !newRepresentative
                            ? 'Введите имя'
                            : validateMessage(validate.firstName ?? 0)
                        }
                      >
                        <Input
                          size="small"
                          placeholder="Имя"
                          value={editObj.name.firstName}
                          onChange={(value) => {
                            setEditObj((prev: Learner.TransformRepresentative) => ({
                              ...prev,
                              name: { ...prev.name, firstName: value.target.value },
                            }));

                            checkValidation(value.target.value, setValidate, 'firstName');
                          }}
                        />
                      </FormGroup>

                      <Push orientation="vertical" size={12} />
                      <FormGroup label="" error={validateMessage(validate.middleName ?? 0)}>
                        <Input
                          size="small"
                          value={editObj.name.middleName}
                          placeholder="Отчество (если есть)"
                          onChange={(value) => {
                            setEditObj((prev: Learner.TransformRepresentative) => ({
                              ...prev,
                              name: { ...prev.name, middleName: value.target.value },
                            }));
                            checkValidation(value.target.value, setValidate, 'middleName');
                          }}
                        />
                      </FormGroup>
                    </>
                  ) : (
                    editName(item.name.lastName, item.name.firstName, item.name.middleName)
                  );
                },
                hiddenSort: true,
                width: '21%',
              },
              {
                dataIndex: 'phone',
                title: 'Номер телефона',
                render: (item: any) => {
                  return editObj.id === item.id ? (
                    <FormGroup
                      label=""
                      error={
                        !editObj.phone && !newRepresentative ? phoneErrorMessage : validateMessage(validate.phone ?? 0)
                      }
                    >
                      <Input
                        size="small"
                        value={editObj.phone}
                        mask={phoneMask}
                        placeholder="+7 (XXX) XXX-XX-XX"
                        onChange={(value) => {
                          setEditObj((prev: Learner.TransformRepresentative) => {
                            checkValidation(cutToNumbers(value.target.value) || '', setValidate, 'phone', false, true);
                            return { ...prev, phone: value.target.value };
                          });
                        }}
                      />
                    </FormGroup>
                  ) : (
                    item.phone
                  );
                },
                hiddenSort: true,
                width: '21%',
              },
              {
                dataIndex: 'email',
                title: 'E-mail',
                render: (item: any) => {
                  return editObj.id === item.id ? (
                    <FormGroup label="" error={validateMessage(validate.email ?? 0)}>
                      <Input
                        size="small"
                        value={editObj.email}
                        placeholder="E-mail"
                        onChange={(value) => {
                          setEditObj((prev: Learner.TransformRepresentative) => {
                            return { ...prev, email: value.target.value };
                          });

                          checkValidation(value.target.value, setValidate, 'email', true);
                        }}
                      />
                    </FormGroup>
                  ) : (
                    item.email
                  );
                },
                hiddenSort: true,
                width: '21%',
              },

              {
                dataIndex: '',
                title: '',
                render: (item: any) =>
                  hasAccessObjectParent(userProfile, accessObject.Pupils, accessAction.Edit) &&
                  !userData.isArchive && (
                    <Edit
                      id={item.id}
                      editObj={editObj}
                      pupilId={userData.id}
                      setValidate={setValidate}
                      updateLearn={updateLearn}
                      submitHandler={submitForm}
                      setBlockAddBtn={setBlockAddBtn}
                      editMode={editObj.id === item.id}
                      checkAllValidate={checkAllValidate}
                      typeRepresentative={typeRepresentative}
                      setRepresentative={setNewRepresentative}
                      removeRepresentative={deleteRepresentative}
                      setEditMode={(mode) => {
                        const data = transformRepresentatives(userData.representatives);
                        setTableData(data);

                        setEditObj(mode ? { ...item, phone: formatPhoneNumber(item.phone) } : NewRepresentatives);
                        setTypeRepresentative(item.type ? searchActiveType(item.type) : defaultRepresentation);
                      }}
                    />
                  ),
                hiddenSort: true,
              },
            ]}
          />
        ) : (
          <div className="learner-no-data">Сведения о законных представителях отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default LearnerRepresentatives;
