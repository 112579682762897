import React, { useContext, useMemo, useState } from 'react';
import { Button, IconArrowDown, Push } from '@mosru/esz_uikit';
import Expulsion from '../../components/modals/expulsion';
import TransferGroup from '../../components/modals/transfer-group';
import RecoverySimple from '../../components/modals/recovery-simple';
import TransferStage from '../../components/modals/transfer-stage';
import DropDown from '../../../../components/drop-down';
import Union from '../../components/modals/union';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, learners, selected, openDetails, active } = useContext(ServiceClassTableContext);

  const { accessRecover } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);
  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openTransferStagePopup, setOpenTransferStagePopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  const disableStage = useMemo(() => {
    const selectedLearners = learners.filter((value) => selected.includes(value.id));
    return selectedLearners.some((item) => item.serviceId !== selectedLearners[0].serviceId);
  }, [learners, selected]);

  return (
    <>
      {' '}
      {openDetails && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  default
                  handleClick={() => {
                    setSelected([]);
                    setOpenDetails(false);
                  }}
                  label="Отменить выбор"
                />
                {active ? (
                  <>
                    {' '}
                    <Push size={12} orientation="horizontal" />
                    <Button
                      danger
                      border
                      label="Отчислить"
                      handleClick={() => {
                        setOpenExpulsionPopup(true);
                      }}
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button handleClick={() => setOpenUnionPopup(true)} primary border label="Объединить в подгруппу" />
                    <Push size={12} orientation="horizontal" />
                    <DropDown
                      component={() => (
                        <Button
                          onClick={() => {}}
                          primary
                          border
                          label="Перевести"
                          iconRight={() => <IconArrowDown />}
                        />
                      )}
                    >
                      <div className="drop-down-panel top">
                        <div className="drop-down-panel__list">
                          <button
                            type="button"
                            className="drop-down-panel__list-item"
                            onClick={() => setOpenTransferGroupPopup(true)}
                          >
                            в другую группу обучения
                          </button>

                          {disableStage || (
                            <button
                              type="button"
                              className="drop-down-panel__list-item"
                              onClick={() => setOpenTransferStagePopup(true)}
                            >
                              на другой этап
                            </button>
                          )}
                        </div>
                      </div>
                    </DropDown>
                  </>
                ) : (
                  accessRecover && (
                    <>
                      <Push size={12} orientation="horizontal" />
                      <Button handleClick={() => setOpenRecoveryPopup(true)} primary border label="Восстановить" />
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Expulsion open={openExpulsionPopup} close={() => setOpenExpulsionPopup(false)} />
      <RecoverySimple open={openRecoveryPopup} close={() => setOpenRecoveryPopup(false)} />
      <Union open={openUnionPopup} close={() => setOpenUnionPopup(false)} />
      <TransferStage open={openTransferStagePopup} close={() => setOpenTransferStagePopup(false)} />
      <TransferGroup open={openTransferGroupPopup} close={() => setOpenTransferGroupPopup(false)} />
    </>
  );
};

export default PanelButtons;
