import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import Favorite from '../../../components/favorite';
import TemplateServicesDetails from './details';
import { ReactComponent as IconShare } from '../../../assets/images/icons/copy-2-white-blocks.svg';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/remove.svg';
import { ReactComponent as IconPlus } from '../../../assets/images/icons/plus-color.svg';
import { getHeaderLink, getTabsButton } from '../utils';
import { ServiceContext } from '../index';
import { generateLink, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import ToArchiveModal from '../components/modals/to-archive';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { AppState } from '../../../redux/types/state';
import RemoveModal from '../../../components/remove-modal';
import { templateApi } from '../../../lib/api/template';
import history from '../../../history';

const TemplateServices = () => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const { serviceData, educationTypes, type, accessAddArchive, accessPanelEdit, routeBack } =
    useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);

  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const showCreateByTemplate = useMemo(
    () =>
      (hasAccessObjectAny(
        userProfile,
        [accessObject.ServiceSport, accessObject.ServiceArtHouse],
        accessAction.CreateByTemplate
      ) ||
        hasGeneralAccess(userProfile, generalAccess.AdminEdit)) &&
      serviceData.serviceStatusId !== ServiceStatusEnum.Arhive,
    [userProfile, serviceData.serviceStatusId]
  );

  const canEdit = useMemo(
    () =>
      hasAccessObjectAny(userProfile, [accessObject.TemplateArtHouse, accessObject.TemplateSport], accessAction.Edit) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const showCopyButton = useMemo(
    () => canEdit && serviceData.serviceStatusId !== ServiceStatusEnum.Arhive && serviceData.id > 0,
    [canEdit, serviceData.serviceStatusId, serviceData.id]
  );

  const showDelButton = useMemo(
    () =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.TemplateArtHouse, accessObject.TemplateSport],
        accessAction.Archive
      ) &&
      serviceData.serviceStatusId !== ServiceStatusEnum.Arhive &&
      serviceData.serviceStatusId !== ServiceStatusEnum.Signed &&
      !serviceData.hasSlaveServices &&
      serviceData.id > 0,
    [serviceData.id, serviceData.serviceStatusId, serviceData.hasSlaveServices, userProfile]
  );

  const showFavorite = !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <TemplateServicesDetails />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name=""
            entityTypes={[EntityTypeEnum.Service]}
            extendEntityGuid={serviceData.extendEntityGuid}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [serviceData.extendEntityGuid]
  );

  return (
    <>
      <PageHeading
        buttonBack
        routeBack={routeBack}
        title="Шаблон услуги"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Шаблоны услуг', link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId) },
          { title: 'Описание шаблона услуги' },
        ]}
        controlsTopRight={
          <ButtonGroup>
            {showCreateByTemplate && (
              <Button
                onClick={() => {
                  history.push(generateLink(routes.createServiceByTemplate, { templateId: serviceData.id }));
                }}
                label="Услуга на основе шаблона"
                iconLeft={() => <IconPlus />}
                size="small"
              />
            )}
            {showCopyButton && (
              <Button
                label="Копировать шаблон"
                iconLeft={() => <IconShare />}
                size="small"
                onClick={() => {
                  history.push(generateLink(routes.copyTemplate, { id: serviceData.id }));
                }}
              />
            )}
            {checkAccessAddArchive && (
              <Button
                label="В архив"
                handleClick={() => setShowModalArchive(true)}
                iconLeft={() => <IconArchive />}
                size="small"
              />
            )}
            {showDelButton && (
              <Button
                size="small"
                label="Удалить"
                iconLeft={() => <IconRemove />}
                handleClick={() => setShowModalDelete(true)}
              />
            )}
          </ButtonGroup>
        }
        tabsButton={getTabsButton(serviceData.serviceStatusId, serviceData.serviceStatusName)}
        tabs={tabs}
        activeTab={activeTab}
        expansion={
          showFavorite && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="шаблон услуги"
              entityTypeId={EntityTypeEnum.Service}
              currentEntityType={serviceData.info.name}
            />
          )
        }
      />
      {tabs.find((item) => item.label === activeTab)?.component}
      <ToArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
      <RemoveModal
        buttonReverse
        show={showModalDelete}
        onRemoveHandler={async () => {
          await templateApi.deleteTemplate(serviceData.id);
          history.push(routes.myTemplates);
        }}
        title="Удаление шаблона услуги"
        deleteBtnTitle="Да, продолжить"
        onCloseHandle={() => setShowModalDelete(false)}
        description="Внимание! Введенные данные будут удалены из системы. Продолжить?"
      />
    </>
  );
};

export default TemplateServices;
