import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RequestData } from '../../types/requests';
import { EducationTypeEnum } from '../../types/education-type';
import DayCareRequestCreate from './day-care/create';
import DkgmRequestCreate from './dkgm/create';
import requestsApi from '../../lib/api/requests';
import { access, RequestContext } from '../../lib/utils/requests';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import DonmRequestCreate from './donm/create';
import { DocumentTypeEnum } from '../../mock-data/type-document';
import RequestProfCreate from './preparation/create';

const CreateRequestFromSchedule = () => {
  const params = useParams<any>();
  const [request, setRequest] = useState<RequestData>();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const getRequest = async () => {
      if (params.slotId) {
        const response = await requestsApi.buildRequestByTrainingGroup(params.slotId);
        setRequest({ ...response, child: { ...response.child, documentTypeId: DocumentTypeEnum.BirthCertificate } });
      }
    };
    getRequest();
  }, [params.slotId]);

  const currentRequest = (edId: number) => {
    if (request) {
      switch (edId) {
        case EducationTypeEnum.ChildrenEducation:
        case EducationTypeEnum.ChildrenNonDogmEducation:
          return <DonmRequestCreate request={request} educationType={request?.educationTypeId} serviceInfoReadOnly />;
        case EducationTypeEnum.ArtHouseEducation:
        case EducationTypeEnum.SportEducation:
          return (
            <DkgmRequestCreate
              request={request}
              educationType={request?.educationTypeId ?? EducationTypeEnum.ArtHouseEducation}
              serviceInfoReadOnly
            />
          );
        case EducationTypeEnum.ProfessionalEducation:
          return <RequestProfCreate request={request} serviceInfoReadOnly />;
        case EducationTypeEnum.DayCareCentersEducation:
          return <DayCareRequestCreate request={request} />;
        default:
          return null;
      }
    }
  };
  return (
    <RequestContext.Provider value={access(userProfile)}>
      {currentRequest(request?.educationTypeId ?? 0)}
    </RequestContext.Provider>
  );
};

export default CreateRequestFromSchedule;
