import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { ModalPanel, Switcher } from '@mosru/esz_uikit';
import EditSchedule, { ScheduleType } from './index';

type Props = {
  title: string;
  onClose: () => void;
  controls: ReactNode;
  submit: boolean;
  submitError: () => void;
  submitSuccess: (schedule: ScheduleType[]) => void;
  scheduleData: ScheduleType[];
  setScheduleError?: (value: boolean) => void;
};

const ModalPanelSchedule: FC<Props> = ({
  title,
  onClose,
  controls,
  submit,
  submitError,
  submitSuccess,
  scheduleData,
  setScheduleError,
}) => {
  const extendedButtonRef = useRef<HTMLButtonElement | null>(null);
  const notExtendedButtonRef = useRef<HTMLButtonElement | null>(null);
  const [scheduleExtended, setScheduleExtended] = useState(false);

  useEffect(() => {
    if (!(extendedButtonRef.current && notExtendedButtonRef.current)) {
      return;
    }
    setScheduleExtended(extendedButtonRef.current?.classList.contains('active'));
  }, [extendedButtonRef, notExtendedButtonRef]);

  return (
    <ModalPanel
      alert
      onClose={onClose}
      modalTitle={title}
      controls={() => controls}
      bodyNoPadding
      headingControl={
        <div style={{ marginTop: -8 }}>
          <Switcher size="small">
            <button
              type="button"
              onClick={() => {
                setScheduleExtended(false);
                notExtendedButtonRef.current?.click();
              }}
              className={!scheduleExtended ? 'active' : ''}
            >
              Будни/выходные
            </button>
            <button
              type="button"
              onClick={() => {
                setScheduleExtended(true);
                extendedButtonRef.current?.click();
              }}
              className={scheduleExtended ? 'active' : ''}
            >
              Детально по дням
            </button>
          </Switcher>
        </div>
      }
      renderComponent={() => (
        <EditSchedule
          title=""
          timeRoundingUp
          submit={submit}
          submitError={submitError}
          submitSuccess={submitSuccess}
          scheduleData={scheduleData}
          extendedButtonRef={extendedButtonRef}
          notExtendedButtonRef={notExtendedButtonRef}
          setCurrentErrorSchedule={setScheduleError}
        />
      )}
    />
  );
};

export default ModalPanelSchedule;
