import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import requestsApi from '../../lib/api/requests';
import {
  EvaluationData,
  RequestApproveData,
  RequestData,
  RequestDeclineData,
  RequestEnrollmentData,
  TrainingGroupRequestData,
} from '../../types/requests';
import { EducationTypeEnum } from '../../types/education-type';
import DonmRequestCreate from './donm/create';
import DayCareRequestCreate from './day-care/create';
import PreparationCreate from './preparation/create';
import { access, RequestContext } from '../../lib/utils/requests';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import DkgmRequestCreate from './dkgm/create';

const CreateRequestBasedOn = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const [request, setRequest] = useState<RequestData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const getRequest = async () => {
      if (id) {
        const response = await requestsApi.getRequest(id);
        setRequest({
          ...response,
          dateRequest: moment().format().toString(),
          statusChangeDate: null,
          trainingGroup: {} as TrainingGroupRequestData,
          approve: {} as RequestApproveData,
          evaluation: {} as EvaluationData,
          enrollment: {} as RequestEnrollmentData,
          decline: {} as RequestDeclineData,
          requestNumber: undefined,
        });
        setLoading(true);
      }
    };
    getRequest();
  }, [id]);

  const currentRequest = (edId: number) => {
    if (request) {
      switch (edId) {
        case EducationTypeEnum.ChildrenEducation:
        case EducationTypeEnum.ChildrenNonDogmEducation:
          return <DonmRequestCreate educationType={request?.educationTypeId} request={request} loading={loading} />;
        case EducationTypeEnum.SportEducation:
        case EducationTypeEnum.ArtHouseEducation:
          return <DkgmRequestCreate educationType={request?.educationTypeId} request={request} />;
        case EducationTypeEnum.DayCareCentersEducation:
          return <DayCareRequestCreate request={request} />;
        case EducationTypeEnum.ProfessionalEducation:
          return <PreparationCreate request={request} />;
        default:
          return null;
      }
    }
  };

  return (
    <RequestContext.Provider value={access(userProfile)}>
      {currentRequest(request?.educationTypeId ?? 0)}
    </RequestContext.Provider>
  );
};

export default CreateRequestBasedOn;
