import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, Infobox, Panel, Push } from '@mosru/esz_uikit';
import { CreateContactPerson } from '../../components/panels/contact-person/create-contact-person';
import { ServiceData } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import history from '../../../../history';
import { routes } from '../../../../config/constants';
import { CreateServiceContext } from '../../create';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import CreateInfo from './create-info';
import CreateFinancing from '../../components/panels/financing/create';
import { convertScheduleToBack } from '../../../../lib/utils/time-table';
import EditServiceSchedule from '../../components/panels/service-schedule/edit';
import { getHeaderLink, setGenderStartEndToBack } from '../../utils';
import { getInfoDoValidationSchema } from '../panels/info';
import { financingValidationSchema } from '../../components/panels/financing';

const ServiceChildCreateDetails = () => {
  const { serviceData, educationTypes, type } = useContext(CreateServiceContext);
  const initialErrors = useInitialErrors(serviceData, getValidationSchema());

  const [load, setLoad] = useState<boolean>(false);
  const [scheduleError, setScheduleError] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);

  const submitForm = async (values: ServiceData) => {
    setLoad(true);
    try {
      const serviceId = await serviceTemplateApi.createService({
        ...values,
        info: setGenderStartEndToBack(values.info),
      });
      history.push(`${routes.registerChildEducations}/${serviceId}`);
      setLoad(false);
    } catch (e) {
      setLoad(false);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceData}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, isValid, setFieldValue, values } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />

            <CreateInfo />
            <CreateContactPerson parent="contactPerson" />
            <Push size={12} />
            {values.info?.typeOfAttendanceId === 1 ? (
              <Panel title={() => 'Адрес проведения занятий'}>
                <div className="container">
                  <Infobox
                    fullWidth
                    color="warning"
                    text="Адрес проведения занятий станет доступным для ввода после первого сохранения."
                  />
                </div>

                <Push size={24} />
              </Panel>
            ) : null}

            <Push size={12} />
            <Panel title={() => 'Учебные периоды'}>
              <div className="container">
                <Infobox
                  fullWidth
                  color="warning"
                  text="Редактирование учебных периодов станет доступно после первого сохранения."
                />
              </div>

              <Push size={24} />
            </Panel>

            <Push size={12} />
            <CreateFinancing />
            <Push size={12} />
            <EditServiceSchedule
              isCreate
              submit={(schedule, selectedSchedule) => {
                setFieldValue('schedule.list', convertScheduleToBack(schedule));
                setFieldValue('schedule.scheduleTypeOfServiceName', selectedSchedule.label);
                setFieldValue('schedule.scheduleTypeOfServiceId', selectedSchedule.value);
                handleSubmit();
              }}
              scheduleSubmit={scheduleSubmit}
              setScheduleSubmit={setScheduleSubmit}
              setScheduleError={setScheduleError}
              serviceData={serviceData}
              title="Время проведения занятий"
            />

            <Push size={12} />
            <Panel title={() => 'Адреса приема документов'}>
              <div className="container">
                <Infobox
                  fullWidth
                  color="warning"
                  text="Адреса приема документов станут доступными для ввода после первого сохранения."
                />
              </div>

              <Push size={24} />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Планы приема'}>
              <div className="container">
                <Infobox
                  fullWidth
                  color="warning"
                  text="Планы приема станут доступными для ввода после первого сохранения."
                />
              </div>
              <Push size={24} />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Фотографии услуги'}>
              <div className="container">
                <Infobox
                  fullWidth
                  color="warning"
                  text="Возможность добавления фотографий услуги станет доступна после первого сохранения."
                />
              </div>
              <Push size={24} />
            </Panel>

            <div className="room-save-container">
              <div className="room-panel-save">
                <div className="container">
                  <div className="room-panel-save__inner">
                    <Button
                      onClick={() => history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
                      border
                      primary
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      handleClick={() => setScheduleSubmit(true)}
                      load={load}
                      disabled={!isValid || scheduleError}
                      primary
                      label="Создать детское объединение"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceChildCreateDetails;

export const getValidationSchema = () =>
  objectYup().shape({
    info: getInfoDoValidationSchema(),
    contactPerson: contactPersonValidationSchema(),
    financing: financingValidationSchema(),
  });
