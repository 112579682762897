import React, { Fragment, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Heading, Push } from '@mosru/esz_uikit';
import ImgLogo from '../../assets/images/logo.svg';
import Panel from './panel';
import { helpPanels } from '../../mock-data/auth';
import { aupdApiUrl, routes } from '../../config/constants';
import Login from './login';
import Sign from './sign';
import ChangePassword from './change-password';
import { ReactComponent as IconChrome } from '../../assets/images/auth/chrome.svg';
import { ReactComponent as IconYandex } from '../../assets/images/auth/yandex.svg';
import { ReactComponent as IconFirefox } from '../../assets/images/auth/firefox.svg';
import { ReactComponent as IconEdge } from '../../assets/images/auth/edge.svg';
import { ReactComponent as IconSafari } from '../../assets/images/auth/safari.svg';
import { withErrorBoundary } from '../../hoc/error-boundary';
import auth from '../../lib/api/auth';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';

const Auth = () => {
  const [loading, setLoading] = useState(true);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  useEffect(() => {
    const fetch = async () => {
      const isValidKey = await auth.checkLocalAuthApiKey();
      if (!isValidKey) {
        window.location.href = !userProfile.tokenAupd ? aupdApiUrl : routes.loginAupd;
      }
      setLoading(false);
    };
    fetch();
  }, [userProfile.tokenAupd]);

  return loading ? null : (
    <div className="auth-grid">
      <div className="auth-grid__content">
        <div className="auth-grid__body">
          <div className="container">
            <img src={ImgLogo} alt="Logo" />
            <Push size={32} />
            <Heading label="Важная информация" as="h4" color="#fff" />
            {helpPanels.map((item) => (
              <Fragment key={item.title}>
                <Push size={16} />
                <Panel key={item.title} {...item} />
              </Fragment>
            ))}
          </div>
        </div>
        <div className="auth-grid__contacts">
          <div className="container">
            <div className="auth-contacts">
              <div className="auth-contacts__links">
                <a href="tel:+74955393838" className="auth-contacts__link">
                  +7 (495) 539-38-38
                </a>
                <Push orientation="horizontal" size={24} />
                <a href="mailto:dop_hd@mos.ru" className="auth-contacts__link">
                  dop_hd@mos.ru
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="auth-grid__form">
        <div className="auth-container">
          <div className="auth-container__body">
            <Switch>
              <Route path={routes.login} component={Login} />
              <Route path={routes.sign} component={Sign} />
              <Route path={routes.changePassword} component={ChangePassword} />
            </Switch>
          </div>
          <div className="auth-container__footer">
            <a target="_blank" rel="noreferrer" href="https://www.google.ru/chrome/">
              <IconChrome />
            </a>
            <a target="_blank" rel="noreferrer" href="https://browser.yandex.ru/">
              <IconYandex />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.mozilla.org/ru/firefox/">
              <IconFirefox />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.microsoft.com/ru-ru/edge">
              <IconEdge />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.apple.com/ru/safari/">
              <IconSafari />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(Auth);
