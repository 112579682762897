import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AddressForm from './address';
import ChildForm from './child';
import ApplicantForm from './applicant';
import Contract from './contract';
import Organization from './organization';
import Association from './association';
import ActionsForm from './actions';
import { RequestData } from '../../../types/requests';
import { RequestSourceEnum } from '../../../mock-data/request-source-enum';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { EducationTypeEnum } from '../../../types/education-type';
import InterdepartmentalChecks from '../components/fields/interdepartmental-checks';
import Status from './status';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const Details: React.FC<Props> = ({ requestData, updateRequest }) => {
  const [editMode, setEditMode] = useState<string | null>(null);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const btnEditDisabled = useMemo(
    () =>
      requestData.requestStatusId === RequestStatusEnum.Archive ||
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.ApplicantRefused ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      !hasAccessObjectAny(userProfile, [accessObject.RequestDOGM, accessObject.RequestNonDOGM], accessAction.Edit),
    [requestData.requestStatusId, userProfile]
  );

  const canNotEditPersonalData = useMemo(
    () =>
      requestData.requestSourceId === RequestSourceEnum.Mpgu &&
      requestData.educationTypeId === EducationTypeEnum.ChildrenEducation &&
      !hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile, requestData.requestSourceId, requestData.educationTypeId]
  );

  return (
    <>
      {!editMode && <Status updateRequest={updateRequest} requestData={requestData} />}
      {!editMode && requestData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation && (
        <InterdepartmentalChecks requestData={requestData} />
      )}

      {(!editMode || editMode === 'child') && (
        <ChildForm
          editable={!btnEditDisabled}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          requestChild={requestData.child}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
          isSourceMosRu={requestData.requestSourceId === RequestSourceEnum.Mpgu}
        />
      )}
      {(!editMode || editMode === 'address') &&
        requestData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation && (
          <AddressForm
            requestId={requestData.id}
            updateRequest={updateRequest}
            setEditModeParent={setEditMode}
            address={requestData.regAddress}
            educationTypeId={requestData.educationTypeId}
            editable={!btnEditDisabled && !(requestData.requestSourceId === RequestSourceEnum.Mpgu)}
          />
        )}
      {!editMode && (
        <Organization
          contingentGuid={requestData.contingentGuid}
          contingentLinkTypeId={requestData.contingentLinkTypeId}
        />
      )}
      {(!editMode || editMode === 'applicant') && (
        <ApplicantForm
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          requestApplicant={requestData.applicant}
          editable={!btnEditDisabled && !canNotEditPersonalData}
        />
      )}
      {!editMode && <Contract requestContract={requestData.contract} />}
      {!editMode && (
        <Association trainingGroup={requestData.trainingGroup} educationType={requestData.educationTypeId} />
      )}
      {!editMode && requestData.requestStatusId !== RequestStatusEnum.New && (
        <ActionsForm
          contingentGuid={requestData.contingentGuid}
          requestId={requestData.id}
          updateRequest={updateRequest}
          decline={requestData.decline}
          setEditModeParent={setEditMode}
          requestData={requestData}
          requestStatusId={requestData.requestStatusId}
          extendEntityGuid={requestData.extendEntityGuid}
          serviceId={requestData.trainingGroup?.serviceId}
          trainServiceClassId={requestData.trainingGroup?.serviceClass?.id}
        />
      )}
    </>
  );
};

export default Details;
