import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, IconSearch, Panel, Loader, Push } from '@mosru/esz_uikit';
import PageHeading from '../../components/header/page-heading';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { convertReportInfoToSidebarLink, SidebarLinkType } from '../../mock-data/routes';
import TreeLinkWrapper from '../../components/tree-link-wrapper';
import { reportsApi } from '../../lib/api/reports';
import { ReportInformation } from '../../types/reports';
import { routes } from '../../config/constants';
import { sendReachGoal } from '../../lib/metrica';

const Reports = () => {
  const [search, setSearch] = useState('');
  const [reportList, setReportList] = useState<ReportInformation[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const filteredReportList: SidebarLinkType[] | undefined = useMemo(() => {
    const list = search
      ? reportList?.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()) || item.hasChildren)
      : reportList;
    return list?.map(convertReportInfoToSidebarLink);
  }, [search, reportList]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const reports = await reportsApi.getReportList();
        setReportList(reports);
      } finally {
        setLoading(false);
      }
    };
    fetch();
    sendReachGoal('reports');
  }, []);

  return (
    <>
      <PageHeading
        title="Отчеты"
        sections={[{ title: 'Главная', link: routes.main }, { title: 'Отчеты' }]}
        controls={() => (
          <div className="report-search">
            <Input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Поиск по отчетам..."
              iconLeft={() => <IconSearch />}
              size="medium"
            />
          </div>
        )}
      />
      {loading ? (
        <>
          <Push size={12} />
          <Panel>
            <div className="flex justify-center flex-column">
              <Push orientation="vertical" size={140} />
              <Loader roller small />
              <Push orientation="vertical" size={140} />
            </div>
          </Panel>
        </>
      ) : (
        <>
          <Push size={12} />
          <TreeLinkWrapper userProfile={userProfile} itemList={filteredReportList ?? []} />
        </>
      )}
    </>
  );
};

export default Reports;
