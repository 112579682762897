import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, SelectOptionType, Panel, Loader, Push, SimpleTable as Table, notify } from '@mosru/esz_uikit';
import { Discipline } from '../../../../types/discipline';
import lookupApi from '../../../../lib/api/lookup';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import EditDisciplines from './edit-disciplines';
import teacherApi from '../../../../lib/api/teacher';
import { DisciplineEditObject, Teacher } from '../../../../types/teacher';
import { TeacherContext } from '../index';
import { isError, transformData } from '../helpers';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';

type Props = {
  values: Teacher;
  discipline: Discipline[] | undefined;
  setDiscipline: (value: Discipline[] | undefined) => void;
};

const TeacherDisciplines = ({ values, discipline, setDiscipline }: Props) => {
  const context = useContext(TeacherContext);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const access =
    !hasGeneralAccess(userProfile, generalAccess.AdminEdit) &&
    !hasAccessObjectAny(userProfile, [accessObject.Teachers, accessObject.TeachersAD], accessAction.Edit);

  const [rerenderTableKey, setRerenderTableKey] = useState(0);
  const [editObj, setEditObj] = useState<DisciplineEditObject>({ id: 0, name: '' });

  const [tableData, setTableData] = useState<DisciplineEditObject[]>(transformData(discipline));

  const filterTable = () => setTableData(tableData.filter((item: DisciplineEditObject) => item.id));

  const newDisciplineTeacher = async (valueDiscipline: { newId?: number; name: string }) => {
    if (discipline) {
      const allDisciplines = [...discipline, { id: valueDiscipline.newId, code: '', name: valueDiscipline.name }];

      if (!(values.firstName && values.lastName)) {
        isError(filterTable);
      } else {
        await teacherApi.addTeacherDiscipline(values.id, {
          id: valueDiscipline.newId,
          name: valueDiscipline.name,
          code: '',
        });
        setTableData(transformData(allDisciplines));
        setDiscipline(allDisciplines);
      }
    }
  };

  const updateDisciplinesTeacher = async (currentId: number, valueDiscipline: DisciplineEditObject) => {
    if (discipline) {
      const searchDisciplines = discipline.find((item: Discipline) => item.id === currentId);

      if (searchDisciplines) {
        const updatedList = discipline.map((item: Discipline) => {
          if (item.id === currentId) {
            return { ...item, id: valueDiscipline.newId, name: valueDiscipline.name };
          }
          return item;
        });

        if (!(values.firstName && values.lastName)) {
          isError(filterTable);
        } else {
          await teacherApi.deleteTeacherDiscipline(values.id, currentId);
          await teacherApi.addTeacherDiscipline(values.id, {
            id: valueDiscipline.newId,
            name: valueDiscipline.name,
            code: '',
          });
          setDiscipline(updatedList);
          setTableData(transformData(updatedList));
        }
      } else {
        newDisciplineTeacher(valueDiscipline);
      }
    }
  };

  const deleteDiscipline = async (id: number) => {
    if (discipline) {
      const deleteDis = discipline.filter((item: Discipline) => item.id !== id);

      if (!(values.firstName && values.lastName)) {
        isError(filterTable);
      } else {
        await teacherApi.deleteTeacherDiscipline(values.id, id);
        setEditObj({ id: 0, name: '' });
        setTableData(transformData(deleteDis));
        setDiscipline(deleteDis);
      }
    }
  };

  const selectDiscipline = (item: DisciplineEditObject) => {
    const { name, id } = editObj;

    if (!name) {
      return null;
    }

    if (name) {
      return { label: name, value: id || 0 };
    } else {
      return { label: item.name, value: item.id || 0 };
    }
  };

  const handleEditDiscipline = (id: number) => {
    if (tableData.find(({ id }) => editObj.newId === id)) {
      notify.danger({
        data: {
          label: 'Данная дисциплина уже добавлена',
          icon: true,
          close: true,
        },
      });
      return;
    }

    if (editObj.name) {
      updateDisciplinesTeacher(id, editObj);
    } else {
      filterTable();
    }
    setEditObj({ id: 0, name: '' });
    setRerenderTableKey(Math.random());
  };

  return (
    <Panel
      title={() => (
        <>
          Дисциплины
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableData.length}
          </span>
        </>
      )}
      headingControl={() =>
        !access ? (
          <button
            type="button"
            disabled={editObj.id === undefined}
            onClick={() => {
              const newData = { name: '' };
              const newTableData = new Array(newData).concat(tableData);
              setTableData(newTableData);
              setEditObj(newData);
            }}
            className="icon-group"
          >
            <span className="icon-group__icon">
              <IconPlus />
            </span>
            <span className="icon-group__text color-primary">
              <b>Добавить дисциплину</b>
            </span>
          </button>
        ) : null
      }
    >
      {context?.loading ? (
        <div className="flex justify-center flex-column">
          <Push orientation="vertical" size={20} />
          <Loader roller small />
          <Push orientation="vertical" size={20} />
        </div>
      ) : tableData.length ? (
        <Table
          overflow
          hideSort
          data={tableData}
          key={rerenderTableKey}
          columns={[
            {
              dataIndex: 'count',
              title: '№',
              width: '80px',
              render: (item: any) => item.count,
            },
            {
              dataIndex: 'name',
              title: 'Наименование',
              width: '96%',
              render: (item: any) => {
                return editObj.id === item.id ? (
                  <Select
                    name="discipline"
                    isSearchable
                    placeholder="Начните вводить название дисциплины…"
                    onChange={(selectedOption) => {
                      const option = selectedOption as SelectOptionType;

                      const value = (option && option.value) || null;
                      const label = (option && option.label) || null;

                      setEditObj((prev: DisciplineEditObject) => ({
                        ...prev,
                        newId: value,
                        name: label,
                      }));
                    }}
                    value={selectDiscipline(item)}
                    loadOptions={async (query) => await lookupApi.getDisciplines(query)}
                    options={[]}
                    size="small"
                  />
                ) : (
                  item.name
                );
              },
            },
            {
              dataIndex: 'code',
              title: '',
              width: '80px',
              render: (item: any) => (
                <EditDisciplines
                  id={item.id}
                  isAcceptedEdit={!access}
                  editObject={editObj}
                  key={tableData.length}
                  filterTable={filterTable}
                  deleteDiscipline={deleteDiscipline}
                  setEditMode={(mode) => {
                    setEditObj(mode ? item : { id: 0, name: '' });
                    setRerenderTableKey(Math.random());
                  }}
                  editMode={editObj.id === item.id}
                  submitHandler={handleEditDiscipline}
                />
              ),
            },
          ]}
        />
      ) : (
        <div className="teacher-no-data">Не добавлено ни одной преподаваемой дисциплины...</div>
      )}
    </Panel>
  );
};

export default TeacherDisciplines;
