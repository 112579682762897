import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Panel, Loader, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { PersonRequestData } from '../../../types/requests';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { submitPersonRequestData } from '../utils';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import ContingentSearch from '../components/fields/contingent-search';
import Gender from '../../../components/fields/gender';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import Snils from '../components/fields/snils';
import MoscowRegistration from '../components/fields/moscow-registration';
import { childrenValidationSchema } from './create';
import DependentDocument from '../components/fields/dependent-document';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import RegistryOffice from '../components/fields/registry-office';

type ChildFormProps = {
  data: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  editable?: boolean;
  contingentLinkTypeId: ContingentLinkTypeEnum;
  updateRequest: () => void;
};

const ChildForm: React.FC<ChildFormProps> = ({
  setEditModeParent,
  data,
  editable,
  contingentLinkTypeId,
  updateRequest,
}: ChildFormProps) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(data, childrenValidationSchema);
  const [loading, setLoading] = useState(false);

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      await submitPersonRequestData(values, true, setLoading, setEditMode, setEditModeParent, updateRequest);
    },
    [setEditModeParent, updateRequest]
  );
  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={childrenValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={data}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm, values } = formikProps;
        const isBirthRecord = values.documentTypeId === DocumentTypeEnum.BirthRecord;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о ребенке'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('child');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <DependentDocument>
                    <FIO label="ФИО учащегося" contingent={contingentLinkTypeId} required editMode={editMode} />
                    <BirthDate name="dateOfBirth" searchButton required editMode={editMode} />
                    <ContingentSearch editMode={editMode} />
                    <Gender editMode={editMode} required name="sex" />
                    <Document name="documentType" required editMode={editMode} dependentFields />
                    <DocumentNumber editMode={editMode} required showErrorImmediately calendarPosition="top-end" />
                    {isBirthRecord ? <RegistryOffice name="issued" required editMode={editMode} /> : null}
                    <Snils name="snils" disabled editMode={editMode} />
                    <MoscowRegistration name="moscowRegistration" editMode={editMode} />
                  </DependentDocument>
                </div>
              </div>
            </Panel>

            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ChildForm;
