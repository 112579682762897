import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EducationTypeEnum } from '../../types/education-type';
import DkgmRequest from './dkgm/create';
import DayCareRequestCreate from './day-care/create';
import DonmRequestCreate from './donm/create';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { access, RequestContext } from '../../lib/utils/requests';
import PreparationCreate from './preparation/create';

const CreateRequest = () => {
  const { edType } = useParams<Record<string, string | undefined>>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const currentRequest = (edId: string | undefined) => {
    if (edId) {
      const edTypeId = parseInt(edId);
      switch (edTypeId) {
        case EducationTypeEnum.ChildrenEducation:
        case EducationTypeEnum.ChildrenNonDogmEducation:
          return <DonmRequestCreate educationType={edTypeId} />;
        case EducationTypeEnum.SportEducation:
        case EducationTypeEnum.ArtHouseEducation:
          return <DkgmRequest educationType={edTypeId} />;
        case EducationTypeEnum.DayCareCentersEducation:
          return <DayCareRequestCreate />;
        case EducationTypeEnum.ProfessionalEducation:
          return <PreparationCreate />;
        default:
          return null;
      }
    }
  };
  return <RequestContext.Provider value={access(userProfile)}>{currentRequest(edType)}</RequestContext.Provider>;
};

export default CreateRequest;
