import React from 'react';
import { useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceDataInfo } from '../../../../types/service';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';

type Props = { editMode: boolean; parent?: string };

export const ServiceDuration: React.FC<Props> = ({ editMode, parent }) => {
  const { values } = useFormikContext<ServiceDataInfo>();
  const disabled = values.hasRequests || (values.hasMegaRelations && !values.hasAllMegaRelationsExcludedOrChanged);
  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Продолжительность услуги {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <div className="table-data-grid-4 items-start">
            <div className="table-data__group">
              <div className="table-data__label">Лет</div>
              <div className="table-data__body">
                <FormikFormGroup
                  required
                  label=""
                  name={parent ? `${parent}.durationOfTraining` : 'durationOfTraining'}
                >
                  <FormikInput
                    number
                    size="small"
                    placeholder="0"
                    onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    name={parent ? `${parent}.durationOfTraining` : 'durationOfTraining'}
                    disabled={disabled}
                  />
                </FormikFormGroup>
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__label">Месяцев</div>
              <div className="table-data__body">
                <FormikFormGroup
                  required
                  label=""
                  name={parent ? `${parent}.durationOfTrainingMonths` : 'durationOfTrainingMonths'}
                >
                  <FormikInput
                    onKeyPress={(e: any) => {
                      allowOnlyNumbers(e);
                    }}
                    name={parent ? `${parent}.durationOfTrainingMonths` : 'durationOfTrainingMonths'}
                    size="small"
                    placeholder="0"
                    number
                    disabled={disabled}
                  />
                </FormikFormGroup>
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__label">Недель</div>
              <div className="table-data__body">
                <FormikFormGroup
                  required
                  label=""
                  name={parent ? `${parent}.durationOfTrainingWeeks` : 'durationOfTrainingWeeks'}
                >
                  <FormikInput
                    onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    name={parent ? `${parent}.durationOfTrainingWeeks` : 'durationOfTrainingWeeks'}
                    size="small"
                    placeholder="0"
                    number
                    disabled={disabled}
                  />
                </FormikFormGroup>
              </div>
            </div>
            <div className="table-data__group">
              <div className="table-data__label">Дней</div>
              <div className="table-data__body">
                <FormikFormGroup
                  required
                  label=""
                  name={parent ? `${parent}.durationOfTrainingDays` : 'durationOfTrainingDays'}
                >
                  <FormikInput
                    onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    name={parent ? `${parent}.durationOfTrainingDays` : 'durationOfTrainingDays'}
                    size="small"
                    placeholder="0"
                    number
                    disabled={disabled}
                  />
                </FormikFormGroup>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            {!!values.durationOfTraining && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Лет</div>
                  <div className="table-data__body" style={{ width: 200 }}>
                    {values.durationOfTraining}
                  </div>
                </div>

                <Push size={16} orientation="horizontal" />
              </>
            )}
            {!!values.durationOfTrainingMonths && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Месяцев</div>
                  <div className="table-data__body" style={{ width: 200 }}>
                    {values.durationOfTrainingMonths}
                  </div>
                </div>
                <Push size={16} orientation="horizontal" />
              </>
            )}
            {!!values.durationOfTrainingWeeks && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Недель</div>
                  <div className="table-data__body" style={{ width: 200 }}>
                    {values.durationOfTrainingWeeks}
                  </div>
                </div>
                <Push size={16} orientation="horizontal" />
              </>
            )}
            {!!values.durationOfTrainingDays && (
              <>
                <div className="table-data__group">
                  <div className="table-data__label">Дней</div>
                  <div className="table-data__body" style={{ width: 200 }}>
                    {values.durationOfTrainingDays}
                  </div>
                </div>
                <Push size={16} orientation="horizontal" />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
