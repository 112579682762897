import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import moment from 'moment';
import { Button, ModalPanel, Modal, Infobox, SelectOptionType, Loader, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import learnerApi from '../../../../lib/api/learner';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { ServiceClassTableContext } from '../../utils';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassPupil } from '../../../../types/service-class';
import { Learner } from '../../../../types/learners';

type Props = {
  open: boolean;
  close: () => void;
  readOnly?: boolean;
  groupMembers?: ServiceClassPupil | null;
};

const Certificate: React.FC<Props> = ({ open, close, readOnly, groupMembers }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData, learners } =
    useContext(ServiceClassTableContext);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [hasFinishedService, setHasFinishedService] = useState<boolean>(false);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);

  const [currentDocData, setCurrentDocData] = useState<Learner.Document | null>(null);
  const [formKey, setFormKey] = useState<number>(0);

  const selectedPeople = learners?.find((value) => selected.includes(value.id));

  const initialValues = useMemo(
    () => ({
      documentTypeId: 7,
      serviceName: serviceClassData.serviceName,
      unionCatalogServicesId: serviceClassData.serviceId,
      programmModuleId: serviceClassData.programmModuleId,
      programName: serviceClassData.programmModuleName,
      organizationId: serviceClassData.organizationId,
      organizationName: serviceClassData.organizationName,
      pupilId: selectedPeople?.pupilId,
      programmLevelName: readOnly ? groupMembers?.programmLevelName : selectedPeople?.programmLevelName,
      programmLevelId: readOnly ? groupMembers?.programmLevelId : selectedPeople?.programmLevelId,
      docNumber: readOnly ? currentDocData?.docNumber : undefined,
      issueDate: readOnly ? moment(currentDocData?.issueDate).toDate() : undefined,
    }),
    [serviceClassData, selectedPeople, readOnly, groupMembers, currentDocData]
  );

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const submitForm = useCallback(
    async (values: any) => {
      setLoadBtn(true);
      try {
        await learnerApi.postDocument({ ...values, programmLevel: values.programmLevelId });
        close();
        fetchTable();
        setSelected([]);
        setOpenDetails(false);
      } finally {
        setLoadBtn(false);
      }
    },
    [close, fetchTable, setSelected, setOpenDetails]
  );

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(7);
      setDocuments(documentTypes);
      if (selectedPeople?.pupilId) {
        const finishedServices = await learnerApi.getDocumentFinishedServices({ pupilId: selectedPeople?.pupilId });
        setHasFinishedService(finishedServices.some((item) => item.serviceId === serviceClassData.serviceId));
      }
      setLoader(false);
    };
    fetch();
  }, [selectedPeople?.pupilId, serviceClassData.serviceId]);

  useEffect(() => {
    if (readOnly) {
      const fetch = async () => {
        if (groupMembers) {
          const data = {
            pupilId: String(groupMembers?.pupilId),
            docId: String(groupMembers?.completionDocumentList[0].id),
          };
          const document = await learnerApi.getDocument(data);

          setCurrentDocData(document);
          setFormKey(Math.random());
        }
      };

      fetch();
    }
  }, [groupMembers, readOnly]);

  const showReadOnlyModal = readOnly ? true : hasFinishedService;

  const professionOption = readOnly
    ? {
        label: groupMembers?.classificatorEKUName || '',
        value: groupMembers?.serviceId || null,
      }
    : {
        label: selectedPeople?.classificatorEKUName || '',
        value: serviceClassData.serviceId || null,
      };

  const programmLevelOption = readOnly
    ? {
        label: groupMembers?.programmLevelName || '',
        value: groupMembers?.programmLevelId || null,
      }
    : {
        label: selectedPeople?.programmLevelName || '',
        value: selectedPeople?.programmLevelId || null,
      };

  return (
    <Modal show={open} onClose={close}>
      <Formik
        key={formKey}
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<any>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                maxHeightBody="calc(100vh - 134px)"
                onClose={close}
                controls={() =>
                  showReadOnlyModal &&
                  !loader && (
                    <>
                      <Button label="Отмена" border primary size="small" onClick={close} />
                      <Push orientation="horizontal" size={12} />
                      <Button
                        label="Выдать сертификат"
                        disabled={!isValid || isSubmitting || readOnly}
                        load={loadBtn}
                        primary
                        size="small"
                        submit
                      />
                    </>
                  )
                }
                modalTitle="Выдача сертификата"
                renderComponent={() =>
                  loader ? (
                    <div className="learners-modal-right__loader">
                      <Loader small roller />
                    </div>
                  ) : showReadOnlyModal ? (
                    <>
                      <Infobox
                        text={`Документ о прохождении обучения для "${
                          readOnly ? groupMembers?.pupilName : selectedPeople?.pupilName
                        }"`}
                        color="primary"
                      />

                      <Push size={16} />
                      <FormikFormGroup name="documentTypeId" label="Тип документа" required>
                        <FormikSelect
                          disabled
                          name="documentTypeId"
                          size="small"
                          options={documents}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="docNumber" label="Номер документа" required>
                        <FormikInput size="small" name="docNumber" placeholder="Введите..." disabled={readOnly} />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="issueDate" label="Дата" required>
                        <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="issueDate" disabled={readOnly} />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="unionCatalogServicesId" label="Образовательная программа" required>
                        <FormikSelect
                          disabled
                          name="unionCatalogServicesId"
                          size="small"
                          defaultValue={
                            serviceClassData.serviceId && serviceClassData.serviceName
                              ? { label: serviceClassData.serviceName, value: serviceClassData.serviceId }
                              : null
                          }
                          options={
                            serviceClassData.serviceId && serviceClassData.serviceName
                              ? [{ label: serviceClassData.serviceName, value: serviceClassData.serviceId }]
                              : []
                          }
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="programmModuleId" label="Модуль" required>
                        <FormikSelect
                          disabled
                          name="programmModuleId"
                          size="small"
                          defaultValue={
                            serviceClassData.programmModuleId && serviceClassData.programmModuleName
                              ? { label: serviceClassData.programmModuleName, value: serviceClassData.programmModuleId }
                              : null
                          }
                          options={
                            serviceClassData.programmModuleId && serviceClassData.programmModuleName
                              ? [
                                  {
                                    label: serviceClassData.programmModuleName,
                                    value: serviceClassData.programmModuleId,
                                  },
                                ]
                              : []
                          }
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="organizationId" label="Организация">
                        <FormikSelect
                          disabled
                          name="organizationId"
                          size="small"
                          defaultValue={
                            serviceClassData.organizationId && serviceClassData.organizationName
                              ? { label: serviceClassData.organizationName, value: serviceClassData.organizationId }
                              : null
                          }
                          options={
                            serviceClassData.organizationId && serviceClassData.organizationName
                              ? [{ label: serviceClassData.organizationName, value: serviceClassData.organizationId }]
                              : []
                          }
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="" label="Профессия">
                        <FormikSelect
                          disabled
                          name=""
                          size="small"
                          // Возможно тут что-то другое должно быть
                          defaultValue={professionOption}
                          options={[]}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="programmLevelId" label="Квалификация">
                        <FormikSelect
                          disabled
                          name="programmLevelId"
                          size="small"
                          // Возможно тут что-то другое должно быть
                          defaultValue={programmLevelOption}
                          options={[]}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                    </>
                  ) : (
                    <Infobox text="Не найдено завершенных образовательных программ" color="danger" />
                  )
                }
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Certificate;

const getValidationSchema = () =>
  objectYup().shape({
    documentTypeId: stringYup().required('Выберите тип документа'),
    docNumber: stringYup().required('Введите номер документа'),
    issueDate: docDate.nullable().required('Введите дату документа'),
    unionCatalogServicesId: stringYup().required('Выберите образовательную программу'),
  });
