import React from 'react';
import { useField } from 'formik';
import { FormGroup, FormGroupProps } from '@mosru/esz_uikit';

export type FormicFormGroupType = FormGroupProps & {
  name: string;
  showErrorImmediately?: boolean;
};

const FormikFormGroupFromName: React.FC<FormicFormGroupType> = ({ name, showErrorImmediately, ...props }) => {
  const [, meta] = useField(name);

  const error = showErrorImmediately ? meta.error : meta.touched && meta.error;

  return (
    <FormGroup {...props} labelId={name} error={error ? (meta.error as string) : ''}>
      {props.children}
    </FormGroup>
  );
};

const FormikFormGroupFromNotName: React.FC<FormicFormGroupType> = ({ name, ...props }) => (
  <FormGroup {...props} labelId={name} error="">
    {props.children}
  </FormGroup>
);

const FormikFormGroup: React.FC<FormicFormGroupType> = (props) => {
  const { name } = props;

  if (name) {
    return <FormikFormGroupFromName {...props} />;
  } else {
    return <FormikFormGroupFromNotName {...props} />;
  }
};

export default FormikFormGroup;
