import { Field as FormikField, FieldProps as FormikFieldProps, useField, useFormikContext } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Push } from '@mosru/esz_uikit';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import { ReactComponent as IconSearch } from '../../../../assets/images/icons/search.svg';
import Field, { FieldProps } from '../../../../components/fields/field';
import contingentApi from '../../../../lib/api/contingents';
import { ChildrenSearchResult } from '../../../../types/contingent';
import { fioFirstNameField, fioLastNameField, fioMiddleNameField } from './fio';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { buildFormFieldName } from '../../../../lib/utils/requests';
import { formatDate, getDate } from '../../../../lib/utils/date';
import { DependentDocumentContext } from './dependent-document';

type BirthDateProps = FieldProps<any> & {
  searchButton?: boolean;
  setChildrenList?: React.Dispatch<React.SetStateAction<ChildrenSearchResult[] | null>> | null;
  disabled?: boolean;
  parent?: string;
  isClickedSelect?: boolean;
};

export const birthDateField = 'dateOfBirth';

const BirthDate = ({
  label = 'Дата рождения',
  name,
  editMode,
  required,
  searchButton,
  setChildrenList = null,
  disabled = false,
  parent,
  isClickedSelect,
}: BirthDateProps) => {
  const { values, initialValues } = useFormikContext<any>();

  const [fioFirstName] = useField(buildFormFieldName(parent, fioFirstNameField));
  const [fioLastName] = useField(buildFormFieldName(parent, fioLastNameField));
  const [fioMiddleName] = useField(buildFormFieldName(parent, fioMiddleNameField));
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isCLick = isClickedSelect || false;

    setSearchDisabled(!fioLastName.value || !fioFirstName.value || disabled || isCLick);
  }, [fioLastName, fioFirstName, disabled, isClickedSelect]);

  const [loading, setLoading] = useState(false);
  const birthDateField = buildFormFieldName(parent, name);

  const { birthDateKey } = useContext(DependentDocumentContext);

  const searchBtnDisabledRef = useRef<boolean>(false);

  const prevFirstName = parent ? initialValues?.child?.firstName : initialValues?.firstName;
  const prevLastName = parent ? initialValues?.child?.lastName : initialValues?.lastName;
  const prevMiddleName = parent ? initialValues?.child?.middleName : initialValues?.middleName;
  const prevDateOfBirth = parent ? initialValues?.child?.dateOfBirth : initialValues?.dateOfBirth;

  const currentFirstName = parent ? values?.child?.firstName : values?.firstName;
  const currentLastName = parent ? values?.child?.lastName : values?.lastName;
  const currentMiddleName = parent ? values?.child?.middleName : values?.middleName;
  const currentDateOfBirth = parent ? values?.child?.dateOfBirth : values?.dateOfBirth;

  useEffect(() => {
    const compareFirstName = prevFirstName === currentFirstName;
    const compareLastName = prevLastName === currentLastName;
    const compareMiddleName = prevMiddleName === currentMiddleName;
    const dateOfBirth = prevDateOfBirth === currentDateOfBirth;

    if (compareFirstName && compareLastName && compareMiddleName && dateOfBirth) {
      searchBtnDisabledRef.current = true;
    } else {
      searchBtnDisabledRef.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <FormikField name={birthDateField}>
      {({ field }: FormikFieldProps) => {
        const { value } = field;

        return (
          <Field
            editMode={editMode}
            label={label}
            defaultValue="Не указано"
            value={value ? formatDate(value) : value}
            required={required}
          >
            <div className="flex items-start white-space-nowrap">
              <div style={{ width: 220 }}>
                <FormikFormGroup required label="" name={birthDateField}>
                  <FormikDatePicker
                    key={birthDateKey}
                    placeholder="ДД.ММ.ГГГГ"
                    size="small"
                    name={birthDateField}
                    startDate={value ? getDate(value) : undefined}
                    disabled={disabled}
                  />
                </FormikFormGroup>
              </div>
              {searchButton && (
                <>
                  <Push orientation="horizontal" size={16} />
                  <Button
                    iconLeft={() => <IconSearch />}
                    primary
                    size="small"
                    label="Поиск"
                    load={loading}
                    onClick={async () => {
                      try {
                        setLoading(true);
                        const result = await contingentApi.searchChildrenByPersonalData(
                          fioFirstName.value,
                          fioLastName.value,
                          fioMiddleName.value,
                          value ? moment(value).format('YYYY-MM-DD') : undefined
                        );
                        if (result && setChildrenList) {
                          setChildrenList(result);
                        }
                      } catch {}
                      setLoading(false);
                    }}
                    disabled={searchDisabled || searchBtnDisabledRef.current}
                  />
                </>
              )}
            </div>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default BirthDate;
