import React, { useMemo } from 'react';
import { Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ContractStatusEnum } from '../../../mock-data/contract-status-enum';
import { ContractRequestData } from '../../../types/requests';
import { formatDateAndTime } from '../../../lib/utils/date';

type Props = {
  requestContract: ContractRequestData | undefined;
};

const Contract: React.FC<Props> = ({ requestContract }) => {
  const columns = useMemo(() => {
    const columnList = [
      {
        dataIndex: 'number',
        title: '№ договора',
        render: (item: any) =>
          item.contractNumber && (
            <a href={item.contractUrl} target="_blank" rel="noreferrer" className="brand-link">
              №{item.contractNumber}
            </a>
          ),
        hiddenSort: true,
      },
      {
        dataIndex: 'status',
        title: 'Статус',
        render: (item: any) => item.contractStatusName,
        hiddenSort: true,
      },
    ];

    if (requestContract?.contractStatusId === ContractStatusEnum.Terminated) {
      columnList.push({
        dataIndex: 'contractDeclineReasonName',
        title: 'Сторона расторжения договора',
        render: (item: any) => item.contractDeclineReasonName,
        hiddenSort: true,
      });
    }

    columnList.push({
      dataIndex: 'date',
      title: 'Дата',
      render: (item: any) =>
        item.contractStatusDate ? <span>от {formatDateAndTime(item.contractStatusDate)}</span> : '',
      hiddenSort: true,
    });

    if (
      (requestContract?.contractStatusId === ContractStatusEnum.Signed ||
        requestContract?.contractStatusId === ContractStatusEnum.Terminated) &&
      requestContract?.contractSigUrl
    ) {
      columnList.push({
        dataIndex: 'contractSigUrl',
        title: 'Электронная подпись',
        render: (item: any) => (
          <a href={item.contractSigUrl} target="_blank" rel="noreferrer" className="brand-link cursor-pointer">
            Посмотреть
          </a>
        ),
        hiddenSort: true,
      });
    }

    return columnList;
  }, [requestContract?.contractSigUrl, requestContract?.contractStatusId]);

  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация о договоре'}>
        {requestContract ? (
          <Table data={[requestContract]} pageSize={20} columns={columns} />
        ) : (
          <div className="requests-no-data">Нет данных</div>
        )}
      </Panel>
    </>
  );
};

export default Contract;
