import React, { useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import OrganizationFormInfo from './form-info';
import OrganizationFormSchedule from './form-schedule';
import OrganizationFormPoint from './form-point/form-point';
import OrganizationFormService from './form-service';
import { Organization } from '../../../types/organization';
import { accessVedomst } from '../../../mock-data/access-enum';
import OrganizationAddress from './adress';
import { PlaceServices } from '../../../types/place-service';
import { ItemsWork } from '../../../types/items-work';

type OrganizationDetailsProps = {
  organization: Organization;
  departments: SelectOptionType[];
  updateOrganization: (value: Organization) => void;
};

const OrganizationDetails = ({ organization, departments, updateOrganization }: OrganizationDetailsProps) => {
  const [editMode, setEditMode] = useState<string | null>(null);

  const [placeServices, setPlaceServices] = useState<PlaceServices[]>();
  const [itemWorks, setItemWorks] = useState<ItemsWork[]>([]);

  return (
    <>
      {(!editMode || (editMode && editMode === 'info')) && (
        <OrganizationFormInfo
          setEditModeParent={(value) => setEditMode(value ? 'info' : null)}
          organization={organization}
          departments={departments}
          updateOrganization={updateOrganization}
        />
      )}

      {!editMode && organization.addressList && (
        <OrganizationAddress
          itemWorks={itemWorks}
          placeServices={placeServices}
          addresses={organization.addressList}
          vedomstvoId={organization.vedomstvoId}
        />
      )}
      {(!editMode || (editMode && editMode === 'time')) && organization.vedomstvoId !== accessVedomst.Dogm && (
        <OrganizationFormSchedule
          setEditModeParent={(value) => setEditMode(value ? 'time' : null)}
          organization={organization}
        />
      )}
      {!editMode && organization.vedomstvoId !== accessVedomst.Dogm && (
        <OrganizationFormPoint setWork={setItemWorks} organization={organization} />
      )}
      {!editMode && organization.vedomstvoId !== accessVedomst.Dogm && (
        <OrganizationFormService setPlace={setPlaceServices} organization={organization} />
      )}
    </>
  );
};

export default OrganizationDetails;
