import React, { useEffect, useState } from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps, useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { MoscowRegistrationTypeEnum } from '../../../../mock-data/moscow-registration-type-enum';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

type MoscowRegistrationProps = FieldProps<SelectOptionType>;

const MoscowRegistration = ({ label = 'Регистрация в Москве', name, editMode, required }: MoscowRegistrationProps) => {
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);
  const [optionsSet, setOptionsSet] = useState<boolean>(false);
  const fieldId = `${name}Id`;
  const fieldName = `${name}Name`;

  const [, , setFiledId] = useField(fieldId);
  const [, , setFiledLabel] = useField(fieldName);

  useEffect(() => {
    const fetchOptions = async () => {
      setOptionsData(await dictionariesApi.getMoscowRegistrationTypes());
      setOptionsSet(true);
    };
    if (!optionsSet) {
      fetchOptions();
    }
  }, [optionsSet]);

  return (
    <FormikField name={fieldId}>
      {({ form }: FormikFieldProps) => {
        const v = form.values[fieldId] ?? MoscowRegistrationTypeEnum.None;
        const l = form.values[fieldName] ?? optionsData?.find((m) => m.value === v)?.label;
        return (
          <Field label={label} editMode={editMode} required={required} value={l}>
            <FormikFormGroup required label="" name={fieldId}>
              <FormikSelect
                name={fieldId}
                size="small"
                placeholder="Выберите"
                isSearchable
                options={optionsData}
                defaultValue={{ value: v, label: l }}
                selectedValue={(selected: SelectOptionType) => {
                  setFiledId.setValue(selected.value);
                  setFiledLabel.setValue(selected.label);
                }}
                menuPlacement="top"
              />
            </FormikFormGroup>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default MoscowRegistration;
