import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';

type MarkProps = FieldProps<string>;

const markList = [
  { value: undefined, label: 'Не указано' },
  { value: 2, label: 'Неудовлетворительно' },
  { value: 3, label: 'Удовлетворительно' },
  { value: 4, label: 'Хорошо' },
  { value: 5, label: 'Отлично' },
];

const Mark = ({ label, defaultValue = '—', name, editMode, required, disabled }: MarkProps) => {
  return (
    <FormikField name={name}>
      {({ form }: FormikFieldProps) => {
        const value = form.values[name];
        const l = markList?.find((m) => m.value === value)?.label;
        return (
          <Field label={label} editMode={editMode} required={required} value={l} defaultValue={defaultValue}>
            <div className="table-data-grid-4">
              <FormikFormGroup required label="" name={name}>
                <FormikSelect
                  name={name}
                  size="small"
                  hideClearIndicator
                  options={markList as SelectOptionType[]}
                  placeholder="Выберите оценку"
                  disabled={disabled}
                />
              </FormikFormGroup>
            </div>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default Mark;
