import { notify } from '@mosru/esz_uikit';
import axios, { AxiosError, AxiosRequestConfig, HttpStatusCode } from 'axios';
import { debugMode, routes } from '../../config/constants';
import history from '../../history';
import { StatusResponseCode } from '../../mock-data/status-response-enum';
import { signOut } from '../../redux/utils';
import CustomError from '../custom-error';
import tokenManager from '../token-manager';
import { redirectToLogin } from '../utils';

export type RequestConfig = AxiosRequestConfig & {
  isProtected?: boolean;
  showAccessDeny?: boolean;
};

const axiosBaseQuery = (params?: Pick<AxiosRequestConfig, 'baseURL'>) => {
  const baseURL = params?.baseURL;
  return async (requestConfig: RequestConfig = {}) => {
    const { isProtected = true, showAccessDeny = true, ...config } = requestConfig;
    const isTokenValid = await tokenManager.isTokenValid();

    if (isProtected && !isTokenValid) {
      if (debugMode) {
        notify.danger({
          data: {
            label: 'Для совершения запроса необходима авторизация, пожалуйста, авторизуйтесь.',
            icon: true,
            close: false,
          },
        });
        console.warn('redirected to login from src/lib/api/index.ts token is invalid');
      }

      return signOut();
    }

    const token = tokenManager.getToken();
    const headers = isProtected
      ? {
          ...config.headers,
          ESZToken: `${token || ''}`,
        }
      : { ...config.headers };

    try {
      const response = await axios({
        baseURL,
        ...config,
        headers,
      });
      const contentDisposition = response.headers['content-disposition'];

      if (contentDisposition && response.config.responseType === 'blob') {
        const blob = response.data;
        return { contentDisposition, blob };
      }
      return { data: response.status === HttpStatusCode.NoContent ? null : response.data };
    } catch (reason) {
      if (!axios.isAxiosError(reason)) {
        return Promise.reject(reason);
      } else if (reason.code === AxiosError.ECONNABORTED) {
        console.error(reason.message);
        return Promise.reject(reason);
      } else if (reason.response) {
        const { response } = reason;
        const error = response.data;

        switch (response.status) {
          case HttpStatusCode.Unauthorized:
            tokenManager.clearToken();

            if (!isProtected) {
              debugMode &&
                notify.danger({
                  data: {
                    label: 'Для совершения запроса необходима авторизация, пожалуйста, авторизуйтесь.',
                    icon: true,
                    close: false,
                  },
                });
            }

            await redirectToLogin({
              redirectUrl: window.location.pathname,
            });
            return;
          case HttpStatusCode.Forbidden:
            showAccessDeny && history.push(routes.accessDeny);
            break;
          case HttpStatusCode.BadRequest:
          case HttpStatusCode.NotFound:
          case HttpStatusCode.UnprocessableEntity:
          case StatusResponseCode.LogicError:
          case StatusResponseCode.Crash:
            if (response.status === HttpStatusCode.NotFound && showAccessDeny) {
              history.push(routes.accessDeny);
            }

            if (error.messages instanceof Array) {
              notify.danger({
                data: {
                  label: error.messages.join('; '),
                  icon: true,
                  close: false,
                },
              });
            }

            if (error.errors) {
              notify.danger({
                data: {
                  label: Object.keys(error.errors)
                    .map((i) => error.errors[i])
                    .join('; '),
                  icon: true,
                  close: false,
                },
              });
            }

            if (error.message) {
              notify.danger({
                data: {
                  label: error.message,
                  icon: true,
                  close: false,
                },
              });
            }
            break;
          case StatusResponseCode.LogicErrorWithoutNotify:
            return error;
          case HttpStatusCode.ServiceUnavailable:
            return Promise.reject(new Error('Server is under maintenance'));
          case HttpStatusCode.InternalServerError:
          case HttpStatusCode.BadGateway:
            history.location.pathname !== routes.unknownError && history.push(routes.unknownError, { error });
            return Promise.reject(error);
          default:
            break;
        }

        return Promise.reject(
          new CustomError(
            typeof error === 'string'
              ? {
                  message: error || `Unhandled error. Server status code ${response.status}`,
                  status: response.status,
                }
              : error
          )
        );
      } else {
        return Promise.reject(reason);
      }
    }
  };
};

export default axiosBaseQuery;
