import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Button, ButtonGroup, Loader, Push } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import PlanDetails from './plan-details';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/remove.svg';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ScheduleData, ServiceData, TrainingGroupData } from '../../../types/service';
import { generateLink, hasAccessObjectAny, hasGeneralAccess, parseUrlSearchString } from '../../../lib/utils';
import { TrainingGroupStatusEnum } from '../../../mock-data/training-group-status-enum';
import SimpleModal from '../components/modals/simple-modal';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import history from '../../../history';
import { formatDate } from '../../../lib/utils/date';

const Plan = () => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const [isRetro, setIsRetro] = useState<boolean>();
  const params = useParams<{ serviceId: string; id: string }>();
  const [service, setService] = useState<ServiceData>();
  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupData>();
  const [schedule, setSchedule] = useState<ScheduleData>();
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // todo костыль как будет время нужно поправить
  const [key, setKey] = useState<number>(0);

  const location = useLocation();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    setIsRetro(
      trainingGroup?.scheduleList?.length
        ? trainingGroup.scheduleList[0].isRetro
        : !!parseUrlSearchString(location.search).isRetro
    );
  }, [trainingGroup, location.search]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const service = await serviceTemplateApi.getServiceById(params.serviceId);
        setService(service);
      } catch (e) {
        setService({} as ServiceData);
      } finally {
        setIsLoading(false);
      }
    };
    if (params.serviceId) {
      fetch();
    }
  }, [params.serviceId]);

  const getTraininig = useCallback(async () => {
    if (params.id !== '0') {
      try {
        setIsLoading(true);
        const tg = await serviceTemplateApi.getTrainingGroup(params.id);
        setTrainingGroup(tg);
        if (tg?.scheduleList?.length > 0) {
          setSchedule(tg?.scheduleList[0]);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setTrainingGroup({ id: 0 } as TrainingGroupData);
    }
  }, [params.id]);

  useEffect(() => {
    getTraininig();
  }, [params.id, getTraininig]);

  const canEdit = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
      hasAccessObjectAny(userProfile, [accessObject.ServiceArtHouse, accessObject.ServiceSport], accessAction.Edit),
    [userProfile]
  );

  const scheduleStatus = useMemo(() => {
    if (!service || !trainingGroup || !trainingGroup.scheduleList) {
      return undefined;
    } else {
      return service?.isTestService
        ? trainingGroup?.scheduleList.some((s) =>
            [TrainingGroupStatusEnum.Signed, TrainingGroupStatusEnum.Unavailable].includes(s.trainingGroupStatusId)
          )
          ? TrainingGroupStatusEnum.Signed
          : TrainingGroupStatusEnum.Draft
        : trainingGroup?.scheduleList[0]?.trainingGroupStatusId;
    }
  }, [trainingGroup, service]);

  const canDelete = useMemo(
    () =>
      canEdit &&
      scheduleStatus === TrainingGroupStatusEnum.Draft &&
      (schedule?.requestTotalCount === 0 || service?.isTestService),
    [schedule, scheduleStatus, canEdit, service?.isTestService]
  );

  const updateStatus = useCallback(
    async (statusId: TrainingGroupStatusEnum) => {
      await serviceTemplateApi.updateTrainingGroupStatus({
        serviceId: params.serviceId,
        educationTypeId: trainingGroup?.educationTypeId,
        trainingGroupId: trainingGroup?.id ?? 0,
        trainingGroupStatusId: statusId,
      });
      getTraininig();
      setKey(Math.random());
    },
    [trainingGroup, params.serviceId, getTraininig]
  );

  const handleEditModal = async () => {
    if (trainingGroup) {
      await updateStatus(TrainingGroupStatusEnum.Unavailable);
    }
  };

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: (
          <PlanDetails key={key} trainingGroup={trainingGroup} service={service} getTraininig={getTraininig} />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name=""
            extendEntityGuid={trainingGroup?.extendEntityGuid}
            entityTypes={[EntityTypeEnum.Service]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [trainingGroup, service, getTraininig, key]
  );

  const getStatus = (status?: TrainingGroupStatusEnum) => {
    switch (status) {
      case TrainingGroupStatusEnum.Archive:
        return <Badge text="Архивное" color="default" />;
      case TrainingGroupStatusEnum.Unavailable:
        return <Badge text="Приема нет" color="warning" />;
      case TrainingGroupStatusEnum.Signed:
        return <Badge text="Опубликовано" color="success" />;
      default:
        return <Badge text="Черновик" color="default" />;
    }
  };

  return (
    <>
      {service && trainingGroup ? (
        <>
          <PageHeading
            buttonBack
            buttonBackFn={() => history.push(generateLink(routes.service, { id: params.serviceId }))}
            title={`План приема${
              isRetro && !service?.isTestService
                ? ' без публикации на Mos.ru'
                : trainingGroup?.educationStartDate
                ? ` ${formatDate(trainingGroup.educationStartDate)}`
                : ''
            }`}
            sections={[
              { title: 'Главная', link: routes.main },
              { title: 'Образовательные услуги', link: routes.services },
              {
                title: 'Описание образовательной услуги',
                link: generateLink(routes.service, { id: params.serviceId }),
              },
              { title: 'План приема' },
            ]}
            tabs={tabs}
            tabsButton={!service?.isTestService && getStatus(schedule?.trainingGroupStatusId)}
            controlsTopRight={
              <>
                {canDelete && (
                  <ButtonGroup>
                    {!service?.isTestService && (
                      <Button
                        label="В архив"
                        iconLeft={() => <IconArchive />}
                        size="small"
                        onClick={async () => {
                          await updateStatus(TrainingGroupStatusEnum.Archive);
                        }}
                      />
                    )}
                    <Button
                      label="Удалить"
                      iconLeft={() => <IconRemove />}
                      size="small"
                      onClick={async () => {
                        await serviceTemplateApi.deleteTrainingGroup(params.serviceId, trainingGroup.id);
                        history.push(generateLink(routes.service, { id: params.serviceId }));
                      }}
                    />
                  </ButtonGroup>
                )}{' '}
                {schedule?.trainingGroupStatusId === TrainingGroupStatusEnum.Unavailable && !service.isTestService ? (
                  <div className="flex">
                    <Push size={12} orientation="horizontal" />
                    <Button
                      label="Возобновить прием"
                      size="small"
                      primary
                      onClick={async () => {
                        await updateStatus(TrainingGroupStatusEnum.Signed);
                      }}
                    />
                  </div>
                ) : (
                  schedule &&
                  !schedule.isRetro &&
                  schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
                  schedule.requestTotalCount > 0 &&
                  !service.isTestService && (
                    <Button
                      label="Остановить прием"
                      size="small"
                      primary
                      onClick={async () => {
                        setShowUnavailableModal(true);
                      }}
                    />
                  )
                )}
              </>
            }
            activeTab={activeTab}
          />
          {tabs.find((item) => item.label === activeTab)?.component}
          <SimpleModal
            show={showUnavailableModal}
            handleSubmit={handleEditModal}
            onClose={() => {
              setShowUnavailableModal(false);
            }}
            title="Остановка приема заявлений"
            description="Внимание! После подтверждения прием заявлений на Mos.ru будет остановлен."
          />
        </>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto loader-auto--bg">
            <Loader roller />
          </div>
        </>
      ) : null}
    </>
  );
};

export default Plan;
