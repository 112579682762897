import { AnyAction } from 'redux';
import { UserSettings } from '../../types/system-settings';

export const moduleName = 'system-settings';
export const SET_USER_SYSTEM = `${moduleName}/SET_USER_SYSTEM`;

export const reducerRecord: UserSettings = Object.freeze({
  meshLink: '',
});

// Reducer
export default function reducer(state: UserSettings = reducerRecord, action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_SYSTEM:
      return {
        meshLink: payload.data,
      };

    default:
      return state;
  }
}

// AC
export const setUserSystem = (data: any) => ({
  type: SET_USER_SYSTEM,
  payload: { data },
});
