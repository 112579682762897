import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeading from '../../../../components/header/page-heading';
import { routes } from '../../../../config/constants';
import ServiceProgramCreateDetails from './details';
import { getHeaderLink } from '../../utils';
import { CreateServiceContext } from '../../create';

const ServiceProgramCreate = () => {
  const { currentEducationType, educationTypes, type } = useContext(CreateServiceContext);
  const history = useHistory();

  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={() => history.push(getHeaderLink(educationTypes, type, currentEducationType))}
        title="Новая образовательная программа"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Образовательные программы', link: getHeaderLink(educationTypes, type, currentEducationType) },
          { title: 'Описание образовательной программы' },
        ]}
      />
      <ServiceProgramCreateDetails />
    </>
  );
};

export default ServiceProgramCreate;
