import { useField } from 'formik';
import { Checkbox, CheckboxType } from '@mosru/esz_uikit';

export type FormicCheckboxProps = CheckboxType & {
  name: string;
};

const FormikCheckbox: React.FC<Omit<Omit<Omit<FormicCheckboxProps, 'labelId'>, 'onChange'>, 'checked'>> = ({
  name,
  ...props
}) => {
  const [field, , helpers] = useField(name);

  return (
    <Checkbox
      {...props}
      {...field}
      labelId={name}
      onChange={(checked) => {
        helpers.setValue(checked);
      }}
      checked={field.value}
    />
  );
};

export default FormikCheckbox;
