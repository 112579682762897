export enum ContractDeclineReasonId {
  Applicant = 3,
  EducationalOrganization = 2,
}

export const contractDeclineReasonOptions = [
  {
    label: 'Все',
    value: null,
  },
  {
    label: 'Заявитель',
    value: ContractDeclineReasonId.Applicant,
  },
  {
    label: 'Образовательная организация',
    value: ContractDeclineReasonId.EducationalOrganization,
  },
];
