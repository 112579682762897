import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import FieldAsyncSelect from './async-select';
import { SearchKeyContext } from '../contexts/key-context';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { PlaceServiceData } from '../../../../types/service';

type Props = {
  values: SearchRequestsInitialFormData;
  isAdmin: boolean;
  label: string;
  placeholder: string;
};

const FieldPlaceService: React.FC<Props> = ({ values, isAdmin, label, placeholder }) => {
  const [placeServiceData, setPlaceServiceData] = useState<PlaceServiceData[]>([]);

  const { placeServiceKey } = useContext(SearchKeyContext);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    const fetchPlaceService = async (serviceId: number) => {
      setPlaceServiceData(await serviceTemplateApi.getPlaceServices({ serviceId }));
    };
    if (values.serviceId) {
      fetchPlaceService(values.serviceId);
    } else {
      setPlaceServiceData([]);
    }
  }, [values.serviceId]);

  const onChange = () => {};

  return !hasAccessObjectAny(userProfile, [accessObject.RequestVA], accessAction.View) || isAdmin ? (
    <>
      <Push size={16} />
      <FieldAsyncSelect
        key={placeServiceKey}
        disabled={!values.organizationId}
        onChange={onChange}
        nameFieldId="placeServiceId"
        nameFieldName="placeServiceName"
        label={label}
        placeholder={placeholder}
        option={placeServiceData?.map((i) => {
          return { value: i.id, label: i.fullAddress };
        })}
        loadOptions={async (query) => {
          return await lookupApi.getPlaceService(
            query,
            values.vedomstvoId,
            values.organizationId,
            values.educationTypeId
          );
        }}
      />
    </>
  ) : null;
};

export default FieldPlaceService;
