import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../../../components/fields/field';
import SimpleInput from '../../../../../../components/fields/simple-input';
import lookupApi from '../../../../../../lib/api/lookup';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { Learner } from '../../../../../../types/learners';
import { DocumentTypeEnum } from '../../../../../../mock-data/type-document';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';

type Props = FieldProps<string> & {
  index: number;
};

export const DocIssuer = ({ name, value, editMode, index }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const isEgrZags = values.pupilDocument[index]?.documentTypeId === DocumentTypeEnum.BirthRecord;
  const label = isEgrZags ? 'Кем внесена запись' : 'Кем выдан документ';

  return isEgrZags ? (
    <Field editMode={editMode} label={label} required={isEgrZags} value={value}>
      <FormikFormGroup showErrorImmediately name={name} label="">
        <FormikSelect
          name={name}
          size="small"
          isSearchable
          menuPlacement="top"
          placeholder="Начните вводить..."
          selectedValue={(option: SelectOptionType) => {
            if (option) {
              setFieldValue(name, option.label);
            }
          }}
          defaultValue={value ? { value: null, label: value } : null}
          loadOptions={async (query) => await lookupApi.getRegistryOffice(query)}
          options={[]}
        />
      </FormikFormGroup>
    </Field>
  ) : (
    <SimpleInput name={name} label={label} value={value} editMode={editMode} showErrorImmediately />
  );
};
