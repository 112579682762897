import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, Infobox, Panel, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../../components/header/page-heading';
import { RequestData, TrainingGroupRequestData } from '../../../../types/requests';
import { bookingApi } from '../../../../lib/api/booking';
import { EducationTypeEnum } from '../../../../types/education-type';
import requestsApi from '../../../../lib/api/requests';
import { formatDate } from '../../../../lib/utils/date';
import { routes } from '../../../../config/constants';
import FIO from '../../components/fields/fio';
import BirthDate from '../../components/fields/birthdate';
import Gender from '../../../../components/fields/gender';
import Document from '../../components/fields/document';
import DocumentNumber from '../../components/fields/document-number';
import Issued from '../../components/fields/issued';
import Snils from '../../components/fields/snils';
import MoscowRegistration from '../../components/fields/moscow-registration';
import Phone from '../../../../components/fields/phone';
import Email from '../../../../components/fields/email';
import {
  birthRecordIssued,
  dateOfBirth,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  middleName,
  nullableDate,
  nullableEmail,
  phone,
  scheduleOfTimetable,
  serviceId,
  sexId,
  snils,
  stringRequired,
} from '../../../../lib/utils/validation';
import ServiceDonm from '../../components/fields/service-donm';
import AdmissionPlanService from '../../components/fields/admission-plan-service';
import { generateLink, getServiceEditLink } from '../../../../lib/utils';
import WrapperFields from '../../components/fields/wrapper-fields';
import DependentDocument from '../../components/fields/dependent-document';
import { RequestContext } from '../../../../lib/utils/requests';
import history from '../../../../history';
import Education from '../association';
import WrapperFieldsDocument from '../../components/fields/wrapper-fields-document';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import SavePanel from '../../../../components/save-panel';

type Props = {
  request?: RequestData;
  serviceInfoReadOnly?: boolean;
};

const RequestProfCreate: React.FC<Props> = ({ request, serviceInfoReadOnly }) => {
  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const { admin } = useContext(RequestContext);

  const initialValue = useMemo(() => {
    if (request) {
      const item = { ...request, trainingGroup: request.trainingGroup || ({} as TrainingGroupRequestData) };
      if (!item.applicant?.documentTypeId) {
        item.applicant = {
          ...item.applicant,
          documentTypeId: 2,
          documentTypeName: 'Паспорт гражданина РФ',
        };
      }
      if (item.child && item.child.sexId === undefined) {
        item.child.sexId = 1;
      }
      return item;
    }
    return {
      child: {
        sexId: 1,
        sexName: 'Мужской',
        documentTypeId: 1,
        documentTypeName: 'Свидетельство о рождении',
      },
      applicant: {
        documentTypeId: 2,
        documentTypeName: 'Паспорт гражданина РФ',
      },
    } as RequestData;
  }, [request]);

  const submitForm = useCallback(
    async (values: RequestData) => {
      const fetch = async () => {
        setLoadBtn(true);
        try {
          const booking = request?.trainingGroup?.bookingGuid
            ? { bookingId: request.trainingGroup.bookingGuid, slotId: request.trainingGroup.slotId }
            : await bookingApi.preBookingByscheduleOfTimetable(values.trainingGroup?.scheduleOfTimetableId);
          if (booking) {
            values.trainingGroup.bookingGuid = booking.bookingId;
            values.trainingGroup.slotId = booking.slotId;
            values.educationTypeId = EducationTypeEnum.ProfessionalEducation;
            values.child.isChild = true;
            values.child.educationTypeId = EducationTypeEnum.ProfessionalEducation;
            values.applicant.isChild = false;
            values.applicant.educationTypeId = EducationTypeEnum.ProfessionalEducation;

            const requestId = await requestsApi.createRequest(values);

            history.push(generateLink(routes.request, { id: requestId }));
          }
        } catch {
        } finally {
          setLoadBtn(false);
        }
      };
      fetch();
    },
    [request]
  );

  const back = () => history.push(routes.requests);

  const dataParams = useParams<Record<string, string | undefined>>();
  const hasEdTypeOrPupilId = 'id' in dataParams || 'pupilId' in dataParams;

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(new Date())}
              <Push size={20} orientation="horizontal" />
              Источник: Оператор
            </div>
          </>
        }
        controlsTopRight={
          <ButtonGroup>
            {serviceInfoReadOnly ? (
              <Button
                label="Вернуться к ОП"
                handleClick={() => {
                  window.open(
                    getServiceEditLink(
                      request?.educationTypeId ?? EducationTypeEnum.ProfessionalEducation,
                      request?.trainingGroup.serviceId
                    ),
                    '_blank'
                  );
                }}
                border
                size="small"
              />
            ) : null}
          </ButtonGroup>
        }
        buttonBack
        buttonBackFn={back}
        title="Новое заявление"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Новое заявление' },
        ]}
      />

      <Formik
        validateOnMount
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValue}
        validationSchema={getValidationSchema}
      >
        {(formikProps: FormikProps<RequestData>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />
              <Infobox
                fullWidth
                text="Проверка в реестре контингента не проводилась. Сведения будут проверены, результаты проверки
                          отображены после первого сохранения."
                color="warning"
              />
              <Panel title={() => 'Информация о ребенке'}>
                <div className="container">
                  <div className="table-data">
                    <DependentDocument>
                      <WrapperFields />
                      <Gender editMode required name="child.sex" />
                      <WrapperFieldsDocument showErrorImmediately={hasEdTypeOrPupilId} />
                      <Snils name="child.snils" disabled={!admin} editMode />
                      <MoscowRegistration name="child.moscowRegistration" editMode />
                    </DependentDocument>
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация о заявителе'}>
                <div className="container">
                  <div className="table-data">
                    <DependentDocument>
                      <FIO label="ФИО заявителя" required editMode parent="applicant" />
                      <BirthDate name="applicant.dateOfBirth" editMode />
                      <Phone
                        name="applicant.phone"
                        editMode
                        required
                        showErrorImmediately={!!initialValue.applicant?.phone}
                      />
                      <Email name="applicant.email" editMode />
                      <Document
                        name="documentType"
                        parent="applicant"
                        editMode
                        dependentFields
                        formType={FormTypeEnum.EditRequestApplicant}
                      />
                      <Issued name="applicant.issued" editMode />
                      <DocumentNumber parent="applicant" editMode calendarPosition="top-end" />
                    </DependentDocument>
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              {!serviceInfoReadOnly ? (
                <Panel title={() => 'Информация об образовательной программе'}>
                  <div className="container">
                    <div className="table-data">
                      <ServiceDonm
                        menuPlacement="top"
                        parent="trainingGroup"
                        name="service"
                        label="Образовательная программа"
                        editMode
                        required
                        educationType={EducationTypeEnum.ProfessionalEducation}
                      />
                      <AdmissionPlanService name="trainingGroup.scheduleOfTimetableId" editMode />
                    </div>
                  </div>
                </Panel>
              ) : (
                request && <Education data={request.trainingGroup} educationType={request.educationTypeId} />
              )}

              <SavePanel
                controls={
                  <>
                    <Button handleClick={back} border primary label="Отмена" />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      handleClick={handleSubmit}
                      load={isSubmitting || loadBtn}
                      disabled={!isValid}
                      primary
                      label="Создать заявление"
                    />
                  </>
                }
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    child: childrenValidationSchema,
    applicant: applicantValidationSchema,
    trainingGroup: trainingGroupSchema,
  });

const trainingGroupSchema = objectYup().shape({
  serviceId,
  scheduleOfTimetableId: scheduleOfTimetable,
});

export const childrenValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  sexId,
  documentTypeId: stringRequired,
  series: documentSeries(true),
  number: documentNumber(true),
  dateOfIssue: dateOfIssue(true),
  snils,
  issued: birthRecordIssued,
});

export const applicantValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  email: nullableEmail,
  documentTypeId: stringYup().nullable(),
  series: documentSeries().nullable(),
  number: documentNumber().nullable(),
  dateOfIssue: dateOfIssue(),
  phone,
  dateOfBirth: nullableDate,
});

export default RequestProfCreate;
