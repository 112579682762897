import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

type DependentServicePropsType = {
  children: any;
};

type DependentServiceValuesType = {
  addressKey: number;
  setAddressKey: Dispatch<SetStateAction<number>>;
  trainingGroupKey: number;
  setTrainingGroupKey: Dispatch<SetStateAction<number>>;
  moduleKey: number;
  setModuleKey: Dispatch<SetStateAction<number>>;
  parallelKey: number;
  setParallelKey: Dispatch<SetStateAction<number>>;
};

export const DependentServiceContext = createContext<DependentServiceValuesType>({} as DependentServiceValuesType);

const DependentService: React.FC<DependentServicePropsType> = ({ children }) => {
  const [addressKey, setAddressKey] = useState<number>(0);
  const [trainingGroupKey, setTrainingGroupKey] = useState<number>(0);
  const [moduleKey, setModuleKey] = useState<number>(0);
  const [parallelKey, setParallelKey] = useState<number>(0);

  const values: DependentServiceValuesType = useMemo(
    () => ({
      addressKey,
      setAddressKey,
      trainingGroupKey,
      setTrainingGroupKey,
      moduleKey,
      setModuleKey,
      parallelKey,
      setParallelKey,
    }),
    [
      addressKey,
      setAddressKey,
      trainingGroupKey,
      setTrainingGroupKey,
      moduleKey,
      setModuleKey,
      parallelKey,
      setParallelKey,
    ]
  );

  return <DependentServiceContext.Provider value={values}>{children}</DependentServiceContext.Provider>;
};

export default DependentService;
