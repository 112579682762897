import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import PageHeading from '../../components/header/page-heading';
import { routes } from '../../config/constants';
import useClearHistoryState from '../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../lib/utils';
import { historyState } from '../../mock-data/history-state';
import { SearchDisciplinesFormData } from '../../types/discipline';
import DisciplineSearch from './search';
import DisciplinesTable from './table';

export const initialFormData: SearchDisciplinesFormData = {
  query: '',
  showArchive: false,
};

const Disciplines = () => {
  const [search, setSearch] = useState<SearchDisciplinesFormData>(
    window.history.state[historyState.search] || initialFormData
  );

  useClearHistoryState();

  const handleSubmitSearch = (values: SearchDisciplinesFormData) => {
    setSearch(values);
    replaceHistoryState({ [historyState.search]: values });
  };

  return (
    <>
      <PageHeading title="Дисциплины" sections={[{ title: 'Главная', link: routes.main }, { title: 'Дисциплины' }]} />
      <Push size={12} />
      <DisciplineSearch submitForm={handleSubmitSearch} initialForm={search} />
      <Push size={12} />
      <DisciplinesTable search={search} />
    </>
  );
};

export default Disciplines;
