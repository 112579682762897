import React, { useContext } from 'react';
import SimpleInput, { SimpleInputProps } from '../../../../components/fields/simple-input';
import { DependentDocumentContext } from './dependent-document';

const Issued: React.FC<SimpleInputProps> = (props) => {
  const { issuedKey } = useContext(DependentDocumentContext);

  return <SimpleInput key={issuedKey} label="Кем выдан" {...props} />;
};

export default Issued;
