import { useEffect, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import { serviceTemplateApi } from '../lib/api/service-template';

export const useGetProgrammLevels = (value: number): SelectOptionType[] => {
  const [programmLevelData, setProgrammLevelData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    if (value) {
      const fetchProgrammLevels = async (classificatorEKUId: number) => {
        setProgrammLevelData([
          { value: null, label: 'Все' },
          ...(await serviceTemplateApi.getProgrammLevels({ classificatorEKUId })),
        ]);
      };

      fetchProgrammLevels(value);
    } else {
      setProgrammLevelData([]);
    }
  }, [value]);

  return programmLevelData;
};
