import React, { useEffect } from 'react';
import { FormikProps } from 'formik';

import { Push } from '@mosru/esz_uikit';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';

import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import { setAsyncValues } from '../../../../../lib/utils/requests';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { Dictionary } from '../../fields/dictionary';
import { Finance } from './index';
import { priceMask } from '../../../../../lib/utils/mask';

type Props = FormikProps<Finance> & {
  isTemplate?: boolean;
  editMode: boolean;
};

const FormFinancing = ({ isTemplate, editMode, ...formikProps }: Props) => {
  const { values, dirty, setFieldValue } = formikProps;

  const showFields = values.typeFinancingId !== TypeFinancingEnum.Free;
  const disabled = values.educationTypeId === EducationTypeEnum.ProfessionalEducation;

  useEffect(() => {
    if (dirty) {
      const fieldsToClean = [
        { key: 'typeValueServiceId' },
        { key: 'typeValueServiceName' },
        { key: 'fullPrice' },
        { key: 'lessonPrice' },
        { key: 'periodPrice' },
        { key: 'subsidiesPercent' },
      ];
      setAsyncValues(fieldsToClean, setFieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.typeFinancingId]);

  return (
    <div className="container">
      <div className="table-data__item table-data__group">
        <div className="table-data__label table-data__label--main">
          Тип финансирования {editMode && <span className="table-data__required" />}
        </div>
        <div className="table-data__body">
          <Dictionary
            name="typeFinancing"
            editMode={editMode && !disabled}
            isNotTableMain
            dictionaryFunc={() => dictionariesApi.getTypeFinancingList(true)}
          />
        </div>
      </div>
      {showFields && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Периодичность оплаты {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <Dictionary
              isNotTableMain
              editMode={editMode}
              name="typeValueService"
              dictionaryFunc={dictionariesApi.getTypeValueServiceList}
            />
          </div>
        </div>
      )}
      {showFields && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Стоимость, руб {isTemplate && editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex items-start">
              {!isTemplate && (
                <>
                  <div className="table-data__group">
                    <div className="table-data__label">
                      Общая {editMode && <span className="table-data__required" />}
                    </div>
                    <div className="table-data__body" style={{ width: 140 }}>
                      {editMode ? (
                        <FormikFormGroup required label="" name="fullPrice">
                          <FormikInput
                            name="fullPrice"
                            size="small"
                            placeholder="0,00"
                            textRight
                            postfix="₽"
                            maxLength={6}
                            mask={priceMask}
                          />
                        </FormikFormGroup>
                      ) : (
                        values.fullPrice || '—'
                      )}
                    </div>
                  </div>
                  <Push size={16} orientation="horizontal" />
                </>
              )}

              <div className="table-data__group">
                {!isTemplate && (
                  <div className="table-data__label">
                    За период
                    {editMode && <span className="table-data__required" />}
                  </div>
                )}
                <div className="table-data__body" style={{ width: 140 }}>
                  {editMode ? (
                    <FormikFormGroup required label="" name="periodPrice">
                      <FormikInput
                        name="periodPrice"
                        size="small"
                        placeholder="0,00"
                        textRight
                        postfix="₽"
                        maxLength={6}
                        mask={priceMask}
                      />
                    </FormikFormGroup>
                  ) : (
                    values.periodPrice || '—'
                  )}
                </div>
              </div>

              {!isTemplate && (
                <>
                  <Push size={16} orientation="horizontal" />
                  <div className="table-data__group">
                    <div className="table-data__label">За занятие</div>
                    <div className="table-data__body" style={{ width: 140 }}>
                      {editMode ? (
                        <FormikFormGroup required label="" name="lessonPrice">
                          <FormikInput
                            textRight
                            postfix="₽"
                            size="small"
                            name="lessonPrice"
                            placeholder="0,00"
                            mask={priceMask}
                          />
                        </FormikFormGroup>
                      ) : (
                        values.lessonPrice || '—'
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormFinancing;
