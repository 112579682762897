import React from 'react';
import { Button, IconInfoboxDanger, Infobox, Push } from '@mosru/esz_uikit';
import { RequestData } from '../../../../types/requests';

type Props = {
  setOpen: (value: boolean) => void;
  disabled: boolean;
  requestData?: RequestData;
};

export const InfoLike = ({ setOpen, disabled, requestData }: Props) => {
  return requestData ? (
    <div className="container">
      {requestData.contingentLinkTypeId >= 3 && requestData.contingentGuid && (
        <>
          <Infobox
            fullWidth
            color="warning"
            text="Обучающийся идентифицирован в Реестре контингента, сведения об общеобразовательной организации отсутствуют. Функционал зачисления доступен"
          />
          <Push size={16} orientation="vertical" />
        </>
      )}

      {!requestData.contingentGuid && (
        <>
          <div className="infobox infobox--danger infobox--border-radius">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <div style={{ minWidth: '28px' }}>
                    <IconInfoboxDanger />
                  </div>
                  <Push size={8} orientation="horizontal" />
                  <div>
                    Функционал зачисления недоступен, так как сведения об обучающемся отсутствуют в Реестре контингента.
                    Вам необходимо проверить эти сведения и идентифицировать обучающегося.
                  </div>
                </div>
              </div>
              <div className="infobox__control">
                <Button primary label="Проверить сведения" disabled={disabled} handleClick={() => setOpen(true)} />
              </div>
            </div>
          </div>
          <Push size={16} />
        </>
      )}
    </div>
  ) : null;
};
