import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../types/state';
import { authApi } from '../../lib/api';
import { AuthOrganizationsType } from '../../types/auth';

export const moduleName = 'aupdOrganizations';
export const SET_AUPD_ORGANIZATIONS = `${moduleName}/SET_AUPD_ORGANIZATIONS`;

// Reducer
export default function reducer(state: AuthOrganizationsType[] = [], action: AnyAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_AUPD_ORGANIZATIONS:
      return payload.data;

    default:
      return state;
  }
}

// AC
export const setAupdOrganizations = (data: AuthOrganizationsType[]) => ({
  type: SET_AUPD_ORGANIZATIONS,
  payload: {
    data,
  },
});

// Thunks
export const getAupdOrganizations =
  (token: string) => async (dispatch: ThunkDispatch<AppState, AuthOrganizationsType[], AnyAction>) => {
    const organizations = await authApi.getOrganizationList(token);
    dispatch(setAupdOrganizations(organizations));
    return organizations;
  };
