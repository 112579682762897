import React, { useContext, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Tooltip, Panel, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ServiceData, ServiceDataInfo } from '../../../../types/service';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../..';
import { ServiceDuration } from '../../components/fields/service-duration';
import NameCodeField from '../../components/fields/name-code';
import OrganizationField from '../../components/fields/organization';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { Dictionary } from '../../components/fields/dictionary';
import { GroupMembersField } from '../../components/fields/group-members';
import {
  altEndValidation,
  altStartValidation,
  durationOfTrainingTest,
  hoursPerWeek,
} from '../../../../lib/utils/validation';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { setGenderStartEndToBack } from '../../utils';
import { hasGeneralAccess } from '../../../../lib/utils';
import { generalAccess } from '../../../../types/authorization-data';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const Info: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit, adminEdit } = useContext(ServiceContext);

  const initialData = useMemo(() => {
    const initial = serviceData?.info as ServiceDataInfo;
    return initial ?? ({} as ServiceDataInfo);
  }, [serviceData]);
  const initialErrors = useInitialErrors(initialData, getInfoDoValidationSchema(serviceData));
  const [editMode, setEditMode] = useState(!setEditModeParent);

  const checkEditable =
    !editMode && setEditModeParent && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitForm = async (values: ServiceDataInfo) => {
    try {
      if (values?.serviceId) {
        const requestData = {
          ...setGenderStartEndToBack(values),
          durationOfTraining: Number(values.durationOfTraining) || undefined,
          durationOfTrainingDays: Number(values.durationOfTrainingDays) || undefined,
          durationOfTrainingMonths: Number(values.durationOfTrainingMonths) || undefined,
          durationOfTrainingWeeks: Number(values.durationOfTrainingWeeks) || undefined,
        };

        await serviceTemplateApi.updateService(serviceData.id, requestData);
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        updateService();
      }
    } catch {}
  };

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialData}
      validationSchema={getInfoDoValidationSchema(serviceData)}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceDataInfo>) => {
        const { values, isSubmitting, isValid, resetForm, setFieldValue } = formikProps;

        return (
          <form>
            <Push size={12} />
            <Panel
              title={() => <>Сведения о детском объединении</>}
              headingControl={() => {
                return checkEditable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent && setEditModeParent('info');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <OrganizationField
                  editMode={editMode}
                  name="organization"
                  required
                  disabled={!hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV)}
                  label="Образовательная организация"
                />
                <NameCodeField required label="Наименование объединения" editMode={editMode} name="name" />
                <ClassificatorNameField
                  editMode={editMode}
                  name="classificator"
                  modalTitle="Реестр базовых направлений"
                  disabled={values.hasMegaRelations}
                />
                <LessonLevelField
                  label="Уровень программы"
                  editMode={editMode}
                  name="programmLevel"
                  value={values.programmLevelName}
                  disabledPlaceholder="Сначала выберите вид деятельности"
                  placeholder="Выберите..."
                  required
                />

                <ServiceDuration editMode={editMode} />
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Занятия</div>
                  <div className="table-data__body">
                    <div className="flex items-start">
                      <div className="table-data__group">
                        <div className="table-data__label">
                          Форма {editMode && <span className="table-data__required" />}
                        </div>
                        <div className="table-data__body" style={{ width: 200 }}>
                          <Dictionary
                            name="serviceForm"
                            editMode={editMode}
                            isNotTableMain
                            dictionaryFunc={dictionariesApi.getServiceForms}
                          />
                        </div>
                      </div>
                      <Push size={16} orientation="horizontal" />
                      <div className="table-data__group">
                        <div className="table-data__label">
                          Вид посещения {editMode && <span className="table-data__required" />}
                        </div>
                        <div className="table-data__body" style={{ width: 200 }}>
                          <Dictionary
                            disabled={values.hasMegaRelations}
                            name="typeOfAttendance"
                            editMode={editMode}
                            isNotTableMain
                            dictionaryFunc={dictionariesApi.getVisitTypes}
                            onChange={(value) => {
                              if (value === 1) {
                                setFieldValue('rulesOfAttendance', '');
                              }
                            }}
                          />
                        </div>
                      </div>
                      <Push size={16} orientation="horizontal" />
                      <div className="table-data__group">
                        <div className="table-data__label">Часов в неделю</div>
                        <div className="table-data__body" style={{ width: 80 }}>
                          {editMode ? (
                            <FormikFormGroup required label="" name="hoursPerWeek">
                              <FormikInput
                                name="hoursPerWeek"
                                size="small"
                                placeholder="0"
                                maxLength={2}
                                maxValue={99}
                                number
                                disabled={values.hasMegaRelations}
                              />
                            </FormikFormGroup>
                          ) : (
                            values.hoursPerWeek || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <GroupMembersField editMode={editMode} disabled={values.hasMegaRelations && !adminEdit} />

                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Описание объединения {editMode && <span className="table-data__required" />}
                  </div>
                  <div className="table-data__body">
                    {editMode ? (
                      <FormikFormGroup name="programmService" label="" horizontalLabelStart required>
                        <FormikTextarea name="programmService" rows={2} placeholder="Введите..." maxLength={1000} />
                      </FormikFormGroup>
                    ) : (
                      values.programmService
                    )}
                  </div>
                </div>
                {values.typeOfAttendanceId === 2 ? (
                  <SimpleTextArea
                    name="rulesOfAttendance"
                    label={
                      <div className="icon-group">
                        <div className="icon-group__text">Правила посещения занятий</div>
                        <div className="icon-group__icon">
                          <Tooltip
                            component={() => <IconInfo />}
                            position="top"
                            text="Здесь вы можете указать информацию о правилах посещения онлайн-кружка: на какой платформе проводятся занятия, нужно ли дополнительное оборудование, будут ли записи занятий и многое другое. Эта информация передается на Mos.ru"
                          />
                        </div>
                      </div>
                    }
                    editMode={editMode}
                    placeholder="Введите..."
                    maxLength={1000}
                  />
                ) : null}
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                        onClick={() => {
                          submitForm(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Info;

export const getInfoDoValidationSchema = (serviceData?: ServiceData) =>
  objectYup().shape({
    name: stringYup().required('Введите наименование').nullable(),
    classificatorEKUId: stringYup().nullable().required('Выберите вид деятельности'),
    programmLevelId: stringYup().nullable().required('Выберите уровень'),
    altStart: altStartValidation(serviceData),
    altEnd: altEndValidation(serviceData),
    programmService: stringYup().nullable().required('Введите описание детского объединения'),
    durationOfTraining: durationOfTrainingTest(9, serviceData),
    durationOfTrainingMonths: durationOfTrainingTest(12, serviceData),
    durationOfTrainingWeeks: durationOfTrainingTest(54, serviceData),
    durationOfTrainingDays: durationOfTrainingTest(54, serviceData),
    hoursPerWeek: hoursPerWeek(serviceData),
  });
