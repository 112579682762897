import React from 'react';
import { ReactComponent as IconAlert } from '../../assets/images/events/alert.svg';
import { ReactComponent as IconCheckmark } from '../../assets/images/events/checkmark.svg';
import { ReactComponent as IconClock } from '../../assets/images/events/clock.svg';
import { ReactComponent as IconClose } from '../../assets/images/events/close.svg';
import { ReactComponent as IconFlag } from '../../assets/images/events/flag.svg';
import { ReactComponent as IconPeople } from '../../assets/images/events/people.svg';
import { ReactComponent as IconQuestion } from '../../assets/images/events/question.svg';
import { routes } from '../../config/constants';
import { adminAccess, generateLink } from '../../lib/utils';
import { EntityTypeEnum } from '../../mock-data/entity-type-enum';
import { eventApi } from '../../store/event';
import { store } from '../../store/store';
import { AuthorizationData } from '../../types/authorization-data';
import { EducationTypeEnum } from '../../types/education-type';
import { EventData, EventParamsFormData } from '../../types/events';
import history from '../../history';
import { EventUrl } from '../../mock-data/event';

export const allTypes = {
  label: 'Все типы уведомлений',
  value: 0,
};

export enum tabList {
  All = 'All',
  IsRead = 'IsRead',
  IsNew = 'IsNew',
}

export const initialEventParams: EventParamsFormData = {
  dates: null,
  selectedTypeId: allTypes.value,
};

export const getRegistryPage = (item: EventData, userProfile: AuthorizationData): string | null => {
  switch (item.entityTypeId) {
    case EntityTypeEnum.Request:
      return routes.requests;
    case EntityTypeEnum.ServiceClass:
      return routes.serviceClasses;
    case EntityTypeEnum.Service:
      return getServiceLink(item.educationTypeId, userProfile);
    default:
      return null;
  }
};

export const handleRead = (item: EventData, userProfile: AuthorizationData) => {
  if (!item.isRead) {
    try {
      store.dispatch(eventApi.endpoints.readEvent.initiate(item.id));
    } catch (e) {}
  }

  const route = getRegistryPage(item, userProfile);

  if (route) {
    history.push(
      generateLink(
        route,
        {},
        {
          [EventUrl.EventId]: item.id,
          [EventUrl.EventName]: item.eventText || '',
        }
      )
    );
  }
};

export const getServiceLink = (educationTypeId: number, userProfile: AuthorizationData): string => {
  type LinkByEducationRegistryType = {
    [index: number]: string;
  };
  // Когда у пользователя больше 1 роли или это админ, мы делаем редирект на общий роут.
  const checkAccessAmount = userProfile.roles.length > 1 || adminAccess('edit-or-view', userProfile);

  const childrenEducationLink = checkAccessAmount ? routes.programs : routes.registerChildEducations;
  const dkgmOrDsitEducationLink = routes.services;

  const linkByEducationRegistryType: LinkByEducationRegistryType = {
    [EducationTypeEnum.ChildrenEducation]: childrenEducationLink,
    [EducationTypeEnum.DayCareCentersEducation]: checkAccessAmount ? routes.programs : routes.dayCarePrograms,
    [EducationTypeEnum.VirtualAssistantEducation]: checkAccessAmount ? routes.programs : routes.services,
    [EducationTypeEnum.ChildrenNonDogmEducation]: childrenEducationLink,
    [EducationTypeEnum.ArtHouseEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.SportEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.ProfessionalEducation]: routes.registerEducationPrograms,
  };

  return linkByEducationRegistryType[educationTypeId];
};

export const getEventColor = (eventTypeId: number): string => {
  const danger = [1, 3, 4, 5, 6, 39, 40, 41, 44, 33, 34, 35, 36, 37, 38];
  const primary = [7, 9, 10, 11, 12, 13, 15, 16, 17, 18, 21, 22, 23, 26];
  const warning = [19, 27, 28, 29, 30, 31, 32];
  const success = 20;

  return danger.includes(eventTypeId)
    ? 'event-item__media--danger'
    : primary.includes(eventTypeId)
    ? 'event-item__media--primary'
    : warning.includes(eventTypeId)
    ? 'event-item__media--warning'
    : success === eventTypeId
    ? 'event-item__media--success'
    : '';
};

export const getEventIcon = (eventTypeId: number): JSX.Element | null => {
  const alert = [1, 3, 4, 5, 6, 7, 9, 10, 11, 12];
  const flag = [13, 15, 16, 17, 18];
  const clock = [21, 22, 23, 26];
  const close = [27, 28, 29, 30, 31, 32];
  const people = [39, 40, 41, 44, 33, 34, 35, 36, 37, 38];
  const checkmark = 20;
  const question = 19;

  return alert.includes(eventTypeId) ? (
    <IconAlert />
  ) : flag.includes(eventTypeId) ? (
    <IconFlag />
  ) : clock.includes(eventTypeId) ? (
    <IconClock />
  ) : close.includes(eventTypeId) ? (
    <IconClose />
  ) : people.includes(eventTypeId) ? (
    <IconPeople />
  ) : checkmark === eventTypeId ? (
    <IconCheckmark />
  ) : question === eventTypeId ? (
    <IconQuestion />
  ) : null;
};
