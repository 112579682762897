import React, { useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { object as objectYup, string as stringYup } from 'yup';
import { useLocation } from 'react-router-dom';
import { Button, Push } from '@mosru/esz_uikit';
import { LoginData, initialLoginData } from '../../../../types/auth';
import FormikInput from '../../../../components/formik/formik-input';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import history from '../../../../history';
import { loginDebugMode, routes } from '../../../../config/constants';
import FormikCheckboxGroup from '../../../../components/formik/formik-checkbox-group';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import { authApi } from '../../../../lib/api';
import { onSignIn } from '../../../../redux/thunks/auth';

type Props = {
  onSuccess?: () => void;
  setErrorBox: (text: string, isShow: boolean) => void;
};

const Signin = ({ setErrorBox }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialErrors = useInitialErrors(initialLoginData, getValidationSchema());

  const signInHandler = useCallback(
    async (values: LoginData, { setErrors }) => {
      try {
        const response = await authApi.signIn(values);
        if (response.isPasswordExpired) {
          history.push({
            pathname: routes.changePassword,
            search: location.search,
            state: values,
          });
        } else if (response.isLoggedIn) {
          await dispatch(onSignIn(response.token));
        } else {
          setErrorBox(response.message, true);
          setErrors({ login: ' ', password: ' ' });
        }
      } catch (err) {
        setErrorBox('Произошла ошибка. Повторите попытку позже.', true);
      }
    },
    [dispatch, location.search, setErrorBox]
  );

  return (
    <Formik
      onSubmit={signInHandler}
      enableReinitialize
      initialValues={initialLoginData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<LoginData>) => {
        const { handleSubmit, values, isSubmitting, isValid } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormikFormGroup name="login" label="Логин">
              <FormikInput name="login" value={values.login} placeholder="Введите логин..." size="medium" />
            </FormikFormGroup>

            <Push size={12} />
            <FormikFormGroup name="password" label="Пароль">
              <FormikInput
                placeholder="Введите пароль..."
                value={values.password}
                name="password"
                password
                size="medium"
              />
            </FormikFormGroup>

            <Push size={20} />
            <FormikCheckboxGroup label="Запомнить меня" name="remember" size="small">
              <FormikCheckbox name="remember" />
            </FormikCheckboxGroup>

            <Push size={20} />
            <Button submit label="Войти в систему" primary load={isSubmitting} disabled={!isValid} fullWidth />
          </form>
        );
      }}
    </Formik>
  );
};

export default Signin;

const getValidationSchema = () =>
  objectYup().shape({
    login: stringYup().nullable().required('Введите логин'),
    password:
      loginDebugMode === 'true' ? stringYup().nullable() : stringYup().nullable().min(6, '').required('Введите пароль'),
  });
