import React from 'react';
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import { InputProps } from '@mosru/esz_uikit';
import Field, { FieldProps } from './field';
import FormikInput from '../formik/formik-input';
import FormikFormGroup from '../formik/formik-form-group';

export type SimpleInputProps = FieldProps<string> & { showErrorImmediately?: boolean } & Pick<InputProps, 'onKeyPress'>;

const SimpleInput = ({
  label,
  defaultValue = '—',
  name,
  editMode,
  required,
  maxLength,
  value,
  showErrorImmediately = false,
  placeholder,
  disabled,
  showRequiredIcon,
  onKeyPress,
}: SimpleInputProps) => {
  return (
    <FormikField name={name}>
      {({ field }: FormikFieldProps) => {
        const v = value ?? field.value;
        return (
          <Field
            label={label}
            editMode={editMode}
            required={required}
            value={v}
            defaultValue={defaultValue}
            showRequiredIcon={showRequiredIcon}
          >
            <FormikFormGroup label="" name={name} showErrorImmediately={showErrorImmediately}>
              <FormikInput
                name={name}
                placeholder={placeholder || 'Введите...'}
                size="small"
                maxLength={maxLength}
                disabled={disabled}
                onKeyPress={onKeyPress}
              />
            </FormikFormGroup>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default SimpleInput;
