import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, Panel, Loader, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { requestDeclineReasonId } from '../../../lib/utils/validation';
import DeclineReason from '../components/fields/decline-reason';
import { RequestDeclineData } from '../../../types/requests';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { submitRefusal } from '../utils';
import { RefuseModal } from '../components/modals';
import SavePanel from '../../../components/save-panel';

type Props = {
  decline: RequestDeclineData;
  disabled?: boolean;
  serviceId: number;
  requestId: number;
  editMode: boolean;
  setEditMode: () => void;
  updateRequest: () => void;
};

const ActionsFormRefusal: React.FC<Props> = ({
  setEditMode,
  updateRequest,
  decline,
  disabled,
  serviceId,
  requestId,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formKey, setFormKey] = useState<number>(0);

  const submitForm = useCallback(
    async (values: RequestDeclineData) => {
      await submitRefusal(values, requestId, setLoading, setShowModal, setEditMode, updateRequest);
    },
    [requestId, updateRequest, setEditMode]
  );
  const initialErrors = useInitialErrors(decline, getValidationSchema());

  return (
    <Formik
      key={formKey}
      enableReinitialize
      onSubmit={submitForm}
      initialValues={decline}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<RequestDeclineData>) => {
        const { handleSubmit, isSubmitting, isValid } = formikProps;

        return loading ? (
          <Panel>
            <div className="loader-container">
              <Loader roller small />
            </div>
          </Panel>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="table-data">
                <DeclineReason
                  required
                  name="requestDeclineReason"
                  editMode={!disabled}
                  serviceId={serviceId}
                  requestId={requestId}
                  defaultValue={
                    decline.requestDeclineReasonName && decline.requestDeclineReasonId
                      ? { label: decline.requestDeclineReasonName, value: decline.requestDeclineReasonId }
                      : undefined
                  }
                />
                <SimpleTextArea
                  name="declineComment"
                  placeholder="Введите..."
                  editMode={!disabled}
                  label="Комментарий"
                />
              </div>
            </div>
            {!disabled && (
              <SavePanel
                controls={
                  <>
                    <Button label="Сбросить" primary border handleClick={() => setFormKey(Math.random())} />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      primary
                      label="Отправить отказ"
                      disabled={!isValid || isSubmitting}
                      handleClick={() => setShowModal(true)}
                    />
                  </>
                }
              />
            )}
            <RefuseModal showModal={showModal} setShowModal={setShowModal} handleSubmit={handleSubmit} />
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormRefusal;

const getValidationSchema = () =>
  objectYup().shape({
    requestDeclineReasonId,
  });
