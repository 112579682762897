import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { SelectOptionType, Tooltip } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceClassContext } from '../../service-class';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { DependentServiceContext } from './dependent-service';
import { serviceIdField } from './service';

type AddressFieldProps = FieldProps<string> & Record<string, any>;

export const addressFieldName = 'address';
export const addressFieldId = 'addressId';

const AddressField = ({
  label = 'Адрес',
  name,
  editMode,
  required,
  defaultValue = '—',
  disabled,
}: AddressFieldProps) => {
  const { serviceClassData } = useContext(ServiceClassContext);
  const { addressKey } = useContext(DependentServiceContext);

  const fieldName = `${name}`;
  const fieldIdName = `${name}Id`;
  const [serviceField] = useField<number>(serviceIdField);

  const [addressData, setAddressData] = useState<SelectOptionType[]>([]);

  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldId] = useField<number>(fieldIdName);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      setAddressData(await serviceTemplateApi.getPlaceServiceAddresses({ serviceId }));
    };

    if (serviceField.value) {
      fetchTrainGroup(serviceField.value);
    } else {
      setAddressData([]);
    }
  }, [serviceField.value]);

  const iconInfo = (
    <span style={{ marginRight: '10px' }}>
      <Tooltip
        component={() => <IconInfo />}
        position="bottom"
        text={
          <div style={{ textAlign: 'center', width: '200px', fontSize: '12px', wordBreak: 'break-word' }}>
            Адрес более не используется организацией в качестве места предоставления услуги
          </div>
        }
      />
    </span>
  );

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={fieldLabel.value}
      defaultValue={defaultValue}
      iconForValue={serviceClassData.isAddressNotFoundInService ? iconInfo : null}
    >
      <FormikSelect
        key={addressKey}
        name={fieldIdName}
        size="small"
        isSearchable
        options={addressData}
        defaultValue={fieldId.value ? { value: fieldId.value, label: fieldLabel.value } : null}
        selectedValue={(selected: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : undefined);
        }}
        placeholder="Выберите..."
        disabled={disabled}
      />
    </Field>
  );
};

export default AddressField;
