import React, { useMemo } from 'react';
import { Modal } from '@mosru/esz_uikit';
import Content from './content';
import { TreeModalPopup } from '../../types/tree-popup';

export const TreeModalContext = React.createContext<TreeModalPopup>({} as TreeModalPopup);

const TreeModal: React.FC<TreeModalPopup> = (props) => {
  const { closePopup, open } = props;

  const close = (value: boolean) => {
    closePopup(value);
  };

  const treeModalData: TreeModalPopup = useMemo(() => {
    return { ...props };
  }, [props]);

  return (
    <TreeModalContext.Provider value={treeModalData}>
      <Modal show={open} onClose={close}>
        <Content />
      </Modal>
    </TreeModalContext.Provider>
  );
};

export default TreeModal;
