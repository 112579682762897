import React, { ChangeEvent } from 'react';
import { useField } from 'formik';
import { Input, InputProps } from '@mosru/esz_uikit';

export type FormicInputProps = InputProps & {
  name: string;
  showErrorImmediately?: boolean;
  maxLength?: number | undefined;
};

const FormikInput: React.FC<Omit<FormicInputProps, 'onChange'>> = ({ showErrorImmediately, ...props }) => {
  const { name, error: errorProp, setValue } = props;
  const [field, meta, helper] = useField(name);

  const error = showErrorImmediately ? meta.error : meta.touched && meta.error && meta.initialValue !== field.value;
  const errorText = typeof errorProp === 'undefined' ? error && (meta.error as string) : errorProp;
  const handleSetValue = (value: string | number | null) => {
    helper.setTouched(true, true);
    helper.setValue(value);
    if (setValue && typeof setValue === 'function') {
      setValue(value);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    helper.setTouched(true);
    field.onChange(event);
  };

  return <Input {...field} {...props} setValue={handleSetValue} onChange={handleChange} error={errorText || ''} />;
};

export default FormikInput;
