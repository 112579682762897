import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, notify, Panel, Loader, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import Phone from '../../../components/fields/phone';
import { PersonRequestData } from '../../../types/requests';
import Email from '../../../components/fields/email';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import Issued from '../components/fields/issued';
import { applicantValidationSchema } from './create';
import requestsApi from '../../../lib/api/requests';
import DependentDocument from '../components/fields/dependent-document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';

type Props = {
  requestApplicant: PersonRequestData;
  setEditModeParent: (value: string | null) => void;
  editable: boolean;
  updateRequest: () => void;
};

const ApplicantForm: React.FC<Props> = ({ updateRequest, requestApplicant, setEditModeParent, editable }) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialErrors = useInitialErrors(requestApplicant, applicantValidationSchema);
  const [formKey, setFormKey] = useState(Math.random());

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      setLoading(true);
      try {
        values.isChild = false;
        await requestsApi.saveRequestPerson(values);
        setEditModeParent(null);
        updateRequest();
      } catch (e) {
        setFormKey(Math.random());
        notify.danger({
          data: { label: 'Не удалось сохранить заявителя', icon: true },
        });
      } finally {
        setLoading(false);
      }
    },
    [setEditModeParent, updateRequest]
  );

  return loading ? (
    <Panel>
      <div className="loader-container">
        <Loader roller small />
      </div>
    </Panel>
  ) : (
    <Formik
      key={formKey}
      validateOnMount
      enableReinitialize
      onSubmit={submitForm}
      initialErrors={initialErrors}
      initialValues={requestApplicant}
      validationSchema={applicantValidationSchema}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, isSubmitting, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о заявителе'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('applicant');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <DependentDocument>
                    <FIO showErrorImmediately label="ФИО заявителя" required editMode={editMode} />
                    <BirthDate name="dateOfBirth" editMode={editMode} />
                    <Phone showErrorImmediately name="phone" required editMode={editMode} />
                    <Email name="email" editMode={editMode} />
                    <Document
                      name="documentType"
                      editMode={editMode}
                      dependentFields
                      formType={FormTypeEnum.EditRequestApplicant}
                    />
                    <Issued name="issued" editMode={editMode} />
                    <DocumentNumber editMode={editMode} calendarPosition="top-end" />
                  </DependentDocument>
                </div>
              </div>
            </Panel>

            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                          setFormKey(Math.random());
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={() => {
                          handleSubmit();
                          setEditModeParent(null);
                          setEditMode(false);
                        }}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ApplicantForm;
