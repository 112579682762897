import React, { useContext } from 'react';
import { Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  isArtHouseSportEducation: boolean;
  label: string;
  placeholder: string;
};

const FieldTestDate: React.FC<Props> = ({ isArtHouseSportEducation, label, placeholder }) => {
  const { testDateKey } = useContext(SearchKeyContext);

  return isArtHouseSportEducation ? (
    <>
      <Push size={16} />
      <FormikFormGroup name="testDate" label={label}>
        <FormikDatePicker key={testDateKey} rangePicker placeholder={placeholder} size="small" name="testDate" />
      </FormikFormGroup>
    </>
  ) : null;
};

export default FieldTestDate;
