import React, { useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import ServiceClassGroup from '../components/panels/group';
import ServiceClassSchedule from '../components/panels/schedule';
import ServiceClassTeacher from '../components/teacher';
import ServiceClassTable from './group-composition';

const ServiceClassDonmDetails: React.FC = () => {
  const [editModeParent, setEditModeParent] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <>
      <Push size={12} />
      {(!editModeParent || editModeParent === 'group') && (
        <ServiceClassGroup setEditModeParent={setEditModeParent} setEditMode={setEditMode} editMode={editMode} />
      )}
      <Push size={12} />
      {(!editModeParent || editModeParent === 'schedule') && (
        <ServiceClassSchedule setEditModeParent={setEditModeParent} editMode={editMode} setEditMode={setEditMode} />
      )}
      <Push size={12} />
      {(!editModeParent || editModeParent === 'teacher') && (
        <ServiceClassTeacher setEditModeParent={setEditModeParent} editMode={editMode} setEditMode={setEditMode} />
      )}
      <Push size={12} />
      {!editModeParent && <ServiceClassTable />}
    </>
  );
};

export default ServiceClassDonmDetails;
