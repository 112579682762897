import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
// @ts-ignore
import Files from 'react-files';
import { Button, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { ServiceDataInfo, ServiceFileTextType } from '../../../../types/service';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ReactComponent as IconAttach } from '../../../../assets/images/icons/attach.svg';
import { ReactComponent as IconClear } from '../../../../assets/images/icons/clear.svg';
import { ReactFilesError, ReactFilesFile } from '../../../../types/files';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { blobToBase64, getFileNameFromDisposition } from '../../../../lib/utils/reports';

type Props = {
  editMode: boolean;
  parent?: string;
  disabled?: boolean;
  programmFile: ServiceFileTextType;
  setProgrammFile: React.Dispatch<React.SetStateAction<ServiceFileTextType>>;
};

export const ProgrammService: React.FC<Props> = ({ editMode, programmFile, setProgrammFile, parent, disabled }) => {
  const { values } = useFormikContext<ServiceDataInfo>();
  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);
  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;
      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  const downloadFile = async (id: string) => {
    const file = await serviceTemplateApi.getServiceFile(getFieldValue('serviceId'), id);
    if (file) {
      const downloadLink = document.createElement('a');
      downloadLink.href = (await blobToBase64(file.blob)) as string;
      downloadLink.download = getFileNameFromDisposition(file.contentDisposition);
      downloadLink.click();
    }
  };

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Описание услуги {editMode && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <>
            <FormikFormGroup name={getFieldFullName('programmService')} label="" horizontalLabelStart>
              <FormikTextarea
                name={getFieldFullName('programmService')}
                rows={2}
                placeholder="Введите..."
                disabled={disabled}
              />
            </FormikFormGroup>
            {typeof programmFile !== 'boolean' && programmFile !== undefined && (
              <>
                <Push size={8} />
                <div className="icon-group">
                  <div className="icon-group__icon">
                    <IconAttach />
                  </div>
                  <div className="icon-group__text">{programmFile.name}</div>
                  <button
                    disabled={disabled}
                    type="button"
                    className="icon-group__icon"
                    onClick={() => {
                      if (programmFile.id !== '0' && programmFile.id !== '') {
                        setProgrammFile(true);
                      }
                    }}
                  >
                    <IconClear />
                  </button>
                </div>
              </>
            )}

            <Push size={8} />
            <div className="inline-block">
              <Files
                multiple={false}
                clickable={!disabled}
                onChange={async (newFiles: ReactFilesFile[]) => {
                  setProgrammFile(newFiles[0]);
                }}
                onError={(error: ReactFilesError) => {
                  console.error(error);
                }}
              >
                <Button border primary label="Прикрепить файл" disabled={disabled} />
              </Files>
            </div>
          </>
        ) : (
          <>
            {getFieldValue('programmService') ?? '—'}
            {typeof programmFile !== 'boolean' && programmFile !== undefined && (
              <>
                <Push size={8} />
                <div className="icon-group">
                  <div className="icon-group__icon">
                    <IconAttach />
                  </div>
                  <button
                    type="button"
                    className="text-decoration-underline cursor-pointer brand-link"
                    onClick={() => downloadFile(programmFile.id)}
                  >
                    {programmFile.name}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
