import { useFormikContext } from 'formik';
import React from 'react';
import { RadioGroup, Checkbox, Radio, SelectOptionType, Switcher, Push } from '@mosru/esz_uikit';
import FormikCheckboxGroup from '../../../../components/formik/formik-checkbox-group';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikSelect from '../../../../components/formik/formik-select';
import { weekday } from '../../../../components/shedule-modal/helpers';
import {
  daysForTrainingSchedule,
  postfixMatrix,
  repetitionTypes,
  weekNumberInMonth,
} from '../../../../lib/utils/service';
import { TypesOfRepetitionEnum } from '../../../../mock-data/type-of-repetition';
import { ScheduleDataEx } from '../tests';

type Props = {
  editMode: boolean;
  enabled: boolean;
};

const ScheduleRepetition: React.FC<Props> = ({ editMode, enabled }) => {
  const { values, setFieldValue } = useFormikContext<ScheduleDataEx>();
  return (
    <>
      {values.repeat && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex">
              {editMode ? (
                <Switcher fullWidth size="small">
                  {repetitionTypes.map((element) => {
                    return (
                      <button
                        key={element.value}
                        type="button"
                        disabled={!enabled}
                        onClick={() => {
                          setFieldValue('typesOfRepetitionId', element.value);
                        }}
                        className={element.value === values.typesOfRepetitionId ? 'active' : ''}
                      >
                        {element.label}
                      </button>
                    );
                  })}
                </Switcher>
              ) : (
                <div className="flex items-center flex-none table-row-item-height">
                  {repetitionTypes.find((r) => values.typesOfRepetitionId === r.value)?.label ?? '—'}
                </div>
              )}
              <Push size={16} orientation="horizontal" />
            </div>
          </div>
        </div>
      )}

      {values.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatDaily && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Ежедневный повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex">
              {editMode ? (
                <>
                  <RadioGroup
                    name="everyday"
                    labelId="everyday"
                    onChange={() => {
                      setFieldValue('everyday', false);
                    }}
                    label="Каждый"
                    checked={!values.everyday}
                    disabled={!enabled}
                  >
                    {(props: any) => <Radio {...props} labelId="everyday" checked={!values.everyday} />}
                  </RadioGroup>
                  <Push size={16} orientation="horizontal" />
                  <div style={{ width: 72 }}>
                    <FormikFormGroup required label="" name="repeatNumber1">
                      <FormikInput name="repeatNumber1" size="small" number disabled={!enabled} />
                    </FormikFormGroup>
                  </div>
                  <div className="flex items-center flex-none table-row-item-height">
                    <Push size={8} orientation="horizontal" />
                    день
                    <Push size={8} orientation="horizontal" />
                  </div>
                  <Push size={32} orientation="horizontal" />
                  <RadioGroup
                    name="everyday"
                    labelId="everyday"
                    onChange={() => {
                      setFieldValue('everyday', true);
                    }}
                    label="Каждый рабочий день"
                    checked={values.everyday ?? false}
                    disabled={!enabled}
                  >
                    {(props: any) => (
                      <Radio {...props} labelId="everyday" checked={values.everyday ?? false} disabled={!enabled} />
                    )}
                  </RadioGroup>
                </>
              ) : (
                <div className="flex items-center flex-none table-row-item-height">
                  {values.everyday ? 'каждый рабочий день' : `каждый ${values.repeatNumber1}-й день`}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {values.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatWeekly && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Еженедельный повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            <div className="flex">
              <div className="flex items-center flex-none table-row-item-height">
                каждую
                <Push size={8} orientation="horizontal" />
              </div>

              {editMode ? (
                <div style={{ width: 72 }}>
                  <FormikFormGroup required label="" name="everyWeekRepeatNumber1">
                    <FormikInput name="everyWeekRepeatNumber1" size="small" number disabled={!enabled} />
                  </FormikFormGroup>
                </div>
              ) : (
                <div>
                  <div className="flex items-center flex-none table-row-item-height">
                    {`${values.everyWeekRepeatNumber1}-ю`}
                  </div>
                </div>
              )}

              <div className="flex items-center flex-none table-row-item-height">
                <Push size={8} orientation="horizontal" />
                неделю в следующие дни:
              </div>

              {editMode ? (
                daysForTrainingSchedule.map((day) => {
                  return (
                    <>
                      <Push size={8} orientation="horizontal" />
                      <FormikCheckboxGroup label={day.label} name={day.value} size="small">
                        <Checkbox
                          labelId={day.value}
                          checked={(values as any)[day.value]}
                          onChange={(c) => {
                            setFieldValue(day.value, c);
                          }}
                          disabled={!enabled}
                        />
                      </FormikCheckboxGroup>
                    </>
                  );
                })
              ) : (
                <div>
                  <div className="flex items-center flex-none table-row-item-height">
                    <Push size={8} orientation="horizontal" />
                    {daysForTrainingSchedule
                      .filter((d) => !!(values as any)[d.value])
                      ?.map((e) => {
                        return e.label;
                      })
                      .join(', ')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {values.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatMonthly && (
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Ежемесячный повтор {editMode && <span className="table-data__required" />}
          </div>
          <div className="table-data__body">
            {editMode ? (
              <div className="flex">
                <RadioGroup
                  name="everymonth"
                  labelId="everymonth"
                  onChange={() => {
                    setFieldValue('everymonth', false);
                  }}
                  label=""
                  checked={!values.everymonth}
                  disabled={!enabled}
                >
                  {(props: any) => <Radio {...props} labelId="everymonth" checked={!values.everymonth} />}
                </RadioGroup>

                <div style={{ width: 72 }}>
                  <FormikFormGroup required label="" name="everymonthRepeatNumber1">
                    <FormikInput name="everymonthRepeatNumber1" size="small" number disabled={!enabled} />
                  </FormikFormGroup>
                </div>
                <div className="flex items-center flex-none table-row-item-height">
                  <Push size={8} orientation="horizontal" />
                  числа каждого
                  <Push size={8} orientation="horizontal" />
                </div>
                <div style={{ width: 72 }}>
                  <FormikFormGroup label="" name="everymonthRepeatNumber2">
                    <FormikInput name="everymonthRepeatNumber2" size="small" number disabled={!enabled} />
                  </FormikFormGroup>
                </div>
                <div className="flex items-center flex-none table-row-item-height">
                  <Push size={8} orientation="horizontal" />
                  месяца
                  <Push size={8} orientation="horizontal" />
                </div>
              </div>
            ) : !values.everymonth ? (
              `${values.everymonthRepeatNumber1}-го числа каждого ${values.everymonthRepeatNumber2}-го месяца`
            ) : null}

            {editMode && <Push size={16} orientation="vertical" />}

            {editMode ? (
              <div className="flex">
                <RadioGroup
                  name="everymonth"
                  labelId="everymonth"
                  onChange={() => {
                    setFieldValue('everymonth', true);
                  }}
                  label="в"
                  checked={values.everymonth ?? false}
                  disabled={!enabled}
                >
                  {(props: any) => (
                    <Radio {...props} labelId="everymonth" checked={values.everymonth ?? false} disabled={!enabled} />
                  )}
                </RadioGroup>
                <Push size={8} orientation="horizontal" />

                <div style={{ width: 130 }}>
                  <FormikFormGroup required label="" name="everymonthRepeatNumber3">
                    <FormikSelect
                      name="everymonthRepeatNumber3"
                      size="small"
                      isSearchable
                      options={weekNumberInMonth}
                      defaultValue={{ label: weekNumberInMonth[0].label, value: weekNumberInMonth[0].value }}
                      selectedValue={(v: SelectOptionType) => {}}
                      disabled={!enabled}
                    />
                  </FormikFormGroup>
                </div>

                <Push size={8} orientation="horizontal" />
                <div style={{ width: 172 }}>
                  <FormikFormGroup required label="" name="dayOfWeek">
                    <FormikSelect
                      name="dayOfWeek"
                      size="small"
                      isSearchable
                      options={weekday.map((e) => {
                        return { label: e.name, value: e.id };
                      })}
                      defaultValue={{ label: weekday[0].name, value: weekday[0].id }}
                      selectedValue={(v: SelectOptionType) => {}}
                      disabled={!enabled}
                    />
                  </FormikFormGroup>
                </div>

                <div className="flex items-center flex-none table-row-item-height">
                  <Push size={8} orientation="horizontal" />
                  каждого
                  <Push size={8} orientation="horizontal" />
                </div>
                <div style={{ width: 72 }}>
                  <FormikFormGroup label="" name="everymonthRepeatNumber4">
                    <FormikInput name="everymonthRepeatNumber4" size="small" number disabled={!enabled} />
                  </FormikFormGroup>
                </div>
                <div className="flex items-center flex-none table-row-item-height">
                  <Push size={8} orientation="horizontal" />
                  месяца
                  <Push size={8} orientation="horizontal" />
                </div>
              </div>
            ) : values.everymonth ? (
              `в${values.everymonthRepeatNumber3 === 2 ? 'о' : ''} ${
                weekNumberInMonth.find((w) => w.value === values.everymonthRepeatNumber3)?.label
              }${values.everymonthRepeatNumber3 !== 5 ? '-' : ''}${
                postfixMatrix[values.everymonthRepeatNumber3 !== 5 ? 0 : 1][(values.dayOfWeek ?? 1) - 1]
              } ${daysForTrainingSchedule.find((w) => w.id === values.dayOfWeek)?.fullName} каждого ${
                values.everymonthRepeatNumber4
              }-го месяца`
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleRepetition;
