import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { EducationTypeEnum } from '../../../../types/education-type';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { buildFormFieldName } from '../../../../lib/utils/requests';

type MenuPlacement = 'bottom' | 'auto' | 'top';

type ServiceProps = FieldProps<string> & {
  educationType: EducationTypeEnum;
  setIsTestService?: React.Dispatch<React.SetStateAction<boolean>>;
  parent?: string;
  menuPlacement?: MenuPlacement;
};

export let serviceIdField = 'serviceId';

const ServiceDonm = ({
  label = 'Детское объединение',
  name,
  editMode,
  required,
  defaultValue,
  educationType,
  setIsTestService,
  disabled,
  parent,
  menuPlacement = 'auto',
}: ServiceProps) => {
  const fieldName = buildFormFieldName(parent, `${name}Name`);
  const fieldIdName = buildFormFieldName(parent, `${name}Id`);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const fetchOptions = useCallback(
    async (query: string) =>
      await lookupApi.getService(query, educationType, undefined, userProfile.organizationId, undefined, true, true),
    [educationType, userProfile.organizationId]
  );
  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldId] = useField<number>(fieldIdName);
  serviceIdField = fieldIdName;
  const [, , helperSchedule] = useField<number | undefined>(buildFormFieldName(parent, 'scheduleOfTimetableId'));
  const [, , helperSlot] = useField<number | undefined>(buildFormFieldName(parent, 'slotId'));

  return (
    <Field label={label} editMode={editMode} required={required} value={fieldLabel.value} defaultValue={defaultValue}>
      <FormikFormGroup required label="" name={fieldIdName}>
        <FormikSelect
          name={fieldIdName}
          size="small"
          disabled={disabled}
          isSearchable
          loadOptions={fetchOptions}
          options={[]}
          menuPlacement={menuPlacement}
          defaultValue={fieldId.value && fieldLabel.value ? { value: fieldId.value, label: fieldLabel.value } : null}
          selectedValue={(selected: SelectOptionType) => {
            helperSlot.setValue(undefined);
            helperSchedule.setValue(undefined);
            helpersLabel.setValue(selected ? selected.label : undefined);
            setIsTestService &&
              setIsTestService(
                selected
                  ? selected.additionalPropertiesJson && JSON.parse(selected.additionalPropertiesJson)?.TestService
                  : false
              );
          }}
          placeholder="Начните вводить..."
        />
      </FormikFormGroup>
    </Field>
  );
};

export default ServiceDonm;
