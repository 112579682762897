import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Button, Checkbox, Tooltip, Panel, Push } from '@mosru/esz_uikit';
import { generateLink, hasAccessObjectAny, hasGeneralAccess, sliceText } from '../../../../../lib/utils';
import { routes } from '../../../../../config/constants';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { ReactComponent as Success } from '../../../../../assets/images/icons/success.svg';
import ModalAboutSignature from '../../modal-about-signature';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { ServiceRegistryContext } from '../../initial-data';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';
import history from '../../../../../history';
import { selectServiceItemCallback } from '../../../../../lib/utils/service';
import { AppState } from '../../../../../redux/types/state';
import { userProfileSelector } from '../../../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../../../types/authorization-data';

type Props = {
  id: number;
  classificatorEKUName: string;
  parallels: string;
  programmLevel: string;
  serviceStatusId: number;
  educationTypeId: number;
  vedomstvo: string;
  organization: string;
  classification: string;
  requestAccessibility: string;
  placeAddress: string;
  sign: boolean;
  isReadyToSign: boolean;
  status: string;
  age: string;
  serviceName: string;
  duration: string;
  canCreateTemplate: boolean;
  checkbox?: boolean;
  count?: number | undefined;
  selected?: boolean | undefined;
  onSelectedCheckboxHandler?: (value: number) => void;
};

type LinkByEducationRegistryType = {
  [index: string]: string;
};

const Divider = () => (
  <>
    <Push size={12} orientation="horizontal" />
    <span className="color-gray-other font-size-base">|</span>
    <Push size={12} orientation="horizontal" />
  </>
);

const ListItem: React.FC<Props> = ({
  checkbox,
  id,
  serviceStatusId,
  vedomstvo,
  organization,
  classification,
  requestAccessibility,
  placeAddress,
  sign,
  duration,
  status,
  age,
  canCreateTemplate,
  onSelectedCheckboxHandler,
  selected,
  count,
  programmLevel,
  parallels,
  serviceName,
  educationTypeId,
  isReadyToSign,
}) => {
  const { currentEducationType, type } = useContext(ServiceRegistryContext);

  const [openPopup, setOpenPopup] = useState<boolean>(false);

  // const link = `/ServiceTemplate/ServiceByTemplate?id=${id}`;
  const statusRequest = requestAccessibility.replaceAll('.', '');

  const showValues = (educationTypeId: EducationTypeEnum) => {
    const levelText = [EducationTypeEnum.ChildrenNonDogmEducation, EducationTypeEnum.ChildrenEducation].includes(
      educationTypeId
    )
      ? 'уровень'
      : '';

    if (
      educationTypeId === EducationTypeEnum.ChildrenEducation ||
      educationTypeId === EducationTypeEnum.DayCareCentersEducation ||
      educationTypeId === EducationTypeEnum.VirtualAssistantEducation ||
      educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation
    ) {
      return (
        <>
          {programmLevel} {levelText}
          <Divider />
          {duration}
          <Divider />
          {age} лет
        </>
      );
    } else {
      return (
        <>
          {duration}
          <Divider />
          {age} лет
          <Divider />
          {programmLevel} {levelText}
        </>
      );
    }
  };

  const colorStatus = (status: number) => {
    switch (status) {
      case ServiceStatusEnum.Signed:
        return 'success';
      case ServiceStatusEnum.Draft:
        return 'warning';
      default:
        return 'default';
    }
  };

  const tooltip = (position: 'top' | 'right' | 'bottom' | 'left') => {
    const textByType =
      type === RegistryTypeEnum.serviceTemplateType ? 'Сведения об ЭП шаблона' : 'Сведения об ЭП услуги';
    return educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      educationTypeId === EducationTypeEnum.SportEducation ? (
      <div role="presentation" className="cursor-pointer" onClick={() => setOpenPopup(true)}>
        <Tooltip component={() => <Success className="color-gray-dark" />} position={position} text={textByType} />
      </div>
    ) : null;
  };

  const signed = serviceStatusId === ServiceStatusEnum.Signed;

  const showStatusRequest = !selectServiceItemCallback && signed && statusRequest;

  const description = () => {
    if (EducationTypeEnum.VirtualAssistantEducation === educationTypeId) {
      return `${vedomstvo}, ${organization}`;
    } else if (type === RegistryTypeEnum.serviceTemplateType) {
      return vedomstvo;
    } else {
      return `${vedomstvo}, ${organization}${placeAddress ? ':' : ''} ${placeAddress ?? ''}`;
    }
  };

  // Когда у пользователя больше 1 роли, мы делаем редирект на общий роут.
  const checkAccessAmount = currentEducationType.length > 1;

  const childrenEducationLink = checkAccessAmount ? routes.program : routes.registerChildEducation;
  const dkgmOrDsitEducationLink = type === RegistryTypeEnum.serviceTemplateType ? routes.myTemplate : routes.service;

  const linkByEducationRegistryType: LinkByEducationRegistryType = {
    [EducationTypeEnum.ChildrenEducation]: childrenEducationLink,
    [EducationTypeEnum.DayCareCentersEducation]: checkAccessAmount ? routes.program : routes.dayCareProgram,
    [EducationTypeEnum.VirtualAssistantEducation]: checkAccessAmount ? routes.program : routes.service,
    [EducationTypeEnum.ChildrenNonDogmEducation]: childrenEducationLink,
    [EducationTypeEnum.ArtHouseEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.SportEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.ProfessionalEducation]: routes.registerEducationProgram,
  };

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const showCreateByTemplate = useMemo(
    () =>
      hasAccessObjectAny(
        userProfile,
        [accessObject.ServiceSport, accessObject.ServiceArtHouse],
        accessAction.CreateByTemplate
      ) || hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  return (
    <>
      <div key={id} className="service-panels-list__item">
        <Panel>
          <div className="container">
            <Push size={16} />
            <div className="service-child-media">
              <div className="service-child-media__num">
                {checkbox && onSelectedCheckboxHandler ? (
                  <>
                    <Checkbox
                      labelId={`checkbox_${count}`}
                      checked={!!selected}
                      onChange={() => onSelectedCheckboxHandler && onSelectedCheckboxHandler(id)}
                      disabled={signed || !isReadyToSign}
                    />
                    <Push orientation="horizontal" size={8} />
                  </>
                ) : null}
                {count}
              </div>
              <div className="service-child-media__body">
                <Link
                  className="brand-link font-size-base"
                  to={generateLink(linkByEducationRegistryType[educationTypeId], { id })}
                >
                  {sliceText(serviceName)}
                </Link>
                <Push size={8} />
                {!(educationTypeId === EducationTypeEnum.DayCareCentersEducation) && (
                  <>
                    <span className="color-gray-dark">{classification}</span>
                    <Push size={16} />
                    <div className="flex items-center">{showValues(educationTypeId)}</div>
                  </>
                )}
                {educationTypeId === EducationTypeEnum.DayCareCentersEducation && <>Параллель: {parallels}</>}
                <Push size={12} />
                {description()}
              </div>
              <div className="service-child-media__status">
                <div className="flex items-start">
                  <div className={classNames('flex flex-column', showStatusRequest ? 'items-center' : 'items-end')}>
                    <Badge color={colorStatus(serviceStatusId)} text={status} />
                    <Push size={8} orientation="vertical" />

                    <div className="flex items-center flex-none">
                      {(canCreateTemplate || (sign && !canCreateTemplate)) && tooltip('bottom')}
                      {showStatusRequest && (
                        <>
                          <Push size={8} orientation="horizontal" />
                          {statusRequest}
                          <Push size={8} orientation="horizontal" />
                        </>
                      )}
                    </div>

                    {selectServiceItemCallback && (
                      <div className="flex flex-column items-end">
                        <Push size={8} orientation="horizontal" />
                        {signed ? statusRequest : ''}
                        <Push size={8} orientation="vertical" />
                        <Button
                          handleClick={() => {
                            selectServiceItemCallback(serviceName, id);
                            window.close();
                          }}
                          primary
                          label="Выбрать"
                        />
                      </div>
                    )}
                  </div>

                  {type === RegistryTypeEnum.serviceTemplateType && showCreateByTemplate && (
                    <>
                      <Push size={8} orientation="horizontal" />
                      <Button
                        handleClick={() => {
                          history.push(generateLink(routes.createServiceByTemplate, { templateId: id }));
                        }}
                        primary
                        border
                        label="Создать услугу"
                      />
                    </>
                  )}
                </div>
                <Push size={12} />
              </div>
            </div>
            <Push size={16} />
          </div>
        </Panel>
      </div>
      <ModalAboutSignature show={openPopup} onCloseHandler={() => setOpenPopup(false)} />
    </>
  );
};

export default ListItem;
