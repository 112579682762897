import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { Button, ModalPanel, Modal, Infobox, SelectOptionType, Loader, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceClassTransferStage } from '../../../../types/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import serviceClassApi from '../../../../lib/api/service-class';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';

const initialValues: ServiceClassTransferStage = {
  serviceStageId: 0,
  documentTypeId: 3,
  docNumber: '',
  docDate: '',
  comment: '',
  educationTypeId: 0,
  megaRelationIds: [],
};

type Props = {
  open: boolean;
  close: () => void;
};

const TransferStage: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, learners, selected, fetchTable, serviceClassData } =
    useContext(ServiceClassTableContext);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);
  const [stages, setStages] = useState<SelectOptionType[]>([]);

  const [loading, setLoading] = useState(false);

  const selectedPeople = learners.filter((value) => selected.includes(value.id));

  initialValues.serviceStageId = selectedPeople[0]?.serviceStageId || 0;

  const initialErrors = useInitialErrors(initialValues, getValidationSchema());
  const submitForm = useCallback(
    async (values: ServiceClassTransferStage) => {
      setLoadBtn(true);
      try {
        values.megaRelationIds = selectedPeople.map((item) => item.id);
        values.educationTypeId = serviceClassData.educationTypeId;
        await serviceClassApi.transferStage(serviceClassData.id || 0, values);
        fetchTable();
        setOpenDetails(false);
        setSelected([]);
        close();
      } finally {
        setLoadBtn(false);
      }
    },
    [
      selectedPeople,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      close,
      fetchTable,
      setOpenDetails,
      setSelected,
    ]
  );

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const documentTypes = await dictionariesApi.getDocumentTypes(3);
        setDocuments(documentTypes);

        if (serviceClassData.serviceId || selectedPeople[0].serviceId) {
          const stages = await serviceTemplateApi.getServiceAvalableStageList(
            serviceClassData.serviceId || selectedPeople[0].serviceId,
            selectedPeople.map((item) => item.id)
          );
          setStages(stages);
        }
      } finally {
        setLoading(false);
      }
    };
    open && fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, serviceClassData.serviceId]);

  return (
    <Modal show={open} onClose={close}>
      <Formik
        enableReinitialize
        onSubmit={submitForm}
        initialErrors={initialErrors}
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
      >
        {(formikProps: FormikProps<any>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={close} />
                    <Push orientation="horizontal" size={12} />
                    <Button
                      primary
                      size="small"
                      label="Перевести"
                      load={loadBtn}
                      submit
                      disabled={!isValid || isSubmitting}
                    />
                  </>
                )}
                modalTitle="Перевод на другой этап обучения"
                renderComponent={() =>
                  loading ? (
                    <div className="flex align-center justify-center">
                      <Loader small roller />
                    </div>
                  ) : (
                    <>
                      <Infobox
                        text="Для перевода обучающихся доступны этапы, на которых на данный момент не обучается ни один из выбранных детей"
                        color="primary"
                      />
                      <Push size={16} />
                      <FormikFormGroup name="serviceStageId" label="Этап обучения" required>
                        <FormikSelect
                          name="serviceStageId"
                          size="small"
                          isSearchable
                          options={stages}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="documentTypeId" label="Тип документа" required>
                        <FormikSelect
                          name="documentTypeId"
                          size="small"
                          options={documents}
                          placeholder="Выберите..."
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="docNumber" label="Номер документа">
                        <FormikInput size="small" name="docNumber" placeholder="Введите..." />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="docDate" label="Дата">
                        <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="docDate" />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="comment" label="Причина перевода">
                        <FormikTextarea name="comment" placeholder="Введите..." rows={2} />
                      </FormikFormGroup>
                    </>
                  )
                }
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TransferStage;

const getValidationSchema = () =>
  objectYup().shape({
    serviceStageId: stringYup().nullable().required('Выберите этап обучения'),
    documentTypeId: stringYup().nullable().required('Выберите тип документа'),
    docDate: docDate.nullable(),
  });
