import moment from 'moment';
import { boolean as booleanYup, date as dateYup, number as numberYup, ref, setLocale, string as stringYup } from 'yup';
import { maskArray } from 'react-text-mask';
import React from 'react';
import { DocumentTypeEnum } from '../../mock-data/type-document';
import { EducationTypeEnum } from '../../types/education-type';
import { TypeFinancingEnum } from '../../mock-data/type-financing-enum';
import { ServiceData, ServiceDataInfo } from '../../types/service';
import { replaceDotsWithCommas } from './index';

setLocale({
  date: {
    max: 'Введённая дата является слишком большой',
    min: 'Введённая дата является слишком маленькой',
  },
});

// eslint-disable-next-line no-useless-escape
export const emailRegexp = /^[a-zA-Z0-9\_*\-*\.*]+@[a-zA-Z0-9\-*]+\.*[A-Za-z\.*\-*]*\.[A-Za-z]+$/;

export const rusWithNumberRegexp = /^[А-Яа-яЁё0-9`'.\-s]+$/;
export const rusWithNumberAndSlashRegexp = /^[А-Яа-яЁё0-9`'.\-s/]+$/;
export const spaceRegexp = /^[^\s]+(\s+[^\s]+)*$/;

export const rusRegexp = /^[А-Яа-яЁё`'.\-s]+$/;
export const rusRegexWithSpace = /^[А-Яа-яЁё`'\s\-_]*$/;
export const rusRegexWithSpaceAndNumber = /^[А-Яа-яЁё0-9`'\s\-_!,.:+=?)(]*$/;
export const rusRegexWithSpaceAndNumberAndSymbols = /^[А-Яа-яЁё`'\s\-!,.:+=?)(]*$/;

export const MAX_VALUE = 9999999.99;

export const emojiRegex = /\p{Extended_Pictographic}/u;

export const maxLengthTextError = (max: number) => `Должно содержать не более ${max} символов`;

// Разрешение на ввод только чисел
export const allowOnlyNumbers = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  if ((e.key.length === 1 && e.key !== '.' && Number.isNaN(Number(e.key)) && !e.ctrlKey) || e.key === '.') {
    e.preventDefault();
  }
};

// разрешение на ввод чисел с точкой
export const allowOnlyNumbersAndComma = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  if (e.key.length === 1 && e.key !== ',' && Number.isNaN(Number(e.key)) && !e.ctrlKey) {
    e.preventDefault();
  }
};

// разрешение на ввод чисел без пробела
export const allowOnlyNumbersNoSpace = (e: React.KeyboardEvent<HTMLInputElement>, allowComma = false): void => {
  const currentValue = e.currentTarget.value;
  const { selectionStart, selectionEnd } = e.currentTarget;

  if (selectionStart !== null && selectionEnd !== null) {
    const newValue = currentValue.slice(0, selectionStart) + e.key + currentValue.slice(selectionEnd);
    if (
      (allowComma || e.key !== ',') && // Проверка на разрешение ввода запятой
      (newValue.includes(' ') || (e.key !== ' ' && Number.isNaN(Number(e.key)) && !e.ctrlKey))
    ) {
      e.preventDefault();
    }
  }
};

export const onlyLetters = (event: React.KeyboardEvent<HTMLElement>): void => {
  if (/[0-9,!?():+=*]/g.test(event.key)) {
    event.preventDefault();
  }
};

export const preventInvalidPaste = (event: React.ClipboardEvent<HTMLDivElement>, regExp?: RegExp) => {
  const pastedData = event.clipboardData?.getData('text');
  const regExpTest = regExp || /[,'.]+|[0-9,!?():+=*]/g;
  if (pastedData && regExpTest.test(pastedData)) {
    event.preventDefault();
  }
};

// Проверка на вхождение числа в диапазон
export const checkRange = (
  range: number[],
  key: string,
  values: any,
  setFieldValue: (field: string, value: any) => void
) => {
  const [min, max] = range;
  const value = typeof values[key] === 'number' ? values[key] : Number(values[key]?.trim());

  if (value > max) {
    setFieldValue(key, max);
  } else if (value < min) {
    setFieldValue(key, min);
  } else if (value) {
    setFieldValue(key, value);
  }
};

export const stringRequired = stringYup().required('');

export const nullableRus = stringYup()
  .nullable()
  .matches(spaceRegexp, { message: 'Пробел в начале или конце недопустим' })
  .matches(rusRegexWithSpace, { message: 'Используйте только русские буквы' });

export const nullableDate = dateYup()
  .nullable()
  .max(new Date(Date.now() - 86400000), 'Дата должна быть меньше текущей')
  .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введенная дата является слишком маленькой');

export const checkMaskDocNumber = (activeDocumentId: number): boolean | maskArray => {
  if (
    activeDocumentId === DocumentTypeEnum.ForeignPassport ||
    activeDocumentId === DocumentTypeEnum.BirthCertificateForeign
  ) {
    return false;
  } else if (activeDocumentId === DocumentTypeEnum.ResidencePermit) {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  } else if (activeDocumentId === DocumentTypeEnum.BirthCertificate) {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  } else {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  }
};

// TODO: доработать
export const checkMaskDocSeries = (activeDocumentId: number): boolean | maskArray => {
  if (
    activeDocumentId === DocumentTypeEnum.ForeignPassport ||
    activeDocumentId === DocumentTypeEnum.BirthCertificateForeign ||
    activeDocumentId === DocumentTypeEnum.BirthCertificate
  ) {
    return false;
  } else if (activeDocumentId === DocumentTypeEnum.ResidencePermit) {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  } else {
    return [/\d/, /\d/, /\d/, /\d/];
  }
};

export const dateOfBirth = dateYup()
  .nullable()
  .required('Введите дату рождения')
  .max(new Date(Date.now() - 86400000), 'Дата должна быть меньше текущей')
  .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введенная дата является слишком маленькой');

export const sexId = stringYup().required('Выберите пол');

export const foreignDocument = {
  series: stringYup().max(100, 'Серия должна содержать не более 100 символов').nullable(),
  number: stringYup().max(100, 'Номер должен содержать не более 100 символов').nullable(),
};

export const documentSeries = (require?: boolean) => {
  const requiredTextError = 'Введите серию документа';

  return stringYup()
    .typeError(requiredTextError)
    .nullable()
    .when('documentTypeId', {
      is: DocumentTypeEnum.BirthCertificate.toString(),
      then: (s) => {
        const result = s
          .max(7, 'Серия должна содержать не более 7 символов')
          .matches(
            /^[A-Za-z]{1,4}-[А-Яа-я]{2,2}$/,
            'Серия свидетельства должна быть вида XXXX-XX, английские и русские буквы'
          );
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.Passport.toString(),
      then: (s) => {
        const result = s.matches(/^[0-9]{4,4}$/, 'Серия паспорта должна содержать 4 цифры');
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.BirthCertificateForeign.toString(),
      then: foreignDocument.series,
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.ForeignPassport.toString(),
      then: foreignDocument.series,
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.OldPassport.toString(),
      then: (s) => {
        const result = s
          .max(7, 'Серия должна содержать не более 7 символов')
          .matches(
            /^[A-Za-z]{1,4}-[А-Яа-я]{2,2}$/,
            'Серия паспорта должна быть вида XXXX-XX, английские и русские буквы'
          );
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.ResidencePermit.toString(),
      then: (s) => {
        const result = s
          .length(2, 'Серия документа должна содержать 2 цифры')
          .matches(/^[0-9]{2,2}$/, 'Серия документа должна содержать 2 цифры');
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.BirthRecord.toString(),
      then: (s) => s.optional(),
    });
};
export const documentNumber = (require?: boolean) => {
  const requiredTextError = 'Введите номер документа';

  return stringYup()
    .typeError(requiredTextError)
    .when('documentTypeId', {
      is: DocumentTypeEnum.BirthCertificate.toString(),
      then: (s) => {
        const result = s
          .max(50, 'Номер должен содержать не более 50 цифр')
          .matches(/^\d{6}$/, 'Номер должен содержать 6 цифр');
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.Passport.toString(),
      then: (s) => {
        const result = s.length(6, 'Номер должен содержать 6 цифр').matches(/^\d{6}$/, 'Номер должен содержать 6 цифр');
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.BirthCertificateForeign.toString(),
      then: () => {
        const result = foreignDocument.number;
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.ForeignPassport.toString(),
      then: () => {
        const result = foreignDocument.number;
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.OldPassport.toString(),
      then: (s) => {
        const result = s
          .max(6, 'Номер должен содержать не более 6 цифр')
          .matches(/^[0-9]{6,6}$/, 'Номер паспорта должен содержать 6 цифр');
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.ResidencePermit.toString(),
      then: (s) => {
        const result = s
          .length(7, 'Номер документа должен содержать 6 цифр')
          .matches(/^[0-9]{6,6}$/, 'Номер документа должен содержать 6 цифр');
        return require ? result.required(requiredTextError) : result;
      },
    })
    .when('documentTypeId', {
      is: DocumentTypeEnum.BirthRecord.toString(),
      then: (s) => {
        const result = s.test('documentTypeId', '', (value: string | null | undefined, ctx: any) => {
          return validateDocumentBirthRecord(value, ctx, ctx.parent.dateOfIssue);
        });
        return require ? result.required(requiredTextError) : result;
      },
    });
};

export const issued = stringYup()
  .nullable()
  .max(250, 'Поле кем выдан должен содержать не более 250 символов')
  .when('documentTypeId', {
    is: DocumentTypeEnum.BirthCertificate.toString(),
    then: (s) => s.required('Введите кем выдан'),
  })
  .when('documentTypeId', {
    is: DocumentTypeEnum.Passport.toString(),
    then: (s) => s.required('Введите кем выдан'),
  })
  .when('documentTypeId', {
    is: DocumentTypeEnum.BirthCertificateForeign.toString(),
    then: (s) => s.required('Введите кем выдан'),
  })
  .when('documentTypeId', {
    is: DocumentTypeEnum.BirthRecord.toString(),
    then: (s) => s.required('Введите кем внесена запись'),
  });

export const issuedNullable = stringYup()
  .nullable()
  .notRequired()
  .max(250, 'Поле кем выдан должен содержать не более 25022 символов');

export const issuedPlace = stringYup()
  .nullable()
  .when('documentTypeId', { is: '1', then: (s) => s.required('Введите место выдачи ') })
  .when('documentTypeId', { is: '2', then: (s) => s.required('Введите место выдачи ') });

export const dateOfIssue = (require?: boolean) => {
  const value = dateYup()
    .nullable()
    .max(moment().format('L'), 'Дата должна быть меньше текущей')
    .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введенная дата является слишком маленькой');

  return require ? value.required('Введите дату выдачи') : value;
};

export const snils = stringYup()
  .matches(/^[0-9]{3,3}-[0-9]{3,3}-[0-9]{3,3} [0-9]{2,2}$/, 'СНИЛС должен содержать 11 цифр')
  .nullable();

export const nullableMaxRusStringWithSlash = (maxAmount: number) =>
  stringYup()
    .nullable()
    .matches(spaceRegexp, { message: 'Пробел в начале или конце недопустим' })
    .max(maxAmount, 'превышена максимальная длинна поля')
    .matches(rusWithNumberAndSlashRegexp, { message: 'Используйте только русские буквы' });

export const nullableMaxRusWithSpaceAndNumberString = stringYup()
  .nullable()
  .max(200, 'превышена максимальная длинна поля')
  .matches(rusRegexWithSpaceAndNumber, { message: 'Используйте только русские буквы' });

export const nullableMaxRusWithSpaceAndNumberStringAndSymbols = stringYup()
  .nullable()
  .max(200, 'превышена максимальная длинна поля')
  .matches(rusRegexWithSpaceAndNumberAndSymbols, { message: 'Используйте только русские буквы' });

export const firstName = stringYup()
  .required('Введите имя')
  .max(200, 'превышена максимальная длинна поля')
  .typeError('Введите имя')
  .matches(spaceRegexp, { message: 'Пробел в начале или конце недопустим' })
  .matches(rusRegexWithSpace, { message: 'Используйте только русские буквы' });

export const lastName = stringYup()
  .required('Введите фамилию')
  .max(200, 'превышена максимальная длинна поля')
  .typeError('Введите фамилию')
  .matches(spaceRegexp, { message: 'Пробел в начале или конце недопустим' })
  .matches(rusRegexWithSpace, { message: 'Используйте только русские буквы' });

export const middleName = stringYup()
  .nullable()
  .max(200, 'превышена максимальная длинна поля')
  .matches(spaceRegexp, { message: 'Пробел в начале или конце недопустим' })
  .matches(rusRegexWithSpace, { message: 'Используйте только русские буквы' });

export const nullableEmail = stringYup().nullable().matches(emailRegexp, {
  message: 'Введите корректный e-mail',
});

export const phoneErrorMessage = 'Введите номер телефона';

export const nullablePhone = stringYup()
  .nullable()
  .matches(/^(\+7\s)?\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/, phoneErrorMessage);

export const phone = nullablePhone.required(phoneErrorMessage);

export const requestDeclineReasonId = numberYup()
  .nullable()
  .test('required', 'Выберите причину отказа', (value) => !!value);

export const documentTypeId = stringYup().required('Выберите тип документа');

export const enrollDocNumber = stringYup().nullable().required('Введите номер документа');

export const docDate = dateYup()
  .max(moment('01.01.2050', 'DD/MM/YYYY').format('L'))
  .min(moment('02.01.1910', 'DD/MM/YYYY').format('L'));

export const serviceClassId = stringYup().required('Введите группу для зачисления');

export const organizationId = stringYup().required('Выберите организацию');

export const classParallelId = stringYup().required('Выберите параллель');

export const childrenAssociation = stringYup().nullable().required('Выберите детское объединение');

export const scheduleOfTimetable = stringYup().required('Выберите план приема');

export const serviceId = stringYup().nullable().required('Выберите наименование программы');

export const slot = stringYup().required('Выберите план приема с доступными местами');

export const serviceClassName = stringYup().nullable().required('Введите наименование группы');

export const serviceProgram = stringYup().nullable().required('Выберите образовательную программу');

export const trainStartDate = dateYup()
  .required()
  .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введенная дата является слишком маленькой');

export const trainEndDate = dateYup()
  .required()
  .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введенная дата является слишком маленькой');

export const altFemaleStart = numberYup()
  .nullable()
  .max(120, 'Введите значение меньше или равное 120')
  .lessThan(ref('altEnd'), 'Минимальный возраст должен быть меньше, чем максимальный')
  .required('Введите');

export const validationCheckDate = (
  requiredText: string,
  dateKeys: {
    start: string;
    end: string;
  },
  errorTextInfo: string,
  type: string
) => {
  const validDate = docDate.nullable().required(requiredText).typeError(requiredText);

  const currentDateStart = validDate.max(ref(dateKeys.end), errorTextInfo);
  const currentDateEnd = validDate.min(ref(dateKeys.start), errorTextInfo);

  const dependentDateKey = type === 'start' ? dateKeys.end : dateKeys.start;

  const validateByType = type === 'start' ? currentDateStart : currentDateEnd;

  return dateYup()
    .when(dependentDateKey, {
      is: (currentDate: Date | string) => {
        return !!currentDate;
      },
      then: () => {
        return validateByType;
      },
      otherwise: () => {
        return validDate;
      },
    })
    .typeError(requiredText);
};

export const chekClassificatorEKU = (classificatorEKUName: string) => {
  return classificatorEKUName
    ?.split(' / ')
    .some(
      (s) =>
        s === 'Инженерное направление' ||
        s === 'Медицинское направление' ||
        s === 'Научно-технологическое направление' ||
        s === 'Направление комплексной безопасности'
    );
};

export const durationOfTrainingFunc = (info: ServiceDataInfo, service?: ServiceData) => {
  if (service?.educationTypeId === EducationTypeEnum.ChildrenEducation) {
    const isClassificatorEKUEx = chekClassificatorEKU(info.classificatorEKUName);

    const sum =
      Number(info?.durationOfTraining || 0) * 365 +
      (Number(info?.durationOfTrainingMonths || 0) * 31 - Number(info.durationOfTrainingMonths || 0) / 2) + //  TODO: костыль, надо придумать как считать дни
      Number(info?.durationOfTrainingWeeks || 0) * 7 +
      Number(info?.durationOfTrainingDays || 0);
    // Ознакомительный
    if (info?.programmLevelId === 11 && service?.financing?.typeFinancingId === TypeFinancingEnum.Free && sum < 90) {
      return 'Для уровня программы "Ознакомительный" минимальный срок обучения от 3 месяцев';
      // Базовый
    } else if (info?.programmLevelId === 12 && (sum < 365 || sum > 11 * 365)) {
      return 'Для уровня программы "Базовый" срок обучения от 1 года до 11 лет';
      // Углубленный
    } else if (info.programmLevelId === 13 && isClassificatorEKUEx && (sum < 365 || sum > 11 * 365)) {
      return 'Для уровня программы "Углубленный" срок обучения от 1 года до 11 лет';
    } else if (info.programmLevelId === 13 && !isClassificatorEKUEx && (sum < 730 || sum > 11 * 365)) {
      return 'Для уровня программы "Углубленный" срок обучения от 2 до 11 лет';
    }
  }
  return null;
};

export const durationOfTraining = (max: number) =>
  stringYup()
    .nullable()
    .test('maxDurationOfTraining', '', function (value: string | null | undefined, ctx: any) {
      if (value && parseInt(value) > max) {
        return ctx.createError({ message: `Введите значение меньше или равное ${max}` });
      }
      return true;
    })
    .test('oneOfDurationOfTraining', 'Введите продолжительность услуги', function () {
      return (
        !!parseInt(this.parent.durationOfTraining) ||
        !!parseInt(this.parent.durationOfTrainingMonths) ||
        !!parseInt(this.parent.durationOfTrainingWeeks) ||
        !!parseInt(this.parent.durationOfTrainingDays)
      );
    });

export const durationOfTrainingTest = (max: number, serviceData?: ServiceData) =>
  durationOfTraining(max).test('hoursPerWeek', '', (value: string | number | null | undefined, ctx: any) => {
    const [parent1, parent2] = ctx.from;
    const service = serviceData || parent2?.value;
    const error = durationOfTrainingFunc(parent1?.value, service);
    if (error) {
      return ctx.createError({ message: error });
    }
    return true;
  });

export const maxNumber = (max: number, message?: string) =>
  numberYup()
    .nullable()
    .max(max, message || `Введите значение меньше или равное ${max}`);

export const altStartValidationFunc = (
  info: ServiceDataInfo,
  value: string | null | undefined,
  service?: ServiceData
) => {
  if (value && parseInt(value) > 120) {
    return 'До 120';
  }
  if (service?.educationTypeId === EducationTypeEnum.ChildrenEducation) {
    // Вводный и ознакомительный
    if (info.programmLevelId === 17 || info.programmLevelId === 11) {
      if (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment && value && parseInt(value) < 0) {
        return 'От 0';
      }
      if (service?.financing?.typeFinancingId === TypeFinancingEnum.Free && value && parseInt(value) < 5) {
        return 'От 5';
      }
      // Базовый
    } else if (info.programmLevelId === 12) {
      if (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment && value && parseInt(value) < 0) {
        return 'От 0';
      }
      if (service?.financing?.typeFinancingId === TypeFinancingEnum.Free && value && parseInt(value) < 8) {
        return 'От 8';
      }
      // Углубленный
    } else if (info.programmLevelId === 13) {
      if (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment && value && parseInt(value) < 0) {
        return 'От 0';
      }
      if (service?.financing?.typeFinancingId === TypeFinancingEnum.Free && value && parseInt(value) < 12) {
        return 'От 12';
      }
    }
  } else {
    if (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment && value && parseInt(value) < 0) {
      return 'От 0';
    }
    if (service?.financing?.typeFinancingId === TypeFinancingEnum.Free && value && parseInt(value) < 1) {
      return 'От 1';
    }
    return null;
  }
};

export const altStartValidation = (serviceData?: ServiceData) =>
  stringYup()
    .required('Введите')
    .nullable()
    .test('altStart', 'От', (value: string | null | undefined, ctx: any) => {
      const [parent1, parent2] = ctx.from;
      const service = serviceData || parent2?.value;
      const error = altStartValidationFunc(parent1?.value, value, service);
      if (error) {
        return ctx.createError({ message: error });
      }
      return true;
    });

export const altEndValidationFunc = (
  info: ServiceDataInfo,
  value: string | null | undefined,
  service?: ServiceData
) => {
  if (service?.educationTypeId === EducationTypeEnum.ChildrenEducation) {
    if (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment && value && parseInt(value) > 120) {
      return 'До 120';
    }
    if (service?.financing?.typeFinancingId === TypeFinancingEnum.Free && value && parseInt(value) > 18) {
      return 'До 18';
    }
  } else if (value && parseInt(value) > 120) {
    return 'До 120';
  }
  if (value && info.altStart && info.altStart > parseInt(value)) {
    return 'Максимальный возраст должен быть больше, чем минимальный';
  }
  return null;
};

export const altEndValidation = (serviceData?: ServiceData) =>
  stringYup()
    .required('Введите')
    .nullable()
    .test('altEnd', 'До', (value: string | null | undefined, ctx: any) => {
      const parent2 = ctx.from[1];
      const service = serviceData || parent2?.value;
      const error = altEndValidationFunc(ctx.from[0]?.value, value, service);
      if (error) {
        return ctx.createError({ message: error });
      }
      return true;
    });

export const checkPeriodPrice = (value: string | null | undefined, ctx: any) => {
  const parent = ctx.from[0];

  if (parent?.value?.typeFinancingId === 2) {
    if (!value || value === '0') {
      return ctx.createError({ message: 'Необходимо указать стоимость' });
    } else if (parseFloat(value) > MAX_VALUE) {
      return ctx.createError({ message: `Значение не может быть больше ${replaceDotsWithCommas(MAX_VALUE)}` });
    } else if (parseFloat(value) < 1) {
      return ctx.createError({ message: 'Необходимо указать стоимость' });
    }
  }
  return true;
};

export const hoursPerWeekFunc = (info: ServiceDataInfo, value: string | undefined | null, service: ServiceData) => {
  if (service?.educationTypeId === EducationTypeEnum.ChildrenEducation) {
    const isClassificatorEKUEx = chekClassificatorEKU(info.classificatorEKUName);
    // Базовый
    if (info?.programmLevelId === 12 && value) {
      if (isClassificatorEKUEx) {
        if (parseInt(value) < 2) {
          return 'Для уровня программы "Базовый" минимальное количество часов в неделю 2';
        } else if (parseInt(value) > 6) {
          return 'Для уровня программы "Базовый" максимальное количество часов в неделю до 6';
        }
      } else if (parseInt(value) < 3) {
        return 'Для уровня программы "Базовый" минимальное количество часов в неделю 3';
      } else if (parseInt(value) > 5) {
        return 'Для уровня программы "Базовый" максимальное количество часов в неделю до 5';
      }
      // Углубленный
    } else if (info?.programmLevelId === 13 && value) {
      if (isClassificatorEKUEx) {
        if (parseInt(value) < 2) {
          return 'Для уровня программы "Углубленный" минимальное количество часов в неделю 2';
        } else if (parseInt(value) > 8) {
          return 'Для уровня программы "Углубленный" максимальное количество часов в неделю до 8';
        }
      } else if (parseInt(value) < 4) {
        return 'Для уровня программы "Углубленный" минимальное количество часов в неделю 4';
      } else if (parseInt(value) > 8) {
        return 'Для уровня программы "Углубленный" максимальное количество часов в неделю до 8';
      }
    } else if (service?.financing?.typeFinancingId === TypeFinancingEnum.Payment) {
      if (value && parseInt(value) > 99) {
        return 'Введите значение меньше или равное 99';
      }
      // Вводный
    } else if (info?.programmLevelId === 17 && value) {
      if (parseInt(value) < 1) {
        return 'Для уровня программы "Вводный" минимальное количество часов в неделю 1';
      } else if (parseInt(value) > 50) {
        return 'Для уровня программы "Вводный" максимальное количество часов в неделю до 50';
      }
      // Ознакомительный
    } else if (info?.programmLevelId === 11 && value) {
      if (parseInt(value) < 1) {
        return 'Для уровня программы "Ознакомительный" минимальное количество часов в неделю 1';
      } else if (parseInt(value) > 3) {
        return 'Для уровня программы "Ознакомительный" максимальное количество часов в неделю до 3';
      }
    }
  }
  return null;
};

export const hoursPerWeek = (serviceData?: ServiceData) =>
  stringYup()
    .nullable()
    .test('hoursPerWeek', '', (value: string | null | undefined, ctx: any) => {
      const [parent1, parent2] = ctx.from;
      const service = serviceData || parent2?.value;

      const error = hoursPerWeekFunc(parent1?.value, value, service);
      if (error) {
        return ctx.createError({ message: error });
      }
      return true;
    });

export const durationOfTrainingDsitDkgm = stringYup()
  .nullable()
  .test('durationOfTraining', '', (value: string | null | undefined, ctx: any) => {
    const [parent1] = ctx.from;

    if (parent1?.value?.durationOfTrainingUnitId === 1) {
      if (!value || value === '0') {
        return ctx.createError({ message: 'Введите количество лет' });
      }
      if (parseInt(value) > 9) {
        return ctx.createError({ message: 'До 9 лет' });
      }
    }
    if (parent1?.value?.durationOfTrainingUnitId === 2) {
      if (!value || value === '0') {
        return ctx.createError({ message: 'Введите количество месяцев' });
      }
      if (parseInt(value) > 12) {
        return ctx.createError({ message: 'До 12 месяцев' });
      }
    }
    if (parent1?.value?.durationOfTrainingUnitId === 3) {
      if (!value || value === '0') {
        return ctx.createError({ message: 'Введите количество недель' });
      }
      if (parseInt(value) > 54) {
        return ctx.createError({ message: 'До 54 недель' });
      }
    }
    if (parent1?.value?.durationOfTrainingUnitId === 4) {
      if (!value || value === '0') {
        return ctx.createError({ message: 'Введите количество дней' });
      }
      if (parseInt(value) > 54) {
        return ctx.createError({ message: 'До 54 дней' });
      }
    }
    return true;
  });

export const isFloorPresent = (name: string) => {
  return booleanYup()
    .nullable()
    .test(name, '', (value: boolean | null | undefined, ctx: any) => {
      const [parent1] = ctx.from;
      if (!parent1?.value?.isMalePresent && !parent1?.value?.isFemalePresent) {
        return ctx.createError({ message: 'Укажите пол' });
      }

      return true;
    });
};

export const validateDocumentBirthRecord = (value: string | null | undefined, ctx: any, docDate: Date | string) => {
  const MIN_DATE = '2018-10-01';
  const SMALL_NUM_REG_EXP = /^(\d){1,5}(-В)?$/;
  const LARGE_NUM_REG_EXP = /^(\d){21}$/;

  const errors = {
    incorrectDocument: 'Некорректный номер',
    maxNumberLength21: 'Номер должен содержать 21 цифру',
  };

  if (value) {
    const isSmallNum = SMALL_NUM_REG_EXP.test(value);
    const isLargeNum = LARGE_NUM_REG_EXP.test(value);

    if (docDate && (moment(docDate).isAfter(MIN_DATE) || moment(MIN_DATE).isSame(docDate))) {
      if (!isLargeNum) {
        return ctx.createError({ message: errors.maxNumberLength21 });
      }
    } else if (!isSmallNum && !isLargeNum) {
      return ctx.createError({ message: errors.incorrectDocument });
    }

    if (isLargeNum && !validateEGRRecord(value)) {
      return ctx.createError({ message: errors.incorrectDocument });
    }
  }

  return true;
};

export function validateEGRRecord(EGRRecord: string) {
  // таблица коэффициентов для подсчета контрольной суммы
  const coefficients = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];

  if (!EGRRecord) {
    return false;
  }
  const result = EGRRecord.replace('-', '').replace(' ', '').trim();

  if (!result) {
    return false;
  }

  // До 18-го года номера записи были другой длинны, их проверять не надо.
  if (result.length < 21) {
    return true;
  }

  // проверить каждый символ, что это цифра
  const isNum = /^\d+$/.test(result);
  if (!isNum) {
    return false;
  }

  // 1 - запись акта о рождении
  if (result[1] !== '1') {
    return false;
  }

  // Код дополнительного признака, характеризующего условия составления записи акта гражданского состояния
  if (Number(result[18]) > 2 || result[19] !== '0') {
    return false;
  }

  let ctrlSum = 0;

  for (let i = 0; i < coefficients.length; i++) {
    const res = Number(result[i]) * coefficients[i];
    ctrlSum += res > 9 ? res - 9 : res;
  }

  const sum = ctrlSum + Number(result[20]);

  return sum % 10 === 0;
}

export const checkPercent = (valuePercent: number, onChangePercent: (value: number | string) => void) => {
  if (valuePercent) {
    const MAX_PERCENT = 100;

    // убираем пробелы между цифрами
    const subsidiesPercent = String(valuePercent).replace(/\s+/g, '');

    const symbolComma = ',';
    const regexSymbol = /[,]/;

    const percentNumber = parseFloat(subsidiesPercent.replace(',', '.'));
    const isSymbol = Boolean(subsidiesPercent.match(regexSymbol));
    const isMaxPercent = percentNumber > MAX_PERCENT;

    const hasLeadingZeros = subsidiesPercent.startsWith('0');

    const isRepeatSymbol = subsidiesPercent.split('').filter((item: string) => item === symbolComma).length > 1;

    const getSubsidiesPercentagePrefix = (str: string) => {
      return str.charAt(1) === ',' ? str.slice(0, 3) : str.slice(0, 4);
    };

    const removeLeadingZeros = (value: string): string => {
      return value.replace(/^0+/, '').trimStart();
    };

    const reset = () => onChangePercent('');

    if (hasLeadingZeros) {
      onChangePercent(removeLeadingZeros(subsidiesPercent));
      return;
    }

    if (!percentNumber) {
      reset();
    }

    if (isSymbol) {
      // является ли символ повторением предыдущего символа
      if (isRepeatSymbol) {
        onChangePercent(subsidiesPercent.slice(0, subsidiesPercent.length - 1));
        return;
      }
      // Проверяем, является ли первый символ запятой
      if (subsidiesPercent[0] === symbolComma) {
        reset();
        return;
      }
      // Проверяем, превышает ли введенный процент максимально допустимое значение
      if (isMaxPercent) {
        onChangePercent(MAX_PERCENT);
        return;
      }
      // Проверяем, начинается ли введенное значение с нулей
      if (hasLeadingZeros) {
        onChangePercent(removeLeadingZeros(getSubsidiesPercentagePrefix(subsidiesPercent)));
        return;
      }
      onChangePercent(getSubsidiesPercentagePrefix(subsidiesPercent));
    }

    if (percentNumber === MAX_PERCENT) {
      onChangePercent(removeLeadingZeros(MAX_PERCENT.toString()));
    }

    if (!isSymbol && isMaxPercent) {
      onChangePercent(MAX_PERCENT);
    }
  }
};

export const birthRecordIssued = stringYup()
  .nullable()
  .max(250, 'Поле кем выдан должен содержать не более 250 символов')
  .when('documentTypeId', {
    is: DocumentTypeEnum.BirthRecord.toString(),
    then: (s) => s.required('Введите кем внесена запись'),
  });
