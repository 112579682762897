import React, { useContext, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { generatePath } from 'react-router-dom';
import { Button, Infobox, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import Financing from './financing';
import Photo from '../../components/panels/photo';
import Period from '../../child/panels/period';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ServiceData } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { Info } from './info';
import history from '../../../../history';
import { CreateContactPerson } from '../../components/panels/contact-person/create-contact-person';
import { CreateServiceContext } from '../../create';
import { routes } from '../../../../config/constants';
import { infoValidationSchema } from '../panels/info';
import { financingValidationSchema } from '../../components/panels/financing';
import { convertParallelInResponse, setGenderStartEndToBack } from '../../utils';

const DigitalTutorCreateCreateDetails = () => {
  const { serviceData } = useContext(CreateServiceContext);

  const initialErrors = useInitialErrors(serviceData, infoValidationSchema);

  const [load, setLoad] = useState<boolean>(false);

  const initialData = useMemo(() => {
    return {
      ...serviceData,
      info: { ...serviceData.info, isFemalePresent: true, isMalePresent: true, classificatorEKUId: undefined },
      financing: {
        ...serviceData.financing,
        typeFinancingId: 2,
        typeValueServiceId: 1,
        typeFinancingName: 'Платно',
        typeValueServiceName: 'За месяц',
      },
    };
  }, [serviceData]);

  const submitForm = async (values: ServiceData) => {
    setLoad(true);
    try {
      const idService = await serviceTemplateApi.createService({
        ...values,
        info: {
          ...setGenderStartEndToBack(values.info),
          parallelList: convertParallelInResponse(values.info.parallelList as SelectOptionType[]),
        },
      });
      history.push(generatePath(routes.service, { id: idService }));
      setLoad(false);
    } finally {
      setLoad(false);
    }
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
      initialValues={initialData}
      initialErrors={initialErrors}
      validationSchema={dataValidationSchema}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, isValid } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Info />

            <CreateContactPerson parent="contactPerson" />

            <Push size={12} />

            <Panel title={() => 'Учебные периоды'}>
              <div className="container">
                <Infobox
                  fullWidth
                  color="warning"
                  text="Редактирование учебных периодов станет доступно после первого сохранения."
                />
              </div>
              <Push size={24} />
            </Panel>

            <Push size={12} orientation="vertical" />

            <Period />

            <Financing />

            <Push size={12} />

            <Panel title={() => 'Планы приема'}>
              <div className="container">
                <Infobox
                  fullWidth
                  color="warning"
                  text="Планы приема станут доступными для ввода после первого сохранения."
                />
              </div>
              <Push size={24} />
            </Panel>

            <Photo />

            <div className="room-save-container">
              <div className="room-panel-save">
                <div className="container">
                  <div className="room-panel-save__inner">
                    <Button primary onClick={() => history.push(routes.programs)} border label="Отмена" />
                    <Push size={12} orientation="horizontal" />
                    <Button load={load} disabled={!isValid} submit primary label="Создать услугу" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default DigitalTutorCreateCreateDetails;

export const dataValidationSchema = () =>
  objectYup().shape({
    info: infoValidationSchema(),
    financing: financingValidationSchema(),
    contactPerson: contactPersonValidationSchema(),
  });
