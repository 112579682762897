import React from 'react';
import { Select, SelectOptionType, TreeNodeType } from '@mosru/esz_uikit';
import { TreeModalContext } from '../index';

type Props = {
  currentCategory: SelectOptionType | undefined;
  selectOptions?: SelectOptionType[];
  setDataTreePopup: (value: TreeNodeType[]) => void;
  setCurrentCategory: (value: SelectOptionType) => void;
};

const SelectCategory: React.FC<Props> = ({ setCurrentCategory, currentCategory, setDataTreePopup, selectOptions }) => {
  const { setCurrentMenuClosePopup } = React.useContext(TreeModalContext);

  return (
    <Select
      name="currentMenuClosePopup"
      isSearchable
      value={currentCategory}
      onChange={(value) => {
        setCurrentCategory(value as SelectOptionType);
        if (setCurrentMenuClosePopup) {
          setCurrentMenuClosePopup(value);
        }
        setDataTreePopup([]);
      }}
      options={selectOptions ?? []}
      placeholder="Выберите значение..."
      size="medium"
    />
  );
};

export default SelectCategory;
