import React, { useContext } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import FieldAsyncSelect from './async-select';
import { SearchKeyContext } from '../contexts/key-context';
import { setAsyncValues } from '../../../../lib/utils/requests';

type Props = {
  values: SearchRequestsInitialFormData;
  label: string;
  placeholder: string;
  disabled: boolean;
};

const FieldOrganization: React.FC<Props> = ({ values, label, placeholder, disabled }) => {
  const { organizationKey, setServiceKey, setPlaceServiceKey, setTrainingGroupKey } = useContext(SearchKeyContext);
  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();

  const onChange = () => {
    const dataToClearValues = [
      { key: 'serviceId', action: setServiceKey },
      { key: 'placeServiceId', action: setPlaceServiceKey },
      { key: 'trainingGroupId', action: setTrainingGroupKey },
    ];

    setAsyncValues(dataToClearValues, setFieldValue);
  };

  return (
    <FieldAsyncSelect
      key={organizationKey}
      disabled={disabled}
      onChange={onChange}
      nameFieldId="organizationId"
      nameFieldName="organizationName"
      label={label}
      placeholder={placeholder}
      loadOptions={async (query) => await lookupApi.getOrganization(query, values.vedomstvoId)}
    />
  );
};

export default FieldOrganization;
