import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Button, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/close.svg';
import { ReportSchedule } from '../../../../types/reports';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AuthorizationData } from '../../../../types/authorization-data';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  listSchedule: ReportSchedule;
  openScheduleModal: () => void;
  resetScheduleModalHandler: (value: boolean) => void;
};

export const FieldSchedule: React.FC<Props> = ({
  isAdmin,
  userProfile,
  listSchedule,
  openScheduleModal,
  resetScheduleModalHandler,
}) => {
  const { setFieldValue } = useFormikContext();

  // Расписание работы группы
  const accessWorkSchedule: boolean = useMemo(() => {
    return hasAccessObjectAny(userProfile, [accessObject.ServiceClassDayCare], accessAction.View) || isAdmin;
  }, [isAdmin, userProfile]);

  useEffect(() => {
    setFieldValue('scheduleOfServiceText', listSchedule?.selectShortDay);
    setFieldValue('scheduleOfService', listSchedule?.selectDaysForRequest);
  }, [listSchedule?.selectDaysForRequest, listSchedule?.selectShortDay, setFieldValue]);

  return accessWorkSchedule ? (
    <>
      <Push size={16} />
      <div className="report__search--wrap">
        <div className="search__wrap--input">
          <FormikFormGroup name="" label="Расписание работы группы">
            <FormikInput value={listSchedule.selectShortDay} size="small" name="" placeholder="Не указано" disabled />
          </FormikFormGroup>
        </div>
        <div className="search__wrap--btn">
          <Push size={25} orientation="vertical" />
          <div className="flex">
            <Button
              border
              size="small"
              iconRight={() => <IconRemove />}
              onClick={() => {
                resetScheduleModalHandler(true);
              }}
            />
            <Push size={10} orientation="horizontal" />
            <div style={{ minWidth: '105px' }}>
              <Button border primary size="small" onClick={openScheduleModal} label="Выбрать" />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};
