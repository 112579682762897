import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import { number, object as objectYup } from 'yup';
import {
  Button,
  TabButton,
  TabGroup,
  DatePickerComponent,
  SelectOptionType,
  Panel,
  Switcher,
  Push,
} from '@mosru/esz_uikit';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import FormikInput from '../../../components/formik/formik-input';
import { ReactComponent as IconOptions } from '../../../assets/images/icons/options.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/close.svg';
import FormikSelect from '../../../components/formik/formik-select';
import { ReactComponent as IconUp } from '../../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../../assets/images/icons/down.svg';
import {
  GetReport,
  OptionFilter,
  ReportFilterData,
  ReportInformation,
  ReportSchedule,
  TypeSwitch,
} from '../../../types/reports';
import lookupApi from '../../../lib/api/lookup';
import SheduleModal, { Weekday } from '../../../components/shedule-modal';
import { reportsApi } from '../../../lib/api/reports';
import { ReportFilterTypeEnum } from '../../../mock-data/report-filter-type-enum';
import { removeEmptyDataForRequest } from '../../../lib/utils/reports';
import { allowOnlyNumbers, checkRange } from '../../../lib/utils/validation';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import { weekday } from '../../../components/shedule-modal/helpers';

type Props = {
  reportFiltersData: GetReport | null;
  loading: boolean;
  setQuery: (data: ReportFilterData | undefined) => void;
  setHeader: (header: { [key: string]: any }[]) => void;
  resetTable: () => void;
};

const disableFilterPlaceholder = 'Выбор фильтра не доступен для вашей роли';

const SavedReportsFilter: React.FC<Props> = ({ reportFiltersData, loading, setQuery, setHeader, resetTable }) => {
  const [open, setOpen] = useState(false);
  const [resetScheduleModal, setResetScheduleModal] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<'Organization' | 'ChildService' | 'Other'>('Organization');
  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const [scheduleModal, setScheduleModal] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<ReportSchedule>({ selectDaysForRequest: '', selectShortDay: '' });
  const [currentSelectDay, setCurrentSelectDay] = useState<Weekday[]>([]);
  const [formData, setFormData] = useState<ReportFilterData>({ vedomstvoId: null, organizationId: null });
  const [listReport, setListReport] = useState<SelectOptionType[] | undefined>();
  const [typesFinansing, setTypesFinansing] = useState<TypeSwitch[]>([]);
  const [contingents, setContingents] = useState<TypeSwitch[]>([]);
  const [organization, setOrganization] = useState<SelectOptionType | null>(null);
  const [mrsd, setMrsd] = useState<SelectOptionType | null>(null);
  const [service, setService] = useState<SelectOptionType | null>(null);
  const [teacher, setTeacher] = useState<SelectOptionType | null>(null);
  const [serviceClass, setServiceClass] = useState<SelectOptionType | null>(null);
  const [resetFlag, setResetFlag] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [parallels, setParallels] = useState<SelectOptionType[]>([]);
  const [letters, setLetters] = useState<SelectOptionType[]>([]);

  const getUserValidationSchema = () => {
    return objectYup().shape({
      maxAge: number()
        .nullable()
        .test('maxAge', 'Максимальный возраст не может быть меньше минимального', function (value) {
          if (this.parent.minAge !== undefined && value !== undefined && value && value < this.parent.minAge) {
            return false;
          }
          return true;
        })
        .min(1, 'От 1')
        .max(120, 'До 120')
        .typeError('Введите значение меньшее или равное 120.'),

      minAge: number()
        .nullable()
        .test('minAge', 'Минимальный возраст не может быть больше максимального', function (value) {
          if (this.parent.maxAge !== undefined && value !== undefined && value && value > this.parent.maxAge) {
            return false;
          }
          return true;
        })
        .min(1, 'От 1')
        .max(120, 'До 120')
        .typeError('Введите значение меньшее или равное 120.'),
    });
  };

  /** установка значений по-умолчанию */
  const initialFormData: ReportFilterData = useMemo(() => {
    const d: ReportFilterData = {
      vedomstvoId: null,
      organizationId: null,
    };
    reportFiltersData?.filterList?.forEach((f: OptionFilter) => {
      if (f.value !== null) {
        d[f.parameterField] = f.value;
      }
    });
    setFormData(d);
    return d;
  }, [reportFiltersData]);

  const FillFilterValues = (filter: OptionFilter): TypeSwitch[] => {
    const filterValues: TypeSwitch[] = [];
    for (let i = 0; i < filter.listItems.length; i++) {
      filterValues.push({
        name: filter.listItems[i].name,
        id: filter.listItems[i].id,
        selected: filter.displayValue === filter.listItems[i].name,
      });
    }
    return filterValues;
  };

  // *** schedule modal
  const openScheduleModal = () => {
    setScheduleModal(true);
  };

  const resetScheduleModalHandler = (value: boolean) => {
    setResetScheduleModal(value);
    setCurrentSelectDay(weekday);
    if (value) {
      setSchedule({ selectDaysForRequest: '', selectShortDay: '' });
      setFormData((prev: ReportFilterData) => ({ ...prev, [getFilterName(ReportFilterTypeEnum.BySchedule)]: '' }));
    }
  };

  const setScheduleValue = (value: ReportSchedule) => {
    setSchedule(value);
    setFormData({ ...formData, [getFilterName(ReportFilterTypeEnum.BySchedule)]: value.selectDaysForRequest });
  };

  const getFilterData = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return [];
      }

      const defaultValue = { label: filter.allOption ?? '', value: '' };
      return [
        defaultValue,
        ...(filter.listItems || []).map((i) => {
          return { label: i.name, value: i.id };
        }),
      ];
    },
    [reportFiltersData]
  );

  const getFilterAllOption = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return { label: '', value: '' };
      }
      return { label: filter.allOption ?? '', value: '' };
    },
    [reportFiltersData]
  );

  const getFilterName = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return '';
      }
      return filter.parameterField;
    },
    [reportFiltersData]
  );

  const getFilterLabel = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return '';
      }
      return filter.parameterCaption;
    },
    [reportFiltersData]
  );

  const getFilterReadOnly = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return undefined;
      }
      return filter.readOnly;
    },
    [reportFiltersData]
  );

  const isVisible = useCallback(
    (filterName: string) => {
      const filter = reportFiltersData?.filterList.find((f) => f.parameterField === filterName);
      if (filter === undefined) {
        return false;
      }
      return filter.visible;
    },
    [reportFiltersData]
  );

  const getFilterTypeAheadPlaceholder = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = reportFiltersData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return undefined;
      }
      return filter.readOnly ? disableFilterPlaceholder : filter.typeAheadPlaceholder;
    },
    [reportFiltersData]
  );

  // список отчетов для поиска
  useEffect(() => {
    const fetch = async () => {
      const reports = await reportsApi.getAviableReportList();
      const transformReports = reports?.map((item: ReportInformation) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      setListReport([{ label: 'Все отчеты', value: '' }, ...transformReports]);
    };
    fetch();
  }, [reportFiltersData]);

  // Инициализация фильтров типа Switch
  useEffect(() => {
    const fetch = async () => {
      const typeFinansingFilter = reportFiltersData?.filterList.find(
        (f) => f.key === ReportFilterTypeEnum.ByTypeFinansing
      );
      if (typeFinansingFilter !== undefined) {
        setTypesFinansing([{ name: 'Все', id: 0, selected: true }, ...FillFilterValues(typeFinansingFilter)]);
      }

      const contingentLinkFilter = reportFiltersData?.filterList.find(
        (f) => f.key === ReportFilterTypeEnum.ByContingentLinkType
      );
      if (contingentLinkFilter !== undefined) {
        setContingents([{ name: 'Все', id: 0, selected: true }, ...FillFilterValues(contingentLinkFilter)]);
      }
    };
    fetch();
  }, [reportFiltersData, resetFlag]);

  useEffect(() => {
    const fetch = async () => {
      const defaultValues = { label: 'Все', value: 'Все' };
      if (!organization) {
        setParallels([]);
        setLetters([]);
      } else {
        const parallels = await dictionariesApi.getParallels(organization.value as number);
        const letters = await dictionariesApi.getLetters(organization.value as number);
        setParallels(parallels?.length !== 1 ? [defaultValues, ...parallels] : parallels);
        setLetters(letters?.length !== 1 ? [defaultValues, ...letters] : letters);
      }
    };

    fetch();
  }, [organization, getFilterData]);

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const submitForm = (value: ReportFilterData, actions: { setSubmitting: (arg: boolean) => void }) => {
    setIsLoading(true);

    removeEmptyDataForRequest(value);
    setQuery(value);
    actions.setSubmitting(false);
  };

  const searchAction = (data: any) => {
    const fields = Object.keys(data);
    const countDiff = Object.keys(data).reduce((acc, valueIndex, index) => {
      // @ts-ignore
      if (
        data[valueIndex] !== undefined &&
        (data[valueIndex] !== 0 || fields[index] === 'ageGroup' || fields[index] === 'territoryId') &&
        data[valueIndex] !== '' &&
        data[valueIndex] !== null &&
        data[valueIndex] !== initialFormData[valueIndex] &&
        isVisible(fields[index]) &&
        fields[index] !== 'periodTo' // костыль
      ) {
        acc++;
      }
      return acc;
    }, 0);
    return countDiff;
  };

  const resetForm = () => {
    resetTable();
    setHeader([]);
    setFormData(initialFormData);
    setQuery(undefined);
    setOrganization(null);
    setMrsd(null);
    setService(null);
    setServiceClass(null);
    setTeacher(null);
    setRerenderFormKey(Math.random());
    setResetFlag(Math.random());
    resetScheduleModalHandler(true);
  };

  return (
    <>
      <Formik
        onSubmit={submitForm}
        enableReinitialize
        initialValues={formData}
        key={rerenderFormKey}
        validationSchema={getUserValidationSchema}
      >
        {(formikProps: FormikProps<ReportFilterData>) => {
          const { handleSubmit, isValid, values, setFieldValue } = formikProps;
          const filterCount = searchAction(values);

          return (
            <form onSubmit={handleSubmit}>
              <Panel
                subControl={() => (
                  <button type="button" onClick={() => setOpen(!open)} className="icon-group">
                    <span className="icon-group__icon">
                      <IconOptions />
                    </span>
                    <span className="icon-group__text">Расширенный поиск</span>
                    {filterCount > 0 && !open && (
                      <span className="icon-group__icon">
                        <span className="badge">{filterCount}</span>
                      </span>
                    )}
                    <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
                  </button>
                )}
                controls={() => (
                  <>
                    <Button label="Сбросить" onClick={resetForm} border size="small" />
                    <Push orientation="horizontal" size={12} />
                    <Button submit label="Начать поиск" load={isLoading} disabled={!isValid} primary size="small" />
                  </>
                )}
              >
                <div className="container">
                  <Push size={16} />
                  <FormikFormGroup name="reportId" label="">
                    <FormikSelect
                      name="reportId"
                      size="small"
                      isSearchable
                      options={listReport || []}
                      defaultValue={{ label: 'Все отчеты', value: '' }}
                    />
                  </FormikFormGroup>
                </div>
                {open && (
                  <>
                    <Push size={16} />
                    <div className="container">
                      <TabGroup data-test="tab-group">
                        <TabButton
                          active={searchType === 'Organization'}
                          label="Организация"
                          onClick={() => {
                            setRerenderFormKey(Math.random());
                            setSearchType('Organization');
                            setFormData(values);
                          }}
                        />
                        <TabButton
                          active={searchType === 'ChildService'}
                          label="Детское объединение и услуга"
                          onClick={() => {
                            setRerenderFormKey(Math.random());
                            setSearchType('ChildService');
                            setFormData(values);
                          }}
                        />

                        <TabButton
                          active={searchType === 'Other'}
                          label="Другое"
                          onClick={() => {
                            setRerenderFormKey(Math.random());
                            setSearchType('Other');
                            setFormData(values);
                          }}
                        />
                      </TabGroup>

                      {searchType === 'Organization' && (
                        <div>
                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByTerritory)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByTerritory)}
                          >
                            <FormikSelect
                              name={getFilterName(ReportFilterTypeEnum.ByTerritory)}
                              size="small"
                              isSearchable
                              options={getFilterData(ReportFilterTypeEnum.ByTerritory)}
                              defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByTerritory)}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTerritory)}
                            />
                          </FormikFormGroup>
                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByVedomstvo)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByVedomstvo)}
                          >
                            <FormikSelect
                              size="small"
                              isSearchable
                              name={getFilterName(ReportFilterTypeEnum.ByVedomstvo)}
                              options={getFilterData(ReportFilterTypeEnum.ByVedomstvo)}
                              defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByVedomstvo)}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByVedomstvo)}
                            />
                          </FormikFormGroup>
                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByMrsd)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByMrsd)}
                          >
                            <FormikSelect
                              size="small"
                              isSearchable
                              name={getFilterName(ReportFilterTypeEnum.ByMrsd)}
                              loadOptions={async (query) => await lookupApi.getOrganizationMrsd(query)}
                              selectedValue={setMrsd}
                              defaultValue={mrsd}
                              placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByMrsd)}
                              options={[]}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByMrsd)}
                            />
                          </FormikFormGroup>
                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByOrganization)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByOrganization)}
                          >
                            <FormikSelect
                              size="small"
                              isSearchable
                              name={getFilterName(ReportFilterTypeEnum.ByOrganization)}
                              options={[]}
                              loadOptions={async (query) => await lookupApi.getOrganization(query)}
                              selectedValue={(value: SelectOptionType) => {
                                if (!value) {
                                  setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassParallel), null);
                                  setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassLetter), null);
                                }
                                setOrganization(value);
                              }}
                              defaultValue={organization}
                              placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByOrganization)}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByOrganization)}
                            />
                          </FormikFormGroup>
                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByTypeProvServ)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByTypeProvServ)}
                          >
                            <FormikSelect
                              size="small"
                              isSearchable
                              name={getFilterName(ReportFilterTypeEnum.ByTypeProvServ)}
                              options={getFilterData(ReportFilterTypeEnum.ByTypeProvServ)}
                              defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByTypeProvServ)}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTypeProvServ)}
                            />
                          </FormikFormGroup>
                        </div>
                      )}

                      {searchType === 'ChildService' && (
                        <div>
                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByProgrammLevel)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByProgrammLevel)}
                          >
                            <FormikSelect
                              size="small"
                              isSearchable
                              name={getFilterName(ReportFilterTypeEnum.ByProgrammLevel)}
                              options={getFilterData(ReportFilterTypeEnum.ByProgrammLevel)}
                              defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByProgrammLevel)}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByProgrammLevel)}
                            />
                          </FormikFormGroup>

                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByService)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByService)}
                          >
                            <FormikSelect
                              name={getFilterName(ReportFilterTypeEnum.ByService)}
                              size="small"
                              isSearchable
                              options={[]}
                              loadOptions={async (query) => await lookupApi.getService(query)}
                              placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByService)}
                              selectedValue={setService}
                              defaultValue={service}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByService)}
                            />
                          </FormikFormGroup>

                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByServiceClass)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByServiceClass)}
                          >
                            <FormikSelect
                              loadOptions={async (query) => {
                                return await lookupApi.getServiceClass(query);
                              }}
                              placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByServiceClass)}
                              name={getFilterName(ReportFilterTypeEnum.ByServiceClass)}
                              size="small"
                              isSearchable
                              options={[]}
                              selectedValue={setServiceClass}
                              defaultValue={serviceClass}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByServiceClass)}
                            />
                          </FormikFormGroup>

                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByClassParallel)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByClassParallel)}
                          >
                            <FormikSelect
                              placeholder={
                                getFilterReadOnly(ReportFilterTypeEnum.ByClassParallel)
                                  ? disableFilterPlaceholder
                                  : 'Сначала выберите организацию'
                              }
                              name={getFilterName(ReportFilterTypeEnum.ByClassParallel)}
                              size="small"
                              isSearchable
                              options={parallels}
                              disabled={
                                getFilterReadOnly(ReportFilterTypeEnum.ByClassParallel) || !values.organizationId
                              }
                              defaultValue={parallels ? parallels[0] : undefined}
                            />
                          </FormikFormGroup>

                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByClassLetter)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByClassLetter)}
                          >
                            <FormikSelect
                              placeholder={
                                getFilterReadOnly(ReportFilterTypeEnum.ByClassLetter)
                                  ? disableFilterPlaceholder
                                  : 'Сначала выберите организацию'
                              }
                              name={getFilterName(ReportFilterTypeEnum.ByClassLetter)}
                              size="small"
                              isSearchable
                              options={letters}
                              disabled={
                                getFilterReadOnly(ReportFilterTypeEnum.ByClassParallel) || !values.organizationId
                              }
                              defaultValue={letters ? letters[0] : undefined}
                            />
                          </FormikFormGroup>

                          <Push size={16} />
                          <div className="report__search--wrap">
                            <div className="search__wrap--input">
                              <FormikFormGroup
                                name={getFilterName(ReportFilterTypeEnum.BySchedule)}
                                label={getFilterLabel(ReportFilterTypeEnum.BySchedule)}
                              >
                                <FormikInput
                                  key={Math.random()}
                                  size="small"
                                  name={getFilterName(ReportFilterTypeEnum.BySchedule)}
                                  value={schedule.selectShortDay}
                                  placeholder={
                                    getFilterReadOnly(ReportFilterTypeEnum.BySchedule)
                                      ? disableFilterPlaceholder
                                      : 'Не указано'
                                  }
                                  disabled
                                />
                              </FormikFormGroup>
                            </div>
                            <div className="search__wrap--btn">
                              <Push size={25} orientation="vertical" />
                              <div className="flex">
                                <Button
                                  border
                                  size="small"
                                  iconRight={() => <IconRemove />}
                                  onClick={() => {
                                    resetScheduleModalHandler(true);
                                  }}
                                  disabled={getFilterReadOnly(ReportFilterTypeEnum.BySchedule)}
                                />

                                <Push size={10} orientation="horizontal" />
                                <div style={{ minWidth: '184px' }}>
                                  <Button
                                    border
                                    primary
                                    size="small"
                                    onClick={openScheduleModal}
                                    label="Выбрать расписание"
                                    disabled={getFilterReadOnly(ReportFilterTypeEnum.BySchedule)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <Push size={16} />
                          <FormikFormGroup
                            name={getFilterName(ReportFilterTypeEnum.ByTeacher)}
                            label={getFilterLabel(ReportFilterTypeEnum.ByTeacher)}
                          >
                            <FormikSelect
                              loadOptions={async (query) => {
                                return await lookupApi.getTeachers(query, values.organizationId);
                              }}
                              name={getFilterName(ReportFilterTypeEnum.ByTeacher)}
                              size="small"
                              isSearchable
                              selectedValue={setTeacher}
                              placeholder={getFilterTypeAheadPlaceholder(ReportFilterTypeEnum.ByTeacher)}
                              options={[]}
                              defaultValue={teacher}
                              disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTeacher)}
                            />
                          </FormikFormGroup>

                          <Push size={16} />
                          <div style={{ maxWidth: '225px' }}>
                            <FormikFormGroup
                              name={getFilterName(ReportFilterTypeEnum.ByTypeFinansing)}
                              label={getFilterLabel(ReportFilterTypeEnum.ByTypeFinansing)}
                            >
                              <Switcher fullWidth size="small">
                                {typesFinansing.map((element: TypeSwitch) => {
                                  return (
                                    <button
                                      key={element.id}
                                      type="button"
                                      disabled={getFilterReadOnly(ReportFilterTypeEnum.ByTypeFinansing)}
                                      onClick={() => {
                                        setTypesFinansing(
                                          typesFinansing.map((item) => {
                                            return {
                                              ...item,
                                              selected: item.id === element.id,
                                            };
                                          })
                                        );
                                        setFormData({
                                          ...values,
                                          [getFilterName(ReportFilterTypeEnum.ByTypeFinansing)]: element.id,
                                        });
                                      }}
                                      className={element.selected ? 'active' : ''}
                                    >
                                      {element.name}
                                    </button>
                                  );
                                })}
                              </Switcher>
                            </FormikFormGroup>
                          </div>
                        </div>
                      )}

                      {searchType === 'Other' && (
                        <>
                          <Push size={16} />
                          <div className="report__option">
                            <div>
                              <FormikFormGroup
                                name={getFilterName(ReportFilterTypeEnum.ByAgeGroup)}
                                label={getFilterLabel(ReportFilterTypeEnum.ByAgeGroup)}
                              >
                                <FormikSelect
                                  size="small"
                                  isSearchable
                                  name={getFilterName(ReportFilterTypeEnum.ByAgeGroup)}
                                  options={getFilterData(ReportFilterTypeEnum.ByAgeGroup)}
                                  defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByAgeGroup)}
                                  disabled={getFilterReadOnly(ReportFilterTypeEnum.ByAgeGroup)}
                                />
                              </FormikFormGroup>

                              <Push size={16} />

                              <FormikFormGroup
                                name={getFilterName(ReportFilterTypeEnum.ByYearOfTraining)}
                                label={getFilterLabel(ReportFilterTypeEnum.ByYearOfTraining)}
                              >
                                <FormikSelect
                                  size="small"
                                  isSearchable
                                  name={getFilterName(ReportFilterTypeEnum.ByYearOfTraining)}
                                  options={getFilterData(ReportFilterTypeEnum.ByYearOfTraining)}
                                  defaultValue={getFilterAllOption(ReportFilterTypeEnum.ByYearOfTraining)}
                                  disabled={getFilterReadOnly(ReportFilterTypeEnum.ByYearOfTraining)}
                                />
                              </FormikFormGroup>

                              <Push size={16} />

                              <div>
                                <div>Возраст</div>
                                <Push size={8} />
                                <div className="report__age--wrap">
                                  <div className="report__age--element">
                                    <FormikFormGroup
                                      showErrorImmediately
                                      name={getFilterName(ReportFilterTypeEnum.ByMinAgeGroup)}
                                      label=""
                                    >
                                      <FormikInput
                                        size="small"
                                        name={getFilterName(ReportFilterTypeEnum.ByMinAgeGroup)}
                                        placeholder="Мин введите"
                                        number
                                        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByMinAgeGroup)}
                                        onBlur={() => {
                                          checkRange([1, 120], 'minAge', values, setFieldValue);
                                        }}
                                        onKeyPress={(e: any) => {
                                          allowOnlyNumbers(e);
                                        }}
                                      />
                                    </FormikFormGroup>
                                  </div>
                                  <Push size={8} orientation="horizontal" />
                                  <div className="report__age--element">
                                    <FormikFormGroup
                                      showErrorImmediately
                                      name={getFilterName(ReportFilterTypeEnum.ByMaxAgeGroup)}
                                      label=""
                                    >
                                      <FormikInput
                                        size="small"
                                        name={getFilterName(ReportFilterTypeEnum.ByMaxAgeGroup)}
                                        placeholder="Макс введите"
                                        number
                                        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByMaxAgeGroup)}
                                        onBlur={() => {
                                          checkRange([1, 120], 'maxAge', values, setFieldValue);
                                        }}
                                        onKeyPress={(e: any) => {
                                          allowOnlyNumbers(e);
                                        }}
                                      />
                                    </FormikFormGroup>
                                  </div>
                                </div>
                                <Push size={16} />
                              </div>
                            </div>

                            {/* вторая колонка */}
                            <div>
                              <div>{getFilterLabel(ReportFilterTypeEnum.ByContingentLinkType)}</div>
                              <Push size={8} orientation="vertical" />
                              <div style={{ maxWidth: '285px' }}>
                                <Switcher fullWidth size="small">
                                  {contingents?.map((element: TypeSwitch) => {
                                    return (
                                      <button
                                        key={element.id * 10}
                                        type="button"
                                        disabled={getFilterReadOnly(ReportFilterTypeEnum.ByContingentLinkType)}
                                        onClick={() => {
                                          setContingents(
                                            contingents.map((item) => {
                                              return {
                                                ...item,
                                                selected: item.id === element.id,
                                              };
                                            })
                                          );
                                          setFormData({
                                            ...values,
                                            [getFilterName(ReportFilterTypeEnum.ByContingentLinkType)]: element.id,
                                          });
                                        }}
                                        className={element.selected ? 'active' : ''}
                                      >
                                        {element.name}
                                      </button>
                                    );
                                  })}
                                </Switcher>
                              </div>
                              <Push size={16} />
                              <div>
                                <div>Период</div>
                                <Push size={8} orientation="vertical" />
                                <DatePickerComponent
                                  name="formData"
                                  placeholder="ДД.ММ.ГГГГ-ДД.ММ.ГГГГ"
                                  error=""
                                  onChange={(item) => {
                                    const from = Array.isArray(item)
                                      ? new Date(moment(item[0]).format('yyyy-MM-DD'))
                                      : undefined;
                                    const to =
                                      Array.isArray(item) && item[1]
                                        ? new Date(moment(item[1]).format('yyyy-MM-DD'))
                                        : undefined;
                                    setFormData({
                                      ...values,
                                      [getFilterName(ReportFilterTypeEnum.ByPeriodFrom)]: from?.toISOString(),
                                      [getFilterName(ReportFilterTypeEnum.ByPeriodTo)]: to?.toISOString(),
                                    });
                                  }}
                                  rangePicker
                                  size="small"
                                  startDate={
                                    formData[getFilterName(ReportFilterTypeEnum.ByPeriodFrom)]
                                      ? new Date(formData[getFilterName(ReportFilterTypeEnum.ByPeriodFrom)])
                                      : undefined
                                  }
                                  endDate={
                                    formData[getFilterName(ReportFilterTypeEnum.ByPeriodTo)]
                                      ? new Date(formData[getFilterName(ReportFilterTypeEnum.ByPeriodTo)])
                                      : undefined
                                  }
                                  disabled={getFilterReadOnly(ReportFilterTypeEnum.ByPeriodFrom)}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </Panel>
            </form>
          );
        }}
      </Formik>

      <SheduleModal
        show={scheduleModal}
        title="Время работы"
        reset={resetScheduleModal}
        saveSchedule={setScheduleValue}
        currentSelectDay={currentSelectDay}
        resetModal={resetScheduleModalHandler}
        setCurrentSelectDay={setCurrentSelectDay}
        currentShortDay={schedule.selectShortDay}
        onCloseHandler={() => setScheduleModal(false)}
      />
    </>
  );
};

export default SavedReportsFilter;
