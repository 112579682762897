import moment from 'moment';
import React, { Reducer, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  DatePickerComponent,
  FormGroup,
  IconCheckmark,
  IconClear,
  Select,
  SelectOptionType,
  Panel,
  Loader,
  Push,
  SimpleTable as Table,
} from '@mosru/esz_uikit';
import DropDown from '../../../../components/drop-down';
import { PlaceOfWork, Teacher } from '../../../../types/teacher';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import teacherApi from '../../../../lib/api/teacher';
import lookupApi from '../../../../lib/api/lookup';
import { TeacherContext } from '..';
import { isError } from '../helpers';
import { hasGeneralAccess } from '../../../../lib/utils';
import { generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import organizationApi from '../../../../lib/api/organization';

const emptyPlaceOfWorks: PlaceOfWorkId = {
  id: '0',
  finishDate: '',
  isArchive: false,
  organizationId: 0,
  organizationName: '',
  personId: null,
  personPositionId: 0,
  personPositionName: '',
  startDate: '',
};

type Props = {
  values: Teacher;
  placeOfWork: PlaceOfWork[] | undefined;
  setPlaceOfWork: (value: PlaceOfWork[]) => void;
  isAcceptedEdit: boolean;
};

type PlaceOfWorkId = PlaceOfWork & { id: string };
type TypeDate = Date | [Date, Date | null] | null;

type ValidationDate = {
  startDate: string;
  finishDate: string;
  compareOrganization: boolean;
};

function filterTableData(placeOfWork: PlaceOfWork[] | undefined) {
  if (!placeOfWork) {
    return [];
  }
  return placeOfWork.map((item) => {
    return {
      id: `${item.organizationId}${item.personId}${Math.random()}`,
      ...item,
    };
  });
}

const textError = {
  minDate: 'Введённая дата является слишком маленькой',
  maxDate: 'Введённая дата является слишком большой',
  startDate: 'Дата начала не может быть позже даты окончания',
  finishDate: 'Дата окончания не может быть раньше даты начала',
};

const PlaceOfWorks = ({ values, isAcceptedEdit, placeOfWork, setPlaceOfWork }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { loading } = useContext(TeacherContext);

  const [editObj, setEditObj] = useState<PlaceOfWorkId | null>(null);

  const [tableData, setTableData] = useState<PlaceOfWorkId[]>(filterTableData(placeOfWork));

  const [organization, setOrganization] = useState<SelectOptionType | null>({ label: '', value: 0 });

  const [validate, dispatch] = useReducer<Reducer<ValidationDate, Partial<ValidationDate>>>(
    (state, newState) => ({ ...state, ...newState }),
    { startDate: '', finishDate: '', compareOrganization: true }
  );
  const [keyStartDate, setKeyStartDate] = useState<number>(0);
  const [keyFinishDate, setKetFinishDate] = useState<number>(0);
  const disabledNewPlaceWork = tableData.every((item) => Number(item.id) > 0);

  const isNameEmpty = !(values.firstName && values.lastName);

  const filterTable = () =>
    setTableData(filterTableData(tableData.filter((item: PlaceOfWorkId) => Number(item.id) > 0)));

  // При отсутствии условий AdminEdit, OIV_dep предзаполняется наименованием организации, к которой прикреплен пользователь без возможности редактирования.
  const access =
    !hasGeneralAccess(userProfile, generalAccess.AdminEdit) &&
    !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);

  useEffect(() => {
    const fetch = async () => {
      if (userProfile.organizationId) {
        const response = await organizationApi.getShortOrganization(userProfile.organizationId);
        if (isAcceptedEdit) {
          setOrganization({ label: response?.organizationName, value: userProfile.organizationId });
        } else {
          setOrganization(null);
        }
      }
    };

    fetch();
  }, [isAcceptedEdit, userProfile.organizationId]);

  const newPlaceOfWork = async (value: any) => {
    if (placeOfWork) {
      const allOrganization = [...placeOfWork, value];
      if (isNameEmpty) {
        isError(filterTable);
      } else {
        await teacherApi.addTeacherPosition(values.id, value);
        setTableData(filterTableData(allOrganization));
        setPlaceOfWork(allOrganization);
      }
    }
  };

  const updatePlaceOfWork = async (delPlaceWork: PlaceOfWork, value: PlaceOfWork) => {
    const searchOrganization = tableData.find((item: PlaceOfWork) => {
      if (delPlaceWork.personPositionId === 0) {
        return null;
      } else {
        return (
          item.personPositionId === delPlaceWork.personPositionId && item.organizationId === delPlaceWork.organizationId
        );
      }
    });

    if (searchOrganization) {
      const restPlaces =
        placeOfWork?.filter(
          (item: PlaceOfWork) =>
            item.personPositionId !== delPlaceWork.personPositionId ||
            item.organizationId !== delPlaceWork.organizationId
        ) ?? [];
      const allOrganization = [...restPlaces, value];

      if (isNameEmpty) {
        isError(filterTable);
      } else {
        await teacherApi.deleteTeacherPosition(values.id, delPlaceWork);
        await teacherApi.addTeacherPosition(values.id, value);
        setTableData(filterTableData(allOrganization));
        setPlaceOfWork(allOrganization);
      }
    } else {
      newPlaceOfWork(value);
    }
  };

  const deletePlaceWork = async (delPlaceWork: PlaceOfWork) => {
    if (isNameEmpty) {
      isError(filterTable);
    } else {
      await teacherApi.deleteTeacherPosition(values.id, delPlaceWork);
      const restPlaces =
        placeOfWork?.filter(
          (item: PlaceOfWork) =>
            item.personPositionId !== delPlaceWork.personPositionId ||
            item.organizationId !== delPlaceWork.organizationId
        ) ?? [];
      setTableData(filterTableData(restPlaces));
      setPlaceOfWork(restPlaces);
    }
  };

  const onChangeDate = (value: TypeDate, name: 'startDate' | 'finishDate') => {
    if (!value) {
      dispatch({ [name]: '' });
      name === 'startDate' ? dispatch({ finishDate: '' }) : dispatch({ startDate: '' });

      setEditObj((prevState: PlaceOfWorkId | null) => (prevState ? { ...prevState, [name]: value } : prevState));
    }

    if (value && !Array.isArray(value)) {
      const minDate = moment(value).isAfter('1910-01-01');
      const maxDate = moment('2050-01-02').isAfter(value);

      const condition = minDate && maxDate;

      const date = moment(value).format();

      setEditObj((prevState: PlaceOfWorkId | null) =>
        prevState ? { ...prevState, [name]: date } : { ...emptyPlaceOfWorks, [name]: date }
      );

      const startDate = moment(editObj?.startDate);
      const finishDate = moment(editObj?.finishDate);

      const val = moment(value);

      if (name === 'startDate') {
        if (!condition) {
          if (!minDate) {
            dispatch({ startDate: textError.minDate });
          } else if (!maxDate) {
            dispatch({ startDate: textError.maxDate });
          }
        } else {
          const check = !editObj?.finishDate || (moment(finishDate).isAfter(val) && !moment(finishDate).isSame(val));
          dispatch({ startDate: check ? '' : textError.startDate });

          if (moment(val).isBefore(finishDate)) {
            dispatch({ finishDate: '' });
          }
        }
      }

      if (name === 'finishDate') {
        if (!condition) {
          if (!minDate) {
            dispatch({ finishDate: textError.minDate });
          } else if (!maxDate) {
            dispatch({ finishDate: textError.maxDate });
          }
        } else {
          const check = !editObj?.startDate || (moment(startDate).isBefore(val) && !moment(startDate).isSame(val));
          dispatch({ finishDate: check ? '' : textError.finishDate });

          if (moment(val).isAfter(startDate)) {
            dispatch({ startDate: '' });
          }
        }
      }
    }
  };

  const submit = (item: PlaceOfWorkId) => {
    if (editObj) {
      if ((editObj.organizationId || organization?.value) && editObj.personPositionId) {
        const send = {
          ...editObj,
          id: item.id,
          organizationId: access ? organization?.value : editObj.organizationId,
          organizationName: access ? organization?.label : editObj.organizationName,
          isEditable: false,
        };
        // @ts-ignore
        updatePlaceOfWork(item, send);
      } else {
        filterTable();
      }
      setEditObj(null);
    }
  };

  const isDisabledSubmit = () => {
    const org = access ? organization?.value : editObj?.organizationId;

    return !(
      org &&
      editObj?.personPositionId &&
      !validate.startDate &&
      !validate.finishDate &&
      validate.compareOrganization
    );
  };

  const selectOrganization = (item: PlaceOfWork) => {
    if (access && organization) {
      return { label: organization.label, value: organization.value };
    } else {
      if (!editObj?.organizationName) {
        return null;
      }

      if (editObj?.organizationId) {
        return { label: editObj?.organizationName, value: editObj?.organizationId || 0 };
      } else {
        return { label: item?.organizationName, value: item?.organizationId };
      }
    }
  };

  const selectPosition = (item: PlaceOfWork) => {
    const personPositionId = editObj?.personPositionId;

    const labelValue = (element: PlaceOfWork) => ({
      label: element?.personPositionName,
      value: element?.personPositionId || 0,
    });

    if (!personPositionId) {
      return null;
    }

    if (editObj && personPositionId) {
      return labelValue(editObj);
    }

    return labelValue(item);
  };

  const newPlace = () => {
    const newData: PlaceOfWorkId = {
      id: '0',
      isArchive: false,
      organizationId: 0,
      personPositionId: 0,
      organizationName: '',
      personPositionName: '',
      isEditable: true,
    };
    dispatch({ finishDate: '', startDate: '' });
    const newTableData = new Array(newData).concat(tableData);
    setTableData(newTableData);
    setEditObj(newData);
    setKeyStartDate(Math.random());
    setKetFinishDate(Math.random());
  };

  const handleEdit = (value?: PlaceOfWorkId) => {
    setEditObj(value || null);
    filterTable();
    dispatch({ finishDate: '', startDate: '' });
    dispatch({ compareOrganization: true });
    setKeyStartDate(Math.random());
    setKetFinishDate(Math.random());
  };

  const validateOrganization = useCallback(
    (positionId, organizationId) => {
      // нельзя выбрать одинаковую должность в одной организации
      const data = editObj === null ? tableData : [...tableData, editObj];
      const checkMatchesPerson = data.filter(
        (item: PlaceOfWork) => item.organizationId === organizationId && item.personPositionId === positionId
      );

      if (checkMatchesPerson.length) {
        dispatch({ compareOrganization: false });
      } else {
        dispatch({ compareOrganization: true });
      }
    },
    [editObj, tableData]
  );

  return (
    <Panel
      title={() => (
        <>
          Места работы
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableData.length}
          </span>
        </>
      )}
      headingControl={() =>
        isAcceptedEdit ? (
          <button type="button" disabled={!disabledNewPlaceWork} onClick={() => newPlace()} className="icon-group">
            <span className="icon-group__icon">
              <IconPlus />
            </span>
            <span className="icon-group__text color-primary">
              <b>Добавить место работы</b>
            </span>
          </button>
        ) : null
      }
    >
      {loading ? (
        <div className="flex justify-center flex-column">
          <Push orientation="vertical" size={20} />
          <Loader roller small />
          <Push orientation="vertical" size={20} />
        </div>
      ) : tableData.length ? (
        <Table
          data={tableData}
          overflow
          columns={[
            {
              dataIndex: 'organizationName',
              hiddenSort: true,
              title: 'Организация',
              width: '30%',
              render: (item: any) =>
                editObj?.id === item.id ? (
                  <FormGroup label="">
                    <Select
                      name="organization"
                      size="small"
                      isSearchable
                      disabled={access}
                      placeholder="Организация..."
                      loadOptions={async (query) => await lookupApi.getOrganization(query)}
                      options={[]}
                      value={selectOrganization(item)}
                      onChange={(selectedOption) => {
                        const option = selectedOption as SelectOptionType;

                        const value = (option && option.value) || null;
                        const label = (option && option.label) || null;

                        setEditObj((prev: any) => {
                          const next = prev ? { ...prev } : emptyPlaceOfWorks;
                          next.organizationId = value;
                          next.organizationName = label;
                          return next;
                        });
                        validateOrganization(editObj?.personPositionId, value);
                      }}
                    />
                  </FormGroup>
                ) : (
                  item.organizationName
                ),
            },
            {
              hiddenSort: true,
              dataIndex: 'personPositionName',
              title: 'Должность',
              width: '20%',
              render: (item: any) =>
                editObj?.id === item.id ? (
                  <FormGroup
                    error={
                      !validate.compareOrganization
                        ? 'Данное рабочее место уже есть в списке рабочих мест преподавателя'
                        : ''
                    }
                    label=""
                  >
                    <Select
                      name="personPosition"
                      size="small"
                      isSearchable
                      error=""
                      placeholder="Должность..."
                      loadOptions={async (query) => await lookupApi.getPersonPosition(query)}
                      options={[]}
                      value={selectPosition(item)}
                      onChange={(selectedOption) => {
                        const option = selectedOption as SelectOptionType;

                        const value = (option && option.value) || null;
                        const label = (option && option.label) || null;

                        setEditObj((prev: any) => {
                          const next = prev ? { ...prev } : emptyPlaceOfWorks;
                          next.personPositionId = value;
                          next.personPositionName = label;
                          return next;
                        });
                        validateOrganization(value, editObj?.organizationId);
                      }}
                    />
                  </FormGroup>
                ) : (
                  item.personPositionName
                ),
            },
            {
              dataIndex: 'startDate',
              title: 'Дата начала ',
              hiddenSort: true,
              width: '20%',
              render: (item: any) =>
                editObj?.id === item.id ? (
                  <FormGroup label="" error={!validate.startDate ? '' : validate.startDate}>
                    <DatePickerComponent
                      name="startDate"
                      size="small"
                      key={keyStartDate}
                      placeholder="ДД.ММ.ГГГГ"
                      startDate={item.startDate && new Date(item.startDate)}
                      onChange={(value) => onChangeDate(value, 'startDate')}
                      popperPlacement="bottom-end"
                    />
                  </FormGroup>
                ) : item.startDate ? (
                  moment(item.startDate).format('DD.MM.yyyy')
                ) : (
                  ''
                ),
            },
            {
              dataIndex: 'finishDate',
              title: 'Дата окончания ',
              hiddenSort: true,
              width: '20%',
              render: (item: any) =>
                editObj?.id === item.id ? (
                  <FormGroup label="" error={!validate.finishDate ? '' : validate.finishDate}>
                    <DatePickerComponent
                      name="finishDate"
                      size="small"
                      key={keyFinishDate}
                      placeholder="ДД.ММ.ГГГГ"
                      startDate={item.finishDate && new Date(item.finishDate)}
                      onChange={(value) => onChangeDate(value, 'finishDate')}
                      popperPlacement="bottom-end"
                    />
                  </FormGroup>
                ) : item.finishDate ? (
                  moment(item.finishDate).format('DD.MM.yyyy')
                ) : (
                  ''
                ),
            },
            {
              title: null,
              width: '10%',
              hiddenSort: true,
              render: (item: any) =>
                isAcceptedEdit ? (
                  <div className="teacher-table-comment">
                    <div className="teacher-table-comment__controls">
                      {editObj?.id === item.id ? (
                        <>
                          <Button iconLeft={() => <IconClear />} border size="small" handleClick={handleEdit} />
                          <Push size={12} orientation="horizontal" />
                          <Button
                            border
                            success
                            size="small"
                            disabled={isDisabledSubmit()}
                            handleClick={() => submit({ ...item, id: `${item.organizationId}${Math.random()}` })}
                            iconLeft={() => <IconCheckmark />}
                          />
                        </>
                      ) : (
                        <>
                          <DropDown
                            component={() => (
                              <span className="drop-down-btn-round">
                                <IconDots />
                              </span>
                            )}
                          >
                            <div className="drop-down-panel">
                              <div className="drop-down-panel__list">
                                <button
                                  type="button"
                                  onClick={() => handleEdit(item)}
                                  className="drop-down-panel__list-item"
                                >
                                  <IconEdit />
                                  Редактировать
                                </button>
                                <button
                                  type="button"
                                  onClick={() => deletePlaceWork(item)}
                                  className="drop-down-panel__list-item"
                                >
                                  <IconRemove />
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </DropDown>
                          <Push size={2} orientation="horizontal" />
                        </>
                      )}
                    </div>
                  </div>
                ) : null,
            },
          ]}
        />
      ) : (
        <div className="teacher-no-data">Не добавлено ни одного места работы...</div>
      )}
      <Push size={12} />
    </Panel>
  );
};

export default PlaceOfWorks;
