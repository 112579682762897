import { Person } from '../../types/like-modal';
import { MatchLevelEnum, MatchTypeEnum } from '../../mock-data/like-modal-enum';

export const editSex = (value: number) => (value === 1 ? 'муж' : 'жен');

export const transformDate = (date: string) => {
  return date.split('-').reverse().join('.');
};

export function checkMatch(value: number) {
  return value < 70 ? MatchLevelEnum.low : value < 95 ? MatchLevelEnum.medium : MatchLevelEnum.high;
}

export function useDegree(list: any) {
  if (!list.length) {
    return MatchTypeEnum.noMatch;
  }
  if (list.length === 1) {
    return list.some((item: Person) => checkMatch(item.degree) === MatchLevelEnum.low)
      ? MatchTypeEnum.oneLow
      : list.some((item: Person) => checkMatch(item.degree) === MatchLevelEnum.medium)
      ? MatchTypeEnum.oneMedium
      : MatchTypeEnum.oneHigh;
  }
  if (
    list.some(
      (item: Person) =>
        checkMatch(item.degree) === MatchLevelEnum.high || checkMatch(item.degree) === MatchLevelEnum.medium
    )
  ) {
    return MatchTypeEnum.severalWithHigh;
  }
  return MatchTypeEnum.several;
}

export const hiddenSymbol = (type: string, value: string | undefined) => {
  if (!value) {
    return;
  }

  const str = [...value];

  const hiddenSymbolSnils = () => {
    const result = [
      ...`${str[0] + str[1] + str[2]}-${str[3]}${str[4]}${str[5]}-${str[6]}${str[7]}${str[8]} ${str[9]}${str[10]}`,
    ];

    result[5] = '*';
    result[6] = '*';
    result[8] = '*';
    return result;
  };

  const hiddenSymbolNumberPasswort = () => {
    const result = [...str];
    result[2] = '*';
    result[3] = '*';
    return result.join('');
  };

  switch (type) {
    case 'snils':
      return hiddenSymbolSnils();

    case 'number':
      return hiddenSymbolNumberPasswort();
    default:
      break;
  }
};
