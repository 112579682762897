import React from 'react';
import { ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  title: string;
  onCloseHandler: () => void;
};

const LoadingModal = ({ show, onCloseHandler, title }: Props) => {
  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle={title}
        hideButton
        renderComponent={() => (
          <div className="loading__modal">
            <Push size={6} />

            <div className="loading__modal--progress">
              <span className="linear">
                <span className="linear__progress" />
              </span>
            </div>
            <Push size={16} />
            <div data-test="loading-text">Пожалуйста, не перезагружайте страницу.</div>
            <span data-test="loading-text">
              Если вам необходимо продолжить работу с сервисом, вы можете открыть его в новом окне{' '}
              <a data-test="loading-btn-link" target="_blank" href="/">
                по ссылке
              </a>
            </span>
            <Push size={12} />
          </div>
        )}
      />
    </Modal>
  );
};

export default LoadingModal;
