import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, ButtonGroup, IconClock, IconPlus, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import Details from './details';
import { ReactComponent as IconWarning } from '../../../assets/images/icons/clock-warning.svg';
import { ReactComponent as IconHard } from '../../../assets/images/icons/hard-drive.svg';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import ChangeHistory from '../../../components/change-history';
import { ReactComponent as IconError } from '../../../assets/images/icons/error.svg';
import { RequestData } from '../../../types/requests';
import { formatDate, formatDateAndTime } from '../../../lib/utils/date';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { routes } from '../../../config/constants';
import requestsApi from '../../../lib/api/requests';
import { generateLink, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { ApplicationRegistrationModal } from '../components/modals';
import { RequestContext } from '../../../lib/utils/requests';

type RequestDayCareProps = {
  requestData: RequestData;
  updateRequest: () => void;
  setRequestErrorMessage: Dispatch<SetStateAction<string>>;
  requestErrorMessage: string;
};

const RequestDayCare = ({
  requestData,
  updateRequest,
  setRequestErrorMessage,
  requestErrorMessage,
}: RequestDayCareProps) => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const [showModal, setShowModal] = useState(false);

  const { adminView, routeBack } = useContext(RequestContext);

  const [editMode, setEditMode] = useState<string | null>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: (
          <Details
            data={requestData}
            updateRequest={updateRequest}
            requestErrorMessage={requestErrorMessage}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name="сведения о заявлении"
            extendEntityGuid={requestData?.extendEntityGuid}
            entityTypes={[EntityTypeEnum.Request]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestData, updateRequest, editMode, setEditMode]
  );

  const roleAccess =
    hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
    hasGeneralAccess(userProfile, generalAccess.AdminView) ||
    (hasAccessObjectAny(userProfile, [accessObject.RequestDayCare], accessAction.Create) &&
      !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV));

  const checkOnRequestStatusId =
    roleAccess &&
    (requestData.requestStatusId === RequestStatusEnum.New ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.Archive ||
      requestData.requestStatusId === RequestStatusEnum.Draft ||
      requestData.requestStatusId === RequestStatusEnum.Excluded ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded);

  // Добавить проверку: организация ученика, указанная в реестре контингента, не соответствует организации пользователя
  const checkisCopyRequestAllowed = requestData?.isCopyRequestAllowed;

  const checkShowCreateFromCurrent = checkOnRequestStatusId && checkisCopyRequestAllowed && !adminView;

  const addRequestInArchive = async () => {
    await requestsApi.setRequestStatus(
      requestData?.id,
      requestData?.educationTypeId,
      requestData?.requestStatusId,
      RequestStatusEnum.Archive
    );

    updateRequest();
  };

  const checkAddArchiveButton =
    hasAccessObjectAny(userProfile, [accessObject.RequestDayCare], accessAction.Archive) &&
    (requestData.requestStatusId === RequestStatusEnum.Draft || requestData.requestStatusId === RequestStatusEnum.New);

  const submitRegistration = async () => {
    try {
      await requestsApi.setRequestStatus(
        requestData.id,
        requestData.educationTypeId,
        requestData.requestStatusId,
        RequestStatusEnum.UnderConsideration
      );

      updateRequest();
      setShowModal(false);
    } catch (error) {
      const textError = error?.data?.messages.length > 1 ? error?.data?.messages?.join(' ;') : error?.data?.messages[0];
      if (textError) {
        setRequestErrorMessage(textError);
        updateRequest();
      }

      setShowModal(false);
    }
  };

  const showNumberIcon =
    requestData.requestStatusId === RequestStatusEnum.New ||
    requestData.requestStatusId === RequestStatusEnum.Archive ||
    requestData.requestStatusId === RequestStatusEnum.Draft;

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(requestData.dateRequest)}
              <Push size={20} orientation="horizontal" />
              Источник: {requestData.requestSourceName}
            </div>
            <Push size={12} />
          </>
        }
        buttonBack
        routeBack={routeBack}
        expansion={
          <div className="flex items-center">
            <Favorite
              entityId={requestData.id}
              typeEntity="заявление"
              currentEntityType={`${!showNumberIcon ? '№' : ''}${requestData.requestNumber || ''}`}
              entityTypeId={EntityTypeEnum.Request}
            />
            <Push size={20} orientation="horizontal" />
            <Badge text={requestData.requestStatusName} {...getRequestStatusColor(requestData.requestStatusId)} />
          </div>
        }
        title={`Заявление ${!showNumberIcon ? '№' : ''}${requestData.requestNumber || ''}`}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Сведения о заявлении' },
        ]}
        activeTab={activeTab}
        tabs={tabs}
        controlsTopRight={
          <ButtonGroup>
            {checkShowCreateFromCurrent ? (
              <Button
                label="Создать на основе текущего"
                iconLeft={() => <IconPlus />}
                size="small"
                onClick={() => window.open(generateLink(routes.createRequestBasedOn, { id: requestData.id }), '_blank')}
              />
            ) : null}
            {checkAddArchiveButton && (
              <Button
                onClick={() => {
                  addRequestInArchive();
                }}
                label="В архив"
                iconLeft={() => <IconHard />}
                size="small"
              />
            )}
          </ButtonGroup>
        }
      />

      {requestData.requestStatusId === RequestStatusEnum.ForInclude && !editMode && (
        <>
          <Push size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconError className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <div className="color-danger-dark">
                    Зачисление / отчисление по текущему Заявлению должно быть произведено до{' '}
                    {formatDateAndTime(requestData.actionDeadline)}. В противном случае Заявление станет просроченным.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {requestData.requestStatusId === RequestStatusEnum.ForInclude && !editMode && (
        <>
          <Push size={12} />
          <div className="infobox infobox--warning">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconWarning className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <span className="color-warning-dark font-weight-bold">
                    Дата явки заявителя для заключения договора: {formatDate(requestData.actionDeadline)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {requestData.requestStatusId === RequestStatusEnum.UnderConsideration && !editMode && (
        <>
          <Push size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <span className="flex-none color-danger-dark">
                    <IconClock />
                  </span>
                  <Push size={8} orientation="horizontal" />
                  <div className="color-danger-dark">
                    <span className="font-weight-bold">Срок обработки заявления истекает</span>{' '}
                    {formatDateAndTime(requestData.actionDeadline)}. Необходимо перевести текущее заявление в статус
                    “Ожидание прихода заявителя для заключения договора”. Для этого нажмите кнопку “Рассмотрено”.
                  </div>
                </div>
              </div>
              <div className="infobox__control">
                <Button
                  size="small"
                  primary
                  label="Рассмотрено"
                  onClick={async () => {
                    await requestsApi.setRequestStatus(
                      requestData.id,
                      requestData.educationTypeId,
                      requestData.requestStatusId,
                      RequestStatusEnum.ForInclude
                    );
                    updateRequest();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {requestData.requestStatusId === RequestStatusEnum.New && !editMode && (
        <>
          <Push size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <span className="flex-none color-danger-dark">
                    <IconClock />
                  </span>
                  <Push size={8} orientation="horizontal" />
                  <div className="color-danger-dark">
                    <span className="font-weight-bold">Зарегистрируйте заявление!</span> Автоматическая архивация
                    текущего заявления будет произведена {formatDateAndTime(requestData.actionDeadline)}
                  </div>
                </div>
              </div>
              <div className="infobox__control">
                <Button
                  size="small"
                  primary
                  label="Зарегистрировать"
                  onClick={() => setShowModal(true)}
                  disabled={Boolean(requestData?.dayCareRegisterRequestMessage)}
                />
              </div>
            </div>
            <ApplicationRegistrationModal
              showModal={showModal}
              setShowModal={setShowModal}
              handleSubmit={submitRegistration}
              text="Хотите зарегистрировать заявление?"
              modalTitle="Регистрация заявления"
            />
          </div>
        </>
      )}

      {tabs.find((item) => item.label === activeTab)?.component}
    </>
  );
};

export default RequestDayCare;
