import { useContext } from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { ServiceClassContext } from '../../service-class';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const WarningScheduleModal = ({ show, onCloseHandler, setEditModeParent, setEditMode }: Props) => {
  const { setIsModalSchedule } = useContext(ServiceClassContext);
  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle="Проверьте время проведения занятий"
        renderComponent={() => (
          <>
            <Push orientation="vertical" size={16} />
            <div>
              Обратите внимание, что даты начала и окончания периодов, созданных в блоке "Время проведения занятий",
              выходят за пределы дат начала и окончания занятий. Проверьте и измените даты периодов в блоке "Время
              проведения занятий".
            </div>
            <Push orientation="vertical" size={16} />
          </>
        )}
        controls={() => (
          <>
            <Button border primary label="Отмена" onClick={onCloseHandler} />
            <Push orientation="horizontal" size={12} />
            <Button
              danger
              label="Изменить время проведения занятий"
              handleClick={() => {
                setEditMode(true);
                if (setIsModalSchedule) {
                  setIsModalSchedule(true);
                }
                setEditModeParent && setEditModeParent('schedule');
                document.documentElement.style.overflowY = 'auto'; // todo нужно разобраться почему нет скролла
              }}
            />
          </>
        )}
      />
    </Modal>
  );
};

export default WarningScheduleModal;
