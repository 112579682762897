import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeading from '../../../../components/header/page-heading';
import { routes } from '../../../../config/constants';
import DayCareCreateDetails from './details';
import { getHeaderLink } from '../../utils';
import { CreateServiceContext } from '../../create';

const DayCareCreate = () => {
  const { currentEducationType, educationTypes, type, serviceData } = useContext(CreateServiceContext);

  const historyRouter = useHistory();

  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={() => historyRouter.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
        title="Программа по уходу и присмотру"
        sections={[
          { title: 'Главная', link: routes.main },
          {
            title: 'Программы по уходу и присмотру за детьми школьного возраста',
            link: getHeaderLink(educationTypes, type, currentEducationType),
          },
          { title: 'Описание программы по уходу и присмотру за детьми школьного возраста' },
        ]}
      />
      <DayCareCreateDetails />
    </>
  );
};

export default DayCareCreate;
