import React, { SetStateAction, useContext, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType, Switcher, Push } from '@mosru/esz_uikit';
import FormikInput from '../../../../components/formik/formik-input';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikCheckbox from '../../../../components/formik/formik-checkbox';
import FormikCheckboxGroup from '../../../../components/formik/formik-checkbox-group';
import { contractDeclineReasonOptions } from '../../../../mock-data/contract-decline-reason';
import { ContractStatusEnum } from '../../../../mock-data/contract-status-enum';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../../types/requests';
import { AuthorizationData, generalAccess } from '../../../../types/authorization-data';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { clearField } from '../../../../lib/utils/requests';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { EducationTypeEnum } from '../../../../types/education-type';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  setTableOptions: React.Dispatch<SetStateAction<SearchRequestsTableOptions>>;
  infoChild: { value: number | null; label: string | number }[];
  privilegeStatusList: SelectOptionType[];
  period: { value: boolean; label: string | number }[];
  isArtHouseSportEducation: boolean;
  userProfile: AuthorizationData;
  contractStatusData: SelectOptionType[];
  requestStatusData: SelectOptionType[];
  requestSourceData: SelectOptionType[];
  privilegiesData: SelectOptionType[];
  allowedEducationTypeId: number;
};

const StatementChild: React.FC<Props> = ({
  setTableOptions,
  infoChild,
  privilegeStatusList,
  period,
  isArtHouseSportEducation,
  userProfile,
  contractStatusData,
  requestStatusData,
  requestSourceData,
  privilegiesData,
  allowedEducationTypeId,
}) => {
  const { values, setFieldValue, validateField } = useFormikContext<SearchRequestsInitialFormData>();

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  // показывать статус договора
  const showContractStatus = useMemo(() => {
    return hasAccessObjectAny(userProfile, [accessObject.RequestDOGM], accessAction.ViewRegistry) || isAdmin;
  }, [isAdmin, userProfile]);

  // показывать преподавателя
  const canShowTeacher = useMemo(() => {
    return (
      hasAccessObjectAny(userProfile, [accessObject.RequestSport], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestArtHouse], accessAction.ViewRegistry) ||
      isAdmin
    );
  }, [isAdmin, userProfile]);

  useEffect(() => {
    clearField(values.requestDate, 'requestDate', validateField);
  }, [validateField, values.requestDate]);

  // Дефолтное значение для полей "Льготная категория" и "Статус проверки МЭШ.Льготники"
  const defaultValuePrivilegeCategoryIdIsAdmin = isAdmin &&
    values.educationTypeId === EducationTypeEnum.ArtHouseEducation && { label: 'Все', value: null };

  const defaultValuePrivilegeCategoryIdOther =
    allowedEducationTypeId === EducationTypeEnum.ArtHouseEducation ? { label: 'Все', value: null } : null;

  const defaultValuePrivilegeCategoryId = isAdmin
    ? defaultValuePrivilegeCategoryIdIsAdmin
    : defaultValuePrivilegeCategoryIdOther;

  const { privilegeCategoryKey, privilegeStatusKey } = useContext(SearchKeyContext);

  return (
    <div className="requests-search-grid">
      <div>
        <FormikFormGroup name="requestDate" label="Дата заявления">
          <FormikDatePicker
            maxDate={new Date()}
            rangePicker
            placeholder="ДД.ММ.ГГГГ—ДД.ММ.ГГГГ"
            size="small"
            name="requestDate"
          />
        </FormikFormGroup>
        <Push size={16} />
        <FormikFormGroup name="requestNumber" label="Номер заявления">
          <FormikInput name="requestNumber" placeholder="Введите..." size="small" />
        </FormikFormGroup>
        <Push size={16} />
        <FormikFormGroup name="requestStatus" label="Статус заявления">
          <FormikSelect
            name="requestStatusId"
            size="small"
            isSearchable
            options={requestStatusData}
            defaultValue={{ value: '', label: 'Все' }}
          />
        </FormikFormGroup>
        <Push size={16} />
        <FormikFormGroup name="requestSourceId" label="Источник заявления">
          <FormikSelect
            name="requestSourceId"
            size="small"
            isSearchable
            options={requestSourceData}
            defaultValue={{ value: '', label: 'Все' }}
          />
        </FormikFormGroup>
        {showContractStatus && (
          <>
            <Push size={16} />
            <FormikFormGroup name="contractStatus" label="Статус договора">
              <FormikSelect
                name="contractStatusId"
                size="small"
                isSearchable
                options={contractStatusData}
                defaultValue={{ value: '', label: 'Все' }}
                selectedValue={(option) => {
                  setFieldValue(
                    'contractDeclineReasonId',
                    option?.value === ContractStatusEnum.Terminated ? null : undefined
                  );
                }}
              />
            </FormikFormGroup>
            {values.contractStatusId === ContractStatusEnum.Terminated && (
              <>
                <Push size={16} />
                <FormikFormGroup name="contractDeclineReasonId" label="Сторона расторжения договора">
                  <FormikSelect
                    name="contractDeclineReasonId"
                    size="small"
                    isSearchable
                    options={contractDeclineReasonOptions}
                    defaultValue={{ value: null, label: 'Все' }}
                  />
                </FormikFormGroup>
              </>
            )}
          </>
        )}
        {canShowTeacher && (
          <>
            <Push size={16} />
            <FormikFormGroup name="person" label="Преподаватель">
              <FormikInput name="person" placeholder="Введите..." size="small" />
            </FormikFormGroup>
          </>
        )}
      </div>
      <div>
        <FormikFormGroup name="" label="ФИО ребёнка">
          <div className="requests-child-name">
            <FormikInput
              name="childLastName"
              placeholder="Введите фамилию..."
              size="small"
              value={values.childLastName}
            />
            <FormikInput
              name="childFirstName"
              placeholder="Введите имя..."
              size="small"
              value={values.childFirstName}
            />
            <FormikInput
              name="childMiddleName"
              placeholder="Введите отчество..."
              size="small"
              value={values.childMiddleName}
            />
          </div>
        </FormikFormGroup>
        {!isArtHouseSportEducation && (
          <>
            <Push size={16} />
            <FormikFormGroup name="" label="Сведения о ребёнке в Контингенте">
              <Switcher size="small" btnWidthAuto>
                {infoChild.map((item) => (
                  <button
                    key={item.value}
                    type="button"
                    onClick={() => setFieldValue('contingentLinkTypeId', item.value)}
                    className={(values.contingentLinkTypeId ?? null) === item.value ? 'active' : ''}
                  >
                    {item.label}
                  </button>
                ))}
              </Switcher>
            </FormikFormGroup>
          </>
        )}
        <Push size={16} />
        <FormikFormGroup name="" label="Данные за период">
          <Switcher size="small" btnWidthAuto>
            {period.map((item) => (
              <button
                key={item.value?.toString()}
                type="button"
                onClick={() => {
                  setTableOptions((prev): any => {
                    return { ...prev, isActualIndex: item.value };
                  });
                  setFieldValue('isActualRequestIndex', item.value);
                }}
                className={(values.isActualRequestIndex ?? false) === Boolean(item.value) ? 'active' : ''}
              >
                {item.label}
              </button>
            ))}
          </Switcher>
        </FormikFormGroup>
        {(hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) || isAdmin) && (
          <>
            <Push size={16} />

            <FormikFormGroup name="privilegeCategoryId" label="Льготная категория">
              <FormikSelect
                name="privilegeCategoryId"
                key={privilegeCategoryKey}
                size="small"
                isSearchable
                options={privilegiesData}
                defaultValue={defaultValuePrivilegeCategoryId || null}
                placeholder={
                  isAdmin && values.educationTypeId === EducationTypeEnum.ArtHouseEducation
                    ? 'Начните вводить наименование льготной категории...'
                    : `Выберите тип услуги "Дома творчества"`
                }
                disabled={isAdmin && values.educationTypeId !== EducationTypeEnum.ArtHouseEducation}
              />
            </FormikFormGroup>

            <Push size={16} />
            <FormikFormGroup name="privilegeStatusId" label="Статус проверки МЭШ.Льготники">
              <FormikSelect
                name="privilegeStatusId"
                key={privilegeStatusKey}
                size="small"
                options={privilegeStatusList}
                defaultValue={defaultValuePrivilegeCategoryId || null}
                placeholder={
                  isAdmin && values.educationTypeId === EducationTypeEnum.ArtHouseEducation
                    ? ''
                    : `Выберите тип услуги "Дома творчества"`
                }
                disabled={isAdmin && values.educationTypeId !== EducationTypeEnum.ArtHouseEducation}
              />
            </FormikFormGroup>
          </>
        )}

        <Push size={16} />
        <FormikFormGroup name="" label="Просроченные">
          <div className="requests-search-overdue">
            <FormikCheckboxGroup key="isOverdueToday" label="на сегодня" name="isOverdueToday" size="small" background>
              <FormikCheckbox size="small" name="isOverdueToday" />
            </FormikCheckboxGroup>
            <FormikCheckboxGroup
              key="isOverdueTomorrow"
              label="на завтра"
              name="isOverdueTomorrow"
              size="small"
              background
            >
              <FormikCheckbox size="small" name="isOverdueTomorrow" />
            </FormikCheckboxGroup>
          </div>
        </FormikFormGroup>
      </div>
    </div>
  );
};

export default StatementChild;
