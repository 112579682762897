import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, RadioGroup, Radio, Heading, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconLogin } from '../../assets/images/login/logo.svg';
import { aupdApiUrl, aupdTokenFieldName } from '../../config/constants';
import history from '../../history';
import { authApi } from '../../lib/api';
import LoginLayoutLoader from './loader';
import LoginLayoutError from './error';
import { onSignIn } from '../../redux/thunks/auth';
import { AppState } from '../../redux/types/state';
import { aupdOrganizationsSelector, userProfileSelector } from '../../redux/selectors';
import { moduleName as appModule } from '../../redux/ducks/app';
import { getAupdOrganizations } from '../../redux/ducks/aupd-organizations';
import { getCookie, redirectToLogin } from '../../lib/utils';

const LoginLayout = () => {
  const dispatch = useDispatch();
  const { appLoading: initLoading } = useSelector((state: AppState) => ({ appLoading: state[appModule].loading }));
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { aupdOrganizations } = useSelector((state: AppState) => ({
    aupdOrganizations: aupdOrganizationsSelector(state),
  }));
  const [authLoading, setAuthLoading] = useState(false);
  const [checkedLogin, setCheckedLogin] = useState<string | null>(null);
  const [error, setError] = useState(false);

  const aupdToken = useMemo(() => getCookie(aupdTokenFieldName), []);

  const loginAupd = useCallback(async () => {
    if (!checkedLogin || !aupdToken) {
      return;
    }
    try {
      setAuthLoading(true);
      const { token, isLoggedIn } = await authApi.loginAupd({ token: aupdToken, login: checkedLogin });
      if (isLoggedIn) {
        await dispatch(onSignIn(token));
      }
    } catch (err: any) {
      console.error(err.response);
      setError(true);
    } finally {
      setAuthLoading(false);
    }
  }, [checkedLogin, aupdToken, dispatch]);

  useEffect(() => {
    if (aupdOrganizations.length && !checkedLogin) {
      setCheckedLogin(aupdOrganizations[0].login);
    }
  }, [aupdOrganizations, checkedLogin]);

  useEffect(() => {
    if (aupdOrganizations.length === 1 && checkedLogin && aupdToken) {
      loginAupd();
    }
  }, [aupdOrganizations, checkedLogin, aupdToken, loginAupd]);

  useEffect(() => {
    (async function () {
      if (!aupdOrganizations.length && aupdToken && !error) {
        const organizations = await dispatch(getAupdOrganizations(aupdToken));
        if (!organizations.length) {
          setError(true);
        }
      }
    })();
  }, [aupdOrganizations, dispatch, aupdToken, error]);

  useEffect(() => {
    if (!initLoading && userProfile.token) {
      if (!aupdToken || !aupdOrganizations.length) {
        window.location.href = aupdApiUrl;
      }
    }
    if (!initLoading && !aupdToken) {
      redirectToLogin();
    }
  }, [initLoading, userProfile, aupdOrganizations, aupdToken]);

  return error ? (
    <LoginLayoutError />
  ) : initLoading || aupdOrganizations.length <= 1 ? (
    <LoginLayoutLoader />
  ) : (
    <div className="login-layout">
      <div className="login-layout__header">
        <IconLogin />
      </div>
      <div className="login-layout__body">
        <div className="login-layout-body">
          <Heading as="h3" label="Выбор учетной записи" />
          <Push />
          {aupdOrganizations.map((item, i) => (
            <Fragment key={item.login}>
              {i > 0 && <Push size={8} />}
              <RadioGroup
                itemsStart
                checked={checkedLogin === item.login}
                background
                label={
                  <>
                    {item.name && <div className="line-height-text">{item.name}</div>}
                    {item.organizationName && <div className="line-height-text">{item.organizationName}</div>}
                    {item.roleList.map((role) => (
                      <div key={role} className="color-gray-dark line-height-text">
                        {role}
                      </div>
                    ))}
                  </>
                }
                labelId={item.login}
                name="auth"
                onChange={() => {
                  setCheckedLogin(item.login);
                }}
                size="medium"
              >
                {(props) => <Radio {...props} checked={props.checked} labelId={props.labelId} />}
              </RadioGroup>
            </Fragment>
          ))}
          <Push size={20} />
          <Button label="Выбрать" onClick={loginAupd} primary fullWidth disabled={!checkedLogin} load={authLoading} />
          <Push size={16} />
          <Button
            label="Вернуться назад"
            onClick={() => {
              // @ts-ignore
              if (history.location.firstPage) {
                window.location.href = `${aupdApiUrl}/choice`;
              } else {
                history.goBack();
              }
            }}
            primary
            border
            fullWidth
          />
        </div>
      </div>
      <div className="login-layout__footer">
        <div className="login-layout-footer-nav">
          <div className="login-layout-footer-nav__item">
            <a href="tel:+74955393838">+7 (495) 539-38-38</a>
          </div>
          <div className="login-layout-footer-nav__item">
            <a href="mailto:dop_hd@mos.ru">dop_hd@mos.ru</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
