import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup, number, ValidationError } from 'yup';
import moment from 'moment';
import { Button, Checkbox, ModalPanel, Modal, SelectOptionType, Tooltip, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import FormikCheckboxGroup from '../../../../../components/formik/formik-checkbox-group';
import lookupApi from '../../../../../lib/api/lookup';
import DateField from '../../fields/add-plan/date';
import TimeField from '../../fields/add-plan/time';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { ScheduleData, ServiceData, TrainingGroupData, TrainingStageData } from '../../../../../types/service';
import { validationCheckDate } from '../../../../../lib/utils/validation';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { calcLearnEnd } from '../../../../../lib/utils/service';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';

type Props = {
  show: boolean;
  planData: TrainingGroupData | undefined;
  serviceData: ServiceData;
  onClose: () => void;
  postSubmit: (plan?: TrainingGroupData) => void;
};

const separator = (
  <div className="flex items-center flex-none table-row-item-height">
    <Push size={8} orientation="horizontal" />
    —
    <Push size={8} orientation="horizontal" />
  </div>
);

const TemplatePlanModal: React.FC<Props> = ({ show, planData, serviceData, onClose, postSubmit }) => {
  const [initialValues, setInitialValues] = useState<TrainingGroupData>({} as TrainingGroupData);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema(serviceData));
  const [formKey, setFormKey] = useState<number>(0);
  const [volumeKey, setVolumemKey] = useState<number>(Math.random());
  const [periodOptions, setPeriodOptions] = useState<SelectOptionType[]>([]);
  const getOptions = async (query: string): Promise<SelectOptionType[]> => {
    const currentOptions = await lookupApi.getTeachers(query, serviceData.info.organizationId);
    return currentOptions;
  };

  const submitTrainingGroup = async (plan: TrainingGroupData | undefined) => {
    if (plan) {
      if (plan.volume && plan.volume > 9999999999) {
        plan.volume = undefined;
      }

      if (!plan.id || plan.id === 0) {
        if (plan.scheduleList) {
          plan.scheduleList[0].trainingGroupId = 0;
        }
        await serviceTemplateApi.createTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.updateTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      }
    }
    postSubmit(plan);
  };

  useEffect(() => {
    if (serviceData.stage?.list?.length > 0) {
      setPeriodOptions(
        serviceData.stage.list.map((s) => {
          return { label: s.name, value: s.id || 0 };
        })
      );
    }
  }, [serviceData.stage]);

  useEffect(() => {
    if (planData) {
      setInitialValues(planData);
      setFormKey(Math.random());
    } else {
      const defaultServerDateFormat = (date: Date): string =>
        moment(date).set('hour', 8).set('minute', 0).set('seconds', 0).format('YYYY-MM-DDTHH:mm:ss');
      setInitialValues({
        scheduleList: [
          {
            requestStart: defaultServerDateFormat(new Date()),
            requestTimeStart: '08:00',
            requestTimeEnd: '22:00',
          } as ScheduleData,
        ],
      } as TrainingGroupData);
    }
  }, [planData]);

  const modalTitle = planData?.id ? 'Изменить план приема' : 'Добавить план приема';
  const submitLabel = planData?.id ? 'Сохранить' : 'Добавить';
  const minVolumeValue = useMemo(() => {
    const min = initialValues?.scheduleList?.length > 0 ? initialValues.scheduleList[0].requestsPositiveCount : 1;
    return min > 0 ? min : 1;
  }, [initialValues]);

  const showMinimalVolumeTooltip = useMemo(() => (planData?.id ?? 0) > 0, [planData?.id]);
  const showErrorImmediately = (planData?.id ?? 0) > 0;

  return (
    <Modal show={show} onClose={onClose}>
      <Formik
        key={formKey}
        initialErrors={initialErrors}
        validationSchema={() => getValidationSchema(serviceData)}
        onSubmit={submitTrainingGroup}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<TrainingGroupData>) => {
          const { handleSubmit, isSubmitting, isValid, values, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                size="medium"
                overflow
                onClose={onClose}
                controls={() => (
                  <>
                    <Button label="Отмена" border primary size="small" onClick={onClose} />
                    <Push orientation="horizontal" size={12} />
                    <Button label={submitLabel} disabled={!isValid || isSubmitting} primary size="small" submit />
                  </>
                )}
                modalTitle={modalTitle}
                renderComponent={() => (
                  <>
                    {periodOptions?.map((e, i) => {
                      const max = Math.max(
                        ...serviceData.stage.list.map((e) => {
                          return e.orderNumber;
                        })
                      );
                      const stageOrder =
                        serviceData.stage.list.find((s) => s.id === planData?.serviceStageId)?.orderNumber ?? max;
                      return (
                        <>
                          {i > 0 ? <Push size={16} /> : ''}
                          <FormikFormGroup
                            name=""
                            label={`Дата начала занятий / Учебный период ${periodOptions.length > 1 ? i + 1 : ''}`}
                            required
                          >
                            <div className="flex items-start">
                              <div className="flex flex-auto" style={{ width: '50%' }}>
                                <DateField
                                  name={`stageList[${i}].dateStart`}
                                  disabled={
                                    values.scheduleList?.length > 0 &&
                                    values.scheduleList[0].requestTotalCount > 0 &&
                                    i < stageOrder
                                  }
                                  showErrorImmediately={showErrorImmediately}
                                />
                              </div>
                              {separator}
                              <div className="flex flex-auto" style={{ width: '50%' }}>
                                <DateField
                                  name={`stageList[${i}].dateEnd`}
                                  disabled={
                                    values.scheduleList?.length > 0 &&
                                    values.scheduleList[0].requestTotalCount > 0 &&
                                    i < stageOrder - 1
                                  }
                                  showErrorImmediately={showErrorImmediately}
                                />
                              </div>
                            </div>
                          </FormikFormGroup>
                        </>
                      );
                    })}

                    <Push size={16} />
                    <FormikFormGroup
                      name="serviceStageId"
                      label="Учебный период, на который ведется прием заявлений"
                      required
                      showErrorImmediately={showErrorImmediately}
                    >
                      <FormikSelect
                        name="serviceStageId"
                        size="small"
                        isSearchable
                        options={periodOptions}
                        placeholder="Выберите..."
                        disabled={
                          values.scheduleList?.length > 0 &&
                          values.scheduleList[0].requestTotalCount > 0 &&
                          periodOptions.some((p) => p.value === initialValues.serviceStageId)
                        }
                        selectedValue={(v: SelectOptionType) => {
                          v && setFieldValue('serviceStageName', v.label);
                        }}
                      />
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup name="" label="Количество мест" required>
                      <div className="flex items-start">
                        <div style={{ width: 88 }}>
                          <FormikFormGroup name="volume" label="" showErrorImmediately={showErrorImmediately}>
                            <FormikInput
                              key={volumeKey}
                              name="volume"
                              number
                              size="small"
                              disabled={values.volume === null}
                              placeholder="0"
                            />
                          </FormikFormGroup>
                        </div>

                        {showMinimalVolumeTooltip && values.volume !== null && (
                          <>
                            <Push size={8} orientation="horizontal" />
                            <div className="flex items-center flex-none table-row-item-height">
                              <Tooltip
                                component={() => <IconInfo />}
                                position="bottom"
                                text={
                                  <div className="text-center">Минимальное количество мест — {minVolumeValue}.</div>
                                }
                              />
                            </div>
                          </>
                        )}
                        <Push size={8} orientation="horizontal" />
                        <div className="flex items-center flex-none table-row-item-height">
                          <FormikCheckboxGroup label="Без ограничений" name="restrictions" size="small">
                            <Checkbox
                              labelId="restrictions"
                              checked={values.volume === null}
                              onChange={(c) => {
                                setVolumemKey(Math.random());
                                setFieldValue('volume', c ? null : planData?.volume ?? 1);
                              }}
                              disabled={
                                initialValues.volume === null &&
                                initialValues.scheduleList?.length > 0 &&
                                initialValues.scheduleList[0].requestTotalCount > 0
                              }
                            />
                          </FormikCheckboxGroup>
                        </div>
                      </div>
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup
                      name="teacher"
                      label="Преподаватель"
                      required
                      showErrorImmediately={showErrorImmediately}
                    >
                      <FormikSelect
                        name="teacher"
                        size="small"
                        isSearchable
                        options={[]}
                        loadOptions={(query: string) => getOptions(query)}
                        placeholder="Начните вводить..."
                        defaultValue={values?.teacher ? { value: 0, label: values?.teacher } : null}
                        selectedValue={(option: any) => {
                          setFieldValue('teacherId', option?.value || '');
                          setFieldValue('teacher', option?.label || '');
                        }}
                      />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup
                      name=""
                      label={`Период приема заявлений${
                        serviceData.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation ? ' на Mos.ru' : ''
                      }`}
                      required
                    >
                      <div className="flex items-start">
                        <div className="flex flex-auto" style={{ width: '50%' }}>
                          <DateField
                            name="scheduleList[0].requestStart"
                            dependentTimeName="scheduleList[0].requestTimeStart"
                            showErrorImmediately={showErrorImmediately}
                          />
                          <Push size={8} orientation="horizontal" />
                          <div style={{ width: 114 }} className="flex-none">
                            <TimeField
                              name="scheduleList[0].requestTimeStart"
                              dependentDateName="scheduleList[0].requestStart"
                            />
                          </div>
                        </div>
                        {separator}
                        <div className="flex flex-auto" style={{ width: '50%' }}>
                          <DateField
                            name="scheduleList[0].requestEnd"
                            dependentTimeName="scheduleList[0].requestTimeEnd"
                            showErrorImmediately={showErrorImmediately}
                          />
                          <Push size={8} orientation="horizontal" />
                          <div style={{ width: 114 }} className="flex-none">
                            <TimeField
                              name="scheduleList[0].requestTimeEnd"
                              dependentDateName="scheduleList[0].requestEnd"
                            />
                          </div>
                        </div>
                      </div>
                    </FormikFormGroup>
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TemplatePlanModal;

const getValidationSchema = (serviceData: ServiceData) => {
  const depsDate: [string, string] = ['dateStart', 'dateEnd'];
  const depsMosDate: [string, string] = ['requestStart', 'requestEnd'];
  const service = serviceData;
  const { stage } = serviceData;

  const dateValidation = objectYup().shape(
    {
      dateStart: validationCheckDate(
        'Выберите дату начала',
        { start: 'dateStart', end: 'dateEnd' },
        'Дата начала учебного периода не может быть больше даты окончания',
        'start'
      ),
      dateEnd: validationCheckDate(
        'Выберите дату окончания',
        { start: 'dateStart', end: 'dateEnd' },
        'Дата окончания учебного периода не может быть меньше даты начала',
        'end'
      ),
    },
    [depsDate]
  );

  const dateMosValidation = objectYup().shape(
    {
      requestStart: validationCheckDate(
        'Выберите дату начала',
        { start: 'requestStart', end: 'requestEnd' },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'start'
      ),
      requestEnd: validationCheckDate(
        'Выберите дату окончания',
        { start: 'requestStart', end: 'requestEnd' },
        'Дата окончания приема заявлений меньше даты начала приема заявлений',
        'end'
      ),
      requestTimeStart: stringYup().nullable().required('Введите время начала'),
      requestTimeEnd: stringYup().nullable().required('Введите время окончания'),
    },
    [depsMosDate]
  );

  const validation = {
    serviceStageId: stringYup().required('Выберите учебный период'),
    volume: number()
      .nullable()
      .typeError('Введите количество мест')
      .test('volume', 'Введите количество мест', (value) => value !== 0)
      .test('volume', '', function (value) {
        const data = this.parent as TrainingGroupData;
        if (data?.scheduleList && data?.scheduleList[0]?.requestTotalCount > 0 && value) {
          return value >= data?.scheduleList[0]?.requestsPositiveCount;
        }
        return true;
      }),
    teacher: stringYup().nullable().required('Выберите преподавателя'),
    stageList: arrayYup()
      .test('TrainingStageData[]', 'ошибка', function (values) {
        const list = values as TrainingStageData[];
        const errors: ValidationError[] = [];
        if (list && list.length > 1) {
          for (let i = 0; i < list.length; i++) {
            if (list[i]?.dateStart) {
              if (i > 0 && list[i - 1].dateEnd && new Date(list[i].dateStart) < new Date(list[i - 1].dateEnd)) {
                errors.push(
                  new ValidationError(
                    'Дата начала учебного периода не может быть меньше даты окончания предыдущего периода',
                    undefined,
                    `stageList[${i}].dateStart`
                  )
                );
              }
              if (
                i < list.length - 1 &&
                i >= 0 &&
                list[i + 1].dateStart &&
                new Date(list[i].dateEnd) > new Date(list[i + 1].dateStart)
              ) {
                errors.push(
                  new ValidationError(
                    'Дата окончания учебного периода не может быть больше даты начала следующего периода',
                    undefined,
                    `stageList[${i}].dateEnd`
                  )
                );
              }
            }
          }
        }

        if (
          list?.length > 0 &&
          list[0] &&
          list[0].dateStart &&
          list[stage.list.length - 1] &&
          list[stage.list.length - 1].dateEnd
        ) {
          const learnEnd = calcLearnEnd(
            new Date(list[0].dateStart),
            undefined,
            service.info.durationOfTraining,
            service.info.durationOfTrainingMonths,
            service.info.durationOfTrainingWeeks,
            service.info.durationOfTrainingDays
          );

          if (learnEnd < new Date(list[stage.list.length - 1].dateEnd)) {
            errors.push(
              new ValidationError(
                'Дата окончания учебного периода выходит за рамки продолжительности программы обучения',
                undefined,
                `stageList[${list.length - 1}].dateEnd`
              )
            );
          }
        }

        return errors.length > 0 ? new ValidationError(errors) : true;
      })
      .of(dateValidation)
      .required(),
    scheduleList: arrayYup()
      .test(
        'dateEnd',
        'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
        function (value) {
          const tg = this.parent as TrainingGroupData;
          const schedule = value as ScheduleData[];
          if (tg?.stageList && tg.stageList.length > 0 && schedule && schedule[0].requestEnd) {
            const index = stage.list?.findIndex((s) => s.id === parseInt(tg.serviceStageId as string));
            if (index !== -1 && tg.stageList[index].dateEnd) {
              const a = new Date(schedule[0].requestEnd);
              const b = new Date(tg.stageList[index].dateEnd);
              return b < a
                ? new ValidationError(
                    'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
                    undefined,
                    `scheduleList[0].requestEnd`
                  )
                : true;
            }
          }
          return true;
        }
      )
      .of(dateMosValidation)
      .required(),
  };

  return objectYup().shape(validation);
};
