import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup, IconArrowLongRight } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import Favorite from '../../../components/favorite';
import DigitalTutorDetails from './details';
import { ServiceContext } from '..';
import { getHeaderLink, getTabsButton } from '../utils';
import ToArchiveModal from '../components/modals/to-archive';
import { generateLink } from '../../../lib/utils';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { ReactComponent as IconRefresh } from '../../../assets/images/icons/refresh.svg';
import RecoverModal from '../components/modals/recover';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { ArchiveObstacle } from '../../../types/service';
import ErrorArchiveModal from '../components/modals/error-archive';

const DigitalTutor = () => {
  const { serviceData, educationTypes, type, accessAddArchive, accessPanelEdit, canRecover, routeBack } =
    useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalErrorArchive, setShowModalErrorArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);
  const [archiveObstacle, setArchiveObstacle] = useState<ArchiveObstacle>();

  const [activeTab, setActiveTab] = useState('Основные сведения');

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);

  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;
  const history = useHistory();

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <DigitalTutorDetails />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name=""
            entityTypes={[EntityTypeEnum.Service]}
            extendEntityGuid={serviceData.extendEntityGuid}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [serviceData.extendEntityGuid]
  );

  return (
    <>
      <PageHeading
        buttonBack
        routeBack={routeBack}
        title="Услуга цифрового репетитора"
        sections={[
          { title: 'Главная', link: routes.main },
          {
            title: 'Услуги цифрового репетитора',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          { title: 'Описание услуги цифровой репетитор' },
        ]}
        tabsButton={getTabsButton(serviceData.serviceStatusId, serviceData.serviceStatusName)}
        controlsTopRight={
          isArchive ? (
            <ButtonGroup>
              {canRecover && (
                <Button
                  label="Восстановить"
                  iconLeft={() => <IconRefresh />}
                  size="small"
                  handleClick={() => setShowModalRecover(true)}
                />
              )}
              <Button
                handleClick={() =>
                  history.push(
                    generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id, showArchive: true })
                  )
                }
                label="Перейти к группам обучения"
                iconLeft={() => <IconArrowLongRight />}
                size="small"
              />
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                handleClick={() => history.push(generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id }))}
                label="Перейти к группам обучения"
                iconLeft={() => <IconArrowLongRight />}
                size="small"
              />
              {checkAccessAddArchive && (
                <Button
                  label="В архив"
                  handleClick={async () => {
                    const data = await serviceTemplateApi.getArchiveObstacle(serviceData.id);
                    if (data.pupilList.length || data.requestList.length) {
                      setShowModalErrorArchive(true);
                      setArchiveObstacle(data);
                    } else {
                      setShowModalArchive(true);
                    }
                  }}
                  iconLeft={() => <IconArchive />}
                  size="small"
                />
              )}
              <Button
                label="Печать"
                iconLeft={() => <IconPrinter />}
                size="small"
                handleClick={() =>
                  window.open(
                    generateLink(routes.printService, {
                      id: serviceData.id,
                    }),
                    '_blank'
                  )
                }
              />
            </ButtonGroup>
          )
        }
        tabs={tabs}
        activeTab={activeTab}
        expansion={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      {tabs.find((item) => item.label === activeTab)?.component}
      <ToArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
      <RecoverModal onCloseHandler={() => setShowModalRecover(false)} show={showModalRecover} />
      <ErrorArchiveModal
        onCloseHandler={() => setShowModalErrorArchive(false)}
        show={showModalErrorArchive}
        data={archiveObstacle}
      />
    </>
  );
};

export default DigitalTutor;
