import React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../../../../lib/utils';
import { routes } from '../../../../../config/constants';

type Props = {
  id: number;
  requestNumber: string;
};

const RequestNumber: React.FC<Props> = ({ id, requestNumber }) => {
  return (
    <Link className="brand-link" to={generateLink(routes.request, { id })}>
      {requestNumber}
    </Link>
  );
};

export default RequestNumber;
