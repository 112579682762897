import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { CheckboxGroup, ToggleGroup, Checkbox, Panel, Toggle, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../../../assets/images/icons/edit-color.svg';
import FormikToggleGroup from '../../../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../../../components/formik/formik-toggle';
import { CandidateRestrictionType, ServiceData } from '../../../../../../types/service';
import { dictionariesApi } from '../../../../../../lib/api/dictionaries';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  parent?: string;
  isTemplate?: boolean;
  checkEditable?: boolean;
};

const CAN_CHANGE_TOGGLE = 'CanChange';

const LimitEdit: React.FC<Props> = ({
  isTemplate,
  setEditModeParent,
  parent,
  editMode,
  setEditMode,
  checkEditable,
}) => {
  const { values, setFieldValue } = useFormikContext<ServiceData>();
  const [defaultRestriction, setDefaultRestriction] = useState<CandidateRestrictionType[]>([]);

  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);

  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;
      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );
  const getRestrictionName = (index: number) => getFieldFullName(`list[${index}].isSelected`);

  useEffect(() => {
    const fetch = async () => {
      const dict = await dictionariesApi.getCandidateRestrictionTypeList();
      setDefaultRestriction(
        dict.map((item) => {
          return {
            id: item.value as number,
            name: item.label,
            isSelected: false,
          };
        })
      );
    };
    if (defaultRestriction.length === 0) {
      fetch();
    }
  }, [defaultRestriction.length]);

  useEffect(() => {
    setFieldValue(
      getFieldFullName(CAN_CHANGE_TOGGLE),
      getFieldValue('list')?.some((c: any) => c.isSelected) || getFieldValue(CAN_CHANGE_TOGGLE)
    );
    if (defaultRestriction.length === 0 || getFieldValue('list')?.length === defaultRestriction.length) {
      return;
    }
    const list = getFieldValue('list') as CandidateRestrictionType[];
    const restriction = defaultRestriction.map((item) => {
      return {
        id: item.id as number,
        name: item.name,
        isSelected: list?.find((f) => f.id === item.id)?.isSelected ?? false,
      };
    });
    setFieldValue(getFieldFullName('list'), restriction);
  }, [values, defaultRestriction, getFieldFullName, getFieldValue, setFieldValue]);

  return (
    <Panel
      title={() => (
        <div className="flex">
          Для лиц с ограниченными возможностями
          <div className="flex font-weight-base">
            <Push orientation="horizontal" size={16} />
            <ToggleGroup
              labelId={getFieldFullName(CAN_CHANGE_TOGGLE)}
              label="Разрешить"
              size="small"
              disabled={!editMode}
            >
              <Toggle
                disabled={!editMode || !checkEditable}
                labelId={getFieldFullName(CAN_CHANGE_TOGGLE)}
                size="xsmall"
                checked={getFieldValue(CAN_CHANGE_TOGGLE)}
                onChange={(result) => {
                  if (!result) {
                    setFieldValue(getFieldFullName('list'), undefined);
                  }
                  setFieldValue(getFieldFullName(CAN_CHANGE_TOGGLE), result);
                }}
              />
            </ToggleGroup>
          </div>
        </div>
      )}
      headingControl={() => {
        return (
          <>
            {checkEditable && !editMode && setEditModeParent && (!parent || isTemplate) && (
              <button
                type="button"
                onClick={() => {
                  setEditModeParent && setEditModeParent('limits');
                  setEditMode && setEditMode(true);
                }}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <IconEdit />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
              </button>
            )}
            {isTemplate && (
              <div className="table-data__control">
                <FormikToggleGroup name={getFieldFullName('canChangeCandidateRestriction')} label="">
                  <FormikToggle
                    disabled={!editMode}
                    name={getFieldFullName('canChangeCandidateRestriction')}
                    size="xsmall"
                  />
                </FormikToggleGroup>
              </div>
            )}
          </>
        );
      }}
    >
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Виды нарушений</div>
          <div className="table-data__body">
            <div className="table-data-grid-1-2 items-center">
              {getFieldValue('list')?.map((r: CandidateRestrictionType, i: number) => {
                return (
                  <CheckboxGroup key={`hearing-${r.id}`} label={r.name} disabled={!editMode} labelId="hearing">
                    <Checkbox
                      labelId={r.name}
                      onChange={(result) => setFieldValue(getRestrictionName(i), result)}
                      disabled={!editMode || !getFieldValue(CAN_CHANGE_TOGGLE) || !checkEditable}
                      checked={r.isSelected}
                    />
                  </CheckboxGroup>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default LimitEdit;
