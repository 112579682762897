import React from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikToggleGroup from '../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../components/formik/formik-toggle';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';
import { TemplateService } from '../../../../types/service';

export const Tests = () => {
  const { values } = useFormikContext<TemplateService.Data>();
  const canEditToursNumber = !values?.test?.isRequired;

  return (
    <Panel
      title={() => (
        <div className="flex">
          Вступительные испытания
          <div className="flex font-weight-base">
            <Push orientation="horizontal" size={16} />
            <FormikToggleGroup size="small" name="test.isRequired" label="Проводятся">
              <FormikToggle size="xsmall" name="test.isRequired" />
            </FormikToggleGroup>
          </div>
        </div>
      )}
    >
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Количество туров</div>
          <div className="table-data__body">
            <FormikFormGroup name="test.toursNumber" label="" required>
              <div style={{ width: 88 }}>
                <FormikInput
                  number
                  size="small"
                  disabled={canEditToursNumber}
                  name="test.toursNumber"
                  placeholder="0"
                  onKeyPress={(e) => allowOnlyNumbers(e)}
                />
              </div>
            </FormikFormGroup>
          </div>
          <div className="table-data__control">
            <FormikToggleGroup name="test.canChangeTestAndToursNumber" label="">
              <FormikToggle size="xsmall" name="test.canChangeTestAndToursNumber" />
            </FormikToggleGroup>
          </div>
        </div>
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            <div>
              Срок предоставления док-ов / выкупа брони, дней <span className="table-data__required" />
            </div>
          </div>
          <div className="table-data__body">
            <FormikFormGroup name="test.periodGettingDocument" label="" required>
              <div style={{ width: 88 }}>
                <FormikInput size="small" placeholder="0" name="test.periodGettingDocument" number />
              </div>
            </FormikFormGroup>
          </div>
          <div className="table-data__control">
            <FormikToggleGroup name="test.canChangePeriodGettingDocument" label="">
              <FormikToggle name="test.canChangePeriodGettingDocument" size="xsmall" />
            </FormikToggleGroup>
          </div>
        </div>
      </div>
    </Panel>
  );
};
