import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Push } from '@mosru/esz_uikit';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import requestsApi from '../../../lib/api/requests';
import { ReactComponent as IconWarning } from '../../../assets/images/icons/clock-warning.svg';
import { formatDate } from '../../../lib/utils/date';
import { ReactComponent as IconError } from '../../../assets/images/icons/error.svg';
import { RequestContext } from '../../../lib/utils/requests';
import { ReactComponent as IconInfo } from '../../../assets/images/icons/alert-primary.svg';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
  showSignModal: (value: boolean) => void;
};

const Status: React.FC<Props> = ({ requestData, updateRequest, showSignModal }) => {
  const { useSign } = useContext(RequestContext);
  const [lastRequestId, setLastRequestId] = useState<number>(requestData.id);

  useEffect(() => {
    const fetch = async () => {
      const newId = await requestsApi.getLastVersion(requestData.id);
      setLastRequestId(newId);
    };
    if (!requestData.flagLast) {
      fetch();
    }
  }, [requestData.flagLast, setLastRequestId, requestData.id]);

  return (
    <>
      {requestData.requestStatusId === RequestStatusEnum.ForInclude && (
        <>
          <Push orientation="vertical" size={12} />
          <div className="infobox infobox--warning">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconWarning className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <span className="color-warning-dark font-weight-bold">
                    Дата явки заявителя для заключения договора:{' '}
                    {requestData.actionDeadline ? formatDate(requestData.actionDeadline) : '—'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {requestData.requestStatusId === RequestStatusEnum.NeedTest && (
        <>
          <Push orientation="vertical" size={12} />
          <div className="infobox infobox--warning">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconWarning className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <span className="color-warning-dark font-weight-bold">
                    Дата обработки результатов вступительных испытаний и уведомления заявителя о результатах
                    вступительных испытаний: {requestData.actionDeadline ? formatDate(requestData.actionDeadline) : '—'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!requestData.flagLast && (
        <>
          <Push orientation="vertical" size={12} />
          <div className="infobox infobox--primary">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconInfo className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <div className="color-primary-dark">
                    <span className="font-weight-bold">Внимание!</span> Показаны сведения не последней версии заявления.{' '}
                    <Link to={() => generateLink(routes.request, { id: lastRequestId })} className="brand-link">
                      Перейти к последней версии.
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {requestData.requestStatusId === RequestStatusEnum.Draft && (
        <>
          <Push orientation="vertical" size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconError className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <div className="color-danger-dark">
                    <span className="font-weight-bold">
                      После редактирования сведений необходимо подтвердить изменения.
                    </span>{' '}
                    Не оставляйте заявление в статусе “Черновик”.
                  </div>
                </div>
              </div>
              <div className="infobox__control">
                {useSign ? (
                  <Button
                    size="small"
                    primary
                    label="Подписать"
                    onClick={async () => {
                      showSignModal(true);
                    }}
                  />
                ) : (
                  <Button
                    size="small"
                    primary
                    label="Подтвердить"
                    onClick={async () => {
                      try {
                        await requestsApi.signRequest(requestData.id, requestData.educationTypeId, null);
                        updateRequest();
                      } catch {}
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Status;
