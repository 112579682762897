import { fetchRequest } from '.';
import { apiService } from '../../config/constants';
import { BookingResultData, BookingSlotData } from '../../types/booking';

const preBookingByscheduleOfTimetable = async (
  scheduleOfTimetableId: number,
  testDate: string | null = null
): Promise<BookingResultData> =>
  await fetchRequest.post(
    `${apiService.booking}/Booking/TrainingGroup?scheduleOfTimetable=${scheduleOfTimetableId}`,
    {}
  );

const preBookingByServiceClass = async (serviceClassId: number): Promise<BookingResultData> =>
  await fetchRequest.post(`${apiService.booking}/Booking/ServiceClass?serviceClassId=${serviceClassId}`, {});

const getTrainingGroupSlots = async (scheduleId: number): Promise<BookingSlotData[]> => {
  const result = (await fetchRequest.post(`${apiService.booking}/Slot/Search`, {
    IsActual: true,
    BookingTypeId: 1, // BookingTypeEnum.TrainingGroupBooking
    TrainingGroupData: { ScheduleOfTimetableId: scheduleId },
  })) as BookingSlotData[] | undefined | null;

  return result
    ? result.filter(
        (s) => s.isActual && (!s.volume || s.volume - (s.volumeUsed ?? 0) - (s.volumeUnapprovedUsed ?? 0) > 0)
      )
    : [];
};

const makePreBooking = async (slotId: string | undefined): Promise<BookingResultData> =>
  await fetchRequest.post(`${apiService.booking}/Booking?slotId=${slotId}`, {});
const undoPreBooking = async (slotId: string, bookingId: string) =>
  await fetchRequest.delete(`${apiService.booking}/Booking/${bookingId}`, { slotId });

export const bookingApi = {
  preBookingByscheduleOfTimetable,
  preBookingByServiceClass,
  getTrainingGroupSlots,
  makePreBooking,
  undoPreBooking,
};
