import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import { ServiceClassContext } from '../../service-class';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
  serviceClassCode: string;
};

const ConfirmDeletionModal = ({ show, onCloseHandler, serviceClassCode }: Props) => {
  const { isRemovedGroup, deleteServiceClass } = useContext(ServiceClassContext);

  const history = useHistory();

  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle="Подтвердите удаление группы обучения"
        renderComponent={() => (
          <>
            <Push orientation="vertical" size={15} />
            <div>
              Вы действительно хотите удалить группу обучения <b>{serviceClassCode} ?</b>
            </div>
            <Push orientation="vertical" size={15} />
          </>
        )}
        controls={() => (
          <>
            <Button border label="Нет" onClick={onCloseHandler} />
            <Push orientation="horizontal" size={12} />
            <Button
              primary
              load={isRemovedGroup}
              label="Да"
              onClick={async () => {
                deleteServiceClass && (await deleteServiceClass());
                !isRemovedGroup && onCloseHandler();
                history.push(generateLink(routes.serviceClasses, {}));
              }}
            />
          </>
        )}
      />
    </Modal>
  );
};

export default ConfirmDeletionModal;
