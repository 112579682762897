import React, { useContext } from 'react';
import { SimpleTable as Table } from '@mosru/esz_uikit';
import { formatDate } from '../../../../lib/utils/date';
import { ScheduleData, TrainingGroupData } from '../../../../types/service';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import history from '../../../../history';
import { generateLink } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../..';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';

type Props = {
  list: TrainingGroupData[];
  getTGList: () => Promise<void>;
};

const TgListWithTests: React.FC<Props> = ({ list, getTGList }) => {
  const { serviceData } = useContext(ServiceContext);
  const getIncluded = (tg: TrainingGroupData) =>
    tg.scheduleList.map((s) => s.requestIncludedCount).reduce((partialSum: number, a: number) => partialSum + a, 0);

  const handleEdit = (item: TrainingGroupData) => () => {
    const isRetro = item.scheduleList.length && item.scheduleList[0].isRetro ? 1 : 0;

    history.push(
      `${generateLink(routes.trainingGroup, {
        serviceId: item.serviceId ?? 0,
        id: item.id,
      })}?isRetro=${isRetro}&yearId=${item.yearOfTrainingId}`
    );
  };

  const canRemove = (tg: TrainingGroupData): boolean => {
    return (
      !tg.scheduleList?.length ||
      tg.scheduleList.every((s: ScheduleData) =>
        [TrainingGroupStatusEnum.Draft, TrainingGroupStatusEnum.Archive].includes(s.trainingGroupStatusId)
      )
    );
  };

  const getScheduleCountText = (count?: number): string => {
    if (!count) {
      return 'нет расписаний';
    }
    switch (count) {
      case 1:
        return '1 расписание';
      case 2:
      case 3:
      case 4:
        return `${count} расписания`;
      default:
        return `${count} расписаний`;
    }
  };

  const handleDelete = (id: number) => async () => {
    await serviceTemplateApi.deleteTrainingGroup(serviceData.id, id);
    getTGList();
  };

  return (
    <Table
      overflow
      data={list}
      hideSort
      columns={[
        {
          dataIndex: 'date',
          title: 'Дата начала занятий',
          render: (item: any) =>
            item.educationStartDate && <span className="brand-link">{formatDate(item.educationStartDate)}</span>,
          width: '16%',
        },
        {
          dataIndex: 'stage',
          title: 'Этап обучения',
          render: (item: any) => <div className="overflow-wrap-anywhere">{item.serviceStageName}</div>,
          width: '16%',
        },
        {
          dataIndex: 'amount',
          title: 'План приема / зачислено',
          render: (item: any) => {
            return `${item.volume}/${getIncluded(item)}`;
          },
          width: '16%',
        },
        {
          dataIndex: 'teacher',
          title: 'Преподаватель',
          render: (item: any) => item.teacher,
        },
        {
          dataIndex: 'schedule',
          title: 'Расписание вступ. испытаний',
          render: (item: any) => {
            return getScheduleCountText(item.scheduleList?.length);
          },
          width: '23%',
        },
        {
          dataIndex: '',
          title: '',
          render: (item: any) => (
            <DropDown
              component={() => (
                <span className="drop-down-btn-round">
                  <IconDots />
                </span>
              )}
            >
              <div className="drop-down-panel">
                <div className="drop-down-panel__list">
                  <button type="button" onClick={handleEdit(item)} className="drop-down-panel__list-item">
                    <IconEdit />
                    Редактировать
                  </button>
                  {canRemove(item) && (
                    <button type="button" onClick={handleDelete(item.id)} className="drop-down-panel__list-item">
                      <IconRemove />
                      Удалить
                    </button>
                  )}
                </div>
              </div>
            </DropDown>
          ),
          width: '40px',
        },
      ]}
    />
  );
};

export default TgListWithTests;
