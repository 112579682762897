import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { Button, Select, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { scheduleMockData } from '../../../../../lib/utils/time-table';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { ServiceClassContext } from '../../../service-class';
import { ServiceClassPeriodListScheduleData, ServiceClassScheduleListParams } from '../../../../../types/service-class';
import serviceClassApi from '../../../../../lib/api/service-class';
import { formatDate, formatTime, getDate } from '../../../../../lib/utils/date';
import { weekday } from '../../../../../components/shedule-modal/helpers';
import { serviceClassStatusEnum } from '../../../../../mock-data/service-class-status';
import EditPeriodSchedule, { dateInvalidError, PeriodType } from '../../../../../components/schedule/period';
import { editPeriodDataToTransformRequestData } from '../../../../../lib/utils/service-class';
import SavePanel from '../../../../../components/save-panel';

type Props = {
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServiceClassSchedule: React.FC<Props> = ({ setEditModeParent, editMode, setEditMode }) => {
  const {
    serviceClassData,
    updateServiceClass,
    accessEditServiceClass,
    startDateSchedulePeriod,
    endDateSchedulePeriod,
    currentValuesGroupDetails,
    setIsModalSchedule,
    isModalSchedule,
  } = useContext(ServiceClassContext);

  const [scheduleData, setScheduleData] = useState<PeriodType[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<SelectOptionType>();

  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [currentErrorSchedule, setCurrentErrorSchedule] = useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const scheduleData = serviceClassData.schedule.list?.map((period) => {
      const schedule = weekday.map((day: any) => {
        // @ts-ignore
        const item = period[day.field];
        return {
          id: day?.id ?? 0,
          name: day.name,
          from: item && formatTime(item[0]?.f),
          to: item && formatTime(item[0]?.t),
          dayOff: !item || !item.length,
          dayOfWeek: day.id - 1,
          intervals:
            item &&
            item.slice(1).map((interval: { f: string; t: string }, index: number) => ({
              id: index,
              from: formatTime(interval.f),
              to: formatTime(interval.t),
            })),
        };
      });
      return {
        id: period.id,
        dateEnd: getDate(period.dateEnd),
        dateStart: getDate(period.dateStart),
        shedulePeriod: period.shedulePeriod,
        schedule,
        dateOverlapError: null,
        dateInvalidError: null,
        dateOrderError: null,
      } as PeriodType;
    });

    if (scheduleData.length > 0) {
      setScheduleData(scheduleData);
    } else {
      const schedule = {
        schedule: scheduleMockData('days').map((mock) => ({
          id: mock.id,
          name: mock.name,
          dayOff: true,
          intervals: [],
        })),
        id: 0,
        dateInvalidError,
      } as unknown as PeriodType;
      setScheduleData([schedule]);
    }
  }, [serviceClassData.schedule.list]);

  const closeEditPanel = useCallback(() => {
    setEditModeParent && setEditModeParent(null);
    setEditMode && setEditMode(false);
  }, [setEditModeParent, setEditMode]);

  const handleSubmit = useCallback(
    async (schedule: PeriodType[]) => {
      setLoader(true);
      const payload: ServiceClassScheduleListParams = {
        serviceClassId: serviceClassData.id,
        educationTypeId: serviceClassData.educationTypeId,
        list: editPeriodDataToTransformRequestData(schedule) as unknown as ServiceClassPeriodListScheduleData[],
      };
      if (setIsModalSchedule) {
        setIsModalSchedule(false);
      }

      try {
        if (currentValuesGroupDetails) {
          await serviceClassApi.updateServiceClass(currentValuesGroupDetails);
        }
        await serviceClassApi.updateServiceClassScheduleList(payload);
        updateServiceClass();
        setLoader(false);

        closeEditPanel();
      } catch {
        closeEditPanel();
        setLoader(false);
      }
    },
    [
      serviceClassData.id,
      serviceClassData.educationTypeId,
      setIsModalSchedule,
      currentValuesGroupDetails,
      updateServiceClass,
      closeEditPanel,
    ]
  );

  const options = useMemo(() => {
    const array = scheduleData.map((schedule, index) => ({
      label: `период ${index + 1} ${
        schedule.dateStart
          ? formatDate(schedule.dateStart)
          : serviceClassData.trainStartDate
          ? formatDate(serviceClassData.trainStartDate)
          : ''
      } - ${
        schedule.dateEnd
          ? formatDate(schedule.dateEnd)
          : serviceClassData.trainEndDate
          ? formatDate(serviceClassData.trainEndDate)
          : 'отсутствует'
      }`,
      value: schedule.id,
    }));
    setSelectedSchedule(array[0]);
    return array;
  }, [scheduleData, serviceClassData.trainEndDate, serviceClassData.trainStartDate]);

  const currentSchedule = scheduleData.find((schedule) => schedule.id === selectedSchedule?.value) || scheduleData[0];

  const data = isModalSchedule
    ? scheduleData.map((item) => ({ ...item, dateStart: null, dateEnd: null }))
    : scheduleData;

  return (
    <>
      {editMode ? (
        scheduleData.length && (
          <EditPeriodSchedule
            dateStartProps={startDateSchedulePeriod}
            dateEndProps={endDateSchedulePeriod}
            title="Время проведения занятий"
            submit={scheduleSubmit}
            submitError={() => setScheduleSubmit(false)}
            submitSuccess={(schedule) => {
              if (scheduleSubmit) {
                handleSubmit(schedule);
              }
              setScheduleSubmit(false);
            }}
            extendedPeriods={data}
            setCurrentErrorSchedule={setCurrentErrorSchedule}
          />
        )
      ) : (
        <Panel
          title={() => (
            <div className="flex items-center">
              Время проведения занятий
              <Push orientation="horizontal" size={16} />
              {!!scheduleData.length && (
                <div className="font-weight-base" style={{ width: 272 }}>
                  <Select
                    name="selectedSchedule"
                    onChange={(selectedOption) => {
                      selectedOption && setSelectedSchedule(selectedOption as SelectOptionType);
                    }}
                    isSearchable={false}
                    hideClearIndicator
                    options={options}
                    value={selectedSchedule}
                    size="small"
                  />
                </div>
              )}
            </div>
          )}
          headingControl={() =>
            serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
            accessEditServiceClass && (
              <button
                type="button"
                onClick={() => {
                  setEditMode && setEditMode(true);
                  setEditModeParent && setEditModeParent('schedule');
                }}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <IconEdit />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
              </button>
            )
          }
        >
          <div className="table-data-striped">
            {currentSchedule?.schedule.map((item) => (
              <div key={item.name} className="table-data-striped__item">
                <div className="table-data-striped__label">{item.name}</div>
                <div className="table-data-striped__body">
                  {item.dayOff ? (
                    <span className="color-gray-dark">нерабочий</span>
                  ) : (
                    <>
                      {`${formatTime(item.from)} — ${formatTime(item.to)}`}
                      {item.intervals?.map((interval) => `, ${formatTime(interval.from)} — ${formatTime(interval.to)}`)}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Push size={8} />
        </Panel>
      )}

      {editMode && (
        <SavePanel
          controls={
            <>
              <Button
                onClick={() => {
                  if (setIsModalSchedule) {
                    setIsModalSchedule(false);
                  }
                  closeEditPanel();
                }}
                border
                label="Отмена"
                primary
              />
              <Push size={12} orientation="horizontal" />
              <Button
                onClick={() => setScheduleSubmit(true)}
                disabled={currentErrorSchedule}
                primary
                load={loader}
                label="Сохранить"
              />
            </>
          }
        />
      )}
    </>
  );
};

export default ServiceClassSchedule;
