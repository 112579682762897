import { emailRegexp, phoneErrorMessage, rusRegexp } from '../../../../lib/utils/validation';
import { Learner } from '../../../../types/learners';

/*
checkValidation	проверяет входящие данные и отдает номер ошибки
validateMessage проверяет номер ошибки и отдает - сообщение этого номера
проверяем validate если в обьекте есть значения  > 1
*/

export const validateMessage = (val: number) => {
  switch (val) {
    case 1:
      return 'Вводите не менее 2 букв.';
    case 2:
      return 'Используйте только русские буквы';
    case 3:
      return 'Не правильный email';
    case 4:
      return phoneErrorMessage;
    default:
      return '';
  }
};

export const checkValidation = (
  val: string,
  setVal: (e: Learner.ValidateRepresentatives) => void,
  nameVal: string,
  email?: boolean,
  phone?: boolean
) => {
  if (email) {
    if (val === '') {
      setVal({ [nameVal]: 0 });
    } else if (!emailRegexp.test(val)) {
      setVal({ [nameVal]: 3 });
    } else {
      setVal({ [nameVal]: 0 });
    }
  } else if (phone) {
    if (val.length < 11) {
      setVal({ [nameVal]: 4 });
    } else {
      setVal({ [nameVal]: 0 });
    }
  } else if (val === '') {
    setVal({ [nameVal]: 0 });
  } else if (val.length < 2) {
    setVal({ [nameVal]: 1 });
  } else if (!val.match(rusRegexp)) {
    setVal({ [nameVal]: 2 });
  } else {
    setVal({ [nameVal]: 0 });
  }
};

export const fieldValidation = (value: string, phone?: boolean) => {
  if (phone) {
    if (value.length) {
      return true;
    }
  } else if (value.length < 2) {
    return false;
  } else {
    return value.search(rusRegexp) !== -1;
  }
};
