import React, { useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { selectedDefaultDocument } from '../../../../../../lib/utils/learners';
import { generateDocuments } from '../utils';
import { DocumentDataFinishedAndTypes, PropsDocumentType } from '../../../../../../types/learners';

type Props = {
  newDocument: boolean;
  documentTypeId: number;
  accessDisabled: boolean;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
};

export const DocumentType: React.FC<Props> = ({ documentDataFinishedAndTypes, newDocument, documentTypeId }) => {
  const defaultTypeDocument: SelectOptionType = { label: 'Выберите тип документа', value: 0 };

  const document: PropsDocumentType | undefined = documentDataFinishedAndTypes.type.find(
    (item: { id: number }) => item.id === documentTypeId
  );

  const documentValue: SelectOptionType = !newDocument
    ? selectedDefaultDocument(document, defaultTypeDocument)
    : { label: '', value: 0 };

  const [selectedDocument, setSelectedDocument] = useState<SelectOptionType>(documentValue);

  const documents: SelectOptionType[] = generateDocuments(documentDataFinishedAndTypes.type);

  return (
    <FormikFormGroup required name="documentTypeId" label="Тип документа">
      <FormikSelect
        size="small"
        isSearchable
        name="documentTypeId"
        disabled={!newDocument}
        selectedValue={setSelectedDocument}
        placeholder="Выберите тип документа"
        options={newDocument ? documents : []}
        value={selectedDocument}
        defaultValue={newDocument ? null : selectedDefaultDocument(document, defaultTypeDocument)}
      />
    </FormikFormGroup>
  );
};
