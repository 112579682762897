import React, { useCallback, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number as numberYup, object as objectYup } from 'yup';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../../components/header/page-heading';
import FIO from '../../components/fields/fio';
import BirthDate from '../../components/fields/birthdate';
import Gender from '../../../../components/fields/gender';
import Document from '../../components/fields/document';
import DocumentNumber from '../../components/fields/document-number';
import Issued from '../../components/fields/issued';
import IssuedPlace from '../../components/fields/issued-place';
import Snils from '../../components/fields/snils';
import MoscowRegistration from '../../components/fields/moscow-registration';
import Phone from '../../../../components/fields/phone';
import Email from '../../../../components/fields/email';
import Service from '../../components/fields/service';
import AdmissionPlan from '../../components/fields/admission-plan';
import VisitDays from '../../components/fields/visit-days';
import ServiceClassField from '../../components/fields/service-class';
import { formatDate } from '../../../../lib/utils/date';
import { routes } from '../../../../config/constants';
import OrganizationField from '../../components/fields/organization';
import ClassParallelLetter from '../../components/fields/class-parallel-leter';
import RepresentationType from '../../components/fields/representation-type';
import { PersonRequestData, RequestData, TrainingGroupRequestData } from '../../../../types/requests';
import requestsApi from '../../../../lib/api/requests';
import { EducationTypeEnum } from '../../../../types/education-type';
import {
  classParallelId,
  dateOfBirth,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  issued,
  issuedNullable,
  issuedPlace,
  lastName,
  middleName,
  nullableEmail,
  organizationId,
  phone,
  scheduleOfTimetable,
  serviceId,
  sexId,
  snils,
  stringRequired,
} from '../../../../lib/utils/validation';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { generateLink } from '../../../../lib/utils';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import DependentDocument from '../../components/fields/dependent-document';
import { ServiceClassListData, ServiceClassPeriodListScheduleData } from '../../../../types/service-class';
import { LoadingModal } from '../../components/modals';
import WrapperFieldsDocument from '../../components/fields/wrapper-fields-document';
import { BookingResultData } from '../../../../types/booking';
import WrapperFields from '../../components/fields/wrapper-fields';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import { StatusChild } from '../../components/status-child';
import useInitialErrors from '../../../../hooks/formik-initial-errors';

type Props = {
  request?: RequestData;
};

const DayCareRequestCreate: React.FC<Props> = ({ request }) => {
  const [classParallelIdKey, setClassParallelIdKey] = useState<number>(0);
  const [classLetterIdKey, setClassLetterIdKey] = useState<number>(0);
  const [serviceIdKey, setServiceIdKey] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [serviceClassList, setServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);
  const [initialDataServiceClassList, setInitialDataServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);
  const [booking, setBooking] = useState<BookingResultData | undefined>(
    request ? { bookingId: request.trainingGroup.bookingGuid, slotId: request.trainingGroup.slotId } : undefined
  );

  const dataParams = useParams<Record<string, string | undefined>>();
  // Если это новое заявление, то мы не будем сразу отображать ошибки валидации.
  const hasEdTypeOrPupilId = 'id' in dataParams || 'pupilId' in dataParams;

  const initialValue = useMemo(() => {
    if (request) {
      return {
        ...request,
        applicant: {
          ...request.applicant,
          firstName: request.applicant?.firstName?.trim(),
          lastName: request.applicant?.lastName?.trim(),
          middleName: request.applicant?.middleName?.trim(),
          sexId: request.applicant ? request.applicant?.sexId : 1,
          sexName: request.applicant?.sexName || 'Мужской',
          educationTypeId: request.applicant?.educationTypeId ?? EducationTypeEnum.DayCareCentersEducation,
        } as PersonRequestData,
        child:
          request.child &&
          ({
            ...request.child,
            firstName: request.child?.firstName?.trim(),
            lastName: request.child?.lastName?.trim(),
            middleName: request.child?.middleName?.trim(),
            sexId: request.child ? request.child?.sexId : 1,
            sexName: request.child?.sexName || 'Мужской',
            educationTypeId: request.child?.educationTypeId ?? EducationTypeEnum.DayCareCentersEducation,
          } as PersonRequestData),
        trainingGroup: {} as TrainingGroupRequestData,
      };
    }
    return {
      child: {
        sexId: 1,
        sexName: 'Мужской',
        series: '',
        documentTypeId: DocumentTypeEnum.BirthCertificate,
        documentTypeName: 'Свидетельство о рождении',
        educationTypeId: EducationTypeEnum.DayCareCentersEducation,
      },
      applicant: {
        sexId: 1,
        sexName: 'Мужской',
        documentTypeId: DocumentTypeEnum.Passport,
        documentTypeName: 'Паспорт гражданина РФ',
        educationTypeId: EducationTypeEnum.DayCareCentersEducation,
      },
    } as RequestData;
  }, [request]);

  const initialErrors = useInitialErrors(initialValue, getValidationSchema());

  const submitForm = useCallback(
    async (values: RequestData) => {
      const fetch = async () => {
        setLoading(true);
        try {
          if (booking) {
            values.trainingGroup.bookingGuid = booking.bookingId;
            values.trainingGroup.slotId = booking.slotId;
            values.educationTypeId = EducationTypeEnum.DayCareCentersEducation;
            values.child.isChild = true;
            values.applicant.isChild = false;
            values.dateRequest = moment().format().toString();
            const newRequestId = await requestsApi.createRequest(values);

            setLoading(false);
            window.open(generateLink(routes.request, { id: newRequestId }), '_self');
          }
        } catch (error) {}
      };
      fetch();
    },
    [booking]
  );

  const checkIssuedRequired = (values: RequestData): boolean =>
    Boolean(
      values.child.documentTypeId === DocumentTypeEnum.Passport ||
        values.child.documentTypeId === DocumentTypeEnum.BirthCertificate ||
        values.child.documentTypeId === DocumentTypeEnum.BirthCertificateForeign
    );

  const checkTypeIssuePlaceRequired = (values: RequestData): boolean =>
    Boolean(
      values.child.documentTypeId === DocumentTypeEnum.BirthCertificateForeign ||
        values.child.documentTypeId === DocumentTypeEnum.BirthCertificate
    );

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(new Date())}
              <Push size={20} orientation="horizontal" />
              Источник: Оператор
            </div>
          </>
        }
        buttonBack
        buttonBackFn={() => window.open(routes.requests, '_self')}
        title="Новое заявление"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Новое заявление' },
        ]}
      />

      <Formik
        initialErrors={initialErrors}
        validateOnMount
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValue}
        validationSchema={getValidationSchema}
      >
        {(formikProps: FormikProps<RequestData>) => {
          const { handleSubmit, isSubmitting, isValid, values, initialValues } = formikProps;

          const isBirthRecord = values.child.documentTypeId === DocumentTypeEnum.BirthRecord;

          return (
            <form onSubmit={handleSubmit}>
              <Push size={12} />

              <Panel title={() => 'Информация о ребенке'}>
                <div className="container">
                  <StatusChild contingentLinkTypeId={values.contingentLinkTypeId} />
                  <Push size={20} />
                  <div className="table-data">
                    <DependentDocument>
                      <WrapperFields hasSearchOrganization />
                      <Gender editMode required name="child.sex" />
                      <WrapperFieldsDocument formType={FormTypeEnum.EditRequestDayCare} />
                      {isBirthRecord ? null : (
                        <Issued
                          name="child.issued"
                          editMode
                          required={(checkIssuedRequired(values) && hasEdTypeOrPupilId) || checkIssuedRequired(values)}
                          showErrorImmediately={checkIssuedRequired(values) && hasEdTypeOrPupilId}
                        />
                      )}
                      <IssuedPlace
                        name="typeIssuePlace"
                        parent="child"
                        editMode
                        required={
                          (checkTypeIssuePlaceRequired(values) && hasEdTypeOrPupilId) ||
                          checkTypeIssuePlaceRequired(values)
                        }
                        vedomstvoId={userProfile?.vedomstvoId}
                        showErrorImmediately={checkTypeIssuePlaceRequired(values) && hasEdTypeOrPupilId}
                      />
                      <Snils name="child.snils" editMode disabled />
                      <MoscowRegistration name="child.moscowRegistration" editMode />
                    </DependentDocument>
                  </div>
                </div>
              </Panel>

              <Push size={12} />

              <Panel title={() => 'Общеобразовательная организация'}>
                <div className="container">
                  <div className="table-data">
                    <OrganizationField
                      editMode
                      required
                      parent="school"
                      name="schoolOrganization"
                      setClassParallelIdKey={setClassParallelIdKey}
                      setClassLetterIdKey={setClassLetterIdKey}
                      setServiceIdKey={setServiceIdKey}
                      initialValues={initialValues}
                    />
                    <ClassParallelLetter
                      parent="school"
                      editMode
                      classParallelIdKey={classParallelIdKey}
                      classLetterIdKey={classLetterIdKey}
                      setClassLetterIdKey={setClassLetterIdKey}
                    />
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация о заявителе'}>
                <div className="container">
                  <div className="table-data">
                    <DependentDocument>
                      <RepresentationType name="typeRepresentation" parent="applicant" required editMode>
                        <Gender isNotTableMain name="sex" parent="applicant" required editMode size={1} />
                      </RepresentationType>
                      <FIO label="ФИО заявителя" required editMode parent="applicant" />
                      <BirthDate name="dateOfBirth" parent="applicant" editMode required />
                      <Phone
                        name="applicant.phone"
                        editMode
                        required
                        showErrorImmediately={!!initialValue.applicant?.phone}
                      />
                      <Email name="applicant.email" editMode />
                      <Document
                        name="documentType"
                        parent="applicant"
                        editMode
                        dependentFields
                        formType={FormTypeEnum.EditRequestApplicant}
                      />
                      <Issued name="applicant.issued" editMode />
                      <DocumentNumber parent="applicant" editMode showErrorImmediately calendarPosition="top-end" />
                      <Snils name="applicant.snils" editMode />
                    </DependentDocument>
                  </div>
                </div>
              </Panel>

              <Push size={12} />
              <Panel title={() => 'Информация о группе по присмотру и уходу за детьми школьного возраста'}>
                <div className="container">
                  <div className="table-data">
                    <Service
                      menuPlacement="top"
                      name="service"
                      parent="trainingGroup"
                      editMode
                      required
                      serviceIdKey={serviceIdKey}
                    />
                    <AdmissionPlan name="scheduleOfTimetableId" parent="trainingGroup" editMode required />
                    <VisitDays
                      name="desiredDays"
                      parent="trainingGroup"
                      editMode
                      setServiceClassList={setServiceClassList}
                      initialDataServiceClassList={initialDataServiceClassList}
                      required
                      initialValues={initialValues}
                    />
                    <ServiceClassField
                      name="serviceClass"
                      parent="trainingGroup"
                      editMode
                      serviceClassList={serviceClassList}
                      setServiceClassList={setServiceClassList}
                      setInitialDataServiceClassList={setInitialDataServiceClassList}
                      required
                      setBooking={setBooking}
                      booking={booking}
                    />
                  </div>
                </div>
              </Panel>

              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => window.open(generateLink(routes.requests, {}), '_self')}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid || !booking}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <LoadingModal showModal={loading} />
    </>
  );
};

export const trainingGroupSchema = objectYup().shape({
  serviceId,
  serviceClass: objectYup({
    id: numberYup().required('Укажите группа по уходу и присмотру за детьми школьного возраста'),
  }),
  scheduleOfTimetableId: scheduleOfTimetable,
  desiredDays: numberYup().moreThan(0, 'Укажите дни для посещения').required('Укажите дни для посещения'),
});

const schoolSchema = objectYup().shape({
  schoolOrganizationId: organizationId,
  classParallelId,
});

const getValidationSchema = () =>
  objectYup().shape({
    child: childrenValidationSchema,
    applicant: applicantValidationSchema,
    trainingGroup: trainingGroupSchema,
    school: schoolSchema,
  });

export const childrenValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  sexId,
  documentTypeId: stringRequired,
  series: documentSeries(true),
  number: documentNumber(true),
  dateOfIssue: dateOfIssue(true),
  issued,
  typeIssuePlaceId: issuedPlace,
  snils,
});

export const applicantValidationSchema = objectYup().shape({
  typeRepresentationId: stringRequired,
  sexId,
  firstName,
  lastName,
  middleName,
  email: nullableEmail,
  phone,
  dateOfBirth,
  issued: issuedNullable,
  documentTypeId: stringRequired,
  series: documentSeries().nullable(),
  number: documentNumber().nullable(),
  dateOfIssue: dateOfIssue(),
});

export default DayCareRequestCreate;
