import { SelectOptionType, SelectValueType } from '@mosru/esz_uikit';
import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { LookupResponse } from '../../types/entities';
import { transformSelectOptions } from '../utils';

const responseMapAdditional = (data: LookupResponse[]): SelectOptionType[] =>
  data.map((item: LookupResponse) => ({
    value: item.id,
    label: item.name,
    additionalPropertiesJson: item.additionalPropertiesJson,
  }));

const getOrganization = async (
  query: string,
  vedomstvoId: SelectValueType | undefined = undefined,
  isForDayCareSchool = false
): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/lookup/organization`, {
    name: query,
    vedomstvoId,
    isForDayCareSchool,
  });
  return responseMapAdditional(data.items);
};

export const getOrganizationMrsd = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/OrganizationMrsd`, { name: query });
  return transformSelectOptions(data.items);
};

const getDisciplines = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/lookup/discipline`, { name: query });
  return transformSelectOptions(data.items);
};

const getService = async (
  query: string | undefined,
  educationTypeId: SelectValueType | undefined = undefined,
  vedomstvoId: SelectValueType | undefined = undefined,
  organizationId: SelectValueType | undefined = undefined,
  classParallelId: SelectValueType | undefined = undefined,
  isOnlyActual = true,
  opened = false,
  count?: number
): Promise<SelectOptionType[]> => {
  const option = {
    edType: educationTypeId,
    vedId: vedomstvoId,
    orgId: organizationId,
    onlyActual: isOnlyActual,
    classParallelId,
    opened,
    count,
  };

  const data = await fetchRequest.get(
    `${apiService.data}/Lookup/ServiceIndex`,
    query ? { ...{ name: query }, ...option } : option
  );
  return responseMapAdditional(data.items);
};

const getServiceClass = async (
  query: string,
  serviceId?: number,
  edType?: number,
  count = 14,
  organizationId?: number
): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/ServiceClassIndex`, {
    name: query,
    serviceId,
    edType,
    count,
    organizationId,
  });
  return transformSelectOptions(data.items);
};

export const getClassificator = async (
  query: string,
  edType?: number,
  organizationId?: number
): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/Classificator`, {
    name: query,
    edType,
    orgId: organizationId,
  });

  return data.items.map((item: LookupResponse) => {
    const additional = JSON.parse(item.additionalPropertiesJson);

    return {
      value: item.id,
      label: item.name,
      properties: additional.ProgrammLevel.map((item: { Id: number; Name: string }) => ({
        label: item.Name,
        value: item.Id,
      })),
    };
  });
};

export const getTeachers = async (
  query: string,
  orgId: number | null | undefined,
  showAll?: boolean,
  count = 8
): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/Teacher`, { name: query, orgId, count, showAll });
  return data.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, additionalPropertiesJson: item.additionalPropertiesJson };
  });
};

const getAddress = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/Address`, { name: query });
  return data.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, additionalPropertiesJson: item.additionalPropertiesJson };
  });
};

const getOrganizationOC = async (query: string, isShort?: boolean): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/OrganizationOC`, { name: query });
  return data.items.map((item: LookupResponse) => {
    const additional = JSON.parse(item.additionalPropertiesJson);

    return {
      value: item.id,
      label: isShort ? additional.ShortName : item.name,
      properties: { ...additional, OrgName: item.name },
    };
  });
};

const getModuleOc = async (query: string, serviceId: SelectValueType | undefined): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/ModuleOC`, { name: query, serviceId });
  return data.items.map((item: LookupResponse) => {
    const additional = JSON.parse(item.additionalPropertiesJson);

    return {
      value: additional.ModuleId,
      label: additional.ModuleName,
    };
  });
};

const getDocumentReacceptServices = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/DocumentReacceptServiceIndex`, { name: query });
  return data.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, additionalPropertiesJson: item.additionalPropertiesJson };
  });
};

const getServiceOc = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/ServiceOC`, { name: query });
  return data.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, properties: JSON.parse(item.additionalPropertiesJson) };
  });
};

const getPersonPosition = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/lookup/PersonPosition`, { name: query });
  return transformSelectOptions(data.items);
};

export const getSportObject = async (query: string): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/lookup/SportObject`, { name: query });
  return data.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, additionalPropertiesJson: item.additionalPropertiesJson };
  });
};

export const getPersonalAddressStreet = async (
  query: string,
  districtId: number | null = null
): Promise<SelectOptionType[]> => {
  const response = await fetchRequest.get(`${apiService.data}/lookup/PersonalAddress/Street`, {
    name: query,
    districtId,
  });
  return response?.data?.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, additionalPropertiesJson: item.additionalPropertiesJson };
  });
};

export const getArea = async (query: string, districtId: number | null = null): Promise<SelectOptionType[]> => {
  const response = await fetchRequest.get(`${apiService.data}/lookup/Area`, { name: query, districtId });
  return response?.data?.items.map((item: LookupResponse) => {
    return { value: item.id, label: item.name, additionalPropertiesJson: item.additionalPropertiesJson };
  });
};

export const getPlaceService = async (
  query: string,
  vedId: number | null | undefined,
  orgId: number | undefined,
  edType: number | undefined
): Promise<SelectOptionType[]> => {
  const response = await fetchRequest.get(`${apiService.data}/Lookup/PlaceService`, {
    name: query,
    vedId,
    orgId,
    edType,
  });

  return response?.items.map((item: LookupResponse) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export const getRegistryOffice = async (query: string, count = 15): Promise<SelectOptionType[]> => {
  const data = await fetchRequest.get(`${apiService.data}/Lookup/RegistryOffice`, { name: query, count });
  return responseMapAdditional(data.data.items);
};

// Тут собраны методы по всем typeahead
const lookupApi = {
  getPersonPosition,
  getTeachers,
  getModuleOc,
  getAddress,
  getServiceOc,
  getOrganization,
  getDisciplines,
  getService,
  getServiceClass,
  getClassificator,
  getOrganizationMrsd,
  getOrganizationOC,
  getDocumentReacceptServices,
  getSportObject,
  getPersonalAddressStreet,
  getArea,
  getPlaceService,
  getRegistryOffice,
};

export default lookupApi;
