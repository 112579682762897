import React from 'react';
import { useFormikContext } from 'formik';
import { Switcher } from '@mosru/esz_uikit';
import { editSex } from '../../../../../../lib/utils/learners';
import { Learner } from '../../../../../../types/learners';

type Props = { editMode: boolean };

export const Gender = ({ editMode }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const genderOptions = [
    {
      label: 'Мужской',
      value: 1,
    },
    {
      label: 'Женский',
      value: 2,
    },
  ];

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Пол {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body">
        {editMode ? (
          <Switcher size="small" btnWidthAuto>
            {genderOptions.map((item) => (
              <button
                key={item.value}
                type="button"
                onClick={() => setFieldValue('sexId', item.value)}
                className={editSex(Number(values.sexId)) === item.label ? 'active' : ''}
              >
                {item.label}
              </button>
            ))}
          </Switcher>
        ) : (
          editSex(Number(values.sexId))
        )}
      </div>
    </div>
  );
};
