import React, { useContext, useState } from 'react';
import { FieldArray, useField } from 'formik';
import { Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconEditGray } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import AddModule from '../../components/modals/add-module';
import { ProgrammModule } from '../../../../types/entities';
import { CreateServiceContext } from '../../create';

const Module: React.FC = () => {
  const { serviceData } = useContext(CreateServiceContext);

  const [showModal, setShowModal] = useState(false);
  const [editableItem, setEditableItem] = useState<ProgrammModule | undefined>();

  const [programmModule] = useField<ProgrammModule[]>('programmModule');

  return (
    <FieldArray
      name="programmModule"
      render={(arrayHelpers) => {
        const submitForm = async (values: ProgrammModule) => {
          if (editableItem?.count) {
            arrayHelpers.replace(editableItem.count - 1, values);
          } else {
            arrayHelpers.push(values);
          }
          setShowModal(false);
          setEditableItem(undefined);
        };
        return (
          <>
            <Push size={12} />
            <Panel
              title={() => (
                <>
                  Модули <span className="table-data__required" />{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {programmModule.value?.length}
                  </span>
                </>
              )}
              headingControl={() => (
                <button type="button" onClick={() => setShowModal(true)} className="icon-group">
                  <span className="icon-group__icon">
                    <IconPlus />
                  </span>
                  <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
                </button>
              )}
            >
              {programmModule.value?.length ? (
                <Table
                  overflow
                  data={programmModule.value?.map((item, index) => ({ ...item, count: index + 1 }))}
                  hideSort
                  columns={[
                    {
                      dataIndex: 'count',
                      title: '№',
                      render: (item: any) => item.count,
                      width: '40px',
                    },
                    {
                      dataIndex: 'name',
                      title: 'Наименование',
                      render: (item: any) => <div className="overflow-wrap-anywhere">{item.name}</div>,
                    },
                    {
                      dataIndex: '',
                      title: '',
                      render: (item: any) => (
                        <DropDown
                          component={() => (
                            <span className="drop-down-btn-round">
                              <IconDots />
                            </span>
                          )}
                        >
                          <div className="drop-down-panel">
                            <div className="drop-down-panel__list">
                              <button
                                type="button"
                                onClick={() => {
                                  setShowModal(true);
                                  setEditableItem(item);
                                }}
                                className="drop-down-panel__list-item"
                              >
                                <IconEditGray />
                                Редактировать
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(item.count - 1);
                                }}
                                className="drop-down-panel__list-item"
                              >
                                <IconRemove />
                                Удалить
                              </button>
                            </div>
                          </div>
                        </DropDown>
                      ),
                      width: '40px',
                    },
                  ]}
                />
              ) : (
                <div className="table-no-data">Нет данных</div>
              )}
            </Panel>

            <AddModule
              show={showModal}
              hideModal={() => {
                setShowModal(false);
                setEditableItem(undefined);
              }}
              module={editableItem}
              onSubmit={submitForm}
              serviceData={serviceData}
            />
          </>
        );
      }}
    />
  );
};

export default Module;
