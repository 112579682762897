import React, { ChangeEvent, ReactNode, Ref, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Input, Infobox, Select, SelectOptionType, Panel, Toggle, Switcher, Push } from '@mosru/esz_uikit';
import { timeMask, timeMaskFunction } from '../../lib/utils/mask';
import { ReactComponent as IconClock } from '../../assets/images/icons/clock-color.svg';

export type ScheduleType = {
  id: number;
  name: string;
  from: string;
  to: string;
  dayOff: boolean;
  period?: number;
};

type EditScheduleProps = {
  title: ReactNode;
  scheduleData: ScheduleType[];
  submit: boolean;
  submitError: () => void;
  errorSchedule?: (value: boolean) => void;
  submitSuccess: (schedule: ScheduleType[]) => void;
  extendedOnly?: boolean;
  showCustomPeriod?: boolean;
  headingControl?: ReactNode;
  setCurrentErrorSchedule?: (value: boolean) => void;
  extendedButtonRef?: Ref<HTMLButtonElement>;
  notExtendedButtonRef?: Ref<HTMLButtonElement>;
  timeRoundingUp?: boolean;
  hours24?: boolean;
  isHiding?: boolean;
  textInfoBox?: string;
  disabled?: boolean;
};

const EditSchedule: React.FC<EditScheduleProps> = ({
  errorSchedule,
  title,
  scheduleData,
  submit,
  submitError,
  submitSuccess,
  extendedOnly,
  showCustomPeriod,
  headingControl,
  setCurrentErrorSchedule,
  extendedButtonRef,
  notExtendedButtonRef,
  timeRoundingUp,
  hours24,
  isHiding,
  textInfoBox,
  disabled,
}) => {
  const scheduleExtendedData = useMemo(() => scheduleData.slice(0, 7), [scheduleData]);
  const scheduleWeekendData = useMemo(() => scheduleData.slice(7), [scheduleData]);

  const [scheduleExtended, setScheduleExtended] = useState(
    extendedOnly || scheduleExtendedData.some((day) => !day.dayOff)
  );
  const [validateMode, setValidateMode] = useState(false);
  const [schedule, setSchedule] = useState(scheduleExtended ? scheduleExtendedData : scheduleWeekendData);

  const removeEmptyCharacters = (time: string) => {
    return time.replace(/\s/g, '');
  };
  const inputChange = useCallback(
    (item, match, ev: ChangeEvent<HTMLInputElement>, round: boolean) => {
      const newSchedule = schedule.map((current) => {
        if (current.name === item.name) {
          return {
            ...current,
            [match]: round ? roundingUpTime(removeEmptyCharacters(ev.target.value), timeRoundingUp) : ev.target.value,
          };
        } else {
          return current;
        }
      });
      setSchedule(newSchedule);
    },
    [schedule, timeRoundingUp]
  );

  const validateTime = (from: string, to: string): boolean => {
    return moment(from, 'HH:mm').isAfter(moment(to, 'HH:mm')) || moment(from, 'HH:mm').isSame(moment(to, 'HH:mm'));
  };

  useEffect(() => {
    let listError: string[] = [];

    for (const key in schedule) {
      const from = schedule[key].from.length;
      const to = schedule[key].to.length;

      if (!schedule[key].dayOff) {
        if (from + to === 10) {
          listError = [...listError, 'success'];
        } else {
          listError = [...listError, 'error'];
        }
      }
    }

    if (listError.length) {
      const check = listError.every((item) => item === 'success');

      if (errorSchedule) {
        errorSchedule(check);
      }
    } else if (errorSchedule) {
      errorSchedule(false);
    }
  }, [errorSchedule, schedule]);

  const roundingUpTime = (str: string, timeRoundingUp?: boolean) => {
    const splitAr = str.split(':');
    let result: string | undefined;

    if (splitAr.length === 2) {
      if (splitAr[0] && splitAr[1] && timeRoundingUp) {
        const step = 5;
        const maxMinute = 60 - step;
        const padLength = 2;
        const minutes = parseInt(splitAr[1], 10);
        const round = Math.round(minutes / step) * step;
        let roundMinutes = '00';

        if (minutes <= maxMinute) {
          if (minutes > round) {
            roundMinutes = String(round + step);
          } else {
            roundMinutes = String(round);
          }
        }

        result = `${splitAr[0]}:${roundMinutes.padStart(padLength, '0')}`;
      }
      if (splitAr[0] && !splitAr[1].trim()) {
        result = `${splitAr[0]}:00`;
      }
      if (splitAr[0] && splitAr[1].trim().length === 1) {
        result = `${splitAr[0]}:${splitAr[1].trim()}0`;
      }

      if (splitAr[0].length === 1 && result) {
        result = `0${result}`;
      }
    }

    return result ?? str;
  };

  useEffect(() => {
    const error =
      (showCustomPeriod
        ? schedule.some(
            (item) =>
              !item.dayOff &&
              item.period === 3 &&
              (!timeMask.test(item.from) || !timeMask.test(item.to) || validateTime(item.from, item.to))
          )
        : schedule.some(
            (item) =>
              !item.dayOff && (!timeMask.test(item.from) || !timeMask.test(item.to) || validateTime(item.from, item.to))
          )) || schedule.every((item) => item.dayOff);
    // Проверка для блокировки кнопки "Сохранить"

    if (setCurrentErrorSchedule) {
      if (error && !isHiding) {
        setCurrentErrorSchedule(true);
      } else {
        setCurrentErrorSchedule(false);
      }
    }

    if (submit) {
      !validateMode && setValidateMode(true);
      if (error && !isHiding) {
        submitError();
      } else {
        submitSuccess(schedule);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, submit, validateMode, showCustomPeriod, isHiding]);

  useEffect(() => {
    if (isHiding) {
      setSchedule(scheduleWeekendData);
    }
  }, [isHiding, scheduleWeekendData]);

  return (
    <Panel
      hideHeading={!!(extendedButtonRef && notExtendedButtonRef)}
      title={() => (typeof title === 'function' ? title() : title)}
      headingControl={() =>
        extendedOnly || isHiding || disabled ? null : (
          <div
            data-test="schedule"
            className="flex items-center"
            style={extendedButtonRef && notExtendedButtonRef ? { display: 'none' } : {}}
          >
            <Switcher size="small">
              <button
                data-test="schedule-btn-weekend"
                ref={notExtendedButtonRef}
                type="button"
                onClick={() => {
                  setSchedule(scheduleWeekendData);
                  setScheduleExtended(false);
                  setValidateMode(false);
                }}
                className={!scheduleExtended ? 'active' : ''}
              >
                Будни/выходные
              </button>
              <button
                data-test="schedule-btn-extended"
                ref={extendedButtonRef}
                type="button"
                onClick={() => {
                  setSchedule(scheduleExtendedData);
                  setScheduleExtended(true);
                  setValidateMode(false);
                }}
                className={scheduleExtended ? 'active' : ''}
              >
                Детально по дням
              </button>
            </Switcher>
            {typeof headingControl === 'function' && headingControl()}
          </div>
        )
      }
    >
      {isHiding ? (
        <div className="container">
          <Infobox fullWidth color="primary" text={textInfoBox ?? ''} />
          <Push size={24} />
        </div>
      ) : (
        <div className="table-data-striped table-data-striped--border-bottom">
          {schedule.map((item) => (
            <div key={item.name} data-test={item.name} className="table-data-striped__item items-center">
              <div className="table-data-striped__label table-data-striped__label--edit">
                <div className="flex items-center">
                  <Toggle
                    labelId={item.name}
                    size="xsmall"
                    disabled={disabled}
                    checked={!item.dayOff}
                    onChange={(checked) => {
                      const newSchedule = schedule.map((current) => {
                        if (current.name === item.name) {
                          const newCurrent = {
                            ...current,
                            dayOff: !checked,
                            period: showCustomPeriod ? 1 : 3,
                            ...(!checked && { from: '' }),
                            ...(!checked && { to: '' }),
                          };
                          return newCurrent;
                        } else {
                          return current;
                        }
                      });
                      setSchedule(newSchedule);
                    }}
                  />
                  <Push size={12} orientation="horizontal" />
                  <label htmlFor={item.name}>{item.name}</label>
                </div>
              </div>
              <div className="table-data-striped__body">
                {item.dayOff ? (
                  <span
                    data-test="schedule-day-off"
                    className="flex items-center color-gray-dark"
                    style={{ minHeight: '36px' }}
                  >
                    нерабочий
                  </span>
                ) : (
                  <div className="flex items-center">
                    {/* Требуется доработка */}
                    {showCustomPeriod && (
                      <>
                        <div style={{ width: 120 }}>
                          <Select
                            name="period"
                            size="small"
                            disabled={disabled}
                            hideClearIndicator
                            isSearchable={false}
                            value={period.find((s) => s.value === item.period)}
                            onChange={(selectedOption) => {
                              const val = selectedOption as SelectOptionType;
                              const newSchedule = schedule.map((current) =>
                                current.name === item.name
                                  ? {
                                      ...current,
                                      period: val.value as number,
                                      ...(val.value === 3 && { from: '' }),
                                      ...(val.value === 3 && { to: '' }),
                                    }
                                  : current
                              );
                              setSchedule(newSchedule);
                            }}
                            options={period}
                          />
                        </div>
                        <Push size={12} orientation="horizontal" />
                      </>
                    )}
                    {(item.period === 3 || !showCustomPeriod) && (
                      <>
                        <div className="org-input-time">
                          <Input
                            disabled={disabled}
                            mask={(value) => timeMaskFunction(value, hours24)}
                            size="small"
                            error={
                              (validateMode && !item.dayOff && !timeMask.test(item.from) && !item.from) ||
                              validateTime(item.from, item.to)
                                ? 'error'
                                : ''
                            }
                            value={item.from}
                            placeholder="00:00"
                            onChange={(ev) => inputChange(item, 'from', ev, false)}
                            onBlur={(ev) => inputChange(item, 'from', ev, true)}
                            iconRight={() => <IconClock />}
                          />
                        </div>
                        <Push size={8} orientation="horizontal" />
                        —
                        <Push size={8} orientation="horizontal" />
                        <div className="org-input-time">
                          <Input
                            mask={(value) => timeMaskFunction(value, hours24)}
                            size="small"
                            error={
                              (validateMode && !item.dayOff && !timeMask.test(item.to) && !item.to) ||
                              validateTime(item.from, item.to)
                                ? 'error'
                                : ''
                            }
                            value={item.to}
                            disabled={disabled}
                            placeholder="00:00"
                            onChange={(ev) => inputChange(item, 'to', ev, false)}
                            onBlur={(ev) => inputChange(item, 'to', ev, true)}
                            iconRight={() => <IconClock />}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </Panel>
  );
};

export default EditSchedule;

export const period = [
  {
    label: 'Утро',
    value: 1,
  },
  {
    label: 'День',
    value: 2,
  },
  {
    label: 'Точно',
    value: 3,
  },
  {
    label: 'Вечер',
    value: 4,
  },
];
