import React, { useContext, useMemo, useState } from 'react';
import { Badge, Button, ButtonGroup } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import Favorite from '../../../components/favorite';
import ServiceDetails from './details';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { ServiceContext } from '../index';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { getHeaderLink } from '../utils';
import { generateLink } from '../../../lib/utils';
import ToArchiveModal from '../components/modals/to-archive';
import { ReactComponent as IconRefresh } from '../../../assets/images/icons/refresh.svg';
import RecoverModal from '../components/modals/recover';

const ServiceDKGM = () => {
  const { serviceData, educationTypes, type, accessPanelEdit, accessAddArchive, canRecover, routeBack } =
    useContext(ServiceContext);

  const [activeTab, setActiveTab] = useState('Основные сведения');
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);

  const checkAccessAddArchive = useMemo(() => {
    return (
      serviceData.serviceStatusId === ServiceStatusEnum.Draft &&
      accessPanelEdit &&
      accessAddArchive &&
      (!serviceData.info?.hasMegaRelations || serviceData.info?.hasAllMegaRelationsExcludedOrChanged)
    );
  }, [
    serviceData.serviceStatusId,
    serviceData.info?.hasMegaRelations,
    serviceData.info?.hasAllMegaRelationsExcludedOrChanged,
    accessPanelEdit,
    accessAddArchive,
  ]);
  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <ServiceDetails />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name="сведения о программе"
            extendEntityGuid={serviceData.extendEntityGuid}
            entityTypes={[EntityTypeEnum.Service]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [serviceData.extendEntityGuid]
  );

  const getStatus = (status?: ServiceStatusEnum) => {
    switch (status) {
      case ServiceStatusEnum.Arhive:
        return <Badge text="Архивная" color="default" />;
      case ServiceStatusEnum.Draft:
        return <Badge text="Не опубликовано на Mos.ru" color="warning" />;
      case ServiceStatusEnum.Signed:
        return <Badge text="Опубликовано на Mos.ru" color="success" />;
      default:
        return <Badge text="Архивная" color="default" />;
    }
  };

  return (
    <>
      <PageHeading
        buttonBack
        routeBack={routeBack}
        title="Образовательная услуга"
        sections={[
          { title: 'Главная', link: routes.main },
          {
            title: 'Образовательные услуги',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          { title: 'Описание образовательной услуги' },
        ]}
        tabsButton={getStatus(serviceData.serviceStatusId)}
        controlsTopRight={
          isArchive ? (
            canRecover && (
              <ButtonGroup>
                <Button
                  label="Восстановить"
                  iconLeft={() => <IconRefresh />}
                  size="small"
                  handleClick={() => setShowModalRecover(true)}
                />
              </ButtonGroup>
            )
          ) : (
            <ButtonGroup>
              {checkAccessAddArchive ? (
                <Button
                  label="В архив"
                  iconLeft={() => <IconArchive />}
                  size="small"
                  handleClick={() => setShowModalArchive(true)}
                />
              ) : null}
              <Button
                label="Печать"
                iconLeft={() => <IconPrinter />}
                handleClick={() =>
                  window.open(
                    generateLink(routes.printService, {
                      id: serviceData.id,
                    }),
                    '_blank'
                  )
                }
                size="small"
              />
            </ButtonGroup>
          )
        }
        tabs={tabs}
        activeTab={activeTab}
        expansion={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      {tabs.find((item) => item.label === activeTab)?.component}
      <ToArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
      <RecoverModal onCloseHandler={() => setShowModalRecover(false)} show={showModalRecover} />
    </>
  );
};

export default ServiceDKGM;
