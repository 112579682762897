import React, { useEffect, useState, createContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import TeacherForm from './form/index';
import ChangeHistory from '../../../components/change-history';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import teacherApi from '../../../lib/api/teacher';
import { Teacher as TeacherType } from '../../../types/teacher';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { hasAccessObjectAny } from '../../../lib/utils';
import AddArchiveModal from './modal/add-archive';

export type TeacherContextType = {
  loading: boolean;
  teacher: TeacherType | undefined;
};

export const TeacherContext = createContext<TeacherContextType>({} as TeacherContextType);

const Teacher = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [activeTab, setActiveTab] = useState('Основные сведения');
  const [teacher, setTeacher] = useState<TeacherType | undefined>(undefined);

  const { id } = useParams<Record<string, string | undefined>>();

  const [loading, setLoading] = useState<boolean>(true);
  const [fullName, setFullName] = useState<string>('');

  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);

  const getTeacher = useCallback(async () => {
    if (id) {
      try {
        const data = await teacherApi.getTeacher(id);
        setTeacher(data);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    getTeacher();
  }, [id, getTeacher]);

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: teacher && <TeacherForm setFullName={setFullName} teacher={teacher} />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменения',
        component: (
          <ChangeHistory
            name="преподавателей"
            entityTypes={[EntityTypeEnum.Person]}
            extendEntityGuid={teacher?.extendEntityGuid}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [teacher]
  );

  const accessAddArchive = hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.Archive);

  const initialValues: TeacherContextType = useMemo(
    () => ({
      loading,
      teacher,
    }),
    [loading, teacher]
  );

  return (
    <TeacherContext.Provider value={initialValues}>
      {!loading && (
        <PageHeading
          title={fullName || teacher?.fullName}
          buttonBack
          routeBack={routes.teachers}
          sections={[
            { title: 'Главная', link: routes.main },
            { title: 'Преподаватели', link: routes.teachers },
            { title: 'Сведения о преподавателе' },
          ]}
          tabs={tabs}
          activeTab={activeTab}
          controlsTopRight={
            <ButtonGroup>
              {!teacher?.isArchive && teacher?.externalSystemId !== 1 && (
                <Button
                  label="В архив"
                  disabled={!accessAddArchive}
                  onClick={() => setShowModalArchive(true)}
                  iconLeft={() => <IconArchive />}
                  size="small"
                />
              )}
            </ButtonGroup>
          }
        />
      )}
      {tabs.find((item) => item.label === activeTab)?.component}

      <AddArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
    </TeacherContext.Provider>
  );
};

export default Teacher;
