import React, { useCallback, useContext, useState } from 'react';
import { Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconEditGray } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import AddModule from '../../components/modals/add-module';
import { ServiceContext } from '../../index';
import { ProgrammModule } from '../../../../types/entities';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import RemoveModal from '../../../../components/remove-modal';

type Props = {
  editable?: boolean;
};

const Module: React.FC<Props> = ({ editable }) => {
  const [showModal, setShowModal] = useState(false);
  const [editableItem, setEditableItem] = useState();
  const [removedId, setRemovedId] = useState(0);

  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const submitForm = useCallback(
    async (values: ProgrammModule) => {
      if (values.id) {
        await serviceTemplateApi.updateModule(values.serviceId, values.id, values);
      } else {
        await serviceTemplateApi.createModule(values.serviceId, values);
      }
      setShowModal(false);
      setEditableItem(undefined);
      updateService();
    },
    [updateService]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Модули <span className="table-data__required" />{' '}
            <span className="color-gray-dark">
              {' \u00A0'} {serviceData?.programmModule?.length || ''}
            </span>
          </>
        )}
        headingControl={() =>
          checkEditable && (
            <button type="button" onClick={() => setShowModal(true)} className="icon-group">
              <span className="icon-group__icon">
                <IconPlus />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          )
        }
      >
        {serviceData?.programmModule?.length ? (
          <Table
            overflow
            data={serviceData?.programmModule?.map((item, index) => ({ ...item, count: index + 1 }))}
            hideSort
            columns={[
              {
                dataIndex: 'count',
                title: '№',
                render: (item: any) => item.count,
                width: '40px',
              },
              {
                dataIndex: 'name',
                title: 'Наименование',
                render: (item: any) => <div className="overflow-wrap-anywhere">{item.name}</div>,
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) =>
                  checkEditable && (
                    <DropDown
                      itemId={item.id}
                      component={() => (
                        <span className="drop-down-btn-round">
                          <IconDots />
                        </span>
                      )}
                    >
                      <div className="drop-down-panel">
                        <div className="drop-down-panel__list">
                          <button
                            type="button"
                            onClick={() => {
                              setShowModal(true);
                              setEditableItem(item);
                            }}
                            className="drop-down-panel__list-item"
                            disabled={!item.canChangeModule}
                          >
                            <IconEditGray />
                            Редактировать
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setRemovedId(item.id);
                            }}
                            className="drop-down-panel__list-item"
                            disabled={!item.canChangeModule}
                          >
                            <IconRemove />
                            Удалить
                          </button>
                        </div>
                      </div>
                    </DropDown>
                  ),
                width: '40px',
              },
            ]}
          />
        ) : (
          <div className="table-no-data">Нет данных</div>
        )}
      </Panel>

      <AddModule
        show={showModal}
        hideModal={() => {
          setShowModal(false);
          setEditableItem(undefined);
        }}
        module={editableItem}
        onSubmit={submitForm}
        serviceData={serviceData}
      />
      <RemoveModal
        title="Модули"
        description="Внимание! Данный объект будет удален из реестра «модули». Хотите продолжить?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await serviceTemplateApi.deleteModule(serviceData.id, removedId);
          await updateService();
          setRemovedId(0);
        }}
      />
    </>
  );
};

export default Module;
