import React, { useContext, useState } from 'react';
import { Button, Infobox, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { ServiceContext } from '../../../index';
import ViewAddressClass from './view';
import EditAddressClass from './edit';
import { PlaceServiceData } from '../../../../../types/service';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { EducationTypeEnum } from '../../../../../types/education-type';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  editCount: 'one' | 'many';
  label?: string;
  textForEmpty?: string;
};

const AddressClass: React.FC<Props> = ({
  setEditModeParent,
  editCount,
  textForEmpty,
  label = `Адрес${editCount === 'many' ? 'а' : ''} проведения занятий`,
}) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [placeServiceData, setPlaceServiceData] = useState<PlaceServiceData[]>([]);
  const [load, setLoad] = useState(false);

  const infoText = `${label} ${
    editCount === 'many' ? 'станут доступными' : 'станет доступным'
  } для ввода после первого сохранения.`;

  const counter =
    setEditModeParent && editMode
      ? placeServiceData.filter(({ isSelected }) => isSelected).length
      : serviceData?.placeService.list.length || null;

  const checkEditable =
    !editMode &&
    setEditModeParent &&
    serviceData.serviceStatusId === ServiceStatusEnum.Draft &&
    accessPanelEdit &&
    (!serviceData.placeService.hasMegaRelations ||
      serviceData.educationTypeId === EducationTypeEnum.SportEducation ||
      serviceData.educationTypeId === EducationTypeEnum.ArtHouseEducation);

  const save = async () => {
    setLoad(true);
    try {
      if (serviceData) {
        await serviceTemplateApi.updatePlaceServiceList(serviceData.id, {
          educationTypeId: serviceData.educationTypeId,
          list: placeServiceData.filter((v) => v.isSelected),
          serviceId: serviceData.id,
        });
      }
    } catch {}

    setLoad(false);
    setEditModeParent && setEditModeParent(null);
    setEditMode(false);
    updateService();
  };

  const handleEdit = () => {
    setEditModeParent && setEditModeParent('address-class');
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditModeParent && setEditModeParent(null);
    setEditMode(false);
    setPlaceServiceData([]);
  };

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            {label}
            <span className="color-gray-dark">
              {' \u00A0'} {counter}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            checkEditable && (
              <button type="button" onClick={handleEdit} className="icon-group">
                <span className="icon-group__icon">
                  <IconEdit />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
              </button>
            )
          );
        }}
      >
        {setEditModeParent ? (
          editMode ? (
            <EditAddressClass
              setPlaceServiceData={setPlaceServiceData}
              placeServiceData={placeServiceData}
              editCount={editCount}
              serviceId={serviceData.id}
              educationType={serviceData.educationTypeId}
            />
          ) : checkEditable && textForEmpty && !counter ? (
            <div className="container">
              <Infobox fullWidth color="danger" text={textForEmpty} />
              <Push size={24} />
            </div>
          ) : (
            <ViewAddressClass />
          )
        ) : (
          <div className="container">
            <Infobox fullWidth color="warning" text={infoText} />
            <Push size={24} />
          </div>
        )}
      </Panel>

      {editMode && setEditModeParent && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button onClick={handleCancel} border primary label="Отмена" />
                <Push size={12} orientation="horizontal" />
                <Button
                  submit
                  onClick={save}
                  load={load}
                  disabled={load || placeServiceData.every(({ isSelected }) => !isSelected)}
                  primary
                  label="Сохранить"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressClass;
