import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  controls: ReactNode;
};

const SavePanel: FC<Props> = ({ controls }) => {
  const portalElement = useMemo(() => {
    const element = document.createElement('div');
    element.className = 'room-save-container room-save-container--portal';
    return element;
  }, []);
  const container = useMemo(() => document.getElementsByClassName('layout__content')[0], []);
  const element = useMemo(() => container.getElementsByClassName('content-holder')[0], [container]);

  useEffect(() => {
    element.after(portalElement);
    return () => {
      container.removeChild(portalElement);
    };
  }, [portalElement, element, container]);

  return ReactDOM.createPortal(
    <div className="room-panel-save">
      <div className="container">
        <div className="room-panel-save__inner">{controls}</div>
      </div>
    </div>,
    portalElement
  );
};

export default SavePanel;
