import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Panel } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { FinancingData } from '../../../../types/service';
import { Dictionary } from '../../components/fields/dictionary';
import SimpleInput from '../../../../components/fields/simple-input';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { TypeFinancingEnum } from '../../../../mock-data/type-financing-enum';
import { priceMask } from '../../../../lib/utils/mask';
import { allowOnlyNumbersNoSpace, checkPercent } from '../../../../lib/utils/validation';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  parent?: string;
  checkEditable?: boolean;
};

const NAME_PERCENT = 'subsidiesPercent';

const Financing: React.FC<Props> = ({ setEditModeParent, parent, editMode, setEditMode, checkEditable }) => {
  const { values, setFieldValue } = useFormikContext<FinancingData>();
  const [key, setKey] = useState(Math.random());
  const [advancedFields, showAdvancedFields] = useState(true);

  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);
  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;
      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  const disabled = useMemo(() => !getFieldValue('canChangeFinancing'), [getFieldValue]);

  const financingType = getFieldValue('typeFinancingId');
  // обнуляем поля если переключаемся на бесплатно
  useEffect(() => {
    if (financingType === TypeFinancingEnum.Free) {
      setFieldValue(getFieldFullName('periodPrice'), undefined);
      setFieldValue(getFieldFullName(NAME_PERCENT), undefined);
      setFieldValue(getFieldFullName('typeValueServiceId'), undefined);
      setKey(Math.random());
    }
    showAdvancedFields(financingType !== TypeFinancingEnum.Free);
  }, [financingType, editMode, getFieldFullName, setFieldValue]);

  const subsidiesPercent = getFieldValue(NAME_PERCENT);

  const handleChangeSubsidiesPercent = useCallback(
    (value: string | number) => {
      setFieldValue(getFieldFullName(NAME_PERCENT), value);
    },
    [setFieldValue, getFieldFullName]
  );

  useEffect(() => {
    checkPercent(subsidiesPercent, handleChangeSubsidiesPercent);
  }, [handleChangeSubsidiesPercent, subsidiesPercent]);
  return (
    <Panel
      title={() => <>Финансирование</>}
      headingControl={() => {
        return checkEditable && !editMode && setEditModeParent && !parent ? (
          <button
            type="button"
            onClick={() => {
              setEditModeParent && setEditModeParent('financing');
              setEditMode && setEditMode(true);
            }}
            className="icon-group"
          >
            <span className="icon-group__icon">
              <IconEdit />
            </span>
            <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
          </button>
        ) : null;
      }}
    >
      <div className="container">
        <Dictionary
          name="typeFinancing"
          parent={parent}
          editMode={editMode}
          label="Тип финансирования"
          dictionaryFunc={() => dictionariesApi.getTypeFinancingList(true)}
          disabled={disabled}
          required
        />
        {advancedFields && (
          <>
            <Dictionary
              name="typeValueService"
              parent={parent}
              editMode={editMode}
              label="Периодичность оплаты"
              disabled={getFieldValue('typeFinancingId') === TypeFinancingEnum.Free || disabled}
              dictionaryFunc={dictionariesApi.getTypeValueServiceList}
              required
            />

            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">
                Стоимость, руб {editMode && <span className="table-data__required" />}
              </div>
              <div className="table-data__body">
                <div style={{ width: 140 }}>
                  {editMode ? (
                    <FormikFormGroup required label="" name={getFieldFullName('periodPrice')}>
                      <FormikInput
                        key={key}
                        name={getFieldFullName('periodPrice')}
                        mask={priceMask}
                        size="small"
                        placeholder="0,00"
                        disabled={getFieldValue('typeFinancingId') === TypeFinancingEnum.Free || disabled}
                        textRight
                        postfix="₽"
                      />
                    </FormikFormGroup>
                  ) : (
                    getFieldValue('periodPrice') || '—'
                  )}
                </div>
              </div>
            </div>

            {editMode ? (
              <SimpleInput
                key={key}
                label="Процент субсидии"
                name={getFieldFullName(NAME_PERCENT)}
                disabled={getFieldValue('financing')?.typeFinancingId === TypeFinancingEnum.Free || disabled}
                placeholder="Введите..."
                editMode
                onKeyPress={allowOnlyNumbersNoSpace}
              />
            ) : (
              getFieldValue('financing')?.typeFinancingId !== TypeFinancingEnum.Free && (
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Процент субсидии</div>
                  <div className="table-data__body">{getFieldValue(NAME_PERCENT) || '—'}</div>
                </div>
              )
            )}
          </>
        )}
      </div>
    </Panel>
  );
};

export default Financing;
