import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import { SearchLearnersInitialFormData } from '../../../../../types/learners';

const SERVICE_ID = 'serviceId';

type Props = {
  serviceKey: number;
  service: SelectOptionType | null;
  educationType: number | null;
  vedomstvo: number | null;
  organization: number | null;
  setService: (value: SelectOptionType | null) => void;
};

export const ServiceField = ({ serviceKey, service, educationType, vedomstvo, organization, setService }: Props) => {
  const { setFieldValue } = useFormikContext<SearchLearnersInitialFormData>();

  const onSelectedValue = (option: SelectOptionType) => {
    if (!option) {
      setService(null);
      setFieldValue(SERVICE_ID, null);
    } else {
      setService(option);
    }
  };

  return (
    <FormikFormGroup name="serviceId" label="Детское объединение / услуга">
      <FormikSelect
        size="small"
        isSearchable
        options={[]}
        name={SERVICE_ID}
        key={serviceKey}
        defaultValue={service}
        selectedValue={onSelectedValue}
        placeholder="Начните вводить название детского объединения или услуги..."
        loadOptions={async (query) => await lookupApi.getService(query, educationType, vedomstvo, organization)}
      />
    </FormikFormGroup>
  );
};
