import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

export type DropDownProps = {
  show?: boolean;
  component: () => ReactNode;
  children: ReactNode;
  itemId?: number;
};

const DropDown: React.FC<DropDownProps> = ({ show: propsShow, component, children, itemId }) => {
  const [id] = useState(itemId || Date.now());
  const [show, setShow] = useState(propsShow || false);

  const hide = useCallback(
    ({ target }) => {
      if (show && !target.closest(`[data-name="popover${id}"]`)) {
        setShow(false);
      }
    },
    [show, id]
  );

  const hideCurrentDropDown = () => setShow(false);

  useEffect(() => {
    document.addEventListener('click', hide, true);
    return () => {
      document.removeEventListener('click', hide, true);
    };
  }, [hide]);

  return (
    <div data-name={`popover${id}`} className={classNames('drop-down', show && 'active')}>
      <button type="button" className="drop-down__button" onClick={() => setShow(!show)}>
        {component()}
      </button>

      <div className="drop-down__body" role="presentation" onClick={hideCurrentDropDown}>
        {children}
      </div>
    </div>
  );
};

export default DropDown;
