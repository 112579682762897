import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel, Push } from '@mosru/esz_uikit';
import { TrainingGroupRequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { getServiceEditLink, hasGeneralAccess } from '../../../lib/utils';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { generalAccess } from '../../../mock-data/access-enum';
import { EducationTypeEnum } from '../../../types/education-type';

type Props = {
  trainingGroup: TrainingGroupRequestData | undefined;
  educationType: EducationTypeEnum;
};

const Association: React.FC<Props> = ({ trainingGroup, educationType }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.AdminView) && hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const accessEducationalOrganization = useMemo(() => {
    return hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) || isAdmin;
  }, [isAdmin, userProfile]);

  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация о детском объединении'}>
        <div className="container">
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Детское объединение</div>
              <div className="table-data__body">
                <Link
                  className="brand-link cursor-pointer"
                  to={() => getServiceEditLink(educationType, trainingGroup?.serviceId)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {trainingGroup?.serviceName}
                </Link>
              </div>
            </div>
            {accessEducationalOrganization && (
              <div className="table-data__item table-data__group">
                <div className="table-data__label table-data__label--main">Образовательная организация</div>
                <div className="table-data__body">
                  <span>{trainingGroup?.organizationName}</span>
                </div>
              </div>
            )}
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Адрес приема документов</div>
              <div className="table-data__body">{trainingGroup?.itemsWorkAddress}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Преподаватель</div>
              <div className="table-data__body">{trainingGroup?.teacherName}</div>
            </div>
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Дата начала занятий</div>
              <div className="table-data__body">
                {trainingGroup?.educationStartDate ? formatDate(trainingGroup.educationStartDate) : ''}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Association;
