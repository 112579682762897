import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { Button, ModalPanel, Modal, SelectOptionType, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceClassTransfer } from '../../../../types/service-class';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import serviceClassApi from '../../../../lib/api/service-class';
import { docDate } from '../../../../lib/utils/validation';
import { ServiceClassTableContext } from '../../utils';
import { EducationTypeEnum } from '../../../../types/education-type';
import { ServiceClassContext } from '../../service-class';

type Props = {
  open: boolean;
  close: () => void;
};

const initialValues: ServiceClassTransfer = {
  megaRelationIds: [],
  serviceClassId: null,
  educationTypeId: 0,
  docNumber: '',
  docDate: '',
  documentTypeId: 3,
  isExamParticipate: false,
};

const TransferGroup: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);
  const { updateServiceClass } = useContext(ServiceClassContext);
  const [documents, setDocuments] = useState<SelectOptionType[]>([]);
  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [serviceClasses, setServiceClasses] = useState<SelectOptionType[]>([]);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema());

  const submitForm = useCallback(
    async (values: ServiceClassTransfer) => {
      setLoadBtn(true);
      try {
        values.megaRelationIds = selected || [];
        values.educationTypeId = serviceClassData.educationTypeId;
        await serviceClassApi.transferClass(serviceClassData.id || 0, values);
        close();
        fetchTable();
        setSelected([]);
        setOpenDetails(false);
        updateServiceClass();
      } finally {
        setLoadBtn(false);
      }
    },
    [
      selected,
      serviceClassData.educationTypeId,
      serviceClassData.id,
      close,
      fetchTable,
      setSelected,
      setOpenDetails,
      updateServiceClass,
    ]
  );

  useEffect(() => {
    const fetch = async () => {
      const documentTypes = await dictionariesApi.getDocumentTypes(3);
      setDocuments(documentTypes);
      const needExcludeServiceId = [EducationTypeEnum.ArtHouseEducation, EducationTypeEnum.SportEducation].includes(
        serviceClassData.educationTypeId
      );

      const serviceClasses = await serviceClassApi.getServiceClassList(
        needExcludeServiceId ? undefined : serviceClassData.serviceId,
        undefined,
        serviceClassData.organizationId,
        serviceClassData.educationTypeId
      );

      const filterServiceClasses = serviceClasses
        .filter((item: { id: number | undefined }) => item.id !== serviceClassData.id)
        .map((item: { id: number; name: string }) => {
          return { value: item.id, label: item.name };
        });

      setServiceClasses(filterServiceClasses);
    };
    fetch();
  }, [
    serviceClassData.organizationId,
    serviceClassData.trainingGroupId,
    serviceClassData.serviceId,
    serviceClassData.educationTypeId,
    serviceClassData,
  ]);

  return (
    <Modal show={open} onClose={close}>
      <Formik
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<ServiceClassTransfer>) => {
          const { handleSubmit, isSubmitting, isValid, values } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={close} />
                    <Push orientation="horizontal" size={12} />
                    <Button
                      label="Перевести"
                      disabled={!isValid || isSubmitting || !values.serviceClassId}
                      primary
                      load={loadBtn}
                      size="small"
                      submit
                    />
                  </>
                )}
                modalTitle="Перевод обучающегося в другую группу обучения"
                renderComponent={() => (
                  <>
                    <FormikFormGroup name="serviceClassId" label="Группа обучения" required showErrorImmediately>
                      <FormikSelect
                        name="serviceClassId"
                        size="small"
                        isSearchable
                        options={serviceClasses}
                        maxMenuHeight={205}
                        placeholder="Выберите..."
                      />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="documentTypeId" label="Тип документа" required>
                      <FormikSelect name="documentTypeId" size="small" options={documents} placeholder="Выберите..." />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="docNumber" label="Номер документа">
                      <FormikInput size="small" name="docNumber" placeholder="Введите..." />
                    </FormikFormGroup>
                    <Push size={16} />
                    <FormikFormGroup name="docDate" label="Дата документа">
                      <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="docDate" />
                    </FormikFormGroup>
                    <Push size={16} />
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TransferGroup;

const getValidationSchema = () =>
  objectYup().shape({
    serviceClassId: stringYup().required('Выберите группу обучения').typeError('Выберите группу обучения'),
    documentTypeId: stringYup().nullable().required('Выберите тип документа'),
    docDate: docDate.nullable(),
  });
