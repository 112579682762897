import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Push } from '@mosru/esz_uikit';
import { userProfileSelector } from '../../redux/selectors';
import { AppState } from '../../redux/types/state';
import { ReactComponent as IconTriangle } from '../../assets/images/icons/alert-triangle.svg';
import history from '../../history';
import { routes } from '../../config/constants';

const UserDataNotice = () => {
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  useEffect(() => {
    if (userProfile) {
      const fields: string[] = [];
      if (!userProfile.fullName?.lastName) {
        fields.push('Фамилия');
      }
      if (!userProfile.fullName?.firstName) {
        fields.push('Имя');
      }
      if (!userProfile.fullName?.middleName && fields.length > 0) {
        fields.push('Отчество (при наличии)');
      }
      if (!userProfile.snils) {
        fields.push('СНИЛС');
      }
      if (fields.length > 0) {
        setMissingFields(fields);
      }
    }
  }, [userProfile]);

  return missingFields.length > 0 ? (
    <>
      <Push size={12} />
      <div className="infobox infobox--warning">
        <div className="infobox__head items-start">
          <div className="infobox__body">
            <div className="flex">
              <IconTriangle className="flex-none" />
              <Push size={8} orientation="horizontal" />
              <div className="line-height-text">
                <span className="font-weight-bold">Отсутствуют данные пользователя. </span>
                Необходимо заполнить следующие поля в личном кабинете:
                <br />
                {`${missingFields.join(', ')}.`}
                <br />
                <br />
                При отсутствии данных ваша учетная запись будет заблокирована в течение 30 дней.
              </div>
            </div>
          </div>
          <div className="infobox__control">
            <Button
              onClick={() => {
                history.push(routes.room);
              }}
              label="Перейти к заполнению"
              primary
              border
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default UserDataNotice;
