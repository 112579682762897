import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { Error } from '../../types/error';

export const saveError = async (formName: string, error: Error): Promise<number> =>
  await fetchRequest.post(`${apiService.data}/saveError/${formName}`, { error });

export const getContractUrl = async (): Promise<string> => await fetchRequest.get(`${apiService.data}/Tss/Contract`);

const homeApi = {
  saveError,
  getContractUrl,
};

export default homeApi;
