import React, { useContext, useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  values: SearchRequestsInitialFormData;
  isArtHouseSportEducation: boolean;
  label: string;
  placeholder: string;
};

const FieldYearOfTraining: React.FC<Props> = ({ values, isArtHouseSportEducation, label, placeholder }) => {
  const [yearOfTrainingData, setYearOfTrainingData] = useState<SelectOptionType[]>([]);

  const { yearOfTrainingKey } = useContext(SearchKeyContext);

  useEffect(() => {
    const fetch = async () => {
      setYearOfTrainingData([{ value: '', label: 'Все' }, ...(await dictionariesApi.getYearOfTrainings())]);
    };
    fetch();
  }, []);

  const [, , helpers] = useField('yearOfTrainingId');
  const [, , helpersName] = useField('yearOfTrainingName');

  const onChange = (option: SelectOptionType) => {
    helpers.setValue(option.value);
    helpersName.setValue(option.label);
  };

  return isArtHouseSportEducation ? (
    <>
      <Push size={16} />

      <FormikFormGroup name="yearOfTrainingId" label={label}>
        <FormikSelect
          key={yearOfTrainingKey}
          name="yearOfTrainingId"
          size="small"
          options={yearOfTrainingData}
          defaultValue={
            values.yearOfTrainingId ? { label: values.yearOfTrainingName ?? '', value: values.yearOfTrainingId } : null
          }
          placeholder={placeholder}
          selectedValue={onChange}
        />
      </FormikFormGroup>
    </>
  ) : null;
};

export default FieldYearOfTraining;
