import React, { useContext, useMemo, useState } from 'react';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import { nullableMaxRusWithSpaceAndNumberStringAndSymbols, nullablePhone } from '../../../../../lib/utils/validation';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { ServiceContext } from '../../../index';
import TemplateContactPerson from './template-contact-person';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { ContactPersonData } from '../../../../../types/service';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const ContactPerson: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);

  const initialValues = useMemo(() => {
    return serviceData?.contactPerson || ({} as ContactPersonData);
  }, [serviceData?.contactPerson]);

  const initialErrors = useInitialErrors(initialValues, contactPersonValidationSchema());
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [loading, setLoading] = useState(false);

  const submitForm = async (values: ContactPersonData) => {
    setLoading(true);
    try {
      if (serviceData?.id) {
        await serviceTemplateApi.updateContactPerson(serviceData.id, values);
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        setLoading(false);
        updateService();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      validationSchema={contactPersonValidationSchema()}
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<ContactPersonData>) => {
        const { values, isValid, handleSubmit, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <TemplateContactPerson
              values={values}
              editMode={editMode}
              setEditMode={setEditMode}
              setEditModeParent={setEditModeParent}
              published={serviceData.serviceStatusId === ServiceStatusEnum.Signed}
              isArchive={serviceData.serviceStatusId === ServiceStatusEnum.Arhive}
              accessEditable={accessPanelEdit}
              organizationId={serviceData?.info?.organizationId}
            />

            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button submit load={loading} disabled={!isValid} primary label="Сохранить" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ContactPerson;

export const contactPersonValidationSchema = () =>
  objectYup().nullable().shape({
    fullName: nullableMaxRusWithSpaceAndNumberStringAndSymbols,
    phone: nullablePhone,
  });
