import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { string as stringYup, object as objectYup } from 'yup';
import { Button, notify, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikToggle from '../../../components/formik/formik-toggle';
import FormikToggleGroup from '../../../components/formik/formik-toggle-group';
import SimpleInput from '../../../components/fields/simple-input';
import Mark from '../components/fields/mark';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { EvaluationData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import { formatDate, formatTime } from '../../../lib/utils/date';

type Props = {
  setEditModeParent: (value: string | null) => void;
  evaluation: EvaluationData;
  editable: boolean;
  requestId: number;
  updateRequest: () => void;
};

const TestResults: React.FC<Props> = ({ setEditModeParent, evaluation, editable, requestId, updateRequest }) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(evaluation, getValidationSchema());

  const submitForm = useCallback(
    async (values: EvaluationData) => {
      try {
        const id = await requestsApi.saveEvaluation(requestId, values);
        setEditModeParent(null);
        setEditMode(false);
        if (id !== requestId) {
          history.push(generateLink(routes.request, { id }));
        } else {
          updateRequest();
        }
      } catch (ex: any) {
        notify.danger({
          data: {
            label: ex.data?.message,
            icon: true,
            close: true,
          },
        });
      }
    },
    [requestId, setEditModeParent, updateRequest]
  );

  return (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={evaluation}
    >
      {(formikProps: FormikProps<EvaluationData>) => {
        const { handleSubmit, isSubmitting, isValid, values } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => (
                <div className="flex items-center">
                  Результаты вступительных испытаний
                  <Push size={16} orientation="horizontal" />
                  <span className="font-weight-base">
                    <FormikToggleGroup name="isEvaluationCompleted" size="small" label="Испытания пройдены">
                      <FormikToggle disabled={!editMode} name="isEvaluationCompleted" size="small" />
                    </FormikToggleGroup>
                  </span>
                </div>
              )}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('results');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <SimpleInput
                    name="testDate"
                    label="Дата и время испытаний"
                    value={
                      !values.testDate && !values.testTimeFrom && !values.testTimeTo
                        ? '—'
                        : `${values.testDate ? formatDate(values.testDate) : ''} ${formatTime(
                            values.testTimeFrom
                          )} - ${formatTime(values.testTimeTo)}`
                    }
                  />
                  <SimpleInput name="addressEvaluation" label="Место проведения испытаний" />
                  <Mark name="markEvaluation" label="Оценка" editMode={editMode} />
                  <SimpleTextArea name="commentEvaluation" label="Комментарий" editMode={editMode} />
                </div>
              </div>
            </Panel>

            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default TestResults;

const getValidationSchema = () =>
  objectYup().shape({
    markEvaluation: stringYup()
      .typeError('Укажите оценку')
      .when('isEvaluationCompleted', { is: true, then: (s) => s.required('Укажите оценку') }),
  });
