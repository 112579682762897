import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, IconSearch, SelectOptionType, Panel, TreeNodeType, Push } from '@mosru/esz_uikit';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as IconOptions } from '../../../assets/images/icons/options.svg';
import { ReactComponent as IconUp } from '../../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../../assets/images/icons/down.svg';
import TreeModal from '../../../components/tree-modal';
import { replaceHistoryState } from '../../../lib/utils';
import { historyState } from '../../../mock-data/history-state';
import FormikInput from '../../../components/formik/formik-input';
import { getCountDiff } from '../../../lib/utils/service';
import { SearchInitialFormData } from '../../../types/service';
import FieldsContainer from './components/fields-container';
import { ServiceRegistryContext } from './initial-data';
import { summElementTree } from '../../../components/tree-modal/helpers';
import { RegistryTypeEnum } from '../../../mock-data/registry-type-enum';
import { sendReachGoal } from '../../../lib/metrica';

type Props = {
  submitForm: (values: SearchInitialFormData) => void;
  setCurrentSearchData: (values: SearchInitialFormData) => void;
  searchPlaceholder: string;
  initialValues: SearchInitialFormData;
};

const Search: React.FC<Props> = ({ submitForm, searchPlaceholder, setCurrentSearchData, initialValues }) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [openTreePopup, setOpenTreePopup] = useState(false);

  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const [dataTreePopup, setDataTreePopup] = useState<TreeNodeType[]>([]);

  const [basicDirection, setBasicDirection] = useState<SelectOptionType | null>();
  const allElementNumbersTreePopupRef = useRef<number>(0);

  // текущее меню в тот момент когда его закрыли
  const [currentMenuClosePopup, setCurrentMenuClosePopup] = useState<SelectOptionType>({ label: '', value: 0 });

  const location = useLocation();
  const history = useHistory();

  const resetState = () => {
    setBasicDirection(null);
    setRerenderFormKey(Math.random());
  };

  const { type, initialData, educationTypeData } = React.useContext(ServiceRegistryContext);

  useEffect(() => {
    allElementNumbersTreePopupRef.current = summElementTree(dataTreePopup);
  }, [dataTreePopup, dataTreePopup.length]);

  const treeModalOptions = educationTypeData.filter((item) => item.label !== 'Все');

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [historyState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      <Formik
        onSubmit={(values, formikHelpers) => {
          sendReachGoal(`onclick-service-search-${values.educationTypeId}`);
          submitForm(values);
          formikHelpers.setSubmitting(false);
        }}
        enableReinitialize
        initialValues={initialValues}
        key={rerenderFormKey}
      >
        {(formikProps: FormikProps<SearchInitialFormData>) => {
          const { handleSubmit, isSubmitting, values, resetForm } = formikProps;
          const { eventId } = initialValues;

          // если мы переходим по ссылке с формы уведомлений увеличиваем count
          const countDiff = getCountDiff(values, initialData) + (eventId ? 1 : 0);

          const onResetForm = () => {
            let newObj: SearchInitialFormData = initialData;

            if (eventId) {
              history.replace(location.pathname);
              replaceHistoryState({ [historyState.openAdvanced]: open });
              const { eventId, ...obj } = initialData;
              newObj = obj;
            }

            resetState();
            resetForm({ values: newObj });
            submitForm(newObj);
            setCurrentSearchData(newObj);
          };

          return (
            <form onSubmit={handleSubmit}>
              <Panel
                subControl={() => (
                  <button type="button" onClick={handleClickAdvancedSearch} className="icon-group">
                    <span className="icon-group__icon">
                      <IconOptions />
                    </span>
                    <span className="icon-group__text">
                      Расширенный поиск{' '}
                      {!open && countDiff > 0 && <span className="icon-group__badge">{countDiff}</span>}{' '}
                    </span>
                    <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
                  </button>
                )}
                controls={() => (
                  <>
                    <Button label="Сбросить" onClick={onResetForm} border size="small" />
                    <Push orientation="horizontal" size={12} />
                    <Button submit label="Начать поиск" load={isSubmitting} primary size="small" />
                  </>
                )}
              >
                <div className="container">
                  <Push size={20} />
                  <FormikInput name="query" placeholder={searchPlaceholder} iconLeft={() => <IconSearch />} />
                  {open && (
                    <FieldsContainer
                      basicDirection={basicDirection}
                      type={type}
                      setOpenTreePopup={setOpenTreePopup}
                      setBasicDirection={setBasicDirection}
                      setCurrentSearchData={setCurrentSearchData}
                    />
                  )}
                </div>
              </Panel>
            </form>
          );
        }}
      </Formik>

      <TreeModal
        open={openTreePopup}
        title={
          type === RegistryTypeEnum.serviceEducationProgramType ? 'Реестр профессий' : 'Реестр базовых направлений'
        }
        closePopup={() => {
          setOpenTreePopup(false);
        }}
        dataTreePopup={dataTreePopup}
        selectOptions={treeModalOptions}
        setDataTreePopup={setDataTreePopup}
        selected={educationTypeData.length !== 1}
        setValueBasicDirection={setBasicDirection}
        setCurrentMenuClosePopup={setCurrentMenuClosePopup}
        currentMenuClosePopup={currentMenuClosePopup}
        placeholderName="Поиск..."
        numberElements={allElementNumbersTreePopupRef.current ?? 0}
      />
    </>
  );
};

export default Search;
