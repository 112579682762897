import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Select, SelectOptionType, Panel, Loader, Push, AsyncTable } from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { pageSizeOptions } from '../../../mock-data';
import { SearchGroupTableItem, SearchGroupTrainingInitialFormData } from '../../../types/service-class';
import { numberWithCommas } from '../../../lib/utils/learners';
import { tableColumn } from './table-column';
import serviceClassApi from '../../../lib/api/service-class';
import { AuthorizationData } from '../../../types/authorization-data';
import { removeEmptyDataForRequest } from '../../../lib/utils/service-class';
import { ServiceClassContext } from '../service-class';
import { maxRegistryRows } from '../../../config/constants';
import { replaceHistoryState, showExcelErrorData } from '../../../lib/utils';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  search: SearchGroupTrainingInitialFormData | undefined;
};

const ServiceClassRegistryTable: React.FC<Props> = ({ search, isAdmin, userProfile }) => {
  const { isRemovedGroup } = useContext(ServiceClassContext);

  const [pageSize, setPageSize] = useState(window.history.state[historyState.pageSize] || pageSizeOptions[0].value);
  const [loading, setLoading] = useState<boolean>(false);
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);

  const [tableData, setTableData] = useState<SearchGroupTableItem[]>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);

  const [loadingFile, setLoadingFile] = useState<boolean>(false);

  const [currentSearch, setCurrentSearch] = useState<SearchGroupTrainingInitialFormData>();

  const fetchData = useCallback(async () => {
    if (currentSearch) {
      setLoading(true);
      const data = JSON.parse(JSON.stringify(currentSearch));

      const response = await serviceClassApi.postServiceClass({
        ...removeEmptyDataForRequest(data, true),
        pageSize,
        pageNumber: pageNum + 1,
      });

      setTableData(response?.items);
      setTableTotal(response?.total);
      setLoading(false);
    }
  }, [pageNum, pageSize, currentSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Для обновления рееестра групп, после удаления группы.
  useEffect(() => {
    // Позже удалить, как бэк сможеть быстрее обновлять список
    if (isRemovedGroup) {
      setTimeout(() => {
        fetchData();
      }, 10000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemovedGroup]);

  useLayoutEffect(() => {
    if (search) {
      setCurrentSearch(search);
      if (initPageNum) {
        setInitPageNum(0);
      } else {
        setPageNum(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const handleExportFile = async () => {
    if (currentSearch) {
      setLoadingFile(true);
      try {
        await serviceClassApi.getServiceClassExcel({
          ...removeEmptyDataForRequest(currentSearch, false),
          pageSize,
          pageNumber: pageNum + 1,
        });
      } catch (e) {
        showExcelErrorData();
      } finally {
        setLoadingFile(false);
      }
    }
  };

  return (
    <Panel
      title={() => (
        <>
          Группы обучения
          <span className="color-gray-dark">
            {' \u00A0'} {numberWithCommas(tableTotal)}
          </span>
        </>
      )}
      headingControl={() => (
        <>
          <Button
            border
            black
            colorIcon
            size="small"
            label="Экспорт"
            load={loadingFile}
            handleClick={handleExportFile}
            iconRight={() => <IconExcel />}
          />
          <Push orientation="horizontal" size={12} />
          <div style={{ width: 184 }}>
            <Select
              name="pageSize"
              size="small"
              hideClearIndicator
              isSearchable={false}
              value={pageSizeOptions.find((item) => item.value === pageSize)}
              onChange={(selectedOption) => {
                const { value } = selectedOption as SelectOptionType;
                setPageSize(value as number);
                setPageNum(0);
              }}
              options={pageSizeOptions}
            />
          </div>
        </>
      )}
    >
      {loading ? (
        <div className="loader-container">
          <Loader title="Загружаем реестр групп обучения" description="Нужно немного подождать" />
        </div>
      ) : tableTotal ? (
        <AsyncTable
          rowLimit={maxRegistryRows}
          overflow
          hideSort
          data={tableData}
          itemsAll={tableTotal}
          pageNum={pageNum}
          pageSize={pageSize}
          setPageNumber={setPageNum}
          columns={tableColumn(userProfile, isAdmin)}
        />
      ) : (
        <div className="table-no-data">Нет данных</div>
      )}
    </Panel>
  );
};

export default ServiceClassRegistryTable;
