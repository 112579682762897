import React, { useEffect, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Radio, Infobox, Loader, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import serviceClassApi, { MAX_LIST_ITEM_COUNT } from '../../../../lib/api/service-class';
import { transformServiceGroupList } from '../../../../lib/utils/requests';
import { routes } from '../../../../config/constants';
import { ScheduleDays, ServiceClassListData } from '../../../../types/service-class';
import { ReactComponent as IconUp } from '../../../../assets/images/icons/up-primary.svg';
import { Statuses } from '../../statuses';
import { checkStatus } from '../../utils';
import { sliceText } from '../../../../lib/utils';

type ServiceClassList = {
  code: string;
  dates: { periodsSchedule: ScheduleDays.TransformDays[] | undefined; stringSchedule: string | string[] | undefined };
  id: number;
  name: number;
  teacher: string;
  volumes: string;
};

type Props = {
  serviceId: number;
  trainingGroupId?: number;
  warningMessage?: string;
  type?: 'EditDogm' | 'EditNonDogm' | 'EditVA' | 'EditOp';
  disabledRadio?: boolean;
  contingentGuid: string;
};

const ServiceClassesEnrollment: React.FC<Props> = ({
  serviceId,
  trainingGroupId,
  warningMessage,
  contingentGuid,
  type,
  disabledRadio = false,
}) => {
  const [serviceClassList, setServiceClassList] = useState<ServiceClassList[]>([]);
  const [loading, setLoading] = useState(false);

  const [loadingCheck, setLoadingCheck] = useState<boolean>(false); // loading проверки расписаний
  const [intersection, setIntersection] = useState<boolean>(false); // пересечение дат

  const [message, setMessage] = useState<string>(''); // сообщение если найдены пересечения
  const [openElement, setOpenElement] = useState(0);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      try {
        const response = await serviceClassApi.getServiceClassList(
          serviceId,
          trainingGroupId,
          undefined,
          undefined,
          undefined,
          MAX_LIST_ITEM_COUNT
        );

        const listGroup = response?.map((sc: ServiceClassListData<ScheduleDays.ScheduleData>) =>
          transformServiceGroupList(sc)
        );

        setServiceClassList(listGroup);

        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (serviceId) {
      fetch();
    }
  }, [contingentGuid, serviceId, trainingGroupId]);

  const renderDates = (periodsSchedule: ScheduleDays.TransformDays[]) => {
    if (periodsSchedule) {
      return periodsSchedule.map((item: ScheduleDays.TransformDays) => {
        const days = (
          <div>
            {editDay(item.monday)}
            {editDay(item.tuesday)}
            {editDay(item.wednesday)}
            {editDay(item.thursday)}
            {editDay(item.friday)}
            {editDay(item.saturday)}
            {editDay(item.sunday)}
          </div>
        );

        return periodsSchedule.length > 1 ? (
          <div className="flex flex-column">
            <div className="flex items-center">
              <span>{item.period}&nbsp;</span>
              <span
                role="presentation"
                className="brand-link cursor-pointer flex items-center"
                onClick={() => (openElement ? setOpenElement(0) : setOpenElement(item.id))}
              >
                {item.startEnd}{' '}
                <span
                  className={classNames('icon-group__icon', !(openElement === item.id) && 'icon-group__icon--rotate')}
                >
                  <IconUp />
                </span>
              </span>
            </div>
            {openElement === item.id && (
              <>
                <Push orientation="vertical" size={8} />
                <div>{days}</div>
                <Push orientation="vertical" size={8} />
              </>
            )}
          </div>
        ) : (
          <div>{days}</div>
        );
      });
    }
  };

  function editDay(day: any) {
    if (Array.isArray(day)) {
      return day.map((item: string) => item);
    } else {
      return day.fromTo ? (
        <div>
          <span className="color-gray-dark">{day.name}&nbsp;&nbsp;</span>
          {day.fromTo}
        </div>
      ) : null;
    }
  }

  return loading ? (
    <div className="flex justify-center flex-column">
      <Push orientation="vertical" size={16} />
      <Loader roller small />
      <Push orientation="vertical" size={16} />
    </div>
  ) : serviceClassList?.length > 0 ? (
    <>
      <Field name="serviceClass.id">
        {(fieldProps: FieldProps) => {
          const { field, form } = fieldProps;

          return (
            <Table
              hideSort
              data={serviceClassList}
              columns={[
                {
                  dataIndex: 'id',
                  title: '',
                  render: (item: any) => (
                    <Radio
                      name="serviceClass.id"
                      onChange={() => {
                        !form.touched[field.name] && form.setFieldTouched(field.name, true);
                        form.setFieldValue(field.name, item.id);
                        checkStatus(item.id, contingentGuid, setMessage, setLoadingCheck, setIntersection);
                      }}
                      checked={item.id === field.value}
                      labelId={item.code}
                      disabled={disabledRadio}
                    />
                  ),
                  width: '60px',
                },
                {
                  dataIndex: 'code',
                  title: 'Код группы',
                  render: (item: any) => (
                    // TODO убрать когда будет сделана Группа обучения
                    <Link
                      className="brand-link cursor-pointer"
                      to={`${routes.serviceClasses}/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.code}
                    </Link>
                  ),
                  width: '10%',
                },
                {
                  dataIndex: 'name',
                  title: 'Наименование группы',
                  render: (item: any) => sliceText(item.name, 80),
                },
                {
                  dataIndex: 'teacher',
                  title: 'Преподаватель',
                  render: (item: any) => item.teacher,
                  width: '17%',
                },
                {
                  dataIndex: 'dates',
                  title: 'Расписание',
                  render: (item: any) => renderDates(item.dates?.periodsSchedule),
                  width: '22%',
                },
                {
                  dataIndex: 'volumes',
                  title: 'Зачислено/Всего',
                  render: (item: any) => item.volumes,
                  width: '17%',
                },
              ]}
            />
          );
        }}
      </Field>

      <Statuses
        message={message}
        intersection={intersection}
        loadingCheck={loadingCheck}
        contingentGuid={contingentGuid}
      />
    </>
  ) : (
    <div className="container">
      <Infobox fullWidth text={warningMessage || ''} color="warning" />
    </div>
  );
};

export default ServiceClassesEnrollment;
