import React, { useEffect, useMemo, useState } from 'react';
import { Panel, Switcher, Push } from '@mosru/esz_uikit';
import ActionsFormEnrollment from './actions-form-enrollment';
import ActionsFormRefusal from './actions-form-refusal';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type Props = {
  setEditModeParent: (value: string | null) => void;
  requestData: RequestData;
  editable: boolean;
  updateRequest: () => void;
};

const ActionsForm: React.FC<Props> = ({ setEditModeParent, requestData, editable, updateRequest }) => {
  const [refusal, setRefusal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const showEnrollment = useMemo(
    () =>
      requestData.requestStatusId !== RequestStatusEnum.Excluded &&
      requestData.requestStatusId !== RequestStatusEnum.IncludeDeclined &&
      requestData.requestStatusId !== RequestStatusEnum.IncludedExcluded,
    [requestData.requestStatusId]
  );
  const showRefusal = useMemo(
    () => requestData.requestStatusId !== RequestStatusEnum.Included,
    [requestData.requestStatusId]
  );

  useEffect(() => {
    setRefusal(
      !requestData.approve?.isEnrollmentApproved ||
        (!requestData.evaluation?.isEvaluationCompleted && requestData.trainingGroup?.isTestService) ||
        !showEnrollment
    );
  }, [requestData, showEnrollment]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() =>
          requestData.requestStatusId === RequestStatusEnum.Included ||
          requestData.requestStatusId === RequestStatusEnum.IncludedExcluded
            ? 'Зачисление'
            : showRefusal && !showEnrollment
            ? 'Отказ в зачислении'
            : 'Действия по заявлению'
        }
        headingControl={() =>
          showEnrollment &&
          showRefusal && (
            <div className="flex items-center">
              <Switcher size="small">
                <button
                  type="button"
                  onClick={() => {
                    setRefusal(false);
                  }}
                  className={refusal ? '' : 'active'}
                >
                  Зачисление
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setRefusal(true);
                  }}
                  className={refusal ? 'active' : ''}
                >
                  Отказ в зачислении
                </button>
              </Switcher>
              {!editMode && editable && (
                <>
                  <Push size={12} orientation="horizontal" />
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('actions');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                </>
              )}
            </div>
          )
        }
      >
        {refusal && showRefusal ? (
          <ActionsFormRefusal
            editMode={editMode}
            disabled={!editMode}
            setEditMode={() => {
              setEditModeParent('');
              setEditMode(false);
            }}
            decline={requestData.decline}
            requestId={requestData.id}
            serviceId={requestData.trainingGroup?.serviceId}
            updateRequest={updateRequest}
          />
        ) : (
          <ActionsFormEnrollment
            requestData={requestData}
            disabled={!editMode}
            editMode={editMode}
            setEditMode={() => {
              setEditModeParent('');
              setEditMode(false);
            }}
            updateRequest={updateRequest}
          />
        )}
      </Panel>
    </>
  );
};

export default ActionsForm;
