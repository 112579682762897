import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType, Toggle, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../../components/formik/formik-select';
import lookupApi from '../../../../../../lib/api/lookup';
import { checkAddress } from '../utils';
import { Learner } from '../../../../../../types/learners';

type Props = {
  editMode: boolean;
  addressMatches: boolean;
  currentAddressResidence: SelectOptionType | null;
  currentAddressRegistration: SelectOptionType | null;
  setAddressMatches: (value: boolean) => void;
  setCurrentAddressResidence: (option: SelectOptionType | null) => void;
};

export const FactAddress = ({
  editMode,
  addressMatches,
  setAddressMatches,
  currentAddressResidence,
  currentAddressRegistration,
  setCurrentAddressResidence,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const waitCurrentAddress = async (value: string) => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return [{ value: null, label: value }];
  };

  useEffect(() => {
    if (addressMatches && values.regCity === 'other' && !values.regAddress) {
      setCurrentAddressResidence(null);
    }
  }, [addressMatches, setCurrentAddressResidence, values.regAddress, values.regCity]);

  useEffect(() => {
    if (addressMatches && editMode) {
      setFieldValue('factAddress', undefined);
    }
  }, [addressMatches, editMode, setFieldValue]);

  return (
    <div className="table-data__item table-data__group table-data__group--center">
      <div className="table-data__label table-data__label--main">Адрес проживания</div>
      <div className="table-data__body">
        {editMode ? (
          <>
            <div className="flex items-center">
              <Toggle
                size="small"
                checked={addressMatches}
                labelId="addressMatches"
                onChange={(checked) => {
                  if (checked) {
                    setAddressMatches(true);
                    if (values.regCity === 'other') {
                      if (values.regAddress) {
                        setCurrentAddressResidence({ label: values.regAddress, value: null });
                      }
                    } else {
                      setCurrentAddressResidence(currentAddressRegistration);
                    }
                  } else {
                    setAddressMatches(false);
                    setCurrentAddressResidence(null);
                  }
                }}
              />
              <Push orientation="horizontal" size={8} />
              <label htmlFor="addressMatches">Совпадает с адресом регистрации</label>
            </div>
            {!addressMatches && (
              <>
                <Push size={10} />
                <FormikFormGroup name="factAddressId" label="">
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={[]}
                    name="factAddressId"
                    selectedValue={(option) => {
                      setFieldValue('factAddress', option?.label);
                      setCurrentAddressResidence(option);
                    }}
                    loadOptions={async (query) => {
                      const res = await lookupApi.getAddress(query);
                      return res.length ? res : waitCurrentAddress(query);
                    }}
                    defaultValue={values.factAddress ? currentAddressResidence : null}
                    placeholder="Укажите адрес проживания"
                  />
                </FormikFormGroup>

                <Push size={10} />
              </>
            )}
          </>
        ) : (
          checkAddress(values.factAddress ?? '', '')
        )}
      </div>
    </div>
  );
};
