import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, IconArrowDown, Select, SelectOptionType, Panel, Loader, Push, Pagination } from '@mosru/esz_uikit';
import { historyState } from '../../../../../mock-data/history-state';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { pageSizeOptions } from '../../../../../mock-data';
import ListItem from './list-item';
import { SearchInitialFormData, SearchItem } from '../../../../../types/service';
import { numberWithCommas } from '../../../../../lib/utils/learners';
import {
  countSignedElements,
  findNumberPages,
  removeEmptyDataForRequest,
  selectServiceItemCallback,
  transformServiceDataSearch,
} from '../../../../../lib/utils/service';
import { ReactComponent as IconExcel } from '../../../../../assets/images/teachers/excel.svg';
import DropDown from '../../../../../components/drop-down';
import SignModal from '../../../../../components/sign-modal';
import { ServiceRegistryContext } from '../../initial-data';
import { hasGeneralAccess, replaceHistoryState, showExcelErrorData } from '../../../../../lib/utils';
import { generalAccess } from '../../../../../mock-data/access-enum';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { blobToBase64, getFileNameFromDisposition } from '../../../../../lib/utils/reports';
import { ExportFileData } from '../../../../../types';
import { maxRegistryRows } from '../../../../../config/constants';
import systemPropertyApi from '../../../../../lib/api/system-propetry';

type Props = {
  enable?: boolean;
  search: SearchInitialFormData;
  title: string;
  loadingTitle: string;
  currentSearchData: SearchInitialFormData;
};

const ServiceChildList: React.FC<Props> = ({ search, enable, title, loadingTitle, currentSearchData }) => {
  const [pageSize, setPageSize] = useState(window.history.state[historyState.pageSize] || pageSizeOptions[0].value);
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [currentSearch, setCurrentSearch] = useState<SearchInitialFormData>(search);

  const [tableData, setTableData] = useState<SearchItem[]>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [maxCountSignService, setMaxCountSignService] = useState<number>(0);

  const [signModal, showSignModal] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SearchItem[]>([]);
  const [selectedCheckboxes200, setSelectedCheckboxes200] = useState<
    { id: number; educationTypeId: EducationTypeEnum }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [loading200, setLoading200] = useState<boolean>(false);

  const [sign200, setSign200] = useState<boolean>(false);

  const { userProfile, isAdmin } = useContext(ServiceRegistryContext);

  const accessUseSign = hasGeneralAccess(userProfile, generalAccess.UseSign) || isAdmin;

  const numberPages = useMemo(() => {
    return findNumberPages(tableData, tableTotal > maxRegistryRows ? maxRegistryRows : tableTotal, pageSize, pageNum);
  }, [tableData, tableTotal, pageSize, pageNum]);

  useEffect(() => {
    if (tableData) {
      const currentCheckboxSelected = tableData.filter((item: SearchItem) => item.selected);
      setSelectedCheckboxes(currentCheckboxSelected);
    }
  }, [tableData]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const data = JSON.parse(JSON.stringify(currentSearch));

    const response = await serviceTemplateApi.postServiceTemplate({
      ...data,
      pageSize,
      pageNumber: pageNum + 1,
    });

    setTableData(transformServiceDataSearch(response?.items, pageNum, pageSize));
    setTableTotal(response?.total);
    setLoading(false);
  }, [pageNum, pageSize, currentSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetch = async () => {
      const data = await systemPropertyApi.getSystemProperty('MaxCountSignService');
      setMaxCountSignService(parseInt(data));
    };
    if (enable && accessUseSign) {
      fetch();
    }
  }, [accessUseSign, enable]);

  useEffect(() => {
    setCurrentSearch(search);
    if (initPageNum) {
      setInitPageNum(0);
    } else {
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const onSelectedCheckboxHandler = (id: number) => {
    setTableData((prevState: SearchItem[]) => {
      return prevState.map((item: SearchItem) => {
        if (item.id === id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return {
          ...item,
        };
      });
    });
  };

  const signFirst200 = async () => {
    setSign200(true);
    setLoading200(true);
    const response = await serviceTemplateApi.postServiceTemplate({
      pageSize: maxCountSignService || 200,
      pageNumber: 1,
      isReadyToSign: true,
    });

    const list = response.items
      .filter((x) => x.isReadyToSign)
      .map((item: SearchItem) => {
        return { id: item.id, educationTypeId: item.educationTypeId };
      });

    setSelectedCheckboxes200(list);
    setLoading200(false);
    showSignModal(true);
  };

  const getDataForSignHandle = async (itemId: number) => {
    const data = tableData.find(({ id }) => id === itemId);
    return data ? JSON.stringify(data) : '';
  };

  const signSelectedItems = async (id: number, sign: string, educationTypeId?: EducationTypeEnum) => {
    try {
      await serviceTemplateApi.signByIds(
        sign200 ? selectedCheckboxes200.map((e) => e.id) : selectedCheckboxes.map((e) => e.id),
        {
          serviceId: id,
          educationTypeId: EducationTypeEnum.ArtHouseEducation,
          signedDocument: sign,
        }
      );
    } catch {}
  };

  const handleExportFile = async () => {
    setLoadingFile(true);
    try {
      const searchData =
        Object.keys(currentSearchData).length > Object.keys(currentSearch).length ? currentSearchData : currentSearch;

      const res: ExportFileData = await serviceTemplateApi.getServiceExport({
        ...removeEmptyDataForRequest(searchData, true),
        pageSize,
        pageNumber: pageNum + 1,
      });
      if (res) {
        const downloadLink = document.createElement('a');
        downloadLink.href = (await blobToBase64(res.blob)) as string;
        downloadLink.download = getFileNameFromDisposition(res.contentDisposition);
        downloadLink.click();
      }
    } catch (e) {
      showExcelErrorData();
    } finally {
      setLoadingFile(false);
    }
  };

  return (
    <>
      <Panel
        title={() => (
          <>
            {title}
            <span className="color-gray-dark">
              {' \u00A0'} {numberWithCommas(tableTotal)}
            </span>
          </>
        )}
        headingControl={() => (
          <>
            {enable && accessUseSign && (
              <>
                <DropDown
                  component={() => (
                    <Button
                      border
                      primary
                      size="small"
                      label="Подписать"
                      load={loading200}
                      iconRight={() => <IconArrowDown />}
                    />
                  )}
                >
                  <div className="drop-down-panel">
                    {selectedCheckboxes.length ? (
                      <button type="button" onClick={() => showSignModal(true)} className="drop-down-panel__list-item">
                        {countSignedElements(selectedCheckboxes.length)}
                      </button>
                    ) : null}
                    <button type="button" onClick={signFirst200} className="drop-down-panel__list-item">
                      {maxCountSignService || 200} первых услуг
                    </button>
                  </div>
                </DropDown>
                <Push orientation="horizontal" size={12} />
              </>
            )}

            {selectServiceItemCallback ? null : (
              <Button
                border
                black
                colorIcon
                size="small"
                label="Экспорт"
                load={loadingFile}
                iconRight={() => <IconExcel />}
                handleClick={handleExportFile}
              />
            )}
            <Push orientation="horizontal" size={12} />
            <div style={{ width: 184 }}>
              <Select
                name="pageSize"
                size="small"
                hideClearIndicator
                isSearchable={false}
                value={pageSizeOptions.find((item) => item.value === pageSize)}
                onChange={(selectedOption) => {
                  const { value } = selectedOption as SelectOptionType;
                  setPageSize(value as number);
                  setPageNum(0);
                }}
                options={pageSizeOptions}
              />
            </div>
          </>
        )}
      >
        {null}
      </Panel>

      <Push size={12} />

      {loading ? (
        <Panel>
          <div className="loader-container">
            <Loader title={loadingTitle} description="Нужно немного подождать" />
          </div>
        </Panel>
      ) : tableTotal ? (
        <>
          <div className="service-panels-list">
            {tableData.map((item: SearchItem) => (
              <ListItem
                {...item}
                key={item.id}
                checkbox={enable && accessUseSign}
                onSelectedCheckboxHandler={onSelectedCheckboxHandler}
              />
            ))}
          </div>

          <Push size={8} />
          <Panel>
            <Pagination
              scrollUp
              showPagination
              pageNum={pageNum}
              pageSize={pageSize}
              itemsAll={tableTotal}
              setPageNumber={setPageNum}
              paginationArray={numberPages}
              tableDataLength={tableData.length}
            />
          </Panel>
        </>
      ) : (
        <Panel>
          <Push size={20} />
          <div className="table-no-data">Нет данных</div>
        </Panel>
      )}

      <SignModal
        show={signModal}
        onCloseHandle={() => {
          showSignModal(false);
          fetchData();
        }}
        ids={[{ id: 0 }]}
        getDataForSignHandle={getDataForSignHandle}
        setSignedDataHandle={signSelectedItems}
      />
    </>
  );
};

export default ServiceChildList;
