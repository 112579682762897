import React from 'react';
import { Link } from 'react-router-dom';
import DropDown from '../../components/drop-down';
import { ReactComponent as IconDots } from '../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../assets/images/icons/edit.svg';
import { ReactComponent as IconCheckCircleBlack } from '../../assets/images/icons/check-circle-black.svg';
import { ReactComponent as IconRemove } from '../../assets/images/icons/remove.svg';
import { routes } from '../../config/constants';

type EditProps = {
  id: number;
  onSign: () => void;
  canEdit: boolean;
  canSign: boolean;
  canDelete: boolean;
  setRemovedId: () => void;
};

const Edit: React.FC<EditProps> = ({ setRemovedId, id, onSign, canEdit, canSign, canDelete }) => {
  return (
    <div className="disciplines-table-comment right">
      <div className="disciplines-table-comment__controls">
        <DropDown
          component={() => (
            <span className="drop-down-btn-round">
              <IconDots />
            </span>
          )}
          itemId={id}
        >
          <div className="drop-down-panel">
            <div className="drop-down-panel__list">
              {canEdit && (
                <Link to={`${routes.organizations}/${id}`} type="button" className="drop-down-panel__list-item">
                  <IconEdit />
                  Редактировать
                </Link>
              )}

              {canSign && (
                <button type="button" onClick={onSign} className="drop-down-panel__list-item">
                  <IconCheckCircleBlack />
                  Подписать эл. подписью
                </button>
              )}

              {canDelete && (
                <button type="button" onClick={setRemovedId} className="drop-down-panel__list-item">
                  <IconRemove />
                  Удалить
                </button>
              )}
            </div>
          </div>
        </DropDown>
      </div>
    </div>
  );
};

export default Edit;
