import React from 'react';
import { Infobox, Push } from '@mosru/esz_uikit';
import { editTextInfobox } from '../../lib/utils/requests';
import { ReactComponent as IconClockWarning } from '../../assets/images/icons/clock-warning-light.svg';

type Props = {
  message: string;
  intersection: boolean;
  loadingCheck: boolean;
  contingentGuid: string;
};

export const Statuses: React.FC<Props> = ({ intersection, contingentGuid, message, loadingCheck }) => {
  return (
    <div className="container">
      {!contingentGuid && (
        <>
          <Push orientation="vertical" size={12} />
          <Infobox
            fullWidth
            color="danger"
            text="Ребенок не найден в Реестре контингента, поиск пересечений по расписанию невозможен."
          />
        </>
      )}

      {message && contingentGuid && (
        <>
          <Push orientation="vertical" size={12} />
          <Infobox fullWidth color="warning" text={editTextInfobox(message)} />
        </>
      )}

      {!message && loadingCheck && (
        <>
          <Push orientation="vertical" size={12} />
          <Infobox
            fullWidth
            color="warning"
            text="Подождите, идет проверка пересечений по расписанию"
            customIcon={<IconClockWarning />}
          />
        </>
      )}

      {!message && contingentGuid && intersection && (
        <>
          <Push orientation="vertical" size={12} />
          <Infobox fullWidth color="success" text="Пересечений по расписанию не найдено" />
        </>
      )}
    </div>
  );
};
