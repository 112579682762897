import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import { date as dateYup, number, object as objectYup, string as stringYup } from 'yup';
import { Button, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { GetDataRequest, OptionFilter, ReportFilterData, ReportSchedule, TypeSwitch } from '../../../types/reports';
import { blobToBase64, getFileNameFromDisposition, removeEmptyDataForRequest } from '../../../lib/utils/reports';
import FilterItem from './filter-item';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { reportsApi } from '../../../lib/api/reports';

type Props = {
  loading: boolean;
  data: GetDataRequest;
  currentReportId: number;
  resetTable: () => void;
  listSchedule: ReportSchedule;
  openScheduleModal: () => void;
  setDataTable: (merge: any) => void;
  setLoading: (value: boolean) => void;
  setLoadingModal: (value: boolean) => void;
  setOpenTreePopup: (value: boolean) => void;
  resetScheduleModalHandler: (value: boolean) => void;
  setHeader: (header: { [key: string]: any }[]) => void;
  setQuery: (data: ReportFilterData | undefined) => void;
  setValueBasicDirection: React.Dispatch<React.SetStateAction<SelectOptionType>>;
  valueBasicDirection: SelectOptionType;
};

const ReportFilter: React.FC<Props> = ({
  data,
  currentReportId,
  setHeader,
  resetTable,
  setLoading,
  loading,
  setOpenTreePopup,
  resetScheduleModalHandler,
  listSchedule,
  openScheduleModal,
  setQuery,
  setValueBasicDirection,
  valueBasicDirection,
}) => {
  const [typeFinance, setTypeFinance] = useState<TypeSwitch[]>([]);
  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const [excelLoading, setExcelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialFormData: ReportFilterData = useMemo(() => {
    const d: ReportFilterData = {
      vedomstvoId: null,
      organizationId: null,
    };
    data?.filterData?.filterList?.forEach((f: OptionFilter) => {
      if (f.listItems?.length === 1) {
        d[f.parameterField] = f.listItems[0].id;
      } else if (f.value !== null) {
        if (typeof f.value === 'string' && moment(f.value).isValid() && Boolean(Date.parse(f.value))) {
          d[f.parameterField] = moment(f.value).set('hour', 3).toDate();
        } else {
          d[f.parameterField] = f.value;
        }
      }
    });
    return d;
  }, [data.filterData]);

  const offset = Math.abs(new Date().getTimezoneOffset()) / 60;
  const currentDateWithOffset = moment(new Date()).set('hour', offset).toDate();

  const newDateWithOneDayOffset = moment(currentDateWithOffset).add(1, 'day').toDate();

  const getUserValidationSchema = () => {
    switch (currentReportId) {
      case 1:
        return objectYup().shape({
          periodFrom: dateYup()
            .nullable()
            .max(currentDateWithOffset, 'Введённая дата является слишком большой')
            .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введённая дата является слишком маленькой'),
        });
      case 39:
      case 40:
      case 5:
      case 11:
        return objectYup().shape({
          periodFrom: dateYup()
            .nullable()
            .max(currentDateWithOffset, 'Введённая дата является слишком большой')
            .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введённая дата является слишком маленькой'),

          periodTo: dateYup()
            .nullable()
            .max(currentDateWithOffset, 'Введённая дата является слишком большой')
            .min(moment('01.01.1910', 'DD/MM/YYYY').format('L'), 'Введённая дата является слишком маленькой'),
        });

      case 24:
        return objectYup().shape({
          reportDate: dateYup()
            .nullable()
            .max(newDateWithOneDayOffset, 'Введённая дата является слишком большой')
            .min(moment('01.01.1910', 'DD/MM/YYYY').format('L')),
        });

      case 37:
        return objectYup().shape({
          organizationId: stringYup().required('Необходимо указать организацию').nullable(),
        });
      case 6:
        return objectYup().shape({
          classificatorEKUId: number()
            .required('Необходимо выбрать направление')
            .typeError('Необходимо выбрать направление')
            .test('classificatorEKUId', 'Необходимо выбрать направление', (value) => value !== 0),
        });
      case 26:
      case 27:
      case 29:
      case 30:
        return objectYup().shape({
          maxAge: stringYup()
            .nullable()
            .test('maxAge', '', (value: string | null | undefined, ctx: any) => {
              const [parent1] = ctx.from;

              if (!value) {
                return true;
              }
              if (value && Number.isNaN(Number(value))) {
                return ctx.createError({ message: 'От 1 до 120' });
              }

              if (Number(value) < 1) {
                return ctx.createError({ message: 'От 1' });
              }
              if (Number(value) > 120) {
                return ctx.createError({ message: 'До 120' });
              }
              if (parent1?.value?.minAge !== undefined && parent1?.value?.minAge > Number(value)) {
                return ctx.createError({ message: 'Максимальный возраст не может быть меньше минимального' });
              }
              return true;
            }),
          minAge: stringYup()
            .nullable()
            .test('minAge', '', (value: string | null | undefined, ctx: any) => {
              const [parent1] = ctx.from;

              if (!value) {
                return true;
              }

              if (value && Number.isNaN(Number(value))) {
                return ctx.createError({ message: 'От 1 до 120' });
              }
              if (Number(value) < 1) {
                return ctx.createError({ message: 'От 1' });
              }
              if (Number(value) > 120) {
                return ctx.createError({ message: 'До 120' });
              }
              if (parent1?.value?.maxAge !== undefined && parent1?.value?.maxAge < Number(value)) {
                return ctx.createError({ message: 'Минимальный возраст не может быть больше максимального' });
              }
              return true;
            }),
        });
      default:
        return objectYup().shape({});
    }
  };

  useEffect(() => {
    setTypeFinance(data.sourceFinance);
  }, [data.sourceFinance, rerenderFormKey]);

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const prepareReportQuery = (value: ReportFilterData) => {
    const requestData = {
      ...value,
      scheduleIds: listSchedule?.selectDaysForRequest,
      classificatorEKUName: valueBasicDirection?.label,
    };
    removeEmptyDataForRequest(requestData);
    return requestData;
  };

  const submitForm = (value: ReportFilterData, actions: { setSubmitting: (arg: boolean) => void }) => {
    if (value.excel) {
      reportToExcelOnFilter(currentReportId, value);
    } else {
      setIsLoading(true);
      const requestData = prepareReportQuery(value);
      setQuery(requestData);
      actions.setSubmitting(false);
    }
  };

  const resetForm = () => {
    resetTable();
    setHeader([]);
    setLoading(false);
    resetScheduleModalHandler(true);
    setRerenderFormKey(Math.random());
    setValueBasicDirection({
      value: 0,
      label: '',
    });
  };

  const reportToExcelOnFilter = async (reportId: number, query: ReportFilterData) => {
    try {
      setExcelLoading(true);
      const requestData = prepareReportQuery(query);
      const report = await reportsApi.postReports(reportId, requestData, true);
      if (report) {
        const downloadLink = document.createElement('a');
        downloadLink.href = (await blobToBase64(report.blob)) as string;
        downloadLink.download = getFileNameFromDisposition(report.contentDisposition);
        downloadLink.click();
      }
    } catch (e) {}

    setExcelLoading(false);
  };

  return (
    <Formik
      key={rerenderFormKey}
      onSubmit={submitForm}
      enableReinitialize
      validateOnMount
      initialValues={initialFormData}
      validationSchema={getUserValidationSchema}
    >
      {(formikProps: FormikProps<ReportFilterData>) => {
        const { handleSubmit, values, setFieldValue, isValid } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Panel
              controls={() => (
                <>
                  <Button label="Очистить" onClick={resetForm} border size="small" />
                  {currentReportId !== 10 && (
                    <>
                      <Push orientation="horizontal" size={12} />
                      <Button
                        submit
                        black
                        border
                        colorIcon
                        size="small"
                        label="Экспорт"
                        handleClick={async () => {
                          setFieldValue('excel', true);
                        }}
                        load={excelLoading}
                        iconRight={() => <IconExcel />}
                      />
                    </>
                  )}
                  <Push orientation="horizontal" size={12} />
                  <Button
                    submit
                    label="Сформировать"
                    load={isLoading}
                    primary
                    disabled={!isValid}
                    size="small"
                    handleClick={async () => {
                      setFieldValue('excel', false);
                    }}
                  />
                </>
              )}
            >
              <div className="container">
                <Push size={16} />
                <FilterItem
                  values={values}
                  filterData={data.filterData}
                  typeFinance={typeFinance}
                  listSchedule={listSchedule}
                  setTypeFinance={setTypeFinance}
                  currentReportId={currentReportId}
                  setOpenTreePopup={setOpenTreePopup}
                  openScheduleModal={openScheduleModal}
                  setFieldValue={setFieldValue}
                  setValueBasicDirection={setValueBasicDirection}
                  valueBasicDirection={valueBasicDirection}
                  resetScheduleModalHandler={resetScheduleModalHandler}
                />
              </div>
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

export default ReportFilter;
