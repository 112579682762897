import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormikProps, useField, useFormikContext } from 'formik';
import { CheckboxGroup, FormGroup, Checkbox, Push } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceIdField } from './service';
import { ServiceClassListData, ServiceClassPeriodListScheduleData } from '../../../../types/service-class';
import { scheduleOfTimetableIdField } from './admission-plan';
import { schoolOrganizationIdField } from './organization';
import { serviceClassIdField } from './service-class';
import { RequestData, TrainingGroupRequestData } from '../../../../types/requests';
import { getDate } from '../../../../lib/utils/date';

type VisitDaysProps = FieldProps<number> & {
  parent?: string;
  setServiceClassList?: Dispatch<SetStateAction<ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null>>;
  initialDataServiceClassList?: ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null;
  initialValues?: TrainingGroupRequestData | RequestData;
  curentServiceClassId?: number;
};

type Days = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

type DayOfWeekType = {
  weekDayId: Days;
  weekDayBitId: number;
};

export const desiredDaysField = 'desiredDays';

const VisitDays = ({
  label = 'Дни для посещения',
  name,
  editMode,
  required,
  defaultValue,
  parent,
  setServiceClassList,
  initialDataServiceClassList,
  initialValues,
  curentServiceClassId,
}: VisitDaysProps) => {
  const { setFieldValue } = useFormikContext<FormikProps<any>>();
  const [field, , helpers] = useField(parent ? `${parent}.${name}` : name);
  const [serviceIdOptions] = useField(parent ? `${parent}.${serviceIdField}` : serviceIdField);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<Days[]>([]);

  const [scheduleOfTimetableIdFieldOption] = useField(
    parent ? `${parent}.${scheduleOfTimetableIdField}` : scheduleOfTimetableIdField
  );

  const [schoolOrganizationIdOption] = useField<number>(
    parent ? `school.${schoolOrganizationIdField}` : schoolOrganizationIdField
  );

  const serviceClassId = parent ? `${parent}.${serviceClassIdField}` : serviceClassIdField;

  const serviceIdRef = useRef<number | null>(null);

  useEffect(() => {
    const arrDayOfWeek: DayOfWeekType[] = [
      { weekDayId: 'monday', weekDayBitId: 1 },
      { weekDayId: 'tuesday', weekDayBitId: 2 },
      { weekDayId: 'wednesday', weekDayBitId: 4 },
      { weekDayId: 'thursday', weekDayBitId: 8 },
      { weekDayId: 'friday', weekDayBitId: 16 },
      { weekDayId: 'saturday', weekDayBitId: 32 },
      { weekDayId: 'sunday', weekDayBitId: 64 },
    ];
    const setInitialDayOfWeek = arrDayOfWeek
      ?.filter((item) => item.weekDayBitId & field.value)
      ?.map((item) => item.weekDayId);

    if (selectedDayOfWeek.length === 0) {
      setSelectedDayOfWeek(setInitialDayOfWeek);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) {
      serviceIdRef.current = initialValues.serviceId;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (!serviceIdOptions?.value || serviceIdOptions?.value !== serviceIdRef.current) {
      setSelectedDayOfWeek([]);
      helpers.setValue('');
      setFieldValue(scheduleOfTimetableIdField, '');
      setFieldValue(serviceClassId, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceIdOptions?.value]);

  const checkedHandler = (checked: boolean, value: number, dayWeek: Days) => {
    if (checked) {
      if (field.value) {
        helpers.setValue(field.value + value);
      } else {
        helpers.setValue(value);
      }
    } else {
      helpers.setValue(field.value - value);
    }

    const findDayId = selectedDayOfWeek.find((weekDayId) => weekDayId === dayWeek);

    if (findDayId === undefined) {
      setSelectedDayOfWeek((prevState) => [...prevState, dayWeek]);
    } else {
      setSelectedDayOfWeek((prevState) => prevState.filter((weekDayId) => weekDayId !== findDayId));
    }
  };

  useEffect(() => {
    function equalArrays(schedule: ServiceClassPeriodListScheduleData[]) {
      let count = 0;
      const dateNow = new Date();
      const currentSchedule =
        schedule.length === 1
          ? schedule[0]
          : schedule.find((s) => getDate(s.dateStart) <= dateNow && getDate(s.dateEnd) >= dateNow) ||
            schedule.find((s) => getDate(s.dateStart) >= dateNow);
      if (currentSchedule) {
        selectedDayOfWeek.forEach((weekDayId) => {
          if (currentSchedule[weekDayId]) {
            count += 1;
          }
        });
      }

      return selectedDayOfWeek.length === count;
    }

    const filterClassList = initialDataServiceClassList?.filter(
      (item) => equalArrays(item.schedule) || item.id === curentServiceClassId
    );

    if (filterClassList) {
      setServiceClassList && setServiceClassList(filterClassList);
    } else {
      setServiceClassList && initialDataServiceClassList && setServiceClassList(initialDataServiceClassList);
    }
  }, [selectedDayOfWeek, initialDataServiceClassList, setServiceClassList, curentServiceClassId]);

  return serviceIdOptions.value && schoolOrganizationIdOption.value ? (
    <Field label={label} editMode={editMode} disabled={!editMode} required={required} defaultValue={defaultValue}>
      <FormGroup
        label=""
        error={!field.value && scheduleOfTimetableIdFieldOption.value ? 'Укажите хотя бы один день недели' : undefined}
      >
        <div className="flex">
          <CheckboxGroup label="Понедельник" checked labelId="monday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 1) !== 0}
              labelId="monday"
              onChange={(checked) => {
                checkedHandler(checked, 1, 'monday');
              }}
            />
          </CheckboxGroup>
          <Push orientation="horizontal" size={12} />
          <CheckboxGroup label="Вторник" checked labelId="tuesday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 2) !== 0}
              labelId="tuesday"
              onChange={(checked) => {
                checkedHandler(checked, 2, 'tuesday');
              }}
            />
          </CheckboxGroup>
          <Push orientation="horizontal" size={12} />
          <CheckboxGroup label="Среда" checked labelId="wednesday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 4) !== 0}
              labelId="wednesday"
              onChange={(checked) => {
                checkedHandler(checked, 4, 'wednesday');
              }}
            />
          </CheckboxGroup>
          <Push orientation="horizontal" size={12} />
          <CheckboxGroup label="Четверг" checked labelId="thursday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 8) !== 0}
              labelId="thursday"
              onChange={(checked) => {
                checkedHandler(checked, 8, 'thursday');
              }}
            />
          </CheckboxGroup>
          <Push orientation="horizontal" size={12} />
          <CheckboxGroup label="Пятница" checked labelId="friday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 16) !== 0}
              labelId="friday"
              onChange={(checked) => {
                checkedHandler(checked, 16, 'friday');
              }}
            />
          </CheckboxGroup>
          <Push orientation="horizontal" size={12} />
          <CheckboxGroup label="Суббота" checked labelId="saturday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 32) !== 0}
              labelId="saturday"
              onChange={(checked) => {
                checkedHandler(checked, 32, 'saturday');
              }}
            />
          </CheckboxGroup>
          <Push orientation="horizontal" size={12} />
          <CheckboxGroup label="Воскресенье" checked labelId="sunday" onChange={() => {}}>
            <Checkbox
              disabled={!editMode}
              checked={(field.value & 64) !== 0}
              labelId="sunday"
              onChange={(checked) => {
                checkedHandler(checked, 64, 'sunday');
              }}
            />
          </CheckboxGroup>
        </div>
      </FormGroup>
    </Field>
  ) : null;
};

export default VisitDays;
