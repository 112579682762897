import React from 'react';
import { SimpleTable as Table } from '@mosru/esz_uikit';
import { getDayOfTheWeekFromString } from '../../../../lib/utils/time-table';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { AuthorizationData } from '../../../../types/authorization-data';
import { Organization } from '../../../../types/organization';
import { ItemsWork } from '../../../../types/items-work';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

type Props = {
  tableData: ItemsWork[];
  setEditMode: (value: boolean) => void;
  organization: Organization;
  rerenderTableKey: number;
  userProfile: AuthorizationData;
  setRemovedId: (value: number) => void;
  setItemWork: (value: ItemsWork) => void;
};

export const TablePoint = ({
  setEditMode,
  organization,
  rerenderTableKey,
  tableData,
  userProfile,
  setRemovedId,
  setItemWork,
}: Props) => {
  const getItemWork = async (value: number) => {
    const response = await dictionariesApi.getItemWork(value);
    setItemWork(response);
  };

  return (
    <Table
      key={rerenderTableKey}
      data={tableData || []}
      pageSize={Number.MAX_SAFE_INTEGER}
      hideSort
      overflow
      columns={[
        {
          dataIndex: 'count',
          title: '№',
          render: (item: any) => item.count,
          width: '50px',
        },
        {
          dataIndex: 'name',
          title: 'Наименование',
          render: (item: any) => <div className="word-break-all">{item.fullName ?? item.shortName}</div>,
          width: '25%',
        },
        {
          dataIndex: 'fullAddress',
          title: 'Адрес',
          render: (item: any) => item.fullAddress,
          width: '25%',
        },
        {
          dataIndex: 'time',
          title: 'Расписание',
          render: (item: any) => getDayOfTheWeekFromString(item.timetable).map((item: string) => <div>{item}</div>),
          width: '25%',
        },
        {
          dataIndex: '',
          title: '',
          render: (item: any) =>
            !organization.isArchive &&
            !item.isDefaultItemsWork &&
            hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
              <div className="disciplines-table-comment right">
                <div className="disciplines-table-comment__controls">
                  <DropDown
                    itemId={item.id}
                    component={() => (
                      <span className="drop-down-btn-round">
                        <IconDots />
                      </span>
                    )}
                  >
                    <div className="drop-down-panel">
                      <div className="drop-down-panel__list">
                        <button
                          type="button"
                          onClick={async () => {
                            await getItemWork(item.id);
                            setEditMode(true);
                          }}
                          className="drop-down-panel__list-item"
                        >
                          <IconEdit />
                          Редактировать
                        </button>
                        <button
                          type="button"
                          onClick={() => setRemovedId(item.id)}
                          className="drop-down-panel__list-item"
                        >
                          <IconRemove />
                          Удалить
                        </button>
                      </div>
                    </div>
                  </DropDown>
                </div>
              </div>
            ),
          width: '25%',
        },
      ]}
    />
  );
};
