import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { SearchInitialFormData } from '../../../../../types/service';
import { ReactComponent as IconOptions } from '../../../../../assets/images/icons/options.svg';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';
import { EducationTypeEnum } from '../../../../../types/education-type';

type Props = {
  setOpenTreePopup: (value: boolean) => void;
  basicDirection: SelectOptionType | null | undefined;
  title: string;
  setBasicDirection: (value: SelectOptionType | null) => void;
  type: RegistryTypeEnum;
  isAdmin: boolean;
};

const FieldDirection: React.FC<Props> = ({
  setOpenTreePopup,
  basicDirection,
  title,
  setBasicDirection,
  type,
  isAdmin,
}) => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();

  useEffect(() => {
    if (basicDirection) {
      setFieldValue('classificatorEKUId', basicDirection.value);
      setFieldValue('classificationName', basicDirection.label);
    }
  }, [basicDirection, setFieldValue]);

  const additionalCondition =
    type === RegistryTypeEnum.serviceChildType &&
    isAdmin &&
    values.educationTypeId === EducationTypeEnum.DayCareCentersEducation;

  const initDefaultValue = values.classificatorEKUId
    ? {
        value: values.classificatorEKUId,
        label: values.classificationName ?? '',
      }
    : null;

  const defaultValue = additionalCondition
    ? {
        value: EducationTypeEnum.DayCareCentersEducation,
        label: 'Группы по присмотру и уходу за детьми школьного возраста',
      }
    : initDefaultValue;

  return (
    <div className="flex items-end">
      <div className="flex-auto">
        <FormikFormGroup name="classificatorEKUId" label={title}>
          <FormikSelect
            disabled={additionalCondition}
            size="small"
            isSearchable
            options={[]}
            name="classificatorEKUId"
            placeholder="Начните вводить или выберите..."
            loadOptions={async (query) => await lookupApi.getClassificator(query, values.educationTypeId as number)}
            defaultValue={defaultValue}
            selectedValue={(v: SelectOptionType) => {
              if (v) {
                setFieldValue('classificationName', v.label);
              } else {
                setFieldValue('classificationName', '');
                setBasicDirection(null);
              }
            }}
          />
        </FormikFormGroup>
      </div>
      <Push size={10} orientation="horizontal" />
      <Button
        disabled={additionalCondition}
        size="small"
        onClick={() => {
          setOpenTreePopup(true);
        }}
        border
        primary
        iconLeft={() => <IconOptions />}
      />
    </div>
  );
};

export default FieldDirection;
