import React, { useEffect, useState } from 'react';
import { Panel, Loader, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { EducationFact } from '../../../types/learners';
import learnerApi from '../../../lib/api/learner';

type Props = {
  contingentGuid: string | undefined;
};

const LearnerGeneralEducation: React.FC<Props> = ({ contingentGuid }) => {
  const [edFacts, setEdFacts] = useState<EducationFact[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      if (contingentGuid) {
        const edFact = await learnerApi.getEdFacts(contingentGuid);
        setLoading(false);
        setEdFacts(edFact);
      } else {
        setLoading(false);
      }
    };

    fetch();
  }, [contingentGuid]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Общеобразовательная организация
            <span className="color-gray-dark">
              {' \u00A0'} {edFacts?.length ? edFacts?.length : ''}
            </span>
          </>
        )}
      >
        {loading ? (
          <div className="flex justify-center flex-column">
            <Push orientation="vertical" size={20} />
            <Loader roller small />
            <Push orientation="vertical" size={20} />
          </div>
        ) : edFacts?.length ? (
          <Table
            data={edFacts}
            pageSize={Number.MAX_SAFE_INTEGER}
            columns={[
              {
                dataIndex: 'educationLevelName',
                title: 'Уровень образования',
                render: (item: any) => item.educationLevelName,
                hiddenSort: true,
              },
              {
                dataIndex: 'organizationName',
                title: 'Образовательная организация',
                render: (item: any) => item.organizationName,
                hiddenSort: true,
              },
              {
                dataIndex: 'className',
                title: 'Группа/класс',
                render: (item: any) => item.className,
                hiddenSort: true,
              },
            ]}
          />
        ) : (
          <div className="learner-no-data">Сведения о получаемом ребенком образовании отсутствуют</div>
        )}
      </Panel>
    </>
  );
};

export default LearnerGeneralEducation;
