import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Tooltip, Loader, Push, AsyncTable as Table } from '@mosru/esz_uikit';
import { generateLink, hasAccessObjectAny } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { ReactComponent as IconInfoDanger } from '../../../../assets/images/icons/info-danger.svg';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { setTextColorByCheckCurrentDate, textColorByStatusId } from '../../../../lib/utils/service-class';
import { styleVars } from '../../../../theme/vars';
import { formatDate } from '../../../../lib/utils/date';
import { ContractStatusEnum } from '../../../../mock-data/contract-status-enum';
import { GetCheckboxColumn } from '../../components/table/chekbox-column';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';

const TableGroupComposition = () => {
  const { loading, setSelected, setOpenDetails, pageNum, pageSize, totalCount, setPageNum, learners, selected } =
    useContext(ServiceClassTableContext);

  const { isArchiveGroup } = useContext(ServiceClassContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const hasEditAccess = hasAccessObjectAny(
    userProfile,
    [accessObject.ServiceClassDOGM, accessObject.ServiceClassNonDOGM],
    accessAction.Edit
  );

  const displayContractStatus = (contractStatusId?: number) => {
    switch (contractStatusId) {
      case ContractStatusEnum.Signed:
        return <Badge round border text="подписан" color="success" />;
      case ContractStatusEnum.Canceled:
        return <Badge round border text="аннулирован" color="warning" />;
      case ContractStatusEnum.Terminated:
        return <Badge round border text="расторгнут" color="danger" />;
      default:
        return <span className="color-gray-dark">Нет информации</span>;
    }
  };

  return !loading ? (
    <Table
      hideSort
      overflow
      pageNum={pageNum}
      pageSize={pageSize}
      itemsAll={totalCount}
      setPageNumber={setPageNum}
      data={learners}
      columns={[
        GetCheckboxColumn(selected, learners, setSelected, setOpenDetails, isArchiveGroup || !hasEditAccess),
        {
          dataIndex: 'count',
          title: '№',
          render: (item: any) => {
            return item.count;
          },
          width: '40px',
        },
        {
          dataIndex: 'pupilName',
          title: 'ФИО',
          render: (item: any) => (
            <>
              <div className="icon-group">
                <div
                  className="icon-group__text cursor-pointer text-decoration-underline"
                  onClick={() => {
                    window.open(
                      generateLink(routes.learner, {
                        id: item.pupilId,
                      }),
                      '_blank'
                    );
                  }}
                >
                  {item.pupilName}
                </div>
                <div className="icon-group__icon">
                  {(item.isMoreThanEighteen || item.isLearnEnds) && (
                    <Tooltip
                      component={() =>
                        item.isMoreThanEighteen || (item.isMoreThanEighteen && item.isLearnEnds) ? (
                          <IconInfoDanger />
                        ) : (
                          <IconInfo />
                        )
                      }
                      position="bottom"
                      text={
                        <div>
                          {item.isMoreThanEighteen && <div>Уже исполнилось 18 лет</div>}
                          {item.isLearnEnds && <div>Истек срок обучения</div>}
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
              <Push size={4} />
              <span className={textColorByStatusId[item.statusId]}>{item.statusName}</span>
              {item?.subclassList.length > 0 && (
                <>
                  <Push size={17} />
                  <div className="flex wrap">
                    {item?.subclassList?.map((subItem: any) => {
                      return (
                        <div style={{ marginBottom: '4px', marginRight: '4px' }}>
                          <Badge color="default" textColor={styleVars.textColor} text={subItem.name} />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          ),
        },
        {
          dataIndex: 'service',
          title: 'ДО',
          width: '30%',
          render: (item: any) => (
            <div
              className="text-decoration-underline cursor-pointer"
              onClick={() => window.open(generateLink(routes.registerChildEducation, { id: item.serviceId }), '_blank')}
            >
              {item.classificatorEKUName}, {item.servicePeriod}
            </div>
          ),
        },
        {
          dataIndex: 'dates',
          title: 'Даты обучения',
          render: (item: any) => {
            return (
              <div>
                <span>с {formatDate(item.dateLearnStart)}</span>
                <span style={{ color: setTextColorByCheckCurrentDate(item.dateLearnStart, item.dateLearnEnd) }}>
                  {' '}
                  по {formatDate(item.dateLearnEnd)}
                </span>
              </div>
            );
          },
          width: '16%',
        },
        {
          dataIndex: '',
          title: 'Статус договора',
          render: (item: any) => displayContractStatus(item.contractStatusId),
          width: '16%',
        },
      ]}
    />
  ) : (
    <div className="loader-container">
      <Loader roller small />
    </div>
  );
};

export default TableGroupComposition;
