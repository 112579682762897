import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
// @ts-ignore
import Files from 'react-files';
import { Button, Infobox, notify, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { rtshedLink } from '../../../../../config/constants';
import { ErrorCodeEnum, ReactFilesError, ReactFilesFile } from '../../../../../types/files';
import { ServiceContext } from '../../../index';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { PhotoData, ServicePhotoItem } from '../../../../../types/service';
import PhotoCard from './photo-card';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';
import { GalleryModal } from '../../../../../components/modal-gallery';
import systemPropertyApi from '../../../../../lib/api/system-propetry';
import { templateApi } from '../../../../../lib/api/template';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
};

export type ServiceImageData = ReactFilesFile & {
  removed?: boolean;
};

const photoCount = 4;

const Photo: React.FC<Props> = ({ setEditModeParent, isTemplate = false }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [files, setFiles] = useState<ServiceImageData[]>([]);
  const [rtshedUrl, setRtshedUrl] = useState<string | undefined>();
  const [loader, setLoader] = useState(false);

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<ServiceImageData>();

  const openFullScreen = (currentImage: ServiceImageData, open: boolean) => {
    setFullScreen(open);
    setCurrentImage(currentImage);
  };

  const compareOrderPhoto = (a: PhotoData, b: PhotoData) => {
    const first = a.sortOrder ?? a.id;
    const second = b.sortOrder ?? b.id;
    if (first < second) {
      return -1;
    } else {
      return 1;
    }
  };

  const servicePhotos = useMemo(
    () =>
      serviceData?.photos?.length
        ? serviceData.photos.sort(compareOrderPhoto).map((photo): ServiceImageData => {
            return {
              id: photo.id.toString(),
              extension: 'jpg',
              sizeReadable: '',
              name: photo.name,
              preview: {
                type: 'image',
                fullSize: `${rtshedUrl}${rtshedLink}${photo.sourceFileName}`,
                // url: `${rtshedUrl}${rtshedLink}${photo.sourceFileName}`,
                url: `${window.location.protocol}/Images/Photos/Thumbnails${photo.path}`,
              },
              removed: false,
            } as ServiceImageData;
          })
        : [],
    [rtshedUrl, serviceData?.photos]
  );

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setFiles((prevCards: ServiceImageData[]) => {
      const activeCards = prevCards.filter((f) => !f.removed);
      const removedCards = prevCards.filter((f) => f.removed);
      const sortedCards = update(activeCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, activeCards[dragIndex] as ServiceImageData],
        ],
      });
      return [...removedCards, ...sortedCards];
    });
  }, []);

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  const dataURItoBlob = async (dataURI: string) => (await fetch(dataURI)).blob();

  const save = async () => {
    setLoader(true);
    try {
      const remList = files.filter((f) => f.removed);
      if (remList?.length > 0) {
        for (let i = 0; i < remList?.length; i++) {
          if (isTemplate) {
            await templateApi.deletePhoto(remList[i].id, serviceData.info?.templateId ?? 0);
          } else {
            await serviceTemplateApi.deletePhoto(remList[i].id, serviceData.id);
          }
        }
      }

      if (files?.length) {
        for (let i = 0; i < files?.length; i++) {
          if (files[i].id.indexOf('file') === 0) {
            const item = (await createImage(files[i].preview.url)) as HTMLImageElement;

            const blob = await dataURItoBlob(files[i].preview.url);
            const image = {
              name: files[i].name,
              width: item.width,
              height: item.height,
              sortOrder: i + 1,
              image: blob,
              educationTypeId: serviceData.educationTypeId,
              templateId: serviceData.id,
              serviceId: serviceData.id,
            } as ServicePhotoItem;

            let result = 0;
            if (isTemplate) {
              result = await templateApi.putPhoto(serviceData.id, image);
            } else {
              result = await serviceTemplateApi.putPhoto(serviceData.id, image);
            }

            files[i].id = String(result);
          }
        }
      }

      const sortList = files
        .filter((item) => !item.removed)
        .map((item, index) => ({ sortOrder: index, photoId: Number(item.id) }));

      if (sortList.length > 0) {
        if (isTemplate) {
          await templateApi.updateServicePhotoListOrder({
            templateId: serviceData.info?.templateId,
            educationTypeId: serviceData.educationTypeId,
            list: sortList,
          });
        } else {
          await serviceTemplateApi.updateServicePhotoListOrder({
            serviceId: serviceData.id,
            educationTypeId: serviceData.educationTypeId,
            list: sortList,
          });
        }
      }
    } catch {}

    setLoader(false);
    setEditModeParent && setEditModeParent(null);
    setEditMode(false);
    updateService();
  };

  useEffect(() => {
    const fetch = async () => {
      const rtshed = await systemPropertyApi.getSystemProperty('baseUrlRtshed');
      setRtshedUrl(rtshed);
    };
    fetch();
  }, []);

  useEffect(() => {
    setFiles(rtshedUrl ? servicePhotos : []);
  }, [rtshedUrl, servicePhotos]);

  const checkEditable =
    setEditModeParent &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    accessPanelEdit;

  const isPossibleAddFile = files?.filter((f) => !f.removed)?.length < photoCount;

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => 'Фотографии услуги'}
        headingControl={() =>
          checkEditable &&
          !editMode && (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
                setEditModeParent && setEditModeParent('photo');
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconEdit />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          )
        }
      >
        {serviceData?.id ? (
          !editMode ? (
            <div className="container">
              {files.length ? (
                <div className="service-photo-grid">
                  {files.map((file, i) => (
                    <div
                      key={file.id}
                      role="presentation"
                      className="service-photo"
                      onClick={() => openFullScreen(file, true)}
                    >
                      <div className="service-photo__inner">
                        <div className="service-photo__img" style={{ backgroundImage: `url(${file.preview.url})` }} />
                        <div className="service-photo__head">{i === 0 ? 'Главное фото' : `Фото №${i + 1}`}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span className="color-gray-dark">Фотографии отсутствуют</span>
              )}
              <Push size={24} />
            </div>
          ) : (
            <div className="container">
              <Files
                clickable={isPossibleAddFile ?? true}
                multiple={false}
                className="auth-files-dropzone"
                onChange={(newFiles: ReactFilesFile[]) => {
                  if (newFiles.length) {
                    const img = document.createElement('img');
                    img.src = newFiles[0].preview.url;
                    img.onload = function () {
                      const { width } = img;
                      const { height } = img;
                      if (width > 1920 || width < 1600) {
                        notify.danger({
                          data: {
                            label:
                              'Ширина изображения должна быть в интервале 1600-1920px, выберите другое изображение и повторите загрузку.',
                          },
                        });
                      } else if (height > 1200 || height < 900) {
                        notify.danger({
                          data: {
                            label:
                              'Высота изображения должна быть в интервале 900-1200px, выберите другое изображение и повторите загрузку.',
                          },
                        });
                      } else if (isPossibleAddFile) {
                        setFiles([...files, ...newFiles]);
                      }
                    };
                  }
                }}
                onError={(error: ReactFilesError) => {
                  if (isPossibleAddFile) {
                    if (error.code === ErrorCodeEnum.fileTooSmall || error.code === ErrorCodeEnum.fileTooLarge) {
                      notify.danger({
                        data: {
                          label:
                            'Размер изображения должен быть в интервале 0.3-2.5Мб, выберите другое изображение и повторите загрузку.',
                        },
                      });
                    }
                    if (error.code === ErrorCodeEnum.invalidFileType) {
                      notify.danger({
                        data: {
                          label:
                            'Для загрузки доступны изображения в формате JPG, выберите другое изображение и повторите загрузку.',
                        },
                      });
                    }
                    if (error.code === ErrorCodeEnum.maximumFileCount) {
                      notify.danger({
                        data: {
                          label: `Максимальное количество загружаемых файлов - ${photoCount}.`,
                        },
                      });
                    }
                  }
                }}
                accepts={['.jpg', '.jpeg']}
                maxFileSize={2_621_440}
                minFileSize={314_572}
              >
                <div className="org-photo-dropzone__title font-size-base">Перетащите файлы сюда, чтобы загрузить</div>
                <Push size={16} />
                <div className="text-center line-height-text">
                  Формат файлов: JPG. Ориентация — горизонтальная.
                  <br />
                  Ширина: 1600-1920 px. Высота: 900-1200 px. Размер: 0,3-2,5 Мб.
                  <br />
                  Не более 4 фотографий.
                </div>
                <Push size={16} />
                <Button
                  label="Выбрать файл"
                  primary
                  size="small"
                  disabled={!(files?.filter((f) => !f.removed)?.length < photoCount ?? true)}
                />
              </Files>
              <DndProvider backend={HTML5Backend}>
                {!!files.length && (
                  <>
                    <Push size={24} />
                    <div className="font-size-base font-weight-bold">Загруженные фотографии</div>
                    <Push size={16} />
                    <div className="service-photo-grid">
                      {files
                        .filter((f) => !f.removed)
                        .map((file, i) => (
                          <PhotoCard
                            key={file.id}
                            file={file}
                            files={files}
                            index={i}
                            setFiles={setFiles}
                            moveCard={moveCard}
                            openGallery={() => openFullScreen(file, true)}
                          />
                        ))}
                    </div>
                  </>
                )}
              </DndProvider>
              <Push size={24} />
            </div>
          )
        ) : (
          <div className="container">
            <Infobox
              fullWidth
              color="warning"
              text="Возможность добавления фотографий услуги станет доступна после первого сохранения."
            />
            <Push size={24} />
          </div>
        )}
      </Panel>

      {editMode && setEditModeParent && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  onClick={() => {
                    setEditMode(false);
                    setEditModeParent && setEditModeParent(null);
                    setFiles(servicePhotos);
                  }}
                  border
                  label="Отмена"
                  primary
                />
                <Push size={12} orientation="horizontal" />
                <Button onClick={save} primary label="Сохранить" load={loader} />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentImage && (
        <GalleryModal
          show={fullScreen}
          currentImage={currentImage}
          onCloseHandler={() => setFullScreen(false)}
          images={files.filter((item) => !item.removed)}
        />
      )}
    </>
  );
};

export default Photo;
