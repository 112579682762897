import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Input, Select, SelectOptionType, Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { ReactComponent as IconEditGray } from '../../../../assets/images/icons/edit.svg';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconCheck } from '../../../../assets/images/icons/check.svg';
import { ReactComponent as IconClose } from '../../../../assets/images/icons/close.svg';
import { DisciplineData } from '../../../../types/service';
import lookupApi from '../../../../lib/api/lookup';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../../index';
import RemoveModal from '../../../../components/remove-modal';

const Disciplines = () => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [optionsState, setOptionsState] = useState<(DisciplineData & { number?: number })[]>([]);
  const [editObj, setEditObj] = useState<(DisciplineData & { number?: number }) | null>(null);
  const [removedId, setRemovedId] = useState(0);

  const checkEditable = serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const updateByClassificator = async () => {
    await serviceTemplateApi.updateByClassifier(serviceData.id);
    updateService();
  };

  useEffect(() => {
    setOptionsState(serviceData?.serviceDisciplines?.map((item, index) => ({ ...item, number: index + 1 })) ?? []);
  }, [serviceData?.serviceDisciplines]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'number',
        title: '№',
        render: (item: any) => (
          <div className={editObj?.number === item.number ? 'table-row-item-height flex items-center' : ''}>
            {item.number}
          </div>
        ),
        width: '40px',
      },
      {
        dataIndex: 'disciplineName',
        title: 'Наименование дисциплины',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <Select
              name="disciplineId"
              size="small"
              options={
                editObj?.disciplineId && editObj?.disciplineName
                  ? [{ label: editObj.disciplineName, value: editObj.disciplineId }]
                  : []
              }
              value={editObj?.disciplineId ? { value: editObj?.disciplineId, label: editObj?.disciplineName } : null}
              isSearchable
              loadOptions={(query) => lookupApi.getDisciplines(query)}
              onChange={(selectedOption) => {
                const option = selectedOption
                  ? (selectedOption as SelectOptionType)
                  : { value: undefined, label: undefined };
                setEditObj((prev) => {
                  if (option.value && typeof option.value === 'string') {
                    return { ...prev, disciplineId: parseInt(option.value), disciplineName: option.label };
                  }
                  if (option.value && typeof option.value === 'number') {
                    return { ...prev, disciplineId: option.value, disciplineName: option.label };
                  }
                  return { ...prev, disciplineId: undefined, disciplineName: undefined };
                });
              }}
              placeholder="Начните вводить..."
            />
          ) : (
            item.disciplineName
          ),
        width: '33%',
      },
      {
        dataIndex: 'hours',
        title: 'Кол-во часов',
        render: (item: any) =>
          editObj?.number === item.number ? (
            <div style={{ width: 88 }}>
              <Input
                size="small"
                value={editObj?.hoursCount}
                onChange={(value) => {
                  setEditObj((prev) => ({ ...prev, hoursCount: parseInt(value.target.value) || 0 }));
                }}
              />
            </div>
          ) : (
            item.hoursCount
          ),
        width: '33%',
      },
      {
        dataIndex: 'required',
        title: 'Обязательная',
        render: (item: any) => (
          <div className="flex">
            <div className="flex-auto">
              {editObj?.number === item.number ? (
                <div className="table-row-item-height flex items-center">
                  <Checkbox
                    checked={editObj?.isRequired || false}
                    onChange={(value) => setEditObj((prev) => ({ ...prev, isRequired: value }))}
                    labelId={item.number}
                  />
                </div>
              ) : item.isRequired ? (
                'Да'
              ) : (
                'Нет'
              )}
            </div>
            <div className="flex-none">
              {editObj?.number === item.number ? (
                <div className="flex">
                  <Button
                    iconLeft={() => <IconClose />}
                    border
                    size="small"
                    handleClick={() => {
                      setEditObj(null);
                      if (!item.disciplineId) {
                        setOptionsState((prev) => prev.slice(0, -1));
                      }
                    }}
                  />
                  <Push size={8} orientation="horizontal" />
                  <Button
                    iconLeft={() => <IconCheck />}
                    success
                    size="small"
                    border
                    disabled={!(editObj?.disciplineId && editObj.hoursCount)}
                    handleClick={async () => {
                      let id = editObj?.id;
                      if (editObj?.id) {
                        await serviceTemplateApi.updateDiscipline(serviceData.id, serviceData.educationTypeId, editObj);
                      } else if (editObj) {
                        id = await serviceTemplateApi.createDiscipline(
                          serviceData.id,
                          serviceData.educationTypeId,
                          editObj
                        );
                      }
                      setOptionsState((prev) => {
                        if (editObj?.number) {
                          prev[editObj.number - 1] = { ...editObj, id };
                        }
                        return [...prev];
                      });
                      setEditObj(null);
                      updateService();
                    }}
                  />
                </div>
              ) : (
                checkEditable && (
                  <DropDown
                    component={() => (
                      <span className="drop-down-btn-round">
                        <IconDots />
                      </span>
                    )}
                  >
                    <div className="drop-down-panel">
                      <div className="drop-down-panel__list">
                        <button type="button" onClick={() => setEditObj(item)} className="drop-down-panel__list-item">
                          <IconEditGray />
                          Редактировать
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setRemovedId(item.id);
                          }}
                          className="drop-down-panel__list-item"
                        >
                          <IconRemove />
                          Удалить
                        </button>
                      </div>
                    </div>
                  </DropDown>
                )
              )}
            </div>
          </div>
        ),
        width: '33%',
      },
    ],
    [editObj, serviceData.educationTypeId, serviceData.id, updateService, checkEditable]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Дисциплины
            <span className="color-gray-dark">
              {' \u00A0'} {optionsState.length}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            checkEditable && (
              <div className="flex">
                {serviceData?.id > 0 && (
                  <>
                    <Button
                      onClick={updateByClassificator}
                      disabled={!!editObj}
                      primary
                      border
                      label="Обновить по РБНДО"
                    />
                    <Push size={12} orientation="horizontal" />
                  </>
                )}
                <button
                  type="button"
                  onClick={() => {
                    setOptionsState((prev) => [...prev, { number: optionsState.length + 1 }]);
                    setEditObj({ number: optionsState.length + 1 });
                  }}
                  disabled={!!editObj}
                  className="icon-group"
                >
                  <span className="icon-group__icon">
                    <IconPlus />
                  </span>
                  <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
                </button>
              </div>
            )
          );
        }}
      >
        <Table overflow data={optionsState} hideSort columns={columns} />
      </Panel>
      <RemoveModal
        title="Дисциплина"
        description="Внимание! Данный объект будет удален из списка дисциплин. Хотите продолжить?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await serviceTemplateApi.deleteDiscipline(serviceData.id, removedId);
          setRemovedId(0);
          setOptionsState((prev) => prev.filter((item) => item.id !== removedId));
          updateService();
        }}
      />
    </>
  );
};

export default Disciplines;
