import React from 'react';
import { Push } from '@mosru/esz_uikit';
import { EntityTypeEnum } from '../../../../mock-data/entity-type-enum';
import ChangeHistory from '../../../../components/change-history';
import Enrollments from '../../../../components/change-history/enrollments';
import Expulsions from '../../../../components/change-history/expulsions';
import TransfersGroup from '../../../../components/change-history/transfers-group';
import Recoveries from '../../../../components/change-history/recoveries';
import TransfersService from '../../../../components/change-history/transfers-service';
import TransfersStage from '../../../../components/change-history/transfers-stage';
import StartEndDateChange from '../../../../components/change-history/start-end-date-change';

type Props = {
  extendEntityGuid: string;
  peopleId: number;
};

const LearnerHistory = ({ extendEntityGuid, peopleId }: Props) => {
  return (
    <>
      <Push size={12} />
      <ChangeHistory
        name="сведения об обучающемся"
        extendEntityGuid={extendEntityGuid}
        entityTypes={[EntityTypeEnum.Pupil]}
      />
      <Enrollments type="learner" id={peopleId ?? 0} />
      <TransfersGroup type="learner" id={peopleId ?? 0} />
      <Expulsions type="learner" id={peopleId ?? 0} />
      <Recoveries type="learner" id={peopleId ?? 0} />
      <TransfersService educationType={1} type="learner" id={peopleId ?? 0} />
      <TransfersStage type="learner" id={peopleId ?? 0} />
      <StartEndDateChange type="learner" id={peopleId ?? 0} />
    </>
  );
};

export default LearnerHistory;
