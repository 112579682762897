import { Badge, SelectOptionType } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';
import { serviceTemplateApi } from '../../lib/api/service-template';
import { RegistryTypeEnum } from '../../mock-data/registry-type-enum';
import { EducationTypeEnum } from '../../types/education-type';
import { ParallelOptionsByResponse } from './components/fields/parallel';
import { hasAccessObjectAny, hasGeneralAccess } from '../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../mock-data/access-enum';
import { AuthorizationData } from '../../types/authorization-data';
import { ServiceStatusEnum } from '../../mock-data/service-status-enum';
import { ServiceData, ServiceDataInfo } from '../../types/service';

export const MAX_STAGE_NAME_LENGTH = 300;

export const toArchive = async (id: string) => {
  if (id) {
    try {
      await serviceTemplateApi.serviceToArchive(id);
    } catch {}
  }
};

export const getHeaderLink = (
  currentEducationType: EducationTypeEnum[],
  type: RegistryTypeEnum,
  educationTypeId: number
): string => {
  type LinkByEducationRegistryType = {
    [index: number]: string;
  };
  // Когда у пользователя больше 1 роли, мы делаем редирект на общий роут.
  const checkAccessAmount = currentEducationType.length > 1;

  const childrenEducationLink = checkAccessAmount ? routes.programs : routes.registerChildEducations;
  const dkgmOrDsitEducationLink = type === RegistryTypeEnum.serviceTemplateType ? routes.myTemplates : routes.services;

  const linkByEducationRegistryType: LinkByEducationRegistryType = {
    [EducationTypeEnum.ChildrenEducation]: childrenEducationLink,
    [EducationTypeEnum.DayCareCentersEducation]: checkAccessAmount ? routes.programs : routes.dayCarePrograms,
    [EducationTypeEnum.VirtualAssistantEducation]: checkAccessAmount ? routes.programs : routes.services,
    [EducationTypeEnum.ChildrenNonDogmEducation]: childrenEducationLink,
    [EducationTypeEnum.ArtHouseEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.SportEducation]: dkgmOrDsitEducationLink,
    [EducationTypeEnum.ProfessionalEducation]: routes.registerEducationPrograms,
  };

  return linkByEducationRegistryType[educationTypeId];
};

export const convertParallelInResponse = (options: SelectOptionType[]): ParallelOptionsByResponse[] => {
  return options?.map(
    (item: SelectOptionType) => ({ id: item.value, parallelName: item.label } as ParallelOptionsByResponse)
  );
};

export const convertParallelInSelect = (options: ParallelOptionsByResponse[]): SelectOptionType[] => {
  const newOptions = options?.map(
    (item: ParallelOptionsByResponse) => ({ value: item.id, label: item.parallelName } as SelectOptionType)
  );
  const sort = newOptions.sort((a, b) => {
    const labelFirst = +a.label;
    const labelSecond = +b.label;

    if (labelFirst > labelSecond) {
      return 1;
    } else if (labelFirst < labelSecond) {
      return -1;
    } else {
      return 0;
    }
  });
  return sort;
};

export const organizationAccess = (userProfile: AuthorizationData): boolean => {
  return (
    hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ||
    hasGeneralAccess(userProfile, generalAccess.AdminView) ||
    hasGeneralAccess(userProfile, generalAccess.AdminEdit)
  );
};

export const getTabsButton = (serviceStatusId: ServiceStatusEnum, serviceStatusName: string): JSX.Element => {
  const color =
    serviceStatusId === ServiceStatusEnum.Arhive
      ? 'default'
      : serviceStatusId === ServiceStatusEnum.Draft
      ? 'warning'
      : 'success';

  return <Badge text={serviceStatusName} color={color} />;
};

export const accessPanelEdit = (
  userProfile: AuthorizationData,
  isAdmin: boolean,
  educationType: EducationTypeEnum,
  isTemplate?: boolean
): boolean => {
  const serviceDOGM: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceDOGM], accessAction.Edit);

  const serviceNonDOGM: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceNonDOGM], accessAction.Edit);

  const serviceSport = hasAccessObjectAny(
    userProfile,
    [isTemplate ? accessObject.TemplateSport : accessObject.ServiceSport],
    accessAction.Edit
  );

  const serviceArtHouse: boolean = hasAccessObjectAny(
    userProfile,
    [isTemplate ? accessObject.TemplateArtHouse : accessObject.ServiceArtHouse],
    accessAction.Edit
  );

  const serviceOP: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceOP], accessAction.Edit);

  const serviceDayCare: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.Edit);

  const serviceVa: boolean = hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Edit);

  const access = {
    [EducationTypeEnum.DayCareCentersEducation]: serviceDayCare,
    [EducationTypeEnum.VirtualAssistantEducation]: serviceVa,
    [EducationTypeEnum.ChildrenNonDogmEducation]: serviceNonDOGM,
    [EducationTypeEnum.ArtHouseEducation]: serviceArtHouse,
    [EducationTypeEnum.ChildrenEducation]: serviceDOGM,
    [EducationTypeEnum.SportEducation]: serviceSport,
    [EducationTypeEnum.ProfessionalEducation]: serviceOP,
  };

  return access[educationType] || isAdmin;
};

export const accessObjectByEducationTypeService: { [key: number]: number } = {
  [EducationTypeEnum.DayCareCentersEducation]: accessObject.ServiceDayCare,
  [EducationTypeEnum.ProfessionalEducation]: accessObject.ServiceOP,
  [EducationTypeEnum.VirtualAssistantEducation]: accessObject.ServiceVA,
  [EducationTypeEnum.ArtHouseEducation]: accessObject.ServiceArtHouse,
  [EducationTypeEnum.ChildrenEducation]: accessObject.ServiceDOGM,
  [EducationTypeEnum.ChildrenNonDogmEducation]: accessObject.ServiceNonDOGM,
  [EducationTypeEnum.SportEducation]: accessObject.ServiceSport,
};

export const accessByEducationTypeService = (
  userProfile: AuthorizationData,
  educationType: number,
  accessAction: number
): boolean => {
  return hasAccessObjectAny(userProfile, [accessObjectByEducationTypeService[educationType]], accessAction);
};

export const setGenderStartEndToBack = (info: ServiceDataInfo) => {
  const newInfo = { ...info };
  if (info.isFemalePresent) {
    newInfo.altFemaleStart = info.altStart;
    newInfo.altFemaleEnd = info.altEnd;
  }
  if (!info.isMalePresent) {
    newInfo.altStart = undefined;
    newInfo.altEnd = undefined;
  }
  return newInfo;
};

export const setGenderStartEndFromBack = (serviceData: ServiceData) => {
  if (serviceData.info.isFemalePresent && serviceData.info.altFemaleEnd) {
    serviceData.info.altStart = serviceData.info.altFemaleStart;
    serviceData.info.altEnd = serviceData.info.altFemaleEnd;
  }
  return serviceData;
};
