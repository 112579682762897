import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ServiceData } from '../../../types/service';
import { EducationTypeEnum } from '../../../types/education-type';
import LightTable, { LightTableType } from '../light-table';
import { convertScheduleToFront, getDayOfTheWeekFromString } from '../../../lib/utils/time-table';
import { period } from '../../../components/schedule';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { accessVedomst } from '../../../mock-data/access-enum';

type Props = {
  data: ServiceData | undefined;
  type: string | undefined;
};

const name: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'услуги',
  [EducationTypeEnum.VirtualAssistantEducation]: 'услуги',
  [EducationTypeEnum.ArtHouseEducation]: 'услуги',
  [EducationTypeEnum.ChildrenEducation]: 'детского объединения',
  [EducationTypeEnum.SportEducation]: 'услуги',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'услуги',
  [EducationTypeEnum.ProfessionalEducation]: 'образовательной программы',
};

const schedulerName: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'Расписание предоставления услуги',
  [EducationTypeEnum.ArtHouseEducation]: 'Расписание предоставления услуги',
  [EducationTypeEnum.ChildrenEducation]: 'Время проведения занятий',
  [EducationTypeEnum.SportEducation]: 'Расписание предоставления услуги',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'Расписание предоставления услуги',
  [EducationTypeEnum.ProfessionalEducation]: 'Время проведения занятий',
};

const duration: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'услуги',
  [EducationTypeEnum.VirtualAssistantEducation]: 'программы обучения',
  [EducationTypeEnum.ArtHouseEducation]: 'программы обучения',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'программы обучения',
  [EducationTypeEnum.ChildrenEducation]: 'программы обучения',
  [EducationTypeEnum.SportEducation]: 'программы обучения',
  [EducationTypeEnum.ProfessionalEducation]: 'программы обучения',
};
const title: { [index: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]: 'Описание услуги',
  [EducationTypeEnum.VirtualAssistantEducation]: 'Описание услуги',
  [EducationTypeEnum.ArtHouseEducation]: 'Описание услуги',
  [EducationTypeEnum.ChildrenNonDogmEducation]: 'Описание услуги',
  [EducationTypeEnum.ChildrenEducation]: 'Детское объединение',
  [EducationTypeEnum.SportEducation]: 'Описание услуги',
  [EducationTypeEnum.ProfessionalEducation]: 'Образовательная программа',
};

const PrintList: React.FC<Props> = ({ data }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isShowRestrictions = useMemo(() => userProfile.vedomstvoId === accessVedomst.Kdc, [userProfile.vedomstvoId]);

  const schedulers = useMemo(
    () =>
      ({
        column: [
          {
            name: 'День недели',
          },
          {
            name: 'Время',
          },
        ],
        row:
          data &&
          convertScheduleToFront(data.schedule.list)
            .filter((s) => !s.dayOff)
            .map((item) => ({
              description: [
                { name: item.name },
                {
                  name:
                    item.period === 3
                      ? `${item.from} — ${item.to}`
                      : `${period.find((p) => p.value === item.period)?.label ?? ''}`,
                },
              ],
            })),
      } as LightTableType),
    [data]
  );
  const addressPlace = useMemo(
    () =>
      ({
        column: [
          {
            name: 'Наименование организации',
          },
          {
            name: 'Адрес',
          },
        ],
        row: data?.placeService.list.map((item) => ({
          description: [{ name: item.name }, { name: item.fullAddress }],
        })),
      } as LightTableType),
    [data]
  );
  const addressDoc = useMemo(
    () =>
      ({
        column: [
          {
            name: '№',
          },
          {
            name: 'Наименование организации',
          },
          {
            name: 'Адрес',
          },
        ],
        row: data?.itemWork.list.map((item, index) => ({
          description: [
            { name: index + 1 },
            {
              name: (
                <>
                  {item.fullName}
                  <br />
                  {getDayOfTheWeekFromString(item.timetable).map((item: string, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`schedule_${index}`}>{item}</div>
                  ))}
                </>
              ),
            },
            { name: item.fullAddress },
          ],
        })),
      } as LightTableType),
    [data]
  );
  const stages = useMemo(
    () =>
      ({
        column: [
          {
            name: '№',
          },
          {
            name: 'Наименование',
          },
        ],
        row: data?.stage.list.map((item, index) => ({
          description: [{ name: index + 1 }, { name: item.name }],
        })),
      } as LightTableType),
    [data]
  );
  const disciplines = useMemo(
    () =>
      ({
        column: [
          {
            name: '№',
          },
          {
            name: 'Наименование дисциплины',
          },
          {
            name: 'Количество часов',
          },
          {
            name: 'Обязательная',
          },
        ],
        row: data?.serviceDisciplines.map((item, index) => ({
          description: [
            { name: index + 1 },
            { name: item.disciplineName },
            { name: item.hoursCount },
            { name: item.isRequired ? 'да' : 'нет' },
          ],
        })),
      } as LightTableType),
    [data]
  );
  return data ? (
    <div className="word-break-all">
      <h2 className="text-center">{title[data.educationTypeId]}</h2>
      {data.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      data.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
      data.educationTypeId === EducationTypeEnum.SportEducation ? (
        <p>
          <strong>Реестр базовых направлений: </strong> {data.info.classificatorEKUName}
        </p>
      ) : null}
      {data.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      data.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
      data.educationTypeId === EducationTypeEnum.SportEducation ? (
        <p>
          <strong>Организация: </strong> {data.info.organizationName}
        </p>
      ) : null}
      <p>
        <strong>Наименование {name[data.educationTypeId]}: </strong> {data.info.name || ''}
      </p>
      <p>
        <strong>Код {name[data.educationTypeId]}:</strong> {data.info.code}
      </p>
      <p>
        <strong>Продолжительность {duration[data.educationTypeId]}: </strong>
        {data.info.durationOfTraining ? (
          <>
            <strong>
              {data.info.educationTypeId === EducationTypeEnum.SportEducation ||
              data.info.educationTypeId === EducationTypeEnum.ArtHouseEducation
                ? data.info.durationOfTrainingUnitName
                : 'лет'}
              :
            </strong>
            &nbsp;
            {data.info.durationOfTraining}
          </>
        ) : null}
        {data.info.durationOfTrainingMonths ? (
          <>
            &nbsp;
            <strong>месяцев:</strong>
            &nbsp;
            {data.info.durationOfTrainingMonths}
            &nbsp;
          </>
        ) : null}
        {data.info.durationOfTrainingWeeks ? (
          <>
            &nbsp;
            <strong>недель:</strong>
            &nbsp;
            {data.info.durationOfTrainingWeeks}
            &nbsp;
          </>
        ) : null}
        {data.info.durationOfTrainingDays ? (
          <>
            <strong>дней:</strong>
            &nbsp;
            {data.info.durationOfTrainingDays}
            &nbsp;
          </>
        ) : null}
      </p>
      {data.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      data.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
      data.educationTypeId === EducationTypeEnum.SportEducation ? (
        <p>
          <strong>Уровень образовательной программы: </strong> {data.info.programmLevelName}
        </p>
      ) : null}
      <p>
        <strong>Описание {name[data.educationTypeId]}: </strong> {data.info.programmService || ''}
      </p>
      <p>
        <strong>Порядок предоставления услуги: </strong> {data.info.ruleService}
      </p>
      {data.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      data.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
      data.educationTypeId === EducationTypeEnum.SportEducation ? (
        <>
          <h2 className="text-center">Этапы обучения</h2>
          <LightTable data={stages} />
        </>
      ) : null}
      {data.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      data.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
      data.educationTypeId === EducationTypeEnum.SportEducation ? (
        <>
          <h2 className="text-center">Дисциплины</h2>
          <LightTable data={disciplines} />
        </>
      ) : null}
      <h2 className="text-center">Пол и возраст</h2>
      {data.info.isFemalePresent && data.info.isMalePresent ? (
        <p>
          <strong>Все: </strong> c {data.info.altStart} по {data.info.altEnd}
        </p>
      ) : data.info.isFemalePresent ? (
        <p>
          <strong>Женский: </strong> c {data.info.altFemaleStart} по {data.info.altFemaleEnd}
        </p>
      ) : (
        <p>
          <strong>Мужской: </strong> c {data.info.altStart} по {data.info.altEnd}
        </p>
      )}
      <h2 className="text-center">Финансирование</h2>
      <p>
        <strong>Тип финансирования: </strong> {data.financing.typeFinancingName}
      </p>
      {data.financing.typeFinancingId !== 3 ? (
        <>
          <p>
            <strong>Тип стоимости: </strong> {data.financing.typeValueServiceName}
          </p>
          <p>
            <strong>Стоимость, руб: </strong> {data.financing.periodPrice}
          </p>
          {data.educationTypeId === EducationTypeEnum.SportEducation ? (
            <p>
              <strong>Процент субсидии: </strong> {data.financing.subsidiesPercent}
            </p>
          ) : null}
        </>
      ) : null}

      {isShowRestrictions ? (
        <>
          <h2 className="text-center">Ограничения</h2>
          <p>
            <strong>Только для лиц с ограниченными возможностями:</strong>{' '}
            {data.candidateRestrictionType.canChangeCandidateRestriction ? 'да' : 'нет'}
          </p>
        </>
      ) : null}
      {data.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
      data.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
      data.educationTypeId === EducationTypeEnum.SportEducation ? (
        <>
          <h2 className="text-center">Дополнительно</h2>
          <p>
            <strong>Наличие вступительных испытаний: </strong> {data.test.isRequired ? 'да' : 'нет'}
          </p>
          {data.test.isRequired ? (
            <p>
              <strong>Количество туров: </strong> {data.test.toursNumber}
            </p>
          ) : null}
          <p>
            <strong>Срок предоставления документов / выкупа брони, дней: </strong> {data.test.periodGettingDocument}
          </p>
        </>
      ) : null}
      {data.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation && (
        <>
          <h2 className="text-center">{schedulerName[data.educationTypeId]}</h2>
          <LightTable data={schedulers} />
        </>
      )}
      <h2 className="text-center">Контактные данные</h2>
      <p>
        <strong>ФИО контактного лица: </strong> {data.contactPerson.fullName || ''}
      </p>
      <p>
        <strong>Телефон контактного лица: </strong> {data.contactPerson.phone || ''}
      </p>
      {data.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation && (
        <>
          <h2 className="text-center">Адреса предоставления услуги</h2>
          <LightTable data={addressPlace} />
          <h2 className="text-center">Адреса приема документов</h2>
          <LightTable data={addressDoc} />
        </>
      )}
    </div>
  ) : null;
};

export default PrintList;
