import React from 'react';
import moment from 'moment';
import { Switcher, Push } from '@mosru/esz_uikit';
import { GetReport, OptionFilter, TypeSwitch } from '../../../../types/reports';
import { onSelectedSwitch } from '../../../../lib/utils/reports';
import { ReportFilterTypeEnum } from '../../../../mock-data/report-filter-type-enum';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';

type Props = {
  filterData: GetReport | null;
  setTypeFinance: (value: TypeSwitch[]) => void;
  typeFinance: TypeSwitch[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

const FilterHorizontal: React.FC<Props> = ({ filterData, setTypeFinance, typeFinance, setFieldValue }) => {
  const currentData = moment().format();

  // Источник финансирования
  const sourceFinance = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
        if (item.key === ReportFilterTypeEnum.ByTypeFinansing && item.visible) {
          return (
            <div className="column__switch" key={item.key}>
              <div>{item.parameterCaption}</div>
              <Push size={8} />
              <Switcher fullWidth size="small">
                {typeFinance.map((element: TypeSwitch) => {
                  return (
                    <button
                      key={element.id}
                      type="button"
                      onClick={() => {
                        onSelectedSwitch(element.id, setTypeFinance);
                        setFieldValue(item.parameterField, element.id);
                      }}
                      className={element.selected ? 'active' : ''}
                    >
                      {element.name}
                    </button>
                  );
                })}
              </Switcher>
            </div>
          );
        }
        return null;
      })
    : null;

  // на дату
  const onDate = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
        if (item.key === ReportFilterTypeEnum.ByReportDate && item.visible) {
          return (
            <div className="column__switch" key={item.key}>
              <div>{item.parameterCaption}</div>
              <Push size={8} />
              <FormikFormGroup label="" name={item.parameterField}>
                <FormikDatePicker
                  name={item.parameterField}
                  placeholder="ДД.ММ.ГГГГ"
                  size="small"
                  startDate={new Date(currentData)}
                />
              </FormikFormGroup>
            </div>
          );
        }
        return null;
      })
    : null;

  // за период
  const onPeriod = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
        if (item.key === ReportFilterTypeEnum.ByPeriodFrom && item.visible) {
          return (
            <div style={{ maxWidth: '215px' }} className="column__switch" key={item.key}>
              <div>{item.parameterCaption}</div>
              <Push size={8} />
              <FormikFormGroup label="" name={item.parameterField} showErrorImmediately>
                <FormikDatePicker
                  name={item.parameterField}
                  placeholder="ДД.ММ.ГГГГ"
                  size="small"
                  startDate={item.value && new Date(item.value)}
                />
              </FormikFormGroup>
            </div>
          );
        }
        return null;
      })
    : null;

  // за период по
  const beforeDate = filterData?.filterList
    ? filterData?.filterList.map((item: OptionFilter) => {
        if (item.key === ReportFilterTypeEnum.ByPeriodTo && item.visible) {
          return (
            <div style={{ maxWidth: '215px' }} className="column__switch" key={item.key}>
              <div>{item.parameterCaption}</div>
              <Push size={8} />
              <FormikFormGroup label="" name={item.parameterField} showErrorImmediately>
                <FormikDatePicker
                  name={item.parameterField}
                  placeholder="ДД.ММ.ГГГГ"
                  size="small"
                  startDate={item.value && new Date(item.value)}
                />
              </FormikFormGroup>
            </div>
          );
        }
        return null;
      })
    : null;

  return (
    <div className="wrap__switch">
      {sourceFinance}
      {onDate}
      {onPeriod}
      {beforeDate}
    </div>
  );
};

export default FilterHorizontal;
