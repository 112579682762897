import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { serviceIdField } from './service';
import { DependentServiceContext } from './dependent-service';

type ModuleFieldProps = FieldProps<string> & Record<string, any>;

export const moduleFieldId = 'programmModuleId';
export const moduleFieldName = 'programmModuleName';

const ModuleField = ({
  label = 'Модуль',
  name,
  editMode,
  required,
  defaultValue = '—',
  disabled,
}: ModuleFieldProps) => {
  const { moduleKey } = useContext(DependentServiceContext);

  const fieldName = `${name}Name`;
  const fieldIdName = `${name}Id`;
  const [serviceField] = useField<number>(serviceIdField);

  const [moduleData, setModuleData] = useState<SelectOptionType[]>([]);

  const [fieldLabel, , helpersLabel] = useField(fieldName);
  const [fieldId] = useField<number>(fieldIdName);

  // загрузка справочиков
  useEffect(() => {
    const fetchTrainGroup = async (serviceId: number) => {
      setModuleData(await serviceTemplateApi.getListProgramModule({ serviceId }));
    };

    if (serviceField.value) {
      fetchTrainGroup(serviceField.value);
    } else {
      setModuleData([]);
    }
  }, [serviceField.value]);

  return (
    <Field label={label} editMode={editMode} required={required} value={fieldLabel.value} defaultValue={defaultValue}>
      <FormikSelect
        key={moduleKey}
        name={fieldIdName}
        size="small"
        isSearchable
        options={moduleData}
        defaultValue={fieldId.value ? { value: fieldId.value, label: fieldLabel.value } : null}
        selectedValue={(selected: SelectOptionType) => {
          helpersLabel.setValue(selected ? selected.label : undefined);
        }}
        placeholder="Начните вводить или выберите..."
        disabled={disabled}
      />
    </Field>
  );
};

export default ModuleField;
