import React from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import FilterBottom from './filter-bottom';
import FilterHorizontal from './filter-horizontal';
import { GetReport, ReportFilterData, ReportSchedule, TypeSwitch } from '../../../../types/reports';
import FilterVertical from './filter-vertical';

type Props = {
  currentReportId: number;
  filterData: GetReport | null;
  typeFinance: TypeSwitch[];
  setTypeFinance: (value: TypeSwitch[]) => void;
  setOpenTreePopup: (value: boolean) => void;
  openScheduleModal: () => void;
  resetScheduleModalHandler: (value: boolean) => void;
  listSchedule: ReportSchedule;
  values: ReportFilterData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setValueBasicDirection: React.Dispatch<React.SetStateAction<SelectOptionType>>;
  valueBasicDirection: SelectOptionType;
};

const FilterItem: React.FC<Props> = ({
  setTypeFinance,
  filterData,
  currentReportId,
  typeFinance,
  setOpenTreePopup,
  openScheduleModal,
  resetScheduleModalHandler,
  listSchedule,
  values,
  setFieldValue,
  setValueBasicDirection,
  valueBasicDirection,
}) => {
  return (
    <>
      <FilterVertical
        values={values}
        filterData={filterData}
        listSchedule={listSchedule}
        currentReportId={currentReportId}
        setOpenTreePopup={setOpenTreePopup}
        openScheduleModal={openScheduleModal}
        setFieldValue={setFieldValue}
        setValueBasicDirection={setValueBasicDirection}
        valueBasicDirection={valueBasicDirection}
        resetScheduleModalHandler={resetScheduleModalHandler}
      />

      <FilterHorizontal
        filterData={filterData}
        typeFinance={typeFinance}
        setTypeFinance={setTypeFinance}
        setFieldValue={setFieldValue}
      />
      <FilterBottom filterData={filterData} />
    </>
  );
};

export default FilterItem;
