import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number, object as objectYup, string as stringYup } from 'yup';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalPanel,
  Modal,
  SelectOptionType,
  SelectValueType,
  Panel,
  Switcher,
  Loader,
  Push,
  SimpleTable as Table,
} from '@mosru/esz_uikit';
import { ReactComponent as IconPlus } from '../../../assets/images/icons/plus-color.svg';
import { ReactComponent as IconDots } from '../../../assets/images/icons/3dots.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikInput from '../../../components/formik/formik-input';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import FormikSelect from '../../../components/formik/formik-select';
import { maxLengthTextError } from '../../../lib/utils/validation';
import { PlaceServices } from '../../../types/place-service';
import DropDown from '../../../components/drop-down';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/remove.svg';
import lookupApi from '../../../lib/api/lookup';
import { hasAccessObjectAny } from '../../../lib/utils';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import RemoveModal from '../../../components/remove-modal';
import { Organization } from '../../../types/organization';
import { dictionariesApi } from '../../../lib/api/dictionaries';

type FormData = {
  name?: string;
  addressId?: SelectValueType | null;
  sportObjectId?: number | null;
};

type Props = {
  organization: Organization;
  setPlace: (value: PlaceServices[]) => void | undefined;
};

const OrganizationFormService = ({ organization, setPlace }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [loading, setLoading] = useState<boolean>(true);

  const [editMode, setEditMode] = useState(false);
  const [switchForm, setSwitchForm] = useState<boolean>(false); // false - городской обьект

  const [listAddress, setListAddress] = useState<SelectOptionType[]>();
  const [currentAddress, setCurrentAddress] = useState<SelectOptionType>();

  const [placeServices, setPlaceServices] = useState<PlaceServices[]>();
  const [placeService, setPlaceService] = useState<PlaceServices | null>();

  const [removedId, setRemovedId] = useState(0);
  const [rerenderTableKey, setRerenderTableKey] = useState(0);

  const tableData = placeServices?.map((item, index) => ({ count: index + 1, ...item }));

  const [sityObject, setSityObject] = useState<SelectOptionType & { additionalPropertiesJson?: string }>();

  const initialFormData = useMemo(() => {
    return switchForm
      ? {
          name: '',
          addressId: currentAddress?.value ?? null,
        }
      : {
          sportObjectId: null,
        };
  }, [switchForm, currentAddress]);

  const itemPlaceFormData = useMemo(() => {
    return placeService
      ? switchForm
        ? placeService.sportObjectId
          ? initialFormData
          : {
              name: placeService.name || '',
              addressId: placeService.addressId || null,
            }
        : {
            sportObjectId: placeService.sportObjectId,
          }
      : initialFormData;
  }, [placeService, switchForm, initialFormData]);

  const initialErrors = useInitialErrors(itemPlaceFormData, getValidationSchema(switchForm));

  const getPlaceService = useCallback(async () => {
    if (organization.id) {
      const placeService = await dictionariesApi.getPlaceServiceList(organization.id);
      setPlaceServices(placeService);
      setLoading(false);
      setPlace(placeService);
    }
  }, [organization.id, setPlace]);

  const submitForm = useCallback(
    async (values: FormData) => {
      if (placeService?.id) {
        const conditionSend = switchForm
          ? ({ ...placeService, name: values.name, addressId: values.addressId, sportObjectId: null } as PlaceServices)
          : ({
              ...placeService,
              name: '',
              sportObjectId: values.sportObjectId,
              addressId: sityObject?.additionalPropertiesJson,
              organizationId: organization.id || 0,
            } as PlaceServices);

        await dictionariesApi.updatePlaceService(conditionSend.id, conditionSend);
        setPlaceService(null);
      } else {
        const conditionNewPlace = values.sportObjectId
          ? {
              sportObjectId: values.sportObjectId,
              addressId: sityObject?.additionalPropertiesJson,
              organizationId: organization.id,
            }
          : { name: values.name, addressId: values.addressId, organizationId: organization.id };

        await dictionariesApi.postPlaceService(conditionNewPlace);
        setPlaceService(null);
      }
      getPlaceService();
      setEditMode(false);
      setSwitchForm(false);
      setRerenderTableKey(Math.random());
    },
    [getPlaceService, organization.id, placeService, sityObject?.additionalPropertiesJson, switchForm]
  );

  const deletePlaceService = (id: number) => {
    return dictionariesApi.deletePlaceService({ ids: [id], organizationId: organization.id });
  };

  const getPlaceServiceResult = async (id: number) => {
    const response = await dictionariesApi.getPlaceService(id);
    setPlaceService(response);

    // включаем активную вкладку при редактировании
    if (!response.sportObjectId) {
      setSwitchForm(true);
    }
  };

  useEffect(() => {
    getPlaceService();
  }, [getPlaceService]);

  // Получаем текущий адрес
  useEffect(() => {
    const currentAddress = organization.addressList?.find((item) => organization.addressId === item.id);

    if (currentAddress) {
      setCurrentAddress({ label: currentAddress?.fullAddress, value: currentAddress?.id });
    }
  }, [organization.addressId, organization.addressList, editMode]);

  // Получаем список адресов
  useEffect(() => {
    const addressList = organization.addressList?.map((item: { fullAddress: string; id: number }) => {
      return { label: item.fullAddress, value: item.id };
    });

    setListAddress(addressList);
  }, [organization.addressList]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Места предоставления услуг
            <span className="color-gray-dark">
              {' \u00A0'}
              {placeServices?.length}
            </span>
          </>
        )}
        headingControl={() =>
          !organization.isArchive &&
          hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) ? (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
                setPlaceService(null);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconPlus />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          ) : null
        }
      >
        {loading ? (
          <div className="flex justify-center flex-column">
            <Push orientation="vertical" size={20} />
            <Loader roller small />
            <Push orientation="vertical" size={20} />
          </div>
        ) : tableData?.length ? (
          <Table
            data={tableData || []}
            hideSort
            overflow
            pageSize={Number.MAX_SAFE_INTEGER}
            key={rerenderTableKey}
            columns={[
              {
                dataIndex: 'count',
                title: '№',
                render: (item: any) => item.count,
                width: '50px',
              },
              {
                dataIndex: 'name',
                title: 'Наименование',
                render: (item: any) => <div className="word-break-all">{item.name}</div>,
                width: '25%',
              },
              {
                dataIndex: 'address',
                title: 'Адрес',
                render: (item: any) => (item.isArchive ? 'Адрес является архивным.' : item.fullAddress),
                width: '25%',
              },
              {
                dataIndex: 'station',
                title: 'Станция метро',
                render: (item: any) =>
                  item.metroStations?.length
                    ? item.metroStations?.reduce(
                        (acc: string, value: string) => `${acc ? `${acc}, ` : ''} ${value}`,
                        ''
                      )
                    : 'Не указана',
                width: '25%',
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) => (
                  <div className="disciplines-table-comment right">
                    <div className="disciplines-table-comment__controls">
                      {!organization.isArchive &&
                        !item.isDefaultPlaceService &&
                        hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
                          <DropDown
                            itemId={item.id}
                            component={() => (
                              <span className="drop-down-btn-round">
                                <IconDots />
                              </span>
                            )}
                          >
                            <div className="drop-down-panel">
                              <div className="drop-down-panel__list">
                                {hasAccessObjectAny(
                                  userProfile,
                                  [accessObject.EducationalOrganization],
                                  accessAction.Edit
                                ) && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setEditMode(true);
                                      setSityObject({ label: item.name, value: item.sportObjectId });
                                      getPlaceServiceResult(item.id);
                                    }}
                                    className="drop-down-panel__list-item"
                                  >
                                    <IconEdit />
                                    Редактировать
                                  </button>
                                )}

                                {hasAccessObjectAny(
                                  userProfile,
                                  [accessObject.EducationalOrganization],
                                  accessAction.Edit
                                )}
                                <button
                                  type="button"
                                  onClick={() => setRemovedId(item.id)}
                                  className="drop-down-panel__list-item"
                                >
                                  <IconRemove />
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </DropDown>
                        )}
                    </div>
                  </div>
                ),
                width: '25%',
              },
            ]}
          />
        ) : (
          <div className="table-no-data">Нет записей в таблице</div>
        )}
      </Panel>

      <Modal
        show={editMode}
        onClose={() => {
          setEditMode(false);
          setPlaceService(null);
          setSwitchForm(false);
        }}
      >
        <Formik
          key={rerenderTableKey}
          onSubmit={submitForm}
          enableReinitialize
          initialValues={itemPlaceFormData}
          validationSchema={getValidationSchema(switchForm)}
          initialErrors={initialErrors}
        >
          {(formikProps: FormikProps<FormData>) => {
            const { handleSubmit, isSubmitting, isValid } = formikProps;

            return (
              <form onSubmit={handleSubmit}>
                <ModalPanel
                  overflow
                  controls={() => (
                    <>
                      <Button
                        label="Отмена"
                        onClick={() => {
                          setEditMode(false);
                          setSwitchForm(false);
                        }}
                        border
                        size="small"
                        primary
                      />
                      <Push orientation="horizontal" size={12} />
                      <Button submit primary size="small" load={isSubmitting} disabled={!isValid} label="Сохранить" />
                    </>
                  )}
                  onClose={() => {
                    setEditMode(false);
                    setSwitchForm(false);
                  }}
                  size="medium"
                  modalTitle="Место предоставления услуг"
                  renderComponent={() => (
                    <div style={{ minHeight: '282px' }}>
                      <Switcher size="small">
                        <button
                          type="button"
                          onClick={() => {
                            setSwitchForm(false);
                            setRerenderTableKey(Math.random());
                          }}
                          className={!switchForm ? 'active' : ''}
                        >
                          Городской объект
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setSwitchForm(true);
                            setRerenderTableKey(Math.random());
                          }}
                          className={switchForm ? 'active' : ''}
                        >
                          Адреса организации
                        </button>
                      </Switcher>
                      <Push size={16} />
                      {switchForm ? (
                        <>
                          <FormikFormGroup name="name" label="Наименование" required>
                            <FormikInput size="small" name="name" placeholder="Введите наименование площадки..." />
                          </FormikFormGroup>
                          <Push size={16} />
                          <FormikFormGroup name="addressId" label="Адрес места предоставления услуг" required>
                            <FormikSelect
                              isSearchable
                              size="small"
                              name="addressId"
                              options={listAddress?.length ? listAddress : []}
                              placeholder="Выберите адрес места предоставления услуг из адресов организации..."
                            />
                          </FormikFormGroup>
                        </>
                      ) : (
                        <FormikFormGroup name="object" label="Городской объект" required>
                          <FormikSelect
                            size="small"
                            isSearchable
                            options={[]}
                            name="sportObjectId"
                            selectedValue={setSityObject}
                            defaultValue={placeService?.sportObjectId ? sityObject : null}
                            loadOptions={async (query) => await lookupApi.getSportObject(query)}
                            placeholder="Начните вводить наименование городского объекта..."
                          />
                        </FormikFormGroup>
                      )}
                    </div>
                  )}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>

      <RemoveModal
        buttonReverse
        title="Удаление места предоставления услуги"
        description="Вы уверены, что хотите удалить выбранную запись?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await deletePlaceService(removedId);
          getPlaceService();
          setRemovedId(0);
          setRerenderTableKey(Math.random());
        }}
      />
    </>
  );
};

export default OrganizationFormService;

const getValidationSchema = (value: boolean) => {
  if (!value) {
    return objectYup().shape({
      sportObjectId: number().required('Укажите городской объект').nullable(),
    });
  } else {
    return objectYup().shape({
      name: stringYup().required('Введите наименование').nullable().max(1000, maxLengthTextError(1000)),
      addressId: number().required('Выберите адрес предоставления услуг').nullable(),
    });
  }
};
