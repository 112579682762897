import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup, IconArrowDown, IconPlus } from '@mosru/esz_uikit';
import { routes } from '../../../config/constants';
import PageHeading from '../../../components/header/page-heading';
import { useGetFromRoute } from '../../../hooks/get-from-route';
import LearnerDetails from './details';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import Favorite from '../../../components/favorite';
import DropDown from '../../../components/drop-down';
import { getRequestEducationLinks } from '../../../lib/utils/education';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { hasAccessObjectParent, hasGeneralAccess } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import learnerApi from '../../../lib/api/learner';
import { showSuccessNotification } from '../../../lib/utils/learners';
import { accessPeople } from './access';
import LearnerHistory from './change-history';

const LearnerInfo = () => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const fromRoute = useGetFromRoute();

  const [activeTab, setActiveTab] = useState('Основные сведения');

  const [learnerName, setLearnerName] = useState('');

  const [requestOptions, setRequestOptions] = useState<{ link: string; label: string; value: number }[]>([]);

  const [extendEntityGuid, setExtendEntityGuid] = useState('');
  const [deleteLearner, setDeleteLearners] = useState(false);

  const [peopleId, setPeopleId] = useState(0);

  const [loading, setLoading] = useState(true);

  const [reloadRequest, setReloadRequest] = useState(false);

  const [peopleIsArchive, setPeopleIsArchive] = useState(false);

  useEffect(() => {
    setRequestOptions(getRequestEducationLinks(userProfile.objectAccessActionList, [], peopleId));
    const fetchData = async () => {
      const educationTypes = await dictionariesApi.getEducationTypes();
      setRequestOptions(getRequestEducationLinks(userProfile.objectAccessActionList, educationTypes, peopleId));
    };
    fetchData();
  }, [peopleId, userProfile.objectAccessActionList]);

  const learnerRestore = async (id: number) => {
    try {
      await learnerApi.patchLearner(id);

      showSuccessNotification();

      setReloadRequest(true);
      setPeopleIsArchive(false);
    } catch (e) {}
  };

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: (
          <LearnerDetails
            isAdminEdit={isAdminEdit}
            setPeopleId={setPeopleId}
            deleteLearner={deleteLearner}
            reloadRequest={reloadRequest}
            setHeadingLoading={setLoading}
            setLearnerName={setLearnerName}
            setReloadRequest={setReloadRequest}
            setDeleteLearners={setDeleteLearners}
            setPeopleIsArchive={setPeopleIsArchive}
            setExtendEntityGuid={setExtendEntityGuid}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: <LearnerHistory peopleId={peopleId} extendEntityGuid={extendEntityGuid} />,
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [isAdminEdit, deleteLearner, reloadRequest, peopleId, extendEntityGuid]
  );

  const redirect = (value: string) => {
    return window.open(value, '_blank');
  };

  return (
    <>
      {!loading && (
        <PageHeading
          title={learnerName}
          buttonBack
          routeBack={fromRoute || routes.learners}
          sections={[
            { title: 'Главная', link: routes.main },
            { title: 'Обучающиеся', link: routes.learners },
            { title: 'Сведения об обучающемся' },
          ]}
          tabs={tabs}
          activeTab={activeTab}
          expansion={
            <Favorite
              entityId={peopleId}
              typeEntity="обучающегося"
              currentEntityType={learnerName}
              entityTypeId={EntityTypeEnum.Pupil}
            />
          }
          controlsTopRight={
            <ButtonGroup>
              {hasAccessObjectParent(userProfile, accessObject.Requests, accessAction.Create) && (
                <DropDown
                  component={() => (
                    <Button
                      label="Новое заявление"
                      iconRight={() => <IconArrowDown />}
                      iconLeft={() => <IconPlus />}
                      size="small"
                    />
                  )}
                >
                  <div className="drop-down-panel">
                    <div className="drop-down-panel__list">
                      {requestOptions.map((item) => {
                        return (
                          <span key={Math.random()}>
                            <button
                              type="button"
                              onClick={() => redirect(item.link)}
                              className="drop-down-panel__list-item"
                            >
                              {item.label}
                            </button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </DropDown>
              )}
              {((accessPeople(userProfile, accessAction.Archive) && !peopleIsArchive) ||
                (isAdminEdit && !peopleIsArchive)) && (
                <Button
                  onClick={() => setDeleteLearners(true)}
                  label="В архив"
                  iconLeft={() => <IconArchive />}
                  size="small"
                />
              )}

              {((accessPeople(userProfile, accessAction.Archive) && peopleIsArchive) ||
                (isAdminEdit && peopleIsArchive)) && (
                <Button
                  size="small"
                  label="Восстановить"
                  onClick={() => learnerRestore(peopleId)}
                  iconLeft={() => <IconArchive />}
                />
              )}

              <Button handleClick={() => window.print()} label="Печать" iconLeft={() => <IconPrinter />} size="small" />
            </ButtonGroup>
          }
        />
      )}
      {tabs.find((item) => item.label === activeTab)?.component}
    </>
  );
};

export default LearnerInfo;
