import React, { useContext, useState } from 'react';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { accessVedomst } from '../../../../mock-data/access-enum';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { TemplateService } from '../../../../types/service';
import { ServiceDuration } from '../../components/dkgm-dsit-template/panels/fields/service-duration';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { templateApi } from '../../../../lib/api/template';
import FormikToggle from '../../../../components/formik/formik-toggle';
import FormikToggleGroup from '../../../../components/formik/formik-toggle-group';
import { GroupMembersField } from '../../components/dkgm-dsit-template/panels/fields/group-members';
import { durationOfTrainingDsitDkgm, isFloorPresent } from '../../../../lib/utils/validation';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ServiceContext } from '../../index';
import { HoursPerWeek } from '../../components/fields/hours-per-week';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  data?: TemplateService.Info;
};

const TYPE_OF_ATTENDANCE_ID = 1;
const SERVICE_FORM_ID = 1;

const Info = ({ setEditModeParent, data }: Props) => {
  const { serviceData, accessPanelEdit } = useContext(ServiceContext);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const isVisibleHoursPerWeek = serviceData.info.vedomstvoId === accessVedomst.Dkgm;

  const validationSchema = infoValidationSchema(isVisibleHoursPerWeek);

  const initialErrors = useInitialErrors(data, validationSchema);

  const submitForm = async (values: TemplateService.Info) => {
    setLoadBtn(true);

    try {
      if (values?.templateId) {
        const data = {
          ...values,
          ...{ typeOfAttendanceId: TYPE_OF_ATTENDANCE_ID },
          ...{ serviceFormId: SERVICE_FORM_ID },
        };

        await templateApi.updateTemplate(values.templateId, data);

        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        setLoadBtn(false);
      }
    } finally {
      setLoadBtn(false);
    }
  };

  const toggle = (name: string): JSX.Element => {
    return (
      <div className="table-data__control">
        <FormikToggleGroup name={name} label="">
          <FormikToggle disabled={!editMode} name={name} size="xsmall" />
        </FormikToggleGroup>
      </div>
    );
  };

  const canEdit: boolean =
    !!serviceData.id &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    accessPanelEdit;

  return (
    <Formik
      onSubmit={(values) => {
        submitForm(values);
      }}
      enableReinitialize
      initialValues={data as TemplateService.Info}
      validationSchema={validationSchema}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<TemplateService.Info>) => {
        const { values, isValid, resetForm, handleSubmit } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Сведения об образовательной услуге'}
              headingControl={() => {
                return (
                  <div className="flex items-center">
                    {canEdit && !editMode && (
                      <button
                        type="button"
                        onClick={() => {
                          if (setEditModeParent) {
                            setEditModeParent('info');
                          }
                          setEditMode(true);
                        }}
                        className="icon-group"
                      >
                        <span className="icon-group__icon">
                          <IconEdit />
                        </span>
                        <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                      </button>
                    )}
                    <Push size={16} orientation="horizontal" />
                    <div className="color-gray-dark text-right">
                      Разрешить
                      <br />
                      редактир.
                    </div>
                  </div>
                );
              }}
            >
              <div className="container">
                <ClassificatorNameField
                  editMode={editMode}
                  name="classificator"
                  label="Реестр базовых направлений"
                  modalTitle="Реестр базовых направлений"
                  toggleName="canChangeClassificatorEKU"
                />
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Наименование услуги {editMode && <span className="table-data__required" />}
                  </div>
                  <div className="table-data__body">
                    {editMode ? (
                      <FormikFormGroup required label="" name="name">
                        <FormikInput name="name" size="small" placeholder="Введите..." />
                      </FormikFormGroup>
                    ) : (
                      values.name || '—'
                    )}
                  </div>

                  {toggle('canChangeName')}
                </div>
                <ServiceDuration isTemplate editMode={editMode} />
                <LessonLevelField
                  isToggle
                  required
                  editMode={editMode}
                  name="programmLevel"
                  label="Уровень программы"
                  value={values.programmLevelName}
                  otherElement={toggle('canChangeProgrammLevel')}
                  disabledPlaceholder="Сначала выберите базовое направление"
                />
                {isVisibleHoursPerWeek && (
                  <HoursPerWeek
                    required
                    width={80}
                    editMode={editMode}
                    name="hoursPerWeek"
                    value={values.hoursPerWeek}
                    otherElement={toggle('canChangeHoursPerWeek')}
                  />
                )}
                <GroupMembersField isTemplate editMode={editMode} />
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Описание услуги {editMode && <span className="table-data__required" />}
                  </div>
                  <div className="table-data__body">
                    {editMode ? (
                      <FormikFormGroup name="programmService" label="" horizontalLabelStart>
                        <FormikTextarea name="programmService" rows={2} placeholder="Введите..." />
                      </FormikFormGroup>
                    ) : (
                      values.programmService || '—'
                    )}
                  </div>
                  {toggle('canChangeProgrammService')}
                </div>
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Порядок предоставления</div>
                  <div className="table-data__body">
                    {editMode ? (
                      <FormikFormGroup name="ruleService" label="" horizontalLabelStart>
                        <FormikTextarea name="ruleService" rows={2} placeholder="Введите..." />
                      </FormikFormGroup>
                    ) : (
                      values.ruleService || '—'
                    )}
                  </div>
                  {toggle('canChangeRuleService')}
                </div>
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        handleClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button submit primary load={loadBtn} label="Сохранить" disabled={!isValid} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Info;

export const infoValidationSchema = (isHoursPerWeek?: boolean) =>
  objectYup().shape({
    name: stringYup().nullable().required('Введите наименование').nullable(),
    classificatorEKUId: stringYup().nullable().required('Выберите реестр базовых направлений'),
    durationOfTraining: durationOfTrainingDsitDkgm,
    programmLevelId: stringYup().nullable().required('Выберите уровень'),
    programmService: stringYup().nullable().required('Введите описание услуги'),
    hoursPerWeek: isHoursPerWeek
      ? numberYup()
          .nullable()
          .required('Введите количество часов в неделю')
          .min(1, 'минимальное количество часов в неделю 1')
      : numberYup().optional().nullable(),
    altStart: stringYup()
      .nullable()
      .test('altStart', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isMalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст от' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
        }
        return true;
      }),
    altEnd: stringYup()
      .nullable()
      .test('altEnd', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isMalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст до' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
          if (parent1?.value?.altStart && parent1?.value?.altStart > parseInt(value)) {
            return ctx.createError({ message: 'Максимальный возраст должен быть больше, чем минимальный' });
          }
        }
        return true;
      }),
    allStart: stringYup()
      .nullable()
      .test('allStart', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст от' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
        }
        return true;
      }),
    allEnd: stringYup()
      .nullable()
      .test('allEnd', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст до' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
          if (parent1?.value?.allStart && parent1?.value?.allStart > parseInt(value)) {
            return ctx.createError({ message: 'Максимальный возраст должен быть больше, чем минимальный' });
          }
        }
        return true;
      }),
    altFemaleStart: stringYup()
      .nullable()
      .test('altFemaleStart', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isFemalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст от' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
        }
        return true;
      }),
    altFemaleEnd: stringYup()
      .nullable()
      .test('altFemaleEnd', '', (value: string | null | undefined, ctx: any) => {
        const [parent1] = ctx.from;
        if (parent1?.value?.isFemalePresent && !parent1?.value?.genderAll) {
          if (!value || value === '0') {
            return ctx.createError({ message: 'Введите возраст до' });
          }
          if (parseInt(value) > 120) {
            return ctx.createError({ message: 'До 120' });
          }
          if (parent1?.value?.altFemaleStart && parent1?.value?.altFemaleStart > parseInt(value)) {
            return ctx.createError({ message: 'Максимальный возраст должен быть больше, чем минимальный' });
          }
        }
        return true;
      }),

    isFemalePresent: isFloorPresent('isFemalePresent'),
    isMalePresent: isFloorPresent('isMalePresent'),
  });
