import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import DayCareServiceClassDetails from './details';
import Favorite from '../../../components/favorite';
import { generateLink } from '../../../lib/utils';
import { serviceTypeEnum } from '../../../mock-data/print';
import ChangeHistory from '../change-history';
import { ServiceClassContext } from '../service-class';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import ConfirmDeletionModal from '../components/modals/confirm-deletion';
import AddArchiveModal from '../components/modals/add-archive';

const DayCareServiceClass: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const { id } = useParams<Record<string, string | undefined>>();
  const { serviceClassData, accessAddArchive, isArchiveGroup, routeBack } = useContext(ServiceClassContext);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <DayCareServiceClassDetails />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: <ChangeHistory />,
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    []
  );

  return (
    <>
      <PageHeading
        buttonBack
        routeBack={routeBack}
        title={`Группа продленного дня ${isArchiveGroup ? '(архивная)' : ''}`}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Группы обучения', link: routes.serviceClasses },
          { title: 'Группа по уходу и присмотру за детьми школьного возраста' },
        ]}
        controlsTopRight={
          <ButtonGroup>
            {serviceClassData.canBeDeleted ? (
              <Button
                label="Удалить"
                onClick={() => setShowModalDelete(true)}
                iconLeft={() => <IconArchive />}
                size="small"
                disabled={!accessAddArchive}
              />
            ) : (
              <Button
                label="В архив"
                disabled={isArchiveGroup || !accessAddArchive}
                onClick={() => setShowModalArchive(true)}
                iconLeft={() => <IconArchive />}
                size="small"
              />
            )}

            <Button
              label="Печать"
              handleClick={() =>
                window.open(
                  generateLink(routes.printServiceClass, {
                    type: serviceTypeEnum.dayCareCentersEducation,
                    id: id as string,
                  }),
                  '_blank'
                )
              }
              iconLeft={() => <IconPrinter />}
              size="small"
            />
          </ButtonGroup>
        }
        tabs={tabs}
        activeTab={activeTab}
        expansion={
          <Favorite
            entityId={serviceClassData.id as number}
            typeEntity="группу обучения"
            currentEntityType={serviceClassData.name}
            entityTypeId={EntityTypeEnum.ServiceClass}
          />
        }
      />
      {tabs.find((item) => item.label === activeTab)?.component}

      <ConfirmDeletionModal
        serviceClassCode={serviceClassData?.code as string}
        onCloseHandler={() => setShowModalDelete(false)}
        show={showModalDelete}
      />

      <AddArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
    </>
  );
};

export default DayCareServiceClass;
