import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, cloneDeep } from 'lodash';
import { Button, Input, IconSearch, Modal, Tree, TreeNodeType, Loader, Push, Key } from '@mosru/esz_uikit';
import { classificatorApi } from '../../../../../lib/api/classificator';
import { ReactComponent as IconClose } from '../../../../../assets/images/icons/close.svg';
import { searchTreeElements } from '../../../../../components/tree-modal/helpers';
import { ClassificatorChildData, ClassificatorTreeData } from '../../../../../types/classificator';
import { getClassificatorList } from '../utils';

type CheckableTreeModalProps = {
  show: boolean;
  organizationId: number;
  educationTypeId: number;
  vedomstvoId?: number;
  yearOfTrainingId?: number;
  checkableKeys: Key[];
  onSave: (value: ClassificatorChildData[]) => void;
  onClose: (value: boolean) => void;
};

const CheckableTreeModal = ({
  show,
  organizationId,
  educationTypeId,
  yearOfTrainingId,
  vedomstvoId,
  checkableKeys,
  onSave,
  onClose,
}: CheckableTreeModalProps) => {
  const [initialData, setInitialData] = useState<TreeNodeType[]>([]);
  const [dataTree, setDataTree] = useState<ClassificatorTreeData[]>([]);
  const [loading, setLoading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);

  const convertToTreeNodeType = useCallback(
    (data: ClassificatorTreeData[]): TreeNodeType[] =>
      data.map(({ data, child }) => ({
        key: data.id,
        title: data.name,
        isLeaf: !child.length,
        children: convertToTreeNodeType(child),
        disabled: !!child.length,
        disableCheckbox: checkableKeys.includes(data.id),
        checkable: !child.length,
      })),
    [checkableKeys]
  );

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await classificatorApi.getClassificatorTree({
        educationTypeId,
        organizationId,
        yearOfTrainingId,
        vedomstvoId,
      });

      setDataTree(data);
      setInitialData(convertToTreeNodeType(data));
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  }, [educationTypeId, organizationId, yearOfTrainingId, vedomstvoId, convertToTreeNodeType]);

  useEffect(() => {
    if (show) {
      getData();
    } else {
      setCheckedKeys([]);
      setInitialData([]);
      setDataTree([]);
    }
  }, [show, getData]);

  const handleSearchChanged = useCallback(
    (value: string) => {
      const data = convertToTreeNodeType(dataTree);
      const copyArray = cloneDeep(data);
      const result = value ? searchTreeElements(value.toLowerCase().trim(), copyArray) : data;

      setInitialData(result);
      setLoading(false);
    },
    [dataTree, convertToTreeNodeType]
  );

  const debounceFn = useMemo(() => debounce(handleSearchChanged, DEBOUNCE_DELAY), [handleSearchChanged]);

  const handleDebouncedSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    // @ts-ignore
    debounceFn(event.target.value);
  };

  const handleCheckedKey = (selectedKeys: Key[]) => {
    setCheckedKeys(selectedKeys.map((key) => Number(key)));
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleSave = () => {
    const list = getClassificatorList(dataTree);
    onSave(list.filter(({ id }) => checkedKeys.includes(id)));
    handleClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="modal-right">
        <div className="learners-modal-right">
          <div className="learners-modal-right__head">
            <div className="flex">
              <div className="font-weight-bold font-size-base">Реестр профессий</div>
              <button type="button" onClick={handleClose} className="learners-modal-right__head-close">
                <IconClose />
              </button>
            </div>
            <Push size={4} />
            <div className="color-gray-dark font-size-small">
              Направленность {'>'} профиль {'>'} вид деятельности
            </div>
          </div>

          <div className="learners-modal-right__body">
            <div className="learners-modal-right__body-inner">
              <Input
                size="small"
                placeholder="Поиск..."
                onChange={handleDebouncedSearchChange}
                iconLeft={() => <IconSearch />}
              />

              <Push size={16} />

              {loading ? (
                <span className="loader">
                  <Loader small roller />
                </span>
              ) : (
                <Tree expandAll data={initialData} checkedKeys={checkedKeys} handleChange={handleCheckedKey} />
              )}
            </div>
          </div>

          <div className="learners-modal-right__controls">
            <Button border primary size="small" label="Отмена" onClick={handleClose} />
            <Push size={12} orientation="horizontal" />
            <Button primary size="small" label="Сохранить" disabled={!checkedKeys.length} onClick={handleSave} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckableTreeModal;

const DEBOUNCE_DELAY = 500;
