import { PropsDocumentType } from '../../../../../types/learners';

export const generateDocuments = (data: PropsDocumentType[]) => {
  return data.reduce((acc: { label: string; value: number }[], item: PropsDocumentType) => {
    acc.push({ label: item.name, value: item.id });

    return acc;
  }, []);
};

const date = new Date();

export const initialStateFormMoscowDocument = {
  docNumber: '',
  documentTypeId: 7,
  documentTypeName: '',
  id: null,
  isArchive: false,
  issueDate: date.toUTCString(),
  moduleName: '',
  organizationName: '',
  profName: '',
  programName: '',
  programmLevel: '',
  unionCatalogServicesId: null,
  programmModuleId: null,
  state: false,
  organizationId: null,
};
