import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { SearchInitialFormData } from '../../../../../types/service';
import { usePrevious } from '../../../../../hooks/use-previous';

const FieldAddress: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();
  const [addressIdKey, setAddressIdKey] = useState<number>(0);

  const prevVedomstvoId = usePrevious(values.vedomstvoId);
  const prevOrganizationId = usePrevious(values.organizationId);
  const prevEducationTypeId = usePrevious(values.educationTypeId);
  const prevAddressId = usePrevious(values.servicePlaceId);

  useEffect(() => {
    // При изменении "Департамента", "Организации", "Типа образования" обнуляем значение "Адрес предоставления услуги"
    if (
      (values.vedomstvoId !== prevVedomstvoId ||
        values.organizationId !== prevOrganizationId ||
        values.educationTypeId !== prevEducationTypeId) &&
      prevAddressId
    ) {
      setFieldValue('servicePlaceId', null);
      setFieldValue('servicePlaceQuery', null);
      setAddressIdKey(Math.random());
    }
  }, [
    values.vedomstvoId,
    values.organizationId,
    values.educationTypeId,
    prevVedomstvoId,
    prevOrganizationId,
    prevEducationTypeId,
    prevAddressId,
    setFieldValue,
  ]);

  return (
    <FormikFormGroup name="servicePlaceId" label="Адрес предоставления услуги">
      <FormikSelect
        key={addressIdKey}
        name="servicePlaceId"
        size="small"
        disabled={!values.organizationId}
        defaultValue={
          values?.servicePlaceId
            ? { label: values?.servicePlaceQuery ?? '', value: values?.servicePlaceId ?? null }
            : null
        }
        options={[]}
        isSearchable
        loadOptions={async (query) => {
          return await lookupApi.getPlaceService(
            query,
            values.vedomstvoId as number,
            values.organizationId as number,
            values.educationTypeId as number
          );
        }}
        placeholder={values.organizationId ? 'Начните вводить...' : 'Сначала выберите организацию'}
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('servicePlaceQuery', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};

export default FieldAddress;
