import React, { ReactChild, ReactChildren, ReactNode } from 'react';
import classNames from 'classnames';

type PrivateFieldProps<T> = FieldProps<T> & {
  size?: 1 | 2 | 3;
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
};

export type FieldProps<T> = {
  label?: string | ReactNode;
  name: string;
  editMode?: boolean;
  required?: boolean;
  value?: T | null;
  defaultValue?: T;
  disabled?: boolean;
  isNotTableMain?: boolean;
  maxLength?: number | undefined;
  showRequiredIcon?: boolean;
  placeholder?: string;
  iconForValue?: ReactChild | null;
  parent?: string;
  isToggle?: boolean;
  otherElement?: ReactNode;
  isAlignStart?: boolean;
};

const Field = ({
  label,
  editMode,
  required,
  size,
  value,
  defaultValue,
  children,
  disabled,
  isNotTableMain,
  showRequiredIcon,
  iconForValue,
  otherElement,
  isAlignStart,
}: Omit<PrivateFieldProps<any>, 'name'>) => {
  return (
    <div
      data-test={label}
      className={classNames(
        'table-data__group',
        !isNotTableMain && 'table-data__item',
        isAlignStart && 'table-data__group--start'
      )}
    >
      {label && (
        <div className={classNames('table-data__label', !isNotTableMain && 'table-data__label--main')}>
          <div>
            {label} {(editMode && required) || showRequiredIcon ? <span className="table-data__required" /> : null}
          </div>
        </div>
      )}
      <div className="table-data__body">
        {editMode || disabled ? (
          size ? (
            <div className={`table-data-grid-${size}`}>{children}</div>
          ) : (
            children
          )
        ) : (
          <span data-test="field-value" className="flex items-center">
            {iconForValue || ''}
            {value || defaultValue}
          </span>
        )}
      </div>
      {otherElement}
    </div>
  );
};

export default Field;
