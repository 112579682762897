import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import serviceClassApi from '../../../../lib/api/service-class';
import { ServiceClassContext } from '../../service-class';
import { ServiceClassTableContext } from '../../utils';

const initialValues = {
  comment: '',
};

type Props = {
  open: boolean;
  close: () => void;
};

type FormData = {
  comment: string;
  educationTypeId: number;
  serviceClassName: string;
  megaRelationIds: number[];
  serviceClassId: number;
};

const RecoverySimple: React.FC<Props> = ({ open, close }) => {
  const { setSelected, setOpenDetails, selected, fetchTable, serviceClassData } = useContext(ServiceClassTableContext);

  const { updateServiceClass } = useContext(ServiceClassContext);

  const [loading, setLoading] = useState(false);

  const submitForm = async (values: FormData) => {
    setLoading(true);

    try {
      if (serviceClassData.id) {
        await serviceClassApi.restorationServiceClass(serviceClassData.id, {
          ...values,
          educationTypeId: serviceClassData.educationTypeId,
          serviceClassName: serviceClassData.name,
          megaRelationIds: selected,
          serviceClassId: serviceClassData.id,
        });

        fetchTable();
        setLoading(false);
        setOpenDetails(false);
        setSelected([]);
        close();
        updateServiceClass();
      }
    } catch {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onClose={close}>
      <Formik enableReinitialize onSubmit={submitForm} initialValues={initialValues as FormData}>
        {(formikProps: FormikProps<FormData>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                overflow
                onClose={close}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={close} />

                    <Push orientation="horizontal" size={12} />

                    <Button
                      label="Восстановить"
                      disabled={!isValid || isSubmitting}
                      primary
                      load={loading}
                      size="small"
                      submit
                    />
                  </>
                )}
                modalTitle="Восстановление обучающегося"
                renderComponent={() => (
                  <FormikFormGroup name="comment" label="Причина восстановления">
                    <FormikTextarea name="comment" placeholder="Введите..." rows={2} />
                  </FormikFormGroup>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default RecoverySimple;
