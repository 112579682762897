import React from 'react';
import { Infobox } from '@mosru/esz_uikit';

type Props = {
  isAdminEdit: boolean;
};

export const Status: React.FC<Props> = ({ isAdminEdit }) => {
  return !isAdminEdit ? (
    <Infobox
      fullWidth
      color="primary"
      text="В случае обнаружения ошибки в персональных данных обучающегося необходимо обратиться в СТП для внесения изменений"
    />
  ) : null;
};
