import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import {
  ServiceClassData,
  ServiceClassListData,
  ServiceClassListScheduleData,
} from '../../../../../../types/service-class';
import { SubmitValues } from '../index';
import { EducationTypeEnum } from '../../../../../../types/education-type';
import { editNameGroup } from './utils';

type Props = {
  open: boolean;
  serviceClassData: ServiceClassData;
  serviceClassList: ServiceClassListData<ServiceClassListScheduleData>[] | undefined;
};

const FiledService: React.FC<Props> = ({ open, serviceClassData, serviceClassList }) => {
  const { setFieldValue } = useFormikContext<SubmitValues>();

  const [list, setList] = useState<SelectOptionType[]>([]);

  const accessNewGroup =
    EducationTypeEnum.ProfessionalEducation === serviceClassData.educationTypeId ||
    EducationTypeEnum.DayCareCentersEducation === serviceClassData.educationTypeId;

  useEffect(() => {
    if (serviceClassData.serviceId && serviceClassList?.length) {
      const data = serviceClassList.map((item) => {
        return {
          label: editNameGroup(item.code, item.name, item.included, item.capacity),
          value: item.id,
          additionalPropertiesJson: item.name,
        };
      });

      const element = data.find((item) => item.value === serviceClassData.id);

      const newGroup = { label: 'Создать новую группу', value: 0 };

      const nameGroup = {
        label: editNameGroup(
          serviceClassData.code ?? '',
          serviceClassData.name,
          serviceClassData.included ?? 0,
          serviceClassData.capacity
        ),
        value: serviceClassData.id ?? '',
        additionalPropertiesJson: serviceClassData.name,
      };

      if (element) {
        if (accessNewGroup) {
          setList(data);
        } else {
          setList([newGroup, ...data]);
        }
      } else if (accessNewGroup) {
        setList([nameGroup, ...data]);
      } else {
        setList([newGroup, nameGroup, ...data]);
      }
    }
  }, [
    accessNewGroup,
    open,
    serviceClassData.capacity,
    serviceClassData.code,
    serviceClassData.id,
    serviceClassData.included,
    serviceClassData.name,
    serviceClassData.serviceId,
    serviceClassList,
  ]);

  return (
    <FormikFormGroup name="serviceClassId" label="Группа обучения" required>
      <FormikSelect
        selectedValue={(option: SelectOptionType) => {
          setFieldValue('serviceClassName', option.additionalPropertiesJson);
        }}
        size="small"
        isSearchable
        options={list}
        name="serviceClassId"
        placeholder="Выберите..."
      />
    </FormikFormGroup>
  );
};

export default FiledService;
