import React, { useContext, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup, array as arrayYup, number } from 'yup';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Button, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ServiceDataInfo } from '../../../../types/service';
import ParallelField, { ParallelOptionsByResponse } from '../../components/fields/parallel';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import { ServiceDuration } from '../../components/fields/service-duration';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { GroupMembersField } from '../../components/fields/group-members';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { Dictionary } from '../../components/fields/dictionary';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { ServiceContext } from '../..';
import { convertParallelInResponse, convertParallelInSelect, setGenderStartEndToBack } from '../../utils';
import { altEndValidation, altStartValidation, durationOfTraining } from '../../../../lib/utils/validation';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';

type Props = {
  setEditModeParent?: (value: string | null) => void;
};

const Info: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, adminEdit } = useContext(ServiceContext);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const [loadBtn, setLoadBtn] = useState<boolean>(false);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const initData = useMemo(() => {
    if (serviceData.info) {
      return {
        ...serviceData.info,
        parallelList: convertParallelInSelect(serviceData.info.parallelList as ParallelOptionsByResponse[]),
      };
    }
    return {} as ServiceDataInfo;
  }, [serviceData.info]);

  const initialErrors = useInitialErrors(initData, infoValidationSchema());

  const submitForm = async (values: ServiceDataInfo) => {
    setLoadBtn(true);
    if (values?.serviceId) {
      try {
        await serviceTemplateApi.updateService(values.serviceId, {
          ...setGenderStartEndToBack(values),
          parallelList: convertParallelInResponse(values.parallelList as SelectOptionType[]),
        });
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
        updateService();
      } finally {
        setLoadBtn(false);
      }
    }
  };

  const canEdit =
    !editMode &&
    setEditModeParent &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.Edit);

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initData}
      validationSchema={infoValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceDataInfo>) => {
        const { values, isValid, handleSubmit, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => <>Сведения об услуге</>}
              headingControl={() => {
                return (
                  canEdit && (
                    <button
                      type="button"
                      onClick={() => {
                        if (setEditModeParent) {
                          setEditModeParent('info');
                        }
                        setEditMode(true);
                      }}
                      className="icon-group"
                    >
                      <span className="icon-group__icon">
                        <IconEdit />
                      </span>
                      <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                    </button>
                  )
                );
              }}
            >
              <div className="container">
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Образовательная организация {editMode && <span className="table-data__required" />}
                  </div>
                  <div className="table-data__body">{values.organizationName || '—'}</div>
                </div>
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Наименование услуги {editMode && <span className="table-data__required" />}
                  </div>
                  <div className="table-data__body">
                    <div className="table-data-grid-3-1 items-center">
                      <div>
                        {editMode ? (
                          <FormikFormGroup required label="" name="name">
                            <FormikInput name="name" size="small" placeholder="Введите..." />
                          </FormikFormGroup>
                        ) : (
                          values.name || '—'
                        )}
                      </div>

                      <div>
                        <div className="table-data__group">
                          <div className="table-data__label">Код</div>
                          <div className="table-data__body">{values.code || '—'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ParallelField name="parallel" required editMode={editMode} />

                <ClassificatorNameField
                  editMode={editMode}
                  name="classificator"
                  modalTitle="Реестр базовых направлений"
                  disabled={values.hasMegaRelations}
                />

                <ServiceDuration editMode={editMode} />

                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Занятия</div>
                  <div className="table-data__body">
                    <div className={`${editMode ? 'table-data-grid-3-3-4' : 'table-data-grid-3'} items-center`}>
                      <div className="table-data__group">
                        <div className="table-data__label">Форма</div>
                        <div className="table-data__body">
                          <Dictionary
                            isNotTableMain
                            name="serviceForm"
                            editMode={editMode}
                            value={values.serviceFormName}
                            dictionaryFunc={dictionariesApi.getServiceForms}
                          />
                        </div>
                      </div>
                      <LessonLevelField
                        isNotTableMain
                        label="Уровень"
                        editMode={editMode}
                        name="programmLevel"
                        value={values.programmLevelName}
                        disabledPlaceholder="Сначала заполните поле Вид деятельности"
                        required
                        hasTooltip
                      />

                      <div className="table-data__group">
                        <div className="table-data__label">Вид посещения</div>
                        <div className="table-data__body">
                          <Dictionary
                            name="typeOfAttendance"
                            editMode={editMode}
                            isNotTableMain
                            dictionaryFunc={() => dictionariesApi.getVisitTypes(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Количество в неделю</div>
                  <div className="table-data__body">
                    <div className="flex items-start">
                      <div className="table-data__group" style={{ width: 160 }}>
                        <div className="table-data__label">
                          Занятий {editMode && <span className="table-data__required" />}
                        </div>
                        <div className="table-data__body">
                          {editMode ? (
                            <FormikFormGroup required label="" name="lessonCountPerWeek">
                              <FormikInput
                                name="lessonCountPerWeek"
                                size="small"
                                placeholder="0"
                                number
                                disabled={values.hasMegaRelations}
                              />
                            </FormikFormGroup>
                          ) : (
                            values.lessonCountPerWeek || '—'
                          )}
                        </div>
                      </div>
                      <Push size={12} orientation="horizontal" />
                      <div className="table-data__group" style={{ width: 160 }}>
                        <div className="table-data__label">Часов</div>
                        <div className="table-data__body">
                          {editMode ? (
                            <FormikFormGroup required label="" name="hoursPerWeek">
                              <FormikInput
                                name="hoursPerWeek"
                                size="small"
                                placeholder="0"
                                number
                                disabled={values.hasMegaRelations}
                              />
                            </FormikFormGroup>
                          ) : (
                            values.hoursPerWeek || '—'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <GroupMembersField editMode={editMode} disabled={values.hasMegaRelations && !adminEdit} />

                <SimpleTextArea
                  name="programmService"
                  required
                  editMode={editMode}
                  label="Описание услуги"
                  maxLength={1000}
                />

                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">Правила посещения</div>
                  <div className="table-data__body">
                    {editMode ? (
                      <FormikFormGroup name="ruleService" label="" horizontalLabelStart required>
                        <FormikTextarea name="ruleService" rows={2} placeholder="Введите..." maxLength={1000} />
                      </FormikFormGroup>
                    ) : (
                      values.ruleService || '—'
                    )}
                  </div>
                </div>
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button load={loadBtn} disabled={!isValid} primary label="Сохранить" submit />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Info;

export const infoValidationSchema = () =>
  objectYup().shape({
    name: stringYup().required('Введите наименование').nullable(),
    parallelList: arrayYup()
      .min(1, 'Выберите параллель обучения')
      .required('Должен состоять из списка параллелей')
      .nullable(),
    lessonCountPerWeek: number()
      .required('Необходимо указать количество занятий в неделю')
      .min(1, 'Количество занятий не может быть меньше 1')
      .max(99, 'Количество занятий не может быть больше 99')
      .nullable(),
    hoursPerWeek: number().max(99, 'Количество часов в неделю не может быть больше 99').nullable(),
    classificatorEKUId: stringYup().required('Выберите вид деятельности').nullable(),
    durationOfTraining: durationOfTraining(9),
    durationOfTrainingMonths: durationOfTraining(12),
    durationOfTrainingWeeks: durationOfTraining(54),
    durationOfTrainingDays: durationOfTraining(54),
    altStart: altStartValidation(),
    altEnd: altEndValidation(),
    programmService: stringYup().nullable().required('Введите описание услуги'),
    programmLevelId: stringYup().nullable().required('Выберите уровень'),
  });
