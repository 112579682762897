import { Link } from 'react-router-dom';
import { Button, Push } from '@mosru/esz_uikit';
import { routes } from '../../config/constants';
import { ReactComponent as IconPhone } from '../../assets/images/icons/phone.svg';
import { useGetAppealLinkQuery } from '../../store/home';

const Footer = () => {
  const { data: appealLink = '' } = useGetAppealLinkQuery();

  return (
    <footer className="footer">
      <div className="footer__body">
        <div className="footer__nav">
          <div className="footer__nav-item">
            <Link to={routes.favorite} className="footer__nav-link">
              Избранное
            </Link>
          </div>
          <div className="footer__nav-item">
            <Link to={routes.normativeDoc} className="footer__nav-link">
              Нормативные документы
            </Link>
          </div>
          <div className="footer__nav-item">
            <Link to={routes.userManual} className="footer__nav-link">
              Руководство пользователя
            </Link>
          </div>
          <div className="footer__nav-item">
            <Link to={routes.faq} className="footer__nav-link">
              Ответы на вопросы
            </Link>
          </div>
        </div>
        <div className="footer__help">
          В случае возникновения проблем и ошибок при работе с Системой, а также выявлении некорректных данных просим
          обращаться в службу технической поддержки
        </div>
      </div>
      <div className="footer__contacts">
        <a href="tel:+74955393838" className="footer-icon-group">
          <span className="footer-icon-group__icon">
            <IconPhone />
          </span>
          <span className="footer-icon-group__text">+7 (495) 539-38-38</span>
        </a>
        <Push />
        <Button
          onClick={async () => {
            window.location.href = appealLink;
          }}
          size="small"
          label="Написать нам"
          border
        />
      </div>
    </footer>
  );
};

export default Footer;
