import React, { useContext, useMemo, useState } from 'react';
import { Badge, Button, ButtonGroup, IconPlus, Push } from '@mosru/esz_uikit';
import Details from './details';
import ChangeHistory from '../../../components/change-history';
import Favorite from '../../../components/favorite';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { getRequestStatusColor } from '../../../lib/utils/dictionary';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { RequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { generateLink, getServiceEditLink } from '../../../lib/utils';
import { serviceTypeEnum } from '../../../mock-data/print';
import { RequestContext } from '../../../lib/utils/requests';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const RequestProf: React.FC<Props> = ({ requestData, updateRequest }) => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const { adminView, routeBack } = useContext(RequestContext);

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <Details data={requestData} updateRequest={updateRequest} />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name="сведения о заявлении"
            extendEntityGuid={requestData?.extendEntityGuid}
            entityTypes={[EntityTypeEnum.Request]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [requestData, updateRequest]
  );

  const checkShowCreateFromCurrent =
    (requestData.requestStatusId === RequestStatusEnum.New ||
      requestData.requestStatusId === RequestStatusEnum.UnderConsideration ||
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.DocumentAccepted ||
      requestData.requestStatusId === RequestStatusEnum.Archive) &&
    !adminView;

  return (
    <>
      <PageHeading
        details={
          <>
            <Push size={12} />
            <div className="flex items-center">
              Дата заявления: {formatDate(requestData.dateRequest)}
              <Push size={20} orientation="horizontal" />
              Источник: {requestData.requestSourceName}
            </div>
            <Push size={12} />
          </>
        }
        buttonBack
        routeBack={routeBack}
        expansion={
          <div className="flex items-center">
            <Favorite
              entityId={requestData.id}
              typeEntity="заявление"
              currentEntityType={`№${requestData.requestNumber || ''}`}
              entityTypeId={EntityTypeEnum.Request}
            />
            <Push size={20} orientation="horizontal" />
            <Badge text={requestData.requestStatusName} {...getRequestStatusColor(requestData.requestStatusId)} />
          </div>
        }
        title={`Заявление №${requestData.requestNumber || ''}`}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Заявления', link: routes.requests },
          { title: 'Сведения о заявлении' },
        ]}
        activeTab={activeTab}
        tabs={tabs}
        controlsTopRight={
          <ButtonGroup>
            <Button
              label="Вернуться к ОП"
              size="small"
              handleClick={() => {
                window.open(
                  getServiceEditLink(requestData.educationTypeId, requestData.trainingGroup.serviceId),
                  '_blank'
                );
              }}
            />
            {checkShowCreateFromCurrent ? (
              <Button
                label="Создать на основе текущего"
                iconLeft={() => <IconPlus />}
                size="small"
                onClick={() => window.open(generateLink(routes.createRequestBasedOn, { id: requestData.id }), '_blank')}
              />
            ) : null}
            <Button
              label="Печать"
              iconLeft={() => <IconPrinter />}
              size="small"
              handleClick={() =>
                window.open(
                  generateLink(routes.printRequest, { type: serviceTypeEnum.programEducational, id: requestData.id }),
                  '_blank'
                )
              }
            />
          </ButtonGroup>
        }
      />

      {tabs.find((item) => item.label === activeTab)?.component}
    </>
  );
};

export default RequestProf;
