import React, { ReactNode } from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  onCloseHandle: () => void;
  onRemoveHandler: () => void;
  title: string;
  description: string | ReactNode;
  buttonReverse?: boolean;
  deleteBtnTitle?: string;
};

const RemoveModal = ({
  show,
  onCloseHandle,
  onRemoveHandler,
  title,
  description,
  buttonReverse,
  deleteBtnTitle,
}: Props) => {
  const deleteTitle = deleteBtnTitle || 'Удалить';

  return (
    <Modal show={show} onClose={onCloseHandle}>
      <ModalPanel
        alert
        modalTitle={title}
        onClose={onCloseHandle}
        description={description}
        controls={() =>
          buttonReverse ? (
            <>
              <Button label="Отмена" border size="small" primary onClick={onCloseHandle} />
              <Push orientation="horizontal" size={12} />
              <Button label={deleteTitle} primary size="small" onClick={onRemoveHandler} />
            </>
          ) : (
            <>
              <Button label={deleteTitle} danger size="small" onClick={onRemoveHandler} />
              <Push orientation="horizontal" size={12} />
              <Button label="Отмена" border primary size="small" onClick={onCloseHandle} />
            </>
          )
        }
      />
    </Modal>
  );
};
export default RemoveModal;
