import React from 'react';
import PageHeading from '../../components/header/page-heading';

const Main = () => {
  return (
    <>
      <PageHeading title="Единый сервис записи" sections={[{ title: 'Рабочий стол' }]} />
      <div className="container">Main</div>
    </>
  );
};

export default Main;
