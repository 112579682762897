import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../../../components/formik/formik-select';

type Props = {
  accessCheck: boolean;
  finTypeId: string | null;
  setFinId: (value: string | null) => void;
};

export const FinDocument: React.FC<Props> = ({ finTypeId, accessCheck, setFinId }) => {
  const findFinanceDocument = (value: number) => typeFinance.find((item: { value: number }) => item.value === value);

  const { setFieldValue } = useFormikContext<any>();

  const typeFinance = [
    {
      label: 'Бесплатно',
      value: 3,
    },
    {
      label: 'Платно',
      value: 2,
    },
  ];

  return (
    <FormikFormGroup required name="finTypeId" label="Тип финансирования">
      <FormikSelect
        name="finTypeId"
        size="small"
        isSearchable
        disabled={!accessCheck}
        selectedValue={(option: SelectOptionType) => {
          setFinId(option?.value as string);
          setFieldValue('finTypeId', option.value);
        }}
        defaultValue={findFinanceDocument(Number(finTypeId))}
        options={typeFinance}
        placeholder="Выберите тип финансирования"
      />
    </FormikFormGroup>
  );
};
