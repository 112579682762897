import React, { useContext } from 'react';
import { Tooltip, SimpleTable as Table, TableColumnType } from '@mosru/esz_uikit';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';
import { ServiceContext } from '../../../index';
import { EducationTypeEnum } from '../../../../../types/education-type';

const ViewAddressClass: React.FC = () => {
  const { serviceData } = useContext(ServiceContext);

  const hasAddressArchive = serviceData.placeService.list.some(({ isAddressArchive }) => isAddressArchive);

  const isChildrenEducation =
    serviceData.educationTypeId === EducationTypeEnum.ChildrenEducation ||
    serviceData.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation;

  const columns: TableColumnType[] = [
    {
      dataIndex: 'address',
      title: 'Адрес',
      render: (item: any) => item.fullAddress,
    },
  ];

  if (!isChildrenEducation) {
    columns.splice(0, 0, {
      dataIndex: 'number',
      title: '№',
      render: (item: any) => item.number,
      width: '40px',
    });
  }

  if (hasAddressArchive) {
    columns.splice(isChildrenEducation ? 0 : 1, 0, {
      dataIndex: 'isAddressArchive',
      title: '',
      width: '1%',
      render: (item: any) =>
        item.isAddressArchive && (
          <Tooltip
            component={() => <IconInfo />}
            position="right"
            text={
              <div className="text-center" style={{ maxWidth: '203px' }}>
                Адрес более не используется организацией в качестве места предоставления услуги
              </div>
            }
          />
        ),
    });
  }

  if (
    serviceData.educationTypeId === EducationTypeEnum.SportEducation ||
    serviceData.educationTypeId === EducationTypeEnum.ArtHouseEducation
  ) {
    columns.splice(hasAddressArchive ? 2 : 1, 0, {
      dataIndex: 'organization',
      title: 'Наименование организации',
      render: (item: any) => item.name,
    });
  }
  return (
    <Table
      data={serviceData.placeService.list.map((item, index) => ({ ...item, number: index + 1 }))}
      hideSort
      overflow
      columns={columns}
    />
  );
};

export default ViewAddressClass;
