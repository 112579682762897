import React, { useCallback, useState } from 'react';
import { Push } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { replaceHistoryState } from '../../../lib/utils';
import { historyState } from '../../../mock-data/history-state';
import LearnersSearch from './search';
import LearnersTable from './table';
import { SearchLearnersInitialFormData } from '../../../types/learners';

const Learners = () => {
  const [search, setSearch] = useState<SearchLearnersInitialFormData>();

  useClearHistoryState();

  const handleSubmitSearch = useCallback((values: SearchLearnersInitialFormData) => {
    setSearch(values);
    replaceHistoryState({ [historyState.search]: values });
  }, []);

  return (
    <>
      <PageHeading title="Обучающиеся" sections={[{ title: 'Главная', link: routes.main }, { title: 'Обучающиеся' }]} />
      <Push size={12} />
      <LearnersSearch submitForm={handleSubmitSearch} search={search} />
      <Push size={12} />
      <LearnersTable search={search} />
    </>
  );
};

export default Learners;
