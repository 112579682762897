import { Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { object as objectYup } from 'yup';
import { useSelector } from 'react-redux';
import { Button, IconSearch, SelectOptionType, Panel, Switcher, Push } from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { ReactComponent as IconOptions } from '../../../assets/images/icons/options.svg';
import { ReactComponent as IconUp } from '../../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../../assets/images/icons/down.svg';
import FormikInput from '../../../components/formik/formik-input';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import FormikSelect from '../../../components/formik/formik-select';
import { SearchTeachersFormData } from '../../../types/teacher';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import lookupApi from '../../../lib/api/lookup';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { getCountDiff, hasAccessObjectAny, hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { searchTeachersInitialFormData } from './index';

type Props = {
  submitForm: (values: SearchTeachersFormData) => void;
  initialForm: SearchTeachersFormData;
};

const TeachersSearch = ({ submitForm, initialForm }: Props) => {
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const initialErrors = useInitialErrors(initialForm, getValidationSchema());

  const [organization, setOrganization] = useState<SelectOptionType | null>(null);
  const [discipline, setDiscipline] = useState<SelectOptionType | null>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAcceptedEdit = useMemo(
    () =>
      (hasAccessObjectAny(userProfile, [accessObject.Teachers], accessAction.ViewRegistry) &&
        !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) &&
        !hasGeneralAccess(userProfile, generalAccess.AdminView) &&
        !hasGeneralAccess(userProfile, generalAccess.AdminEdit)) ||
      (!hasGeneralAccess(userProfile, generalAccess.AdminView) &&
        hasGeneralAccess(userProfile, generalAccess.AdminEdit)),
    [userProfile]
  );

  useEffect(() => {
    if (!organization && (userProfile.organizationId || initialForm.organizationId)) {
      if (isAcceptedEdit || initialForm.organizationId) {
        setOrganization(
          initialForm.organizationId && initialForm.organizationName
            ? { label: initialForm.organizationName, value: initialForm.organizationId }
            : { label: userProfile.organizationName, value: userProfile.organizationId }
        );
      } else {
        setOrganization(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialForm.organizationId,
    initialForm.organizationName,
    isAcceptedEdit,
    userProfile.organizationId,
    userProfile.organizationName,
  ]);

  useEffect(() => {
    if (initialForm.disciplineId && initialForm.disciplineName && !discipline) {
      setDiscipline({
        label: initialForm.disciplineName,
        value: initialForm.disciplineId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialForm.disciplineId, initialForm.disciplineName]);

  const resetForm = () => {
    submitForm({
      ...searchTeachersInitialFormData,
      organizationId: isAcceptedEdit ? userProfile.organizationId : searchTeachersInitialFormData.organizationId,
      organizationName: isAcceptedEdit ? userProfile.organizationName : undefined,
    });
    setRerenderFormKey(Math.random());
    setOrganization(isAcceptedEdit ? organization : null);
    setDiscipline(null);
  };

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [historyState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        const send =
          isAcceptedEdit && organization?.value === userProfile.organizationId
            ? {
                ...values,
                organizationId: userProfile.organizationId,
                organizationName: userProfile.organizationName,
              }
            : values;

        submitForm(send);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialForm}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
      key={rerenderFormKey}
    >
      {(formikProps: FormikProps<SearchTeachersFormData>) => {
        const { handleSubmit, values, isSubmitting, isValid, setFieldValue } = formikProps;

        const countDiff = getCountDiff(values, {
          ...searchTeachersInitialFormData,
          disciplineName: values.disciplineName,
          organizationName: values.organizationName,
          organizationId: isAcceptedEdit ? values.organizationId : searchTeachersInitialFormData.organizationId,
        });

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              subControl={() => (
                <button type="button" onClick={handleClickAdvancedSearch} className="icon-group">
                  <span className="icon-group__icon">
                    <IconOptions />
                  </span>
                  <span className="icon-group__text">
                    Расширенный поиск {!open && countDiff > 0 && <span className="icon-group__badge">{countDiff}</span>}
                  </span>
                  <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
                </button>
              )}
              controls={() => (
                <>
                  <Button label="Сбросить" onClick={() => resetForm()} border size="small" />
                  <Push orientation="horizontal" size={12} />
                  <Button submit label="Начать поиск" load={isSubmitting} disabled={!isValid} primary size="small" />
                </>
              )}
            >
              <div className="container">
                <Push size={20} />
                <FormikInput
                  name="query"
                  value={values.query}
                  placeholder="Поиск по преподавателям..."
                  iconLeft={() => <IconSearch />}
                />
                {open && (
                  <>
                    <Push size={16} />
                    <div className="teachers-input-grid">
                      <FormikFormGroup name="organizationId" label="Организация">
                        <FormikSelect
                          loadOptions={async (query) => await lookupApi.getOrganization(query)}
                          name="organizationId"
                          size="small"
                          isSearchable
                          disabled={isAcceptedEdit}
                          selectedValue={(option) => {
                            setOrganization(option);
                            setFieldValue('organizationName', option?.label);
                          }}
                          placeholder="Начните вводить..."
                          defaultValue={organization}
                          options={[defaultOptionValue]}
                        />
                      </FormikFormGroup>
                      <FormikFormGroup name="disciplineId" label="Дисциплины">
                        <FormikSelect
                          loadOptions={async (query) => await lookupApi.getDisciplines(query)}
                          name="disciplineId"
                          size="small"
                          isSearchable
                          defaultValue={discipline}
                          selectedValue={(option: SelectOptionType) => {
                            setDiscipline(option);
                            setFieldValue('disciplineName', option?.label);
                          }}
                          placeholder="Начните вводить..."
                          options={[defaultOptionValue]}
                        />
                      </FormikFormGroup>
                      <FormikFormGroup name="isContactPerson" label="Контактное лицо">
                        <Switcher size="small">
                          {contactOptions.map((item, index) => (
                            <button
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              type="button"
                              onClick={() => setFieldValue('isContactPerson', item.value)}
                              className={values.isContactPerson === item.value ? 'active' : ''}
                            >
                              {item.label}
                            </button>
                          ))}
                        </Switcher>
                      </FormikFormGroup>
                    </div>
                    <Push size={16} />
                    <FormikFormGroup name="presentInNSI" label="Сведения о преподавателе в реестре преподавателей">
                      <Switcher size="small">
                        {teacherInfoOptions.map((item, index) => (
                          <button
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            type="button"
                            onClick={() => setFieldValue('presentInNSI', item.value)}
                            className={values.presentInNSI === item.value ? 'active' : ''}
                          >
                            {item.label}
                          </button>
                        ))}
                      </Switcher>
                    </FormikFormGroup>
                  </>
                )}
              </div>
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

export default TeachersSearch;

const getValidationSchema = () => objectYup().shape({});

const defaultOptionValue = { value: 0, label: 'Все' };

const contactOptions = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

const teacherInfoOptions = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Присутствуют',
    value: true,
  },
  {
    label: 'Отсутствуют',
    value: false,
  },
];
