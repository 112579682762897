import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ChildForm from './child';
import ApplicantForm from './applicant';
import Organization from './organization';
import ServiceClass from './service-class';
import ActionsForm from './actions';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { hasAccessObjectAny } from '../../../lib/utils';
import { accessAction, accessObject } from '../../../types/authorization-data';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import requestsApi from '../../../lib/api/requests';

type DetailsProps = {
  data: RequestData;
  updateRequest: () => void;
  requestErrorMessage: string;
  editMode: string | null;
  setEditMode: Dispatch<SetStateAction<string | null>>;
};

const Details = ({ data, updateRequest, requestErrorMessage, editMode, setEditMode }: DetailsProps) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const checkAccessRequestDayCareEdit = hasAccessObjectAny(
    userProfile,
    [accessObject.RequestDayCare],
    accessAction.Edit
  );

  const btnEditDisabled = useMemo(() => {
    if (
      checkAccessRequestDayCareEdit &&
      (data.requestStatusId === RequestStatusEnum.New || data.requestStatusId === RequestStatusEnum.Draft)
    ) {
      return true;
    } else {
      return false;
    }
  }, [data.requestStatusId, checkAccessRequestDayCareEdit]);

  const showEditBtn = (type: string): boolean => {
    if (
      checkAccessRequestDayCareEdit &&
      (data.requestStatusId === RequestStatusEnum.UnderConsideration ||
        data.requestStatusId === RequestStatusEnum.ForInclude) &&
      type === 'actions'
    ) {
      return true;
    } else if (btnEditDisabled) {
      return true;
    } else {
      return false;
    }
  };

  const actionEnable = useMemo(
    () =>
      data.requestStatusId === RequestStatusEnum.UnderConsideration ||
      data.requestStatusId === RequestStatusEnum.Included ||
      data.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      data.requestStatusId === RequestStatusEnum.ForInclude ||
      data.requestStatusId === RequestStatusEnum.ApplicantRefused ||
      data.requestStatusId === RequestStatusEnum.IncludedExcluded,
    [data.requestStatusId]
  );

  const changeStatusRequest = useCallback(() => {
    const setStatus = async (newRequestStatus: RequestStatusEnum) => {
      await requestsApi.setRequestStatus(data?.id, data?.educationTypeId, data?.requestStatusId, newRequestStatus);
    };
    if (data.requestStatusId === RequestStatusEnum.Draft) {
      setStatus(RequestStatusEnum.New);
    }
  }, [data?.educationTypeId, data?.id, data.requestStatusId]);

  return (
    <>
      {(!editMode || editMode === 'child') && (
        <ChildForm
          data={data.child}
          setEditModeParent={setEditMode}
          contingentLinkTypeId={data.contingentLinkTypeId}
          updateRequest={updateRequest}
          dayCareRegisterRequestMessage={data.dayCareRegisterRequestMessage}
          requestErrorMessage={requestErrorMessage}
          showEditBtn={showEditBtn}
          requestStatusId={data.requestStatusId}
          changeStatusRequest={changeStatusRequest}
        />
      )}
      {(!editMode || editMode === 'organization') && (
        <Organization
          data={data.school}
          requestId={data.id}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          showEditBtn={showEditBtn}
          requestStatusId={data.requestStatusId}
          changeStatusRequest={changeStatusRequest}
        />
      )}
      {(!editMode || editMode === 'applicant') && (
        <ApplicantForm
          requestApplicant={data.applicant}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          showEditBtn={showEditBtn}
          changeStatusRequest={changeStatusRequest}
        />
      )}
      {(!editMode || editMode === 'serviceClass') && (
        <ServiceClass
          trainingGroup={data.trainingGroup}
          school={data.school}
          requestId={data.id}
          updateRequest={updateRequest}
          setEditModeParent={setEditMode}
          showEditBtn={showEditBtn}
          changeStatusRequest={changeStatusRequest}
        />
      )}
      {(!editMode || editMode === 'actions') && actionEnable && (
        <ActionsForm
          enrollment={data.enrollment}
          decline={data.decline}
          requestStatusId={data.requestStatusId}
          serviceId={data.trainingGroup?.serviceId}
          requestId={data.id}
          setEditModeParent={setEditMode}
          updateRequest={updateRequest}
          showEditBtn={showEditBtn}
          changeStatusRequest={changeStatusRequest}
          contingentGuid={data.contingentGuid}
          serviceClassId={data.trainingGroup.serviceClass.id}
        />
      )}
    </>
  );
};

export default Details;
