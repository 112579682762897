import React from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as IconOptions } from '../../../../assets/images/icons/options.svg';
import { ReactComponent as IconUp } from '../../../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../../../assets/images/icons/down.svg';
import { SearchLearnersInitialFormData } from '../../../../types/learners';
import { getCountDiff } from '../../../../lib/utils';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  initialForm: SearchLearnersInitialFormData;
};

export const ExpandedSearchButton = ({ open, setOpen, initialForm }: Props) => {
  const { values } = useFormikContext<SearchLearnersInitialFormData>();

  const countDiff = getCountDiff(values, {
    ...initialForm,
    organizationName: values.organizationName,
  });

  return (
    <button type="button" onClick={() => setOpen(!open)} className="icon-group">
      <span className="icon-group__icon">
        <IconOptions />
      </span>
      <span className="icon-group__text">
        Расширенный поиск {!open && countDiff > 0 && <span className="icon-group__badge">{countDiff}</span>}
      </span>
      <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
    </button>
  );
};
