import { Formik, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { number, object as objectYup, string as stringYup } from 'yup';
import { Button, ModalPanel, SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import FormikInput from '../../../../components/formik/formik-input';
import FormikSelect from '../../../../components/formik/formik-select';
import { getStringFromTimeTable, getTimeTableFromString, scheduleMockData } from '../../../../lib/utils/time-table';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { maxLengthTextError } from '../../../../lib/utils/validation';
import { ItemsWork } from '../../../../types/items-work';
import { Organization } from '../../../../types/organization';
import EditSchedule, { ScheduleType } from '../../../../components/schedule';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

type FormData = {
  schedule: ScheduleType[];
  saveOn: boolean;
} & ItemsWork;

type Props = {
  editMode: boolean;
  scheduleSubmit: boolean;
  organization: Organization;
  addressOptions: SelectOptionType[];
  setEditMode: (value: boolean) => void;
  itemWork: ItemsWork | null | undefined;
  setScheduleSubmit: (value: boolean) => void;
  setRerenderTableKey: (value: number) => void;
  setItemWork: (value: ItemsWork | null) => void;
};

export const ModalForm: React.FC<Props> = ({
  setEditMode,
  scheduleSubmit,
  itemWork,
  organization,
  addressOptions,
  setRerenderTableKey,
  setScheduleSubmit,
  setItemWork,
  editMode,
}) => {
  const itemWorkFormData = useMemo(
    () =>
      itemWork
        ? ({
            ...itemWork,
            schedule: getTimeTableFromString(itemWork?.timetable),
            saveOn: false,
          } as FormData)
        : ({} as FormData),
    [itemWork]
  );

  const [errorSchedule, setErrorSchedule] = useState<boolean>(false);
  const initialErrors = useInitialErrors(itemWorkFormData, getValidationSchema());

  const submitForm = useCallback(
    async (values: FormData) => {
      const data = {
        addressId: values.addressId,
        shortName: values.shortName,
        fullName: values.fullName,
        timetable: getStringFromTimeTable(values.schedule),
        fullAddress: values.fullAddress,
      };

      if (itemWork?.id) {
        if (organization?.id) {
          await dictionariesApi.updateItemWork(itemWork.id, {
            ...itemWork,
            ...data,
            organizationId: organization?.id,
          });
        }
      } else if (organization?.id) {
        await dictionariesApi.saveItemWork({ ...data, organizationId: organization?.id } as ItemsWork);
      }

      setEditMode(false);
      setRerenderTableKey(Math.random());
    },
    [itemWork, organization?.id, setEditMode, setRerenderTableKey]
  );

  return (
    <Formik
      onSubmit={submitForm}
      initialValues={itemWorkFormData}
      validationSchema={getValidationSchema()}
      enableReinitialize
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<FormData>) => {
        const { handleSubmit, isSubmitting, isValid, setFieldValue, values } = formikProps;

        return (
          <form>
            <ModalPanel
              controls={() => (
                <>
                  <Button
                    label="Отмена"
                    onClick={() => {
                      setEditMode(false);
                      setItemWork(null);
                    }}
                    border
                    size="small"
                    primary
                  />
                  <Push orientation="horizontal" size={12} />
                  <Button
                    label="Сохранить"
                    primary
                    size="small"
                    load={isSubmitting}
                    disabled={!(errorSchedule && isValid)}
                    handleClick={() => {
                      setScheduleSubmit(true);
                    }}
                  />
                </>
              )}
              onClose={() => {
                setEditMode(false);
              }}
              size="medium"
              modalTitle="Пункт работы с заявителями"
              maxHeightBody="calc(100vh - 134px)"
              renderComponent={() => (
                <>
                  <FormikFormGroup name="fullName" label="Полное наименование" required>
                    <FormikTextarea
                      placeholder="Введите полное наименование пункта работы с заявителями..."
                      name="fullName"
                    />
                  </FormikFormGroup>

                  <Push size={16} />
                  <FormikFormGroup name="shortName" label="Краткое наименование">
                    <FormikInput
                      placeholder="Введите краткое наименование пункта работы с заявителями..."
                      name="shortName"
                      size="small"
                    />
                  </FormikFormGroup>
                  <Push size={16} />
                  <FormikFormGroup name="address" label="Адрес пункта" required>
                    <FormikSelect
                      name="addressId"
                      size="small"
                      placeholder="Выберите адрес пункта работы с заявителями из адресов организации..."
                      isSearchable
                      maxMenuHeight={205}
                      defaultValue={
                        values.addressId && values.fullAddress
                          ? { value: values.addressId, label: values.fullAddress }
                          : null
                      }
                      options={addressOptions}
                    />
                  </FormikFormGroup>
                  <div className="org-schedule-panel">
                    <EditSchedule
                      errorSchedule={setErrorSchedule}
                      submit={scheduleSubmit}
                      title={<span className="font-size-small">Расписание работы пункта</span>}
                      submitError={() => setScheduleSubmit(false)}
                      submitSuccess={(schedule) => {
                        if (scheduleSubmit) {
                          setFieldValue('schedule', schedule);
                          handleSubmit();
                        }
                        setScheduleSubmit(false);
                      }}
                      scheduleData={values.schedule?.length ? values.schedule : scheduleMockData()}
                      timeRoundingUp
                      hours24
                    />
                  </div>
                </>
              )}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    fullName: stringYup().required('Введите полное наименование').max(65535, maxLengthTextError(65535)),
    shortName: stringYup().nullable().max(500, maxLengthTextError(500)),
    addressId: number().required('Выберите адрес'),
  });
