import { Loader, Panel, Push } from '@mosru/esz_uikit';
import React from 'react';

type Props = {
  size: number;
  isLargeLoader?: boolean;
  hasPanel?: boolean;
};

export const LoaderCustom = ({ hasPanel, ...props }: Props) => {
  return hasPanel ? (
    <Panel>
      <LoaderInner {...props} />
    </Panel>
  ) : (
    <LoaderInner {...props} />
  );
};

const LoaderInner = ({ size, isLargeLoader }: Omit<Props, 'hasPanel'>) => (
  <div className="flex justify-center flex-column">
    <Push orientation="vertical" size={size} />
    <Loader roller small={!isLargeLoader} />
    <Push orientation="vertical" size={size} />
  </div>
);
