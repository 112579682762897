import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconRemove } from '../../../../../../assets/images/icons/remove-no-fill.svg';
import FormikSelect from '../../../../../../components/formik/formik-select';
import { convertTypeDocument, listTypeDocuments } from '../../../../../../lib/utils/learners';
import { DocumentTypeEnum } from '../../../../../../mock-data/type-document';
import { Learner } from '../../../../../../types/learners';

type Props = {
  index: number;
  editMode: boolean;
  onChangeDocType: (option: SelectOptionType) => void;
};

export const DocumentType = ({ index, editMode, onChangeDocType }: Props) => {
  const { values, setFieldValue } = useFormikContext<Learner.Info>();

  const handleDeleteDoc = () => {
    setFieldValue(
      'pupilDocument',
      values.pupilDocument.filter((doc, docIndex) => docIndex !== index)
    );
  };

  useEffect(() => {
    if (
      editMode &&
      values.pupilDocument[0]?.documentTypeId !== DocumentTypeEnum.BirthCertificate &&
      values.pupilDocument.length > 1
    ) {
      setFieldValue('pupilDocument', [values.pupilDocument[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.pupilDocument[0]?.documentTypeId, editMode]);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        Документ, удост. личность {editMode ? <span className="table-data__required" /> : null}
      </div>
      <div className="table-data__body flex">
        {editMode ? (
          <>
            <div className="flex-auto">
              <FormikSelect
                selectedValue={onChangeDocType}
                defaultValue={values.activeDocument}
                name={`pupilDocument[${index}].documentTypeId`}
                size="small"
                isSearchable
                options={listTypeDocuments}
                disabled={!!index}
                placeholder="Выберите..."
              />
            </div>
            {!!index && (
              <>
                <Push size={12} orientation="horizontal" />
                <Button
                  id={`delete-${index}`}
                  border
                  iconLeft={() => <IconRemove />}
                  size="small"
                  handleClick={handleDeleteDoc}
                />
              </>
            )}
          </>
        ) : (
          convertTypeDocument(values.pupilDocument[index]?.documentTypeId) ||
          values?.activeDocument?.label || <span className="color-gray">Не указано</span>
        )}
      </div>
    </div>
  );
};
