import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Link } from 'react-router-dom';
import { Radio, SimpleTable } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import { routes } from '../../../../config/constants';
import { ServiceClass, StatusCount, TrainingGroupByServiceDayCare } from '../../../../types/requests';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { formatDate } from '../../../../lib/utils/date';
import { serviceIdField } from './service';
import { styleVars } from '../../../../theme/vars';
import { EducationTypeEnum } from '../../../../types/education-type';
import { schoolOrganizationIdField } from './organization';
import { generateUrlSearchString } from '../../../../lib/utils';

type AdmissionPlanProps = FieldProps<number> & { parent?: string };

export const scheduleOfTimetableIdField = 'scheduleOfTimetableId';
export const trainingGroupIdFieldId = 'trainingGroupId';

const AdmissionPlan = ({
  label = 'План приема',
  name,
  editMode,
  required,
  defaultValue,
  parent,
}: AdmissionPlanProps) => {
  const [trainingGroupList, setTrainingGroupList] = useState<TrainingGroupByServiceDayCare[] | null>(null);
  const [serviceField] = useField<number>(parent ? `${parent}.${serviceIdField}` : serviceIdField);
  const [field, , helpers] = useField(parent ? `${parent}.${name}` : name);
  const [, , trainingGroupIdFieldHelpers] = useField(trainingGroupIdFieldId);

  const [schoolOrganizationIdOption] = useField<number>(
    parent ? `school.${schoolOrganizationIdField}` : schoolOrganizationIdField
  );

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceTemplateApi.getTrainingGroupsDayCareByService(serviceField.value ?? 0);

      setTrainingGroupList(tgList);
    };

    if (serviceField.value) {
      fetch();
    } else {
      setTrainingGroupList(null);
    }
  }, [serviceField.value]);

  return trainingGroupList && schoolOrganizationIdOption.value ? (
    <Field label={label} editMode={editMode} disabled={!editMode} required={required} defaultValue={defaultValue}>
      <SimpleTable
        data={trainingGroupList}
        hideSort
        headerBorderTop={false}
        columns={[
          {
            dataIndex: 'id',
            title: '',

            render: (item: any) => (
              <Radio
                name=""
                labelId=""
                checked={field.value === item.scheduleOfTimetableId}
                onChange={() => {
                  helpers.setValue(item.scheduleOfTimetableId);
                  trainingGroupIdFieldHelpers.setValue(item.id);
                }}
                disabled={!editMode || item.serviceClassList.length === 0}
              />
            ),
            width: '40px',
          },
          {
            dataIndex: 'educationStartDate',
            title: 'Начало занятий',
            render: (item: any) => formatDate(item.educationDateStart),
            width: '150px',
          },
          {
            dataIndex: 'serviceClass',
            title: 'Группа',
            render: (item: any) => {
              return item.serviceClassList.length > 0 ? (
                <div className="flex flex-column wrap">
                  {item.serviceClassList?.map((serviceClass: ServiceClass) => {
                    return (
                      <Link
                        key={serviceClass.id}
                        className="brand-link"
                        to={`${routes.serviceClasses}/${serviceClass.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {serviceClass.name ?? 'Группа обучения не найдена'}
                      </Link>
                    );
                  })}
                </div>
              ) : (
                <div style={{ color: styleVars.brandGrayDark }}>Группа обучения не найдена</div>
              );
            },
          },
          {
            dataIndex: 'numbers',
            title: 'Всего / активные / зачислено / отчислено / отказано / отозвано',
            width: '270px',
            render: (item: any) =>
              item.statusCount?.map((statusCount: StatusCount, index: number) => {
                return statusCount.volume === 0 ? (
                  <>
                    {0}
                    {index < item.statusCount.length - 1 && <> / </>}
                  </>
                ) : (
                  <>
                    {' '}
                    <Link
                      to={{
                        pathname: routes.requests,
                        search: generateUrlSearchString({
                          educationTypeId: EducationTypeEnum.DayCareCentersEducation,
                          serviceId: serviceField.value,

                          requestStatusId: statusCount.requestStatusId === 0 ? '' : statusCount.requestStatusId,
                          serviceClassId: item.serviceClassList[0]?.id,

                          trainingGroupId: item.id,
                        }),
                      }}
                      target="_blank"
                      className="brand-link"
                    >
                      {statusCount.volume}
                    </Link>
                    {index < item.statusCount.length - 1 && <> / </>}
                  </>
                );
              }),
          },
        ]}
      />
    </Field>
  ) : null;
};

export default AdmissionPlan;
