import React, { useContext, useState } from 'react';
import { Button, Push } from '@mosru/esz_uikit';
import Expulsion from '../../components/modals/expulsion';
import Union from '../../components/modals/union';
import TransferGroup from '../../components/modals/transfer-group';
import RecoverySimple from '../../components/modals/recovery-simple';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import Change from '../../components/modals/change';

const PanelButtons = () => {
  const { setSelected, setOpenDetails, openDetails } = useContext(ServiceClassTableContext);

  const { adminView, adminEdit } = useContext(ServiceClassContext);

  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false);

  const [openExpulsionPopup, setOpenExpulsionPopup] = useState<boolean>(false);

  const [openTransferGroupPopup, setOpenTransferGroupPopup] = useState<boolean>(false);

  const [openUnionPopup, setOpenUnionPopup] = useState<boolean>(false);

  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);

  return (
    <>
      {openDetails && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  default
                  handleClick={() => {
                    setSelected([]);
                    setOpenDetails(false);
                  }}
                  label="Отменить выбор"
                />
                <Push size={12} orientation="horizontal" />
                <Button
                  danger
                  border
                  label="Отчислить"
                  handleClick={() => {
                    setOpenExpulsionPopup(true);
                  }}
                />

                <Push size={12} orientation="horizontal" />

                {(adminView || adminEdit) && (
                  <>
                    <Button
                      border
                      primary
                      label="Изменить даты обучения"
                      handleClick={() => setOpenChangeModal(true)}
                    />
                    <Push size={12} orientation="horizontal" />
                  </>
                )}

                <Button
                  border
                  primary
                  label="Перевести в другую группу"
                  handleClick={() => setOpenTransferGroupPopup(true)}
                />

                <Push size={12} orientation="horizontal" />

                <Button handleClick={() => setOpenUnionPopup(true)} primary border label="Объединить в подгруппу" />
              </div>
            </div>
          </div>
        </div>
      )}
      <RecoverySimple open={openRecoveryPopup} close={() => setOpenRecoveryPopup(false)} />
      <Expulsion open={openExpulsionPopup} close={() => setOpenExpulsionPopup(false)} />
      <Change open={openChangeModal} close={() => setOpenChangeModal(false)} />
      <TransferGroup open={openTransferGroupPopup} close={() => setOpenTransferGroupPopup(false)} />
      <Union open={openUnionPopup} close={() => setOpenUnionPopup(false)} />
    </>
  );
};

export default PanelButtons;
