import React, { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Button, ModalPanel, Modal, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikSelect from '../../../components/formik/formik-select';
import FormikTextarea from '../../../components/formik/formik-textarea';
import FormikToggle from '../../../components/formik/formik-toggle';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import { Organization } from '../../../types/organization';
import { accessAction, accessObject, accessVedomst, generalAccess } from '../../../mock-data/access-enum';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import organizationApi from '../../../lib/api/organization';
import documentApi from '../../../lib/api/document';
import { checkPhone } from '../../../lib/utils/organization';
import SavePanel from '../../../components/save-panel';

type OrganizationFormInfoProps = {
  setEditModeParent: (edit: boolean) => void;
  organization: Organization;
  departments: SelectOptionType[];
  updateOrganization: (value: Organization) => void;
};

type FormData = {
  type: number;
  departament: number;
  description: string;
  test: boolean;
};

const OrganizationFormInfo: React.FC<OrganizationFormInfoProps> = ({
  setEditModeParent,
  organization,
  departments,
  updateOrganization,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [openCancelledPopupSing, setOpenCancelledPopupSing] = useState<boolean>(false);
  const [submitPopup, setSubmitPopup] = useState<boolean>(false);
  const [formikKey, setFormikKey] = useState(0);

  const initialFormData = useMemo(
    () => ({
      type: organization.typesProvidingServicesId || 0,
      departament: organization.vedomstvoId || 0,
      description: organization.description || '',
      test: organization.isTest || false,
    }),
    [organization]
  );

  const initialErrors = useInitialErrors(initialFormData, getValidationSchema());

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const onSignatureModal =
    organization.organizationSign || (organization.vedomstvoSign && organization.vedomstvoId !== accessVedomst.Dsit);

  const submitForm = useCallback(
    async (values: FormData) => {
      await organizationApi.updateOrganization({
        ...organization,
        vedomstvoId: values.departament,
        typesProvidingServicesId: values.type,
        isTest: values.test,
        description: values.description,
      });
      const newOrganization = await organizationApi.getOrganization(organization.id);
      updateOrganization(newOrganization);
      setEditMode(false);
      setEditModeParent(false);
      setFormikKey(Math.random());
      setOpenCancelledPopupSing(false);
    },
    [organization, setEditModeParent, setEditMode, updateOrganization]
  );

  return (
    <Formik
      key={formikKey}
      onSubmit={async (values, { setSubmitting }) => {
        submitForm(values);
        setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialFormData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<FormData>) => {
        const { handleSubmit, values, isValid } = formikProps;
        return (
          <form>
            <Push size={12} />
            <Panel
              title={() => 'Сведения об организации'}
              headingControl={() =>
                !organization.isArchive &&
                organization.vedomstvoId !== accessVedomst.Dogm &&
                !editMode &&
                hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
                  <button
                    type="button"
                    onClick={() => {
                      setEditMode(true);
                      setEditModeParent(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                )
              }
            >
              <div className="container">
                <div className="table-data">
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Полное наименование</div>
                    <div className="table-data__body">{organization.fullName}</div>
                  </div>
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Краткое наименование</div>
                    <div className="table-data__body">{organization.shortName}</div>
                  </div>
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Реквизиты организации</div>
                    <div className="table-data__body">
                      <div className="table-data-grid-3">
                        <div className="table-data__group">
                          <div className="table-data__label">ИНН</div>
                          <div className="table-data__body">{organization.inn}</div>
                        </div>
                        <div className="table-data__group">
                          <div className="table-data__label">КПП</div>
                          <div className="table-data__body">{organization.kpp}</div>
                        </div>
                        <div className="table-data__group">
                          <div className="table-data__label">ОГРН</div>
                          <div className="table-data__body">{organization.ogrn}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Тип организации {editMode && <div className="table-data__required" />}
                    </div>
                    <div className="table-data__body">
                      {editMode ? (
                        <FormikSelect
                          name="type"
                          size="small"
                          isSearchable
                          options={
                            organization.organizationTypes?.map((item) => ({ value: item.id, label: item.name })) || []
                          }
                          placeholder="Выбрать"
                        />
                      ) : (
                        organization.organizationTypes?.find((row) => row.id === organization.typesProvidingServicesId)
                          ?.name
                      )}
                    </div>
                  </div>
                  {organization.vedomstvoId === accessVedomst.Dogm && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">ЕКИС</div>
                      <div className="table-data__body">
                        <div className="table-data-grid-2">
                          <div className="table-data__group">
                            <div className="table-data__label">Код ЕКИС</div>
                            <div className="table-data__body">{organization.ekisIds}</div>
                          </div>
                          <div className="table-data__group">
                            <div className="table-data__label">Статус ЕКИС</div>
                            <div className="table-data__body">{organization.description}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Департамент {editMode && <div className="table-data__required" />}
                    </div>
                    <div className="table-data__body">
                      {editMode ? (
                        <FormikSelect name="departament" size="small" isSearchable options={departments} />
                      ) : (
                        departments.find((row) => row.value === values.departament)?.label
                      )}
                    </div>
                  </div>
                  {organization.vedomstvoId !== accessVedomst.Dogm && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Учредитель</div>
                      <div className="table-data__body">{organization.creator}</div>
                    </div>
                  )}
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Руководитель</div>
                    <div className="table-data__body">
                      <div className="table-data-grid-2">
                        <div className="table-data__group">
                          <div className="table-data__label">ФИО</div>
                          <div className="table-data__body">{organization.person}</div>
                        </div>
                        <div className="table-data__group">
                          <div className="table-data__label">Должность</div>
                          <div className="table-data__body">{organization.personPosition}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {organization.vedomstvoId !== accessVedomst.Dogm && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">
                        Описание {editMode && <div className="table-data__required" />}
                      </div>
                      <div className="table-data__body">
                        {editMode ? (
                          <FormikFormGroup name="description" label="">
                            <FormikTextarea name="description" rows={3} />
                          </FormikFormGroup>
                        ) : (
                          values.description
                        )}
                      </div>
                    </div>
                  )}
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Направленности доп. образования</div>
                    <div className="table-data__body">
                      {organization.educationOrientations?.length ? (
                        organization.educationOrientations.map((educationOrientation) => (
                          <Fragment key={educationOrientation}>
                            {educationOrientation}
                            <br />
                          </Fragment>
                        ))
                      ) : (
                        <span className="color-gray">Не указано</span>
                      )}
                    </div>
                  </div>
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Направленности проф. подготовки</div>
                    <div className="table-data__body">
                      {organization.profTrainingOrientations?.length ? (
                        organization.profTrainingOrientations.map((profTrainingOrientation) => (
                          <Fragment key={profTrainingOrientation}>
                            {profTrainingOrientation}
                            <br />
                          </Fragment>
                        ))
                      ) : (
                        <span className="color-gray">Не указано</span>
                      )}
                    </div>
                  </div>
                  {organization.vedomstvoId === accessVedomst.Dogm && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Принадлежность к МРСД</div>
                      <div className="table-data__body">{organization.mrsdId && organization.mrsdName}</div>
                    </div>
                  )}
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Юридический адрес</div>
                    <div className="table-data__body">{organization.address}</div>
                  </div>
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">Контактная информация</div>
                    <div className="table-data__body">
                      <div className="table-data-grid-3">
                        <div className="table-data__group">
                          <div className="table-data__label">Телефон</div>
                          <div className="table-data__body">{checkPhone(organization.phone)}</div>
                        </div>
                        <div className="table-data__group">
                          <div className="table-data__label">E-mail</div>
                          <div className="table-data__body">{organization.email}</div>
                        </div>
                        <div className="table-data__group">
                          <div className="table-data__label">Сайт</div>
                          <div className="table-data__body">{organization.www}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {editMode && (
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Тестовая организация</div>
                      <div className="table-data__body">
                        <FormikToggle
                          disabled={!hasGeneralAccess(userProfile, generalAccess.AdminEdit)}
                          name="test"
                          size="small"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Panel>

            {editMode && (
              <SavePanel
                controls={
                  <>
                    <Button
                      onClick={() => {
                        setEditMode(false);
                        setEditModeParent(false);
                        setFormikKey(Math.random());
                      }}
                      border
                      primary
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    {onSignatureModal ? (
                      <Button
                        primary
                        label="Сохранить"
                        disabled={!isValid}
                        handleClick={() => setOpenCancelledPopupSing(true)}
                      />
                    ) : (
                      <Button primary label="Сохранить" onClick={handleSubmit} disabled={!isValid} />
                    )}
                  </>
                }
              />
            )}
            <CancellationSignatureModal
              signId={organization.organizationSign?.signId || organization.vedomstvoSign?.signId || 0}
              submit={submitPopup}
              submitEdit={setSubmitPopup}
              title="Аннулирование электронной подписи"
              description="Обратите внимание! При внесении изменений наложенная электронная подпись будет аннулирована."
              onCloseHandle={() => setOpenCancelledPopupSing(false)}
              submitSuccess={() => {
                if (submitPopup) {
                  handleSubmit();
                }
                setSubmitPopup(false);
              }}
              show={openCancelledPopupSing}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const CancellationSignatureModal: React.FC<{
  signId: number;
  show: boolean;
  onCloseHandle: () => void;
  title: string;
  description: string;
  submitSuccess: () => void;
  submitEdit: (value: boolean) => void;
  submit: boolean;
}> = ({ signId, submit, submitEdit, submitSuccess, show, onCloseHandle, title, description }) => {
  useEffect(() => {
    if (submit) {
      submitSuccess();
    }
  }, [submit, submitSuccess]);

  const cancelledRequest = async () => {
    submitEdit(true);
    await documentApi.getUnsign({
      signId,
      allTypes: true,
      accessObjectId: accessObject.EducationalOrganization,
    });
  };

  return (
    <Modal show={show} onClose={onCloseHandle}>
      <ModalPanel
        alert
        modalTitle={title}
        onClose={onCloseHandle}
        description={description}
        controls={() => (
          <>
            <Button label="Отмена" border primary size="small" onClick={onCloseHandle} />
            <Push orientation="horizontal" size={12} />
            <Button label="Продолжить" primary size="small" onClick={cancelledRequest} />
          </>
        )}
      />
    </Modal>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    type: stringYup().required('Выберите тип организации'),
    departament: stringYup().required('Выберите департамент'),
    description: stringYup().trim().required('Введите описание'),
  });

export default OrganizationFormInfo;
