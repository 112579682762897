import React from 'react';
import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type Props = {
  open: boolean;
  title: string;
  onCloseHandler: () => void;
  onSaveHandler: () => void;
};

const AddOrganizationPrevYear = ({ open, onCloseHandler, title, onSaveHandler }: Props) => {
  return (
    <Modal show={open} onClose={onCloseHandler}>
      <ModalPanel
        onClose={onCloseHandler}
        modalTitle={title}
        hideButton
        renderComponent={() => (
          <div>
            Обратите внимание! После подтверждения действия в план приема на текущий период обучения будут скопированы
            значения из предыдущего периода обучения. Уже введенные значения будут заменены. Продолжить?
          </div>
        )}
        controls={() => (
          <>
            <Button primary border label="Отмена" onClick={onCloseHandler} />
            <Push orientation="horizontal" size={12} />
            <Button primary label="Продолжить" onClick={onSaveHandler} />
          </>
        )}
      />
    </Modal>
  );
};

export default AddOrganizationPrevYear;
