import React, { useContext, useEffect, useState } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { setAsyncValues } from '../../../../lib/utils/requests';
import { SearchKeyContext } from '../contexts/key-context';
import { filterEducations } from '../../../../lib/utils/education';
import { accessAction, accessObject } from '../../../../types/authorization-data';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { EducationTypeEnum } from '../../../../types/education-type';

type Props = {
  onChangeEducationType?: (value: number) => void;
};

const FieldEducationType: React.FC<Props> = ({ onChangeEducationType }) => {
  const [educationTypeData, setEducationTypeData] = useState<SelectOptionType[]>([]);

  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();
  const {
    setServiceKey,
    setTrainingGroupKey,
    setClassificatorEKUKey,
    setProgrammLevelKey,
    setPrivilegeCategoryKey,
    setPrivilegeStatusKey,
  } = useContext(SearchKeyContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [fieldId, , helpers] = useField('educationTypeId');
  const [fieldName, , helpersName] = useField('educationTypeName');

  useEffect(() => {
    const fetch = async () => {
      // Данные для селекта "Тип услуги"
      const educationTypes = await dictionariesApi.getEducationTypes();

      const edTypes = filterEducations(
        userProfile.objectAccessActionList,
        educationTypes,
        undefined,
        accessObject.Requests,
        accessAction.ViewRegistry
      );
      setEducationTypeData(edTypes);
    };
    if (!educationTypeData.length) {
      fetch();
    }
  }, [userProfile.objectAccessActionList, educationTypeData.length]);

  const onChange = (option: SelectOptionType) => {
    const dataToClearValues = [
      { key: 'classificatorEKUId', action: setClassificatorEKUKey },
      { key: 'programmLevelId', action: setProgrammLevelKey },
      { key: 'serviceId', action: setServiceKey },
      { key: 'trainingGroupId', action: setTrainingGroupKey },
    ];
    if (option.value !== EducationTypeEnum.ArtHouseEducation) {
      dataToClearValues.push({ key: 'privilegeCategoryId', action: setPrivilegeCategoryKey });
      dataToClearValues.push({ key: 'privilegeStatusId', action: setPrivilegeStatusKey });
    }

    setAsyncValues(dataToClearValues, setFieldValue);
    helpers.setValue(option.value);
    helpersName.setValue(option.label);
    onChangeEducationType && onChangeEducationType(option?.value ? parseInt(option.value.toString()) : 0);
  };
  return (
    <FormikFormGroup name="educationTypeId" label="Тип услуги">
      <FormikSelect
        name="educationTypeId"
        size="small"
        disabled={educationTypeData.length <= 1}
        isSearchable
        options={educationTypeData}
        placeholder="Выберите тип образования..."
        selectedValue={onChange}
        defaultValue={fieldId.value ? { label: fieldName.value ?? '', value: fieldId.value } : null}
      />
    </FormikFormGroup>
  );
};

export default FieldEducationType;
