import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, ButtonGroup, IconArrowLongRight } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import { ReactComponent as IconPrinter } from '../../../assets/images/icons/printer.svg';
import { ReactComponent as IconCopy } from '../../../assets/images/icons/copy-2-white-blocks.svg';
import Favorite from '../../../components/favorite';
import ServiceProgramDetails from './details';
import { ServiceContext } from '..';
import { getHeaderLink } from '../utils';
import { ServiceStatusEnum } from '../../../mock-data/service-status-enum';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { generateLink } from '../../../lib/utils';
import ToArchiveModal from '../components/modals/to-archive';
import RecoverModal from '../components/modals/recover';
import { ReactComponent as IconRefresh } from '../../../assets/images/icons/refresh.svg';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import ErrorArchiveModal from '../components/modals/error-archive';
import { ArchiveObstacle, NumberSeatsAdmissionPlan } from '../../../types/service';
import { CopyProgramModal } from '../components/modals/copy-program';
import { TypeFinancingEnum } from '../../../mock-data/type-financing-enum';
import { classificatorEKULimitApi } from '../../../lib/api/classificator-EKU-limit';
import { RestrictionsBudgetPlacesData } from '../../../types/classificator';

const ServiceProgram = () => {
  const {
    serviceData,
    educationTypes,
    type,
    accessPanelEdit,
    accessAddArchive,
    canRecover,
    numberSeatsAdmissionPlan,
    routeBack,
  } = useContext(ServiceContext);
  const [showModalArchive, setShowModalArchive] = useState<boolean>(false);
  const [showModalErrorArchive, setShowModalErrorArchive] = useState<boolean>(false);
  const [showModalRecover, setShowModalRecover] = useState<boolean>(false);
  const [archiveObstacle, setArchiveObstacle] = useState<ArchiveObstacle>();
  const [loadCopy, setLoadCopy] = useState(false);

  const [showModalCopyProgram, setShowModalCopyProgram] = useState<boolean>(false);
  const [restrictionsBudgetPlaces, setRestrictionsBudgetPlaces] = useState<RestrictionsBudgetPlacesData[]>([]);

  const [activeTab, setActiveTab] = useState('Основные сведения');
  const history = useHistory();

  const checkAccessAddArchive = useMemo(() => {
    return serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit && accessAddArchive;
  }, [serviceData.serviceStatusId, accessPanelEdit, accessAddArchive]);
  const isArchive = serviceData?.serviceStatusId === ServiceStatusEnum.Arhive;

  // список годов без повтора и суммой мест
  const noRepeatYearOfTraining = useMemo(() => {
    return numberSeatsAdmissionPlan.reduce((accum: NumberSeatsAdmissionPlan[], currentValue) => {
      const findYear = accum.find(({ yearOfTrainingId }) => currentValue.yearOfTrainingId === yearOfTrainingId);

      if (findYear) {
        findYear.volume = (findYear.volume || 0) + (currentValue.volume || 0);
        return [...accum];
      } else {
        return [...accum, { ...currentValue }];
      }
    }, []);
  }, [numberSeatsAdmissionPlan]);

  // список Планы приема с количеством оставшихся мест
  const numberSeats = useMemo(() => {
    return numberSeatsAdmissionPlan.map((item) => {
      const currentYear = restrictionsBudgetPlaces.find((elem) => elem.yearOfTrainingId === item.yearOfTrainingId);
      if (currentYear?.limitVolume) {
        return { ...item, numberSeatsLeft: currentYear.limitVolume - (currentYear.volume || 0) };
      }
      return null;
    }, []);
  }, [numberSeatsAdmissionPlan, restrictionsBudgetPlaces]);

  // проверяем количество оставшихся мест по годам с суммой количество мест по годам (Планы приема)
  const checkPlace = useMemo(() => {
    return numberSeats.every((item) => {
      // текущий год
      const countNumberSeats =
        noRepeatYearOfTraining.find(({ yearOfTrainingId }) => item?.yearOfTrainingId === yearOfTrainingId)?.volume || 0;

      return !!item && item.numberSeatsLeft && item.numberSeatsLeft >= countNumberSeats;
    });
  }, [noRepeatYearOfTraining, numberSeats]);

  const handleCopyProgram = useCallback(() => {
    setLoadCopy(true);
    setTimeout(async () => {
      try {
        const serviceId = await serviceTemplateApi.copyService(serviceData.id);
        history.push(generateLink(routes.registerEducationProgram, { id: serviceId }));
      } catch {}
      setLoadCopy(false);
    }, 500);
  }, [history, serviceData.id]);

  useEffect(() => {
    const fetch = async () => {
      const data: RestrictionsBudgetPlacesData[] = [];
      try {
        for (let i = 0; i < noRepeatYearOfTraining.length; i++) {
          const response = await classificatorEKULimitApi.getBudgetPlaces({
            organizationId: serviceData.info.organizationId,
            educationTypeId: serviceData.info.educationTypeId,
            yearOfTrainingId: noRepeatYearOfTraining[i].yearOfTrainingId,
            classificatorEKUId: serviceData.info.classificatorEKUId,
          });

          if (response.length) {
            data.push(response[0]);
          }
        }
      } catch (e) {}
      setRestrictionsBudgetPlaces(data);
    };
    fetch();
  }, [
    noRepeatYearOfTraining,
    numberSeatsAdmissionPlan,
    serviceData.info.classificatorEKUId,
    serviceData.info.educationTypeId,
    serviceData.info.organizationId,
  ]);

  const showCopyProgram = () => {
    const access = accessPanelEdit && serviceData.serviceStatusId === ServiceStatusEnum.Draft;

    if (serviceData.financing.typeFinancingId === TypeFinancingEnum.Free) {
      return access && checkPlace;
    } else {
      return access;
    }
  };

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: <ServiceProgramDetails />,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name=""
            entityTypes={[EntityTypeEnum.Service]}
            extendEntityGuid={serviceData.extendEntityGuid}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [serviceData.extendEntityGuid]
  );

  return (
    <>
      <PageHeading
        buttonBack
        routeBack={routeBack}
        title="Образовательная программа"
        sections={[
          { title: 'Главная', link: routes.main },
          {
            title: 'Образовательные программы',
            link: getHeaderLink(educationTypes, type, serviceData?.educationTypeId),
          },
          { title: 'Описание образовательной программы' },
        ]}
        tabsButton={
          serviceData?.serviceStatusId === ServiceStatusEnum.Arhive ? (
            <Badge text="Архивная" color="default" />
          ) : (
            <Badge
              text={serviceData?.serviceStatusId === ServiceStatusEnum.Signed ? 'Опубликовано' : 'Не опубликовано'}
              color={serviceData?.serviceStatusId === ServiceStatusEnum.Signed ? 'success' : 'warning'}
            />
          )
        }
        controlsTopRight={
          isArchive ? (
            <ButtonGroup>
              {canRecover && (
                <Button
                  label="Восстановить"
                  iconLeft={() => <IconRefresh />}
                  size="small"
                  handleClick={() => setShowModalRecover(true)}
                />
              )}
              <Button
                handleClick={() =>
                  history.push(
                    generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id, showArchive: true })
                  )
                }
                label="Перейти к группам обучения"
                iconLeft={() => <IconArrowLongRight />}
                size="small"
              />
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                handleClick={() => history.push(generateLink(routes.serviceClasses, {}, { serviceId: serviceData.id }))}
                label="Перейти к группам обучения"
                iconLeft={() => <IconArrowLongRight />}
                size="small"
              />
              {showCopyProgram() && (
                <Button
                  label="Создать копию программы"
                  iconLeft={() => <IconCopy />}
                  size="small"
                  load={loadCopy}
                  handleClick={() =>
                    serviceData.financing.typeFinancingId === TypeFinancingEnum.Free && numberSeatsAdmissionPlan.length
                      ? setShowModalCopyProgram(true)
                      : handleCopyProgram()
                  }
                />
              )}
              {checkAccessAddArchive ? (
                <Button
                  label="В архив"
                  iconLeft={() => <IconArchive />}
                  size="small"
                  handleClick={async () => {
                    const data = await serviceTemplateApi.getArchiveObstacle(serviceData.id);
                    if (data.pupilList.length || data.requestList.length) {
                      setShowModalErrorArchive(true);
                      setArchiveObstacle(data);
                    } else {
                      setShowModalArchive(true);
                    }
                  }}
                />
              ) : null}
              <Button
                label="Печать"
                iconLeft={() => <IconPrinter />}
                size="small"
                handleClick={() =>
                  window.open(
                    generateLink(routes.printService, {
                      id: serviceData.id,
                    }),
                    '_blank'
                  )
                }
              />
            </ButtonGroup>
          )
        }
        tabs={tabs}
        activeTab={activeTab}
        expansion={
          serviceData && (
            <Favorite
              entityId={serviceData.id}
              typeEntity="услугу"
              currentEntityType={serviceData.info.name}
              entityTypeId={EntityTypeEnum.Service}
            />
          )
        }
      />
      {tabs.find((item) => item.label === activeTab)?.component}
      <ToArchiveModal onCloseHandler={() => setShowModalArchive(false)} show={showModalArchive} />
      <ErrorArchiveModal
        onCloseHandler={() => setShowModalErrorArchive(false)}
        show={showModalErrorArchive}
        data={archiveObstacle}
      />
      <RecoverModal onCloseHandler={() => setShowModalRecover(false)} show={showModalRecover} />
      <CopyProgramModal
        id={serviceData?.id}
        handleCopyProgram={handleCopyProgram}
        show={showModalCopyProgram}
        numberSeats={numberSeats}
        onClose={() => setShowModalCopyProgram(false)}
      />
    </>
  );
};

export default ServiceProgram;
