import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import { templateApi } from '../../../../../lib/api/template';
import { convertScheduleToBack } from '../../../../../lib/utils/time-table';
import { ScheduleType } from '../../../../../components/schedule';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { ServiceContext } from '../../..';
import EditServiceSchedule from './edit';
import ViewServiceSchedule from './view';
import { accessVedomst } from '../../../../../mock-data/access-enum';
import { AppState } from '../../../../../redux/types/state';
import { userProfileSelector } from '../../../../../redux/selectors';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  isTemplate?: boolean;
  isService?: boolean;
  title?: string;
};

export const showText = (isTemplateOrService: boolean | undefined) => {
  return isTemplateOrService
    ? 'Дни недели и время расписания будут определяться для каждого обучающегося в индивидуальном порядке.'
    : 'Расписание для каждого обучающегося уточняется в процессе зачисления.';
};

const ServiceSchedule = ({ setEditModeParent, isTemplate, title: propsTitle, isService }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { serviceData, updateService } = useContext(ServiceContext);

  const [editMode, setEditMode] = useState(!setEditModeParent);

  const [scheduleSubmit, setScheduleSubmit] = useState(false);

  const [scheduleError, setScheduleError] = useState<boolean>(false);

  const [currentSelectOption, setCurrentSelectOption] = useState<SelectOptionType | null>();

  const [canChangeSchedule, setCanChangeSchedule] = useState<boolean>(!!serviceData?.schedule?.canChangeSchedule);

  const [load, setLoad] = useState<boolean>(false);

  const submit = async (
    schedule: ScheduleType[],
    selectedSchedule: SelectOptionType | undefined,
    canChangeSchedule?: boolean
  ) => {
    const schedules = convertScheduleToBack(schedule);
    setLoad(true);
    const requestData = {
      scheduleTypeOfServiceName: selectedSchedule?.label as string,
      scheduleTypeOfServiceId: selectedSchedule?.value as number,
      list: schedules,
      educationTypeId: serviceData.educationTypeId,
      canChangeSchedule,
    };

    try {
      isTemplate
        ? await templateApi.updateSchedule(serviceData.id, {
            ...requestData,
            templateId: serviceData.id,
          })
        : await serviceTemplateApi.updateServiceSchedule(serviceData.id, {
            ...requestData,
            serviceId: serviceData.id,
          });
      setLoad(false);
    } catch {}
    await updateService();
    setEditModeParent && setEditModeParent(null);
    setEditMode(false);
  };

  const title = useMemo(() => {
    return propsTitle || (isTemplate ? 'Расписание предоставления услуги' : 'Время проведения занятий');
  }, [isTemplate, propsTitle]);

  const isTemplateOrService = useMemo(
    () =>
      userProfile.vedomstvoId === accessVedomst.Dkgm ||
      userProfile.vedomstvoId === accessVedomst.Dsit ||
      userProfile.vedomstvoId === accessVedomst.Kdc ||
      isTemplate,
    [isTemplate, userProfile.vedomstvoId]
  );

  useEffect(() => {
    if (serviceData.schedule.scheduleTypeOfServiceId) {
      setCurrentSelectOption({
        label: serviceData.schedule.scheduleTypeOfServiceName,
        value: serviceData.schedule.scheduleTypeOfServiceId,
      });
    }
  }, [serviceData.schedule.scheduleTypeOfServiceId, serviceData.schedule.scheduleTypeOfServiceName]);

  return (
    <>
      <Push size={12} />
      {editMode ? (
        <EditServiceSchedule
          title={title}
          submit={submit}
          scheduleSubmit={scheduleSubmit}
          setScheduleSubmit={setScheduleSubmit}
          serviceData={serviceData}
          isTemplate={isTemplate}
          isService={isService}
          setScheduleError={setScheduleError}
          setCurrentSelectOption={setCurrentSelectOption}
          canChangeSchedule={canChangeSchedule}
          isTemplateOrService={isTemplateOrService}
          setCanChangeSchedule={setCanChangeSchedule}
          editMode={editMode}
        />
      ) : (
        <ViewServiceSchedule
          title={title}
          isTemplate={isTemplate}
          setEditMode={setEditMode}
          isTemplateOrService={isTemplateOrService}
          setEditModeParent={setEditModeParent}
        />
      )}
      {editMode && setEditModeParent && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  onClick={() => {
                    setEditMode && setEditMode(false);
                    setEditModeParent && setEditModeParent(null);
                  }}
                  border
                  label="Отмена"
                  primary
                />
                <Push size={12} orientation="horizontal" />
                <Button
                  load={load}
                  handleClick={() =>
                    currentSelectOption?.value === 2
                      ? submit([], currentSelectOption, canChangeSchedule)
                      : setScheduleSubmit(true)
                  }
                  disabled={currentSelectOption?.value === 2 ? false : scheduleError}
                  primary
                  label="Сохранить"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceSchedule;
