import { useContext, useEffect } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import lookupApi from '../../../../lib/api/lookup';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { DependentDocumentContext } from './dependent-document';
import { RequestData } from '../../../../types/requests';

type RegistryOfficeProps = FieldProps<string> & {
  disabled?: boolean;
  parent?: string;
};

const RegistryOffice = ({
  label = 'Кем внесена запись',
  name,
  editMode,
  required,
  disabled = false,
  parent,
}: RegistryOfficeProps) => {
  const fieldName = parent ? `${parent}.${name}` : `${name}`;

  const [field] = useField(fieldName);

  const { setFieldValue, setFieldTouched } = useFormikContext<FormikHelpers<RequestData>>();
  const { issuedKey } = useContext(DependentDocumentContext);

  useEffect(() => {
    setFieldTouched(fieldName, true);
  }, [setFieldTouched, fieldName]);

  return (
    <Field key={issuedKey} label={label} editMode={editMode} required={required} value={field.value ?? '—'}>
      <FormikFormGroup required={required} label="" name={fieldName}>
        <FormikSelect
          name={fieldName}
          size="small"
          isSearchable
          options={[]}
          loadOptions={async (query) => await lookupApi.getRegistryOffice(query)}
          selectedValue={(v: SelectOptionType) => {
            setFieldValue(fieldName, v?.label);
          }}
          defaultValue={field.value ? { value: null, label: field.value } : null}
          placeholder="Начните вводить..."
          disabled={disabled}
        />
      </FormikFormGroup>
    </Field>
  );
};

export default RegistryOffice;
