import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import FormikSelect from '../../../../components/formik/formik-select';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

export type ParallelOptionsByResponse = {
  id: number;
  parallelName: string;
};

type ParallelFieldProps = FieldProps<SelectOptionType>;

const getParallelLabel = (options: SelectOptionType[]): string => {
  return options?.reduce((a: string, v: SelectOptionType, i: number) => `${a}${i ? ',' : ''} ${v.label}`, '');
};

const ParallelField = ({ label = 'Параллель обучения', name, editMode, required, parent }: ParallelFieldProps) => {
  const [options, setOptions] = useState<SelectOptionType[]>([]);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const parallelList = parent ? `${parent}.${name}List` : `${name}List`;

  const fetchOptions = useCallback(async () => {
    const parallels = await dictionariesApi.getParallels(userProfile.organizationId);
    setOptions(parallels);
  }, [userProfile.organizationId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const [fieldValue, , helpersValue] = useField(parallelList);

  return (
    <Field label={label} editMode={editMode} required={required} value={getParallelLabel(fieldValue?.value)}>
      <FormikFormGroup required label="" name={parallelList}>
        <FormikSelect
          size="small"
          isSearchable={false}
          options={options}
          name={parallelList}
          placeholder="Выберите..."
          isMulti
          defaultValue={fieldValue.value}
          selectedValue={(options: any) => {
            helpersValue.setValue(options || []);
          }}
          maxMenuHeight={parent ? undefined : 150}
        />
      </FormikFormGroup>
    </Field>
  );
};

export default ParallelField;
