import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { array as arrayYup, number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { useHistory } from 'react-router-dom';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import { routes } from '../../../config/constants';
import PageHeading from '../../../components/header/page-heading';
import { ServiceClassData, TeacherType } from '../../../types/service-class';
import { ServiceClassContext } from '../service-class';
import ServiceField from '../components/fields/service';
import SimpleInput from '../../../components/fields/simple-input';
import DateField from '../components/fields/date';
import PupilInGroupField from '../components/fields/pupil-in-group';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import serviceClassApi from '../../../lib/api/service-class';
import TeachersField from '../components/fields/teachers';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { childrenAssociation, serviceClassName, validationCheckDate } from '../../../lib/utils/validation';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import {
  editPeriodDataToTransformRequestData,
  extendedPeriods,
  maxCommentLength,
  scheduleDataCreateService,
} from '../../../lib/utils/service-class';
import EditPeriodSchedule, { PeriodType } from '../../../components/schedule/period';
import SavePanel from '../../../components/save-panel';

type Props = {
  showSearchServiceBtn: boolean;
};

const ServiceClassDonmCreate = ({ showSearchServiceBtn }: Props) => {
  const [loadBtn, setLoadBtn] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [scheduleIsError, setScheduleIsError] = useState(false);
  const { serviceClassData, startDateSchedulePeriod, endDateSchedulePeriod } = useContext(ServiceClassContext);

  const [schedule, setSchedule] = useState<PeriodType[]>(scheduleDataCreateService('days'));
  const [keyDateField, setKeyDateField] = useState(0);

  const historyRouter = useHistory();

  const submitForm = async (values: any) => {
    const v = {
      ...values,
      schedule: {
        serviceClassId: serviceClassData.serviceId,
        educationTypeId: serviceClassData.educationTypeId,
        list: editPeriodDataToTransformRequestData(values.schedule),
      },
    };
    try {
      values.teacher.list = values.teachers || [];
      const id = await serviceClassApi.createServiceClass(v);
      history.push(generateLink(routes.serviceClass, { id }));
    } catch {}
    setLoadBtn(false);
    setKeyDateField(Math.random());
  };

  const initialValues: ServiceClassData = {
    ...serviceClassData,
    trainStartDate: '',
    trainEndDate: '',
  };

  const initialErrors = useInitialErrors(serviceClassData, getValidationSchema());

  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={() => historyRouter.push(generateLink(routes.serviceClasses, {}))}
        title="Новая группа"
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Группы обучения', link: routes.serviceClasses },
          { title: 'Группа обучения' },
        ]}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema}
        initialErrors={initialErrors}
        // Из-за компонента расписания пришлось вынести onSubmit.
        onSubmit={() => {}}
      >
        {(formikProps: FormikProps<ServiceClassData>) => {
          const { isValid, values } = formikProps;
          return (
            <form>
              <Push size={12} />
              <Panel title={() => <>Сведения о группе</>}>
                <div className="container">
                  <div className="table-data">
                    <ServiceField
                      name="service"
                      label="Детское объединение "
                      showSearchServiceBtn={showSearchServiceBtn}
                      editMode
                      required
                      educationType={serviceClassData.educationTypeId}
                      isNewServiceClass
                    />

                    <SimpleInput name="name" editMode required label="Наименование группы" placeholder="Введите..." />

                    <DateField
                      key={keyDateField}
                      label="Даты занятий"
                      name="train"
                      editMode
                      required
                      isNewServiceClass
                    />

                    <PupilInGroupField name="included" label="Человек в группе" editMode isNewServiceClass />

                    <SimpleTextArea
                      name="description"
                      label="Комментарий"
                      editMode
                      placeholder="Введите..."
                      maxLength={maxCommentLength}
                    />
                  </div>
                </div>
              </Panel>
              <Push size={12} />
              <EditPeriodSchedule
                key={`${String(startDateSchedulePeriod)} ${String(endDateSchedulePeriod)}`}
                dateStartProps={startDateSchedulePeriod}
                dateEndProps={endDateSchedulePeriod}
                title="Время проведения занятий"
                submit={scheduleSubmit}
                submitError={() => {
                  setScheduleSubmit(false);
                }}
                submitSuccess={(schedule) => {
                  setSchedule(schedule);
                  if (scheduleSubmit) {
                    submitForm({
                      ...values,
                      schedule,
                    });
                  }
                  setScheduleSubmit(false);
                }}
                extendedPeriods={extendedPeriods(schedule, startDateSchedulePeriod, endDateSchedulePeriod)}
                setCurrentErrorSchedule={setScheduleIsError}
              />
              <Push size={12} />
              <Panel
                title={() => (
                  <>
                    Ответственные преподаватели <span className="color-gray-dark">{' \u00A0'}</span>
                  </>
                )}
              >
                <TeachersField editMode name="teachers" hasCreateServiceClass />
              </Panel>

              <SavePanel
                controls={
                  <>
                    <Button primary border handleClick={() => history.push(routes.serviceClasses)} label="Отмена" />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      handleClick={() => {
                        setLoadBtn(true);
                        setScheduleSubmit(true);
                      }}
                      disabled={!isValid || scheduleIsError}
                      load={loadBtn}
                      primary
                      label="Создать группу"
                    />
                  </>
                }
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ServiceClassDonmCreate;

const getValidationSchema = () => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      serviceId: childrenAssociation,
      name: serviceClassName,
      trainStartDate: validationCheckDate(
        'Выберите дату начала',
        { start: 'trainStartDate', end: 'trainEndDate' },
        'Дата начала обучения больше даты окончания обучения',
        'start'
      ),
      trainEndDate: validationCheckDate(
        'Выберите дату окончания',
        { start: 'trainStartDate', end: 'trainEndDate' },
        'Дата окончания обучения меньше даты начала обучения',
        'end'
      ),
      capacity: numberYup()
        .min(1, 'Предельное число обучающихся в группе не может быть отрицательным числом или нулем')
        .nullable(),
      teachers: arrayYup()
        .min(1, 'Добавьте хот бы одного преподователя')
        .test('test teachers', '', (value) => {
          return (value as TeacherType[]).some((v) => v.isSupervisor);
        })
        .of(
          objectYup().shape({
            name: stringYup().required('Введите ФИО преподавателя'),
          })
        ),
    },
    [depsDate]
  );
};
