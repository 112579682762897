import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Textarea, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconClose } from '../../assets/images/icons/close.svg';
import { ReactComponent as IconCheck } from '../../assets/images/icons/check.svg';
import DropDown from '../../components/drop-down';
import { ReactComponent as IconDots } from '../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../assets/images/icons/remove.svg';

type Props = {
  comment: string;
  editMode: boolean;
  setRemovedId: () => void;
  submitHandler: () => void;
  setEditMode: (v: boolean) => void;
  setComment: (value: string) => void;
};

const FavoriteEdit: React.FC<Props> = ({ comment, submitHandler, setComment, setRemovedId, editMode, setEditMode }) => {
  const [commentLocal, setCommentLocal] = useState<string>(comment);

  const send = () => {
    if (commentLocal.trim() !== '') {
      submitHandler();
    }
  };

  return (
    <div className={classNames('favorite-table-comment', editMode && 'favorite-table-comment--edit')}>
      <div className="favorite-table-comment__body">
        {editMode ? (
          <div className="favorite-table-comment__textarea">
            <Textarea
              rows={2}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value.trim());
                setCommentLocal(e.target.value.trim());
              }}
              resizeDisabled
            />
          </div>
        ) : (
          comment
        )}
      </div>
      <div className="favorite-table-comment__controls">
        {editMode ? (
          <>
            <Push size={24} orientation="horizontal" />
            <Button
              iconLeft={() => <IconClose />}
              border
              size="small"
              handleClick={() => {
                setEditMode(false);
              }}
            />
            <Push size={8} orientation="horizontal" />
            <Button
              border
              success
              size="small"
              handleClick={send}
              iconLeft={() => <IconCheck />}
              disabled={!commentLocal}
            />
          </>
        ) : (
          <>
            <Push size={16} orientation="horizontal" />
            <DropDown
              component={() => (
                <span className="favorite-drop-down-btn">
                  <IconDots />
                </span>
              )}
            >
              <div className="drop-down-panel">
                <div className="drop-down-panel__list">
                  <button type="button" onClick={() => setEditMode(true)} className="drop-down-panel__list-item">
                    <IconEdit />
                    Редактировать комментарий
                  </button>
                  <button type="button" onClick={setRemovedId} className="drop-down-panel__list-item">
                    <IconRemove />
                    Удалить из избранного
                  </button>
                </div>
              </div>
            </DropDown>
          </>
        )}
      </div>
    </div>
  );
};

export default FavoriteEdit;
