import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { number as numberYup, object as objectYup, string as stringYup } from 'yup';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { ServiceClassContext } from '../../service-class';
import { ServiceClassData } from '../../../../types/service-class';
import SimpleInput from '../../../../components/fields/simple-input';
import DateField from '../fields/date';
import PupilInGroupField from '../fields/pupil-in-group';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import ServiceField from '../fields/service';
import serviceClassApi from '../../../../lib/api/service-class';
import { validationCheckDate } from '../../../../lib/utils/validation';
import { EducationTypeEnum } from '../../../../types/education-type';
import { maxCommentLength } from '../../../../lib/utils/service-class';
import { serviceClassStatusEnum } from '../../../../mock-data/service-class-status';
import WarningScheduleModal from '../modals/warning-schedule-modal';
import SavePanel from '../../../../components/save-panel';

type Props = {
  setEditModeParent: React.Dispatch<React.SetStateAction<string | null>>;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServiceClassGroup: React.FC<Props> = ({ setEditModeParent, editMode, setEditMode }) => {
  const {
    setStartDateSchedulePeriod,
    setEndDateSchedulePeriod,
    serviceClassData,
    updateServiceClass,
    accessEditServiceClass,
    setCurrentValuesGroupDetails,
  } = useContext(ServiceClassContext);
  const initialErrors = useInitialErrors(serviceClassData, getValidationSchema());

  const showSearchServiceBtn =
    serviceClassData.educationTypeId !== EducationTypeEnum.VirtualAssistantEducation &&
    !serviceClassData.hasActiveLearners;

  const [loading, setLoading] = useState(false);
  const [showWarningScheduleModal, setShowWarningScheduleModal] = useState<boolean>(false);

  const submitForm = async (data: ServiceClassData) => {
    if (data?.trainStartDate && data?.trainEndDate) {
      if (
        serviceClassData.trainStartDate !== data.trainStartDate ||
        serviceClassData.trainEndDate !== data.trainEndDate
      ) {
        setShowWarningScheduleModal(true);
      } else {
        setLoading(true);
        try {
          await serviceClassApi.updateServiceClass(data);
          updateServiceClass();
          setEditModeParent && setEditModeParent(null);
          setEditMode && setEditMode(false);
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (serviceClassData.trainStartDate && serviceClassData.trainEndDate) {
      setStartDateSchedulePeriod(new Date(serviceClassData.trainStartDate));
      setEndDateSchedulePeriod(new Date(serviceClassData.trainEndDate));
    }
  }, [serviceClassData, setEndDateSchedulePeriod, setStartDateSchedulePeriod]);

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        if (setCurrentValuesGroupDetails) {
          setCurrentValuesGroupDetails(values);
        }
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={serviceClassData as ServiceClassData}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<ServiceClassData>) => {
        const { isValid, handleSubmit, resetForm, values } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              title={() => <>Сведения о группе</>}
              headingControl={() => {
                return !editMode &&
                  serviceClassData.serviceClassStatusId !== serviceClassStatusEnum.Archive &&
                  accessEditServiceClass ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent && setEditModeParent('group');
                      setEditMode && setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <ServiceField
                    name="service"
                    label="Детское объединение "
                    showSearchServiceBtn={showSearchServiceBtn}
                    editMode={editMode}
                    required
                    educationType={serviceClassData.educationTypeId}
                    disabled={!!values.included || serviceClassData.hasActiveLearners}
                  />

                  <SimpleInput required name="name" editMode={editMode} label="Наименование группы" />

                  <SimpleInput name="code" required label="Код группы" />

                  <DateField
                    required
                    name="train"
                    editMode={editMode}
                    label="Даты занятий"
                    disabled={!!values.included}
                  />

                  <PupilInGroupField name="included" editMode={editMode} label="Человек в группе" />

                  <SimpleTextArea
                    name="description"
                    label="Комментарий"
                    editMode={editMode}
                    placeholder="Введите..."
                    maxLength={maxCommentLength}
                  />
                </div>
              </div>
            </Panel>

            {editMode && setEditModeParent && (
              <SavePanel
                controls={
                  <>
                    <Button
                      onClick={() => {
                        setEditModeParent && setEditModeParent(null);
                        setEditMode && setEditMode(false);
                        resetForm();
                      }}
                      border
                      primary
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button handleClick={handleSubmit} load={loading} disabled={!isValid} primary label="Сохранить" />
                  </>
                }
              />
            )}
            <WarningScheduleModal
              setEditMode={setEditMode}
              show={showWarningScheduleModal}
              setEditModeParent={setEditModeParent}
              onCloseHandler={() => setShowWarningScheduleModal(false)}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceClassGroup;

const getValidationSchema = () => {
  const depsDate: [string, string] = ['trainStartDate', 'trainEndDate'];

  return objectYup().shape(
    {
      serviceId: stringYup().required('Выберите детское объединение').nullable(),
      name: stringYup().required('Введите наименование группы').nullable(),
      trainStartDate: validationCheckDate(
        'Выберите дату начала',
        { start: 'trainStartDate', end: 'trainEndDate' },
        'Дата начала обучения больше даты окончания обучения',
        'start'
      ),
      trainEndDate: validationCheckDate(
        'Выберите дату окончания',
        { start: 'trainStartDate', end: 'trainEndDate' },
        'Дата окончания обучения меньше даты начала обучения',
        'end'
      ),
      capacity: numberYup()
        .min(1, 'Предельное число обучающихся в группе не может быть отрицательным числом или нулем')
        .nullable(),
    },
    [depsDate]
  );
};
