import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, IconArrowDown, SelectOptionType, Push } from '@mosru/esz_uikit';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { EventUrl } from '../../../mock-data/event';
import { historyState } from '../../../mock-data/history-state';
import { accessAction, accessObject, AuthorizationData } from '../../../types/authorization-data';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { hasAccessObjectAny, redirect, replaceHistoryState } from '../../../lib/utils';
import Search from './search';
import { ListMenuAddGroup, SearchInitialFormData } from '../../../types/service';
import PageHeading from '../../../components/header/page-heading';
import ServiceChildList from './components/service-child-list/list';
import { RegistryTypeEnum } from '../../../mock-data/registry-type-enum';
import { installInitialData } from './helpers';
import { dictionaryService, listMenuAddGroup, selectServiceItemCallback } from '../../../lib/utils/service';

import { dictionariesApi } from '../../../lib/api/dictionaries';
import DropDown from '../../../components/drop-down';
import { routes } from '../../../config/constants';
import { EducationTypeEnum } from '../../../types/education-type';
import WarningModal from './components/modals/warning-modal';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import useQuery from '../../../hooks/use-query';
import { sendReachGoal } from '../../../lib/metrica';

export type ServiceRegistryContextType = {
  visitTypeData: SelectOptionType[];
  educationTypeData: SelectOptionType[];
  vedomstvoData: SelectOptionType[];
  statusesData: SelectOptionType[];
  isAdmin: boolean;
  userProfile: AuthorizationData;
  type: RegistryTypeEnum;
  periodOfStudyData: SelectOptionType[];
  educationSumm: number;
  trainingGroupStatuses: SelectOptionType[];
  initialData: SearchInitialFormData;
  currentEducationType: EducationTypeEnum[];
  accessVa: boolean;
  edTypeByField: EducationTypeEnum;
  setEdTypeByField: (edType: EducationTypeEnum) => void;
};

export const ServiceRegistryContext = React.createContext<ServiceRegistryContextType>({} as ServiceRegistryContextType);

type Props = {
  type: RegistryTypeEnum;
  isAdmin: boolean;
  educationSumm: number;
  userProfile: AuthorizationData;
  educationTypeData: SelectOptionType[];
};

export const InitialData: React.FC<Props> = ({ type, educationTypeData, educationSumm, userProfile, isAdmin }) => {
  const currentEducationType = educationTypeData.map((item) => item.value) as EducationTypeEnum[];

  const [visitTypeData, setVisitTypeData] = useState<SelectOptionType[]>([]);
  const [statusesData, setStatusesData] = useState<SelectOptionType[]>([]);
  const [periodOfStudyData, setPeriodOfStudyData] = useState<SelectOptionType[]>([]);
  const [trainingGroupStatuses, setTrainingGroupStatuses] = useState<SelectOptionType[]>([]);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const [dayCarePublicatedStatus, setDayCarePublicatedStatus] = useState<boolean>(false);

  const [edTypeByField, setEdTypeByField] = useState<EducationTypeEnum>(EducationTypeEnum.None);

  const eventId = Number(useQuery().get(EventUrl.EventId));

  const initialData = useMemo(() => {
    return {
      ...installInitialData(
        isAdmin,
        userProfile,
        educationTypeData,
        visitTypeData,
        statusesData,
        type,
        educationSumm,
        eventId
      ),
    };
  }, [educationSumm, educationTypeData, eventId, isAdmin, statusesData, type, userProfile, visitTypeData]);

  const [search, setSearch] = useState<SearchInitialFormData>(window.history.state[historyState.search] || initialData);
  const dictionary = dictionaryService(type, currentEducationType);

  const vedomstvoData: SelectOptionType[] = useGetDataDepartment(false);

  const [currentSearchData, setCurrentSearchData] = useState<SearchInitialFormData>(
    window.history.state[historyState.search] || initialData
  );

  const searchPlaceholder = isAdmin ? 'Поиск...' : dictionary.placeholder;

  const listMenu = listMenuAddGroup(userProfile, isAdmin, type);

  useClearHistoryState();

  // Загрузка "Видов посещения"
  useEffect(() => {
    const fetchVisitTypes = async () => {
      const isVirtualAssistantEducation = educationSumm === EducationTypeEnum.VirtualAssistantEducation;
      const visitTypeData = await dictionariesApi.getVisitTypes(
        false,
        isVirtualAssistantEducation ? EducationTypeEnum.VirtualAssistantEducation : undefined
      );

      setVisitTypeData(
        isVirtualAssistantEducation ? [...visitTypeData] : [{ label: 'Все', value: 0 }, ...visitTypeData]
      );
    };

    fetchVisitTypes();
  }, [educationSumm]);

  // Загрузка "Статусов детских объединений"
  useEffect(() => {
    const fetchStatuses = async () => {
      const statusesData = await dictionariesApi.getStatuses(educationSumm);
      setStatusesData([
        selectServiceItemCallback ? { label: 'Опубликовано на Mos.ru', value: 2 } : { label: 'Актуальные', value: 0 },
        ...statusesData,
      ]);
    };

    fetchStatuses();
  }, [educationSumm]);

  // Загрузка "Статусов плана приема"
  useEffect(() => {
    const fetchTrainingGroupStatuses = async () => {
      const trainingGroupStatusData = await dictionariesApi.getTrainingGroupStatus();
      setTrainingGroupStatuses([{ label: 'Все', value: 0 }, ...trainingGroupStatusData]);
    };

    fetchTrainingGroupStatuses();
  }, []);

  // Загрузка "Периодов обучения"
  useEffect(() => {
    const fetchPeriodOfStudy = async () => {
      const periodOfStudyData = await dictionariesApi.getYearOfTrainings();
      setPeriodOfStudyData([{ label: 'Все', value: null }, ...periodOfStudyData]);
    };

    fetchPeriodOfStudy();
  }, []);

  // Проверка возможности пользователя создавать услуги ГПД
  useEffect(() => {
    const isDayCare = hasAccessObjectAny(userProfile, [accessObject.ServiceDayCare], accessAction.Create);

    const fetchGetDayCareAbility = async () => {
      const res = await serviceTemplateApi.getDayCareAbility();
      if (!res) {
        setDayCarePublicatedStatus(true);
      } else {
        setDayCarePublicatedStatus(false);
      }
    };

    if (isDayCare) {
      fetchGetDayCareAbility();
    }
  }, [userProfile]);

  const decoratorDictionary = useCallback(
    (value: any, key: string): string => {
      const data = {
        [EducationTypeEnum.ChildrenEducation]: RegistryTypeEnum.serviceChildType,
        [EducationTypeEnum.ChildrenNonDogmEducation]: RegistryTypeEnum.serviceChildType,
        [EducationTypeEnum.DayCareCentersEducation]: RegistryTypeEnum.serviceGpdType,
        [EducationTypeEnum.VirtualAssistantEducation]: RegistryTypeEnum.serviceEducationType,
      };

      if (isAdmin && type === RegistryTypeEnum.serviceChildType && edTypeByField && edTypeByField !== educationSumm) {
        const dictionary = dictionaryService(data[edTypeByField], [edTypeByField]);

        return dictionary[key];
      } else {
        return value;
      }
    },
    [edTypeByField, educationSumm, isAdmin, type]
  );

  const serviceRegistryData: ServiceRegistryContextType = useMemo(
    () => ({
      visitTypeData,
      educationTypeData,
      vedomstvoData,
      statusesData,
      periodOfStudyData,
      isAdmin,
      accessVa: hasAccessObjectAny(userProfile, [accessObject.ServiceVA], accessAction.ViewRegistry), // цифровой репетитор
      userProfile,
      type,
      educationSumm,
      trainingGroupStatuses,
      initialData,
      currentEducationType,
      edTypeByField,
      setEdTypeByField,
    }),
    [
      visitTypeData,
      educationTypeData,
      vedomstvoData,
      statusesData,
      periodOfStudyData,
      isAdmin,
      userProfile,
      type,
      educationSumm,
      trainingGroupStatuses,
      initialData,
      currentEducationType,
      edTypeByField,
      setEdTypeByField,
    ]
  );

  const handleChangeSearch = (newValue: SearchInitialFormData) => {
    const newSearch = {
      ...newValue,
      educationTypeId: newValue.educationTypeId ?? educationSumm,
    };
    setSearch(newSearch);
    replaceHistoryState({ [historyState.search]: newSearch });
  };

  const handleCreateService = (educationType?: EducationTypeEnum, link?: string) => {
    sendReachGoal(`onClick-service-create-${educationType}`);
    if (educationType) {
      if (educationType === EducationTypeEnum.DayCareCentersEducation && !dayCarePublicatedStatus) {
        setShowWarningModal(true);
      } else {
        link && redirect(link);
      }
    } else if (!dayCarePublicatedStatus) {
      setShowWarningModal(true);
    } else {
      listMenu && redirect(listMenu[0].link);
    }
  };

  return (
    <ServiceRegistryContext.Provider value={serviceRegistryData}>
      <PageHeading
        title={selectServiceItemCallback ? dictionary.subTitle : decoratorDictionary(dictionary.title, 'title')}
        sections={
          selectServiceItemCallback
            ? []
            : [{ title: 'Главная', link: routes.main }, { title: decoratorDictionary(dictionary.title, 'title') }]
        }
        controlsTopRight={
          selectServiceItemCallback
            ? null
            : !!listMenu.length &&
              (listMenu.length > 1 ? (
                <DropDown
                  component={() => <Button primary label="Добавить" iconRight={() => <IconArrowDown />} size="small" />}
                >
                  <div className="drop-down-panel">
                    {listMenu.map((item: ListMenuAddGroup) => {
                      return (
                        <button
                          key={item.label}
                          type="button"
                          onClick={() => handleCreateService(item.educationType, item.link)}
                          className="drop-down-panel__list-item"
                        >
                          {item.label}
                        </button>
                      );
                    })}
                  </div>
                </DropDown>
              ) : (
                dictionary.addBtn && (
                  <Button
                    primary
                    label={dictionary.addBtn}
                    size="small"
                    onClick={() => handleCreateService(listMenu[0].educationType, listMenu[0].link)}
                  />
                )
              ))
        }
      />
      <Push size={12} />
      <Search
        submitForm={handleChangeSearch}
        searchPlaceholder={searchPlaceholder}
        setCurrentSearchData={setCurrentSearchData}
        initialValues={search}
      />
      <Push size={12} />
      <ServiceChildList
        search={search}
        title={decoratorDictionary(dictionary.title, 'title')}
        loadingTitle={dictionary.loading}
        enable={RegistryTypeEnum.serviceEducationType === type || RegistryTypeEnum.serviceEducationProgramType === type}
        currentSearchData={currentSearchData}
      />
      <WarningModal show={showWarningModal} onCloseHandler={() => setShowWarningModal(false)} />
    </ServiceRegistryContext.Provider>
  );
};
