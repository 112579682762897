import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Tooltip, Loader, Push, AsyncTable as Table } from '@mosru/esz_uikit';
import { generateLink, getServiceEditLink, hasAccessObjectAny, sliceText } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { ReactComponent as IconInfoDanger } from '../../../../assets/images/icons/info-danger.svg';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { textColorByStatusId } from '../../../../lib/utils/service-class';
import { styleVars } from '../../../../theme/vars';
import { IdName, ServiceClassPupil } from '../../../../types/service-class';
import { GetCheckboxColumn } from '../../components/table/chekbox-column';
import { ServiceClassTableContext } from '../../utils';
import { ServiceClassContext } from '../../service-class';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import Certificate from '../../components/modals/certificate';
import { EducationTypeEnum } from '../../../../types/education-type';
import Module from '../../../../components/module-modal';

const TableGroupComposition = () => {
  const { loading, setSelected, setOpenDetails, pageNum, pageSize, totalCount, setPageNum, learners, selected } =
    useContext(ServiceClassTableContext);

  const { isArchiveGroup } = useContext(ServiceClassContext);
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const hasEditAccess = hasAccessObjectAny(userProfile, [accessObject.ServiceClassOP], accessAction.Edit);

  const [openCertificatePopup, setOpenCertificatePopup] = useState<boolean>(false);
  const [groupMembers, setGroupMembers] = useState<ServiceClassPupil | null>(null);
  const [moduleId, setModuleId] = useState<number>();
  const [service, setService] = useState<{ id: number; name: string }>();

  return (
    <>
      {!loading ? (
        <Table
          itemsAll={totalCount}
          pageNum={pageNum}
          setPageNumber={setPageNum}
          data={learners}
          pageSize={pageSize}
          hideSort
          overflow
          columns={[
            GetCheckboxColumn(selected, learners, setSelected, setOpenDetails, isArchiveGroup || !hasEditAccess),
            {
              dataIndex: 'count',
              title: '№',
              render: (item: any) => item.count,
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'ФИО',
              render: (item: any) => (
                <>
                  <div className="icon-group">
                    <div
                      className="icon-group__text cursor-pointer text-decoration-underline"
                      onClick={() => {
                        window.open(
                          generateLink(routes.learner, {
                            id: item.pupilId,
                          }),
                          '_blank'
                        );
                      }}
                    >
                      {item.pupilName}
                    </div>
                    <div className="icon-group__icon">
                      {(item.isMoreThanEighteen || item.isLearnEnds) && (
                        <Tooltip
                          component={() =>
                            item.isMoreThanEighteen || (item.isMoreThanEighteen && item.isLearnEnds) ? (
                              <IconInfoDanger />
                            ) : (
                              <IconInfo />
                            )
                          }
                          position="bottom"
                          text={
                            <div>
                              {item.isMoreThanEighteen && <div>Уже исполнилось 18 лет</div>}
                              {item.isLearnEnds && <div>Истек срок обучения</div>}
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                  <Push size={4} />
                  <span className={textColorByStatusId[item.statusId]}>{item.statusName}</span>
                  {item?.subclassList.length > 0 && (
                    <>
                      <Push size={17} />
                      <div className="flex wrap">
                        {item?.subclassList?.map((subItem: any) => {
                          return (
                            <div style={{ marginBottom: '4px', marginRight: '4px' }}>
                              <Badge color="default" textColor={styleVars.textColor} text={subItem.name} />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              ),
            },
            {
              dataIndex: 'program',
              title: 'Образовательная программа',
              width: '20%',
              render: (item: any) => (
                <div
                  className="text-decoration-underline cursor-pointer"
                  onClick={() =>
                    window.open(getServiceEditLink(EducationTypeEnum.ProfessionalEducation, item.serviceId), '_blank')
                  }
                >
                  {sliceText(item.classificatorEKUName)}, {sliceText(item.servicePeriod)}
                </div>
              ),
            },
            {
              dataIndex: 'module',
              title: 'Модуль',
              render: (item: any) => (
                <span
                  role="presentation"
                  className="text-decoration-underline cursor-pointer brand-link overflow-wrap-anywhere"
                  onClick={() => {
                    setModuleId(item.programmModuleId);
                    setService({ id: item.serviceId, name: `${item.classificatorEKUName}, ${item.servicePeriod}` });
                  }}
                >
                  {item.programmModuleName}
                </span>
              ),
              width: '25%',
            },
            {
              dataIndex: 'documents',
              title: 'Документы об обучении',
              render: (item: any) => (
                <span
                  role="presentation"
                  onClick={() => {
                    setOpenCertificatePopup(true);
                    setGroupMembers(item);
                  }}
                  className="brand-link cursor-pointer"
                  dangerouslySetInnerHTML={{
                    __html: (item.completionDocumentList as IdName[])
                      ?.map((v: IdName) => sliceText(v.name, 50))
                      .join('<br>'),
                  }}
                />
              ),
              width: '20%',
            },
          ]}
        />
      ) : (
        <div className="loader-container">
          <Loader roller small />
        </div>
      )}
      {groupMembers && (
        <Certificate
          open={openCertificatePopup}
          close={() => setOpenCertificatePopup(false)}
          readOnly
          groupMembers={groupMembers}
        />
      )}
      <Module
        show={!!moduleId}
        hideModal={() => setModuleId(undefined)}
        moduleId={moduleId}
        serviceId={service?.id}
        serviceName={service?.name || ''}
      />
    </>
  );
};

export default TableGroupComposition;
