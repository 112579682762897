import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, Panel, Loader, Push } from '@mosru/esz_uikit';
import FIO from '../components/fields/fio';
import BirthDate from '../components/fields/birthdate';
import Gender from '../../../components/fields/gender';
import Document from '../components/fields/document';
import DocumentNumber from '../components/fields/document-number';
import MoscowRegistration from '../components/fields/moscow-registration';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { PersonRequestData } from '../../../types/requests';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import {
  birthRecordIssued,
  dateOfIssue,
  documentNumber,
  documentSeries,
  firstName,
  lastName,
  middleName,
  stringRequired,
} from '../../../lib/utils/validation';
import { submitPersonRequestData } from '../utils';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import RegistryOffice from '../components/fields/registry-office';

type Props = {
  requestChild: PersonRequestData;
  contingentLinkTypeId: ContingentLinkTypeEnum;
  setEditModeParent: (value: string | null) => void;
  editable?: boolean;
  updateRequest: () => void;
};

const ChildForm: React.FC<Props> = ({
  requestChild,
  contingentLinkTypeId,
  setEditModeParent,
  editable,
  updateRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialErrors = useInitialErrors(requestChild, childFormValidationSchema);

  const submitForm = useCallback(
    async (values: PersonRequestData) => {
      await submitPersonRequestData(values, true, setLoading, setEditMode, setEditModeParent, updateRequest);
    },
    [setEditModeParent, updateRequest]
  );

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <Formik
      onSubmit={submitForm}
      enableReinitialize
      initialValues={requestChild}
      validationSchema={childFormValidationSchema}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<PersonRequestData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm, values } = formikProps;
        const isBirthRecord = values.documentTypeId === DocumentTypeEnum.BirthRecord;

        return (
          <form>
            <Push size={12} />
            <Panel
              title={() => 'Информация о ребенке'}
              headingControl={() => {
                return !editMode && editable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('child');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <FIO
                    label="ФИО учащегося"
                    contingent={contingentLinkTypeId}
                    editMode={editMode}
                    disabled={editMode}
                  />
                  <BirthDate name="dateOfBirth" editMode={editMode} disabled={editMode} />
                  <Gender name="sex" editMode={editMode} disabled={editMode} />
                  <Document name="documentType" dependentFields editMode={editMode} disabled={editMode} />
                  <DocumentNumber editMode={editMode} disabled={editMode} calendarPosition="top-end" />
                  {isBirthRecord ? <RegistryOffice disabled={editMode} name="issued" editMode={editMode} /> : null}
                  <MoscowRegistration editMode={editMode} name="moscowRegistration" />
                </div>
              </div>
            </Panel>
            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ChildForm;

const childFormValidationSchema = objectYup().shape({
  firstName,
  lastName,
  middleName,
  documentTypeId: stringRequired,
  dateOfIssue: dateOfIssue(true),
  series: documentSeries(true),
  number: documentNumber(true),
  issued: birthRecordIssued,
});
