import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Infobox, Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconDown } from '../../../../assets/images/icons/down.svg';
import { ReactComponent as IconSign } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { ReactComponent as IconCheck } from '../../../../assets/images/icons/checkmark.svg';
import { ReactComponent as IconFile } from '../../../../assets/images/icons/file.svg';
import { ReactComponent as IconEye } from '../../../../assets/images/icons/eye.svg';
import { ScheduleData, TrainingGroupData } from '../../../../types/service';
import { apply, buildScheduleDisplayName } from '../../../../lib/utils/service';
import { formatDate, formatTime } from '../../../../lib/utils/date';
import history from '../../../../history';
import { routes } from '../../../../config/constants';
import { generateLink, generateUrlSearchString, hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { accessAction, accessObject, generalAccess } from '../../../../types/authorization-data';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import SignModal from '../../../../components/sign-modal';
import { accessVedomst } from '../../../../mock-data/access-enum';
import SimpleModal from '../../components/modals/simple-modal';
import { EducationTypeEnum } from '../../../../types/education-type';

type Props = {
  editable?: boolean;
  serviceId: number;
  tg: TrainingGroupData | undefined;
  getTrainingGroup: () => Promise<void>;
};

const TestsList: React.FC<Props> = ({ editable, serviceId, tg, getTrainingGroup }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const [signSchedule, setSignSchedule] = useState<ScheduleData | undefined>();
  const [removedId, setRemovedId] = useState<number | undefined>();

  const canEdit = useCallback(
    (schedule: ScheduleData) =>
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(
          userProfile,
          [accessObject.ServiceArtHouse, accessObject.ServiceSport],
          accessAction.Edit
        )) &&
      schedule.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive,
    [userProfile]
  );

  const canRemove = useCallback(
    (schedule: ScheduleData): boolean =>
      canEdit(schedule) &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      schedule.requestTotalCount === 0,
    [canEdit]
  );
  const canPublicate = useCallback(
    (schedule: ScheduleData): boolean =>
      canEdit(schedule) &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      (!hasGeneralAccess(userProfile, generalAccess.UseSign) || schedule.isRetro),
    [canEdit, userProfile]
  );

  const canSign = useCallback(
    (schedule: ScheduleData): boolean =>
      canEdit(schedule) &&
      !schedule.isRetro &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      hasGeneralAccess(userProfile, generalAccess.UseSign),
    [userProfile, canEdit]
  );

  const canOnlyView = (schedule: ScheduleData): boolean =>
    schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Archive;

  const canCreateRequest = (schedule: ScheduleData): boolean => {
    return (
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(
          userProfile,
          [accessObject.RequestArtHouse, accessObject.RequestSport],
          accessAction.Edit
        )) &&
      schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
      schedule.isAccessible &&
      (userProfile.vedomstvoId !== accessVedomst.Dsit || new Date().getMonth() > 9)
    );
  };

  const updateStatus = useCallback(
    async (scheduleId: number, statusId: TrainingGroupStatusEnum) => {
      await serviceTemplateApi.updateTrainingScheduleStatus({
        serviceId,
        educationTypeId: tg?.educationTypeId,
        scheduleOfTimetableId: scheduleId,
        trainingGroupStatusId: statusId,
      });
      getTrainingGroup();
    },
    [tg, serviceId, getTrainingGroup]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Расписание вступительных испытаний{' '}
            <span className="color-gray-dark">
              {' \u00A0'} {tg?.scheduleList?.length ?? 0}
            </span>
          </>
        )}
        headingControl={() => {
          return (
            editable &&
            (tg?.id ?? 0) > 0 && (
              <DropDown
                component={() => (
                  <span className="drop-down-btn-round">
                    <Button primary border iconRight={() => <IconDown />} label="Добавить" size="small" />
                  </span>
                )}
              >
                <div className="drop-down-panel">
                  <div className="drop-down-panel__list">
                    <button
                      type="button"
                      onClick={() => {
                        history.push(
                          `${generateLink(routes.entranceTests, {
                            serviceId,
                            tgId: tg?.id ?? 0,
                            id: 0,
                          })}?isRetro=0`
                        );
                      }}
                      className="drop-down-panel__list-item"
                    >
                      Вступительные испытания
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        history.push(
                          `${generateLink(routes.entranceTests, { serviceId, tgId: tg?.id ?? 0, id: 0 })}?isRetro=1`
                        );
                      }}
                      className="drop-down-panel__list-item"
                    >
                      Вступительные испытания без публикации на Mos.ru
                    </button>
                  </div>
                </div>
              </DropDown>
            )
          );
        }}
      >
        {tg?.scheduleList ? (
          <Table
            overflow
            data={tg.scheduleList}
            hideSort
            columns={[
              {
                dataIndex: 'date',
                title: 'Проведение испытаний',
                render: (item: any) => <span className="color-primary">{buildScheduleDisplayName(item)}</span>,
                width: '14%',
              },
              {
                dataIndex: 'address',
                title: 'Место проведения',
                render: (item: any) => item.placeOfTesting,
                width: '20%',
              },
              {
                dataIndex: 'amount',
                title: 'Размер группы / Подано / Черновиков /  Отказано',
                render: (item: any) => {
                  return (
                    <>
                      {item.volume}/
                      {item.requestTotalCount && (
                        <Link
                          to={{
                            pathname: routes.requests,
                            search: generateUrlSearchString({
                              serviceId: item.serviceId,
                              scheduleId: item.id,
                              educationTypeId: tg.educationTypeId ?? EducationTypeEnum.ArtHouseEducation,
                            }),
                          }}
                          target="_blank"
                          className="brand-link"
                        >
                          {item.requestTotalCount}
                        </Link>
                      )}
                      /
                      {item.requestDraftCount && (
                        <Link
                          to={{
                            pathname: routes.requests,
                            search: generateUrlSearchString({
                              serviceId: item.serviceId,
                              scheduleId: item.id,
                              requestStatusId: RequestStatusEnum.Draft,
                              educationTypeId: tg.educationTypeId ?? EducationTypeEnum.ArtHouseEducation,
                            }),
                          }}
                          target="_blank"
                          className="brand-link"
                        >
                          {item.requestDraftCount}
                        </Link>
                      )}
                      /
                      {item.requestDeclineCount && (
                        <Link
                          to={{
                            pathname: routes.requests,
                            search: generateUrlSearchString({
                              serviceId: item.serviceId,
                              scheduleId: item.id,
                              requestStatusId: RequestStatusEnum.IncludeDeclined,
                              educationTypeId: tg.educationTypeId ?? EducationTypeEnum.ArtHouseEducation,
                            }),
                          }}
                          target="_blank"
                          className="brand-link"
                        >
                          {item.requestDeclineCount}
                        </Link>
                      )}
                    </>
                  );
                },
                width: '16%',
              },
              {
                dataIndex: 'teacher',
                title: 'Преподаватель',
                render: (item: any) => item.teacher,
                width: '13%',
              },
              {
                dataIndex: 'periodMosru',
                title: 'Период приема заявлений на mos.ru',
                render: (item: any) => {
                  return (
                    <span>
                      {item.isRetro
                        ? 'Без публикации на Mos.ru'
                        : `c ${formatDate(item.requestStart || '')} ${
                            formatTime(item.requestTimeStart) || ''
                          } по ${formatDate(item.requestEnd || '')} ${formatTime(item.requestTimeEnd) || ''}`}
                    </span>
                  );
                },
              },
              {
                dataIndex: 'status',
                title: 'Статус расписания',
                render: (item: any) => item.trainingGroupStatusName,
                width: '13%',
              },
              {
                dataIndex: '',
                title: '',
                render: (item: any) => (
                  <DropDown
                    component={() => (
                      <span className="drop-down-btn-round">
                        <IconDots />
                      </span>
                    )}
                  >
                    <div className="drop-down-panel">
                      <div className="drop-down-panel__list">
                        {canPublicate(item) && (
                          <button
                            type="button"
                            onClick={async () => await updateStatus(item.id, TrainingGroupStatusEnum.Signed)}
                            className="drop-down-panel__list-item"
                          >
                            <IconCheck />
                            {item.isRetro ? 'Подтвердить' : 'Опубликовать'}
                          </button>
                        )}
                        {canSign(item) && (
                          <button
                            type="button"
                            onClick={() => {
                              setSignSchedule(item);
                            }}
                            className="drop-down-panel__list-item"
                          >
                            <IconSign />
                            Подписать
                          </button>
                        )}
                        {canCreateRequest(item) && (
                          <button
                            type="button"
                            onClick={async () => {
                              await apply(item.id);
                            }}
                            className="drop-down-panel__list-item"
                          >
                            <IconFile />
                            Подать заявление
                          </button>
                        )}
                        {canEdit(item) && (
                          <button
                            type="button"
                            onClick={() => {
                              history.push(
                                `${generateLink(routes.entranceTests, {
                                  serviceId,
                                  tgId: tg?.id ?? 0,
                                  id: item.id,
                                })}?isRetro=${item.isRetro ? 1 : 0}`
                              );
                            }}
                            className="drop-down-panel__list-item"
                          >
                            <IconEdit />
                            Редактировать
                          </button>
                        )}
                        {canRemove(item) && (
                          <button
                            type="button"
                            onClick={async () => {
                              setRemovedId(item.id);
                            }}
                            className="drop-down-panel__list-item"
                          >
                            <IconRemove />
                            Удалить
                          </button>
                        )}
                        {canOnlyView(item) && (
                          <button
                            type="button"
                            onClick={() => {
                              history.push(
                                `${generateLink(routes.entranceTests, {
                                  serviceId,
                                  tgId: tg?.id ?? 0,
                                  id: item.id,
                                })}?isRetro=${item.isRetro ? 1 : 0}`
                              );
                            }}
                            className="drop-down-panel__list-item"
                          >
                            <IconEye />
                            Просмотреть
                          </button>
                        )}
                      </div>
                    </div>
                  </DropDown>
                ),
                width: '40px',
              },
            ]}
          />
        ) : (
          <div className="container">
            <Infobox
              fullWidth
              color="warning"
              text="Расписание вступительных испытаний станет доступным для ввода после первого сохранения. "
            />
            <Push size={24} />
          </div>
        )}
      </Panel>
      <SimpleModal
        show={!!removedId}
        onClose={() => {
          setRemovedId(undefined);
        }}
        handleSubmit={async () => {
          if (removedId) {
            await serviceTemplateApi.deleteTrainingSchedule(serviceId, removedId);
            getTrainingGroup();
          }
        }}
        title="Удаление расписания вступительных испытаний"
        description="Вы уверены, что хотите удалить выбранное расписание вступительных испытаний? Обратите внимание, что вся информация о выбранном расписании вступительных испытаний будет утеряна."
        labelOk="Да, удалить"
      />
      <SignModal
        show={!!signSchedule}
        onCloseHandle={() => {
          setSignSchedule(undefined);
        }}
        ids={[{ id: 0 }]}
        getDataForSignHandle={async () => JSON.stringify(signSchedule)}
        setSignedDataHandle={async (id, sign) => {
          if (signSchedule) {
            try {
              await serviceTemplateApi.signTrainingSchedule({
                serviceId,
                educationTypeId: tg?.educationTypeId,
                signedDocument: sign,
                scheduleOfTimetableId: signSchedule.id,
              });
              setSignSchedule(undefined);
              getTrainingGroup();
            } catch {}
          }
        }}
      />
    </>
  );
};

export default TestsList;
