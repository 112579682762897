import { useSelector } from 'react-redux';
import { Button, Input, IconCheckmark, IconClear, Push } from '@mosru/esz_uikit';
import DropDown from '../../components/drop-down';
import { ReactComponent as IconDots } from '../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../assets/images/icons/remove.svg';
import { ReactComponent as IconRestore } from '../../assets/images/icons/restore.svg';
import { hasAccessObjectAny } from '../../lib/utils';
import { accessAction, accessObject } from '../../mock-data/access-enum';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';

type Props = {
  code: string;
  setCode: (value: string) => void;
  setRemovedId: () => void;
  editMode: boolean;
  isArchive: boolean;
  setEditMode: (v: boolean) => void;
  submitHandler: () => void;
  restoreHandler: () => void;
};

const Edit: React.FC<Props> = ({
  code,
  submitHandler,
  setCode,
  setRemovedId,
  editMode,
  setEditMode,
  isArchive,
  restoreHandler,
}) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  return (
    <div className="disciplines-table-comment">
      <div className="disciplines-table-comment__body">
        {editMode ? (
          <Input value={code} onChange={(event) => setCode(event.target.value)} placeholder="0" />
        ) : isArchive ? (
          <span className="color-gray-dark">{code}</span>
        ) : (
          code
        )}
      </div>
      <div className="disciplines-table-comment__controls">
        {editMode ? (
          <>
            <Button
              iconLeft={() => <IconClear />}
              border
              size="small"
              handleClick={() => {
                setEditMode(false);
              }}
            />
            <Push size={12} orientation="horizontal" />
            <Button
              iconLeft={() => <IconCheckmark />}
              border
              success
              size="small"
              handleClick={() => {
                submitHandler();
              }}
            />
          </>
        ) : (
          <>
            {(hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Archive) ||
              (hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Edit) && !isArchive)) && (
              <DropDown
                component={() => (
                  <span className="drop-down-btn-round">
                    <IconDots />
                  </span>
                )}
              >
                <div className="drop-down-panel">
                  <div className="drop-down-panel__list">
                    {isArchive ? (
                      hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Archive) && (
                        <button type="button" onClick={restoreHandler} className="drop-down-panel__list-item">
                          <IconRestore />
                          Вернуть из архива
                        </button>
                      )
                    ) : (
                      <>
                        {' '}
                        {hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Edit) && (
                          <button
                            type="button"
                            onClick={() => setEditMode(true)}
                            className="drop-down-panel__list-item"
                          >
                            <IconEdit />
                            Редактировать
                          </button>
                        )}
                        {hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Archive) && (
                          <button type="button" onClick={setRemovedId} className="drop-down-panel__list-item">
                            <IconRemove />
                            Удалить
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </DropDown>
            )}
            <Push size={24} orientation="horizontal" />
          </>
        )}
      </div>
    </div>
  );
};

export default Edit;
