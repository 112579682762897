import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Panel, Toggle, Loader, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconPlus } from '../../../assets/images/icons/plus-color.svg';
import { notificationRequest } from '../../../lib/utils/learners';
import { ReactComponent as IconDots } from '../../../assets/images/icons/3dots.svg';
import { Learner } from '../../../types/learners';
import DropDown from '../../../components/drop-down';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/remove.svg';
import { ReactComponent as IconMesh } from '../../../assets/images/icons/mesh.svg';
import { ReactComponent as IconReaccept } from '../../../assets/images/icons/reaccept.svg';
import learnerApi from '../../../lib/api/learner';
import RestoreDocumentModal from '../../../components/restore-document-modal';
import DocumentModal from './document-modal';
import { getServiceEditLink, hasAccessObjectAny, hasGeneralAccess } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';
import { formatDate } from '../../../lib/utils/date';
import { OperationResultEnum } from '../../../mock-data/operation-result-enum';
import { EducationTypeEnum } from '../../../types/education-type';
import Module from '../../../components/module-modal';

type Props = {
  pupilId: string;
  access: { [key: string]: boolean } | undefined;
};

type Document = { id: number | string; moscowDocument: boolean };

const certificate = 7;

const LearnerDocumentation: React.FC<Props> = ({ pupilId, access }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdminView = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminView), [userProfile]);
  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);
  const hasCreateDocument = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.CompletionDocument], accessAction.Create),
    [userProfile]
  );

  const [documents, setDocuments] = useState<Learner.Document[]>([]);

  const [tableData, setTableData] = useState<Learner.Document[]>(documents);
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [moduleId, setModuleId] = useState<number>();
  const [service, setService] = useState<{ id: number; name: string }>();
  const [restoreDocumentId, setRestoreDocumentId] = useState<number | string>(0);
  const [document, setDocument] = useState<Document>({
    id: 0,
    moscowDocument: true,
  });

  const newDocument = String(document.id) === 'new' || String(document.id) === '0';

  const accessEdit = useCallback(
    (docTypeId: number, state: boolean) => {
      return state || (hasCreateDocument && docTypeId === certificate) || isAdminEdit || isAdminView;
    },
    [isAdminEdit, isAdminView, hasCreateDocument]
  );

  const getDocuments = useCallback(() => {
    (async () => {
      const document = await learnerApi.getDocuments({ id: pupilId });
      setDocuments(document);
      setLoading(false);
    })();
  }, [pupilId]);

  const deleteDocumentHandler = (id: string) => {
    (async () => {
      await learnerApi.deleteDocument(pupilId, { docId: id });
      notificationRequest(OperationResultEnum.Success, 'Успешно удалено');
      getDocuments();
    })();
  };

  const restoreDocumentHandler = (id: number) => {
    (async () => {
      await learnerApi.patchDocumentRestore(pupilId, id);
      notificationRequest(OperationResultEnum.Success, 'Успешно восстановлено');
      getDocuments();
      setRestoreDocumentId(0);
    })();
  };

  const filterArchive = useCallback(() => {
    if (showArchive) {
      const filter = documents.filter((item: Learner.Document) => item.state);

      setTableData((prevState) => [...prevState, ...filter]);
    } else {
      const onlyActive = documents.filter((item: Learner.Document) => !item.state);

      setTableData(onlyActive);
    }
  }, [showArchive, documents]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments, document]);

  useEffect(() => {
    const onlyActive = documents.filter((item: Learner.Document) => !item.state);

    setTableData(onlyActive);
  }, [documents]);

  useEffect(() => {
    filterArchive();
  }, [showArchive, filterArchive]);

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <div className="flex learner-document-header">
            Документы о прохождении обучения
            <span className="color-gray-dark">
              {' \u00A0'} {tableData.length ? tableData.length : ''}
            </span>
            <Push orientation="horizontal" size={25} />
            {isAdminEdit && (
              <div className="learner-document-toggle-table">
                <Toggle size="xsmall" labelId="id" onChange={(value) => setShowArchive(value)} />
                <Push orientation="horizontal" size={10} />
                <span className="learner-document-toggle-table-label">Показывать удаленные</span>
              </div>
            )}
          </div>
        )}
        headingControl={() =>
          (isAdminEdit || hasCreateDocument) && (
            <button
              type="button"
              className="icon-group"
              onClick={() => {
                setDocument((prevState: Document) => ({
                  ...prevState,
                  id: 'new',
                }));
              }}
            >
              <span className="icon-group__icon">
                <IconPlus />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          )
        }
      >
        {loading ? (
          <div className="flex justify-center flex-column">
            <Push orientation="vertical" size={20} />
            <Loader roller small />
            <Push orientation="vertical" size={20} />
          </div>
        ) : tableData.length ? (
          <Table
            overflow
            data={tableData}
            pageSize={Number.MAX_SAFE_INTEGER}
            columns={[
              {
                dataIndex: 'issueDate',
                title: 'Дата ',
                render: (item: any) => (
                  <span className={item.state ? 'table-bad-visible-field' : ''}>{formatDate(item.issueDate)}</span>
                ),
                hiddenSort: true,
              },
              {
                dataIndex: 'documentTypeName',
                title: 'Тип',
                render: (item: any) => (
                  <span className={item.state ? 'table-bad-visible-field' : ''}>{item.documentTypeName}</span>
                ),
                hiddenSort: true,
              },
              {
                dataIndex: 'docNumber',
                title: 'Номер',
                render: (item: any) => (
                  <div
                    role="presentation"
                    className="brand-link cursor-pointer"
                    onClick={() => {
                      if (!item.state) {
                        setDocument({
                          moscowDocument: item.moscowDocument,
                          id: item.id,
                        });
                      }
                    }}
                  >
                    {item.docNumber}
                  </div>
                ),
                hiddenSort: true,
              },
              {
                dataIndex: 'programName',
                title: 'Программа',
                render: (item: any) => (
                  <Link
                    className="brand-link"
                    to={getServiceEditLink(EducationTypeEnum.ProfessionalEducation, item.unionCatalogServicesId)}
                    target="_blank"
                  >
                    {item.programName}
                  </Link>
                ),
                hiddenSort: true,
              },
              {
                dataIndex: 'moduleName',
                title: 'Модуль',
                render: (item: any) => {
                  return item.moduleName ? (
                    <div
                      role="presentation"
                      className="brand-link cursor-pointer overflow-wrap-anywhere"
                      onClick={() => {
                        setModuleId(item.programmModuleId);
                        setService({ id: item.unionCatalogServicesId, name: item.programName });
                      }}
                    >
                      {item.moduleName}
                    </div>
                  ) : (
                    '—'
                  );
                },

                hiddenSort: true,
              },
              {
                dataIndex: 'profName',
                title: 'Профессия',
                render: (item: any) => (
                  <span className={item.state ? 'table-bad-visible-field' : ''}>{item.profName}</span>
                ),
                hiddenSort: true,
              },
              {
                dataIndex: 'organizationName',
                title: 'Организация',
                render: (item: any) => (
                  <span className={item.state ? 'table-bad-visible-field' : ''}>{item.organizationName}</span>
                ),
                hiddenSort: true,
              },

              {
                dataIndex: 'more',
                title: '',
                render: (item: any) =>
                  accessEdit(item.documentTypeId, item.state) && (
                    <div className="disciplines-table-comment right">
                      <div className="disciplines-table-comment__controls">
                        <DropDown
                          component={() => (
                            <span className="drop-down-btn-round">
                              <IconDots />
                            </span>
                          )}
                        >
                          <div className="drop-down-panel">
                            <div className="drop-down-panel__list">
                              {item.state ? (
                                <button
                                  type="button"
                                  onClick={() => setRestoreDocumentId(item.id)}
                                  className="drop-down-panel__list-item"
                                >
                                  <IconMesh />
                                  Восстановить
                                </button>
                              ) : (
                                <>
                                  {hasCreateDocument &&
                                    item.documentTypeId === certificate &&
                                    !(isAdminEdit || isAdminView) && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setDocument({
                                            moscowDocument: item.moscowDocument,
                                            id: item.id,
                                          });
                                        }}
                                        className="drop-down-panel__list-item"
                                      >
                                        <IconReaccept />
                                        Перезачесть
                                      </button>
                                    )}

                                  {(isAdminView || isAdminEdit) && (
                                    <>
                                      {' '}
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setDocument({
                                            moscowDocument: item.moscowDocument,
                                            id: item.id,
                                          });
                                        }}
                                        className="drop-down-panel__list-item"
                                      >
                                        <IconEdit />
                                        Редактировать
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => deleteDocumentHandler(item.id)}
                                        className="drop-down-panel__list-item"
                                      >
                                        <IconRemove />
                                        Удалить
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </DropDown>
                      </div>
                    </div>
                  ),
                hiddenSort: true,
              },
            ]}
          />
        ) : (
          <div className="learner-no-data">Сведения о документах отсутствуют</div>
        )}
      </Panel>

      <RestoreDocumentModal
        show={!!restoreDocumentId}
        onCloseHandle={() => setRestoreDocumentId(0)}
        onRestoreHandler={() => restoreDocumentHandler(Number(restoreDocumentId))}
        title="Восстановление документа"
        description="Вы действительно хотите восстановить выбранный документ?"
      />

      <DocumentModal
        docId={String(document.id)}
        pupilId={pupilId}
        show={!!document.id}
        newDocument={newDocument}
        moscowDocument={document.moscowDocument}
        onCloseHandler={() => {
          setDocument((prevState: Document) => ({
            ...prevState,
            id: 0,
          }));
        }}
        title="Документ о прохождении обучения"
      />
      <Module
        show={!!moduleId}
        hideModal={() => setModuleId(undefined)}
        moduleId={moduleId}
        serviceId={service?.id}
        serviceName={service?.name || ''}
      />
    </>
  );
};

export default LearnerDocumentation;
