import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Heading, ModalPanel, Modal, notify, Toggle, Push } from '@mosru/esz_uikit';
import PageHeading from '../../components/header/page-heading';
import { emailRegexp } from '../../lib/utils/validation';
import useInitialErrors from '../../hooks/formik-initial-errors';
import FormikFormGroup from '../../components/formik/formik-form-group';
import FormikInput from '../../components/formik/formik-input';
import { useChangeSubscribeMutation, useGetUserEventTypesQuery } from '../../store/event';
import PasswordStrength from '../password-rules/strength';
import { RoomPasswordType, RoomUserType } from '../password-rules/types/room';
import { PasswordRequirementType } from '../password-rules/types/password';
import { getRequirements, validatePassword } from '../password-rules/utils';
import PasswordRequirement from '../password-rules/requirement';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { authApi } from '../../lib/api';
import { defaultPasswordSettings, PasswordSettings } from '../../types/auth';
import { copyTextToClipboard } from '../../lib/utils';
import { getUserProfile } from '../../redux/ducks/user-profile';

type Props = {
  requirements?: PasswordRequirementType[];
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const initialPasswordData = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserProfile,
    },
    dispatch
  );

const enhance = connect(mapStateToProps, mapDispatchToProps);

const Room = ({ requirements, getUserProfile }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [passwordSettings, setPasswordSettings] = useState<PasswordSettings>(defaultPasswordSettings);
  const [showPassword, setShowPassword] = useState(false);

  const { data: notifyValues = [], isFetching } = useGetUserEventTypesQuery();
  const [changeSubscribe, { isLoading }] = useChangeSubscribeMutation();

  useEffect(() => {
    const fetchData = async () => {
      const passwordSettings = await authApi.getSettings();
      setPasswordSettings(passwordSettings);
    };
    fetchData();
  }, []);

  const userData = useMemo(
    () => ({
      login: userProfile.login,
      email: userProfile.email,
      fullName: userProfile.fullName,
      snils: userProfile.snils,
    }),
    [userProfile]
  );

  const initialErrors = useInitialErrors(userData, getUserValidationSchema());
  const [showModal, setShowModal] = useState(false);

  const passwordRules = useMemo(() => {
    return requirements ?? getRequirements(passwordSettings);
  }, [requirements, passwordSettings]);

  return (
    <>
      <PageHeading buttonBack title="Управление аккаунтом" />

      <Formik
        onSubmit={async (v, { resetForm, setSubmitting }) => {
          await authApi.updateProfile(v);
          setSubmitting(false);
          resetForm({ values: v });
          await getUserProfile();
        }}
        enableReinitialize
        initialValues={userData}
        validationSchema={getUserValidationSchema()}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<RoomUserType>) => {
          const { handleSubmit, values, isSubmitting, isValid, dirty } = formikProps;
          return (
            <form>
              <Push size={12} />
              <div className="room-panel">
                <div className="container">
                  <Heading label="Данные пользователя и пароль" />
                  <Push size={16} />
                  <div className="table-data">
                    <div className="table-data__item">
                      <FormikFormGroup name="fullName.lastName" label="Фамилия" horizontal required>
                        <FormikInput name="fullName.lastName" placeholder="Введите..." size="small" />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup name="fullName.firstName" label="Имя" horizontal required>
                        <FormikInput name="fullName.firstName" placeholder="Введите..." size="small" />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup name="fullName.middleName" label="Отчество" horizontal>
                        <FormikInput name="fullName.middleName" placeholder="Введите..." size="small" />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup name="snils" label="СНИЛС" horizontal required>
                        <FormikInput
                          name="snils"
                          mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
                          placeholder="XXX-XXX-XXX XX"
                          size="small"
                          disabled={!!userData?.snils}
                        />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup name="email" label="Email" horizontal required>
                        <FormikInput name="email" placeholder="Введите..." value={values.email} size="small" />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item">
                      <FormikFormGroup name="login" label="Логин" horizontal required>
                        <FormikInput name="login" placeholder="Введите..." value={values.login} size="small" disabled />
                      </FormikFormGroup>
                    </div>
                    <div className="table-data__item table-data__group">
                      <div className="table-data__label table-data__label--main">Пароль</div>
                      <div className="table-data__body">
                        <Button onClick={() => setShowModal(true)} primary label="Изменить пароль" border />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Push size={12} />
              <div className="room-panel">
                <div className="container">
                  <Heading label="Настройка уведомлений" />
                </div>

                <Push size={4} />
                <table className="room-notify-table">
                  <tbody>
                    {notifyValues?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <label htmlFor={item.id.toString()} className="room-notify-table__label">
                              {item.shortName}
                            </label>
                          </td>
                          <td>
                            <Toggle
                              labelId={item.id.toString()}
                              disabled={isLoading || isFetching}
                              onChange={async () => {
                                await changeSubscribe(
                                  notifyValues.reduce((acc: number[], notifyValue) => {
                                    if (
                                      (notifyValue.id === item.id && notifyValue.isSubscribeTo) ||
                                      (notifyValue.id !== item.id && !notifyValue.isSubscribeTo)
                                    ) {
                                      acc.push(notifyValue.id);
                                    }
                                    return acc;
                                  }, [])
                                );
                              }}
                              checked={item.isSubscribeTo}
                              size="small"
                            />
                          </td>
                          <td className="room-notify-table__checkbox">
                            <div>{item.isSubscribeTo ? 'вкл' : 'выкл'}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!dirty || !isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Formik
          onSubmit={async (values) => {
            try {
              const response = await authApi.changePassword({
                ...values,
                login: userProfile.login,
                changeByExpired: true,
                remember: true,
              });
              if (response.errors?.length) {
                notify.danger({
                  data: {
                    label: response.errors.join(' '),
                    icon: true,
                    close: true,
                  },
                });
              } else {
                setShowModal(false);
              }
            } catch (err: any) {
              console.error('error authApi changePassword', err.response);
            }
          }}
          enableReinitialize
          initialValues={initialPasswordData}
          validationSchema={getPasswordValidationSchema(passwordSettings)}
        >
          {(formikProps: FormikProps<RoomPasswordType>) => {
            const { handleSubmit, values, isSubmitting, isValid, setValues, dirty } = formikProps;
            return (
              <form>
                <ModalPanel
                  modalTitle="Изменение пароля"
                  onClose={() => setShowModal(false)}
                  controls={() => (
                    <>
                      <Button onClick={() => setShowModal(false)} label="Отмена" border primary size="small" />
                      <Push orientation="horizontal" size={12} />
                      <Button
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!dirty || !isValid}
                        label="Изменить пароль"
                        primary
                        size="small"
                      />
                    </>
                  )}
                  renderComponent={() => (
                    <>
                      <FormikFormGroup name="oldPassword" label="Текущий пароль" showErrorImmediately>
                        <FormikInput name="oldPassword" password placeholder="Введите пароль..." size="small" />
                      </FormikFormGroup>
                      <Push size={20} />
                      <div className="room-form-group">
                        <button
                          type="button"
                          onClick={async () => {
                            const generatedString = await authApi.generatePassword();
                            copyTextToClipboard(generatedString);
                            setShowPassword(true);
                            notify.success({
                              data: {
                                label: 'Надежный пароль успешно сгенерирован и скопирован в буфер обмена',
                                icon: true,
                                close: true,
                              },
                            });
                            setValues({ ...values, newPassword: generatedString, confirmNewPassword: generatedString });
                          }}
                          className="room-form-group__btn"
                        >
                          Сгенерировать надежный пароль
                        </button>
                        <FormikFormGroup name="newPassword" label="Новый пароль">
                          <FormikInput
                            name="newPassword"
                            showPassword={showPassword}
                            password
                            showClearIndicator={!!values.newPassword}
                            placeholder="Введите пароль..."
                            size="small"
                          />
                        </FormikFormGroup>
                      </div>
                      <Push size={8} />
                      <PasswordStrength password={values.newPassword} passwordRules={passwordRules} />
                      <Push size={12} />
                      <PasswordRequirement password={values.newPassword} passwordRules={passwordRules} />
                      <Push size={20} />
                      <FormikFormGroup name="confirmNewPassword" label="Повторите пароль">
                        <FormikInput
                          name="confirmNewPassword"
                          password
                          placeholder="Введите пароль еще раз..."
                          size="small"
                          showClearIndicator={!!values.confirmNewPassword}
                          showPassword={showPassword}
                        />
                      </FormikFormGroup>
                    </>
                  )}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const getUserValidationSchema = () =>
  objectYup().shape({
    fullName: objectYup().shape({
      firstName: stringYup().nullable().required('Введите имя'),
      lastName: stringYup().nullable().required('Введите фамилию'),
    }),
    login: stringYup().required('Введите логин'),
    email: stringYup()
      .matches(emailRegexp, {
        message: 'Указан неверный адрес электронной почты',
      })
      .required('Введите адрес электронной почты')
      .nullable(),
    snils: stringYup()
      .matches(/^[0-9]{3,3}-[0-9]{3,3}-[0-9]{3,3} [0-9]{2,2}$/, 'СНИЛС должен содержать 11 цифр')
      .nullable()
      .test('test_snils', 'СНИЛС указан некорректно', (snils: string | null | undefined) => {
        return validateSnils(snils);
      })
      .required('СНИЛС должен содержать 11 цифр'),
  });

function validateSnils(snilsVal: string | null | undefined): boolean {
  if (!snilsVal) {
    return true;
  } // Можно оставлять пустым

  snilsVal = snilsVal.replace(/[^\d]/g, '');
  if (snilsVal.length !== 11) {
    return false;
  }

  if (!checkRepeatedDigits(snilsVal)) {
    return false;
  }

  const crc = parseInt(snilsVal.slice(9), 10);
  snilsVal = `${snilsVal}`;
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(snilsVal[i]) * (9 - i);
  }
  if (sum > 101) {
    sum %= 101;
  }
  if (sum === 100 || sum === 101) {
    sum = 0;
  }
  return sum === crc;
}

function checkRepeatedDigits(snilsVal: string): boolean {
  let count = 1;
  for (let i = 0; i < 9; i++) {
    if (i === 0) {
      continue;
    }
    count = snilsVal[i] === snilsVal[i - 1] ? count + 1 : 1;
    if (count >= 3) {
      return false;
    }
  }
  return true;
}

const getPasswordValidationSchema = (settings: PasswordSettings) =>
  objectYup().shape({
    oldPassword: stringYup()
      .when('newPassword', {
        is: (newPassword: string) => !!newPassword,
        then: stringYup().required('Введите текущий пароль').nullable(),
      })
      .nullable(),
    newPassword: stringYup()
      .nullable()
      .test('password-requirement', '', (value) => validatePassword(value, settings)),
    confirmNewPassword: stringYup()
      .nullable()
      .test('passwords-match', 'Пароль и подтверждение пароля не совпадают', function (value) {
        return this.parent.newPassword === value;
      }),
  });

export default enhance(Room);
