import React, { useContext, useState } from 'react';
import { Button, Infobox, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../../assets/images/icons/edit-color.svg';
import { ServiceContext } from '../../../index';
import EditAddressDocuments from './edit';
import ViewAddressDocuments from './view';
import { ItemWorkData } from '../../../../../types/service';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { ServiceStatusEnum } from '../../../../../mock-data/service-status-enum';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  textForEmpty?: string;
};

const AddressDocumentsTemplate: React.FC<Props> = ({ setEditModeParent, textForEmpty }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [itemWorkList, setItemWorkList] = useState<ItemWorkData[]>([]);

  const counter =
    setEditModeParent && editMode
      ? itemWorkList.filter(({ isSelected }) => isSelected).length
      : serviceData?.itemWork.list.filter(({ isSelected }) => isSelected).length || null;

  const submitForm = async (values: ItemWorkData[]) => {
    try {
      if (serviceData) {
        await serviceTemplateApi.updateServiceItemWorkList(serviceData.id, {
          educationTypeId: serviceData.educationTypeId,
          list: values.filter((v) => v.isSelected),
          serviceId: serviceData.id,
        });
        await updateService();
        setEditModeParent && setEditModeParent(null);
        setEditMode(false);
      }
    } catch {}
  };

  const checkEditable =
    !editMode && setEditModeParent && serviceData.serviceStatusId === ServiceStatusEnum.Draft && accessPanelEdit;

  const handleCancel = () => {
    setEditModeParent && setEditModeParent(null);
    setEditMode(false);
    setItemWorkList([]);
  };

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Адреса приема документов{' '}
            <span className="color-gray-dark">
              {' \u00A0'} {counter}
            </span>
          </>
        )}
        headingControl={() => {
          return checkEditable ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent && setEditModeParent('address-documents');
                setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconEdit />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        {setEditModeParent ? (
          editMode ? (
            <EditAddressDocuments
              itemWorkList={itemWorkList}
              setItemWorkList={setItemWorkList}
              serviceId={serviceData.id}
            />
          ) : checkEditable && textForEmpty && !counter ? (
            <div className="container">
              <Infobox fullWidth color="danger" text={textForEmpty} />
              <Push size={24} />
            </div>
          ) : (
            <ViewAddressDocuments />
          )
        ) : (
          <div className="container">
            <Infobox
              fullWidth
              color="warning"
              text="Адреса приема документов станут доступными для ввода после первого сохранения."
            />
            <Push size={24} />
          </div>
        )}
      </Panel>

      {editMode && setEditModeParent && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button onClick={handleCancel} border primary label="Отмена" />
                <Push size={12} orientation="horizontal" />
                <Button
                  onClick={() => {
                    submitForm(itemWorkList);
                  }}
                  primary
                  disabled={itemWorkList.every(({ isSelected }) => !isSelected)}
                  label="Сохранить"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressDocumentsTemplate;
