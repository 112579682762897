import React, { useEffect, useMemo, useState, Fragment, useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup, number, ValidationError } from 'yup';
import moment from 'moment';
import {
  Button,
  Checkbox,
  ModalPanel,
  Modal,
  Infobox,
  SelectOptionType,
  Tooltip,
  Loader,
  Push,
} from '@mosru/esz_uikit';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { serviceTemplateApi } from '../../../../../lib/api/service-template';
import { classificatorEKULimitApi } from '../../../../../lib/api/classificator-EKU-limit';
import { checkPrevYearOfTraining } from '../../../../../lib/utils/year-of-training';
import { TypeFinancingEnum } from '../../../../../mock-data/type-financing-enum';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import FormikCheckboxGroup from '../../../../../components/formik/formik-checkbox-group';
import lookupApi from '../../../../../lib/api/lookup';
import { VOLUME_FIELD_WIDTH } from '../../../../organizations/organization/services/utils';
import DateField from '../../fields/add-plan/date';
import TimeField from '../../fields/add-plan/time';
import { ScheduleData, ServiceData, TrainingGroupData, TrainingStageData } from '../../../../../types/service';
import { validationCheckDate } from '../../../../../lib/utils/validation';
import { calcLearnEnd } from '../../../../../lib/utils/service';
import { ReactComponent as IconInfo } from '../../../../../assets/images/icons/info.svg';

type Props = {
  show: boolean;
  planData: TrainingGroupData | undefined;
  serviceData: ServiceData;
  onClose: () => void;
  postSubmit: (plan?: TrainingGroupData) => void;
};

type YearOfTrainingList = Pick<SelectOptionType, 'label' | 'value'> & {
  limit: number;
};

const separator = (
  <div className="flex items-center flex-none table-row-item-height">
    <Push size={8} orientation="horizontal" />
    —
    <Push size={8} orientation="horizontal" />
  </div>
);

const PreparationPlanModal: React.FC<Props> = ({ show, planData, serviceData, onClose, postSubmit }) => {
  const [initialValues, setInitialValues] = useState<TrainingGroupData>({} as TrainingGroupData);
  const [formKey, setFormKey] = useState<number>(0);
  const [volumeKey, setVolumeKey] = useState<number>(Math.random());
  const [periodOptions, setPeriodOptions] = useState<SelectOptionType[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [limitVolume, setLimitVolume] = useState(0);
  const [yearList, setYearList] = useState<YearOfTrainingList[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const initialErrors = useInitialErrors(initialValues, getValidationSchema(serviceData, limitVolume, selectedYear));

  const modalTitle = `${planData?.id ? 'Изменить' : 'Добавить'} план приема`;
  const submitLabel = planData?.id ? 'Сохранить' : 'Добавить';

  const showErrorImmediately = (planData?.id ?? 0) > 0;

  const hasLimitedVolume =
    !!serviceData.financing && serviceData.financing.typeFinancingId !== TypeFinancingEnum.Payment;
  const hasFreeVolume = serviceData.financing?.typeFinancingId === TypeFinancingEnum.Free;
  const hasRequest =
    !!initialValues && initialValues.scheduleList?.length > 0 && initialValues.scheduleList[0].requestTotalCount > 0;

  const getTeacherList = async (query: string): Promise<SelectOptionType[]> => {
    return await lookupApi.getTeachers(query, serviceData.info.organizationId);
  };

  const getYearOfTrainingsList = useCallback(async () => {
    const getCurrentYearOfTraining = async () => {
      try {
        return await dictionariesApi.getCurrentYearOfTrainings();
      } catch (e) {
        return undefined;
      }
    };

    try {
      setLoadingData(true);
      const currentYear = await getCurrentYearOfTraining();
      const data = await classificatorEKULimitApi.getBudgetPlaces({
        educationTypeId: serviceData.educationTypeId,
        organizationId: serviceData.info.organizationId,
        classificatorEKUId: serviceData.info.classificatorEKUId,
      });

      const filterData = planData
        ? data
        : data.filter(
            ({ yearOfTrainingName }) =>
              !currentYear?.name ||
              (!!yearOfTrainingName && !checkPrevYearOfTraining(yearOfTrainingName, currentYear.name))
          );

      setYearList(
        filterData.map((item) => ({
          label: item.yearOfTrainingName || '',
          value: item.yearOfTrainingId,
          limit: item.limitVolume ? item.limitVolume - (item.volume ?? 0) : 0,
        }))
      );
    } catch (error) {
      setYearList([]);
    } finally {
      setLoadingData(false);
    }
  }, [serviceData, planData]);

  const submitTrainingGroup = async (plan: TrainingGroupData | undefined) => {
    if (plan) {
      if (plan.volume && plan.volume > 9999999999) {
        plan.volume = undefined;
      }

      if (!plan.id || plan.id === 0) {
        if (plan.scheduleList) {
          plan.scheduleList[0].trainingGroupId = 0;
        }

        await serviceTemplateApi.createTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      } else {
        await serviceTemplateApi.updateTrainingGroup(serviceData.info.serviceId, {
          ...plan,
          educationTypeId: serviceData.educationTypeId,
        });
      }
    }
    postSubmit(plan);
  };

  useEffect(() => {
    if (show) {
      getYearOfTrainingsList();
    } else {
      setSelectedYear(undefined);
      setLimitVolume(0);
      setYearList([]);
    }
  }, [show, getYearOfTrainingsList]);

  useEffect(() => {
    if (serviceData.stage?.list?.length > 0) {
      setPeriodOptions(
        serviceData.stage.list.map((s) => {
          return { label: s.name, value: s.id || 0 };
        })
      );
    }
  }, [serviceData.stage]);

  useEffect(() => {
    if (!loadingData) {
      if (planData) {
        setInitialValues({
          ...planData,
          volume:
            planData.volume === null && serviceData.financing.typeFinancingId !== TypeFinancingEnum.Payment
              ? 0
              : planData.volume,
          stageList:
            !planData.yearOfTrainingId &&
            (!planData.scheduleList?.length || !planData.scheduleList[0].requestTotalCount)
              ? planData.stageList.map((stage) => ({
                  ...stage,
                  dateStart: '',
                  dateEnd: '',
                }))
              : planData.stageList,
          yearOfTrainingId: planData.yearOfTrainingId || undefined,
        });
        setFormKey(Math.random());
      } else {
        setInitialValues({
          scheduleList: [
            {
              requestStart: moment(new Date()).format('YYYY-MM-DDT00:00:00'),
              requestTimeStart: '08:00',
              requestTimeEnd: '22:00',
            } as ScheduleData,
          ],
        } as TrainingGroupData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData, loadingData]);

  useEffect(() => {
    if (yearList.length && initialValues.yearOfTrainingId) {
      const initialYear = yearList.find(({ value }) => value === initialValues.yearOfTrainingId);
      const year = initialYear?.label && initialYear.label.split('-')[0];
      const currentVolume = initialValues.volume || 0;

      setSelectedYear(year ? parseInt(year) : undefined);
      setLimitVolume(initialYear ? initialYear.limit + currentVolume : 0);
    }
  }, [initialValues.yearOfTrainingId, initialValues.volume, yearList]);

  const minVolumeValue = useMemo(() => {
    const min = initialValues?.scheduleList?.length > 0 ? initialValues.scheduleList[0].requestsPositiveCount : 1;
    return min > 0 ? min : 1;
  }, [initialValues]);

  const showMinimalVolumeTooltip = useMemo(
    () => !hasFreeVolume && (planData?.id ?? 0) > 0,
    [planData?.id, hasFreeVolume]
  );

  const handleSelectYear = (value: SelectOptionType | null) => {
    const option = value as YearOfTrainingList;
    const year = option?.label && option.label.split('-')[0];
    const currentVolume = planData && option?.value === planData.yearOfTrainingId ? planData.volume || 0 : 0;

    setSelectedYear(year ? parseInt(year) : undefined);
    setLimitVolume(option ? option.limit + currentVolume : 0);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Formik
        key={formKey}
        initialErrors={initialErrors}
        validationSchema={getValidationSchema(serviceData, limitVolume, selectedYear)}
        onSubmit={submitTrainingGroup}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<TrainingGroupData>) => {
          const { handleSubmit, isSubmitting, isValid, values, setFieldValue } = formikProps;

          const getControls = () => (
            <>
              <Button label="Отмена" border primary size="small" onClick={onClose} />
              <Push orientation="horizontal" size={12} />
              <Button
                label={submitLabel}
                disabled={loadingData || !isValid || isSubmitting}
                primary
                size="small"
                submit
              />
            </>
          );

          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                size="medium"
                overflow
                onClose={onClose}
                controls={getControls}
                modalTitle={modalTitle}
                renderComponent={() =>
                  loadingData ? (
                    <div className="flex justify-center items-center">
                      <Loader small roller />
                    </div>
                  ) : (
                    <>
                      <FormikFormGroup
                        name="yearOfTrainingId"
                        label="Учебный год"
                        required
                        showErrorImmediately={showErrorImmediately}
                      >
                        <FormikSelect
                          name="yearOfTrainingId"
                          size="small"
                          isSearchable
                          options={yearList}
                          placeholder="Выберите..."
                          selectedValue={handleSelectYear}
                          disabled={!!planData}
                        />
                      </FormikFormGroup>
                      {periodOptions?.map((period, periodIndex) => {
                        const stageOrder =
                          serviceData.stage.list.find(({ id }) => id === planData?.serviceStageId)?.orderNumber ?? 1;

                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Fragment key={periodIndex}>
                            <Push size={16} />
                            <FormikFormGroup
                              name=""
                              label={`Дата начала занятий / Учебный период ${
                                periodOptions.length > 1 ? periodIndex + 1 : ''
                              }`}
                              required
                            >
                              <div className="flex items-start">
                                <div className="flex flex-auto" style={{ width: '50%' }}>
                                  <DateField
                                    name={`stageList[${periodIndex}].dateStart`}
                                    disabled={!values.yearOfTrainingId || (hasRequest && periodIndex < stageOrder)}
                                    showErrorImmediately={showErrorImmediately}
                                    placeholder={values.yearOfTrainingId ? 'ДД.ММ.ГГГГ' : 'Выберите учебный год'}
                                  />
                                </div>
                                {separator}
                                <div className="flex flex-auto" style={{ width: '50%' }}>
                                  <DateField
                                    name={`stageList[${periodIndex}].dateEnd`}
                                    disabled={!values.yearOfTrainingId || (hasRequest && periodIndex < stageOrder - 1)}
                                    showErrorImmediately={showErrorImmediately}
                                    placeholder={values.yearOfTrainingId ? 'ДД.ММ.ГГГГ' : 'Выберите учебный год'}
                                  />
                                </div>
                              </div>
                            </FormikFormGroup>
                          </Fragment>
                        );
                      })}

                      <Push size={16} />
                      <FormikFormGroup name="" label="Количество мест" required>
                        <div className="flex items-start">
                          <div style={{ width: VOLUME_FIELD_WIDTH, marginTop: `${hasFreeVolume ? '8px' : ''}` }}>
                            <FormikFormGroup name="volume" label="" showErrorImmediately={showErrorImmediately}>
                              <FormikInput
                                key={volumeKey}
                                name="volume"
                                number
                                size="small"
                                disabled={values.volume === null}
                                placeholder="0"
                              />
                            </FormikFormGroup>
                          </div>

                          {showMinimalVolumeTooltip && values.volume !== null && (
                            <>
                              <Push size={8} orientation="horizontal" />
                              <div className="flex items-center flex-none table-row-item-height">
                                <Tooltip
                                  component={() => <IconInfo />}
                                  position="bottom"
                                  text={
                                    <div className="text-center">Минимальное количество мест — {minVolumeValue}.</div>
                                  }
                                />
                              </div>
                            </>
                          )}

                          {hasFreeVolume && (
                            <>
                              <Push size={24} orientation="horizontal" />
                              <div className="flex-auto">
                                <Infobox
                                  text={`Бюджетных мест осталось ${limitVolume}`}
                                  fullWidth
                                  color="warning"
                                  padding="16px"
                                />
                              </div>
                            </>
                          )}

                          {!hasLimitedVolume && (
                            <>
                              <Push size={8} orientation="horizontal" />
                              <div className="flex items-center flex-none table-row-item-height">
                                <FormikCheckboxGroup label="Без ограничений" name="restrictions" size="small">
                                  <Checkbox
                                    labelId="restrictions"
                                    checked={values.volume === null}
                                    onChange={(c) => {
                                      setVolumeKey(Math.random());
                                      setFieldValue('volume', c ? null : planData?.volume ?? 1);
                                    }}
                                    disabled={initialValues.volume === null && hasRequest}
                                  />
                                </FormikCheckboxGroup>
                              </div>
                            </>
                          )}
                        </div>
                      </FormikFormGroup>

                      <Push size={16} />
                      <FormikFormGroup
                        name="teacher"
                        label="Преподаватель"
                        required
                        showErrorImmediately={showErrorImmediately}
                      >
                        <FormikSelect
                          name="teacher"
                          size="small"
                          isSearchable
                          options={[]}
                          loadOptions={(query: string) => getTeacherList(query)}
                          placeholder="Начните вводить..."
                          defaultValue={values?.teacher ? { value: 0, label: values?.teacher } : null}
                          selectedValue={(option: any) => {
                            setFieldValue('teacherId', option?.value || '');
                            setFieldValue('teacher', option?.label || '');
                          }}
                        />
                      </FormikFormGroup>
                      <Push size={16} />
                      <FormikFormGroup name="" label="Период приема заявлений на Mos.ru" required>
                        <div className="flex items-start">
                          <div className="flex flex-auto" style={{ width: '50%' }}>
                            <DateField
                              name="scheduleList[0].requestStart"
                              dependentTimeName="scheduleList[0].requestTimeStart"
                              showErrorImmediately={showErrorImmediately}
                            />
                            <Push size={8} orientation="horizontal" />
                            <div style={{ width: 114 }} className="flex-none">
                              <TimeField
                                name="scheduleList[0].requestTimeStart"
                                dependentDateName="scheduleList[0].requestStart"
                              />
                            </div>
                          </div>
                          {separator}
                          <div className="flex flex-auto" style={{ width: '50%' }}>
                            <DateField
                              name="scheduleList[0].requestEnd"
                              dependentTimeName="scheduleList[0].requestTimeEnd"
                              showErrorImmediately={showErrorImmediately}
                            />
                            <Push size={8} orientation="horizontal" />
                            <div style={{ width: 114 }} className="flex-none">
                              <TimeField
                                name="scheduleList[0].requestTimeEnd"
                                dependentDateName="scheduleList[0].requestEnd"
                              />
                            </div>
                          </div>
                        </div>
                      </FormikFormGroup>
                    </>
                  )
                }
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PreparationPlanModal;

const getValidationSchema = (serviceData: ServiceData, limitVolume: number, yearOfTraining?: number) => {
  const depsDate: [string, string] = ['dateStart', 'dateEnd'];
  const depsMosDate: [string, string] = ['requestStart', 'requestEnd'];
  const service = serviceData;
  const { stage } = serviceData;
  const hasLimitedVolume =
    !!serviceData.financing && serviceData.financing.typeFinancingId !== TypeFinancingEnum.Payment;

  const dateValidation = objectYup().shape(
    {
      dateStart: validationCheckDate(
        'Выберите дату начала',
        { start: 'dateStart', end: 'dateEnd' },
        'Дата начала учебного периода не может быть больше даты окончания',
        'start'
      ),
      dateEnd: validationCheckDate(
        'Выберите дату окончания',
        { start: 'dateStart', end: 'dateEnd' },
        'Дата окончания учебного периода не может быть меньше даты начала',
        'end'
      ),
    },
    [depsDate]
  );

  const dateMosValidation = objectYup().shape(
    {
      requestStart: validationCheckDate(
        'Выберите дату начала',
        { start: 'requestStart', end: 'requestEnd' },
        'Дата начала приема заявлений больше даты окончания приема заявлений',
        'start'
      ),
      requestEnd: validationCheckDate(
        'Выберите дату окончания',
        { start: 'requestStart', end: 'requestEnd' },
        'Дата окончания приема заявлений меньше даты начала приема заявлений',
        'end'
      ),
      requestTimeStart: stringYup().nullable().required('Введите время начала'),
      requestTimeEnd: stringYup().nullable().required('Введите время окончания'),
    },
    [depsMosDate]
  );

  const requiredYearOfTraining = (message?: string) =>
    number()
      .nullable()
      .test('required-year-of-training', message || '', (value) => !!value && yearOfTraining !== undefined);

  const validation = {
    yearOfTrainingId: number()
      .nullable()
      .when('scheduleList', {
        is: (value: ScheduleData[] | null) => {
          return !value || !value.length || !value[0].requestTotalCount;
        },
        then: () => {
          return requiredYearOfTraining('Выберите учебный год');
        },
        otherwise: () => {
          return requiredYearOfTraining();
        },
      }),
    volume: number()
      .nullable()
      .typeError('Введите количество мест')
      .test('volume', 'Введите количество мест', (value) => (hasLimitedVolume ? !!value : value === null || !!value))
      .test('volume', '', function (value) {
        const data = this.parent as TrainingGroupData;
        if (data?.scheduleList && data?.scheduleList[0]?.requestTotalCount > 0 && value) {
          return value >= data?.scheduleList[0]?.requestsPositiveCount;
        }
        return true;
      })
      .test('volume', 'Превышено количество мест', function (value) {
        return !hasLimitedVolume || (!!value && value <= limitVolume);
      }),
    teacher: stringYup().nullable().required('Выберите преподавателя'),
    stageList: arrayYup().when('yearOfTrainingId', {
      is: (value: number | null) => {
        return !!value;
      },
      then: () =>
        arrayYup()
          .test('check-date', '', function (value) {
            if (!value?.length || !this.parent.yearOfTrainingId || !yearOfTraining) {
              return true;
            }

            const errors: ValidationError[] = [];
            const minDateStart = new Date(yearOfTraining, 8, 1).getTime();
            const maxDateStart = new Date(yearOfTraining, 9, 15).getTime();
            const maxDateEnd = new Date(yearOfTraining + 1, 7, 31).getTime();

            for (let i = 0; i < value.length; i++) {
              const dateStart = new Date(value[i].dateStart);
              const dateEnd = new Date(value[i].dateEnd);

              if (dateStart.getTime() < minDateStart || dateStart.getTime() > maxDateStart) {
                errors.push(
                  new ValidationError(
                    'Дата начала занятий должна быть в интервале с 1 сентября по 15 октября выбранного учебного года',
                    undefined,
                    `stageList[${i}].dateStart`
                  )
                );
              }

              if (dateEnd.getTime() > maxDateEnd) {
                errors.push(
                  new ValidationError(
                    'Дата окончания занятий не может быть больше 31 августа выбранного учебного года',
                    undefined,
                    `stageList[${i}].dateEnd`
                  )
                );
              }
            }

            return errors.length > 0 ? new ValidationError(errors) : true;
          })
          .test('TrainingStageData[]', 'ошибка', function (values) {
            const list = values as TrainingStageData[];
            const errors: ValidationError[] = [];
            if (list && list.length > 1) {
              for (let i = 0; i < list.length; i++) {
                if (list[i]?.dateStart) {
                  if (i > 0 && list[i - 1].dateEnd && new Date(list[i].dateStart) < new Date(list[i - 1].dateEnd)) {
                    errors.push(
                      new ValidationError(
                        'Дата начала учебного периода не может быть меньше даты окончания предыдущего периода',
                        undefined,
                        `stageList[${i}].dateStart`
                      )
                    );
                  }
                  if (
                    i < list.length - 1 &&
                    i >= 0 &&
                    list[i + 1].dateStart &&
                    new Date(list[i].dateEnd) > new Date(list[i + 1].dateStart)
                  ) {
                    errors.push(
                      new ValidationError(
                        'Дата окончания учебного периода не может быть больше даты начала следующего периода',
                        undefined,
                        `stageList[${i}].dateEnd`
                      )
                    );
                  }
                }
              }
            }

            if (
              list?.length > 0 &&
              list[0] &&
              list[0].dateStart &&
              list[stage.list.length - 1] &&
              list[stage.list.length - 1].dateEnd
            ) {
              const learnEnd = calcLearnEnd(
                new Date(list[0].dateStart),
                undefined,
                service.info.durationOfTraining,
                service.info.durationOfTrainingMonths,
                service.info.durationOfTrainingWeeks,
                service.info.durationOfTrainingDays
              );

              if (learnEnd < new Date(list[stage.list.length - 1].dateEnd)) {
                errors.push(
                  new ValidationError(
                    'Дата окончания учебного периода выходит за рамки продолжительности программы обучения',
                    undefined,
                    `stageList[${list.length - 1}].dateEnd`
                  )
                );
              }
            }

            return errors.length > 0 ? new ValidationError(errors) : true;
          })
          .of(dateValidation)
          .required(''),
    }),
    scheduleList: arrayYup()
      .test(
        'dateEnd',
        'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
        function (value) {
          const tg = this.parent as TrainingGroupData;
          const schedule = value as ScheduleData[];
          if (tg?.stageList && tg.stageList.length > 0 && schedule && schedule[0].requestEnd) {
            if (tg.stageList[0].dateEnd) {
              const a = new Date(schedule[0].requestEnd);
              const b = new Date(tg.stageList[0].dateEnd);
              return b < a
                ? new ValidationError(
                    'Дата окончания периода приема заявлений должна быть меньше даты окончания учебного периода',
                    undefined,
                    `scheduleList[0].requestEnd`
                  )
                : true;
            }
          }
          return true;
        }
      )
      .of(dateMosValidation)
      .required(''),
  };

  return objectYup().shape(validation);
};
