import React, { useMemo, useState } from 'react';
import { Panel, Switcher, Push } from '@mosru/esz_uikit';
import ActionsFormEnrollment from './actions-form-enrollment';
import ActionsFormRefusal from './actions-form-refusal';
import ActionsFormEnrolled from './actions-form-enrolled';
import { RequestDeclineData, RequestEnrollmentData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type Props = {
  enrollment: RequestEnrollmentData | undefined;
  decline: RequestDeclineData;
  requestStatusId: RequestStatusEnum;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
  contingentGuid: string;
};

const ActionsForm: React.FC<Props> = ({
  enrollment,
  requestStatusId,
  serviceId,
  decline,
  requestId,
  updateRequest,
  contingentGuid,
}) => {
  const showDecline = useMemo(
    () =>
      requestStatusId !== RequestStatusEnum.Included &&
      requestStatusId !== RequestStatusEnum.Archive &&
      requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestStatusId !== RequestStatusEnum.IncludedExcluded &&
      requestStatusId !== RequestStatusEnum.New,
    [requestStatusId]
  );

  const showEnrollment = useMemo(
    () =>
      requestStatusId !== RequestStatusEnum.IncludeDeclined &&
      requestStatusId !== RequestStatusEnum.ApplicantRefused &&
      requestStatusId !== RequestStatusEnum.WaitForElectronicAccept &&
      requestStatusId !== RequestStatusEnum.New &&
      requestStatusId !== RequestStatusEnum.Included &&
      requestStatusId !== RequestStatusEnum.IncludedExcluded &&
      requestStatusId !== RequestStatusEnum.Archive,
    [requestStatusId]
  );
  const [refusal, setRefusal] = useState(!showEnrollment);
  return (
    <>
      <Push size={12} />
      {showDecline || showEnrollment ? (
        <Panel
          title={() => (showDecline && !showEnrollment ? 'Отказ в зачислении' : 'Действия по заявлению')}
          headingControl={() =>
            showDecline &&
            showEnrollment && (
              <Switcher size="small">
                <button
                  type="button"
                  onClick={() => {
                    setRefusal(false);
                  }}
                  className={refusal ? '' : 'active'}
                >
                  Зачисление
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setRefusal(true);
                  }}
                  className={refusal ? 'active' : ''}
                >
                  Отказ в зачислении
                </button>
              </Switcher>
            )
          }
        >
          {refusal ? (
            <ActionsFormRefusal
              decline={decline}
              requestId={requestId}
              serviceId={serviceId}
              disabled={requestStatusId === RequestStatusEnum.IncludeDeclined}
              updateRequest={updateRequest}
            /> // !@#$ + disabled when btnSaveDsbl
          ) : (
            <ActionsFormEnrollment
              contingentGuid={contingentGuid}
              enrollment={enrollment ?? ({} as RequestEnrollmentData)}
              serviceId={serviceId}
              requestId={requestId}
              updateRequest={updateRequest}
            />
          )}
        </Panel>
      ) : (
        requestStatusId !== RequestStatusEnum.ApplicantRefused && (
          <Panel
            title={() =>
              requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded
                ? 'Зачисление'
                : 'Действия по заявлению'
            }
          >
            <ActionsFormEnrolled enrollment={enrollment} />
          </Panel>
        )
      )}
    </>
  );
};

export default ActionsForm;
