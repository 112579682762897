import React, { useCallback, useEffect, useState } from 'react';
import { Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import { GetReport, OptionFilter, ReportFilterData, ReportSchedule } from '../../../../types/reports';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import FormikInput from '../../../../components/formik/formik-input';
import { ReactComponent as IconOptions } from '../../../../assets/images/icons/options.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/close.svg';
import lookupApi from '../../../../lib/api/lookup';
import { ReportFilterTypeEnum } from '../../../../mock-data/report-filter-type-enum';
import { allowOnlyNumbersNoSpace } from '../../../../lib/utils/validation';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

type Props = {
  filterData: GetReport | null;
  currentReportId: number;
  openScheduleModal: () => void;
  listSchedule: ReportSchedule;
  resetScheduleModalHandler: (value: boolean) => void;
  setOpenTreePopup: (value: boolean) => void;
  values: ReportFilterData;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setValueBasicDirection: React.Dispatch<React.SetStateAction<SelectOptionType>>;
  valueBasicDirection: SelectOptionType;
};

const defaultValues = { label: 'Все', value: '' };

const FilterVertical: React.FC<Props> = ({
  filterData,
  setOpenTreePopup,
  listSchedule,
  openScheduleModal,
  resetScheduleModalHandler,
  currentReportId,
  values,
  setFieldValue,
  setValueBasicDirection,
  valueBasicDirection,
}) => {
  const [parallels, setParallels] = useState<SelectOptionType[]>([]);
  const [letters, setLetters] = useState<SelectOptionType[]>([]);
  const [orgKey, setOrgKey] = useState(Math.random());
  const [classKey, setClassKey] = useState(Math.random());

  useEffect(() => {
    const fetch = async () => {
      if (!values.organizationId) {
        setParallels([]);
        setLetters([]);
        setClassKey(Math.random());
        return;
      }

      const parallels = await dictionariesApi.getParallels(values.organizationId as number);

      const letters = await dictionariesApi.getLetters(values.organizationId as number);

      if (values.organizationId) {
        setParallels(parallels?.length !== 1 ? [defaultValues, ...parallels] : parallels);
        setLetters(letters?.length !== 1 ? [defaultValues, ...letters] : letters);
      }
    };

    if (currentReportId === 36) {
      fetch();
    }
  }, [values.organizationId, currentReportId]);

  const getFilterName = useCallback(
    (filterType: ReportFilterTypeEnum) => {
      const filter = filterData?.filterList.find((f) => f.key === filterType);
      if (filter === undefined) {
        return '';
      }
      return filter.parameterField;
    },
    [filterData]
  );

  const onOrganizationSelect = (value: SelectOptionType) => {
    // попробуем подтянуть тип организации из самой организации, как в монолите
    const typeFilter = filterData?.filterList.find((f) => f.key === ReportFilterTypeEnum.ByTypeProvServ);
    if (typeFilter) {
      const orgTypeId = value ? JSON.parse(value.additionalPropertiesJson as string).OrgTypeId : undefined;
      setFieldValue(
        getFilterName(ReportFilterTypeEnum.ByTypeProvServ),
        typeFilter.listItems?.find((f) => f.id === orgTypeId) ? orgTypeId : ''
      );
    }
  };

  const onTypeProvServSelect = () => {
    setOrgKey(Math.random());
    setFieldValue(getFilterName(ReportFilterTypeEnum.ByOrganization), null);
  };

  useEffect(() => {
    if (valueBasicDirection?.value !== undefined && getFilterName(ReportFilterTypeEnum.ByClassificatorEKU)) {
      setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassificatorEKU), valueBasicDirection.value as number);
      setFieldValue('classificatorEKUName', valueBasicDirection?.label, false);
    }
  }, [valueBasicDirection, setFieldValue, getFilterName]);

  const BuildAllOptions = (filter: OptionFilter) => {
    const all = { label: filter.allOption ?? '', value: '' };
    if (filter === undefined) {
      return [all];
    }

    if (filter.listItems && filter.listItems.length > 1) {
      return [
        all,
        ...filter.listItems.map((i) => {
          return { label: i.name, value: i.id };
        }),
      ];
    } else if (filter.listItems?.length === 1) {
      return filter.listItems?.map((i) => {
        return { label: i.name, value: i.id };
      });
    } else {
      return [{ label: filter.displayValue, value: 0 }];
    }
  };

  const BuildOptions = (filter: OptionFilter) => {
    const all = { label: filter.allOption ?? '', value: '' };
    if (filter === undefined) {
      return [all];
    }

    if (filter.listItems && filter.listItems.length > 0) {
      return [
        ...filter.listItems.map((i) => {
          return { label: i.name, value: i.id };
        }),
      ];
    } else {
      return [{ label: filter.displayValue, value: 0 }];
    }
  };

  const age = (key: number) => {
    return (
      <div key={key}>
        <div>Возраст</div>
        <Push size={8} />
        <div className="report__age--wrap">
          <div className="report__age--element">
            <FormikFormGroup name="minAge" label="" showErrorImmediately>
              <FormikInput
                size="small"
                name="minAge"
                number
                placeholder="Мин введите"
                onKeyPress={(e: any) => {
                  allowOnlyNumbersNoSpace(e, true);
                }}
              />
            </FormikFormGroup>
          </div>
          <Push size={8} orientation="horizontal" />
          <div className="report__age--element">
            <FormikFormGroup name="maxAge" label="" showErrorImmediately>
              <FormikInput
                size="small"
                name="maxAge"
                number
                placeholder="Макс введите"
                onKeyPress={(e: any) => {
                  allowOnlyNumbersNoSpace(e, true);
                }}
              />
            </FormikFormGroup>
          </div>
        </div>
        <Push size={16} />
      </div>
    );
  };
  return filterData?.filterList ? (
    <>
      {filterData?.filterList.map((item: OptionFilter) => {
        return (
          <div key={item.key}>
            {/* Департмент */}
            {item.key === ReportFilterTypeEnum.ByVedomstvo && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    defaultValue={BuildAllOptions(item)[0]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Межрайонный Совет Директоров */}
            {item.key === ReportFilterTypeEnum.ByMrsd && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={[]}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    loadOptions={async (query) => await lookupApi.getOrganizationMrsd(query)}
                  />
                </FormikFormGroup>
                {/* <Push size={16} /> */}
              </>
            ) : null}

            {/* Организация */}

            {item.key === ReportFilterTypeEnum.ByOrganization && item.visible ? (
              <>
                {currentReportId === 35 ? <Push size={16} /> : null}

                <FormikFormGroup
                  key={item.key}
                  name={item.parameterField}
                  label={item.parameterCaption}
                  required={currentReportId === 37}
                >
                  <FormikSelect
                    key={orgKey}
                    loadOptions={async (query) => {
                      return await lookupApi.getOrganization(query, values.vedomstvoId as number);
                    }}
                    name={item.parameterField}
                    size="small"
                    isSearchable
                    defaultValue={
                      filterData.filterParams.organizationId
                        ? {
                            value: item.value,
                            label: item.displayValue,
                          }
                        : null
                    }
                    placeholder={item.typeAheadPlaceholder}
                    disabled={!!filterData.filterParams.organizationId}
                    options={[]}
                    selectedValue={(value: SelectOptionType) => {
                      if (!value) {
                        setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassParallel), null);
                        setFieldValue(getFilterName(ReportFilterTypeEnum.ByClassLetter), null);
                      }
                      onOrganizationSelect(value);
                    }}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Тип организации */}
            {item.key === ReportFilterTypeEnum.ByTypeProvServ && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    defaultValue={
                      item.displayValue
                        ? BuildAllOptions(item)?.filter((f) => f.label === item.displayValue)[0] ??
                          BuildAllOptions(item)[0]
                        : BuildAllOptions(item)[0]
                    }
                    selectedValue={onTypeProvServSelect}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Тип обучения */}
            {item.key === ReportFilterTypeEnum.ByEducationType && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={BuildOptions(item)}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    defaultValue={
                      item.displayValue
                        ? BuildAllOptions(item)?.filter((f) => f.label === item.displayValue)[0] ??
                          BuildAllOptions(item)[0]
                        : BuildAllOptions(item)[0]
                    }
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Возрастная категория */}
            {item.key === ReportFilterTypeEnum.ByAgeGroup && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    defaultValue={BuildAllOptions(item)[0]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Уровень программы */}
            {item.key === ReportFilterTypeEnum.ByProgrammLevel && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    defaultValue={BuildAllOptions(item)[0]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Округ */}
            {item.key === ReportFilterTypeEnum.ByTerritory && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    placeholder={item.allOption}
                    name={item.parameterField}
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    defaultValue={BuildAllOptions(item)[0]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Образовательная программа */}
            {item.key === ReportFilterTypeEnum.ByService && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    // key={serviceKey}
                    loadOptions={async (query) => {
                      return await lookupApi.getService(
                        query,
                        filterData?.educationTypeId,
                        values.vedomstvoId ?? undefined,
                        values.organizationId ?? undefined
                      );
                    }}
                    name={item.parameterField}
                    size="small"
                    isSearchable
                    selectedValue={(val: SelectOptionType) => {
                      setFieldValue('serviceName', val ? val.label : undefined);
                    }}
                    /* defaultValue={
                     values.organizationId
                     ? {
                     value: item.value,
                     label: item.displayValue,
                     }
                     : null
                     } */
                    placeholder={item.typeAheadPlaceholder}
                    disabled={!!filterData.filterParams.serviceId}
                    options={[]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Учебный период */}
            {item.key === ReportFilterTypeEnum.ByYearOfTraining && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    placeholder={item.allOption}
                    name={item.parameterField}
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    defaultValue={BuildAllOptions(item)[0]}
                  />
                </FormikFormGroup>
                {currentReportId === 31 ? null : <Push size={16} />}
              </>
            ) : null}

            {/* Группа обучения */}
            {item.key === ReportFilterTypeEnum.ByServiceClass && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    loadOptions={async (query) =>
                      await lookupApi.getServiceClass(query, values.serviceId, filterData?.educationTypeId, 8)
                    }
                    options={[]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Параллель */}
            {item.key === ReportFilterTypeEnum.ByClassParallel && item.visible ? (
              <>
                <FormikFormGroup name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    key={classKey}
                    placeholder="Сначала выберите организацию"
                    name={item.parameterField}
                    size="small"
                    disabled={!values.organizationId}
                    isSearchable
                    options={parallels}
                    defaultValue={parallels ? parallels[0] : undefined}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Буква класса */}
            {item.key === ReportFilterTypeEnum.ByClassLetter && item.visible ? (
              <>
                <FormikFormGroup name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    key={classKey + 1}
                    placeholder="Сначала выберите организацию"
                    name={item.parameterField}
                    size="small"
                    isSearchable
                    defaultValue={letters ? letters[0] : undefined}
                    disabled={!values.organizationId}
                    options={letters}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Преподователь */}
            {item.key === ReportFilterTypeEnum.ByTeacher && item.visible ? (
              <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                <FormikSelect
                  loadOptions={async (query) => {
                    return await lookupApi.getTeachers(query, values.organizationId ?? undefined);
                  }}
                  name={item.parameterField}
                  size="small"
                  isSearchable
                  placeholder={item.typeAheadPlaceholder}
                  options={[]}
                />
              </FormikFormGroup>
            ) : null}

            {/* Статус публикации */}
            {item.key === ReportFilterTypeEnum.ByServiceStatus && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={BuildAllOptions(item)}
                    disabled={item.readOnly}
                    name={item.parameterField}
                    placeholder={item.typeAheadPlaceholder}
                    defaultValue={BuildAllOptions(item)[0]}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* Наличие в реестре контингента */}
            {item.key === ReportFilterTypeEnum.ByContingentLinkType && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikSelect
                    size="small"
                    isSearchable
                    name={item.parameterField}
                    placeholder={item.allOption}
                    options={BuildAllOptions(item)}
                  />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {/* ФИО обучающегося */}
            {item.key === ReportFilterTypeEnum.BySearchText && item.visible ? (
              <>
                <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                  <FormikInput size="small" name={item.parameterField} placeholder="Введите..." />
                </FormikFormGroup>
                <Push size={16} />
              </>
            ) : null}

            {item.key === ReportFilterTypeEnum.ByMinAgeGroup ? <>{age(item.key)}</> : null}

            {/* Расписание */}
            {item.key === ReportFilterTypeEnum.BySchedule && item.visible ? (
              <div className="report__search--wrap">
                <div className="search__wrap--input">
                  <FormikFormGroup key={item.key} name={item.parameterField} label={item.parameterCaption}>
                    <FormikInput
                      size="small"
                      name={item.parameterField}
                      value={listSchedule?.selectShortDay}
                      placeholder="Не указано"
                      disabled
                    />
                  </FormikFormGroup>
                </div>
                <div className="search__wrap--btn">
                  <Push size={25} orientation="vertical" />
                  <div className="flex">
                    <Button
                      border
                      primary
                      size="small"
                      iconRight={() => <IconRemove />}
                      onClick={() => {
                        resetScheduleModalHandler(true);
                      }}
                      disabled={item.readOnly}
                    />
                    <Push size={10} orientation="horizontal" />
                    <div style={{ minWidth: '184px' }}>
                      <Button
                        border
                        primary
                        size="small"
                        onClick={openScheduleModal}
                        label="Выбрать расписание"
                        disabled={item.readOnly}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Базовое направление */}

            {item.key === ReportFilterTypeEnum.ByClassificatorEKU && item.visible ? (
              <div className="report__search--wrap">
                <div className="search__wrap--input">
                  <FormikFormGroup
                    name={item.parameterField}
                    label={item.parameterCaption}
                    required={currentReportId === 6}
                  >
                    <FormikSelect
                      key={values[item.parameterField]}
                      size="small"
                      isSearchable
                      options={[]}
                      name={item.parameterField}
                      selectedValue={(value) => setValueBasicDirection(value)}
                      loadOptions={async (query) => await lookupApi.getClassificator(query)}
                      placeholder="Начните вводить..."
                      defaultValue={!valueBasicDirection?.value ? null : valueBasicDirection}
                    />
                  </FormikFormGroup>
                </div>
                <div className="search__wrap--btn">
                  <Push orientation="vertical" size={25} />
                  <Button
                    border
                    primary
                    size="small"
                    onClick={() => setOpenTreePopup(true)}
                    iconRight={() => <IconOptions />}
                  />
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  ) : null;
};

export default FilterVertical;
