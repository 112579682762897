import React, { useCallback } from 'react';
import { useField } from 'formik';
import moment, { MomentInput } from 'moment';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../../components/formik/formik-datepicker';

type Props = {
  name: string;
  maxDate?: boolean;
  minDate?: boolean;
  dependentDateName?: string;
  dependentTimeName?: string;
  disabled?: boolean;
  isActiveDate?: ((dt: Date) => boolean) | undefined;
  showErrorImmediately?: boolean;
  placeholder?: string;
};

const DateField: React.FC<Props> = ({
  name,
  dependentTimeName,
  disabled,
  isActiveDate,
  showErrorImmediately,
  placeholder = 'ДД.ММ.ГГГГ',
}) => {
  const [, , fieldHelper] = useField(name);
  const [dependentTimeFieldName] = useField(dependentTimeName || '');

  // Обработчик для установки даты с текущим временем.
  const onChange = useCallback(
    (date: Date | [Date, Date | null] | null) => {
      if (dependentTimeName && date) {
        const timeValue = dependentTimeFieldName.value ? dependentTimeFieldName.value : '00:00';

        const [hours, minutes] = timeValue.split(':');
        const newDateWithTime = moment(date as MomentInput)
          .set('hour', hours)
          .set('minute', minutes)
          .toDate();

        fieldHelper.setValue(newDateWithTime);
      }
    },
    [dependentTimeFieldName.value, dependentTimeName, fieldHelper]
  );

  return (
    <div className="flex-auto">
      <FormikFormGroup name={name} label="" showErrorImmediately={showErrorImmediately}>
        <FormikDatePicker
          placeholder={placeholder}
          size="small"
          name={name}
          onChangeValue={(date: Date | [Date, Date | null] | null) => onChange(date)}
          disabled={disabled}
          isActiveDate={isActiveDate}
        />
      </FormikFormGroup>
    </div>
  );
};

export default DateField;
