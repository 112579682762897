import { useEffect, useMemo, useState } from 'react';
import { SelectOptionType } from '@mosru/esz_uikit';
import { dictionariesApi } from '../../../../../lib/api/dictionaries';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { ServiceData } from '../../../../../types/service';
import { accessVedomst } from '../../../../../mock-data/access-enum';
import { AuthorizationData } from '../../../../../types/authorization-data';

export const useGetOptions = (
  isTemplate: boolean | undefined,
  serviceData: ServiceData,
  userProfile: AuthorizationData
) => {
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const access = useMemo(
    () => userProfile.vedomstvoId !== accessVedomst.Dsit && userProfile.vedomstvoId !== accessVedomst.Dkgm,
    [userProfile.vedomstvoId]
  );

  // Todo Правильнее будет чтобы бек возвращал нужноe значение
  const replaceLabel = (str: string) => {
    return access ? str : str?.replace(/Уточняется в процессе зачисления/gi, 'Индивидуальное');
  };

  const [scheduleType, setScheduleType] = useState<SelectOptionType | undefined>(
    serviceData?.schedule
      ? {
          label: replaceLabel(serviceData.schedule.scheduleTypeOfServiceName),
          value: serviceData.schedule.scheduleTypeOfServiceId,
        }
      : undefined
  );

  useEffect(() => {
    const fetch = async () => {
      const result = await dictionariesApi.getScheduleTypes();
      if (
        !isTemplate &&
        access &&
        (serviceData?.educationTypeId === EducationTypeEnum.ProfessionalEducation ||
          serviceData?.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation ||
          serviceData?.educationTypeId === EducationTypeEnum.ChildrenEducation ||
          serviceData?.educationTypeId === EducationTypeEnum.ArtHouseEducation ||
          serviceData?.educationTypeId === EducationTypeEnum.SportEducation)
      ) {
        result[1].label = 'Уточняется в процессе зачисления';
      }
      setOptions(result);
      if (!scheduleType) {
        setScheduleType(result[0]);
      }
    };
    if (!options.length && serviceData?.educationTypeId) {
      fetch();
    }
  }, [access, isTemplate, options.length, scheduleType, serviceData?.educationTypeId]);

  return { options, scheduleType, setScheduleType };
};
