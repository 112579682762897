import React, { useContext, useMemo, useRef, useState } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Button, SelectOptionType, TreeNodeType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { ReactComponent as IconOptionsColor } from '../../../../assets/images/icons/options-color.svg';
import { SearchKeyContext } from '../contexts/key-context';
import TreeModal from '../../../../components/tree-modal';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { setAsyncValues } from '../../../../lib/utils/requests';

type Props = {
  label: string;
  placeholder: string;
  isAdmin: boolean;
};

const FieldClassificatorEku: React.FC<Props> = ({ label, placeholder, isAdmin }) => {
  const [openTreePopup, setOpenTreePopup] = useState<boolean>(false);
  const [dataTreePopup, setDataTreePopup] = useState<TreeNodeType[]>([]);
  const allElementNumbersTreePopupRef = useRef<number>(0);

  const { classificatorEKUKey, setProgrammLevelKey } = useContext(SearchKeyContext);
  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();
  const { values } = useFormikContext<SearchRequestsInitialFormData>();

  const onChange = (value: SelectOptionType) => {
    setFieldValue('classificatorEKUId', value?.value);
    setFieldValue('classificatorEKUName', value?.label);
    if (!value?.value) {
      const dataToClearValues = [
        { key: 'programmLevelId', action: setProgrammLevelKey },
        { key: 'programmLevelName', action: () => {} },
      ];
      setAsyncValues(dataToClearValues, setFieldValue);
    }
  };

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const canShowTypeActivity = useMemo(() => {
    return (
      hasAccessObjectAny(userProfile, [accessObject.RequestNonDOGM], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestVA], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestDOGM], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestOP], accessAction.View) ||
      isAdmin
    );
  }, [isAdmin, userProfile]);
  return (
    <>
      {canShowTypeActivity && (
        <>
          <Push size={16} />
          <FormikFormGroup name="classificatorEKUId" label={label}>
            <div className="flex">
              <div className="flex-auto">
                <FormikSelect
                  key={classificatorEKUKey}
                  name="classificatorEKUId"
                  size="small"
                  isSearchable
                  options={[]}
                  loadOptions={async (query) => await lookupApi.getClassificator(query, values.educationTypeId)}
                  placeholder={placeholder}
                  defaultValue={
                    values.classificatorEKUId
                      ? {
                          value: values.classificatorEKUId,
                          label: values.classificatorEKUName ?? '',
                        }
                      : null
                  }
                  selectedValue={onChange}
                />
              </div>
              <Push size={8} orientation="horizontal" />
              <Button
                iconLeft={() => <IconOptionsColor />}
                border
                primary
                size="small"
                onClick={() => {
                  setOpenTreePopup(true);
                }}
              />
            </div>
          </FormikFormGroup>
        </>
      )}
      <TreeModal
        selected={false}
        open={openTreePopup}
        dataTreePopup={dataTreePopup}
        setDataTreePopup={setDataTreePopup}
        closePopup={setOpenTreePopup}
        title="Реестр базовых направлений"
        setValueBasicDirection={onChange}
        educationId={values.educationTypeId}
        numberElements={allElementNumbersTreePopupRef.current || 0}
      />
    </>
  );
};

export default FieldClassificatorEku;
