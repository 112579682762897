import { FieldArray, FormikHelpers, useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import SelectTeacher from '../teacher/fields/select-teacher';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { TeacherType } from '../../../../types/service-class';
import { getRandomInt } from '../../../../lib/utils';
import serviceClassApi from '../../../../lib/api/service-class';
import { moduleFieldId } from './module';

type Props = {
  editMode: boolean;
  name: string;
  hasCreateServiceClass?: boolean;
};

export const teachersField = 'teachers';

const TeachersField = ({ editMode, name = 'teachers', hasCreateServiceClass }: Props) => {
  const { setFieldValue } = useFormikContext<FormikHelpers<any>>();

  const [field] = useField<TeacherType[]>(name);
  const [fieldModule] = useField<string>(moduleFieldId);

  useEffect(() => {
    const fetch = async () => {
      const res = await serviceClassApi.getTeacherByProgrammModule(fieldModule?.value);

      const filterTeacher = field.value?.filter((teacher: TeacherType) => teacher.id === res.id);
      const teachers = filterTeacher.length ? filterTeacher : [res];

      setFieldValue('teachers', teachers);
    };

    if (fieldModule.value) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldModule?.value]);

  return (
    <div className="container">
      <div className="table-data">
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <>
              {field.value && field.value.length > 0 ? (
                <>
                  {field.value?.map((item, index) => (
                    <SelectTeacher
                      key={`${item.id}${getRandomInt()}`}
                      label="ФИО"
                      data={item}
                      editMode={editMode}
                      arrayHelpers={arrayHelpers}
                      itemIndex={index}
                      child={name}
                      hasCreateServiceClass={hasCreateServiceClass}
                    />
                  ))}
                </>
              ) : (
                ''
              )}
              <div className="table-data__item">
                <button
                  type="button"
                  onClick={() => arrayHelpers.push({ id: Math.random(), name: '', isSupervisor: false })}
                  className="icon-group"
                >
                  <span className="icon-group__icon">
                    <IconPlus />
                  </span>
                  <span className="icon-group__text font-weight-bold color-primary">Добавить преподавателя</span>
                </button>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default TeachersField;
