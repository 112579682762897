import React from 'react';
import { Loader, Push, SimpleTable as Table, TableDataType } from '@mosru/esz_uikit';
import { EducationTypeEnum } from '../../../../../types/education-type';
import { useGetColumns } from './use-get-columns';

type Props = {
  loading: boolean;
  planData: TableDataType[];
  editPlan: (id: number) => void;
  setIdRemoveModal: (id: number) => void;
  educationType: EducationTypeEnum;
};

const EditPlan: React.FC<Props> = ({ loading, planData, editPlan, setIdRemoveModal, educationType }) => {
  const columns = useGetColumns(educationType, editPlan, setIdRemoveModal);

  return loading ? (
    <div className="flex justify-center flex-column">
      <Push orientation="vertical" size={20} />
      <Loader roller small />
      <Push orientation="vertical" size={20} />
    </div>
  ) : columns.length ? (
    <Table overflow data={planData} hideSort columns={columns} />
  ) : null;
};

export default EditPlan;
