import React from 'react';
import GlobalFooterAupd from '@mes-ui/global-footer';
import { useGetChangeLogQuery } from '../../store/static-content';

const GlobalFooter = () => {
  const { data: { releaseVersion } = {} } = useGetChangeLogQuery();

  return (
    <div className="global-footer">
      <GlobalFooterAupd version={releaseVersion} changeFontSize />
    </div>
  );
};

export default GlobalFooter;
