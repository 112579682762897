import React, { SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, TabButton, TabGroup, IconSearch, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconOptions } from '../../../assets/images/icons/options.svg';
import { ReactComponent as IconUp } from '../../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../../assets/images/icons/down.svg';
import { LoaderCustom } from '../../../components/loader-custom';
import FormikInput from '../../../components/formik/formik-input';
import { EventUrl } from '../../../mock-data/event';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../types/requests';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { getEducationTypes, hasGeneralAccess, parseUrlSearchString, replaceHistoryState } from '../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../types/authorization-data';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import {
  filterEducations,
  getEducationTypeForCreateLinks,
  getRequestEducationLinks,
} from '../../../lib/utils/education';
import { transformPrivelegiesData } from '../../../lib/utils/learners';
import { EducationTypeEnum, EducationTypeToCamelCaseEnum } from '../../../types/education-type';
import requestsApi from '../../../lib/api/requests';
import { requestHistoryState } from '../utils';
import StatementChild from './search-tabs/statement-child';
import ChildServiceView from './search-tabs/child-service/index';
import { getCountDiff, infoChild, period, privilegeStatusList, setAsyncValues } from '../../../lib/utils/requests';
import { FieldsModelItem } from '../../../types/entities';
import { fieldsModel } from './fields-model';
import { searchRequestsInitialFormData } from './index';
import { SearchKeyContext } from './contexts/key-context';
import InitFilters from './fields/init-filters';
import useQuery from '../../../hooks/use-query';
import { sendReachGoal } from '../../../lib/metrica';

type Props = {
  submitForm: (values: SearchRequestsInitialFormData) => void;
  setTableOptions: React.Dispatch<SetStateAction<SearchRequestsTableOptions>>; // ((prevState:SearchRequestsTableData) => SearchRequestsTableData) => void;
  setRequestOptions: (val: { value: number; label: string; link: string }[]) => void;
  initialSearchFilters?: SearchRequestsInitialFormData;
};

enum SearchTypeEnum {
  StatementChild = 'StatementChild',
  ChildService = 'ChildService',
}

const RequestsSearch: React.FC<Props> = ({ submitForm, setTableOptions, setRequestOptions, initialSearchFilters }) => {
  const [open, setOpen] = useState(!!window.history.state[requestHistoryState.openAdvanced]);
  const [searchType, setSearchType] = useState<SearchTypeEnum>(
    window.history.state[requestHistoryState.searchType] || SearchTypeEnum.StatementChild
  );
  const [contractStatusData, setContractStatusData] = useState<SelectOptionType[]>([]);
  const [requestStatusData, setRequestStatusData] = useState<SelectOptionType[]>([]);
  const [requestSourceData, setRequestSourceData] = useState<SelectOptionType[]>([]);
  const [privilegiesData, setPrivilegiesData] = useState<SelectOptionType[]>([]);

  const [isLoadingStatementChild, setIsLoadingStatementChild] = useState(searchType === SearchTypeEnum.StatementChild);

  const location = useLocation();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<SearchRequestsInitialFormData>(
    initialSearchFilters || window.history.state[requestHistoryState.search] || searchRequestsInitialFormData
  );

  const [fieldshModelItem, setFieldsModelItem] = useState<FieldsModelItem | null>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  // может ли пользователь менять организацию
  const canChangeOrganization: boolean = useMemo(
    () =>
      hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) ||
      hasGeneralAccess(userProfile, generalAccess.AdminView) ||
      hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    [userProfile]
  );

  const educationTypeData = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Requests,
        accessAction.ViewRegistry
      ),
    [userProfile.objectAccessActionList]
  );

  const eventId = Number(useQuery().get(EventUrl.EventId));

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const changeFieldsModel = useCallback(
    (educationTypeId?: number) => {
      if (fieldsModel) {
        const type = EducationTypeToCamelCaseEnum[educationTypeId || 0];
        const fieldsModelItem = fieldsModel[!type && educationTypeData.length > 1 ? 'allEducations' : type];
        setFieldsModelItem(fieldsModelItem);
        return fieldsModelItem;
      }
    },
    [educationTypeData.length]
  );

  // установка начальных значений в зависимости от прав пользователя
  const initialFilters = useMemo(() => {
    let submitData = {
      ...searchRequestsInitialFormData,
      organizationId: canChangeOrganization ? searchRequestsInitialFormData.organizationId : userProfile.organizationId,
      organizationName: canChangeOrganization ? undefined : userProfile.organizationName,
      educationTypeId: educationTypeData.length > 0 ? educationTypeData[0].value : undefined,
      educationTypeName: educationTypeData.length > 0 ? educationTypeData[0].label : undefined,
      eventId: eventId || null,
    };

    const fieldsModelLocaleItem = changeFieldsModel(submitData.educationTypeId);

    submitData.vedomstvoId =
      fieldsModelLocaleItem?.vedomstvoId?.defaultValue.id || (isAdmin ? null : userProfile.vedomstvoId);
    submitData.vedomstvoName =
      fieldsModelLocaleItem?.vedomstvoName?.defaultValue.name || (isAdmin ? 'Все' : userProfile.vedomstvoName) || '';

    let doNotSearch = false;
    if (location.state) {
      submitData = { ...submitData, ...(location.state as SearchRequestsInitialFormData) };
    } else if (location.search) {
      const urlParams = parseUrlSearchString(location.search);
      if (urlParams.serviceId) {
        doNotSearch = true;
      }
    }

    if (!doNotSearch) {
      const currentEducationTypeId = window.history.state[requestHistoryState.search]?.educationTypeId;
      currentEducationTypeId && changeFieldsModel(currentEducationTypeId);
      submitForm(window.history.state[requestHistoryState.search] || submitData);
    }

    setTableOptions((prev) => {
      return { ...prev, loadEnable: true };
    });
    return submitData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, educationTypeData, userProfile, canChangeOrganization, setTableOptions, isAdmin, location.state]);

  // виды образования, на которые у пользователя есть доступ
  const allowedEducationTypeId: number = useMemo(() => {
    const allowedEdTypes: EducationTypeEnum[] = getEducationTypes(
      userProfile,
      [accessObject.Requests],
      accessAction.ViewRegistry
    );
    const allowedEdTypeId: number = allowedEdTypes.reduce(
      (previousValue, currentValue) => previousValue | currentValue,
      0
    );
    return allowedEdTypeId === 0 ? EducationTypeEnum.ChildrenEducation : allowedEdTypeId;
  }, [userProfile]);

  // пользователь ДСИТ/ДКГМ/КДЦ
  const isArtHouseSportEducation: boolean = useMemo(() => {
    const otherEducations: number =
      allowedEducationTypeId &
      (EducationTypeEnum.All ^ (EducationTypeEnum.SportEducation | EducationTypeEnum.ArtHouseEducation));
    const isArtHouseSportEd: boolean =
      ((allowedEducationTypeId & EducationTypeEnum.SportEducation) !== 0 ||
        (allowedEducationTypeId & EducationTypeEnum.ArtHouseEducation) !== 0) &&
      otherEducations === 0;

    setTableOptions((prev) => {
      return { ...prev, isArtHouseSportEducation: isArtHouseSportEd, allowedEducationTypeId };
    });
    return isArtHouseSportEd;
  }, [allowedEducationTypeId, setTableOptions]);

  // загрузка справочников департаментов и видов образования
  useEffect(() => {
    setRequestOptions(getRequestEducationLinks(userProfile.objectAccessActionList, []));

    const fetchData = async () => {
      // Данные для кнопки "Новое заявление"
      const educationTypeForCreate = await requestsApi.getEducationTypeForCreate();

      setRequestOptions(getEducationTypeForCreateLinks(educationTypeForCreate));
      setRequestStatusData([{ value: '', label: 'Все' }, ...(await requestsApi.getRequestStatuses())]);
      setRequestSourceData([{ value: '', label: 'Все' }, ...(await dictionariesApi.getRequestSources())]);
      setContractStatusData([{ value: '', label: 'Все' }, ...(await dictionariesApi.getContractStatuses())]);
      if (hasGeneralAccess(userProfile, generalAccess.CheckPrivileges) || isAdmin) {
        const privelegiesAll = await dictionariesApi.getPrivilegeCategories();
        const transformPrivelegies = transformPrivelegiesData(privelegiesAll);
        setPrivilegiesData(transformPrivelegies);
      }
    };

    fetchData().finally(() => {
      setIsLoadingStatementChild(false);
    });
  }, [userProfile, setRequestOptions, isArtHouseSportEducation, isAdmin]);

  useEffect(() => {
    replaceHistoryState({ [requestHistoryState.searchType]: searchType });
  }, [searchType]);

  useEffect(() => {
    initialSearchFilters && setInitialValues(initialSearchFilters);
  }, [initialSearchFilters]);

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [requestHistoryState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  const {
    setServiceKey,
    setPlaceServiceKey,
    setTrainingGroupKey,
    setProgrammLevelKey,
    setOrganizationKey,
    setClassificatorEKUKey,
    setServiceClassKey,
    setPrivilegeCategoryKey,
    setPrivilegeStatusKey,
    statementChildKey,
    setStatementChildKey,
    setTestDateKey,
    setYearOfTrainingKey,
  } = useContext(SearchKeyContext);

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
        sendReachGoal('onclick-request-search');
      }}
      enableReinitialize
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<SearchRequestsInitialFormData>) => {
        const { handleSubmit, values, isSubmitting, isValid, resetForm, setFieldValue } = formikProps;

        const countDiff = getCountDiff(values, initialFilters) + (eventId ? 1 : 0);

        const StatementChildProps = {
          setTableOptions,
          infoChild,
          privilegeStatusList,
          values,
          period,
          isArtHouseSportEducation,
          userProfile,
          contractStatusData,
          requestStatusData,
          requestSourceData,
          privilegiesData,
          allowedEducationTypeId,
        };

        // сброс фильтров и возвращение к начальным фильтрам в поиске
        const onResetForm = () => {
          resetForm({ values: initialFilters });

          const dataToClearValues = [
            { key: 'serviceId', action: setServiceKey },
            { key: 'placeServiceId', action: setPlaceServiceKey },
            { key: 'trainingGroupId', action: setTrainingGroupKey },
            { key: 'programmLevelId', action: setProgrammLevelKey },
            { key: 'organizationId', action: setOrganizationKey },
            { key: 'classificatorEKUId', action: setClassificatorEKUKey },
            { key: 'serviceClassId', action: setServiceClassKey },
            { key: 'privilegeCategoryId', action: setPrivilegeCategoryKey },
            { key: 'privilegeStatusId', action: setPrivilegeStatusKey },
            { key: 'testDate', action: setTestDateKey },
            { key: 'yearOfTrainingId', action: setYearOfTrainingKey },
            { action: setStatementChildKey },
          ];

          let newObj: SearchRequestsInitialFormData = initialFilters;

          if (eventId) {
            history.replace(location.pathname);
            replaceHistoryState({ [requestHistoryState.openAdvanced]: open });
            const { eventId, ...obj } = initialFilters;
            newObj = obj;
          }

          changeFieldsModel(newObj.educationTypeId);

          setAsyncValues(dataToClearValues);

          submitForm(newObj);
        };

        const onChangeEducationTypeHandler = (educationTypeId: number) => {
          if (fieldsModel) {
            const fieldsModelItem = changeFieldsModel(educationTypeId);

            // Нужно для правильной работы счетчика countDiff
            for (const item in fieldsModelItem) {
              if (!fieldsModelItem[item]?.visible) {
                setFieldValue(item, null);
              }
            }
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            <InitFilters submitForm={submitForm} />
            <Panel
              subControl={() => (
                <button type="button" onClick={handleClickAdvancedSearch} className="icon-group">
                  <span className="icon-group__icon">
                    <IconOptions />
                  </span>
                  <span className="icon-group__text">
                    Расширенный поиск {!open && countDiff > 0 && <span className="icon-group__badge">{countDiff}</span>}{' '}
                  </span>
                  <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
                </button>
              )}
              controls={() => (
                <>
                  <Button label="Сбросить" onClick={onResetForm} border size="small" />
                  <Push orientation="horizontal" size={12} />
                  <Button submit label="Начать поиск" disabled={!isValid} load={isSubmitting} primary size="small" />
                </>
              )}
            >
              <div className="container">
                <Push size={20} />
                <FormikInput name="query" placeholder="Поиск по заявлениям..." iconLeft={() => <IconSearch />} />
              </div>
              {open && (
                <>
                  <Push size={8} />
                  <div className="requests-search-tabs">
                    <TabGroup data-test="tab-group">
                      <TabButton
                        active={searchType === SearchTypeEnum.StatementChild}
                        label="Заявление и ребёнок"
                        onClick={() => {
                          setSearchType(SearchTypeEnum.StatementChild);
                        }}
                      />
                      <TabButton
                        active={searchType === SearchTypeEnum.ChildService}
                        label="Детское объединение или услуга"
                        onClick={() => {
                          setSearchType(SearchTypeEnum.ChildService);
                        }}
                      />
                    </TabGroup>
                  </div>
                  <Push size={16} />
                  <div className="container">
                    {searchType === SearchTypeEnum.StatementChild &&
                      (isLoadingStatementChild ? (
                        <LoaderCustom size={150} />
                      ) : (
                        <StatementChild key={statementChildKey} {...StatementChildProps} />
                      ))}

                    {searchType === SearchTypeEnum.ChildService && (
                      <ChildServiceView
                        educationTypeData={educationTypeData}
                        onChangeEducationType={onChangeEducationTypeHandler}
                        allowedEducationTypeId={allowedEducationTypeId}
                        isArtHouseSportEducation={isArtHouseSportEducation}
                        fieldshModelItem={fieldshModelItem}
                        canChangeOrganization={canChangeOrganization}
                      />
                    )}
                  </div>
                </>
              )}
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

export default RequestsSearch;
