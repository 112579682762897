import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useField } from 'formik';
import { Link } from 'react-router-dom';
import { Radio, SimpleTable } from '@mosru/esz_uikit';
import Field, { FieldProps } from '../../../../components/fields/field';
import { routes } from '../../../../config/constants';

import { ServiceClassListData, ServiceClassPeriodListScheduleData } from '../../../../types/service-class';
import serviceClassApi from '../../../../lib/api/service-class';
import { serviceIdField } from './service';
import { scheduleOfTimetableIdField, trainingGroupIdFieldId } from './admission-plan';
import { BookingResultData } from '../../../../types/booking';
import { bookingApi } from '../../../../lib/api/booking';
import { schoolOrganizationIdField } from './organization';
import { EducationTypeEnum } from '../../../../types/education-type';

export const serviceClassIdField = 'serviceClass';

type ServiceClassProps = FieldProps<number> & {
  parent?: string;
  serviceClassList?: ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null;
  setServiceClassList?: Dispatch<SetStateAction<ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null>>;
  setInitialDataServiceClassList?: Dispatch<
    SetStateAction<ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null>
  >;
  setBooking?: Dispatch<SetStateAction<BookingResultData | undefined>>;
  booking?: BookingResultData;
  curentServiceClassId?: number;
};

const ServiceClassField = ({
  label = 'Группа по уходу и присмотру за детьми школьного возраста',
  name,
  editMode,
  required,
  defaultValue,
  parent,
  serviceClassList,
  setServiceClassList,
  setInitialDataServiceClassList,
  setBooking,
  booking,
  curentServiceClassId,
}: ServiceClassProps) => {
  const [disabledServiceClass, setDisabledServiceClass] = useState<number[]>([]);

  const [serviceField] = useField<number>(parent ? `${parent}.${serviceIdField}` : serviceIdField);
  const [trainingGroupIdField] = useField(trainingGroupIdFieldId);
  const [field, , helpers] = useField(parent ? `${parent}.${name}` : name);

  const [scheduleOfTimetableField] = useField<number>(
    parent ? `${parent}.${scheduleOfTimetableIdField}` : scheduleOfTimetableIdField
  );

  const [schoolOrganizationIdOption] = useField<number>(
    parent ? `school.${schoolOrganizationIdField}` : schoolOrganizationIdField
  );

  useEffect(() => {
    const fetch = async () => {
      const tgList = await serviceClassApi.getServiceClassList(
        serviceField.value ?? 0,
        trainingGroupIdField.value ?? 0,
        undefined,
        EducationTypeEnum.DayCareCentersEducation,
        curentServiceClassId
      );

      setServiceClassList && setServiceClassList(tgList);
      setInitialDataServiceClassList && setInitialDataServiceClassList(tgList);
    };

    if (serviceField.value) {
      fetch();
    } else {
      setServiceClassList && setServiceClassList(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField.value, trainingGroupIdField.value]);

  return serviceClassList &&
    serviceField.value &&
    scheduleOfTimetableField.value &&
    schoolOrganizationIdOption.value ? (
    <Field label={label} editMode={editMode} disabled={!editMode} required={required} defaultValue={defaultValue}>
      <SimpleTable
        data={serviceClassList}
        hideSort
        headerBorderTop={false}
        columns={[
          {
            dataIndex: 'id',
            title: '',
            render: (item: any) => {
              return (
                <Radio
                  name=""
                  labelId=""
                  disabled={!editMode || disabledServiceClass.includes(item.id)}
                  checked={Boolean(field?.value?.id === item.id && (setBooking ? booking : true))}
                  onChange={async () => {
                    if (setBooking) {
                      setBooking(undefined);
                      try {
                        const bookingData = await bookingApi.preBookingByServiceClass(item.id);
                        setBooking(bookingData);
                      } catch {
                        setBooking(undefined);
                        setDisabledServiceClass((prev) => [...prev, item.id]);
                      }
                    }

                    helpers.setValue({
                      id: item.id,
                      name: item.name,
                      code: item.code,
                      teacher: item.teacher,
                      included: item.included,
                      capacity: item.capacity,
                    });
                  }}
                />
              );
            },

            width: '40px',
          },
          {
            dataIndex: 'name',
            title: 'Группа по уходу и присмотру за детьми школьного возраста',
            render: (item: any) => {
              return (
                <Link
                  className="brand-link"
                  to={`${routes.serviceClasses}/${item.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.code}, {item.name}, {item.teacherName}
                </Link>
              );
            },
          },
          {
            dataIndex: 'numbers',
            title: <div style={{ minWidth: '150px' }}>Кол-во мест в группе (свободно/всего)</div>,
            width: '180px',
            render: (item: any) => (
              <>
                {item.freeVolume}/{item.capacity}
              </>
            ),
          },
        ]}
      />
    </Field>
  ) : null;
};

export default ServiceClassField;
