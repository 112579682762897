import { Link } from 'react-router-dom';
import { Button, ModalPanel, Modal } from '@mosru/esz_uikit';
import { ArchiveObstacle } from '../../../../types/service';
import { generateLink, sliceText } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';

type Props = {
  show: boolean;
  onCloseHandler: () => void;
  data?: ArchiveObstacle;
};

const ErrorArchiveModal = ({ show, onCloseHandler, data }: Props) => {
  return (
    <Modal show={show} onClose={onCloseHandler}>
      <ModalPanel
        size="small"
        onClose={onCloseHandler}
        modalTitle="Пояснение к переводу в архив"
        renderComponent={() => (
          <div className="service-panels-list-gl modal-description">
            {!!data?.requestList.length && (
              <div className="service-panels-list-gl__item">
                Необходимо перевести:
                {data?.requestList.map((item, i) => (
                  <div>
                    — в конечный статус заявление "
                    <Link to={generateLink(routes.request, { id: item.id })} target="_blank">
                      {item.name}
                    </Link>
                    "{i < data.requestList.length - 1 ? ';' : '.'}
                  </div>
                ))}
              </div>
            )}
            {!!data?.pupilList.length && (
              <div className="service-panels-list-gl__item">
                Необходимо исключить:
                {data?.pupilList.map((item, i) => (
                  <div>
                    — обучающегося "
                    <Link to={generateLink(routes.learner, { id: item.id })} target="_blank">
                      {item.name}
                    </Link>
                    " из группы обучения "
                    <Link to={generateLink(routes.serviceClass, { id: item.serviceClassId })} target="_blank">
                      {sliceText(item.serviceClassName, 50)}
                    </Link>
                    "{i < data.pupilList.length - 1 ? ';' : '.'}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        controls={() => <Button primary label="Закрыть" onClick={onCloseHandler} />}
      />
    </Modal>
  );
};

export default ErrorArchiveModal;
