import React from 'react';
import { useField } from 'formik';
import { DatePickerComponent, DatePickerType } from '@mosru/esz_uikit';

export type FormicDatePickerProps = DatePickerType & {
  name: string;
  onChangeValue?: (date: Date | [Date, Date | null] | null) => void;
};

const FormikDatePicker: React.FC<Omit<FormicDatePickerProps, 'onChange'>> = ({ name, onChangeValue, ...props }) => {
  const { error: propsError, startDate, endDate } = props;
  const [field, meta, helpers] = useField(name);

  const error = meta.touched && meta.error && meta.initialValue !== field.value;
  const errorText = typeof propsError === 'undefined' ? error && (meta.error as string) : propsError;

  const start =
    startDate ||
    (field.value && field.value !== undefined && field.value !== null
      ? new Date(Array.isArray(field.value) ? field.value[0] : field.value)
      : undefined);
  const end =
    endDate ||
    (field.value && field.value !== undefined && field.value !== null && Array.isArray(field.value) && field.value[1]
      ? new Date(field.value[1])
      : undefined);

  return (
    <DatePickerComponent
      {...field}
      {...props}
      onChange={(date) => {
        !meta.touched && helpers.setTouched(true);
        helpers.setValue(date);

        onChangeValue && onChangeValue(date);
      }}
      error={errorText || ''}
      startDate={start}
      endDate={end}
    />
  );
};

export default FormikDatePicker;
