import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader, Push } from '@mosru/esz_uikit';
import { EducationTypeEnum } from '../../types/education-type';
import { ServiceData, TemplateService } from '../../types/service';
import TemplateServicesCreate from './template-services/create';
import { templateApi } from '../../lib/api/template';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { accessVedomst } from '../../mock-data/access-enum';

export type CreateTemplateContext = {
  serviceData: ServiceData;
  edType: string | number | undefined;
  isKdc: boolean;
};

export const TemplateContext = createContext<CreateTemplateContext>({} as CreateTemplateContext);

const CreateTemplate = () => {
  const [serviceData, setServiceData] = useState<TemplateService.Data>();
  const { edType } = useParams<Record<string, string>>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isDkgm = useMemo(() => userProfile.vedomstvoId === accessVedomst.Dkgm, [userProfile.vedomstvoId]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await templateApi.getEmptyTemplate(parseInt(edType));
        data.info.durationOfTrainingUnitId = 1;
        data.test.toursNumber = 1;
        if (isDkgm && data.educationTypeId === EducationTypeEnum.ArtHouseEducation) {
          data.info.hoursPerWeek = 1;
        }
        setServiceData(data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [edType, isDkgm]);

  const initialValues: CreateTemplateContext = useMemo(
    () => ({
      serviceData: serviceData || ({} as ServiceData),
      edType,
      isKdc: userProfile.vedomstvoId === accessVedomst.Kdc,
    }),
    [serviceData, edType, userProfile]
  );

  return (
    <>
      {serviceData ? (
        <TemplateContext.Provider value={initialValues}>
          <TemplateServicesCreate />
        </TemplateContext.Provider>
      ) : isLoading ? (
        <>
          <Push size={12} />
          <div className="loader-auto loader-auto--bg">
            <Loader roller />
          </div>
        </>
      ) : null}
    </>
  );
};

export default CreateTemplate;
