import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Badge, Button, ButtonGroup, Push } from '@mosru/esz_uikit';
import ChangeHistory from '../../../components/change-history';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/remove.svg';
import { ReactComponent as IconArchive } from '../../../assets/images/icons/hard-drive.svg';
import TestsDetails from './tests-details';
import { TrainingGroupStatusEnum } from '../../../mock-data/training-group-status-enum';
import { serviceTemplateApi } from '../../../lib/api/service-template';
import { formatTime, formatTimeFromDate } from '../../../lib/utils/date';
import { TypesOfRepetitionEnum } from '../../../mock-data/type-of-repetition';
import { ScheduleData } from '../../../types/service';
import SimpleModal from '../components/modals/simple-modal';
import { generateLink, parseUrlSearchString } from '../../../lib/utils';
import history from '../../../history';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';

export type ScheduleDataEx = ScheduleData & {
  requestTimeStart: string;
  requestTimeEnd: string;
  repeat: boolean;
  endDate: boolean;
  everyday?: boolean;
  everymonth?: boolean;
  everyWeekRepeatNumber1?: number;
  everymonthRepeatNumber1?: number;
  everymonthRepeatNumber2?: number;
  everymonthRepeatNumber3?: number;
  everymonthRepeatNumber4?: number;
};

const Tests = () => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const params = useParams<{ serviceId: string; tgId: string; id: string }>();
  const [schedule, setSchedule] = useState<ScheduleDataEx>({} as ScheduleDataEx);
  const [removedId, setRemovedId] = useState<number | undefined>();
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const [educationType, setEducationType] = useState<number>(0);
  const location = useLocation();
  const { isRetro } = parseUrlSearchString(location.search);

  const canDelete = useMemo(
    () =>
      schedule && schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Draft && schedule.requestTotalCount === 0,
    [schedule]
  );

  useEffect(() => {
    const fetch = async (id: string) => {
      const result = await serviceTemplateApi.getServiceById(id);
      setEducationType(result.educationTypeId ?? 0);
    };

    if (params.serviceId && params.serviceId !== '0') {
      fetch(params.serviceId);
    }
  }, [params.serviceId]);

  const getSchedule = useCallback(async () => {
    if (params.id && params.id !== '0') {
      const result = await serviceTemplateApi.getTrainingSchedule(params.id);
      if (result) {
        setSchedule({
          ...result,
          requestTimeStart: result.requestStart ? formatTimeFromDate(result.requestStart) : '',
          requestTimeEnd: result.requestEnd ? formatTimeFromDate(result.requestEnd) : '',
          timeStart: result.timeStart ? formatTime(result.timeStart) : result.timeStart,
          timeEnd: result.timeEnd ? formatTime(result.timeEnd) : result.timeEnd,
          repeat: !!result.typesOfRepetitionId && result.typesOfRepetitionId !== TypesOfRepetitionEnum.NoRepeat,
          endDate: !!result.periodTo,
          everyday: (result.repeatNumber1 ?? 0) === 0,
          everymonth: (result.dayOfWeek ?? 0) > 0,
          everyWeekRepeatNumber1:
            result.typesOfRepetitionId === TypesOfRepetitionEnum.RepeatWeekly ? result.repeatNumber1 : 1,
          everymonthRepeatNumber1: (result.dayOfWeek ?? 0) > 0 ? 1 : result.repeatNumber1,
          everymonthRepeatNumber2: (result.dayOfWeek ?? 0) > 0 ? 1 : result.repeatNumber2,
          everymonthRepeatNumber3: (result.dayOfWeek ?? 0) === 0 ? 1 : result.repeatNumber1,
          everymonthRepeatNumber4: (result.dayOfWeek ?? 0) === 0 ? 1 : result.repeatNumber2,
        });
      }
    } else {
      setSchedule({ id: 0, trainingGroupStatusId: TrainingGroupStatusEnum.Draft } as ScheduleDataEx);
    }
  }, [params.id]);

  useEffect(() => {
    getSchedule();
  }, [params?.id, getSchedule]);

  const handleEditModal = useCallback(async () => {
    await serviceTemplateApi.updateTrainingScheduleStatus({
      serviceId: params.serviceId,
      educationTypeId: schedule.educationTypeId,
      scheduleOfTimetableId: schedule.id,
      trainingGroupStatusId: TrainingGroupStatusEnum.Unavailable,
    });
    getSchedule();
  }, [schedule, params.serviceId, getSchedule]);

  const tabs = useMemo(
    () => [
      {
        label: 'Основные сведения',
        component: (
          <TestsDetails
            scheduleData={schedule}
            getSchedule={getSchedule}
            serviceId={parseInt(params.serviceId)}
            educationType={educationType}
            isRetro={isRetro}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'История изменений',
        component: (
          <ChangeHistory
            name=""
            extendEntityGuid={schedule?.trainingGroupExtendEntityGuid}
            entityTypes={[EntityTypeEnum.Service]}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ],
    [schedule, getSchedule, params.serviceId, educationType, isRetro]
  );

  const getStatus = (status: TrainingGroupStatusEnum) => {
    switch (status) {
      case TrainingGroupStatusEnum.Draft:
        return <Badge text="Черновик" color="default" />;
      case TrainingGroupStatusEnum.Unavailable:
        return <Badge text="Приема нет" color="warning" />;
      case TrainingGroupStatusEnum.Signed:
        return <Badge text="Опубликовано" color="success" />;
      case TrainingGroupStatusEnum.Archive:
        return <Badge text="Архив" color="default" />;
      default:
        break;
    }
  };

  const updateStatus = useCallback(
    async (statusId: TrainingGroupStatusEnum) => {
      await serviceTemplateApi.updateTrainingScheduleStatus({
        serviceId: params.serviceId,
        educationTypeId: schedule.educationTypeId,
        scheduleOfTimetableId: schedule.id,
        trainingGroupStatusId: statusId,
      });
      getSchedule();
    },
    [params.serviceId, schedule.educationTypeId, schedule.id, getSchedule]
  );

  return (
    <>
      <PageHeading
        buttonBack
        buttonBackFn={() =>
          history.push(generateLink(routes.trainingGroup, { serviceId: params.serviceId, id: params.tgId }))
        }
        title={`Вступительные испытания${isRetro ? ' без публикации на Mos.ru' : ''}`}
        sections={[
          { title: 'Главная', link: routes.main },
          { title: 'Образовательные услуги', link: routes.services },
          { title: 'Описание образовательной услуги', link: generateLink(routes.service, { id: params.serviceId }) },
          {
            title: 'План приема',
            link: generateLink(routes.trainingGroup, { serviceId: params.serviceId, id: params.tgId }),
          },
          { title: 'Вступительные испытания' },
        ]}
        tabsButton={getStatus(schedule.trainingGroupStatusId)}
        tabs={tabs}
        controlsTopRight={
          (schedule.isRetro ||
            [TrainingGroupStatusEnum.Draft, TrainingGroupStatusEnum.Unavailable].includes(
              schedule.trainingGroupStatusId
            )) &&
          canDelete ? (
            <ButtonGroup>
              <Button
                label="В архив"
                iconLeft={() => <IconArchive />}
                size="small"
                onClick={async () => {
                  await updateStatus(TrainingGroupStatusEnum.Archive);
                }}
              />
              <Button
                label="Удалить"
                iconLeft={() => <IconRemove />}
                size="small"
                onClick={async () => {
                  setRemovedId(schedule.id);
                }}
              />
            </ButtonGroup>
          ) : schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Unavailable ? (
            <div className="flex">
              <Button
                label="В архив"
                iconLeft={() => <IconArchive />}
                size="small"
                border
                onClick={async () => {
                  await updateStatus(TrainingGroupStatusEnum.Archive);
                }}
              />
              <Push size={12} orientation="horizontal" />
              <Button
                label="Возобновить прием"
                size="small"
                primary
                onClick={async () => {
                  await updateStatus(TrainingGroupStatusEnum.Signed);
                }}
              />
            </div>
          ) : (
            !schedule.isRetro &&
            schedule.trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
            schedule.requestTotalCount > 0 && (
              <Button
                label="Остановить прием"
                size="small"
                primary
                onClick={async () => {
                  setShowUnavailableModal(true);
                }}
              />
            )
          )
        }
        activeTab={activeTab}
      />
      {tabs.find((item) => item.label === activeTab)?.component}
      <SimpleModal
        show={showUnavailableModal}
        handleSubmit={handleEditModal}
        onClose={() => setShowUnavailableModal(false)}
        title="Остановка приема заявлений"
        description="Внимание! После подтверждения прием заявлений на Mos.ru будет остановлен."
      />
      <SimpleModal
        show={!!removedId}
        handleSubmit={async () => {
          await serviceTemplateApi.deleteTrainingSchedule(params.serviceId, schedule.id);
          history.push(
            `${generateLink(routes.trainingGroup, {
              serviceId: params.serviceId,
              id: params.tgId ?? 0,
            })}?isRetro=${isRetro}`
          );
        }}
        onClose={() => setRemovedId(undefined)}
        title="Удаление расписания вступительных испытаний"
        description="Вы уверены, что хотите удалить выбранное расписание вступительных испытаний? Обратите внимание, что вся информация о выбранном расписании вступительных испытаний будет утеряна."
        labelOk="Да, удалить"
      />
    </>
  );
};

export default Tests;
