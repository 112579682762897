import React from 'react';
import { useField } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import { FieldProps } from '../../../../components/fields/field';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';

type NameCodeFieldProps = FieldProps<SelectOptionType> & { isCreate?: boolean };

const NameCodeField = ({
  label = 'Наименование программы',
  editMode,
  required,
  parent,
  isCreate,
}: NameCodeFieldProps) => {
  const name = parent ? `${parent}.name` : 'name';
  const code = parent ? `${parent}.code` : 'code';

  const [fieldName] = useField(name);
  const [fieldCode] = useField(code);

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main">
        {label} {editMode && required && <span className="table-data__required" />}
      </div>
      <div className="table-data__body">
        <div className={`table-data-grid-${isCreate ? '' : '3-1'} items-center`}>
          <div>
            {editMode ? (
              <FormikFormGroup required label="" name={name}>
                <FormikInput name={name} size="small" placeholder="Введите..." maxLength={1000} />
              </FormikFormGroup>
            ) : (
              fieldName.value || '—'
            )}
          </div>

          {!isCreate && (
            <div>
              <div className="table-data__group">
                <div className="table-data__label">Код</div>
                <div className="table-data__body">{fieldCode.value || '—'}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameCodeField;
