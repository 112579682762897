import React, { useContext, useEffect, useState } from 'react';
import { Button, Infobox, Panel, Loader, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { Formik, FormikProps } from 'formik';
import { array as arrayYup, object as objectYup, string as stringYup } from 'yup';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';
import { ServiceContext } from '../../index';
import { StageData } from '../../../../types/service';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { MAX_STAGE_NAME_LENGTH } from '../../utils';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  label?: string;
};

const Period: React.FC<Props> = ({ setEditModeParent }) => {
  const { serviceData, updateService, accessPanelEdit } = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);

  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [optionsState, setOptionsState] = useState<StageData[]>(serviceData?.stage?.list);

  useEffect(() => {
    setOptionsState(serviceData?.stage?.list);
  }, [serviceData?.stage?.list]);

  const checkEditable =
    !editMode &&
    setEditModeParent &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Arhive) &&
    !(serviceData.serviceStatusId === ServiceStatusEnum.Signed) &&
    accessPanelEdit;

  const submitHandler = async (values: StageData[]) => {
    setLoading(true);
    try {
      await serviceTemplateApi.updateStages(serviceData.id, { ...serviceData.stage, list: values });
      setEditModeParent && setEditModeParent(null);
      setEditMode(false);
      updateService();
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : optionsState ? (
    <>
      <Push size={12} />
      <Formik
        initialValues={optionsState}
        validateOnChange
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, isValid, resetForm, handleSubmit }: FormikProps<StageData[]>) => (
          <>
            <Panel
              title={() => (
                <>
                  Учебные периоды{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {values?.length}
                  </span>
                </>
              )}
              headingControl={() => {
                return checkEditable ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent && setEditModeParent('period');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              {setEditModeParent ? (
                <Table
                  key={String(editMode) + values.length}
                  data={values}
                  hideSort
                  columns={
                    editMode
                      ? [
                          {
                            dataIndex: 'number',
                            title: '№',
                            render: (item: any) => (
                              <div className="table-row-item-height flex items-center">{item.orderNumber}</div>
                            ),
                            width: '40px',
                          },
                          {
                            dataIndex: 'name',
                            title: 'Наименование ',
                            render: (item: any) => {
                              const index = values?.findIndex((entry) => entry.id === item.id);

                              return (
                                <div className="flex">
                                  <div className="flex-auto">
                                    <FormikFormGroup label="" name={`[${index}].name`} showErrorImmediately>
                                      <FormikInput
                                        size="small"
                                        name={`[${index}].name`}
                                        value={values?.[index]?.name}
                                      />
                                    </FormikFormGroup>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]
                      : [
                          {
                            dataIndex: 'number',
                            title: '№',
                            render: (item: any) => item.orderNumber,
                            width: '40px',
                          },
                          {
                            dataIndex: 'name',
                            title: 'Наименование',
                            render: (item: any) => <div className="overflow-wrap-anywhere">{item.name}</div>,
                          },
                        ]
                  }
                />
              ) : (
                <div className="container">
                  <Infobox
                    fullWidth
                    color="warning"
                    text="Редактирование учебных периодов станет доступны для ввода после первого сохранения."
                  />
                  <Push size={24} />
                </div>
              )}
            </Panel>

            {editMode && setEditModeParent && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={async () => {
                          await updateService();
                          resetForm({ values: optionsState });
                          setEditModeParent && setEditModeParent(null);
                          setEditMode(false);
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button disabled={!isValid} submit onClick={handleSubmit} primary label="Сохранить" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  ) : null;
};

export default Period;

const validationSchema = arrayYup()
  .nullable()
  .of(
    objectYup().shape({
      name: stringYup()
        .nullable()
        .max(MAX_STAGE_NAME_LENGTH, `Должно содержать не более ${MAX_STAGE_NAME_LENGTH} символов`),
    })
  );
