import React from 'react';
import { useFormikContext } from 'formik';
import { Panel, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikInput from '../../../../components/formik/formik-input';
import ParallelField from '../../components/fields/parallel';
import { ClassificatorNameField } from '../../components/fields/classificator-name';
import { ServiceDuration } from '../../components/fields/service-duration';
import { LessonLevelField } from '../../components/fields/lesson-level';
import { Dictionary } from '../../components/fields/dictionary';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { GroupMembersField } from '../../components/fields/group-members';
import SimpleTextArea from '../../../../components/fields/simple-textarea';
import FormikTextarea from '../../../../components/formik/formik-textarea';
import { ServiceData } from '../../../../types/service';
import OrganizationField from '../../components/fields/organization';
import { allowOnlyNumbers } from '../../../../lib/utils/validation';
import FormikSelect from '../../../../components/formik/formik-select';

export const Info = () => {
  const { values } = useFormikContext<ServiceData>();

  const typeOfAttendance = {
    label: values.info.typeOfAttendanceName,
    value: values.info.typeOfAttendanceId as number,
  };

  return (
    <Panel title={() => 'Сведения об услуге'}>
      <div className="container">
        <div className="table-data__item table-data__group">
          <div className="table-data__body">
            <OrganizationField
              required
              disabled
              isCreate
              editMode
              parent="info"
              name="organization"
              label="Образовательная организация"
            />
          </div>
        </div>

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">
            Наименование услуги <span className="table-data__required" />
          </div>
          <div className="table-data__body">
            <FormikFormGroup required label="" name="info.name">
              <FormikInput name="info.name" size="small" placeholder="Введите..." />
            </FormikFormGroup>
          </div>
        </div>

        <ParallelField parent="info" name="parallel" required editMode />

        <ClassificatorNameField parent="info" editMode name="classificator" modalTitle="Реестр базовых направлений" />

        <ServiceDuration parent="info" editMode />

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Занятия</div>
          <div className="table-data__body">
            <div className="table-data-grid-3">
              <div className="table-data__group">
                <div className="table-data__label">Форма</div>
                <div className="table-data__body" style={{ width: 150 }}>
                  <Dictionary
                    name="serviceForm"
                    parent="info"
                    editMode
                    isNotTableMain
                    dictionaryFunc={dictionariesApi.getServiceForms}
                  />
                </div>
              </div>

              <LessonLevelField
                editMode
                parent="info"
                isNotTableMain
                label="Уровень"
                name="programmLevel"
                placeholder="Выберите..."
                disabled={!values.info?.classificatorEKUId}
                disabledPlaceholder="Сначала заполните поле Вид деятельности"
                required
                hasTooltip
              />
              <div className="table-data__group">
                <div className="table-data__label">Вид посещения</div>
                <div className="table-data__body" style={{ width: 200 }}>
                  <FormikFormGroup required label="" name="typeOfAttendanceId">
                    <FormikSelect
                      size="small"
                      isSearchable={false}
                      hideClearIndicator
                      placeholder="Выберите"
                      name="typeOfAttendanceId"
                      options={[typeOfAttendance]}
                      defaultValue={typeOfAttendance}
                    />
                  </FormikFormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Количество в неделю</div>
          <div className="table-data__body">
            <div className="flex items-start">
              <div className="table-data__group" style={{ width: 160 }}>
                <div className="table-data__label">
                  <div>
                    Занятий <span className="table-data__required" />
                  </div>
                </div>
                <div className="table-data__body">
                  <FormikFormGroup required label="" name="info.lessonCountPerWeek">
                    <FormikInput
                      number
                      size="small"
                      maxLength={3}
                      placeholder="0"
                      name="info.lessonCountPerWeek"
                      onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    />
                  </FormikFormGroup>
                </div>
              </div>

              <Push size={12} orientation="horizontal" />

              <div className="table-data__group" style={{ width: 160 }}>
                <div className="table-data__label">Часов</div>
                <div className="table-data__body">
                  <FormikFormGroup required label="" name="info.hoursPerWeek">
                    <FormikInput
                      number
                      size="small"
                      maxLength={3}
                      placeholder="0"
                      name="info.hoursPerWeek"
                      onKeyPress={(e: any) => allowOnlyNumbers(e)}
                    />
                  </FormikFormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>

        <GroupMembersField parent="info" isCreate editMode />

        <SimpleTextArea
          required
          editMode
          label="Описание услуги"
          placeholder="Введите..."
          name="info.programmService"
          maxLength={1000}
        />

        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Правила посещения</div>
          <div className="table-data__body">
            <FormikFormGroup name="info.ruleService" label="" horizontalLabelStart required>
              <FormikTextarea name="info.ruleService" rows={2} placeholder="Введите..." maxLength={1000} />
            </FormikFormGroup>
          </div>
        </div>
      </div>
    </Panel>
  );
};
