import React, { useContext, useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectOptionType, Push } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../components/formik/formik-select';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import { EducationTypeEnum } from '../../../../types/education-type';
import requestsApi from '../../../../lib/api/requests';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { SearchKeyContext } from '../contexts/key-context';

type Props = {
  values: SearchRequestsInitialFormData;
  allowedEducationTypeId: number;
  isArtHouseSportEducation: boolean;
  label: string;
  placeholder: string;
};

const allOption = { value: null, label: 'Все' };

const FieldProgrammLevel: React.FC<Props> = ({
  allowedEducationTypeId,
  values,
  isArtHouseSportEducation,
  label,
  placeholder,
}) => {
  const [programmLevelData, setProgrammLevelData] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    if (isArtHouseSportEducation) {
      const fetch = async () => {
        const dataProgrammLevels = await requestsApi.getProgrammLevels();
        dataProgrammLevels && setProgrammLevelData([allOption, ...dataProgrammLevels]);
      };
      fetch();
    }
  }, [isArtHouseSportEducation]);

  useEffect(() => {
    const fetchProgrammLevels = async (classificatorEKUId: number) => {
      setProgrammLevelData([allOption, ...(await serviceTemplateApi.getProgrammLevels({ classificatorEKUId }))]);
    };
    if (values.classificatorEKUId) {
      fetchProgrammLevels(values.classificatorEKUId);
    } else {
      setProgrammLevelData([]);
    }
  }, [values.classificatorEKUId]);

  const { programmLevelKey } = useContext(SearchKeyContext);

  const [, , helpers] = useField('programmLevelId');
  const [, , helpersName] = useField('programmLevelName');

  const onChange = (option: SelectOptionType) => {
    helpers.setValue(option.value);
    helpersName.setValue(option.label);
  };

  return allowedEducationTypeId !== EducationTypeEnum.DayCareCentersEducation ? (
    <>
      <Push size={16} />
      <FormikFormGroup name="programmLevelId" label={label}>
        <FormikSelect
          key={programmLevelKey}
          disabled={!values.classificatorEKUId && !isArtHouseSportEducation}
          name="programmLevelId"
          size="small"
          isSearchable
          options={programmLevelData}
          placeholder={placeholder}
          defaultValue={
            values.programmLevelId
              ? { label: values.programmLevelName ?? '', value: values.programmLevelId }
              : values.programmLevelName === allOption.label
              ? allOption
              : null
          }
          selectedValue={onChange}
        />
      </FormikFormGroup>
    </>
  ) : null;
};

export default FieldProgrammLevel;
