import React from 'react';
import { Infobox, Push } from '@mosru/esz_uikit';
import { ErrorPerson } from '../../../../../types/like-modal';

type Props = ErrorPerson;

export const Error: React.FC<Props> = ({ color, text, show }) => {
  const currentInfo = (color: string) => {
    switch (color) {
      case 'danger':
        return <Infobox fullWidth color={color} text={text} />;
      case 'success':
        return <Infobox fullWidth color={color} text={`Создана новая персона в МЭШ.Контингент: ${text}.`} />;
      default:
        return null;
    }
  };

  return (
    <>
      {show && currentInfo(color)}
      {show && !(text === 'success') && <Push size={12} orientation="vertical" />}
    </>
  );
};
