import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Infobox, Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit-color.svg';
import { ItemWorkData } from '../../../../types/service';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  addresses: ItemWorkData[];
  serviceId?: number;
};

const AddressDocuments: React.FC<Props> = ({ setEditModeParent, addresses, serviceId }) => {
  const [editMode, setEditMode] = useState(!setEditModeParent);
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {}, [serviceId, editMode]);

  const columns = useMemo(
    () =>
      editMode
        ? [
            {
              dataIndex: '1',
              title: (
                <Checkbox
                  labelId=""
                  checked={selected.length === addresses.length}
                  indeterminate={!!selected.length && selected.length !== addresses.length}
                  onChange={() => {
                    setSelected(selected.length ? [] : addresses.map((selectedItem) => selectedItem.id));
                  }}
                />
              ),
              render: (item: any) => (
                <Checkbox
                  labelId=""
                  checked={selected.includes(item.number)}
                  onChange={() => {
                    setSelected(
                      selected.includes(item.number)
                        ? selected.filter((selectedItem) => selectedItem !== item.number)
                        : [...selected, item.number]
                    );
                  }}
                />
              ),
              width: '60px',
            },
            {
              dataIndex: 'number',
              title: '№',
              render: (item: any) => item.number,
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование организации',
              render: (item: any) => item.name,
              width: '45%',
            },
            {
              dataIndex: 'address',
              title: 'Адрес',
              render: (item: any) => item.address,
              width: '30%',
            },
            {
              dataIndex: 'schedule',
              title: 'Время работы',
              render: (item: any) => (
                <div className="flex items-start">
                  <div>{item.schedule}</div>
                  <Push size={4} orientation="horizontal" />
                  {/* Использовать модалку с рассписанием */}
                  <button type="button" className="icon-group flex-none">
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                  </button>
                </div>
              ),
              width: '20%',
            },
          ]
        : [
            {
              dataIndex: 'number',
              title: '№',
              render: (item: any) => item.number,
              width: '40px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование организации',
              render: (item: any) => item.name,
              width: '45%',
            },
            {
              dataIndex: 'address',
              title: 'Адрес',
              render: (item: any) => item.address,
              width: '30%',
            },
            {
              dataIndex: 'schedule',
              title: 'Время работы',
              render: (item: any) => item.schedule,
              width: '20%',
            },
          ],
    [editMode, selected, addresses]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Адрес приема документов{' '}
            {setEditModeParent && (
              <span className="color-gray-dark">
                {' \u00A0'} {addresses.length}
              </span>
            )}
          </>
        )}
        headingControl={() => {
          return !editMode && setEditModeParent ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent('address-documents');
                setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconEdit />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        {setEditModeParent ? (
          <Table key={String(editMode)} data={addresses ?? []} hideSort columns={columns} />
        ) : (
          <div className="container">
            <Infobox
              fullWidth
              color="warning"
              text="Адреса приема документов станут доступными для ввода после первого сохранения."
            />
            <Push size={24} />
          </div>
        )}
      </Panel>

      {editMode && setEditModeParent && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  onClick={() => {
                    setEditModeParent && setEditModeParent(null);
                    setEditMode(false);
                  }}
                  border
                  primary
                  label="Отмена"
                />
                <Push size={12} orientation="horizontal" />
                <Button submit onClick={() => {}} primary label="Сохранить" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressDocuments;
