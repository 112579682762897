import React, { useCallback } from 'react';
import { Formik, FormikProps } from 'formik';
import { date as dateYup, object as objectYup, string as stringYup } from 'yup';
import { Button, ModalPanel, Modal, Infobox, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import FormikDatePicker from '../../../../components/formik/formik-datepicker';
import FormikInput from '../../../../components/formik/formik-input';
import { timeMaskFunction } from '../../../../lib/utils/mask';
import { ReactComponent as IconClock } from '../../../../assets/images/icons/clock-color.svg';

type Props = {
  show: boolean;
  hideModal: () => void;
};

const initialValues = {
  dateStart: '',
  dateEnd: '',
  seats: '',
  restrictions: false,
  dateStartMosru: '',
  dateEndMosru: '',
  timeStartMosru: '',
  timeEndMosru: '',
};

const AddPlan: React.FC<Props> = ({ show, hideModal }) => {
  const initialErrors = useInitialErrors(initialValues, getValidationSchema());
  const submitForm = useCallback(async (values: any) => {}, []);

  return (
    <Modal show={show} onClose={hideModal}>
      <Formik
        initialErrors={initialErrors}
        validationSchema={getValidationSchema()}
        onSubmit={submitForm}
        enableReinitialize
        initialValues={initialValues}
      >
        {(formikProps: FormikProps<any>) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalPanel
                size="medium"
                overflow
                onClose={hideModal}
                controls={() => (
                  <>
                    <Button label="Отмена" primary border size="small" onClick={hideModal} />
                    <Push orientation="horizontal" size={12} />
                    <Button
                      label="Добавить"
                      disabled={!isValid || isSubmitting}
                      primary
                      size="small"
                      onClick={() => {}}
                    />
                  </>
                )}
                modalTitle="Добавить план приема"
                renderComponent={() => (
                  <>
                    <FormikFormGroup name="" label="Дата начала занятий / Учебный период" required>
                      <div className="flex items-start">
                        <div className="flex-auto">
                          <FormikFormGroup name="dateStart" label="">
                            <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="dateStart" />
                          </FormikFormGroup>
                        </div>
                        <div className="flex items-center flex-none table-row-item-height">
                          <Push size={8} orientation="horizontal" />
                          —
                          <Push size={8} orientation="horizontal" />
                        </div>
                        <div className="flex-auto">
                          <FormikFormGroup name="dateEnd" label="">
                            <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="dateEnd" />
                          </FormikFormGroup>
                        </div>
                      </div>
                    </FormikFormGroup>

                    <Push size={16} />
                    <FormikFormGroup name="" label="Количество мест" required>
                      <div style={{ width: 88 }}>
                        <FormikInput name="seats" number size="small" disabled />
                      </div>
                    </FormikFormGroup>

                    <Push size={8} />
                    <Infobox
                      fullWidth
                      color="warning"
                      text="Данный параметр рассчитывается автоматически и является суммой значений предельного кол-ва человек в связанных группах обучения."
                    />

                    <Push size={16} />
                    <FormikFormGroup name="" label="Период приема заявлений на Mos.ru" required>
                      <div className="flex items-start">
                        <div className="flex flex-auto">
                          <div className="flex-auto">
                            <FormikFormGroup name="dateStartMosru" label="">
                              <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="dateStartMosru" />
                            </FormikFormGroup>
                          </div>
                          <Push size={8} orientation="horizontal" />
                          <div style={{ width: 114 }} className="flex-none">
                            <FormikFormGroup name="timeStartMosru" label="">
                              <FormikInput
                                name="timeStartMosru"
                                mask={timeMaskFunction}
                                size="small"
                                placeholder="00:00"
                                iconRight={() => <IconClock />}
                              />
                            </FormikFormGroup>
                          </div>
                        </div>
                        <div className="flex items-center flex-none table-row-item-height">
                          <Push size={8} orientation="horizontal" />
                          —
                          <Push size={8} orientation="horizontal" />
                        </div>
                        <div className="flex flex-auto">
                          <div className="flex-auto">
                            <FormikFormGroup name="dateEndMosru" label="">
                              <FormikDatePicker placeholder="ДД.ММ.ГГГГ" size="small" name="dateEndMosru" />
                            </FormikFormGroup>
                          </div>
                          <Push size={8} orientation="horizontal" />
                          <div style={{ width: 114 }} className="flex-none">
                            <FormikFormGroup name="timeEndMosru" label="">
                              <FormikInput
                                name="timeEndMosru"
                                mask={timeMaskFunction}
                                size="small"
                                placeholder="00:00"
                                iconRight={() => <IconClock />}
                              />
                            </FormikFormGroup>
                          </div>
                        </div>
                      </div>
                    </FormikFormGroup>
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddPlan;

const getValidationSchema = () =>
  objectYup().shape({
    dateStart: dateYup().nullable().required('Выберите дату начала'),
    dateEnd: dateYup().nullable().required('Выберите дату окончания'),
    seats: stringYup().required('Введите количество мест'),
    dateStartMosru: dateYup().nullable().required('Выберите дату начала'),
    dateEndMosru: dateYup().nullable().required('Выберите дату окончания'),
    timeStartMosru: stringYup().required('Введите время начала'),
    timeEndMosru: stringYup().required('Введите время окончания'),
  });
