import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Badge, Tooltip, Push } from '@mosru/esz_uikit';
import { deadlineExpired, formattingDocumentNumber } from '../../../../../lib/utils/requests';
import { getRequestStatusColor } from '../../../../../lib/utils/dictionary';
import { ReactComponent as AlertDangerBorder } from '../../../../../assets/images/icons/alert-danger-border.svg';
import { RequestStatusEnum } from '../../../../../mock-data/request-status-enum';
import { generateLink } from '../../../../../lib/utils';
import { routes } from '../../../../../config/constants';
import { ReactComponent as IconArrow } from '../../../../../assets/images/icons/arrow-right.svg';

type Props = {
  id: number;
  documentDate: string;
  documentNumber: string;
  enrollmentDate: string;
  actionDeadline: string;
  requestStatusId: number;
  requestStatusName: string;
};

const RequestStatusName: React.FC<Props> = ({
  id,
  enrollmentDate,
  actionDeadline,
  requestStatusName,
  requestStatusId,
  documentNumber,
  documentDate,
}) => {
  const [second, setSecond] = useState(0);
  useEffect(() => {
    // Хак для пересчета позиции тултипа
    setTimeout(() => setSecond(1), 150);
  }, []);

  const addTagsExpired = () => (
    <span>
      Ожидание прихода заявителя <br /> для заключения договора
    </span>
  );

  return (
    <div key={second} className="flex items-start justify-between">
      <div className="flex flex-column">
        {actionDeadline ? (
          <Tooltip
            component={() =>
              !!second && (
                <div>
                  <Badge text={deadlineExpired(requestStatusName)} {...getRequestStatusColor(requestStatusId)} />
                </div>
              )
            }
            position="left"
            text={addTagsExpired()}
          />
        ) : (
          <div>
            <Badge text={requestStatusName} {...getRequestStatusColor(requestStatusId)} />
          </div>
        )}

        {actionDeadline && (
          <>
            <Push orientation="vertical" size={6} />
            {second ? (
              <div>
                <Tooltip key={second} component={() => <AlertDangerBorder />} position="top" text={actionDeadline} />
              </div>
            ) : (
              ''
            )}
          </>
        )}
        <Push size={4} />
        {requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded ? (
          <div className="color-gray-dark">{enrollmentDate}</div>
        ) : (
          ''
        )}
        <div className="color-gray-dark">{formattingDocumentNumber(documentNumber, documentDate, requestStatusId)}</div>
      </div>
      <Link to={generateLink(routes.request, { id })}>
        <IconArrow />
      </Link>
    </div>
  );
};

export default RequestStatusName;
