import React, { useEffect, useState } from 'react';
import { SelectOptionType, Tree, TreeNodeType, Key } from '@mosru/esz_uikit';
import { classificatorApi } from '../../../lib/api/classificator';
import { appendData, Element, summElementTree, transformProfession } from '../helpers';
import { transformTreePopup } from '../../../lib/utils/learners';
import { TreeModalContext } from '../index';

type Props = {
  educationId: number | null;
  keyRandom: number;
  selectedKeys: number[];
  initialData: TreeNodeType[];
  setBasicDirectionValue: (value: SelectOptionType) => void;
  organizationId?: number;
  expandedKeys: Key[];
  setExpandedKeys: (value: Key[]) => void;
};

type LastChild = Element & {
  hasChildren: boolean;
};

const TreeElement: React.FC<Props> = ({
  educationId,
  selectedKeys,
  setBasicDirectionValue,
  initialData,
  keyRandom,
  organizationId,
  expandedKeys,
  setExpandedKeys,
}) => {
  const { expandAll, dataTreePopup, numberElements, setDataTreePopup, setQualifications, showAmount } =
    React.useContext(TreeModalContext);

  const [currentKeyMenu, setCurrentKeyMenu] = useState<Key>(0);
  const [useKeyRandom, setUseKeyRandom] = useState(0);

  // получаем список родителей для базового направления
  useEffect(() => {
    if (currentKeyMenu) {
      (async () => {
        const response = await classificatorApi.getParents({ id: currentKeyMenu as number });

        const isLastChild = Boolean(response.find((item: LastChild) => !item.hasChildren));

        if (isLastChild) {
          setBasicDirectionValue(transformProfession(response).profession);
        }

        if (setQualifications) {
          setQualifications.current = { data: transformProfession(response).stage };
        }
      })();
    }
  }, [currentKeyMenu, setQualifications, setBasicDirectionValue, expandAll]);

  useEffect(() => {
    (async () => {
      if (dataTreePopup && dataTreePopup.length && educationId && currentKeyMenu) {
        const data = await classificatorApi.getChildrens({
          parentId: currentKeyMenu,
          edType: educationId,
          orgId: organizationId,
        });

        appendData(currentKeyMenu, dataTreePopup, transformTreePopup(data, showAmount));

        const summElements = summElementTree(dataTreePopup);
        if (numberElements) {
          if (summElements > numberElements) {
            setDataTreePopup(JSON.parse(JSON.stringify(dataTreePopup)));
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKeyMenu]);

  useEffect(() => {
    setUseKeyRandom(Math.random());
  }, [initialData]);

  const getAsyncData = async (key: Key): Promise<TreeNodeType[]> => {
    setCurrentKeyMenu(key);

    try {
      const data = await classificatorApi.getChildrens({
        parentId: key,
        edType: educationId,
        orgId: organizationId,
      });

      return transformTreePopup(data, showAmount);
    } catch (error) {
      return [];
    }
  };

  return (
    <Tree
      key={expandAll ? useKeyRandom : keyRandom}
      selectable
      expandedKeys={expandedKeys}
      onExpand={setExpandedKeys}
      expandAll={expandAll}
      data={initialData}
      selectedKeys={selectedKeys}
      handleChange={(value: Key[]) => {
        setCurrentKeyMenu(Number(value.join('')));
      }}
      getAsyncData={getAsyncData}
    />
  );
};

export default TreeElement;
