import React, { useCallback, useMemo, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, notify, Panel, Loader, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import Service from '../components/fields/service';
import AdmissionPlan from '../components/fields/admission-plan';
import VisitDays from '../components/fields/visit-days';
import ServiceClassField from '../components/fields/service-class';
import { SchoolRequestData, TrainingGroupRequestData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import { ServiceClassListData, ServiceClassPeriodListScheduleData } from '../../../types/service-class';
import { trainingGroupSchema } from './create';
import { BookingResultData } from '../../../types/booking';

type Props = {
  setEditModeParent: (value: string | null) => void;
  trainingGroup: TrainingGroupRequestData;
  updateRequest: () => void;
  requestId: number;
  showEditBtn?: (type: string) => boolean;
  school: SchoolRequestData;
  changeStatusRequest?: () => void;
};

const ServiceClass: React.FC<Props> = ({
  setEditModeParent,
  trainingGroup,
  updateRequest,
  requestId,
  showEditBtn,
  school,
  changeStatusRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceClassList, setServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);
  const [booking, setBooking] = useState<BookingResultData | undefined>({
    bookingId: trainingGroup.bookingGuid,
    slotId: trainingGroup.slotId,
  });

  const initialErrors = useInitialErrors(trainingGroup, trainingGroupSchema);

  const [initialDataServiceClassList, setInitialDataServiceClassList] = useState<
    ServiceClassListData<ServiceClassPeriodListScheduleData>[] | null
  >(null);

  const submitForm = useCallback(
    async (values: TrainingGroupRequestData) => {
      setLoading(true);
      try {
        if (booking) {
          values.slotId = booking.slotId;
          values.bookingGuid = booking.bookingId;
          await requestsApi.saveTrainingGroup(requestId, values);

          setEditModeParent(null);
          setEditMode(false);
          // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
          changeStatusRequest && changeStatusRequest();

          updateRequest();
        }
      } catch (e) {
        notify.danger({
          data: {
            label: 'Не удалось сохранить информацию о группе по присмотру и уходу за детьми школьного возраста',
            text: e,
            icon: true,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditModeParent, updateRequest, requestId, booking]
  );

  const initValues = useMemo(() => {
    return {
      ...trainingGroup,
      schoolOrganizationId: school?.schoolOrganizationId,
      schoolOrganizationName: school?.schoolOrganizationName,
      classParallelId: school?.classParallelId,
      classParallelName: school?.classParallelName,
      classLetterId: school?.classLetterId,
      classLetterName: school?.classLetterName,
    };
  }, [trainingGroup, school]);

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={trainingGroupSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={initValues}
    >
      {(formikProps: FormikProps<TrainingGroupRequestData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm, initialValues } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Информация о группе по присмотру и уходу за детьми школьного возраста'}
              headingControl={() => {
                return !editMode && showEditBtn && showEditBtn('serviceClass') ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('serviceClass');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <Service name="service" editMode={editMode} trainingGroup={trainingGroup} required />
                  <AdmissionPlan name="scheduleOfTimetableId" editMode={editMode} required />
                  <VisitDays
                    name="desiredDays"
                    editMode={editMode}
                    setServiceClassList={setServiceClassList}
                    initialDataServiceClassList={initialDataServiceClassList}
                    required
                    initialValues={initialValues}
                    curentServiceClassId={trainingGroup.serviceClass?.id}
                  />
                  <ServiceClassField
                    name="serviceClass"
                    editMode={editMode}
                    serviceClassList={serviceClassList}
                    setServiceClassList={setServiceClassList}
                    setInitialDataServiceClassList={setInitialDataServiceClassList}
                    required
                    setBooking={setBooking}
                    booking={booking}
                    curentServiceClassId={trainingGroup.serviceClass?.id}
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid || !booking}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceClass;
