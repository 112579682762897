import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Select, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconInfo } from '../../../assets/images/icons/alert-primary.svg';
import { ReactComponent as IconCheck } from '../../../assets/images/icons/check-dark.svg';
import { ReactComponent as IconWarning } from '../../../assets/images/icons/clock-warning.svg';
import { RequestData, RequestEditPrivilegeData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import { formatDate, formatDateAndTime } from '../../../lib/utils/date';
import contingentApi from '../../../lib/api/contingents';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type Props = {
  requestData: RequestData;
};

const Privileges: React.FC<Props> = ({ requestData }) => {
  const [privilege, setPrivilege] = useState<RequestEditPrivilegeData | undefined>();
  const [categories, setCategories] = useState<SelectOptionType[]>([]);
  const [priviligeId, setPrivilegeId] = useState<number>();
  const [needGetPrivilege, setNeedPrivilege] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const p = await requestsApi.getRequestPrivileges(requestData.id);
      setPrivilege(p);
      setNeedPrivilege(false);
    };

    if (requestData?.id && needGetPrivilege) {
      fetch();
    }
  }, [requestData.id, needGetPrivilege]);

  useEffect(() => {
    const fetch = async () => {
      const c = await dictionariesApi.getPrivilegeCategories();
      setCategories(c);
    };
    fetch();
  }, []);

  const isCheckPrivilegeBtnDisabled = useMemo(
    () =>
      requestData.contingentLinkTypeId !== ContingentLinkTypeEnum.Found ||
      !requestData.requestStatusId ||
      requestData.requestStatusId === RequestStatusEnum.Included ||
      requestData.requestStatusId === RequestStatusEnum.IncludeDeclined ||
      requestData.requestStatusId === RequestStatusEnum.ForInclude ||
      requestData.requestStatusId === RequestStatusEnum.Archive ||
      requestData.requestStatusId === RequestStatusEnum.IncludedExcluded ||
      requestData.requestStatusId === RequestStatusEnum.Excluded,
    [requestData]
  );

  const buildPrivilegeHeader = useCallback(() => {
    if (!privilege || !privilege.statusId || !privilege.statusDate || privilege.statusId === 117) {
      return (
        <div className="infobox infobox--in-page infobox--primary">
          <div className="infobox__head">
            <div className="infobox__body">
              <div className="flex">
                <IconInfo className="flex-none" />
                <Push size={8} orientation="horizontal" />
                <span className="color-primary font-weight-bold">Проверка льготных категорий не запрашивалась</span>
              </div>
            </div>
            <div className="infobox__control">
              <Button
                size="small"
                primary
                border
                label="Проверить наличие льгот"
                onClick={async () => {
                  await contingentApi.checkPrivilege(
                    requestData.id,
                    requestData.contingentGuid,
                    requestData.entityId,
                    priviligeId ?? 0
                  );
                  setNeedPrivilege(true);
                }}
                disabled={!priviligeId || isCheckPrivilegeBtnDisabled}
              />
            </div>
          </div>
        </div>
      );
    }

    if (privilege.statusId === 114) {
      return (
        <div className="infobox infobox--in-page infobox--warning">
          <div className="infobox__head">
            <div className="infobox__body">
              <div className="flex">
                <IconWarning className="flex-none" />
                <Push size={8} orientation="horizontal" />
                <div className="color-warning-dark">
                  <span className="font-weight-bold">Проводится проверка действий льготной категории</span>
                </div>
              </div>
            </div>
            <div className="infobox__control">
              <div className="color-warning-dark">{`Начало проверки: ${formatDateAndTime(privilege.statusDate)}`}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="infobox infobox--in-page infobox--success">
        <div className="infobox__head">
          <div className="infobox__body">
            <div className="flex">
              <IconCheck className="flex-none" />
              <Push size={8} orientation="horizontal" />
              <div className="color-success-dark">
                <span className="font-weight-bold">Льготная категория подтверждена</span>
                <Push size={8} />
                {privilege?.privilegeList[0]?.privilegeCategoryName ?? ''} (
                {privilege?.privilegeList[0]?.privilegeStartDate
                  ? formatDate(privilege?.privilegeList[0].privilegeStartDate)
                  : ''}
                —
                {privilege?.privilegeList[0]?.privilegeEndDate
                  ? formatDate(privilege?.privilegeList[0].privilegeEndDate)
                  : ''}
                )
              </div>
            </div>
          </div>
          <div className="infobox__control">
            <div className="color-success-dark">{`Дата проверки: ${formatDateAndTime(privilege.statusDate)}`}</div>
          </div>
        </div>
      </div>
    );
  }, [
    privilege,
    priviligeId,
    requestData.contingentGuid,
    requestData.id,
    requestData.entityId,
    isCheckPrivilegeBtnDisabled,
  ]);

  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Льготные категории'}>
        <div className="container">
          {buildPrivilegeHeader()}
          <Push size={20} />
          <div className="table-data">
            <div className="table-data__item table-data__group">
              <div className="table-data__label table-data__label--main">Перечень льготных категорий</div>
              <div className="table-data__body">
                {privilege && privilege.statusDate && privilege.statusId !== 117 ? (
                  <div className="flex justify-between">
                    {privilege.privilegeList?.length > 0
                      ? privilege.privilegeList[0].privilegeCategoryName
                      : privilege.privilegeCategoryName
                      ? privilege.privilegeCategoryName
                      : 'Активные льготные категории отсутствуют'}
                  </div>
                ) : (
                  <Select
                    name="privilegeId"
                    options={categories}
                    onChange={(selectedOption) => {
                      const v = selectedOption ? (selectedOption as SelectOptionType) : null;
                      setPrivilegeId(v?.value as number);
                    }}
                    placeholder=""
                    disabled={isCheckPrivilegeBtnDisabled}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Privileges;
