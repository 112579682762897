import React from 'react';
import { Button, Modal, ModalPanel } from '@mosru/esz_uikit';

type Props = {
  open: boolean;
  title: string;
  description: string;
  onCloseHandler: () => void;
};

export const Notification = ({ open, title, onCloseHandler, description }: Props) => {
  return (
    <Modal show={open} onClose={onCloseHandler}>
      <ModalPanel
        modalTitle={title}
        onClose={onCloseHandler}
        description={description}
        controls={() => <Button primary label="Закрыть" onClick={onCloseHandler} />}
      />
    </Modal>
  );
};
