import React, { useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, IconSearch, SelectOptionType, Panel, Push } from '@mosru/esz_uikit';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as IconOptions } from '../../../assets/images/icons/options.svg';
import { ReactComponent as IconUp } from '../../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../../assets/images/icons/down.svg';
import { LoaderCustom } from '../../../components/loader-custom';
import { historyState } from '../../../mock-data/history-state';
import FormikInput from '../../../components/formik/formik-input';
import FormikFormGroup from '../../../components/formik/formik-form-group';
import FormikToggle from '../../../components/formik/formik-toggle';
import { SearchGroupTrainingInitialData } from '../../../types/service-class';
import { AuthorizationData, generalAccess } from '../../../types/authorization-data';
import SheduleModal, { Weekday } from '../../../components/shedule-modal';
import { FieldSchedule } from './fields/schedule';
import { ReportSchedule } from '../../../types/reports';
import { accessInitialForm, getCountDiff } from '../../../lib/utils/service-class';
import { FieldPlaceService } from './fields/place-service';
import { FieldOrganization } from './fields/organization';
import { searchGroupTrainingInitialFormData } from './index';
import { FieldPlace } from './fields/service';
import { FieldVedomstvo } from './fields/vedomstvo';
import { FieldEducationType } from './fields/education-type';
import { FieldModule } from './fields/module';
import { hasGeneralAccess, replaceHistoryState } from '../../../lib/utils';
import { useGetDataDepartment } from '../../../hooks/get-department';
import { day, weekday } from '../../../components/shedule-modal/helpers';
import { NotificationField } from '../../../components/fields/notification';
import { sendReachGoal } from '../../../lib/metrica';
import useQuery from '../../../hooks/use-query';
import { EventUrl } from '../../../mock-data/event';

type Props = {
  isAdmin: boolean;
  userProfile: AuthorizationData;
  educationTypeData: SelectOptionType[];
  initialForm: SearchGroupTrainingInitialData | undefined;
  submitForm: (values: SearchGroupTrainingInitialData) => void;
};

const ServiceClassRegistrySearch: React.FC<Props> = ({
  initialForm,
  submitForm,
  isAdmin,
  userProfile,
  educationTypeData,
}) => {
  const [open, setOpen] = useState<boolean>(!!window.history.state[historyState.openAdvanced]);
  const [rerenderFormKey, setRerenderFormKey] = useState(0);

  const [scheduleModal, setScheduleModal] = useState(false);
  const [resetScheduleModal, setResetScheduleModal] = useState(false);
  const [listSchedule, setListSchedule] = useState<ReportSchedule>({ selectDaysForRequest: '', selectShortDay: '' });
  const [currentSelectDay, setCurrentSelectDay] = useState<Weekday[]>([]);

  const [programModules, setProgramModules] = useState<SelectOptionType[]>([]);

  const vedomstvoData: SelectOptionType[] = useGetDataDepartment();

  const accessMain = hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);
  const access = !accessMain && !isAdmin && !!educationTypeData[0];

  const location = useLocation();
  const history = useHistory();
  const eventId = Number(useQuery().get(EventUrl.EventId));

  useEffect(() => {
    if (!initialForm) {
      const state = window.history.state[historyState.search];
      if (state) {
        const scheduleOfService: string[] = state.scheduleOfService.split(';');
        const scheduleOfServiceList = scheduleOfService.reduce((acc: Pick<Weekday, 'id' | 'selected'>[], item) => {
          const selectedItem = item.split(',');
          return [
            ...acc,
            {
              id: Number(selectedItem[0][0]),
              selected: selectedItem.length === 1 ? Number(selectedItem[0][1]) : 3,
            },
          ];
        }, []);

        setListSchedule({
          selectDaysForRequest: state.scheduleOfService || '',
          selectShortDay: state.scheduleOfServiceText || '',
        });
        setCurrentSelectDay(
          weekday.map((item) => {
            const findItem = scheduleOfServiceList.find(({ id }) => item.id === id);
            return {
              ...item,
              selected: findItem?.selected ?? item.selected,
              time: day.find(({ value }) => value === findItem?.selected)?.label ?? item.time,
              checked: !!findItem,
            };
          })
        );
      }
    }
  }, [initialForm]);

  const resetScheduleModalHandler = (value: boolean) => {
    setResetScheduleModal(value);
    setCurrentSelectDay(weekday);
    if (value) {
      setListSchedule({ selectDaysForRequest: '', selectShortDay: '' });
    }
  };

  const openScheduleModal = () => {
    setScheduleModal(true);
  };

  const saveSchedule = (value: ReportSchedule) => {
    setListSchedule(value);
  };

  const resetState = () => {
    resetScheduleModalHandler(true);
    setRerenderFormKey(Math.random());
  };

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [historyState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      <Formik
        onSubmit={(values, formikHelpers) => {
          sendReachGoal('onclick-service-class-search');
          submitForm(values);
          formikHelpers.setSubmitting(false);
        }}
        enableReinitialize
        initialValues={initialForm || searchGroupTrainingInitialFormData}
        key={rerenderFormKey}
      >
        {(formikProps: FormikProps<SearchGroupTrainingInitialData>) => {
          const { handleSubmit, isSubmitting, values, resetForm, initialValues } = formikProps;

          const accessInitial = {
            ...searchGroupTrainingInitialFormData,
            ...accessInitialForm(userProfile, initialValues.educationTypeId as number, initialValues.educationTypeName),
          };

          const resetInitial = () => {
            if (access) {
              return accessInitial;
            } else if (educationTypeData.length === 1) {
              return {
                ...searchGroupTrainingInitialFormData,
                educationTypeId: educationTypeData[0].value as number,
              };
            } else {
              return searchGroupTrainingInitialFormData;
            }
          };

          const onResetForm = () => {
            let newObj: SearchGroupTrainingInitialData = resetInitial();

            if (eventId) {
              history.replace(location.pathname);
              replaceHistoryState({ [historyState.openAdvanced]: open });
              const { eventId, ...obj } = resetInitial();
              newObj = obj;
            }

            resetState();
            resetForm({ values: newObj });
            submitForm(resetInitial());
          };

          const countDiff = getCountDiff(values, resetInitial());

          return (
            <form onSubmit={handleSubmit}>
              <Panel
                subControl={() => (
                  <button type="button" onClick={handleClickAdvancedSearch} className="icon-group">
                    <span className="icon-group__icon">
                      <IconOptions />
                    </span>
                    <span className="icon-group__text">
                      Расширенный поиск{' '}
                      {!open && countDiff > 0 && <span className="icon-group__badge">{countDiff}</span>}
                    </span>
                    <span className="icon-group__icon">{open ? <IconUp /> : <IconDown />}</span>
                  </button>
                )}
                controls={() => (
                  <>
                    <Button label="Сбросить" onClick={onResetForm} border size="small" />
                    <Push orientation="horizontal" size={12} />
                    <Button submit label="Начать поиск" load={isSubmitting} primary size="small" />
                  </>
                )}
              >
                <div className="container">
                  <Push size={20} />
                  <FormikInput
                    name="query"
                    placeholder="Поиск по группам обучения..."
                    iconLeft={() => <IconSearch />}
                  />

                  {open &&
                    (initialForm ? (
                      <>
                        <Push size={16} />
                        <div className="service-class-search-grid">
                          <div>
                            <FieldVedomstvo isAdmin={isAdmin} userProfile={userProfile} vedomstvoData={vedomstvoData} />

                            <FieldPlaceService
                              isAdmin={isAdmin}
                              userProfile={userProfile}
                              educationTypeData={educationTypeData}
                            />

                            <Push size={16} />

                            <FieldEducationType educationTypeData={educationTypeData} />

                            {/* По уведомлению */}
                            <NotificationField />

                            <FieldSchedule
                              isAdmin={isAdmin}
                              userProfile={userProfile}
                              listSchedule={listSchedule}
                              openScheduleModal={openScheduleModal}
                              resetScheduleModalHandler={resetScheduleModalHandler}
                            />

                            <Push size={16} />

                            <div className="flex items-center">
                              <FormikToggle name="showArchive" size="small" />
                              <Push size={8} orientation="horizontal" />
                              <div>Отображать архивные</div>
                            </div>
                          </div>

                          <div>
                            <FieldOrganization isAdmin={isAdmin} userProfile={userProfile} />

                            <Push size={16} />

                            <FieldPlace userProfile={userProfile} setProgramModules={setProgramModules} />

                            <Push size={16} />

                            <FormikFormGroup name="code" label="Код группы">
                              <FormikInput name="code" placeholder="Введите..." size="small" />
                            </FormikFormGroup>

                            <Push size={16} />

                            <FieldModule isAdmin={isAdmin} userProfile={userProfile} programModules={programModules} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <LoaderCustom size={110} />
                    ))}
                </div>
              </Panel>
            </form>
          );
        }}
      </Formik>

      <SheduleModal
        customAll
        show={scheduleModal}
        title="Время работы"
        reset={resetScheduleModal}
        saveSchedule={saveSchedule}
        currentSelectDay={currentSelectDay}
        resetModal={resetScheduleModalHandler}
        setCurrentSelectDay={setCurrentSelectDay}
        currentShortDay={listSchedule.selectShortDay}
        onCloseHandler={() => setScheduleModal(false)}
      />
    </>
  );
};

export default ServiceClassRegistrySearch;
