import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, number as numberYup } from 'yup';
import { Infobox, SelectOptionType, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../../../hooks/formik-initial-errors';
import FormikSelect from '../../../../../components/formik/formik-select';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../components/formik/formik-input';
import FormikDatePicker from '../../../../../components/formik/formik-datepicker';
import { DocumentDataFinishedAndTypes, Learner } from '../../../../../types/learners';
import learnerApi from '../../../../../lib/api/learner';
import { DocumentTypeEnum } from '../../../../../mock-data/type-document';
import { checkEmptyData, showSuccessNotification } from '../../../../../lib/utils/learners';
import { DocNumber } from './fields/doc-number';
import { DocumentType } from './fields/document-type';
import { docDate } from '../../../../../lib/utils/validation';
import { AcceptFields } from './fields/accept-fields';
import { DocumentModalContext } from '../index';
import { DocumentModalMoscow } from '../../../../../types/document-modal-moscow';
import { getDate } from '../../../../../lib/utils/date';

type Props = {
  pupilId: string;
  newDocument: boolean;
  data: Learner.Document;
  accessDisabled: boolean;
  onCloseHandler: () => void;
  setValidateFormMoscow: (value: boolean) => void;
  documentDataFinishedAndTypes: DocumentDataFinishedAndTypes;
  hasCreateDocument: boolean;
};

// Todo нужно полностью отрефакторить
const FormMoscow = forwardRef<any, Props>(
  (
    {
      pupilId,
      accessDisabled,
      newDocument,
      onCloseHandler,
      data,
      setValidateFormMoscow,
      documentDataFinishedAndTypes,
      hasCreateDocument,
    },
    ref
  ) => {
    const [initialData, setInitialData] = useState<DocumentModalMoscow.InitialData>({
      docNumber: data.docNumber.trim(),
      documentTypeId: data.documentTypeId,
      programmModuleId: data.programmModuleId,
      issueDate: data.issueDate ? new Date(data.issueDate) : null,
      unionCatalogServicesId: data.unionCatalogServicesId,
      moduleId: null,
    });

    const { adminEdit, adminView, setLoadingRequest, setLoadingReAccept } = useContext(DocumentModalContext);
    const accessCheck = !adminView || adminEdit;

    const [program, setProgram] = useState<SelectOptionType | null>({
      label: data.programName as string,
      value: data.unionCatalogServicesId as number,
    });

    const [modules, setModules] = useState<SelectOptionType[]>();
    const [programLevel, setProgramName] = useState<string>('');

    const [acceptEducationProgram, setAcceptEducationProgram] = useState<SelectOptionType>({ label: '', value: 0 });
    const [listModules, setListModules] = useState<SelectOptionType[]>([]);

    const [rerenderProgram, setRerenderProgram] = useState(0);

    const [organization, setOrganization] = useState<SelectOptionType | null>({
      label: '',
      value: 0,
    });

    const defaultModule = {
      label: data.moduleName ?? '',
      value: data.programmModuleId ?? 0,
    };

    const [currentModule, setCurrentModule] = useState<SelectOptionType | null>(newDocument ? null : defaultModule);

    const [fields, setFields] = useState<any>();

    const [errorForm, setErrorForm] = useState<boolean>(false);

    const [educationProgram, setEducationProgram] = useState<SelectOptionType | null>(
      !data.programName
        ? {
            label: data.programName ?? program?.label,
            value: data.unionCatalogServicesId ?? program?.value ?? 0,
          }
        : null
    );

    const initialErrors = useInitialErrors(initialData, getValidationSchema());

    useEffect(() => {
      setValidateFormMoscow(errorForm);
    }, [errorForm, setValidateFormMoscow]);

    // генерируем список модулей
    useEffect(() => {
      const search = documentDataFinishedAndTypes.docFinished.find((item) => item.serviceId === program?.value);

      if (search) {
        setProgramName(search.programmLevel);
        const list = search.modules.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        setFields(search);
        setModules(list);
      } else {
        setModules([]);
      }
    }, [program, documentDataFinishedAndTypes.docFinished]);

    useEffect(() => {
      if (acceptEducationProgram && acceptEducationProgram.value) {
        (async () => {
          const result = await learnerApi.getDocumentProgramModules({ serviceId: acceptEducationProgram.value });
          setListModules(result);
        })();
      }
    }, [acceptEducationProgram]);

    useEffect(() => {
      if (!newDocument) {
        if (initialData.unionCatalogServicesId !== data.unionCatalogServicesId) {
          setEducationProgram(
            program && {
              label: program?.label,
              value: program?.value,
            }
          );
        }
      }
    }, [program, data.unionCatalogServicesId, newDocument, initialData.unionCatalogServicesId]);

    // обнуляем значение в "Модуль" при изменении "Образовательная программы"
    useEffect(() => {
      if (educationProgram?.value) {
        if (data.unionCatalogServicesId !== educationProgram?.value) {
          setRerenderProgram(Math.random());
          setCurrentModule(null);
        }
      }
    }, [currentModule?.value, data.programmModuleId, data.unionCatalogServicesId, educationProgram?.value, program]);

    const generatePrograms = documentDataFinishedAndTypes.docFinished.reduce(
      (acc: SelectOptionType[], item: { serviceName: string; serviceId: number }) => {
        if (data.unionCatalogServicesId !== item.serviceId) {
          acc.push({ label: item.serviceName, value: item.serviceId });
        }

        return acc;
      },
      []
    );

    const handleSubmit = () => {
      (async () => {
        setLoadingRequest(true);
        if (newDocument) {
          const filterData = initialData && checkEmptyData(initialData);

          try {
            await learnerApi.postDocument({
              ...filterData,
              pupilId: +pupilId,
              programName: program?.label,
              programmLevel: programLevel,
            });

            showSuccessNotification();

            onCloseHandler();
            setLoadingRequest(false);
          } catch (e) {
            setLoadingRequest(false);
          }
        } else {
          const send = {
            pupilId: +pupilId,
            id: data?.id,
            programName: program?.label,
            programmLevel: data.programmLevel || fields.programmLevel,
            documentTypeId: initialData.documentTypeId,
            unionCatalogServicesId: educationProgram?.value,
            organizationId: data.organizationId,
            organizationName: data.organizationName || organization?.label,
            programmModuleId: currentModule?.value,
            ...initialData,
            issueDate: initialData.issueDate,
          };

          const filterData = checkEmptyData(send);
          await learnerApi.putDocument(filterData);
          showSuccessNotification();
          onCloseHandler();
          setLoadingRequest(false);
        }
      })();
    };

    const reAcceptHandler = useCallback(async () => {
      if (initialData.moduleId && initialData.catalogId) {
        setLoadingReAccept(true);
        try {
          await learnerApi.patchDocumentReAccept(+pupilId, {
            docId: data.id ?? 0,
            catalogId: initialData.catalogId,
            moduleId: initialData.moduleId,
          });
          onCloseHandler();
          setLoadingReAccept(false);
        } catch (e) {
          setLoadingReAccept(false);
        }
      }
    }, [data.id, initialData.catalogId, initialData.moduleId, onCloseHandler, pupilId, setLoadingReAccept]);

    useImperativeHandle(ref, () => ({
      handleSubmit,
      reAcceptHandler,
    }));

    return (
      <Formik
        innerRef={(formikActions) => {
          if (formikActions) {
            setErrorForm(formikActions.isValid);
            setInitialData(formikActions.values);
          } else {
            setInitialData({});
          }
        }}
        enableReinitialize
        initialValues={initialData}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
        initialErrors={initialErrors}
      >
        {(formikProps: FormikProps<DocumentModalMoscow.InitialData>) => {
          const { handleSubmit, values } = formikProps;

          const educationOption = data.unionCatalogServicesId
            ? [{ label: data.programName, value: data.unionCatalogServicesId }, ...generatePrograms]
            : generatePrograms;

          const educationList = () => {
            if (documentDataFinishedAndTypes.docFinished.length) {
              if (data.programName) {
                return educationOption;
              } else {
                return generatePrograms;
              }
            } else {
              return educationProgram?.value ? [educationProgram] : [];
            }
          };

          return (
            <>
              {(adminView || adminEdit) && <Push size={16} />}

              <form onSubmit={handleSubmit}>
                <DocumentType
                  newDocument={newDocument}
                  documentTypeId={data.documentTypeId ?? 0}
                  accessDisabled={accessDisabled}
                  documentDataFinishedAndTypes={documentDataFinishedAndTypes}
                />
                <Push size={16} />

                <DocNumber newDocument={newDocument} accessDisabled={accessDisabled} />

                <Push size={16} />

                <FormikFormGroup showErrorImmediately name="issueDate" required label="Дата">
                  <FormikDatePicker
                    placeholder="ДД.ММ.ГГГГ"
                    size="small"
                    name="issueDate"
                    startDate={values.issueDate ? getDate(values.issueDate) : undefined}
                    disabled={!newDocument && !adminEdit}
                  />
                </FormikFormGroup>

                <Push size={16} />

                <FormikFormGroup required name="unionCatalogServicesId" label="Образовательная программа">
                  <FormikSelect
                    size="small"
                    isSearchable
                    options={educationList()}
                    selectedValue={setProgram}
                    name="unionCatalogServicesId"
                    placeholder="Выберите образовательную программу"
                    defaultValue={
                      documentDataFinishedAndTypes.docFinished.length && newDocument
                        ? null
                        : data.programName
                        ? educationProgram
                        : { label: 'Не найдено завершенных образ. программ', value: 0 }
                    }
                    disabled={!newDocument || !hasCreateDocument}
                  />
                </FormikFormGroup>

                {newDocument || data.moduleName
                  ? !(values.documentTypeId === 8) && (
                      <>
                        <Push size={16} />

                        <div key={rerenderProgram}>
                          <FormikFormGroup required name="programmModuleId" label="Модуль">
                            <FormikSelect
                              name="programmModuleId"
                              size="small"
                              isSearchable
                              selectedValue={setCurrentModule}
                              options={
                                educationProgram?.value === data.unionCatalogServicesId
                                  ? [defaultModule] ?? []
                                  : modules ?? []
                              }
                              placeholder="Выберите модуль"
                              defaultValue={currentModule}
                              disabled={!newDocument}
                            />
                          </FormikFormGroup>
                        </div>
                      </>
                    )
                  : null}

                <Push size={16} />

                <FormikFormGroup name="organizationName" label="Организация">
                  <FormikInput
                    value={fields?.organizationName || data.organizationName}
                    size="small"
                    disabled
                    name="organizationName"
                  />
                </FormikFormGroup>

                <Push size={16} />

                <FormikFormGroup name="profName" label="Профессия">
                  <FormikInput value={fields?.serviceName || data.profName} size="small" disabled name="profName" />
                </FormikFormGroup>

                <Push size={16} />

                <FormikFormGroup name="programmLevel" label="Квалификация">
                  <FormikInput
                    value={fields?.programmLevel || data.programmLevel}
                    size="small"
                    disabled
                    name="programmLevel"
                  />
                </FormikFormGroup>

                <Push size={20} />

                {!newDocument && data.documentTypeId === DocumentTypeEnum.Certificate ? (
                  <>
                    <b>Документ принят в зачет</b>
                    <Push size={16} />
                    <AcceptFields
                      organization={organization}
                      listModules={listModules}
                      acceptEducationProgram={acceptEducationProgram}
                      accessCheck={accessCheck}
                      programName={data.programName}
                      setOrganization={setOrganization}
                      setAcceptEducationProgram={setAcceptEducationProgram}
                      unionCatalogServicesId={data.unionCatalogServicesId ?? 0}
                      documentDataFinishedAndTypes={documentDataFinishedAndTypes}
                      hasCreateDocument={hasCreateDocument}
                    />
                    <Push size={16} />
                    <Infobox
                      color="primary"
                      text='При нажатии на кнопку "Перезачесть" данный документ засчитывается как документ об успешном освоении обучающимся модуля другой действующей образовательной программы'
                    />
                  </>
                ) : null}
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
);

export default FormMoscow;

const getValidationSchema = () =>
  objectYup().shape({
    documentTypeId: numberYup().required('Выберите тип документа'),
    docNumber: stringYup().trim().required('Введите номер').nullable(),
    issueDate: docDate.nullable().required('Выберите дату'),
    unionCatalogServicesId: stringYup().required('Выберите образовательную программу').nullable(),
    programmModuleId: stringYup()
      .nullable()
      .when('documentTypeId', {
        is: 7,
        then: (s) => s.required('Выберите модуль'),
      }),
  });
