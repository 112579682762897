import React, { useState, Fragment } from 'react';
import { Loader, Push } from '@mosru/esz_uikit';
import PageHeading from '../../components/header/page-heading';
import { ReactComponent as IconUp } from '../../assets/images/icons/up.svg';
import { ReactComponent as IconDown } from '../../assets/images/icons/down.svg';
import { useGetFaqQuery } from '../../store/static-content';
import { routes } from '../../config/constants';

const Faq = () => {
  const { data = [], isFetching } = useGetFaqQuery();

  return (
    <>
      <PageHeading
        title="Ответы на вопросы"
        sections={[{ title: 'Главная', link: routes.main }, { title: 'Ответы на вопросы' }]}
      />
      {isFetching ? (
        <div className="loader-auto">
          <Loader />
        </div>
      ) : (
        data.map((item, i) => (
          <Fragment key={`faq_${item.id}`}>
            <Push size={i === 0 ? 12 : 16} />
            <div className="faq-panel">
              <div className="faq-panel__heading">
                <div className="container">{item.name}</div>
              </div>
              {item.faqList.map((faqItem) => (
                <div key={`faqItem_${faqItem.id}`} className="faq-panel__item">
                  <Accordion title={faqItem.question} description={faqItem.answer} />
                </div>
              ))}
            </div>
          </Fragment>
        ))
      )}
    </>
  );
};

const Accordion = ({ title, description }: { title: string; description: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="faq-accordion">
      <div className="container">
        <button type="button" onClick={() => setOpen(!open)} className="faq-accordion__btn">
          <span className="faq-accordion__title">{title}</span>
          {open ? <IconUp /> : <IconDown />}
        </button>
        {open && <div dangerouslySetInnerHTML={{ __html: description }} className="faq-accordion__description" />}
      </div>
    </div>
  );
};

export default Faq;
