import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Push } from '@mosru/esz_uikit';
import FooterWrapper from '../../components/footer/footer-wrapper';
import Img from '../../assets/images/errors/unknown.svg';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import homeApi from '../../lib/api/home';
import { useGetAppealLinkQuery } from '../../store/home';

const UnknownError = () => {
  const [errorId, setErrorId] = useState<number>();
  const { state } = useLocation<{ error: Error }>();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const { data: appealLink = '' } = useGetAppealLinkQuery({ errorId, formName: 'Ошибка' });

  useEffect(() => {
    if (state?.error) {
      const getLink = async () => {
        const errorId = await homeApi.saveError('Ошибка', {
          ...state.error,
          userId: userProfile.id,
          userLogin: userProfile.login,
        });
        setErrorId(errorId);
      };
      getLink();
    }
  }, [userProfile, state]);
  return (
    <div className="error-layout">
      <div className="error-container">
        <Push size={40} />
        <div className="error-heading">Что-то пошло не так</div>
        <Push size={8} />
        Если данная проблема будет повторяться,{' '}
        <a href={appealLink} target="_blank" rel="noreferrer">
          обратитесь в службу
          <br /> технической поддержки
        </a>{' '}
        или напишите нам по электронной почте:
        <br /> <a href="mailto:dop_hd@mos.ru">dop_hd@mos.ru</a>
        <Push size={12} />
        <Link to=".">Обновить страницу</Link>
        <Push size={48} />
        <img src={Img} alt="" className="error-img" />
      </div>
      <FooterWrapper />
    </div>
  );
};

export default UnknownError;
