import React from 'react';
import { Link } from 'react-router-dom';
import { Push } from '@mosru/esz_uikit';
import { formatDate } from '../../../lib/utils/date';
import { RequestEditServiceClassData, RequestEnrollmentData } from '../../../types/requests';
import { routes } from '../../../config/constants';

type Props = {
  enrollment: RequestEnrollmentData | undefined;
  trainServiceClassId: number;
  educationType?: number;
};

const ActionsFormEnrolled: React.FC<Props> = ({ trainServiceClassId, enrollment, educationType }) => {
  const numberStudents = (serviceClass: RequestEditServiceClassData) => {
    return serviceClass.capacity && serviceClass.included
      ? `${serviceClass.capacity} / ${serviceClass.included}`
      : 'без ограничений';
  };

  const buildServiceClassName = (serviceClass?: RequestEditServiceClassData) => {
    if (!serviceClass) {
      return '';
    }
    return `${serviceClass.code},  ${serviceClass.name}, ${serviceClass.teacher}, ${numberStudents(serviceClass)}`;
  };
  return (
    <div className="container">
      <Push size={16} />
      <div className="table-data">
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Группа обучения</div>
          <div className="table-data__body">
            <Link
              className="brand-link cursor-pointer"
              to={`${routes.serviceClasses}/${trainServiceClassId}`}
              target="_blank"
              rel="noreferrer"
            >
              {buildServiceClassName(enrollment?.serviceClass)}
            </Link>
          </div>
        </div>
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Дата зачисления</div>
          <div className="table-data__body">
            <div className="table-data-grid-3">{enrollment?.enrollDate ? formatDate(enrollment.enrollDate) : ''}</div>
          </div>
        </div>
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Вид документа-основания</div>
          <div className="table-data__body">
            <div className="table-data-grid-3">{enrollment?.enrollDocumentTypeName}</div>
          </div>
        </div>
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Номер и дата документа</div>
          <div className="table-data__body">
            <div className="table-data-grid-2">
              <div className="table-data__group">
                <div className="table-data__label">Номер</div>
                <div className="table-data__body">{enrollment?.enrollDocNumber}</div>
              </div>
              <div className="table-data__group">
                <div className="table-data__label">Дата </div>
                <div className="table-data__body">
                  {enrollment?.enrollDocDate ? formatDate(enrollment.enrollDocDate) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-data__item table-data__group">
          <div className="table-data__label table-data__label--main">Комментарий</div>
          <div className="table-data__body word-break-all">{enrollment?.enrollComment ?? '—'}</div>
        </div>
      </div>
    </div>
  );
};

export default ActionsFormEnrolled;
