import { useEffect, useState } from 'react';
import { ScheduleType } from '../../../../../components/schedule';
import { convertScheduleToFront, getTimeTableFromString } from '../../../../../lib/utils/time-table';
import { ServiceData } from '../../../../../types/service';

export const UseGetSchedule = (serviceData: ServiceData) => {
  const [scheduleData, setScheduleData] = useState<ScheduleType[]>(
    serviceData?.schedule?.list
      ? convertScheduleToFront(serviceData.schedule.list)
      : (getTimeTableFromString('') as ScheduleType[])
  );

  useEffect(() => {
    setScheduleData(scheduleData);
  }, [scheduleData, serviceData?.schedule?.list]);

  return { scheduleData, setScheduleData };
};
