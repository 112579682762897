import React, { useEffect, useMemo, useState } from 'react';
import { Infobox, Panel, Switcher, Push } from '@mosru/esz_uikit';
import ActionsFormRefusal from './actions-form-refusal';
import { RequestData, RequestDeclineData, RequestEnrollmentData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import ActionsFormEnrollment from './actions-form-enrollment';
import ActionsFormEnrolled from './actions-form-enrolled';
import requests from '../../../lib/api/requests';

type Props = {
  setEditModeParent: (value: string | null) => void;
  enrollment: RequestEnrollmentData | undefined;
  decline: RequestDeclineData;
  requestStatusId: RequestStatusEnum;
  serviceId: number;
  trainingGroupId: number;
  requestId: number;
  updateRequest: () => void;
  contingentGuid: string;
  requestData?: RequestData;
  extendEntityGuid: string;
};

const ActionsForm: React.FC<Props> = ({
  setEditModeParent,
  enrollment,
  requestStatusId,
  serviceId,
  trainingGroupId,
  decline,
  requestId,
  updateRequest,
  contingentGuid,
  requestData,
  extendEntityGuid,
}) => {
  const showDecline = useMemo(
    () =>
      requestStatusId === RequestStatusEnum.DocumentAccepted || requestStatusId === RequestStatusEnum.IncludeDeclined,
    [requestStatusId]
  );

  const showEnrollment = useMemo(() => requestStatusId === RequestStatusEnum.DocumentAccepted, [requestStatusId]);
  const [refusal, setRefusal] = useState(!showEnrollment);

  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    const fetch = async () => {
      const result = await requests.limitOP(requestId);
      setMessageError(result?.message);
    };

    fetch();
  }, [requestId]);

  return (
    <>
      <Push size={12} />
      {showDecline || showEnrollment ? (
        <Panel
          title={() => (showDecline && !showEnrollment ? 'Отказ в зачислении' : 'Действия по заявлению')}
          headingControl={() => (
            <div className="flex items-center">
              {showDecline && showEnrollment && (
                <Switcher size="small">
                  <button
                    type="button"
                    onClick={() => {
                      setRefusal(false);
                    }}
                    className={refusal ? '' : 'active'}
                  >
                    Зачисление
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setRefusal(true);
                    }}
                    className={refusal ? 'active' : ''}
                  >
                    Отказ в зачислении
                  </button>
                </Switcher>
              )}
            </div>
          )}
        >
          {refusal ? (
            <ActionsFormRefusal
              decline={decline}
              requestId={requestId}
              serviceId={serviceId}
              editMode={requestStatusId !== RequestStatusEnum.IncludeDeclined}
              updateRequest={updateRequest}
            />
          ) : messageError ? (
            <div className="container">
              <Infobox
                fullWidth
                color="danger"
                text={
                  <>
                    <strong>
                      Функционал зачисления для выбранного ученика на бесплатную программу в этом году недоступен.
                    </strong>
                    <br />
                    <span style={{ whiteSpace: 'pre-wrap' }}>{messageError}</span>
                  </>
                }
              />
              <Push orientation="vertical" size={16} />
            </div>
          ) : (
            <ActionsFormEnrollment
              contingentGuid={contingentGuid}
              enrollment={enrollment ?? ({} as RequestEnrollmentData)}
              requestId={requestId}
              trainingGroupId={trainingGroupId}
              updateRequest={updateRequest}
              serviceId={serviceId}
              extendEntityGuid={extendEntityGuid}
              requestData={requestData}
            />
          )}
        </Panel>
      ) : (
        (requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded) && (
          <Panel
            title={() =>
              requestStatusId === RequestStatusEnum.Included || requestStatusId === RequestStatusEnum.IncludedExcluded
                ? 'Зачисление'
                : 'Действия по заявлению'
            }
          >
            <ActionsFormEnrolled enrollment={enrollment} />
          </Panel>
        )
      )}
    </>
  );
};

export default ActionsForm;
