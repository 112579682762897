import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormikHelpers, useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import Field, { FieldProps } from '../../../../components/fields/field';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { FormTypeEnum } from '../../../../mock-data/form-type-enum';
import { buildFormFieldName, setAsyncValues } from '../../../../lib/utils/requests';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { docNumberDateOfIssueField, docNumberField, docNumberSeriesField } from './document-number';
import { DependentDocumentContext } from './dependent-document';
import { issuePlace } from './issued-place';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { IssuedPlaceEnum } from '../../../../mock-data/issued-place';
import { RequestData } from '../../../../types/requests';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { filterEducations } from '../../../../lib/utils/education';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { EducationTypeEnum } from '../../../../types/education-type';

type DocumentProps = {
  formType?: FormTypeEnum;
  disabled?: boolean;
  size?: 1 | 2 | 3;
  parent?: string;
  dependentFields?: boolean;
} & FieldProps<string>;

export const documentTypeField = 'documentTypeId';
export const documentTypeFieldName = 'documentTypeName';
export const issuedFieldName = 'issued';
const Document = ({
  label = 'Документ, удост. личность',
  name,
  editMode,
  required,
  formType = FormTypeEnum.EditRequestOther,
  disabled = false,
  size,
  parent,
  dependentFields,
}: DocumentProps) => {
  const { setFieldValue } = useFormikContext<FormikHelpers<RequestData>>();
  const [optionsData, setOptionsData] = useState<SelectOptionType[]>([]);

  const fieldId = `${name}Id`;
  const fieldName = `${name}Name`;

  const documentTypeIdField = buildFormFieldName(parent, fieldId);
  const documentTypeNameField = buildFormFieldName(parent, fieldName);

  const [documentTypeId, , setDocumentTypeId] = useField(documentTypeIdField);
  const [documentTypeName, , setDocumentTypeName] = useField(documentTypeNameField);

  const numberField = buildFormFieldName(parent, docNumberField);
  const seriesField = buildFormFieldName(parent, docNumberSeriesField);
  const issueField = buildFormFieldName(parent, docNumberDateOfIssueField);
  const issuePlaceField = buildFormFieldName(parent, issuePlace);
  const issuedField = buildFormFieldName(parent, issuedFieldName);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const educationTypeData = useMemo(
    () =>
      filterEducations(
        userProfile.objectAccessActionList,
        [],
        undefined,
        accessObject.Requests,
        accessAction.ViewRegistry,
        true
      ),
    [userProfile.objectAccessActionList]
  );

  useEffect(() => {
    if (!documentTypeId?.value) {
      if (optionsData.length > 0) {
        setDocumentTypeId.setValue(optionsData[0]?.value);
        setDocumentTypeName.setValue(optionsData[0]?.label);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [documentTypeId?.value, optionsData]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await dictionariesApi.getDocumentTypes(formType);
      setOptionsData(response);
    };
    if (editMode) {
      fetchOptions();
    }
  }, [editMode, formType]);

  const { setDocSeriesKey, setDocNumberKey, setDocDateKey, setIssuedPlaceKey, setIssuedKey } =
    useContext(DependentDocumentContext);

  const clearDependentFields = (id: number) => {
    const dataToClearValues = [
      { key: seriesField, action: setDocSeriesKey },
      { key: numberField, action: setDocNumberKey },
      { key: issueField, action: setDocDateKey },
      { key: issuedField, action: setIssuedKey },
    ];

    if (id !== DocumentTypeEnum.BirthCertificateForeign) {
      dataToClearValues.push({ key: issuePlaceField, action: setIssuedPlaceKey });
      setAsyncValues(dataToClearValues, setFieldValue);
    } else {
      setAsyncValues(dataToClearValues, setFieldValue);
      setFieldValue(issuePlaceField, IssuedPlaceEnum.ForeignStates);
      setIssuedPlaceKey(Math.random());
    }
  };

  // Если с бэкенда пришли значения "documentTypeId" и "documentTypeName" которых нет,
  // в данном типе обучения то мы устанавливаем дефолтное значение
  useEffect(() => {
    if (parent === 'child') {
      const documentDayCareOptions = [
        DocumentTypeEnum.BirthCertificate,
        DocumentTypeEnum.BirthCertificateForeign,
        DocumentTypeEnum.BirthRecord,
      ];

      const checkDayCareCentersEducation =
        educationTypeData[0].value === EducationTypeEnum.DayCareCentersEducation &&
        !documentDayCareOptions.includes(documentTypeId?.value);

      const checkOtherTypeEducation = ![
        ...documentDayCareOptions,
        DocumentTypeEnum.Passport,
        DocumentTypeEnum.ForeignPassport,
      ].includes(documentTypeId?.value);

      if (checkDayCareCentersEducation || checkOtherTypeEducation) {
        setDocumentTypeId.setValue(optionsData[0]?.value);
        setDocumentTypeName.setValue(optionsData[0]?.label);
      }
    }
  }, [documentTypeId?.value, educationTypeData, optionsData, parent, setDocumentTypeId, setDocumentTypeName]);

  return (
    <Field
      label={label}
      editMode={editMode}
      required={required}
      value={documentTypeName.value}
      size={size || undefined}
      defaultValue="—"
    >
      <FormikFormGroup required label="" name={documentTypeIdField}>
        <FormikSelect
          name={documentTypeIdField}
          size="small"
          isSearchable
          options={optionsData}
          defaultValue={
            documentTypeId.value && documentTypeName.value
              ? {
                  value: documentTypeId.value,
                  label: documentTypeName.value,
                }
              : null
          }
          placeholder="Выберите документ"
          disabled={disabled}
          selectedValue={(v: SelectOptionType) => {
            setDocumentTypeName.setValue(v.label);
            setDocumentTypeId.setValue(v.value);

            if (dependentFields) {
              clearDependentFields(Number(v.value));
            }
          }}
        />
      </FormikFormGroup>
    </Field>
  );
};

export default Document;
