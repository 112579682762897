import React from 'react';
import { useField } from 'formik';
import { ToggleGroup, ToggleGroupType } from '@mosru/esz_uikit';

export type FormicToggleGroupType = ToggleGroupType & {
  name: string;
  showErrorImmediately?: boolean;
  width?: string;
};

const FormikToggleGroup: React.FC<Omit<Omit<FormicToggleGroupType, 'labelId'>, 'onChange'>> = ({
  name,
  showErrorImmediately,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const error = showErrorImmediately ? meta.error : meta.touched && meta.error && meta.initialValue !== field.value;
  return (
    <ToggleGroup
      {...props}
      labelId={name}
      onChange={(checked) => {
        helpers.setValue(checked);
      }}
      error={error ? (meta.error as string) : ''}
    >
      {props.children}
    </ToggleGroup>
  );
};

export default FormikToggleGroup;
