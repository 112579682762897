import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Badge, Button, SelectOptionType, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import { routes } from '../../../config/constants';
import { useGetFromRoute } from '../../../hooks/get-from-route';
import { hasGeneralAccess } from '../../../lib/utils';
import { SignTypeEnum } from '../../../mock-data/sign-type-enum';
import OrganizationDetails from './details';
import OrganizationPhoto from './photo';
import { ReactComponent as IconSuccess } from '../../../assets/images/icons/alert-success.svg';
import { ReactComponent as IconUp } from '../../../assets/images/icons/up-color.svg';
import { ReactComponent as IconDanger } from '../../../assets/images/icons/alert-danger.svg';
import ChangeHistory from '../../../components/change-history';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import organizationApi from '../../../lib/api/organization';
import { Organization } from '../../../types/organization';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import SignModal from '../../../components/sign-modal';
import { accessVedomst, generalAccess } from '../../../mock-data/access-enum';
import Favorite from '../../../components/favorite';
import { downloadOrganizationSign } from '../../../lib/utils/organization';
import { dictionariesApi } from '../../../lib/api/dictionaries';
import OrganizationServices from './services';

const OrganizationInfo = () => {
  const [activeTab, setActiveTab] = useState('Основные сведения');
  const [signModal, showSignModal] = useState(false);
  const [signedDetails, setSignedDetails] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [departments, setDepartments] = useState<SelectOptionType[]>();

  const [signedDetailsOiv, setSignedDetailsOiv] = useState(false);
  const checkSigned =
    !!organization?.organizationSign?.signId ||
    (!!organization?.vedomstvoSign?.signId && organization?.vedomstvoId !== accessVedomst.Dsit);

  const fromRoute = useGetFromRoute();

  const { id } = useParams<Record<string, string | undefined>>();
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  let organizationId: number | undefined = Number(id);
  if (id === undefined || Number.isNaN(organizationId)) {
    organizationId = userProfile.organizationId;
  }

  const isAdminEdit = useMemo(() => hasGeneralAccess(userProfile, generalAccess.AdminEdit), [userProfile]);

  const accessUseSign =
    hasGeneralAccess(userProfile, generalAccess.UseSign) || hasGeneralAccess(userProfile, generalAccess.UseFakeSign);

  const canSign = accessUseSign || isAdminEdit;

  const getOrganization = useCallback(async () => {
    if (organizationId) {
      const departments = await dictionariesApi.getVedomstvo();
      const organization = await organizationApi.getOrganization(organizationId);
      setDepartments(departments);

      setOrganization(organization);
    }
  }, [organizationId]);

  const updateOrganization = useCallback(async (value: Organization) => {
    if (value) {
      setOrganization(value);
    }
  }, []);

  useEffect(() => {
    getOrganization();
  }, [getOrganization]);
  const tabs = useMemo(() => {
    const t = [
      {
        label: 'Основные сведения',
        component:
          organization && departments ? (
            <OrganizationDetails
              updateOrganization={updateOrganization}
              organization={organization}
              departments={departments}
            />
          ) : null,
        onClick: (label: string) => setActiveTab(label),
      },
      {
        label: 'Услуги и бюджетные места',
        component: organization && (
          <OrganizationServices
            organizationId={organization.id}
            vedomstvoId={organization.vedomstvoId}
            isArchive={organization.isArchive}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      },
    ];
    if (organization?.vedomstvoId !== accessVedomst.Dogm) {
      t.push({
        label: 'Фотографии организации',
        component: (
          <OrganizationPhoto
            checkSigned={checkSigned}
            organizationId={organizationId ?? 0}
            isArchive={organization?.isArchive ?? false}
          />
        ),
        onClick: (label: string) => setActiveTab(label),
      });
    }
    t.push({
      label: 'История изменений',
      component: (
        <ChangeHistory
          name="организаций"
          entityTypes={[EntityTypeEnum.Organization]}
          extendEntityGuid={organization?.extendEntityGuid}
        />
      ),
      onClick: (label: string) => setActiveTab(label),
    });
    return t;
  }, [organization, departments, updateOrganization, checkSigned, organizationId]);

  const showSignInfo =
    !organization?.isArchive &&
    !!organization &&
    (organization.vedomstvoId === accessVedomst.Dsit ||
      organization.vedomstvoId === accessVedomst.Kdc ||
      organization.vedomstvoId === accessVedomst.Dkgm);

  return (
    <>
      {organization && (
        <>
          <PageHeading
            title={organization.shortName}
            buttonBack
            routeBack={fromRoute || routes.organizations}
            sections={[
              { title: 'Главная', link: routes.main },
              { title: 'Организации', link: routes.organizations },
              { title: 'Сведения об организации' },
            ]}
            tabs={tabs}
            activeTab={activeTab}
            tabsButton={
              organization.isArchive ? <Badge text="Архивная запись" textColor="default" color="default" /> : ''
            }
            expansion={
              <Favorite
                entityId={organization.id}
                typeEntity="организацию"
                currentEntityType={organization?.shortName || ''}
                entityTypeId={EntityTypeEnum.Organization}
              />
            }
          />

          {showSignInfo && (
            <>
              {organization?.vedomstvoId !== accessVedomst.Dsit &&
                (organization?.vedomstvoSign ? (
                  <>
                    <div className="infobox infobox--success">
                      <div className="infobox__head">
                        <div className="infobox__body">
                          <div className="flex">
                            <IconSuccess className="flex-none" />
                            <Push size={8} orientation="horizontal" />
                            <span className="color-success-dark font-weight-bold">
                              Подписано электронной подписью ОИВ
                            </span>
                          </div>
                        </div>
                        <div className="infobox__control">
                          <button
                            type="button"
                            onClick={() => setSignedDetailsOiv(!signedDetailsOiv)}
                            className="icon-group"
                          >
                            <span className="icon-group__text color-success-dark">
                              {signedDetailsOiv ? 'Свернуть' : 'Подробнее о подписи'}
                            </span>
                            <span
                              className={classNames(
                                'icon-group__icon',
                                !signedDetailsOiv && 'icon-group__icon--rotate'
                              )}
                            >
                              <IconUp />
                            </span>
                          </button>
                        </div>
                      </div>

                      {signedDetailsOiv && (
                        <div className="infobox__details">
                          <div className="org-signed-details">
                            <div className="org-signed-details__item">
                              <div className="org-signed-details__label">Подписал:</div>
                              <div className="org-signed-details__text">{organization.vedomstvoSign.signer}</div>
                            </div>
                            <div className="org-signed-details__item">
                              <div className="org-signed-details__label">Дата и время:</div>
                              <div className="org-signed-details__text">
                                {moment(organization.vedomstvoSign.date).format('DD.MM.YYYY в HH:mm:ss')}
                              </div>
                            </div>
                            <div className="org-signed-details__item">
                              <div className="org-signed-details__label">Файл:</div>
                              <div className="org-signed-details__text">
                                <span
                                  onClick={() => {
                                    const id = organization.vedomstvoSign ? organization.vedomstvoSign.signId : 0;
                                    downloadOrganizationSign(id);
                                  }}
                                >
                                  {organization.vedomstvoSign.fileName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <Push size={8} orientation="vertical" />
                  </>
                ) : (
                  <>
                    <div className="infobox infobox--danger">
                      <div className="infobox__head">
                        <div className="infobox__body">
                          <div className="flex">
                            <IconDanger className="flex-none" />
                            <Push size={8} orientation="horizontal" />
                            <span className="color-danger font-weight-bold">Не подписано электронной подписью ОИВ</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Push size={8} orientation="vertical" />
                  </>
                ))}

              {organization?.organizationSign ? (
                <div className="infobox infobox--success">
                  <div className="infobox__head">
                    <div className="infobox__body">
                      <div className="flex">
                        <IconSuccess className="flex-none" />
                        <Push size={8} orientation="horizontal" />
                        <span className="color-success-dark font-weight-bold">
                          Подписано электронной подписью организации
                        </span>
                      </div>
                    </div>
                    <div className="infobox__control">
                      <button type="button" onClick={() => setSignedDetails(!signedDetails)} className="icon-group">
                        <span className="icon-group__text color-success-dark">
                          {signedDetails ? 'Свернуть' : 'Подробнее о подписи'}
                        </span>
                        <span className={classNames('icon-group__icon', !signedDetails && 'icon-group__icon--rotate')}>
                          <IconUp />
                        </span>
                      </button>
                    </div>
                  </div>

                  {signedDetails && (
                    <div className="infobox__details">
                      <div className="org-signed-details">
                        <div className="org-signed-details__item">
                          <div className="org-signed-details__label">Подписал:</div>
                          <div className="org-signed-details__text">{organization.organizationSign.signer}</div>
                        </div>
                        <div className="org-signed-details__item">
                          <div className="org-signed-details__label">Дата и время:</div>
                          <div className="org-signed-details__text">
                            {moment(organization.organizationSign.date).format('DD.MM.YYYY в HH:mm:ss')}
                          </div>
                        </div>
                        <div className="org-signed-details__item">
                          <div className="org-signed-details__label">Файл:</div>
                          <div className="org-signed-details__text">
                            <span
                              onClick={() => {
                                const id = organization.organizationSign ? organization.organizationSign.signId : 0;
                                downloadOrganizationSign(id);
                              }}
                            >
                              {organization.organizationSign.fileName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="infobox infobox--danger">
                  <div className="infobox__head">
                    <div className="infobox__body">
                      <div className="flex">
                        <IconDanger className="flex-none" />
                        <Push size={8} orientation="horizontal" />
                        <span className="color-danger font-weight-bold">
                          Не подписано электронной подписью организации
                        </span>
                      </div>
                    </div>
                    {canSign && (
                      <div className="infobox__control">
                        <Button danger onClick={() => showSignModal(true)} border size="small" label="Подписать ЭП" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {tabs.find((item) => item.label === activeTab)?.component}
          <SignModal
            show={signModal}
            onCloseHandle={() => {
              showSignModal(false);
              getOrganization();
            }}
            ids={[{ id: organizationId || 0 }]}
            getDataForSignHandle={async (id) => await organizationApi.getDataForSign(id)}
            setSignedDataHandle={async (id, sign) =>
              await organizationApi.setSignedData(id, SignTypeEnum.Operator, sign)
            }
          />
        </>
      )}
    </>
  );
};

export default OrganizationInfo;
