import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { Button, IconSearch, Panel, Push } from '@mosru/esz_uikit';
import { historyState } from '../../mock-data/history-state';
import { ReactComponent as IconOptions } from '../../assets/images/icons/options.svg';
import { ReactComponent as IconDown } from '../../assets/images/icons/down-black.svg';
import FormikInput from '../../components/formik/formik-input';
import FormikFormGroup from '../../components/formik/formik-form-group';
import FormikSelect from '../../components/formik/formik-select';
import FormikDatePicker from '../../components/formik/formik-datepicker';
import { SearchFavoriteFormData } from '../../types/favorite';
import useInitialErrors from '../../hooks/formik-initial-errors';
import { entityTypeEnum } from '../../mock-data';
import { getCountDiff, replaceHistoryState } from '../../lib/utils';
import { initialFormData } from './index';

type Props = {
  submitForm: (values: SearchFavoriteFormData) => void;
  initialForm: SearchFavoriteFormData;
};

const FavoriteSearch = ({ submitForm, initialForm }: Props) => {
  const [rerenderFormKey, setRerenderFormKey] = useState(0);
  const [open, setOpen] = useState(!!window.history.state[historyState.openAdvanced]);
  const initialErrors = useInitialErrors(initialForm, getValidationSchema());

  const handleClickAdvancedSearch = () => {
    replaceHistoryState({ [historyState.openAdvanced]: !open });
    setOpen((prevState) => !prevState);
  };

  return (
    <Formik
      key={rerenderFormKey}
      onSubmit={(values, formikHelpers) => {
        submitForm({ ...values, startDate: values.date && values.date[0], endDate: values.date && values.date[1] });
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialForm}
      validationSchema={getValidationSchema()}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<SearchFavoriteFormData>) => {
        const { handleSubmit, values, isSubmitting, isValid } = formikProps;

        const countDiff = getCountDiff(values, {
          ...initialFormData,
          startDate: values.startDate,
          endDate: values.endDate,
        });

        return (
          <form onSubmit={handleSubmit}>
            <Panel
              subControl={() => (
                <button type="button" onClick={handleClickAdvancedSearch} className="icon-group">
                  <span className="icon-group__icon">
                    <IconOptions />
                  </span>
                  <span className="icon-group__text">
                    Расширенный поиск {!open && countDiff > 0 && <span className="icon-group__badge">{countDiff}</span>}
                  </span>
                  <span className="icon-group__icon">
                    {open ? <IconDown style={{ transform: 'rotate(180deg)' }} /> : <IconDown />}
                  </span>
                </button>
              )}
              controls={() => (
                <>
                  <Button
                    label="Сбросить"
                    handleClick={() => {
                      submitForm(initialFormData);
                      setRerenderFormKey(Math.random());
                    }}
                    border
                    size="small"
                  />
                  <Push orientation="horizontal" size={12} />
                  <Button label="Начать поиск" submit load={isSubmitting} disabled={!isValid} primary size="small" />
                </>
              )}
            >
              <div className="container">
                <Push size={20} />
                <FormikInput
                  name="query"
                  value={values.query}
                  placeholder="Поиск по избранному..."
                  iconLeft={() => <IconSearch />}
                />
                {open && (
                  <>
                    <Push size={16} />
                    <div className="favorite-input-grid">
                      <FormikFormGroup name="entityTypeId" label="Тип объекта">
                        <FormikSelect name="entityTypeId" size="small" isSearchable options={entityTypeEnum} />
                      </FormikFormGroup>
                      <FormikFormGroup name="date" label="Дата добавления в избранное">
                        <FormikDatePicker rangePicker name="date" size="small" placeholder="ДД.ММ.ГГГГ-ДД.ММ.ГГГГ" />
                      </FormikFormGroup>
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </form>
        );
      }}
    </Formik>
  );
};

const getValidationSchema = () =>
  objectYup().shape({
    request: stringYup().optional(),
    type: stringYup().optional(),
    // date: mixedYup().required('Выберите дату'),
  });

export default FavoriteSearch;
