import React, { useContext, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { object as objectYup, array as arrayYup } from 'yup';
import { Button, Infobox, notify, Panel, Push } from '@mosru/esz_uikit';
import { infoValidationSchema } from '../panels/info';
import { CreateInfo } from './create-info';
import useInitialErrors from '../../../../hooks/formik-initial-errors';
import { BudgetPlacesData, ItemWorkData, PlaceServiceData, ServiceData } from '../../../../types/service';
import history from '../../../../history';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { convertScheduleToBack } from '../../../../lib/utils/time-table';
import EditAddressDocuments from '../../components/panels/address-documents/edit';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import EditAddressClass from '../../components/panels/address-class/edit';
import { CreateServiceContext } from '../../create';
import { contactPersonValidationSchema } from '../../components/panels/contact-person';
import { generateLink, hasGeneralAccess } from '../../../../lib/utils';
import { routes } from '../../../../config/constants';
import { CreateContactPerson } from '../../components/panels/contact-person/create-contact-person';
import CreateFinancing from '../../components/panels/financing/create';
import EditServiceSchedule from '../../components/panels/service-schedule/edit';
import { financingValidationSchema } from '../../components/panels/financing';
import Module from './module';
import { getHeaderLink, setGenderStartEndToBack } from '../../utils';
import { generalAccess } from '../../../../mock-data/access-enum';

const ServiceProgramCreateDetails = () => {
  const { serviceData, educationTypes, type } = useContext(CreateServiceContext);
  const initialErrors = useInitialErrors(serviceData, getValidationSchema());

  const [load, setLoad] = useState<boolean>(false);
  const [scheduleError, setScheduleError] = useState<boolean>(false);
  const [itemWorkList, setItemWorkList] = useState<ItemWorkData[]>(serviceData.itemWork?.list || []);
  const [placeServiceData, setPlaceServiceData] = useState<PlaceServiceData[]>(serviceData.placeService?.list || []);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [budgetPlaces, setBudgetPlaces] = useState<BudgetPlacesData>();

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const canCreatePaydableService = hasGeneralAccess(userProfile, generalAccess.CreatePaydableService);
  const onlyPayment = !budgetPlaces?.limitVolume || budgetPlaces.limitVolume === (budgetPlaces.volume || 0);

  const submitForm = async (values: ServiceData) => {
    if (onlyPayment && !canCreatePaydableService) {
      notify.danger({
        data: {
          label: 'Невозможно создать программу',
          text: 'По выбранной программе отсутствуют бюджетные места, у пользователя отсутствует право на создание платных услуг',
          icon: true,
        },
      });
      return;
    }

    setLoad(true);
    values.itemWork.list = itemWorkList;
    values.placeService.list = placeServiceData;
    try {
      const serviceId = await serviceTemplateApi.createService({
        ...values,
        info: setGenderStartEndToBack(values.info),
      });
      history.push(generateLink(routes.registerEducationProgram, { id: serviceId }));
      setLoad(false);
    } catch (e) {
      setLoad(false);
    }
  };

  const initialValues =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV)
      ? { ...serviceData, info: { ...serviceData.info, organizationName: '' } }
      : serviceData;

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        submitForm(values);
        formikHelpers.setSubmitting(false);
      }}
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
    >
      {(formikProps: FormikProps<ServiceData>) => {
        const { handleSubmit, isValid, values, setFieldValue } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />

            <CreateInfo budgetPlaces={budgetPlaces} updateBudgetPlaces={setBudgetPlaces} />

            <CreateContactPerson parent="contactPerson" />

            <Push size={12} />
            <Panel
              title={() => (
                <>
                  Адреса проведения занятий{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {placeServiceData.filter(({ isSelected }) => isSelected).length}
                  </span>
                </>
              )}
            >
              <EditAddressClass
                placeServiceData={placeServiceData}
                setPlaceServiceData={setPlaceServiceData}
                organizationId={values.info?.organizationId || userProfile.organizationId}
                educationType={values.educationTypeId}
                editCount="many"
              />
            </Panel>
            <Push size={12} />

            <Module />

            <Push size={12} />

            <CreateFinancing onlyPayment={canCreatePaydableService && onlyPayment} />

            <Push size={12} />
            <EditServiceSchedule
              isCreate
              submit={(schedule, selectedSchedule) => {
                setFieldValue('schedule.list', convertScheduleToBack(schedule));
                setFieldValue('schedule.scheduleTypeOfServiceName', selectedSchedule.label);
                setFieldValue('schedule.scheduleTypeOfServiceId', selectedSchedule.value);
                handleSubmit();
              }}
              scheduleSubmit={scheduleSubmit}
              setScheduleSubmit={setScheduleSubmit}
              setScheduleError={setScheduleError}
              serviceData={serviceData}
              title="Время проведения занятий"
            />

            <Push size={12} />
            <Panel
              title={() => (
                <>
                  Адреса приема документов{' '}
                  <span className="color-gray-dark">
                    {' \u00A0'} {itemWorkList.filter((i) => i.isSelected).length}
                  </span>
                </>
              )}
            >
              <EditAddressDocuments
                itemWorkList={itemWorkList}
                setItemWorkList={setItemWorkList}
                organizationId={values.info?.organizationId || userProfile.organizationId}
              />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Планы приема'}>
              <Infobox
                fullWidth
                color="warning"
                text="Планы приема станут доступными для ввода после первого сохранения."
              />
              <Push size={24} />
            </Panel>

            <Push size={12} />
            <Panel title={() => 'Фотографии услуги'}>
              <Infobox
                fullWidth
                color="warning"
                text="Возможность добавления фотографий услуги станет доступна после первого сохранения."
              />
              <Push size={24} />
            </Panel>

            <div className="room-save-container">
              <div className="room-panel-save">
                <div className="container">
                  <div className="room-panel-save__inner">
                    <Button
                      primary
                      onClick={() => history.push(getHeaderLink(educationTypes, type, serviceData?.educationTypeId))}
                      border
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      primary
                      load={load}
                      disabled={!isValid || scheduleError}
                      onClick={() => setScheduleSubmit(true)}
                      label="Создать образовательную программу"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ServiceProgramCreateDetails;

export const getValidationSchema = () =>
  objectYup().shape({
    info: infoValidationSchema(),
    contactPerson: contactPersonValidationSchema(),
    financing: financingValidationSchema(),
    programmModule: arrayYup()
      .required('Должен состоять из списка модулей') // these constraints are shown if and only if inner constraints are satisfied
      .min(1, 'Минимум 1 модуль'),
  });
