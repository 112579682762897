import React, { useEffect, useState } from 'react';
import { getUserCertificates, Certificate, createAttachedSignature } from 'crypto-pro';
import { useDispatch } from 'react-redux';
import { Button, RadioGroup, Radio, Heading, Infobox, Push } from '@mosru/esz_uikit';
import SwitcherForm from '../switcher';
import SelectCertificate from '../../../components/certificate';
import { authApi } from '../../../lib/api';
import { onSignIn } from '../../../redux/thunks/auth';

const Sign = () => {
  const [certificate, setCertificate] = useState<Certificate | null>(null);
  const [infoError, showInfoError] = useState(false);
  const [pluginError, showPluginError] = useState(false);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getCert = async () => {
      try {
        const certs = await getUserCertificates();
        setCertificates(certs);
      } catch (error) {
        showPluginError(true);
      }
    };
    getCert();
  }, []);

  const handleSubmit = async () => {
    if (certificate) {
      showInfoError(false);
      setIsSubmitting(true);
      //
      try {
        const sign = await createAttachedSignature(certificate.thumbprint, 'ui');
        const response = await authApi.loginBySign(sign);
        if (response.isLoggedIn) {
          await dispatch(onSignIn(response.token));
        } else {
          showInfoError(true);
        }
      } catch (e) {
        showInfoError(true);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Push size={8} />
      <SwitcherForm />
      <Push size={80} />
      <Heading label="Выбор сертификата" as="h3" />
      <Push size={16} />
      {certificates.length > 0 ? (
        certificates.map((cert) => (
          <RadioGroup
            background
            size="large"
            name={cert.thumbprint}
            labelId={cert.thumbprint}
            onChange={() => setCertificate(cert)}
            label={<SelectCertificate name={cert.name} validTo={cert.validTo} />}
            checked={false}
          >
            {(props) => <Radio {...props} checked={certificate?.thumbprint === cert.thumbprint} />}
          </RadioGroup>
        ))
      ) : pluginError ? (
        <Infobox
          text='Ошибка загрузки плагина «КриптоПро ЭЦП Browser plug-in». Для входа с помощью электронной подписи требуется установленный на компьютер плагин «КриптоПро ЭЦП Browser plug-in». Вы
              можете загрузить его, перейдя по ссылке:
              <a href="https://cryptopro.ru/products/cades/plugin/get_2_0">Скачать КриптоПро ЭЦП Browser plug-in</a>'
          color="danger"
        />
      ) : (
        <span className="auth-not-certificate">У вас нет доступных для выбора сертификатов.</span>
      )}

      <Push size={16} />
      <Button
        onClick={handleSubmit}
        submit
        label="Готово"
        primary
        load={isSubmitting}
        disabled={!certificate}
        fullWidth
      />

      {infoError && (
        <>
          <Push size={20} />
          <Infobox text="Некорректный файл электронной подписи. Выберите другой файл" color="danger" />
        </>
      )}
    </>
  );
};

export default Sign;
