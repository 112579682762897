import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, notify, Panel, Loader, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import OrganizationField from '../components/fields/organization';
import ClassParallelLetter from '../components/fields/class-parallel-leter';
import { SchoolRequestData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import { classParallelId, organizationId } from '../../../lib/utils/validation';

type Props = {
  setEditModeParent: (value: string | null) => void;
  data: SchoolRequestData;
  updateRequest: () => void;
  requestId: number;
  showEditBtn?: (type: string) => boolean;
  requestStatusId?: number;
  changeStatusRequest?: () => void;
};

const Organization: React.FC<Props> = ({
  setEditModeParent,
  data,
  updateRequest,
  requestId,
  showEditBtn,
  requestStatusId,
  changeStatusRequest,
}) => {
  const [editMode, setEditMode] = useState(false);
  const initialErrors = useInitialErrors(data, organizationValidationSchema);
  const [loading, setLoading] = useState(false);
  const [classParallelIdKey, setClassParallelIdKey] = useState<number>(0);
  const [classLetterIdKey, setClassLetterIdKey] = useState<number>(0);

  const submitForm = useCallback(
    async (values: SchoolRequestData) => {
      setLoading(true);
      try {
        values.requestId = requestId;
        await requestsApi.saveOrganization(requestId, values);

        setEditModeParent(null);
        setEditMode(false);
        // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
        changeStatusRequest && changeStatusRequest();
        updateRequest();
      } catch (e) {
        notify.danger({
          data: {
            label: 'Не удалось сохранить информацию о группе по присмотру и уходу за детьми школьного возраста',
            text: e,
            icon: true,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditModeParent, updateRequest, requestId]
  );

  return loading ? (
    <div className="loader-container">
      <Loader title={`Идет сохранение! `} description="Пожалуйста, подождите..." />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={organizationValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={data}
    >
      {(formikProps: FormikProps<SchoolRequestData>) => {
        const { handleSubmit, isSubmitting, resetForm, isValid, initialValues } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Push size={12} />
            <Panel
              title={() => 'Общеобразовательная организация'}
              headingControl={() => {
                return !editMode && showEditBtn && showEditBtn('organization') ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditModeParent('organization');
                      setEditMode(true);
                    }}
                    className="icon-group"
                  >
                    <span className="icon-group__icon">
                      <IconEdit />
                    </span>
                    <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
                  </button>
                ) : null;
              }}
            >
              <div className="container">
                <div className="table-data">
                  <OrganizationField
                    editMode={editMode}
                    name="schoolOrganization"
                    setClassParallelIdKey={setClassParallelIdKey}
                    setClassLetterIdKey={setClassLetterIdKey}
                    requestStatusId={requestStatusId}
                    initialValues={initialValues}
                    required
                  />

                  <ClassParallelLetter
                    editMode={editMode}
                    classParallelIdKey={classParallelIdKey}
                    classLetterIdKey={classLetterIdKey}
                    setClassLetterIdKey={setClassLetterIdKey}
                  />
                </div>
              </div>
            </Panel>

            {editMode && (
              <div className="room-save-container">
                <div className="room-panel-save">
                  <div className="container">
                    <div className="room-panel-save__inner">
                      <Button
                        onClick={() => {
                          setEditModeParent(null);
                          setEditMode(false);
                          resetForm();
                        }}
                        border
                        primary
                        label="Отмена"
                      />
                      <Push size={12} orientation="horizontal" />
                      <Button
                        submit
                        onClick={handleSubmit}
                        load={isSubmitting}
                        disabled={!isValid}
                        primary
                        label="Сохранить"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export const organizationValidationSchema = objectYup().shape({
  schoolOrganizationId: organizationId,
  classParallelId,
});

export default Organization;
