import React from 'react';
import { Infobox } from '@mosru/esz_uikit';
import { ContingentLinkTypeEnum } from '../../../mock-data/contingent-link-type-enum';

type Props = {
  contingentLinkTypeId: number;
};

export const StatusChild: React.FC<Props> = ({ contingentLinkTypeId }) => {
  const text = {
    found: 'Сведения найдены в Реестре Контингента',
    other:
      'Проверка в реестре контингента не проводилась.' +
      ' Сведения будут проверены, результаты проверки' +
      ' отображены после первого сохранения.',
  };

  return contingentLinkTypeId === ContingentLinkTypeEnum.Found ? (
    <Infobox fullWidth text={text.found} color="success" />
  ) : (
    <Infobox fullWidth color="warning" text={text.other} />
  );
};
