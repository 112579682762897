import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Panel, Loader, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconPlus } from '../../../../assets/images/icons/plus-color.svg';
import { ItemsWork } from '../../../../types/items-work';
import RemoveModal from '../../../../components/remove-modal';
import { Organization } from '../../../../types/organization';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { TablePoint } from './table-point';
import { ModalForm } from './modal-form';
import { dictionariesApi } from '../../../../lib/api/dictionaries';

type Props = {
  organization: Organization;
  setWork: (value: ItemsWork[]) => void | undefined;
};

const OrganizationFormPoint = ({ organization, setWork }: Props) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [editMode, setEditMode] = useState<boolean>(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);

  const [itemWorks, setItemWorks] = useState<ItemsWork[]>([]);
  const [itemWork, setItemWork] = useState<ItemsWork | null>();

  const [removedId, setRemovedId] = useState(0);
  const [rerenderTableKey, setRerenderTableKey] = useState(0);

  useEffect(() => {
    (async () => {
      if (organization.id) {
        const itemWorks = await dictionariesApi.getItemsWorkList(organization.id);
        setItemWorks(itemWorks);
        setLoading(false);
        setWork(itemWorks);
      }
    })();
  }, [organization.id, removedId, editMode, setWork]);

  const tableData = itemWorks?.map((item, index) => ({ count: index + 1, ...item }));

  const addressOptions = useMemo(
    () =>
      organization.addressList?.map((address) => ({
        label: address.fullAddress,
        value: address.id,
      })) || [],
    [organization.addressList]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <>
            Пункты работы с заявителями
            <span className="color-gray-dark">
              {' \u00A0'}
              {tableData?.length}
            </span>
          </>
        )}
        headingControl={() =>
          !organization.isArchive &&
          hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
            <button
              type="button"
              onClick={() => {
                setEditMode(true);
                setItemWork(null);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconPlus />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Добавить</span>
            </button>
          )
        }
      >
        {loading ? (
          <div className="flex justify-center flex-column">
            <Push orientation="vertical" size={20} />
            <Loader roller small />
            <Push orientation="vertical" size={20} />
          </div>
        ) : tableData?.length ? (
          <TablePoint
            tableData={tableData}
            setEditMode={setEditMode}
            setRemovedId={setRemovedId}
            organization={organization}
            rerenderTableKey={rerenderTableKey}
            userProfile={userProfile}
            setItemWork={setItemWork}
          />
        ) : (
          <div className="table-no-data">Нет записей в таблице</div>
        )}
      </Panel>

      <Modal
        show={editMode}
        onClose={() => {
          setEditMode(false);
          setItemWork(null);
        }}
      >
        <ModalForm
          itemWork={itemWork}
          editMode={editMode}
          setItemWork={setItemWork}
          setEditMode={setEditMode}
          organization={organization}
          addressOptions={addressOptions}
          scheduleSubmit={scheduleSubmit}
          setScheduleSubmit={setScheduleSubmit}
          setRerenderTableKey={setRerenderTableKey}
        />
      </Modal>
      <RemoveModal
        buttonReverse
        title="Удаление пункта работы с заявителями"
        description="Вы уверены, что хотите удалить выбранную запись?"
        onCloseHandle={() => setRemovedId(0)}
        show={!!removedId}
        onRemoveHandler={async () => {
          await dictionariesApi.deleteItemWork({ ids: [removedId], organizationId: organization.id });
          setRerenderTableKey(Math.random());
          setRemovedId(0);
        }}
      />
    </>
  );
};

export default OrganizationFormPoint;
