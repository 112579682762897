import React from 'react';
import { Button, IconCheckmark, IconClear, Push } from '@mosru/esz_uikit';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { DisciplineEditObject } from '../../../../types/teacher';

type Props = {
  id: number;
  editMode: boolean;
  filterTable: () => void;
  submitHandler: (id: number) => void;
  isAcceptedEdit: boolean;
  editObject: DisciplineEditObject;
  setEditMode: (v: boolean) => void;
  deleteDiscipline: (v: number) => void;
};

const EditDisciplines: React.FC<Props> = ({
  id,
  submitHandler,
  deleteDiscipline,
  editMode,
  editObject,
  setEditMode,
  filterTable,
  isAcceptedEdit,
}) => {
  return isAcceptedEdit ? (
    <div className="teacher-table-comment">
      <div className="teacher-table-comment__controls">
        {editMode ? (
          <>
            <Button
              iconLeft={() => <IconClear />}
              border
              size="small"
              handleClick={() => {
                setEditMode(false);
                filterTable();
              }}
            />
            <Push size={12} orientation="horizontal" />
            <Button
              iconLeft={() => <IconCheckmark />}
              border
              success
              disabled={!editObject.newId || editObject.newId === id}
              size="small"
              handleClick={() => {
                submitHandler(id);
              }}
            />
          </>
        ) : (
          <>
            <DropDown
              itemId={id}
              component={() => (
                <span className="drop-down-btn-round">
                  <IconDots />
                </span>
              )}
            >
              <div className="drop-down-panel">
                <div className="drop-down-panel__list">
                  <button
                    type="button"
                    onClick={() => {
                      filterTable();
                      setEditMode(true);
                    }}
                    className="drop-down-panel__list-item"
                  >
                    <IconEdit />
                    Редактировать
                  </button>
                  <button type="button" onClick={() => deleteDiscipline(id)} className="drop-down-panel__list-item">
                    <IconRemove />
                    Удалить
                  </button>
                </div>
              </div>
            </DropDown>
            <Push size={2} orientation="horizontal" />
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default EditDisciplines;
