import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import Field, { FieldProps } from './field';
import FormikTextarea from '../formik/formik-textarea';
import FormikFormGroup from '../formik/formik-form-group';

type SimpleTextAreaProps = FieldProps<string> & {
  placeholder?: string;
};

const SimpleTextArea = ({
  label,
  disabled,
  defaultValue = '—',
  name,
  editMode,
  required,
  placeholder,
  maxLength,
}: SimpleTextAreaProps) => {
  return (
    <FormikField name={name}>
      {({ field }: FormikFieldProps) => {
        const { value } = field;

        return (
          <Field
            label={label}
            editMode={editMode}
            disabled={disabled}
            required={required}
            value={value}
            defaultValue={defaultValue}
            isAlignStart
          >
            <div className="table-data__item table-data__group">
              <div className="table-data__body">
                <FormikFormGroup required={required} label="" name={name}>
                  <FormikTextarea
                    rows={2}
                    name={name}
                    disabled={disabled}
                    value={field?.value}
                    maxLength={maxLength}
                    placeholder={placeholder}
                  />
                </FormikFormGroup>
              </div>
            </div>
          </Field>
        );
      }}
    </FormikField>
  );
};

export default SimpleTextArea;
