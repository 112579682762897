import React, { useContext } from 'react';
import { Push } from '@mosru/esz_uikit';

import SecurityHistory from '../../../components/change-history';
import { EntityTypeEnum } from '../../../mock-data/entity-type-enum';
import { ServiceClassContext } from '../service-class';
import Enrollments from '../../../components/change-history/enrollments';
import TransfersGroup from '../../../components/change-history/transfers-group';
import Expulsions from '../../../components/change-history/expulsions';
import Recoveries from '../../../components/change-history/recoveries';
import TransfersService from '../../../components/change-history/transfers-service';
import TransfersStage from '../../../components/change-history/transfers-stage';
import StartEndDateChange from '../../../components/change-history/start-end-date-change';

const ChangeHistory: React.FC = () => {
  const { serviceClassData } = useContext(ServiceClassContext);

  return (
    <>
      <Push size={12} />
      <SecurityHistory
        title="История изменений"
        name="сведения о группе"
        extendEntityGuid={serviceClassData.extendEntityGuid}
        entityTypes={[EntityTypeEnum.ServiceClass]}
        defaultLoading
      />
      <Enrollments type="group" id={serviceClassData.id ?? 0} />
      <TransfersGroup type="group" id={serviceClassData.id ?? 0} />
      <Expulsions type="group" id={serviceClassData.id ?? 0} />
      <Recoveries type="group" id={serviceClassData.id ?? 0} />
      <TransfersService type="group" id={serviceClassData.id ?? 0} educationType={serviceClassData.educationTypeId} />
      <TransfersStage type="group" id={serviceClassData.id ?? 0} />
      <StartEndDateChange type="group" id={serviceClassData.id ?? 0} />
    </>
  );
};

export default ChangeHistory;
