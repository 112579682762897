import React from 'react';

import { Button, ModalPanel, Modal, Push } from '@mosru/esz_uikit';

type Props = {
  show: boolean;
  onClose: (result?: boolean) => void;
  handleRemoval: () => void;
};

const RemovalFromPublicationModal = ({ show, onClose, handleRemoval }: Props) => {
  return (
    <Modal show={show} onClose={onClose}>
      <ModalPanel
        alert
        modalTitle="Снятие с публикации"
        onClose={onClose}
        description="Внимание! Публикация на Mos.ru будет отменена. Продолжить?"
        controls={() => (
          <>
            <Button primary border label="Отмена" onClick={onClose} />
            <Push orientation="horizontal" size={12} />
            <Button primary label="Да, продолжить" onClick={handleRemoval} />
          </>
        )}
      />
    </Modal>
  );
};

export default RemovalFromPublicationModal;
