import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { object as objectYup } from 'yup';
import { Button, Push } from '@mosru/esz_uikit';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { requestDeclineReasonId } from '../../../lib/utils/validation';
import DeclineReason from '../components/fields/decline-reason';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import { RequestDeclineData } from '../../../types/requests';
import requestsApi from '../../../lib/api/requests';
import history from '../../../history';
import { generateLink } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import SavePanel from '../../../components/save-panel';

type Props = {
  editMode: boolean;
  disabled: boolean;
  setEditMode: () => void;
  decline: RequestDeclineData;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
};

const ActionsFormRefusal: React.FC<Props> = ({
  editMode,
  setEditMode,
  decline,
  serviceId,
  requestId,
  disabled,
  updateRequest,
}) => {
  const initialErrors = useInitialErrors(decline, getValidationSchema());
  const [formKey, setFormKey] = useState<number>(0);

  const submitForm = useCallback(
    async (values: RequestDeclineData) => {
      const fetch = async () => {
        try {
          const id = await requestsApi.saveRejection(requestId, values);
          if (id !== requestId) {
            history.push(generateLink(routes.request, { id }));
          } else {
            updateRequest();
          }
          setEditMode();
        } catch (ex) {
          console.error(ex);
        }
      };
      fetch();
    },
    [requestId, setEditMode, updateRequest]
  );

  return (
    <Formik
      key={formKey}
      initialErrors={initialErrors}
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={decline}
    >
      {(formikProps: FormikProps<RequestDeclineData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="table-data">
                <DeclineReason
                  required
                  name="requestDeclineReason"
                  editMode={editMode}
                  serviceId={serviceId}
                  requestId={requestId}
                  disabled={disabled}
                />
                <SimpleTextArea
                  editMode={editMode}
                  label="Комментарий"
                  disabled={disabled}
                  name="declineComment"
                  placeholder={disabled ? '' : 'Введите...'}
                />
              </div>
            </div>

            {editMode && (
              <SavePanel
                controls={
                  <>
                    <Button
                      onClick={() => {
                        resetForm();
                        setEditMode();
                        setFormKey(Math.random());
                      }}
                      border
                      primary
                      label="Отмена"
                    />
                    <Push size={12} orientation="horizontal" />
                    <Button
                      handleClick={handleSubmit}
                      load={isSubmitting}
                      disabled={!isValid}
                      primary
                      label="Сохранить"
                    />
                  </>
                }
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormRefusal;

const getValidationSchema = () =>
  objectYup().shape({
    requestDeclineReasonId,
  });
