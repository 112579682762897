import React from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import { ServiceRegistryContext } from '../../initial-data';
import { RegistryTypeEnum } from '../../../../../mock-data/registry-type-enum';

const FieldEducationType: React.FC = () => {
  const { setFieldValue } = useFormikContext();

  const { educationTypeData, isAdmin, type } = React.useContext(ServiceRegistryContext);

  return (
    <FormikFormGroup name="educationTypeId" label="Тип образования">
      <FormikSelect
        name="educationTypeId"
        size="small"
        disabled={
          !(educationTypeData.length > 1) && (!isAdmin || RegistryTypeEnum.serviceEducationProgramType === type)
        }
        defaultValue={{ label: educationTypeData[0]?.label, value: educationTypeData[0]?.value }}
        isSearchable
        options={educationTypeData}
        placeholder="Выберите..."
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('educationTypeName', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};

export default FieldEducationType;
