import React from 'react';
import { Button, Push } from '@mosru/esz_uikit';
import { RequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';
import { ReactComponent as IconInfo } from '../../../assets/images/icons/info-danger.svg';
import requestsApi from '../../../lib/api/requests';
import { EducationTypeEnum } from '../../../types/education-type';
import { ContractStatusEnum } from '../../../mock-data/contract-status-enum';
import { ReactComponent as IconWarning } from '../../../assets/images/icons/clock-warning.svg';
import { formatDate } from '../../../lib/utils/date';

type Props = {
  requestData: RequestData;
  updateRequest: () => void;
};

const Status: React.FC<Props> = ({ requestData, updateRequest }) => {
  return (
    <>
      {requestData.requestStatusId === RequestStatusEnum.New && (
        <>
          <Push size={12} />
          <div className="infobox infobox--danger">
            <div className="infobox__head">
              <div className="infobox__body">
                <div className="flex">
                  <IconInfo className="flex-none" />
                  <Push size={8} orientation="horizontal" />
                  <div className="color-danger-dark">
                    Для перевода заявления в статус “Ожидание прихода заявителя для заключения договора” нажмите кнопку
                    “Зарегистрировать личный визит”
                  </div>
                </div>
              </div>
              <div className="infobox__control">
                <Button
                  size="small"
                  primary
                  label="Зарегистрировать личный визит"
                  onClick={async () => {
                    try {
                      await requestsApi.setRequestStatus(
                        requestData.id,
                        requestData.educationTypeId,
                        requestData.requestStatusId,
                        RequestStatusEnum.ForInclude
                      );
                    } finally {
                      updateRequest();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {(requestData.educationTypeId === EducationTypeEnum.ChildrenEducation ||
        requestData.educationTypeId === EducationTypeEnum.ChildrenNonDogmEducation) &&
        ((requestData.requestStatusId === RequestStatusEnum.WaitForElectronicAccept &&
          requestData.contract?.contractStatusId !== ContractStatusEnum.SigningRequiredByOrganization) ||
          requestData.requestStatusId === RequestStatusEnum.ForInclude) && (
          <>
            <Push size={12} />
            <div className="infobox infobox--warning">
              <div className="infobox__head">
                <div className="infobox__body">
                  <div className="flex">
                    <IconWarning className="flex-none" />
                    <Push size={8} orientation="horizontal" />
                    <div className="color-warning-dark font-weight-bold">
                      {requestData.requestStatusId === RequestStatusEnum.WaitForElectronicAccept
                        ? 'Для перевода заявления в статус “Поданы документы” нажмите кнопку “Поданы документы”'
                        : `Дата явки  заявителя для заключения договора: ${
                            requestData?.actionDeadline ? formatDate(requestData.actionDeadline) : '—'
                          }`}
                    </div>
                  </div>
                </div>
                {requestData.educationTypeId !== EducationTypeEnum.ChildrenNonDogmEducation && (
                  <div className="infobox__control">
                    <Button
                      size="small"
                      primary
                      label="Поданы документы"
                      onClick={async () => {
                        try {
                          await requestsApi.setRequestStatus(
                            requestData.id,
                            requestData.educationTypeId,
                            requestData.requestStatusId,
                            RequestStatusEnum.DocumentAccepted
                          );
                        } finally {
                          updateRequest();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default Status;
