import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { /* array as arrayYup, */ object as objectYup, string as stringYup } from 'yup';
import classNames from 'classnames';
import { ToggleGroup, Panel, Toggle, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../../../../assets/images/icons/edit-color.svg';
import FormikFormGroup from '../../../../../../components/formik/formik-form-group';
import FormikInput from '../../../../../../components/formik/formik-input';
import { AdmissionPlanData, ServiceData } from '../../../../../../types/service';
import FormikToggleGroup from '../../../../../../components/formik/formik-toggle-group';
import FormikToggle from '../../../../../../components/formik/formik-toggle';

type Props = {
  setEditModeParent?: (value: string | null) => void;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  parent?: string;
  isTemplate?: boolean;
  checkEditable?: boolean;
};

const TestsEdit: React.FC<Props> = ({
  isTemplate,
  setEditModeParent,
  parent,
  editMode,
  setEditMode,
  checkEditable,
}) => {
  const { values, setFieldValue } = useFormikContext<ServiceData>();

  useEffect(() => {
    if (values.test === null) {
      setFieldValue('test', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldFullName = useCallback((name: string) => (parent ? `${parent}.${name}` : name), [parent]);

  const getFieldValue = useCallback(
    (name: string) => {
      const v = values as any;
      return parent ? (v[parent] ? v[parent][name] : undefined) : v[name];
    },
    [parent, values]
  );

  return (
    <>
      <Push size={12} />
      <Panel
        title={() => (
          <div className="flex">
            Вступительные испытания
            <div className="flex font-weight-base">
              <Push orientation="horizontal" size={16} />
              <ToggleGroup
                labelId="tests"
                label="Проводятся"
                size="small"
                disabled={!editMode || values.test?.hasTrainingGroups}
              >
                <Toggle
                  size="xsmall"
                  labelId="tests"
                  onChange={(value) => {
                    setFieldValue(getFieldFullName('isRequired'), value);
                  }}
                  disabled={
                    isTemplate
                      ? !isTemplate || !editMode
                      : !editMode || !getFieldValue('canChangeTestAndToursNumber') || getFieldValue('hasTrainingGroups')
                  }
                  checked={getFieldValue('isRequired')}
                />
              </ToggleGroup>
            </div>
          </div>
        )}
        headingControl={() => {
          return checkEditable && !editMode && setEditModeParent && (!parent || isTemplate) ? (
            <button
              type="button"
              onClick={() => {
                setEditModeParent && setEditModeParent('tests');
                setEditMode && setEditMode(true);
              }}
              className="icon-group"
            >
              <span className="icon-group__icon">
                <IconEdit />
              </span>
              <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
            </button>
          ) : null;
        }}
      >
        <div className="container">
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              Количество туров {editMode && <span className="table-data__required" />}
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikFormGroup name={getFieldFullName('toursNumber')} label="" required>
                  <div style={{ width: 88 }}>
                    <FormikInput
                      number
                      name={getFieldFullName('toursNumber')}
                      size="small"
                      placeholder="0"
                      disabled={
                        isTemplate
                          ? !isTemplate || !getFieldValue('isRequired')
                          : !getFieldValue('isRequired') || !getFieldValue('canChangeTestAndToursNumber')
                      }
                    />
                  </div>
                </FormikFormGroup>
              ) : (
                getFieldValue('toursNumber') || '—'
              )}
            </div>
            {isTemplate && (
              <div className="table-data__control">
                <FormikToggleGroup name={getFieldFullName('canChangeTestAndToursNumber')} label="">
                  <FormikToggle
                    disabled={!editMode}
                    name={getFieldFullName('canChangeTestAndToursNumber')}
                    size="xsmall"
                  />
                </FormikToggleGroup>
              </div>
            )}
          </div>
          <div className="table-data__item table-data__group">
            <div className="table-data__label table-data__label--main">
              <div>
                Срок предоставления док-ов / выкупа брони, дней {editMode && <span className="table-data__required" />}
              </div>
            </div>
            <div className="table-data__body">
              {editMode ? (
                <FormikFormGroup name={getFieldFullName('periodGettingDocument')} label="" required>
                  <div style={{ width: 88 }}>
                    <FormikInput
                      name={getFieldFullName('periodGettingDocument')}
                      size="small"
                      placeholder="0"
                      number
                      disabled={!getFieldValue('canChangePeriodGettingDocument') && !isTemplate}
                    />
                  </div>
                </FormikFormGroup>
              ) : (
                getFieldValue('periodGettingDocument') || '—'
              )}
            </div>
            {isTemplate && (
              <div className="table-data__control">
                <FormikToggleGroup name="canChangePeriodGettingDocument" label="">
                  <FormikToggle disabled={!editMode} name="canChangePeriodGettingDocument" size="xsmall" />
                </FormikToggleGroup>
              </div>
            )}
          </div>
          {getFieldValue('admissionPlanList')?.map((admissionPlan: AdmissionPlanData, index: number) => (
            <div
              key={`year_${admissionPlan.yearOfTrainingId}`}
              className={classNames(
                'table-data__group',
                true,
                (Number(admissionPlan.volume) > 0 || editMode) && 'table-data__item'
              )}
            >
              {(Number(admissionPlan.volume) > 0 || editMode) && (
                <div className="table-data__label table-data__label--main">
                  {`План приема на ${admissionPlan.yearOfTrainingName}`}
                </div>
              )}
              <div className="table-data__body">
                {editMode ? (
                  <div className="flex">
                    <div style={{ width: 90 }}>
                      <FormikFormGroup required label="" name={getFieldFullName(`admissionPlanList[${index}].volume`)}>
                        <FormikInput
                          name={getFieldFullName(`admissionPlanList[${index}].volume`)}
                          size="small"
                          placeholder="0"
                          number
                          disabled={!getFieldValue('canChangeAdmissionPlan')}
                        />
                      </FormikFormGroup>
                    </div>
                    <Push size={8} orientation="horizontal" />
                    <div className="table-row-item-height flex items-center color-gray-dark">человек</div>
                  </div>
                ) : (
                  Number(admissionPlan.volume) > 0 && `${admissionPlan.volume} человек`
                )}
              </div>
            </div>
          ))}
        </div>
      </Panel>
    </>
  );
};

export default TestsEdit;

export const testValidationSchema = (parent?: string) =>
  objectYup().shape({
    toursNumber: stringYup()
      .nullable()
      .test((value: string | null | undefined, ctx: any) => {
        if (ctx.parent?.isRequired) {
          if (!value) {
            return ctx.createError({ message: 'Необходимо указать количество туров ' });
          }
        }
        return true;
      }),
    periodGettingDocument: stringYup()
      .nullable()
      .required('Необходимо указать срок предоставления док-ов / выкупа брони, дней'),
  });
