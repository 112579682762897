import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetFromRoute } from '../../hooks/get-from-route';
import requestsApi from '../../lib/api/requests';
import { RequestData } from '../../types/requests';
import DonmRequest from './donm';
import DkgmRequest from './dkgm';
import RequestDayCare from './day-care';
import { EducationTypeEnum } from '../../types/education-type';
import DigitalTutorRequest from './digital-tutor';
import RequestProf from './preparation';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import { access, RequestContext } from '../../lib/utils/requests';

const Request = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const [request, setRequest] = useState<RequestData | undefined>(undefined);
  const [requestErrorMessage, setRequestErrorMessage] = useState<string>('');

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const fromRoute = useGetFromRoute();

  const getRequest = useCallback(async (): Promise<void> => {
    if (id) {
      const response = await requestsApi.getRequest(id);
      setRequest(response);
    }
  }, [id]);

  useEffect(() => {
    getRequest();
  }, [id, getRequest]);

  const currentRequest = (typeId: number | undefined) => {
    if (request) {
      switch (typeId) {
        case EducationTypeEnum.ChildrenEducation:
        case EducationTypeEnum.ChildrenNonDogmEducation:
          return <DonmRequest requestData={request} updateRequest={getRequest} />;
        case EducationTypeEnum.SportEducation:
        case EducationTypeEnum.ArtHouseEducation:
          return <DkgmRequest requestData={request} updateRequest={getRequest} />;
        case EducationTypeEnum.DayCareCentersEducation:
          return (
            <RequestDayCare
              requestData={request}
              updateRequest={getRequest}
              setRequestErrorMessage={setRequestErrorMessage}
              requestErrorMessage={requestErrorMessage}
            />
          );
        case EducationTypeEnum.VirtualAssistantEducation:
          return <DigitalTutorRequest requestData={request} updateRequest={getRequest} />;
        case EducationTypeEnum.ProfessionalEducation:
          return <RequestProf requestData={request} updateRequest={getRequest} />;
        default:
          return null;
      }
    }
  };

  return (
    <RequestContext.Provider value={access(userProfile, fromRoute)}>
      {currentRequest(request?.educationTypeId)}
    </RequestContext.Provider>
  );
};

export default Request;
