import React from 'react';
import { Panel, Push, SimpleTable as Table } from '@mosru/esz_uikit';
import { ContractRequestData } from '../../../types/requests';
import { formatDate } from '../../../lib/utils/date';

type Props = {
  data: ContractRequestData | undefined;
};

const Contract: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Push size={12} />
      <Panel title={() => 'Информация о договоре'}>
        {data ? (
          <Table
            data={[data]}
            pageSize={20}
            columns={[
              {
                dataIndex: 'number',
                title: '№ договора',
                render: (item: any) =>
                  item.contractNumber && (
                    <a href={item.contractUrl} target="_blank" rel="noreferrer" className="brand-link">
                      №{item.contractNumber}
                    </a>
                  ),
                hiddenSort: true,
              },
              {
                dataIndex: 'status',
                title: 'Статус',
                render: (item: any) => item.contractStatusName,
                hiddenSort: true,
              },
              {
                dataIndex: 'date',
                title: 'Дата',
                render: (item: any) => formatDate(item.contractStatusDate),
                hiddenSort: true,
              },
            ]}
          />
        ) : (
          <div className="requests-no-data">Нет данных</div>
        )}
      </Panel>
    </>
  );
};

export default Contract;
