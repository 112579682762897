import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select, SelectOptionType, Panel, Loader, Push, AsyncTable as Table } from '@mosru/esz_uikit';
import { historyState } from '../../../mock-data/history-state';
import { pageSizeOptions } from '../../../mock-data';
import requestsApi from '../../../lib/api/requests';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../types/requests';
import {
  checkDate,
  getColumns,
  removeEmptyDataForRequest,
  transformDataRequestsList,
} from '../../../lib/utils/requests';
import { numberWithCommas } from '../../../lib/utils/learners';
import { ReactComponent as IconExcel } from '../../../assets/images/teachers/excel.svg';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { clearTypeaheadNames, hasGeneralAccess, replaceHistoryState, showExcelErrorData } from '../../../lib/utils';
import { generalAccess } from '../../../types/authorization-data';
import { maxRegistryRows } from '../../../config/constants';

type Props = {
  search: SearchRequestsInitialFormData | undefined;
  tableOptions: SearchRequestsTableOptions;
};

const RequestsTable: React.FC<Props> = ({ search, tableOptions }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableTotal, setTableTotal] = useState<number>(0);
  const [tableActual, setTableActual] = useState<boolean>(tableOptions.isActualIndex);
  const [pageSize, setPageSize] = useState<number>(
    window.history.state[historyState.pageSize] || pageSizeOptions[0].value
  );
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<SearchRequestsInitialFormData | undefined>(search);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  // показывать кнопку экспорта
  const accessExport = useMemo(() => {
    return hasGeneralAccess(userProfile, generalAccess.UnloadInExcel) || isAdmin;
  }, [isAdmin, userProfile]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      if (tableOptions.loadEnable && currentSearch) {
        const defaultData = {
          pageSize,
          pageNumber: pageNum + 1,
        };
        const requests = await requestsApi.postRequests({
          ...checkDate(removeEmptyDataForRequest(clearTypeaheadNames(currentSearch))),
          ...defaultData,
        });

        setTableData(transformDataRequestsList(requests.items, defaultData.pageSize, defaultData.pageNumber));
        setTableTotal(requests.total);
        setTableActual(currentSearch.isActualRequestIndex ?? false);
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
    }
  }, [currentSearch, tableOptions.loadEnable, pageSize, pageNum]);

  useLayoutEffect(() => {
    if (search) {
      setCurrentSearch(search);
      if (initPageNum) {
        setInitPageNum(0);
      } else {
        setPageNum(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData, tableOptions.loadEnable]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const handleExportFile = async () => {
    setLoadingFile(true);
    try {
      await requestsApi.getRequestsExcel({
        ...currentSearch,
        pageSize,
        pageNumber: pageNum + 1,
      });
    } catch (e) {
      showExcelErrorData();
    } finally {
      setLoadingFile(false);
    }
  };

  return (
    <Panel
      title={() => (
        <>
          Реестр заявлений {tableActual ? 'за последний год' : 'за всё время'}:
          <span className="color-gray-dark">
            {' \u00A0'}
            {numberWithCommas(tableTotal)}
          </span>
        </>
      )}
      headingControl={() => (
        <>
          {accessExport && (
            <Button
              border
              black
              colorIcon
              size="small"
              label="Экспорт"
              load={loadingFile}
              handleClick={handleExportFile}
              iconRight={() => <IconExcel />}
            />
          )}
          <Push orientation="horizontal" size={12} />
          <div style={{ width: 184 }}>
            <Select
              name="pageSize"
              size="small"
              hideClearIndicator
              isSearchable={false}
              value={pageSizeOptions.find((item) => item.value === pageSize)}
              onChange={(selectedOption) => {
                const { value } = selectedOption as SelectOptionType;
                setPageSize(value as number);
                setPageNum(0);
              }}
              options={pageSizeOptions}
            />
          </div>
        </>
      )}
    >
      {loading ? (
        <div className="loader-container">
          <Loader title="Загружаем реестр заявлений" description="Нужно немного подождать" />
        </div>
      ) : tableTotal ? (
        <>
          <Push size={20} />
          <Table
            hideSort
            scrollUp
            rowLimit={maxRegistryRows}
            fixedCols={1}
            data={tableData}
            pageNum={pageNum}
            fixedRightCols={1}
            pageSize={pageSize}
            itemsAll={tableTotal}
            setPageNumber={setPageNum}
            columns={getColumns(
              tableOptions.isArtHouseSportEducation,
              tableOptions.allowedEducationTypeId,
              userProfile
            )}
          />
        </>
      ) : (
        <div className="requests-no-data">Нет данных</div>
      )}
    </Panel>
  );
};

export default RequestsTable;
