import React from 'react';
import { Heading, Infobox, Push } from '@mosru/esz_uikit';
import ChangePasswordForm from './forms/change-password';

const ChangePassword = () => {
  return (
    <>
      <Infobox text="При первом входе в систему вам необходимо придумать новый пароль" color="primary" />
      <Push size={24} />
      <Heading label="Создание пароля" as="h3" />
      <Push size={16} />
      <ChangePasswordForm />
    </>
  );
};

export default ChangePassword;
