import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Panel, Push } from '@mosru/esz_uikit';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-color.svg';
import EditSchedule, { ScheduleType } from '../../../components/schedule';
import { Organization } from '../../../types/organization';
import organizationApi from '../../../lib/api/organization';
import { getStringFromTimeTable, getTimeTableFromString } from '../../../lib/utils/time-table';
import { hasAccessObjectAny } from '../../../lib/utils';
import { accessAction, accessObject } from '../../../mock-data/access-enum';
import { AppState } from '../../../redux/types/state';
import { userProfileSelector } from '../../../redux/selectors';

type OrganizationFormTimeProps = {
  setEditModeParent: (edit: boolean) => void;
  organization: Organization;
};

const OrganizationFormSchedule: React.FC<OrganizationFormTimeProps> = ({ organization, setEditModeParent }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [scheduleData, setScheduleData] = useState(getTimeTableFromString(organization.timetable));

  const submitForm = useCallback(
    (schedule: ScheduleType[]) => {
      organizationApi
        .updateOrganization({ ...organization, timetable: getStringFromTimeTable(schedule) })
        .then((orgId) => {
          organizationApi.getOrganization(orgId ?? undefined).then((r) => {
            setScheduleData(getTimeTableFromString(r.timetable));
            setEditMode(false);
            setEditModeParent(false);
            setLoading(false);
          });
        });
      setLoading(true);
    },
    [setEditModeParent, organization]
  );

  return (
    <>
      <Push size={12} />
      {editMode ? (
        <EditSchedule
          title="Время работы организации"
          submit={scheduleSubmit}
          submitError={() => setScheduleSubmit(false)}
          submitSuccess={(schedule) => {
            if (scheduleSubmit) {
              submitForm(schedule);
            }
            setScheduleSubmit(false);
          }}
          scheduleData={scheduleData}
          timeRoundingUp
          hours24
        />
      ) : (
        <Panel
          title={() => 'Время работы организации'}
          headingControl={() =>
            !organization.isArchive &&
            hasAccessObjectAny(userProfile, [accessObject.EducationalOrganization], accessAction.Edit) && (
              <button
                type="button"
                onClick={() => {
                  setEditMode(true);
                  setEditModeParent(true);
                }}
                className="icon-group"
              >
                <span className="icon-group__icon">
                  <IconEdit />
                </span>
                <span className="icon-group__text font-weight-bold color-primary">Редактировать</span>
              </button>
            )
          }
        >
          <div className="table-data-striped">
            {scheduleData
              .filter((schedule) => !schedule.dayOff)
              .map((item) => (
                <div key={item.name} className="table-data-striped__item">
                  <div className="table-data-striped__label">{item.name}</div>
                  <div className="table-data-striped__body">
                    {item.dayOff ? <span className="color-gray-dark">не рабочий</span> : `${item.from} — ${item.to}`}
                  </div>
                </div>
              ))}
          </div>
          <Push size={8} />
        </Panel>
      )}

      {editMode && (
        <div className="room-save-container">
          <div className="room-panel-save">
            <div className="container">
              <div className="room-panel-save__inner">
                <Button
                  onClick={() => {
                    setEditMode(false);
                    setEditModeParent(false);
                  }}
                  border
                  label="Отмена"
                  primary
                />
                <Push size={12} orientation="horizontal" />
                <Button onClick={() => setScheduleSubmit(true)} load={loading} primary label="Сохранить" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizationFormSchedule;
