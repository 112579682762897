import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Loader, Push } from '@mosru/esz_uikit';
import learnerApi from '../../../../lib/api/learner';
import AddLearnerDocumentModal from './switcher';
import { DocumentDataFinishedAndTypes, Learner } from '../../../../types/learners';
import { DocumentTypeEnum } from '../../../../mock-data/type-document';
import { ReactComponent as IconClose } from '../../../../assets/images/icons/close.svg';
import { hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { accessAction, accessObject, generalAccess } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { initialStateFormMoscowDocument } from './form-moskow/utils';
import { DocumentModalMoscowContext } from '../../../../types/document-modal-moscow';
import { initialStateFormOtherDocument } from './form-other/utils';
import { DocumentModalOtherCity } from '../../../../types/document-modal-other';

type Props = {
  title: string;
  docId: string;
  show: boolean;
  pupilId: string;
  newDocument: boolean;
  moscowDocument: boolean;
  onCloseHandler: () => void;
};

export const DocumentModalContext = createContext<DocumentModalMoscowContext>({} as DocumentModalMoscowContext);

const certificate = 7;

const DocumentModal = ({ pupilId, docId, show, onCloseHandler, title, moscowDocument, newDocument }: Props) => {
  const childRef = React.useRef<any>(null);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [loadingReAccept, setLoadingReAccept] = useState<boolean>(false);

  const contextValues = useMemo(
    () => ({
      pupilId,
      setLoadingReAccept,
      setLoadingRequest,
      adminView: hasGeneralAccess(userProfile, generalAccess.AdminView),
      adminEdit: hasGeneralAccess(userProfile, generalAccess.AdminEdit),
      admin:
        hasGeneralAccess(userProfile, generalAccess.AdminView) &&
        hasGeneralAccess(userProfile, generalAccess.AdminEdit),
    }),
    [pupilId, setLoadingReAccept, userProfile]
  );

  const hasCreateDocument = useMemo(
    () => hasAccessObjectAny(userProfile, [accessObject.CompletionDocument], accessAction.Create),
    [userProfile]
  );

  const { adminView, adminEdit } = contextValues;

  const accessCheck = !adminView || adminEdit;

  const [documentDataMoscow, setDocumentDataMoscow] = useState<Learner.Document>(initialStateFormMoscowDocument);
  const [documentDataOther, setDocumentDataOther] =
    useState<DocumentModalOtherCity.InitialDataDocumentOtherCity>(initialStateFormOtherDocument);

  const [documentDataFinishedAndTypes, setDocumentDataFinishedAndTypes] = useState<DocumentDataFinishedAndTypes>({
    type: [],
    docFinished: [],
  });

  const canRecalculate = documentDataMoscow.documentTypeId === certificate && !(adminEdit || adminView);

  const accessDisabled = useMemo(() => {
    return adminEdit || hasAccessObjectAny(userProfile, [accessObject.CompletionDocument], accessAction.Create);
  }, [adminEdit, userProfile]);

  const [isMoscow, setIsMoscow] = useState<boolean>(true);
  const [newDocumentLocal, setNewDocumentLocal] = useState<boolean>(false);

  const [validateFormMoscow, setValidateFormMoscow] = useState<boolean>(false);
  const [validateFormOtherCity, setValidateFormOtherCity] = useState<boolean>(false);

  const loading =
    moscowDocument === isMoscow ? !newDocument && !documentDataMoscow?.id && !documentDataOther?.id : false;

  useEffect(() => {
    setNewDocumentLocal(newDocument);
  }, [newDocument]);

  useEffect(() => {
    setIsMoscow(moscowDocument);
  }, [moscowDocument]);

  // сбрасываем state чтобы можно было переключать вкладки
  useEffect(() => {
    if (!moscowDocument) {
      setNewDocumentLocal(true);
      setDocumentDataOther(initialStateFormOtherDocument);
    } else {
      setNewDocumentLocal(true);
      setDocumentDataMoscow(initialStateFormMoscowDocument);
    }
  }, [isMoscow, moscowDocument]);

  useEffect(() => {
    const fetch = async () => {
      const dataDocumentTypes = await learnerApi.getDocumentTypes();
      const dataFinishedDocument = await learnerApi.getDocumentFinishedServices({ pupilId });

      setDocumentDataFinishedAndTypes({ type: dataDocumentTypes.items, docFinished: dataFinishedDocument });

      if (newDocument) {
        setDocumentDataMoscow(initialStateFormMoscowDocument);
        setDocumentDataOther(initialStateFormOtherDocument);
        return;
      }

      if (isMoscow) {
        const data = await learnerApi.getDocument({ pupilId, docId });
        setDocumentDataMoscow(data);
        setNewDocumentLocal(newDocument);
      } else {
        const dataOther = await learnerApi.getDocumentOtherCity(pupilId, { id: docId });
        setDocumentDataOther(dataOther);
        setNewDocumentLocal(newDocument);
      }
    };

    if (isMoscow === moscowDocument) {
      fetch();
    }
  }, [docId, isMoscow, moscowDocument, newDocument, pupilId]);

  const submitForm = () => {
    if (childRef.current !== null) {
      if (isMoscow) {
        return childRef.current.handleSubmit();
      } else {
        return childRef.current.handleSubmitOther();
      }
    }
  };

  const reAccept = () => {
    if (childRef.current !== null) {
      return childRef.current.reAcceptHandler();
    }
  };

  const closePopup = () => {
    onCloseHandler();
    if (childRef.current !== null && !isMoscow) {
      childRef.current.deleteFiles();
    }
    setLoadingReAccept(false);
    setLoadingRequest(false);
    if (newDocument) {
      setIsMoscow(true);
    }
    setDocumentDataMoscow(initialStateFormMoscowDocument);
    setDocumentDataOther(initialStateFormOtherDocument);
  };

  const visibleButton = useMemo(() => {
    return (isMoscow ? !validateFormMoscow : !validateFormOtherCity) || !accessCheck;
  }, [accessCheck, isMoscow, validateFormMoscow, validateFormOtherCity]);

  return (
    <DocumentModalContext.Provider value={contextValues}>
      <Modal show={show} onClose={closePopup}>
        <div className="modal-right">
          <div className="learners-modal-right">
            <div className="learners-modal-right__head">
              <div className="flex">
                <div className="font-weight-bold">{title}</div>
                <button type="button" onClick={closePopup} className="learners-modal-right__head-close">
                  <IconClose />
                </button>
              </div>
              <Push size={4} />
            </div>

            <div className="learners-modal-right__body">
              {loading ? (
                <div className="learners-modal-right__loader">
                  <Loader small roller />
                </div>
              ) : (
                <div className="learners-modal-right__body-inner">
                  <AddLearnerDocumentModal
                    ref={childRef}
                    pupilId={pupilId}
                    isMoscow={isMoscow}
                    setIsMoscow={setIsMoscow}
                    newDocument={newDocumentLocal}
                    onCloseHandler={onCloseHandler}
                    accessDisabled={accessDisabled}
                    dataDocument={documentDataMoscow}
                    documentDataOther={documentDataOther}
                    setValidateFormMoscow={setValidateFormMoscow}
                    setValidateFormOtherCity={setValidateFormOtherCity}
                    documentDataFinishedAndTypes={documentDataFinishedAndTypes}
                  />
                </div>
              )}
            </div>

            {!loading ? (
              <div className="learners-modal-right__controls">
                <Button primary border label="Отмена" size="small" handleClick={closePopup} />
                {!newDocument && isMoscow && documentDataMoscow?.documentTypeId === DocumentTypeEnum.Certificate ? (
                  <>
                    <Push orientation="horizontal" size={12} />
                    <Button
                      primary
                      border
                      size="small"
                      label="Перезачесть"
                      load={loadingReAccept}
                      handleClick={reAccept}
                      disabled={!accessCheck || !hasCreateDocument}
                    />
                  </>
                ) : null}
                <Push orientation="horizontal" size={12} />
                <Button
                  submit
                  primary
                  size="small"
                  label="Сохранить"
                  load={loadingRequest}
                  handleClick={submitForm}
                  disabled={canRecalculate && !newDocument ? canRecalculate : visibleButton || !accessDisabled}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </DocumentModalContext.Provider>
  );
};

export default DocumentModal;
