import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import {
  SearchGroupTableItem,
  SearchGroupTrainingInitialFormData,
  ServiceClassData,
  ServiceClassPupilsListParams,
  ServiceClassHistoryData,
  ListPage,
  ServiceClassPupil,
  ServiceClassTeacherListParams,
  ServiceClassScheduleListParams,
  ServiceClassRecover,
  ServiceClassRejectionPopup,
  ServiceTransferModal,
  ServiceClassTransferStage,
  ServiceClassTransfer,
  NextStage,
  ServiceClassTransferNextStage,
  ServiceModal,
  ServiceClassSubClass,
  UpdateSubClassList,
  TeacherType,
} from '../../types/service-class';
import { Response } from '../../types/entities';
import { downloadBase64File } from '../utils';
import { ExportFile } from '../../types';
import { EducationTypeEnum } from '../../types/education-type';
import { sendReachGoal } from '../metrica';

// максимальное кол-во групп обучения отображаемое в заявлении в разделе Действия
export const MAX_LIST_ITEM_COUNT = 1000;

export const postServiceClass = async (
  data: SearchGroupTrainingInitialFormData
): Promise<{
  total: number;
  items: SearchGroupTableItem[];
}> => {
  sendReachGoal('service-class-search');

  return fetchRequest.post(`${apiService.data}/ServiceClass/Search`, data);
};

export const createServiceClass = async (data: ServiceClassData): Promise<number> => {
  return fetchRequest.post(`${apiService.data}/ServiceClass`, data);
};

export const getServiceClassExcel = async (data: any): Promise<Response<ExportFile>> => {
  const resp = await fetchRequest.post(`${apiService.data}/ServiceClass/ToExcel`, data, undefined, 600000);

  downloadBase64File(resp.content, resp.data, resp.name);
  return resp;
};

export const getServiceClassList = async (
  serviceId?: number,
  trainingGroupId?: number,
  organizationId?: number,
  educationType?: EducationTypeEnum,
  serviceClassId?: number,
  listItemCount?: number
) =>
  await fetchRequest.get(
    `${apiService.data}/ServiceClass/List?IsActual=true${serviceId ? `&ServiceId=${serviceId}` : ''}${
      trainingGroupId ? `&TrainingGroupId=${trainingGroupId}` : ''
    }${serviceClassId ? `&ServiceClassId=${serviceClassId}` : ''}${
      organizationId ? `&OrganizationId=${organizationId}` : ''
    }&Opened=${educationType === EducationTypeEnum.DayCareCentersEducation}${
      educationType ? `&EducationTypeId=${educationType}` : ''
    }${listItemCount ? `&PageSize=${listItemCount}` : ''}
    `
  );

export const getServiceClass = async (id: string): Promise<ServiceClassData> => {
  return fetchRequest.get(`${apiService.data}/ServiceClass/${id}`);
};

export const getServiceClassHistory = async (
  serviceClassId: number,
  statusId: number,
  pageNumber = 1,
  pageSize = 10
): Promise<ListPage<ServiceClassHistoryData>> =>
  fetchRequest.get(`${apiService.data}/ServiceClass/History/${serviceClassId}`, { statusId, pageNumber, pageSize });

export const getServiceClassLearnerList = async (
  searchParams: ServiceClassPupilsListParams
): Promise<ListPage<ServiceClassPupil>> =>
  await fetchRequest.post(`${apiService.data}/ServiceClass/${searchParams.serviceClassId}/Learner/List`, searchParams);

export const updateServiceClassTeacherList = async (searchParams: ServiceClassTeacherListParams): Promise<any> =>
  await fetchRequest.put(`${apiService.data}/ServiceClass/${searchParams.serviceClassId}/Teacher/List`, searchParams);

export const updateServiceClassScheduleList = async (searchParams: ServiceClassScheduleListParams): Promise<any> =>
  await fetchRequest.put(`${apiService.data}/ServiceClass/${searchParams.serviceClassId}/Schedule/List`, searchParams);

export const updateServiceClass = async (data: ServiceClassData): Promise<any> => {
  return fetchRequest.put(`${apiService.data}/ServiceClass/${data.id}`, data);
};

export const addServiceClassToArchive = async (serviceId: string): Promise<any> => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceId}/Status`, { id: serviceId });
};

export const deleteServiceClass = async (serviceId: string): Promise<any> => {
  return fetchRequest.delete(`${apiService.data}/ServiceClass/${serviceId}`, { id: serviceId });
};

export const restorationServiceClass = async (serviceClassId: number, data: ServiceClassRecover): Promise<number> => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/Restoration`, data);
};

export const periodServiceClass = async (serviceClassId: number, data: ServiceClassRecover) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/LearnPeriod`, data);
};

export const rejectionServiceClass = async (serviceClassId: number, data: ServiceClassRejectionPopup) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/Rejection`, data);
};

export const serviceTransfer = async (serviceClassId: number, data: ServiceTransferModal) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/Service`, data);
};

export const transferClass = async (serviceClassId: number, data: ServiceClassTransfer) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/Class`, data);
};

export const checkScheduleIntersection = async (serviceClassId: number, contingentGuid: string) =>
  fetchRequest.get(
    `${apiService.data}/ServiceClass/${serviceClassId}/ScheduleIntersection/?contingentGuid=${contingentGuid}`
  );

export const nextStageSearch = async (
  serviceClassId: number,
  peopleIds: number[],
  educationType: EducationTypeEnum
): Promise<NextStage[]> => {
  return fetchRequest.post(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/NextStageSearch`, {
    megaRelationIds: peopleIds,
    serviceClassId,
    educationTypeId: educationType,
  });
};

export const transferNextStage = async (serviceClassId: number, data: ServiceClassTransferNextStage) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/NextStage`, data);
};

export const transferStage = async (serviceClassId: number, data: ServiceClassTransferStage) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Learner/Stage`, data);
};

export const postSubClassList = async (serviceClassId: number, data: ServiceModal): Promise<ServiceClassSubClass[]> => {
  return fetchRequest.post(`${apiService.data}/ServiceClass/${serviceClassId}/Subclass/List`, data);
};

export const updateSubClassList = async (serviceClassId: number, data: UpdateSubClassList) => {
  return fetchRequest.patch(`${apiService.data}/ServiceClass/${serviceClassId}/Subclass/List`, data);
};

export const getTeacherByProgrammModule = async (moduleId: string | number): Promise<TeacherType> => {
  return fetchRequest.get(`${apiService.data}/ServiceClass/ProgrammModule/${moduleId}`, moduleId);
};

const serviceClassApi = {
  postServiceClass,
  getServiceClassList,
  getServiceClassExcel,
  getServiceClass,
  getServiceClassHistory,
  getServiceClassLearnerList,
  updateServiceClassTeacherList,
  updateServiceClassScheduleList,
  updateServiceClass,
  createServiceClass,
  addServiceClassToArchive,
  restorationServiceClass,
  periodServiceClass,
  rejectionServiceClass,
  serviceTransfer,
  transferClass,
  nextStageSearch,
  transferNextStage,
  transferStage,
  postSubClassList,
  updateSubClassList,
  deleteServiceClass,
  getTeacherByProgrammModule,
  checkScheduleIntersection,
};

export default serviceClassApi;
