import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, IconArrowDown, Push } from '@mosru/esz_uikit';
import PageHeading from '../../../components/header/page-heading';
import history from '../../../history';
import useClearHistoryState from '../../../hooks/use-clear-history-state';
import { EventUrl } from '../../../mock-data/event';
import { requestHistoryState } from '../utils';
import RequestsSearch from './search';
import RequestsTable from './table';
import { SearchRequestsInitialFormData, SearchRequestsTableOptions } from '../../../types/requests';
import DropDown from '../../../components/drop-down';
import { userProfileSelector } from '../../../redux/selectors';
import { AppState } from '../../../redux/types/state';
import { accessAction, accessObject, generalAccess } from '../../../mock-data/access-enum';
import { hasAccessObjectAny, hasGeneralAccess, redirect, replaceHistoryState } from '../../../lib/utils';
import { routes } from '../../../config/constants';
import SearchKey from './contexts/key-context';
import useQuery from '../../../hooks/use-query';

export const searchRequestsInitialFormData: SearchRequestsInitialFormData = {
  isActualRequestIndex: true,
  isOverdueToday: false,
  isOverdueTomorrow: false,
  contingentLinkTypeId: null,
  query: '',
};

const Requests = () => {
  const [search, setSearch] = useState<SearchRequestsInitialFormData>();
  const [requestOptions, setRequestOptions] = useState<{ link: string; label: string; value: number }[]>([]);
  const [tableOptions, setTableOptions] = useState<SearchRequestsTableOptions>({
    loadEnable: false,
    isActualIndex: searchRequestsInitialFormData.isActualRequestIndex ?? false,
    isArtHouseSportEducation: false,
  });

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const eventId = Number(useQuery().get(EventUrl.EventId));

  useClearHistoryState();

  const isAdmin =
    hasGeneralAccess(userProfile, generalAccess.AdminView) || hasGeneralAccess(userProfile, generalAccess.AdminEdit);

  const showNewStatement =
    (hasAccessObjectAny(
      userProfile,
      [accessObject.RequestDOGM, accessObject.RequestNonDOGM, accessObject.RequestDayCare, accessObject.RequestOP],
      accessAction.Create
    ) &&
      !isAdmin) ||
    isAdmin;

  const handleSubmitSearch = useCallback(
    (values: SearchRequestsInitialFormData) => {
      setSearch((prevState) => {
        if (prevState && history.location.search) {
          const { state } = window.history;

          if (!eventId) {
            history.replace({
              pathname: history.location.pathname,
              search: undefined,
            });
          }
          replaceHistoryState({ ...state, [requestHistoryState.search]: values });
        } else {
          replaceHistoryState({ [requestHistoryState.search]: values });
        }
        return values;
      });
    },
    [eventId]
  );

  return (
    <>
      <PageHeading
        title="Заявления"
        sections={[{ title: 'Главная', link: routes.main }, { title: 'Заявления' }]}
        controlsTopRight={
          requestOptions?.length > 0 &&
          showNewStatement &&
          !hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV) && (
            <>
              <DropDown
                component={() => (
                  <Button primary label="Новое заявление" iconRight={() => <IconArrowDown />} size="small" />
                )}
              >
                <div className="drop-down-panel">
                  <div className="drop-down-panel__list">
                    {requestOptions.map(({ link, label }) => {
                      return (
                        <span key={Math.random()}>
                          <button type="button" onClick={() => redirect(link)} className="drop-down-panel__list-item">
                            {label}
                          </button>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </DropDown>
              <Push orientation="horizontal" size={12} />
            </>
          )
        }
      />

      <Push size={12} />
      <SearchKey>
        <RequestsSearch
          submitForm={handleSubmitSearch}
          setTableOptions={setTableOptions}
          setRequestOptions={setRequestOptions}
          initialSearchFilters={search}
        />
      </SearchKey>
      <Push size={12} />
      <RequestsTable search={search} tableOptions={tableOptions} />
    </>
  );
};

export default Requests;
