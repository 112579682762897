import { createContext } from 'react';
import serviceClassApi from '../../lib/api/service-class';
import { ServiceClassData, ServiceClassTableContextType } from '../../types/service-class';
import { hasAccessObjectAny } from '../../lib/utils';
import { AuthorizationData } from '../../types/authorization-data';
import { accessObject } from '../../mock-data/access-enum';
import { EducationTypeEnum } from '../../types/education-type';

export const getServiceClassData = async (id: string, setServiceClass: (value: ServiceClassData) => void) => {
  if (id) {
    try {
      const response = await serviceClassApi.getServiceClass(id);

      setServiceClass(response);
    } catch {}
  }
};

export const addArchive = async (id: string) => {
  if (id) {
    try {
      await serviceClassApi.addServiceClassToArchive(id);
    } catch {}
  }
};

export const deleteServiceClass = async (id: string, setIsRemovedGroup: (value: boolean) => void): Promise<void> => {
  if (id) {
    setIsRemovedGroup(true);
    try {
      await serviceClassApi.deleteServiceClass(id);
      setIsRemovedGroup(false);
    } catch {
      setIsRemovedGroup(false);
    }
  }
};

export const ServiceClassTableContext = createContext<ServiceClassTableContextType>({} as ServiceClassTableContextType);

export const accessObjectByEducationType: { [key: number]: number } = {
  [EducationTypeEnum.DayCareCentersEducation]: accessObject.ServiceClassDayCare,
  [EducationTypeEnum.ProfessionalEducation]: accessObject.ServiceClassOP,
  [EducationTypeEnum.VirtualAssistantEducation]: accessObject.ServiceClassVA,
  [EducationTypeEnum.ArtHouseEducation]: accessObject.ServiceClassArtHouse,
  [EducationTypeEnum.ChildrenEducation]: accessObject.ServiceClassDOGM,
  [EducationTypeEnum.ChildrenNonDogmEducation]: accessObject.ServiceClassNonDOGM,
  [EducationTypeEnum.SportEducation]: accessObject.ServiceClassSport,
};

export const accessByEducationType = (
  userProfile: AuthorizationData,
  educationType: number,
  accessAction: number
): boolean => {
  return hasAccessObjectAny(userProfile, [accessObjectByEducationType[educationType]], accessAction);
};

const defaultTooltipText =
  'Для выбора доступны детские объединения в статусе "Опубликовано на Mos.ru" с корректно заполненными планами приема. Вы также можете воспользоваться поиском по коду услуги. Код отображается справа от наименования.';

export const tooltipTextForService: { [key: number]: string } = {
  [EducationTypeEnum.DayCareCentersEducation]:
    'Для выбора доступны программы в статусе "Опубликовано на Mos.ru" с корректно заполненными планами приема',
  [EducationTypeEnum.VirtualAssistantEducation]: defaultTooltipText,
  [EducationTypeEnum.ArtHouseEducation]: defaultTooltipText,
  [EducationTypeEnum.ChildrenEducation]: defaultTooltipText,
  [EducationTypeEnum.SportEducation]: defaultTooltipText,
  [EducationTypeEnum.ChildrenNonDogmEducation]: defaultTooltipText,
  [EducationTypeEnum.ProfessionalEducation]:
    'Для выбора доступны образовательные программы в статусе "Опубликовано на Mos.ru" с корректно заполненными планами приема',
};
