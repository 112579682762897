import { useField, useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { Button, SelectOptionType, Tooltip, Push } from '@mosru/esz_uikit';
import FormikSelect from '../../../../components/formik/formik-select';
import lookupApi from '../../../../lib/api/lookup';
import { EducationTypeEnum } from '../../../../types/education-type';
import { FieldProps } from '../../../../components/fields/field';
import { routes, searchServiceRegistryName } from '../../../../config/constants';
import FormikFormGroup from '../../../../components/formik/formik-form-group';
import { ServiceClassContext } from '../../service-class';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { DependentServiceContext } from './dependent-service';
import { addressFieldId, addressFieldName } from './address';
import { trainingGroupFieldId, trainingGroupFieldName } from './training-group';
import { moduleFieldId, moduleFieldName } from './module';
import { dictionariesApi } from '../../../../lib/api/dictionaries';
import { parallelFieldName } from './parallel';
import { teachersField } from './teachers';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { tooltipTextForService } from '../../utils';
import { generateLink, getServiceEditLink } from '../../../../lib/utils';

type ServiceProps = FieldProps<string> & {
  educationType: EducationTypeEnum;
  showSearchServiceBtn?: boolean;
  disabled?: boolean;
  isNewServiceClass?: boolean;
};
export const serviceIdField = 'serviceId';

const ServiceField = ({
  label = 'Программа',
  name,
  editMode,
  required,
  defaultValue = '—',
  educationType,
  showSearchServiceBtn,
  disabled,
  isNewServiceClass,
}: ServiceProps) => {
  const { initialValues, setFieldValue } = useFormikContext<any>();

  const { serviceClassData } = useContext(ServiceClassContext);
  const { setAddressKey, setTrainingGroupKey, setModuleKey, setParallelKey } = useContext(DependentServiceContext);

  const fieldName = `fullServiceName`;
  const fieldIdName = `${name}Id`;

  const fetchOptions = useCallback(
    async (query: string) => {
      const res = await lookupApi.getService(query, educationType, undefined, undefined);

      const options = res.map((item: any) => {
        const properties = JSON.parse(item.additionalPropertiesJson);
        const labelGpd = `${item.label}, ${properties.Id || ''}`;
        const labelOther = `${item.label} ${properties.TypeFinancingName ? `,${properties.TypeFinancingName}` : ''} ${
          properties.PlaceAddress ? `,${properties.PlaceAddress}` : ''
        } ${isNewServiceClass ? `,${item.value || ''}` : ''}`;

        const label = `${
          EducationTypeEnum.DayCareCentersEducation === serviceClassData.educationTypeId ? labelGpd : labelOther
        }`;

        return {
          ...item,
          label,
        };
      });

      return options;
    },
    [educationType, serviceClassData.educationTypeId, isNewServiceClass]
  );

  const [fieldLabel, , helpersLabel] = useField<string>(fieldName);
  const [fieldId, , helpersId] = useField<number>(fieldIdName);

  const selectServiceItem = async (name: string, id: number) => {
    helpersId.setValue(id);
    const service = await serviceTemplateApi.getServiceName(id);
    helpersLabel.setValue(service);
  };

  useEffect(() => {
    if (initialValues.serviceId !== fieldId.value) {
      setFieldValue(addressFieldName, '');
      setFieldValue(addressFieldId, '');
      setAddressKey && setAddressKey(Math.random());

      setFieldValue(trainingGroupFieldId, '');
      setFieldValue(trainingGroupFieldName, '');
      setTrainingGroupKey && setTrainingGroupKey(Math.random());

      if (serviceClassData?.educationTypeId === EducationTypeEnum.ProfessionalEducation) {
        setFieldValue(moduleFieldId, '');
        setFieldValue(moduleFieldName, '');
        setModuleKey && setModuleKey(Math.random());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId.value]);

  // Обновление паралелли при изменении программы.
  useEffect(() => {
    if (fieldId.value) {
      const fetch = async () => {
        const res = await dictionariesApi.getParallelsByService(fieldId.value);

        const parallelList = res.map((item) => item.label).join(',');

        if (initialValues.serviceId !== fieldId.value) {
          if (serviceClassData?.educationTypeId === EducationTypeEnum.DayCareCentersEducation) {
            setFieldValue(parallelFieldName, parallelList);
            setParallelKey && setParallelKey(Math.random());
          }
        }
      };
      fetch();
    } else {
      setFieldValue(parallelFieldName, '');
      setParallelKey && setParallelKey(Math.random());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId.value]);

  // Обнуление списка преподавателей, при очистке программы.
  useEffect(() => {
    if (!fieldId.value && isNewServiceClass) {
      setFieldValue(teachersField, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldId.value]);

  useEffect(() => {
    const fetch = async () => {
      const service = await serviceTemplateApi.getServiceName(fieldId.value);
      helpersLabel.setValue(service);
    };
    if (fieldId.value && !fieldLabel.value) {
      fetch();
    }
  }, [fieldId.value, fieldLabel.value, helpersLabel]);

  const currentLabel =
    EducationTypeEnum.ProfessionalEducation === serviceClassData.educationTypeId ? (
      <>
        Образовательная <br />
        программа
      </>
    ) : (
      label
    );

  return (
    <div className="table-data__item table-data__group">
      <div className="table-data__label table-data__label--main flex flex-direction-row">
        <div>
          <span> {currentLabel}</span> <span>{editMode && required && <span className="table-data__required" />} </span>
        </div>

        {editMode && (
          <div style={{ marginLeft: '10px' }}>
            <Tooltip
              component={() => <IconInfo />}
              position="bottom"
              text={
                <div style={{ textAlign: 'center', width: '200px', fontSize: '12px' }}>
                  {tooltipTextForService[serviceClassData.educationTypeId]}
                </div>
              }
            />
          </div>
        )}
      </div>

      <div className="table-data__body">
        {editMode ? (
          <div className="flex">
            <div className="flex-auto">
              <FormikFormGroup key={fieldId.value} name={fieldIdName} label="" required>
                <FormikSelect
                  name={fieldIdName}
                  size="small"
                  isSearchable
                  options={[]}
                  loadOptions={fetchOptions}
                  selectedValue={(selected: SelectOptionType) => {
                    helpersId.setValue(selected ? (selected.value as number) : 0);
                    helpersLabel.setValue(selected ? selected.label : '');
                  }}
                  defaultValue={fieldId.value ? { value: fieldId.value, label: fieldLabel.value } : null}
                  placeholder={showSearchServiceBtn ? 'Начните вводить или выберите...' : 'Начните вводить...'}
                  disabled={disabled}
                />
              </FormikFormGroup>
            </div>

            {showSearchServiceBtn && (
              <>
                <Push size={8} orientation="horizontal" />
                <div className="flex-none">
                  <Button
                    primary
                    size="small"
                    label="Подобрать объединение"
                    onClick={() => {
                      const myWnd: (Window & typeof globalThis) | any = window;
                      myWnd.selectServiceItem = selectServiceItem;
                      const searchWnd = myWnd.open(
                        `${generateLink(
                          routes.registerChildEducations,
                          {}
                        )}?IsNewSearch=true&IsShowSelectButtons=true&StatusId=2`,
                        searchServiceRegistryName,
                        'width=1080,height=800,status=no,toolbar=no,menubar=no,location=no,directories=no,resizable=yes,scrollbars=yes'
                      );
                      searchWnd?.focus();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            className="brand-link cursor-pointer"
            onClick={() => window.open(getServiceEditLink(educationType, serviceClassData.serviceId), '_blank')}
          >
            {fieldLabel.value || defaultValue}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceField;
