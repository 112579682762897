import { FieldsModel } from '../../../types/entities';

export const fieldsModel: FieldsModel = {
  allEducations: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование детского объединения или услуги',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Адрес предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приема',
      placeholder: 'Сначала выберите детское объединение',
      disabled: true,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  childrenEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 1,
        name: 'Детские объединения департамента образования',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование детского объединения',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Адрес предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приема',
      placeholder: 'Сначала выберите детское объединение',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  professionalEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 2,
        name: 'Профподготовка',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Профессия',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Квалификация',
      placeholder: 'Сначала выберите профессию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование образовательной программы',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Адрес предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приема',
      placeholder: 'Сначала выберите образовательную программу',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  dayCareCentersEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 8,
        name: 'Группы по уходу и присмотру за детьми школьного возраста',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование программы по присмотру и уходу за детьми школьного возраста',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Адрес предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приема',
      placeholder: 'Сначала выберите программу',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  sportEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 16,
        name: 'Спортивные секции',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование услуги',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Место предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приёма, вступительные испытания',
      placeholder: 'Сначала выберите услугу',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  artHouseEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 32,
        name: 'Дома творчества',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование услуги',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Место предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приёма, вступительные испытания',
      placeholder: 'Сначала выберите услугу',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  childrenNonDogmEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 64,
        name: 'Детские объединения',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование детского объединения',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Адрес предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приема',
      placeholder: 'Сначала выберите детское объединение',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
  virtualAssistantEducation: {
    educationTypeId: {
      label: 'Тип услуги',
      placeholder: '',
      disabled: false,
      visible: true,
      defaultValue: {
        id: 128,
        name: 'Цифровой репетитор',
      },
    },
    vedomstvoId: {
      label: 'Департамент',
      placeholder: 'Выберите',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    classificatorEKUId: {
      label: 'Вид деятельности',
      placeholder: 'Начните вводить или выберите...',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    programmLevelId: {
      label: 'Уровень программы',
      placeholder: 'Сначала выберите вид деятельности',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    organizationId: {
      label: 'Образовательная организация',
      placeholder: 'Сначала выберите департамент',
      disabled: false,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceId: {
      label: 'Наименование услуги',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    placeServiceId: {
      label: 'Адрес предоставления',
      placeholder: 'Сначала выберите образовательную организацию',
      disabled: true,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    trainingGroupId: {
      label: 'План приема',
      placeholder: 'Сначала выберите услугу',
      disabled: true,
      visible: true,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    yearOfTrainingId: {
      label: 'Период обучения',
      placeholder: 'Выберите...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    serviceClassId: {
      label: 'Наименование группы',
      placeholder: 'Начните вводить...',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
    testDate: {
      label: 'Дата испытаний / явки',
      placeholder: '',
      disabled: false,
      visible: false,
      defaultValue: {
        id: null,
        name: null,
      },
    },
  },
};
