import React, { useState } from 'react';
import TestsList from './panels/test-list';
import { ServiceData, TrainingGroupData } from '../../../types/service';
import TrainingGroupBody from './panels/training-group-body';

type ServiceProps = {
  trainingGroup: TrainingGroupData | undefined;
  service: ServiceData | undefined;
  getTraininig: () => Promise<void>;
};

const PlanDetails = ({ trainingGroup, service, getTraininig }: ServiceProps) => {
  const [editMode, setEditMode] = useState<string | null>(null);

  return trainingGroup && service ? (
    <>
      {(!editMode || editMode === 'plan-case1') && (
        <TrainingGroupBody
          setEditModeParent={setEditMode}
          editable={trainingGroup.id === 0}
          planData={trainingGroup}
          getTraininig={getTraininig}
          service={service}
        />
      )}
      {service.isTestService && (!editMode || editMode !== 'plan-case1') && (
        <TestsList editable serviceId={service.id ?? 0} tg={trainingGroup} getTrainingGroup={getTraininig} />
      )}
    </>
  ) : null;
};

export default PlanDetails;
