import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOptionType } from '@mosru/esz_uikit';
import FormikFormGroup from '../../../../../components/formik/formik-form-group';
import FormikSelect from '../../../../../components/formik/formik-select';
import lookupApi from '../../../../../lib/api/lookup';
import { SearchInitialFormData } from '../../../../../types/service';
import { hasGeneralAccess } from '../../../../../lib/utils';
import { generalAccess } from '../../../../../mock-data/access-enum';
import { usePrevious } from '../../../../../hooks/use-previous';
import { ServiceRegistryContext } from '../../initial-data';

const FieldOrganization: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<SearchInitialFormData>();
  const [organizationIdKey, setOrganizationIdKey] = useState<number>(0);

  const { isAdmin, userProfile } = React.useContext(ServiceRegistryContext);

  const access = isAdmin || hasGeneralAccess(userProfile, generalAccess.VedomstvoOIV);

  const prevVedomstvoId = usePrevious(values.vedomstvoId);
  const prevOrganizationId = usePrevious(values.organizationId);

  useEffect(() => {
    // При изменении "Департамента" обнуляем значение "Организация"
    if (values.vedomstvoId !== prevVedomstvoId && prevOrganizationId) {
      setFieldValue('organizationId', null);
      setFieldValue('organizationName', null);
      setOrganizationIdKey(Math.random());
    }
  }, [prevVedomstvoId, setFieldValue, values.vedomstvoId, prevOrganizationId]);

  return (
    <FormikFormGroup name="organizationId" label="Организация">
      <FormikSelect
        key={organizationIdKey}
        name="organizationId"
        size="small"
        disabled={!access}
        defaultValue={
          values.organizationId ? { label: values.organizationName ?? '', value: values.organizationId ?? '' } : null
        }
        options={[]}
        isSearchable
        loadOptions={async (query) => await lookupApi.getOrganization(query, values.vedomstvoId)}
        placeholder="Начните вводить..."
        selectedValue={(v: SelectOptionType) => {
          if (v) {
            setFieldValue('organizationName', v.label);
          }
        }}
      />
    </FormikFormGroup>
  );
};

export default FieldOrganization;
