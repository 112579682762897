import { ClassificatorChildData, ClassificatorTreeData } from '../../../../types/classificator';

export const MAX_LIMIT_VOLUME = 999999;
export const VOLUME_FIELD_WIDTH = 102;

export const getClassificatorList = (data: ClassificatorTreeData[]): ClassificatorChildData[] => {
  const list = [];
  for (const item of data) {
    const newListItem = item.child.length ? getClassificatorList(item.child) : [item.data];
    list.push(...newListItem);
  }
  return list;
};
