import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip, SimpleTable as Table } from '@mosru/esz_uikit';
import { routes } from '../../../../config/constants';
import history from '../../../../history';
import { generateLink, generateUrlSearchString, hasAccessObjectAny, hasGeneralAccess } from '../../../../lib/utils';
import { formatDate, formatTime } from '../../../../lib/utils/date';
import { ScheduleData, TrainingGroupData } from '../../../../types/service';
import DropDown from '../../../../components/drop-down';
import { ReactComponent as IconDots } from '../../../../assets/images/icons/3dots.svg';
import { ReactComponent as IconEdit } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as IconRemove } from '../../../../assets/images/icons/remove.svg';
import { ReactComponent as IconFile } from '../../../../assets/images/icons/file.svg';
import { ReactComponent as IconEye } from '../../../../assets/images/icons/eye.svg';
import { ReactComponent as IconInfo } from '../../../../assets/images/icons/info.svg';
import { RequestStatusEnum } from '../../../../mock-data/request-status-enum';
import { TrainingGroupStatusEnum } from '../../../../mock-data/training-group-status-enum';
import { ReactComponent as IconCheck } from '../../../../assets/images/icons/check-circle-black.svg';
import { ReactComponent as IconSign } from '../../../../assets/images/icons/edit.svg';
import { userProfileSelector } from '../../../../redux/selectors';
import { AppState } from '../../../../redux/types/state';
import { accessVedomst, generalAccess } from '../../../../mock-data/access-enum';
import { accessAction, accessObject } from '../../../../types/authorization-data';
import { ServiceContext } from '../..';
import { serviceTemplateApi } from '../../../../lib/api/service-template';
import { apply } from '../../../../lib/utils/service';
import SignModal from '../../../../components/sign-modal';
import { ServiceStatusEnum } from '../../../../mock-data/service-status-enum';

type Props = {
  list: TrainingGroupData[];
  getTGList: () => Promise<void>;
};

const TgList: React.FC<Props> = ({ list, getTGList }) => {
  const { serviceData } = useContext(ServiceContext);
  const [signTg, setSignTg] = useState<TrainingGroupData | undefined>();
  const handleEdit = (item: TrainingGroupData) => () => {
    const isRetro = item.scheduleList.length && item.scheduleList[0].isRetro ? 1 : 0;
    history.push(
      `${generateLink(routes.trainingGroup, {
        serviceId: item.serviceId ?? 0,
        id: item.id,
      })}?isRetro=${isRetro}&yearId=${item.yearOfTrainingId}`
    );
  };

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const canEdit = useCallback(
    (tg: TrainingGroupData) =>
      (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
        hasAccessObjectAny(
          userProfile,
          [accessObject.ServiceArtHouse, accessObject.ServiceSport],
          accessAction.Edit
        )) &&
      tg?.scheduleList[0]?.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive,
    [userProfile]
  );

  const canEditButton = useCallback(
    (tg: TrainingGroupData) => canEdit(tg) && serviceData.serviceStatusId === ServiceStatusEnum.Draft,
    [canEdit, serviceData.serviceStatusId]
  );

  const canCreateRequest = useCallback(
    (tg: TrainingGroupData): boolean => {
      return (
        (hasGeneralAccess(userProfile, generalAccess.AdminEdit) ||
          hasAccessObjectAny(
            userProfile,
            [accessObject.RequestArtHouse, accessObject.RequestSport],
            accessAction.Edit
          )) &&
        tg.scheduleList?.length > 0 &&
        tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Signed &&
        tg.scheduleList[0].isAccessible &&
        (userProfile.vedomstvoId !== accessVedomst.Dsit || new Date().getMonth() > 9)
      );
    },
    [userProfile]
  );

  const canRemoveButton = useCallback(
    (tg: TrainingGroupData): boolean =>
      canEditButton(tg) &&
      (!tg.scheduleList?.length || tg.scheduleList.every((s: ScheduleData) => s.canDeleteSchedule)),
    [canEditButton]
  );

  const canPublicate = useCallback(
    (tg: TrainingGroupData): boolean =>
      canEdit(tg) &&
      tg.scheduleList?.length > 0 &&
      tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      (!hasGeneralAccess(userProfile, generalAccess.UseSign) || tg.scheduleList[0].isRetro),
    [canEdit, userProfile]
  );

  const canSign = useCallback(
    (tg: TrainingGroupData): boolean =>
      canEdit(tg) &&
      tg.scheduleList?.length > 0 &&
      tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Draft &&
      !tg.scheduleList[0].isRetro &&
      hasGeneralAccess(userProfile, generalAccess.UseSign),
    [canEdit, userProfile]
  );

  const canOnlyView = useCallback(
    (tg: TrainingGroupData): boolean =>
      tg.scheduleList?.length > 0 && tg.scheduleList[0].trainingGroupStatusId === TrainingGroupStatusEnum.Archive,
    []
  );

  const showDropdownMenu = useCallback(
    (tg: TrainingGroupData) =>
      canEditButton(tg) ||
      canCreateRequest(tg) ||
      canRemoveButton(tg) ||
      canPublicate(tg) ||
      canSign(tg) ||
      canOnlyView(tg),
    [canEditButton, canCreateRequest, canRemoveButton, canPublicate, canSign, canOnlyView]
  );

  const updateStatus = async (tgId: number, status: TrainingGroupStatusEnum) => {
    await serviceTemplateApi.updateTrainingGroupStatus({
      serviceId: serviceData.id,
      educationTypeId: serviceData.educationTypeId,
      trainingGroupId: tgId,
      trainingGroupStatusId: status,
    });
    getTGList();
  };

  const getStatusIcon = (status: TrainingGroupStatusEnum) => {
    return status === TrainingGroupStatusEnum.Signed ? <IconCheck /> : <IconInfo />;
  };

  const getStatusText = (status: TrainingGroupStatusEnum) => {
    return (
      <div className="text-center">
        {status === TrainingGroupStatusEnum.Signed
          ? 'Подтверждено'
          : 'Для подачи заявлений необходимо, чтобы данная услуга была в статусе “Опубликовано на Mos.ru”, количество зачисленных заявлений не превышало количество мест и дата приема заявлений на  Mos.ru была не позже сегодняшней даты и текущего времени'}
      </div>
    );
  };

  const handleDelete = (id: number) => async () => {
    await serviceTemplateApi.deleteTrainingGroup(serviceData.id, id);
    getTGList();
  };

  return (
    <>
      <Table
        overflow
        data={list}
        hideSort
        columns={[
          {
            dataIndex: 'date',
            title: 'Дата начала занятий',
            render: (item: any) =>
              item.educationStartDate && <span className="brand-link">{formatDate(item.educationStartDate)}</span>,
            width: '16%',
          },
          {
            dataIndex: 'stage',
            title: 'Этап обучения',
            render: (item: any) => <div className="overflow-wrap-anywhere">{item.serviceStageName}</div>,
            width: '16%',
          },
          {
            dataIndex: 'amount',
            title: 'План / Подано / Черновиков / Зачислено / Отклонено',
            render: (item: any) => {
              return (
                !!item?.scheduleList?.length && (
                  <>
                    {item.volume}/
                    {item.scheduleList[0].requestTotalCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestTotalCount}
                      </Link>
                    )}
                    /
                    {item.scheduleList[0].requestDraftCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            requestStatusId: RequestStatusEnum.Draft,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestDraftCount}
                      </Link>
                    )}
                    /
                    {item.scheduleList[0].requestIncludedCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            requestStatusId: RequestStatusEnum.Included,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestIncludedCount}
                      </Link>
                    )}
                    /
                    {item.scheduleList[0].requestDeclineCount && (
                      <Link
                        to={{
                          pathname: routes.requests,
                          search: generateUrlSearchString({
                            serviceId: item.serviceId,
                            trainingGroupId: item.id,
                            requestStatusId: RequestStatusEnum.IncludeDeclined,
                            educationTypeId: serviceData.educationTypeId,
                          }),
                        }}
                        target="_blank"
                        className="brand-link"
                      >
                        {item.scheduleList[0].requestDeclineCount}
                      </Link>
                    )}
                  </>
                )
              );
            },
            width: '16%',
          },
          {
            dataIndex: 'teacher',
            title: 'Преподаватель',
            render: (item: any) => item.teacher,
          },
          {
            dataIndex: 'periodMosru',
            title: 'Период приема заявлений на mos.ru',
            render: (item: any) => {
              return !item.scheduleList[0]
                ? ''
                : item.scheduleList[0].isRetro
                ? 'Без публикации на Mos.ru'
                : `${formatDate(item.scheduleList[0].requestStart)} ${formatTime(
                    item.scheduleList[0].requestTimeStart
                  )} - ${formatDate(item.scheduleList[0].requestEnd)} ${formatTime(
                    item.scheduleList[0].requestTimeEnd
                  )}`;
            },
          },
          {
            dataIndex: 'status',
            title: 'Статус плана приема',
            render: (item: any) => (
              <div className="icon-group">
                <div className="icon-group__text">{item.scheduleList[0]?.trainingGroupStatusName}</div>
                <div className="icon-group__icon">
                  {item.scheduleList[0]?.trainingGroupStatusId !== TrainingGroupStatusEnum.Archive && (
                    <Tooltip
                      component={() => getStatusIcon(item.scheduleList[0]?.trainingGroupStatusId)}
                      position="bottom"
                      text={getStatusText(item.scheduleList[0]?.trainingGroupStatusId)}
                    />
                  )}
                </div>
              </div>
            ),
            width: '13%',
          },
          {
            dataIndex: '',
            title: '',
            render: (item: any) =>
              showDropdownMenu(item) && (
                <DropDown
                  component={() => (
                    <span className="drop-down-btn-round">
                      <IconDots />
                    </span>
                  )}
                >
                  <div className="drop-down-panel">
                    <div className="drop-down-panel__list">
                      {canCreateRequest(item) && (
                        <button
                          type="button"
                          onClick={async () => {
                            item.scheduleList[0] && (await apply(item.scheduleList[0].id));
                          }}
                          className="drop-down-panel__list-item"
                        >
                          <IconFile />
                          Подать заявление
                        </button>
                      )}
                      {canEditButton(item) && (
                        <button type="button" onClick={handleEdit(item)} className="drop-down-panel__list-item">
                          <IconEdit />
                          Редактировать
                        </button>
                      )}
                      {canOnlyView(item) && (
                        <button type="button" onClick={handleEdit(item)} className="drop-down-panel__list-item">
                          <IconEye />
                          Просмотреть
                        </button>
                      )}
                      {canSign(item) && (
                        <button
                          type="button"
                          onClick={() => {
                            setSignTg(item);
                          }}
                          className="drop-down-panel__list-item"
                        >
                          <IconSign />
                          Подписать
                        </button>
                      )}
                      {canPublicate(item) && (
                        <button
                          type="button"
                          onClick={async () => {
                            updateStatus(item.id, TrainingGroupStatusEnum.Signed);
                          }}
                          className="drop-down-panel__list-item"
                        >
                          <IconCheck />
                          {item.scheduleList[0]?.isRetro ? 'Подтвердить' : 'Опубликовать'}
                        </button>
                      )}
                      {canRemoveButton(item) && (
                        <button type="button" onClick={handleDelete(item.id)} className="drop-down-panel__list-item">
                          <IconRemove />
                          Удалить
                        </button>
                      )}
                    </div>
                  </div>
                </DropDown>
              ),
            width: '40px',
          },
        ]}
      />
      <SignModal
        show={!!signTg}
        onCloseHandle={() => {
          setSignTg(undefined);
        }}
        ids={[{ id: 0 }]}
        getDataForSignHandle={async () => JSON.stringify(signTg)}
        setSignedDataHandle={async (id, sign) => {
          if (signTg) {
            try {
              await serviceTemplateApi.signTrainingGroup({
                serviceId: serviceData.id,
                educationTypeId: serviceData.educationTypeId,
                signedDocument: sign,
                trainingGroupId: signTg.id,
              });
              setSignTg(undefined);
              getTGList();
            } catch {}
          }
        }}
      />
    </>
  );
};

export default TgList;
