import React, { FC, useState } from 'react';
import { Button, Modal, Push } from '@mosru/esz_uikit';
import { ScheduleType } from '.';
import ModalPanelSchedule from './modal-panel';

type Props = {
  show: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (schedule: ScheduleType[]) => void;
  scheduleData: ScheduleType[];
};

const ScheduleModal: FC<Props> = ({ show, title, onClose, scheduleData, onSubmit }) => {
  const [scheduleSubmit, setScheduleSubmit] = useState(false);
  const [scheduleError, setScheduleError] = useState(false);
  return (
    <Modal show={show} onClose={onClose}>
      <ModalPanelSchedule
        title={title}
        onClose={onClose}
        submit={scheduleSubmit}
        submitSuccess={(s) => {
          onSubmit(s);
          setScheduleSubmit(false);
        }}
        submitError={() => {
          setScheduleSubmit(false);
        }}
        scheduleData={scheduleData}
        setScheduleError={setScheduleError}
        controls={
          <>
            <Button primary border label="Отмена" onClick={onClose} />
            <Push orientation="horizontal" size={12} />
            <Button
              primary
              label="Сохранить"
              disabled={scheduleError}
              onClick={() => {
                setScheduleSubmit(true);
              }}
            />
          </>
        }
      />
    </Modal>
  );
};

export default ScheduleModal;
