import React from 'react';
import { useField } from 'formik';
import { Textarea, TextareaProps } from '@mosru/esz_uikit';

export type FormicInputProps = TextareaProps & {
  name: string;
};

const FormikTextarea: React.FC<Omit<FormicInputProps, 'onChange'>> = ({ name, ...props }) => {
  const [field, , helpers] = useField(name);

  return (
    <Textarea
      {...field}
      {...props}
      onChange={(option) => {
        helpers.setTouched(true, true);
        helpers.setValue(option.target.value);
      }}
    />
  );
};

export default FormikTextarea;
