import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormikHelpers, useFormikContext } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { SearchRequestsInitialFormData } from '../../../../types/requests';
import lookupApi from '../../../../lib/api/lookup';
import FieldAsyncSelect from './async-select';
import { hasAccessObjectAny } from '../../../../lib/utils';
import { accessAction, accessObject } from '../../../../mock-data/access-enum';
import { AppState } from '../../../../redux/types/state';
import { userProfileSelector } from '../../../../redux/selectors';
import { SearchKeyContext } from '../contexts/key-context';
import { setAsyncValues } from '../../../../lib/utils/requests';

type Props = {
  values: SearchRequestsInitialFormData;
  label: string;
  placeholder: string;
  disabled: boolean;
};

const FieldService: React.FC<Props> = ({ values, label, placeholder, disabled }) => {
  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  const { serviceKey, setTrainingGroupKey, setPlaceServiceKey } = useContext(SearchKeyContext);

  const { setFieldValue } = useFormikContext<FormikHelpers<SearchRequestsInitialFormData>>();

  const onChange = () => {
    const dataToClearValues = [
      { key: 'trainingGroupId', action: setTrainingGroupKey },
      { key: 'placeServiceId', action: setPlaceServiceKey },
    ];

    setAsyncValues(dataToClearValues, setFieldValue);
  };

  // // показывать наименование детского объединения
  const canShowChildrenAssociation = useMemo(() => {
    return (
      hasAccessObjectAny(userProfile, [accessObject.RequestNonDOGM], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestDOGM], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestDayCare], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestOP], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestVA], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestSport], accessAction.ViewRegistry) ||
      hasAccessObjectAny(userProfile, [accessObject.RequestArtHouse], accessAction.ViewRegistry)
    );
  }, [userProfile]);
  return canShowChildrenAssociation ? (
    <>
      <Push size={16} />
      <FieldAsyncSelect
        key={serviceKey}
        disabled={disabled}
        onChange={onChange}
        nameFieldId="serviceId"
        nameFieldName="serviceName"
        label={label}
        placeholder={placeholder}
        loadOptions={async (query) =>
          await lookupApi.getService(
            query,
            values.educationTypeId,
            values.vedomstvoId,
            values.organizationId,
            undefined,
            false,
            false
          )
        }
      />
    </>
  ) : null;
};

export default FieldService;
