import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Input,
  IconPlus,
  Select,
  SelectOptionType,
  Panel,
  Loader,
  Push,
  AsyncTable as Table,
} from '@mosru/esz_uikit';
import { pageSizeOptions } from '../../mock-data';
import { historyState } from '../../mock-data/history-state';
import { hasAccessObjectAny, replaceHistoryState, sliceText } from '../../lib/utils';
import { accessAction, accessObject } from '../../mock-data/access-enum';
import { Discipline, SearchDisciplinesFormData } from '../../types/discipline';
import { AppState } from '../../redux/types/state';
import { userProfileSelector } from '../../redux/selectors';
import Edit from './edit';
import disciplineApi from '../../lib/api/discipline';
import { maxRegistryRows } from '../../config/constants';

type Props = {
  search: SearchDisciplinesFormData;
};

const DisciplinesTable = ({ search }: Props) => {
  const [pageSize, setPageSize] = useState(window.history.state[historyState.pageSize] || pageSizeOptions[0].value);
  const [tableData, setTableData] = useState<Discipline[]>([]);
  const [tableTotal, setTableTotal] = useState(0);
  const [editObj, setEditObj] = useState<Discipline>({ id: 0, name: '', code: '' });
  const [initPageNum, setInitPageNum] = useState<number>(window.history.state[historyState.pageNumber] || 0);
  const [pageNum, setPageNum] = useState(initPageNum);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (initPageNum) {
      setInitPageNum(0);
    } else {
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const fetchDisciplines = useCallback(async () => {
    const data = await disciplineApi.getDisciplines({
      ...search,
      pageSize,
      pageNumber: pageNum + 1,
    });
    setTableData(data.items.map((item, index) => ({ ...item, index: index + pageNum * pageSize + 1 })));
    setTableTotal(data.total);
    setLoading(false);
    setEditObj({ id: 0, name: '', code: '' });
  }, [search, pageSize, pageNum]);

  useEffect(() => {
    setLoading(true);

    fetchDisciplines();
  }, [fetchDisciplines]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageSize]: pageSize });
  }, [pageSize]);

  useEffect(() => {
    replaceHistoryState({ [historyState.pageNumber]: pageNum });
  }, [pageNum]);

  const { userProfile } = useSelector((state: AppState) => ({
    userProfile: userProfileSelector(state),
  }));

  return (
    <Panel
      title={() => (
        <>
          Дисциплины{' '}
          <span className="color-gray-dark">
            {' \u00A0'}
            {tableTotal}
          </span>
        </>
      )}
      headingControl={() => (
        <>
          <div style={{ width: 184 }}>
            <Select
              name="pageSize"
              size="small"
              isSearchable={false}
              hideClearIndicator
              value={pageSizeOptions.find((item) => item.value === pageSize)}
              onChange={(selectedOption) => {
                const { value } = selectedOption as SelectOptionType;
                setPageSize(value as number);
                setPageNum(0);
              }}
              options={pageSizeOptions}
            />
          </div>
          <Push orientation="horizontal" size={12} />
          {hasAccessObjectAny(userProfile, [accessObject.Discipline], accessAction.Create) && (
            <Button
              label="Добавить"
              onClick={() => {
                const newData = { name: '', code: '' };
                const newTableData = new Array(newData).concat(tableData);
                setTableData(newTableData);
                setEditObj(newData);
              }}
              disabled={!!editObj.id || editObj.id !== 0}
              iconLeft={() => <IconPlus />}
              primary
              size="small"
            />
          )}
        </>
      )}
    >
      {loading ? (
        <div className="loader-container">
          <Loader title="Загружаем дисциплины" description="Нужно немного подождать" />
        </div>
      ) : (
        <Table
          data={tableData}
          pageSize={pageSize}
          overflow
          itemsAll={tableTotal}
          setPageNumber={setPageNum}
          pageNum={pageNum}
          hideSort
          rowLimit={maxRegistryRows}
          columns={[
            {
              dataIndex: '',
              title: '№',
              render: (item: any) => item.index,
              width: '24px',
            },
            {
              dataIndex: 'name',
              title: 'Наименование',
              width: '690px',
              render: (item: any) => {
                return editObj.id === item.id ? (
                  <Input
                    placeholder="Введите наименование дисциплины…"
                    value={editObj.name}
                    onChange={(value) => setEditObj((prev) => ({ ...prev, name: value.target.value }))}
                  />
                ) : item.isArchive ? (
                  <span className="color-gray-dark">{sliceText(item.name)} (архивная)</span>
                ) : (
                  sliceText(item.name)
                );
              },
            },
            {
              dataIndex: 'code',
              title: 'Код',
              render: (item: any) => (
                <Edit
                  key={tableData.length}
                  code={editObj.id === item.id ? editObj.code : item.code}
                  setCode={(code) => setEditObj((prev) => ({ ...prev, code }))}
                  setEditMode={(mode) => {
                    setEditObj(mode ? item : { id: 0, name: '', code: '' });
                    if (!mode) {
                      setLoading(true);
                      fetchDisciplines();
                    }
                  }}
                  editMode={editObj.id === item.id}
                  setRemovedId={async () => {
                    setLoading(true);
                    await disciplineApi.deleteDiscipline(item.id);
                    fetchDisciplines();
                  }}
                  submitHandler={async () => {
                    setLoading(true);
                    if (item.id > 0) {
                      await disciplineApi.updateDiscipline({ ...item, code: editObj.code, name: editObj.name });
                    } else {
                      await disciplineApi.saveDiscipline({ code: editObj.code, name: editObj.name });
                    }
                    setEditObj({ id: 0, name: '', code: '' });
                    fetchDisciplines();
                  }}
                  isArchive={item.isArchive}
                  restoreHandler={async () => {
                    setLoading(true);
                    await disciplineApi.restoreDiscipline(item.id);
                    fetchDisciplines();
                  }}
                />
              ),
              width: '15%',
            },
          ]}
        />
      )}
    </Panel>
  );
};

export default DisciplinesTable;
